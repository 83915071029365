<template>
<div>
    <modal v-model="openValue" :closeable="isCloseable" modalClass="modal-info" style="z-index: 500 !important" :emitEvents="false" hideActions>
        <div class="pa20" style="z-index: 501">
            <div v-if="error" class="flex column" v-html="error"></div>
            <!--<div v-if="error && message && !showErrorMessage" class="label-button" @click="showErrorMessage = true">Mostrar Error</div>-->
            <div v-if="error && message && showErrorMessage" v-html="message"></div>
            <div v-if="redirect" class="flex justify-center"><a :href="`/${Local ? Local.slug : 'login'}`"><button>Iniciar Sessión</button></a></div>
            
            <!-- <div v-if="messageType" class="mt10 flex justify-center"><button @click="openValue=false">Cerrar</button></div> -->
        </div>

        <div v-if="!error"  class="fill flex justify-center align-center">
            <i style="font-size: 100px; color: var(--white);" class="absolute icon icon-star spin"></i>
        </div>
        
    </modal>

    <modal v-model="isLoading" modalClass="loading-modal" hideActions :closeable="false" :emitEvents="false" style="z-index: 500 !important">
        <div class="pa10" style="overflow: hidden" ref="modalLoading">
            <i class="icon icon-semi-circle spin"></i>
        </div>
    </modal>

    
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Boolean, String, Object, Array, Number],
            default: false
        }
    },
    data() {
        return {
            openValue: false,
            error: null,
            message: null,
            showErrorMessage: false,
            count: 1,
            currentCount: 0,
            redirect: false,
            messageType: null,
            typeClasses: {error: 'error-color', success: 'selected-color', info: 'info-color'},
            isLoading: false
        }
    },
    mounted() {
        this.$root.$on('Loading', (count) => {
            if(!count) count = 1;
            this.count = count;
            this.currentCount = 0;
            this.message = null;
            this.showErrorMessage = false;
            this.openValue = false;
            this.error = null;
            this.redirect = false;
            this.messageType = null;
            this.isLoading = true;
            if(document.activeElement) document.activeElement.blur();
        });

        this.$root.$on('Response', data => {
            console.log(data);
            this.openValue = true;
            this.message = null;
            this.showErrorMessage = false;
            this.redirection = false;
            this.messageType = null;
            this.isLoading =false;

            if(data.success)
            {
                this.error = data.success;
                this.messageType = 'success';
            }
            else if(data.info)
            {
                this.error = data.info;
                this.messageType = 'info';
            }
            else if(data.error && typeof data.error === 'string')
            {
                this.error = data.error;
                this.messageType = 'error';
            }
            else if(data.error && data.reason)
            {
                this.error = Util.Error(data.reason);
                this.messageType = 'error';
            }
            else if(data.error || (data.data && data.data.error))
            {
                this.error = Util.Error(data.data ? data.data.reason : data.reason);
            }
            else if(data.response)
            {   
                if(data.response.status == 500)
                {
                    this.error = "<p>¡Ha ocurrido un error!</p>";
                    this.message = JSON.stringify(data.response.data);
                }
                else if(data.response.status == 422)
                {
                    var list = '';
                    for(var key in data.response.data.errors)
                    {
                        var field = data.response.data.errors[key];
                        for(var j = 0; j < field.length; j++)
                        {
                            list += `<li>${field[j].trim()}</li>`
                        }
                    }
                    this.error = `<div class='mb10 bold ta-center'>¡Campos Incorrectos!</div><ul>${list}</ul>`;
                }
                else if(data.response.status == 401) 
                {
                    this.error = '<p>No tiene los permisos necesarios o la sesión ha expirado.</p>';
                    this.redirect = true;
                }
                else if(data.response.status == 400)
                {
                    this.error = Util.Error(data.response.data.message ? data.response.data.message : 'ERROR');
                }
                else if(data.statusText)
                {
                    this.error = data.statusText;
                }
                else
                {
                    this.error = 'Ha ocurrido un error.';
                }
            }
            else if(data.message === 'Network Error')
            {
                this.error = 'Se ha perdido la conexión a internet';
            }
            else
            {
                //Success
                this.currentCount++;
                if(this.currentCount >= this.count)
                {
                    this.openValue = false;
                    this.error = null;
                }
            }
        });
    },
    computed: {
        errors() {
            if(typeof(this.value) == 'object' && this.value.errors)
            {
                var ret = [];
                for(var key in this.value.errors)
                {
                    for(var i = 0; i < this.value.errors[key].length; i++)
                    {
                        ret.push(this.value.errors[key][i]);
                    }
                }
                return ret;
            }
            else
            {
                return null;
            }
        },
        isCloseable() {
            return this.error != null;
        }
    },
    watch: {
        value: function(n, o) {
            this.openValue = n;
            this.$emit('input', n);
        }
    }
}
</script>

<style lang="scss">

.loading-modal {
    box-shadow: none;
    background: transparent !important;
    border: none;
    color: var(--white);
    font-size: 50px;
}

.spin {
	animation: anim-spin 2s infinite linear;
}

@keyframes anim-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.modal-info {
    max-width: 600px;
}
//Mobile
@media (max-width: 820px) {
    .modal-info {
        max-width: 80vw;
    }
}

</style>
