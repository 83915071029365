<template>
<div class="flex-fill fill flex column" style="overflow-x: hidden">
<div v-if="local">

    <div class="section mb20">
        <div class="flex align-center mb20">
            <div class="flex-fill flex">
                <img class="avatar round mr10 unshrink" :src="img(`logos/${local.logotype}`)" width="45" height="45" />
                <div>
                    <div class="f18">{{local.name}}</div>
                    <div v-if="franchise" class="secondary-text">{{desktop ? 'Sub Local de la franquicia' : 'Franquicia: '}} {{ franchise.name }}</div>
                </div>
            </div>
            <button class="secondary" @click="modalProfile=Util.Copy(local)">Editar</button>
        </div>

        <div>
            <div class="bold">Dirección</div>
            <div class="flex column" v-if="local.address">
                <div>{{local.address}}</div>
                <div class="secondary-text">{{local.latitude}}, {{local.longitude}}</div>
            </div>
            <div class="secondary-text" v-else>Sin dirección</div>
        </div>

        <div class="mt15" v-if="local.phone">
            <div class="bold">Teléfono</div>
            <div>{{local.phone}}</div>
        </div>

        <div class="mt15" v-if="local.instagram">
            <div class="bold">Instagram</div>
            <div>{{local.instagram}}</div>
        </div>

        <div class="mt15" v-if="local.public_email">
            <div class="bold">Email Público</div>
            <div>{{local.public_email}}</div>
        </div>

        <div class="mt15" v-if="local.email">
            <div class="bold">Email Administrativo</div>
            <div>{{local.email}}</div>
        </div>

        <div class="mt15 flex align-center" v-if="local.type == 'LOCAL'" >
            <div class="flex-fill">
                <div class="bold">Llave (Influye Service)</div>
                <div v-show="desktop">{{local.service_key}}</div>
            </div>
            <button class="secondary" @click="Util.Clipboard(local.service_key)">Copiar</button>
        </div>

        <div class="mt15 flex align-center" v-if="local.type == 'LOCAL' && facturacion">
            <div class="flex-fill">
                <div class="bold">Datos de Facturación</div>
                <div v-show="facturacion.rut">{{facturacion.name}} - {{ facturacion.rut }}</div>
            </div>
            <button class="secondary" @click="modalFacturacion=Util.Copy(facturacion)">Editar</button>
        </div>
    </div>

    <modal v-model="modalProfile" title="Editar Perfil" dir="right" :modalClass="['fill', 'right h100 md']">

        <div v-if="modalProfile" class="pa20">

            <input-image v-if="!local.id_franchise"
            label="Logo"
            v-model="modalProfile.logotype"
            path="logos/" 
            class="mb15">
            </input-image>

            <!--<input-color v-model="local.color"></input-color>-->

            <div v-if="local.type == 'LOCAL'">
                <input-field 
                v-model="modalProfile.address" 
                label="Dirección" 
                class="mb15">
                </input-field>

                <div class="hs">
                    <input-field 
                    v-model="modalProfile.latitude" 
                    label="Latitud" 
                    class="mb15">
                    </input-field>
                
                    <input-field 
                    v-model="modalProfile.longitude" 
                    label="Longitud" 
                    class="mb15">
                    </input-field>
                </div>

                <div v-if="desktop" class="contain mb15" style="width: 100%; height: 350px; border-radius: 10px">
                    <map-component v-model="modalProfile" 
                    :useMarker="true" 
                    :canEditMarker="true"
                    canSearch geocode
                    @OnMarkerChanged="UpdateLocation($event)">
                    </map-component>
                </div>
            </div>

            <input-field 
            v-model="modalProfile.phone" 
            label="Teléfono" 
            placeholder="(Opcional. Ejemplo: +56912341234 - (41) 123 1234)"
            description="Puedes agregar múltiples números separándolos con un guión"
            class="mb15">
            </input-field>

            <input-select v-model="modalProfile.timezone" class="mb15"
            :items="timezoneItems"
            label="Zona Horaria">
            </input-select>

            <input-field class="mb15"
            v-model="modalProfile.instagram" 
            label="Instagram"
            placeholder="(Opcional. Link al perfil Ej: https://www.instagram.com/influyeapp/)">
            </input-field>    

            <input-field 
            v-model="modalProfile.public_email" 
            label="Email Público"
            placeholder="(Opcional. Se muestra en página de comercio)">
            </input-field>    
            
            <div class="separator mt20 mb20"></div>

            <input-field 
            v-model="modalProfile.email" 
            label="Email Administrativo"
            description="Correo que utilizará Influye para notificar al negocio">
            </input-field>    

            <input-color class="mt15"
            label="Color de Marca"
            v-model="modalProfile.color">
            </input-color>

            <div v-if="Auth.role == 'admin' || Auth.role == 'sub_admin'">
                <div class="separator mt20 mb20"></div>
                <input-field class="mb15" 
                v-model="modalProfile.segment" 
                label="Segmento">
                </input-field> 
                <input-field class="mb15" 
                v-model="modalProfile.city" 
                label="Ciudad">
                </input-field> 
                <input-field class="mb15" 
                v-model="modalProfile.region" 
                label="Región">
                </input-field> 
                <input-field class="mb15" 
                v-model="modalProfile.country" 
                label="País">
                </input-field> 
            </div>

        </div>

        <button class="primary purple" slot="actions" @click="SaveProfile()">Guardar</button>

    </modal>

    <div slot="General" v-if="config && Local.type == 'LOCAL'">
        
        <div class="flex align-center mb20">
            <div class="f18 flex-fill">Métodos de Pago</div>
            <i class="options icon-plus" @click="modalPayMethods={}" v-tooltip="'Crear método de pago'"></i>
        </div>

        <div class="section mb20">

            <draggable
            :list="dragList"
            :animation="200"
            handle=".handle"
            group="methods">

                <div v-for="(method, index) in dragList" :key="index" class="well mb10 selectable">
                    <div class="flex fill align-center" v-if="method.title != 'CASH' && method.title != 'BANK_DEPOSIT' && method.title != 'ONLINE'" @click="OpenModalPayMethods(method,index)">
                        <i class="icon icon-drag-handle handle"></i>
                        <div class="flex flex-fill align-center">
                            <div class="flex column flex-fill">{{method.title}} <span v-if="method.pending_payment" class="secondary-text">Pago Pendiente</span></div>
                            <div class="options icon-trash" v-tooltip="'Eliminar método de pago'" @click.stop="Confirm({
                                text: 'Eliminar', 
                                class: 'error-color', 
                                title: '¿Eliminar método de pago?', 
                                function: () => DeletePaymentMethod(index)
                            })"></div>
                        </div>
                    </div>
                    <div class="flex fill align-center" v-if="method.title == 'CASH'">
                        <i class="icon icon-drag-handle handle"></i>
                        <label class="flex-fill">
                            <input type="checkbox" v-model="config.accept_cash">Efectivo <div class="checkmark"></div>
                        </label>
                    </div>

                    <div class="flex align-center" v-if="method.title == 'BANK_DEPOSIT'">
                        <i class="icon icon-drag-handle handle"></i>
                        <label class="flex-fill align-center">
                            <input type="checkbox" v-model="config.accepts_bank_deposit"><div>
                                <div>Transferencia</div>
                                <div class="secondary-text" v-if="delivery.bank_settings.bank">{{delivery.bank_settings.bank}} - {{delivery.bank_settings.account}} {{delivery.bank_settings.account_number}}</div>
                                <div class="secondary-text" v-else>Sin datos bancarios</div>
                            </div> <div class="checkmark"></div>
                        </label>
                        <button class="secondary" @click="modalBank=Util.Copy(delivery.bank_settings)">Editar</button>
                    </div>

                    <div class="flex align-center" v-if="method.title == 'ONLINE'">
                        <i class="icon icon-drag-handle handle"></i>
                        <label class="flex-fill align-center">
                            <input type="checkbox" v-model="config.accepts_online_payment"><div>
                                <div>Pago Online</div>
                                <div class="secondary-text" v-if="delivery.transbank_commerce">ID Comercio: {{delivery.transbank_commerce}}</div>
                                <div class="secondary-text" v-else>No hay un comercio de Transbank asociado (los pagos online no funcionarán)</div>
                            </div> <div class="checkmark"></div>
                        </label>
                        <button class="secondary" @click="modalTransbank = Util.Copy(delivery)">Editar</button>
                    </div>                
                </div>

            </draggable>

            <div class="separator mt20 mb20"></div>
            <label>
                <input type="checkbox" v-model="config.no_payment_select">Se debe seleccionar el método de pago<div class="checkmark"></div>
            </label>

            <modal v-model="modalPayMethods" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalPayMethods && modalPayMethods.edit ? 'Editar Método de Pago' : 'Nuevo Método de Pago'">
                <div v-if="modalPayMethods" class="section">
                    
                    <input-field class="mb15" v-if="!modalPayMethods.edit"
                    v-model="modalPayMethods.title"
                    label="Método de Pago">
                    </input-field>
                    <div class="mb15" v-else>
                        <div class="bold">Método de Pago</div>
                        <div>{{modalPayMethods.title}}</div>
                    </div>

                    <label class="">
                        <input type="checkbox" v-model="modalPayMethods.pending_payment"><div>
                            <div class="bold">Pago Pendiente</div>    
                            <div class="secondary-text">El pago de las transacciones con este método quedarán pendientes y se deben liquidar después</div>
                        </div> <div class="checkmark"></div>
                    </label>
                    <input-text class="mt15"
                    v-model="modalPayMethods.payment_data"
                    label="Datos adicionales"
                    placeholder="(Opcional) Whatsapp +569XXXXXXXX"
                    description="Ingresa información adicional asociado al método de pago. Puedes colocar el formato 'Whatsapp +569XXXXXXXX' para habilitar un botón de contacto.">
                    </input-text>
                </div>
                
                <button class="primary purple" slot="actions" v-if="modalPayMethods && modalPayMethods.edit" @click="EditPendingPayments()">Editar</button>
                <button v-else slot="actions" class="primary purple" @click="AddPaymentMethod()">Aceptar</button>
                
            </modal>

            <modal v-model="modalBank" title="Datos Transferencia Bancaria" dir="right" :modalClass="['fill', 'md right h100']">
                <div v-if="modalBank" class="pa20">
                    <input-field class="mb15"
                    v-model="modalBank.name"
                    label="Nombre"
                    placeholder="Realizar deposito a nombre de...">
                    </input-field>
                    <input-field class="mb15"
                    v-model="modalBank.rut"
                    label="RUT"
                    placeholder="Realizar deposito a nombre de...">
                    </input-field>
                    <input-field class="mb15"
                    v-model="modalBank.bank"
                    label="Banco"
                    placeholder="Nombre del banco...">
                    </input-field>
                    <input-field class="mb15"
                    v-model="modalBank.account"
                    label="Tipo de Cuenta"
                    placeholder="">
                    </input-field>
                    <input-field class="mb15"
                    v-model="modalBank.account_number"
                    label="Número de Cuenta"
                    placeholder="">
                    </input-field>
                    <input-field class="mb15"
                    v-model="modalBank.email"
                    label="Email"
                    placeholder="Email para recibir el comprobante de pago">
                    </input-field>
                    <input-field
                    v-model="modalBank.phone"
                    label="Teléfono"
                    placeholder="Número para que el cliente pueda notificar la transferencia (Whatsapp)">
                    </input-field>
                </div>
                <button slot="actions" class="primary purple" @click="SaveBankData()">Guardar</button>
            </modal>

            <modal v-model="modalTransbank" dir="center" modalClass="sm" title="Pago Online">
                <div class="section" v-if="modalTransbank">
                    <input-field
                    v-model="modalTransbank.transbank_commerce"
                    label="Código de comercio Transbank"
                    description="Debes estar inscrito en Transbank con el proveedor Influye">
                    </input-field>
                </div>
                
                <button slot="actions" class="primary purple" @click="SaveTransbankID()">Guardar</button>
                
            </modal>



            <!--
            <div class="modal-actions">
                <button @click="modalPayMethods={}">Nuevo Método de Pago</button>
            </div>
            -->
            <!--
            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Datos Transferencia Bancaria</div>
          
            <input-field class="mb15"
            v-model="config.bank_settings.name"
            label="Nombre"
            placeholder="Realizar deposito a nombre de...">
            </input-field>
            <input-field class="mb15"
            v-model="config.bank_settings.rut"
            label="RUT"
            placeholder="Realizar deposito a nombre de...">
            </input-field>
            <input-field class="mb15"
            v-model="config.bank_settings.bank"
            label="Banco"
            placeholder="Nombre del banco...">
            </input-field>
            <input-field class="mb15"
            v-model="config.bank_settings.account"
            label="Tipo de Cuenta"
            placeholder="">
            </input-field>
            <input-field class="mb15"
            v-model="config.bank_settings.account_number"
            label="Número de Cuenta"
            placeholder="">
            </input-field>
            <input-field class="mb15"
            v-model="config.bank_settings.email"
            label="Email"
            placeholder="Email para recibir el comprobante de pago">
            </input-field>
            <input-field
            v-model="config.bank_settings.phone"
            label="Teléfono"
            placeholder="Número para que el cliente pueda notificar la transferencia (Whatsapp)">
            </input-field>
            -->
        
        </div>


        <div class="flex align-center mb20">
            <div class="f18 flex-fill">Pedidos</div>
            <i class="options icon-dots-vertical" @click="Options([
                {text: 'Reiniciar Número de Ticket', class: 'error-color', function: () => {ResetTicketNumber()}, confirm: `Se volverá a iniciar la enumeración de los pedidos por lo que el próximo pedido será el ticket número 1. Este cambio es irreversible, ¿desea continuar?`}
            ])"></i>
        </div>

        <div class="section mb20">
            
            
            

            <label class="mb10"><input type="checkbox" v-model="config.accept_serve"> Acepta pedidos para servir<div class="checkmark"></div></label>
            <label class="mb10"><input type="checkbox" v-model="config.accept_takeaway"> Acepta pedidos para llevar<div class="checkmark"></div></label>
            <label class="mb15"><input type="checkbox" v-model="config.accept_delivery">Acepta pedidos delivery<div class="checkmark"></div></label>
            
            <input-select class="mb15"
            v-model="config.default_sale"
            label="Tipo de pedido por defecto"
            :items="optionsDefaultSale">
            </input-select>

            <div class="separator mb20 mt20"></div>
            
            <label class="mb15"><input type="checkbox" v-model="config.preparation_step"> <div class="flex column">
                <div class="bold">Paso de preparación</div>
                <div class="secondary-text">Los pedidos al ser ingresados quedan en estado pendiente y se deben marcar como 'En preparación' antes de completarlos</div>    
            </div><div class="checkmark"></div></label>

            <div class="separator mb20 mt20"></div>

            <input-select
            v-model="config.default_paid"
            label="Pago de un pedido"
            description="Momento en el cual una transacción fue pagada; al ser ingresada o al ser completada (o la opción de elegir cada vez que se ingresa una transacción)."
            :items="optionsDefaultPaid">
            </input-select>

            <div class="separator mb20 mt20"></div>

            <input-field class="input-top"
            v-model="config.kitchen_activation_time"
            label="Visibilidad de Pedidos con Tiempo de Entrega"
            description="Tiempo en minutos en que se mostrarán los pedidos en Pedidos Pendientes en la vista de Cocina con un tiempo de entrega"
            type="int+"
            suffix=" minutos">
            </input-field>
            <div class="well mini bottom border secondary-text mb15">{{config.kitchen_activation_time ? `Los pedidos se mostrarán ${config.kitchen_activation_time} minutos antes del tiempo de entrega.` : 'Los pedidos se muestran siempre.'}}</div>

            <input-field class="input-top"
            v-model="config.cancelled_transactions_time"
            label="Visibilidad de Pedidos Cancelados"
            description="Tiempo en minutos en que se mostrarán los pedidos cancelados en Pedidos Pendientes"
            type="int+"
            suffix=" minutos">
            </input-field>
            <div class="secondary-text well mini border bottom">{{config.cancelled_transactions_time ? `Los pedidos cancelados se muestran por ${config.cancelled_transactions_time} minutos.` : 'Los pedidos cancelados no se muestran.'}}</div>

            <div class="separator mt20 mb20"></div>

            <input-select :items="optionsReduceInventoryCompleted"
            v-model="config.reduce_inventory_completed" label="Reducción de Inventario"
            description="Determina cuando se hace el desconteo de inventario al ingresar una transacción"></input-select>

            <div class="separator mt20 mb20"></div>

            <label class="mb15"><input type="checkbox" v-model="config.active_kitchen_delay"> <div class="flex column">
                <div class="bold">Color de productos con retraso en Cocina</div>
                <div class="secondary-text">Configura el tiempo de retraso que mostrará colores de los productos en cocina.</div>    
            </div><div class="checkmark"></div></label>
            <template v-if="config.active_kitchen_delay">
                <input-field class="input-top"
                v-model="config.kitchen_late_time"
                label="Producto atrasado"
                description=""
                type="int+"
                suffix=" minutos">
                </input-field>
                <div class="secondary-text well mini border bottom">{{config.kitchen_late_time ? `Los productos después de ${config.kitchen_late_time} minutos se mostrarán de color amarillo.` : 'No se indicarán productos atrasados.'}}</div>
                
                <input-field class="input-top mt10"
                v-model="config.kitchen_very_late_time"
                label="Pedido muy atrasado"
                description=""
                type="int+"
                suffix=" minutos">
                </input-field>
                <div class="secondary-text well mini border bottom">{{config.kitchen_very_late_time ? `Los productos después de ${config.kitchen_very_late_time} minutos se mostrarán de color rojo.` : 'No se indicarán productos muy atrasados.'}}</div>
            </template>

            <div class="separator mt20 mb20"></div>

            <label><input type="checkbox" v-model="config.default_tip_table"> 
                <div class="flex column">
                    <div class="bold">Propina por defecto en mesas</div>
                    <div class="secondary-text">Agrega 10% de propina al cerrar la mesa</div>
                </div>
                <div class="checkmark"></div>
            </label>

        </div>


        <div class="f18 mb20">Repartos</div>
        <div class="section mb20">

            <div class="flex mb20">
                <div class="flex column fill">
                    <div class="f18">Zonas de Reparto</div>
                    <div class="secondary-text">Se mostrarán como costos de reparto seleccionable en el punto de venta. Para pedidos online, un cliente podrá hacer un pedido solo si la dirección de envío está dentro de una zona de reparto (sólo las zonas con un polígono definido y habilitadas).</div>
                </div>
                <i class="options icon-dots-vertical" @click="Options([
                    //{text: 'Definir Zona Circular', function: () => {modalRadio = {radio: 0, cost: 0}}},
                    {text: 'Importar Zona', function: () => {modalImportMaps = {}}},
                    {text: 'Nueva Zona', function: () => {modalZone={label: '', radio: null, cost: 0}}}
                ])"></i>
            </div>

            <div class="hdvm" v-if="delivery.zones.length">
            
                <div class="ofy" style="max-height: 400px;">
                    <div :class="`selectable well mb10 ${currentPolygon === index ? 'selected' : ''}`"
                    v-for="(zone, index) in delivery.zones"
                    :key="index"
                    @click="SelectPolygon(index);">
                        <div class="fill flex align-center">
                            <!--<i :class="`options icon-circle${currentPolygon === index ? '-check cpurple' : ''} mr5`" @click.stop="SelectPolygon(index)"></i>-->
                            <div class="flex column flex-fill">
                                <div :class="`bold ${currentPolygon === index ? 'cselected' : ''}`">{{zone.label ? zone.label : `Zona ${index+1}`}}</div>
                                <div class="secondary-text">Costo {{Util.Price(zone.cost)}}</div>
                                <div class="secondary-text" v-if="zone.polygon.length >= 3 && !zone.disabled">Habilitada para pedidos online</div>
                            </div>
                            <i class="options icon-dots-vertical" @click.stop="Options([
                                {text: 'Eliminar', function: () => {RemovePolygon(index)}, class: 'error-color', confirm: '¿Eliminar zona de reparto?'},
                                {text: 'Editar', function: () => {modalZone=Util.Copy(zone, {edit: index})}},
                            ])"></i>
                        </div>
                    </div>
                </div>

                <map-component style="height: 400px; border-radius: 10px"
                ref="map"
                v-model="local"
                :useMarker="true"
                :canEditPolygon="currentPolygon!==null"
                @OnPolygonChanged="UpdatePolygon($event)">
                </map-component>

            </div>
            <div v-else class="secondary-text ta-center mt10">No hay zonas de reparto</div>

            <div class="separator mt20 mb20"></div>

            <label class="mb10"><input type="checkbox" v-model="config.customer_auto_select_delivery_cost"> Cargar zona de reparto automáticamente de clientes en POS (sólo si se asignó una zona anteriormente)<div class="checkmark"></div></label>

            <!-- Modal para seleccionar un archivo KML -->
            <modal v-model="modalImportMaps" dir="center" modalClass="sm" title="Importar Zona">
                <div class="secondary-text pa20">Seleccione un archivo KML con la zona delimitada.</div>
            
                <button-file accept=".kml" slot="actions"
                :type="null" v-if="modalImportMaps"
                @FileSelected="file => {$set(modalImportMaps, 'file', file)}">
                    <button :class="`secondary ${modalImportMaps.file ? '' : ''}`">{{modalImportMaps.file ? modalImportMaps.file.name : 'Seleccionar Archivo'}}</button>
                </button-file>
                <button slot="actions" class="primary purple" @click="ImportMaps()">Importar</button>
            </modal>

            <!-- Modal zona -->
            <modal v-model="modalZone" dir="center" modalClass="sm" title="Zona">
                <div class="section" v-if="modalZone">

                    <input-field v-model="modalZone.label" class="mb15"
                    label="Nombre"
                    placeholder="(Opcional)">
                    </input-field>

                    <input-field class="mb15"
                    v-model="modalZone.radio"
                    label="Radio en Kilómetros"
                    placeholder="(Opcional)"
                    type="float+"
                    suffix=" km">
                    </input-field>

                    <input-field class="mb15"
                    v-model="modalZone.cost"
                    label="Costo de Reparto"
                    type="int+"
                    prefix="$">
                    </input-field>

                    <label>
                        <input type="checkbox" v-model="modalZone.disabled"><div>Deshabilitado (Pedidos Online)</div> <div class="checkmark"></div>
                    </label>

                </div>
                
                <button slot="actions" v-if="modalZone" class="primary purple" @click="AddZone()">{{modalZone.edit!==undefined ? 'Editar Zona' : 'Agregar Zona'}}</button>
                
            </modal>

        </div>

        <div class="section mb20">

            <div class="flex mb20">
                <div class="flex column fill">
                    <div class="f18">Reglas de Reparto</div>
                    <div class="secondary-text">Se pueden configurar reglas para aplicar costos de reparto de forma automatica si el valor total es mayor o igual a la condicion aplicada.</div>
                </div>
                <i class="options icon-dots-vertical" @click="Options([
                    //{text: 'Definir Zona Circular', function: () => {modalRadio = {radio: 0, cost: 0}}},
                    {text: 'Nueva Regla', function: () => {modalRule={label: '',  cost: 0, condition: 0}}}
                ])"></i>
            </div>

            <div class="hdvm" v-if="delivery.rules.length">

                <div class="ofy" style="max-height: 400px;">
                    <div :class="`selectable well mb10 ${currentPolygon === index ? 'selected' : ''}`"
                    v-for="(rule, index) in delivery.rules"
                    :key="index">
                        <div class="fill flex align-center">
                            <!--<i :class="`options icon-circle${currentPolygon === index ? '-check cpurple' : ''} mr5`" @click.stop="SelectPolygon(index)"></i>-->
                            <div class="flex column flex-fill">
                                <div class="bold">{{rule.label ? rule.label : `Regla ${index+1}`}}</div>
                                <div class="secondary-text">Los pedidos que sean mayor o igual a {{Util.Price(rule.condition)}} tendran costo de reparto {{Util.Price(rule.cost)}}</div>
                                <div class="secondary-text" v-if="!rule.disabled">Habilitada para pedidos online</div>
                            </div>
                            <i class="options icon-dots-vertical" @click.stop="Options([
                                {text: 'Eliminar', function: () => {RemoveRule(index)}, class: 'error-color', confirm: '¿Eliminar regla de reparto?'},
                                {text: 'Editar', function: () => {modalRule=Util.Copy(rule, {edit: index})}},
                            ])"></i>
                        </div>
                    </div>
                </div>

            </div>
            <div v-else class="secondary-text ta-center mt10">No hay reglas de reparto</div>


        <!-- Modal regla -->
            <modal v-model="modalRule" dir="center" modalClass="sm" title="Regla">
                <div class="section" v-if="modalRule">

                    <input-field v-model="modalRule.label" class="mb15"
                    label="Nombre" placeholder="(Opcional)">
                    </input-field>

                    <input-field class="mb15"
                    v-model="modalRule.cost"
                    label="Costo de Reparto"
                    type="int+"
                    prefix="$">
                    </input-field>

                    <input-field class="mb15"
                    v-model="modalRule.condition"
                    label="Valor condicional"
                    type="int+"
                    prefix="mayor o igual $">
                    </input-field>
                    <label>
                        <input type="checkbox" v-model="modalRule.disabled"><div>Deshabilitado (Pedidos Online)</div> <div class="checkmark"></div>
                    </label>

                </div>
                
                <button slot="actions" v-if="modalRule" class="primary purple" @click="AddRule()">{{modalRule.edit!==undefined ? 'Editar Regla' : 'Agregar Regla'}}</button>
                
            </modal>

        </div>

        </div>

        <div class="section mb20" v-if="integration_pya && Local.modules.module_pya">
            <div class="flex align-center mb10">
                <div class="f18 flex-fill">PedidosYa</div>
                <button class="secondary" @click="modalPyaIntegration=Util.Copy(integration_pya)">Configurar</button>
            </div>

            <div v-if="!integration_pya.is_active" class="secondary-text mt10">La integración con PedidosYa no está activada</div>
            <div v-else class="secondary-text mt10">La integración con PedidosYa esta activada <i class="icon icon-circle-check cpurple ml5"></i></div>

            <div v-if="integration_pya && integration_pya.is_active && integration_pya.in_service">
                <div class="separator mt20 mb20"></div>

                <div class="flex align-center">
                    <div class="secondary-text flex-fill">Estado: <span :class="`${dictStatusPya[integration_pya.in_service].color} ml10`">{{ integration_pya.in_service == 'CLOSED_UNTIL'? 'Cerrado hasta '+Util.Date(integration_pya.opening_time, 'HH:mm') : dictStatusPya[integration_pya.in_service].label }}</span></div>
                    <button class="secondary" :disabled="!integration_pya.changeable" @click="modalPyaIntegrationStatusService=Util.Copy({...integration_pya, futureStatus: integration_pya.in_service})">Editar</button>
                </div>
            </div>
        </div>

        <div class="section mb20" v-if="integration_courier && Local.modules.module_pya_courier">
            <div class="flex align-center mb10">
                <div class="f18 flex-fill">PedidosYa Courier</div>
                <button class="secondary" @click="modalPya=Util.Copy(integration_courier)">Configurar</button>
            </div>

            <div v-if="!integration_courier || !integration_courier.is_active" class="secondary-text mt10">La integración con PedidosYa Courier no está activada</div>
            <div v-else class="secondary-text mt10">La integración con PedidosYa Courier esta activada <i class="icon icon-circle-check cpurple ml5"></i></div>
            <!-- <div class="secondary-text" v-if="integration_courier.username">La integración con PedidosYa Courier esta activada con el usuario {{integration_courier.username}} <i class="icon icon-circle-check cpurple ml5"></i></div> -->
        </div>

        <div class="section mb20">
            <div class="flex mb10">
                <div class="flex column flex-fill">
                    <div class="bold">Llave API Google Maps</div>
                    <div class="secondary-text">Usa la API de Google para obtener direcciones, si no se puede obtener se usarán las direcciones proporcionadas por Influye</div>
                </div>
                <button class="secondary" @click="Util.Open(`https://maps.googleapis.com/maps/api/geocode/json?address=chile&key=${config.google_maps_api_key}`)">Probar Llave</button>
            </div>
            <input-field
            v-model="config.google_maps_api_key"
            placeholder="(Opcional)"
            type="password">
            </input-field>
        </div>
        
        <div>
            <div class="f18 mb20">Turnos</div>
            <div class="section mb20">
                <label class="mb10"><input type="checkbox" v-model="config.not_add_initial_cash"> No sumar efectivo inicial<div class="checkmark"></div></label>
                <label class=""><input type="checkbox" v-model="config.only_one_turn_active"> Turno único<div class="checkmark"></div></label>
                <label class="mt15 ml30" v-if="config.only_one_turn_active">
                    <input type="checkbox" v-model="config.same_account_open_close_turn"> 
                    <div class="flex column">
                        <div class="">Restringir apertura y cierra del turno a la misma cuenta</div>
                        <div class="secondary-text">Al estar habilitada esta opción el usuario que abre turno es quien debe cerrarlo. De lo contrario, cualquier otro usuario puede realizar el cierre de turno.</div>
                    </div> 
                    <div class="checkmark"></div>
                </label>
            </div>
        </div>

        <div>

            <div class="f18 mb20">Mensajeria</div>

            <div class="section flex column gap15">
                <input-text
                v-model="config.default_delivery_message"
                label="Mensaje de Reparto"
                description="Mensaje por defecto para envio de pedidos por Whatsapp. Las palabras {name} y {address} (incluyendo las llaves) serán remplazadas por el nombre y la dirección del cliente respectivamente."
                placeholder="Ejemplo: Hola {name}, tu pedido va en camino a {address}!"
                :keywords="{'{name}': 'Nombre', '{address}': 'Dirección'}"
                showKeywords>
                </input-text>

                <input-text
                v-model="config.default_difussion_message"
                label="Mensaje a Clientes"
                description="Mensaje por defecto que se usará para enviar un mensaje por Whatsapp a los clientes. La palabra {name} (incluyendo las llaves) serán remplazadas por el nombre del cliente."
                placeholder="Ejemplo: Hola {name}"
                :keywords="{'{name}': 'Nombre'}"
                showKeywords>
                </input-text>

                <input-text
                v-model="config.default_voucher_message"
                label="Mensaje al Enviar Voucher"
                description="Mensaje por defecto que se usará al enviar un voucher virtual por Whatsapp. La palabra {name} (incluyendo las llaves) serán remplazadas por el nombre del cliente."
                placeholder="Ejemplo: Hola {name}"
                :keywords="{'{name}': 'Nombre'}"
                showKeywords>
                </input-text>

                <input-text v-if="Local.modules.module_sii_boleta"
                v-model="config.default_boleta_message"
                label="Mensaje al Enviar Boleta"
                description="Mensaje por defecto que se usará al enviar una boleta electrónica por Whatsapp. La palabra {name} (incluyendo las llaves) serán remplazadas por el nombre del cliente."
                placeholder="Ejemplo: Hola {name}"
                :keywords="{'{name}': 'Nombre'}"
                showKeywords>
                </input-text>
            </div>
        </div>

    </div>
    
    <modal v-model="modalPyaIntegrationStatusService" dir="center" modalClass="sm" title="Modificar estado de servicio">
        <div class="section" v-if="modalPyaIntegrationStatusService">
            <input-select class="mt15 restriction-option"
            v-model="modalPyaIntegrationStatusService.futureStatus"
            :items="[
                {value: 'CLOSED_UNTIL', text: 'Cerrado hasta --:--'}, 
                {value: 'CLOSED', text: 'Cerrado'},
                // {value: 'INACTIVE', text: 'Inactivo'},
                {value: 'UNKNOWN', text: 'Desconocido'},
                {value: 'OPEN', text: 'Abierto'},
                {value: 'CLOSED_TODAY', text: 'Cerrado por hoy'},
            ]">
            </input-select>

            <input-date v-if="modalPyaIntegrationStatusService.futureStatus == 'CLOSED_UNTIL'" class="sm mt20"
            label="Hora hasta la cual estará cerrado"
            v-model="modalPyaIntegrationStatusService.closingMinutes"
            type="TIME"
            format="HH:mm">
            </input-date>
        </div>
        <button slot="actions" v-if="modalPyaIntegrationStatusService" class="primary purple" @click="UpdateServicePya(modalPyaIntegrationStatusService)">Editar</button>
    </modal>


    <modal v-model="modalPya" title="Configuración PedidosYa Courier" dir="right" :modalClass="['fill', 'right h100 md']">

        <div v-if="modalPya && (!integration_courier || !integration_courier.is_active)" class="pa20">
            <div class="secondary-text mb15">Debes estar registrado con PedidosYa Courier</div>

            <input-field class="mb15"
            v-model="modalPya.token"
            label="Token"
            placeholder="Token">
            </input-field>

            <!-- <input-field class="mb15"
            v-model="modalPya.username"
            label="Usuario"
            placeholder="Email">
            </input-field>

            <input-field class="mb15"
            v-model="modalPya.password"
            label="Contraseña"
            type="password"
            placeholder="Contraseña">
            </input-field>

            <input-field class="mb15"
            v-model="modalPya.client_id"
            label="ID de Cliente"
            placeholder="Client ID">
            </input-field>

            <input-field class=""
            v-model="modalPya.client_secret"
            label="Secreto de Cliente"
            placeholder="Client Secret"
            type="password">
            </input-field> -->
        </div>

        <tabs :tabs="['Datos', 'Ajustes']" v-if="modalPya && integration_courier && integration_courier.is_active" keyByTab>

            <div slot="Datos" class="pa20">

                <!-- <div class="well secondary-text mb15" v-if="integration.username">La integración esta activada con el usuario {{integration.username}}</div> -->

                <div class="secondary-text mb15">Datos que recibirá el repartidor de PedidosYa al recoger el pedido</div>

                <input-field class="mb15"
                v-model="modalPya.name"
                label="Nombre"
                placeholder="(Requerido)">
                </input-field>

                <input-field class="mb15"
                v-model="modalPya.phone"
                label="Teléfono"
                placeholder="(Requerido)">
                </input-field>

                <input-field class="mb15"
                v-model="modalPya.city"
                label="Ciudad"
                placeholder="(Requerido)">
                </input-field>

                <input-field class="mb15"
                v-model="modalPya.address_street"
                label="Dirección"
                placeholder="(Requerido)">
                </input-field>

                <input-field class="mb15"
                v-model="modalPya.address_additional"
                label="Comentario de Dirección"
                placeholder="(Opcional. Información addicional como número de piso)">
                </input-field>

                <input-field class=""
                v-model="modalPya.instructions"
                label="Instrucciones"
                description="Instrucciones adicionales para el repartidor al recoger el pedido"
                placeholder="(Opcional)">
                </input-field>
            </div>

            <div slot="Ajustes" class="pa20">
                <input-toggle class="mb15"
                v-model="modalPya.autocomplete"
                label="Autocompletar Pedidos"
                description="Se completará automaticamente la transacción cuando PedidosYa haya entregado el pedido">
                </input-toggle>

                <input-toggle class="mb15"
                v-model="modalPya.external_delivery"
                label="Reparto externo"
                description="Se registrará el costo de reparto como reparto externo">
                </input-toggle>

                <input-toggle class="mb15"
                v-model="modalPya.send_emails"
                label="Enviar notificaciones"
                description="Se enviarán los emails notificaciones de PedidosYa">
                </input-toggle>

                <input-toggle class="mb15"
                v-model="modalPya.apply_discount"
                label="Aplicar descuento"
                description="Se enviarán los emails notificaciones de PedidosYa">
                </input-toggle>

                <div class="well" v-if="modalPya.apply_discount">
                    <div class="hdvm">
                        <input-field
                        label="Descuento"
                        type="int+"
                        v-model="modalPya.discount_value"
                        :prefix="modalPya.discount_type == '$' ? '$' : ''"
                        :suffix="modalPya.discount_type == '%' ? '%' : ''">
                        </input-field>

                        <input-select
                        label="Tipo"
                        :items="[{value: '%', text: 'Porcentaje de descuento'}, {value: '$', text: 'Descuento en pesos'}]"
                        v-model="modalPya.discount_type">
                        </input-select>
                    </div>

                    <input-field class="mt15" v-if="modalPya.discount_type == '%'"
                    label="Máximo"
                    type="int+"
                    v-model="modalPya.discount_max">
                    </input-field>
                </div>

            </div>

        </tabs>
        
        <button class="primary error-color" v-if="integration_courier && integration_courier.is_active" slot="actions" @click="SavePya(false, true)">Desactivar</button>
        <button class="primary purple" v-if="integration_courier && integration_courier.is_active" slot="actions" @click="SavePyaSettings(true)">Guardar</button>
        <button class="primary purple" v-if="!integration_courier || !integration_courier.is_active" slot="actions" @click="SavePya(true, true)">Activar</button>
    </modal>

    <modal v-model="modalPyaIntegration" title="Configuración PedidosYa" dir="right" :modalClass="['fill', 'right md h100']">
        <div v-if="modalPyaIntegration" class="pa20">
            <div class="secondary-text mb15">Debes ingresar las credenciales de PedidosYa</div>

            <input-field class="mb15"
            v-if="!integration_pya || !integration_pya.is_active"
            v-model="modalPyaIntegration.username"
            label="Usuario"
            placeholder="Usuario">
            </input-field>

            <input-field class="mb15"
            v-if="!integration_pya || !integration_pya.is_active"
            v-model="modalPyaIntegration.password"
            label="Contraseña"
            placeholder="Contraseña"
            type="password">
            </input-field>

            <input-field class="mb15"
            v-model="modalPyaIntegration.chain_id"
            label="Chain ID"
            placeholder="Chain ID">
            </input-field>

            <input-field class="mb15"
            v-model="modalPyaIntegration.vendor_id"
            label="Vendor ID"
            placeholder="Vendor ID">
            </input-field>
        </div>

        <button class="primary error-color" v-if="integration_pya && integration_pya.is_active" slot="actions" @click="SavePya(false)">Desactivar</button>
        <button class="primary purple" v-if="integration_pya && integration_pya.is_active" slot="actions" @click="SavePyaSettings()">Guardar</button>
        <button class="primary purple" v-if="!integration_pya || !integration_pya.is_active" slot="actions" @click="SavePya(true)">Activar</button>
        <!-- <button class="primary error-color" v-if="integration && integration.is_active" slot="actions" @click="SavePya(false)">Desactivar</button> -->
        <!-- <button class="primary purple" v-if="integration && integration.is_active" slot="actions" @click="SavePyaSettings()">Guardar</button> -->
        <!-- <button class="primary purple" v-if="integration && !integration.is_active" slot="actions" @click="SavePya(true)">Activar</button> -->
    </modal>

    <modal v-model="modalFacturacion" title="Datos de facturación" dir="right" :modalClass="['fill', 'right h100 md']">
        <div v-if="modalFacturacion" class="pa20">
            <input-field
            inputClass="primary mb15"
            v-model="modalFacturacion.rut"
            placeholder="RUT"
            label="RUT">
            </input-field>

            <input-field
            inputClass="primary mb15"
            v-model="modalFacturacion.name"
            placeholder="Razón Social"
            label="Razón social">
            </input-field>

            <input-field
            inputClass="primary mb15"
            v-model="modalFacturacion.email"
            placeholder="Email"
            label="Email">
            </input-field>

            <input-field
            inputClass="primary mb15"
            v-model="modalFacturacion.address"
            placeholder="Dirección"
            label="Dirección">
            </input-field>

            <input-field
            inputClass="primary mb15"
            v-model="modalFacturacion.city"
            placeholder="Ciudad"
            label="Ciudad">
            </input-field>

            <input-field
            inputClass="primary mb15"
            v-model="modalFacturacion.commune"
            placeholder="Comuna"
            label="Comuna">
            </input-field>

            <input-field
            inputClass="primary mb15"
            v-model="modalFacturacion.line_business"
            placeholder="Giro"
            label="Giro del negocio">
            </input-field>
        </div>
        <button class="primary error-color" slot="actions" v-if="facturacion && facturacion.rut" @click="Confirm({
                title: `Se borrarán los datos de facturación`,
                text: 'Confirmar',
                class: 'selected-color',
                function: () => {DeleteDataFacturacion()}
            })">Borrar</button>
        <button class="primary purple" slot="actions" @click="SaveDataFacturacion()">Guardar</button>
    </modal>
</div>       
</template>

<script>
import InputSelect from '../common/InputSelect.vue';
export default {
  components: { InputSelect },
    data() {
        return {
            local: null,
            config: null,
            integration_courier: null,
            integration_pya: null,
            facturacion: null,
            delivery: null,
            modalPayMethods: null,
            modalProfile: null,
            modalBank: null,
            modalTransbank: null,
            modalRule : null,
            currentPolygon: null,
            modalImportMaps: null,
            dragList : [],
            modalZone: null,
            modalPya: null,
            modalPyaIntegration: null,
            modalFacturacion: null,
            modalPyaIntegrationStatusService: null,
            optionsReduceInventoryCompleted: [{text: 'se completa la transacción', value: true}, {text: 'se ingresa la transacción', value: false}],
            optionsDefaultSale: [{text: 'Servir', value: 'SERVE'}, {text: 'Llevar', value: 'TAKEAWAY'}, {text: 'Delivery', value: 'DELIVERY'}, {text: 'Se debe seleccionar en cada pedido', value: 'NO_DEFAULT'}],
            optionsDefaultPaid: [{text: 'al ser ingresado', value: 'CREATED'}, {text: 'al ser completado', value: 'COMPLETED'}, {text: 'elegir en cada caso', value: 'CUSTOM'}],
            timezoneItems: [{text: 'Chile - Santiago', value: 'America/Santiago'}, {text: 'Chile - Punta Arenas', value: 'America/Punta_Arenas'}],
            dictStatusPya: {
                CLOSED_UNTIL: { label: 'Cerrado hasta --:--', color: 'cred' },
                CLOSED: { label: 'Cerrado', color: 'cred' },
                //INACTIVE: { label: 'Inactivo', color: 'cred' }, Consultar eso a miguel, porque la api de pedidos ya no lo permite, a pesar de que según la documentación si
                UNKNOWN: { label: 'Desconocido', color: 'cyellow' },
                OPEN: { label: 'Abierto', color: 'cgreen' },
                CLOSED_TODAY: { label: 'Cerrado por hoy', color: 'cred' },
            },
            franchise: null,
        }
    },
    mounted() {
        this.Loading();
        axios.get(`/local/${this.Local.id}/general`).then((res) => {
            this.local = res.data.local;
            this.config = res.data.config;
            this.integration_courier = res.data.pya;
            this.integration_pya = res.data.pya_integration;
            this.facturacion = res.data.facturacion;
            this.franchise = res.data.franchise;
            
            let auxArrayMethods = []
            if(this.config.payment_methods_order.length == 0){
                this.config.payment_methods_order = ['CASH','BANK_DEPOSIT','ONLINE'];
            }
            auxArrayMethods = [{title:'CASH'}, {title:'BANK_DEPOSIT'}, {title:'ONLINE'}]

            this.config.payment_methods.forEach(element => {
                let exist = auxArrayMethods.some(o => o.title == element.title)
                auxArrayMethods.push(element)
                if(!exist && this.config.payment_methods_order.length == 0){
                    this.config.payment_methods_order.push(element.title)
                } 
            });

            let indexMap = {};
            this.config.payment_methods_order.forEach((obj, index) => {
                indexMap[obj] = index;
            });

            let sortedMethods = auxArrayMethods.sort((x, y) => indexMap[x.title] - indexMap[y.title]);

            this.dragList = sortedMethods


            if(Array.isArray(res.data.delivery.bank_settings)) res.data.delivery.bank_settings = {};
            this.delivery = res.data.delivery;
            this.Response(res);
        }).catch(err => {
            this.Response(err);
        });
    },
    methods: {
        EditPendingPayments(){
            let search = this.config.payment_methods.find(element => element.title == this.modalPayMethods.title)            
            this.$set(search, 'pending_payment', !!this.modalPayMethods.pending_payment);
            this.$set(search, 'payment_data',  this.modalPayMethods.payment_data); 
            this.modalPayMethods = null
        },
        OpenModalPayMethods(method, index) {
            if(method.title){
                this.modalPayMethods={title: method.title, pending_payment: !!method.pending_payment, edit: true, index: index, payment_data : method.payment_data}
            } 
        },
        SaveProfile() {
            this.$root.$emit('Loading');
            axios.patch('/local/' + this.local.id, this.modalProfile).then((res) => {
                this.local = res.data;
                this.modalProfile = null;
                this.Info('Perfil guardado');
            }).catch((err) => {
                this.$root.$emit('Response', err);
            });
        },
        Save() {
            if(this.config.active_kitchen_delay){
                if(isNaN(this.config.kitchen_late_time) && isNaN(this.config.kitchen_very_late_time)) return this.Info("Debe ingresar tiempo en 'atrasado' o 'muy atrasado' o en ambos ")
                if(this.config.kitchen_late_time === 0 && this.config.kitchen_very_late_time === 0) return this.Info("'Atrasado' o 'Muy atrasado' debe ser mayor a 0")
                if((this.config.kitchen_late_time === 0 && isNaN(this.config.kitchen_very_late_time)) || (this.config.kitchen_very_late_time === 0 && isNaN(this.config.kitchen_late_time))) return this.Info("Debe ingresar un valor válido")
            }

            this.config.zones = this.delivery.zones;
            let newOrder = []
            for (let index = 0; index < this.dragList.length; index++) {
                const element = this.dragList[index];
                newOrder.push(element.title)
            }
            this.config.payment_methods_order = newOrder
            this.config.rules = this.delivery.rules;
            this.Loading();
            axios.patch(`/settings/${this.Local.id}/v2`, this.config).then(res => {
                this.$emit('OnSettingsSaved');
                this.Info('Ajustes guardados');
            }).catch(err => {
                this.Response(err);
            });
        },
        UpdateLocation(latlng)
        {
            this.$set(this.modalProfile, 'latitude', latlng.lat);
            this.$set(this.modalProfile, 'longitude', latlng.lng);
        },
        AddPaymentMethod()
        {
            if(!this.modalPayMethods.title) return this.Info('Datos inválidos');
            if(this.config.payment_methods.filter(a => { return a.title === this.modalPayMethods.title}).length > 0) return this.Info('El método de pago ya existe');
            this.config.payment_methods.push({title: this.modalPayMethods.title, pending_payment: !!this.modalPayMethods.pending_payment, payment_data : this.modalPayMethods.payment_data});
            this.dragList.push({title: this.modalPayMethods.title, pending_payment: !!this.modalPayMethods.pending_payment, payment_data : this.modalPayMethods.payment_data}); 
            this.modalPayMethods = null;
        },
        DeletePaymentMethod(index){
            let index_payment = this.config.payment_methods.findIndex(element => element.title == this.dragList[index].title)
            this.config.payment_methods.splice(index_payment, 1)
            this.dragList.splice(index, 1)
        },
        SaveBankData()
        {
            this.Loading();
            axios.patch(`/settings/${this.local.id}/bank`, {bank_settings: this.modalBank}).then(res => {
                this.Response(res);
                this.$set(this.delivery, 'bank_settings', this.modalBank);
                this.modalBank = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveTransbankID()
        {
            this.Loading();
            axios.patch(`/settings/${this.local.id}/transbank`, {transbank: this.modalTransbank.transbank_commerce}).then(res => {
                this.Response(res);
                this.$set(this.delivery, 'transbank_commerce', res.data.id);
                this.modalTransbank = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        RemoveRule(index) {
            this.delivery.rules.splice(index, 1);
        },
        SelectPolygon(index) {
            this.currentPolygon = index;
            var bg = [];
            for(var i = 0; i < this.delivery.zones.length; i++)
                if(i !== index) bg.push(this.delivery.zones[i].polygon);
            this.$nextTick(() => {
                this.$refs.map.SetPolygon(this.delivery.zones[index].polygon, bg);
            });
            
        },
        RemovePolygon(index) {
            this.delivery.zones.splice(index, 1);
            if(!this.delivery.zones.length)
            {
                this.currentPolygon = null;
                this.$refs.map.SetPolygon([]);
            }
            else
            {
                this.SelectPolygon(index > 0 ? (index-1) : 0);
            }
        },
        UpdatePolygon(polygon)
        {
            if(this.currentPolygon !== null)
            {
                this.$set(this.delivery.zones[this.currentPolygon], 'polygon', polygon);
            }
            console.log(polygon)
        },
        AddZone() {

            var polygon = [];    
            var radio = null;
            if(this.modalZone.radio && !isNaN(this.modalZone.radio))
            {
                var center = {lat: Number(this.local.latitude), lng: Number(this.local.longitude)};
                radio = parseFloat(this.modalZone.radio);

                var R = 6371;
                var pi = Math.PI;

                var lat  = (center.lat * pi) / 180;
                console.log('lat ' + lat);
                var lng  = (center.lng * pi) / 180;
                var d = radio / R;

                for(var angle = 0; angle < 360; angle+=15)
                {
                    var brng = angle * pi / 180;
                    console.log('brng ' + brng);

                    var pLat = Math.asin(Math.sin(lat)*Math.cos(d) + Math.cos(lat)*Math.sin(d)*Math.cos(brng));
                    console.log('pLat ' + pLat);
                    var pLng = ((lng + Math.atan2(Math.sin(brng)*Math.sin(d)*Math.cos(lat), Math.cos(d)-Math.sin(lat)*Math.sin(pLat))) * 180) / pi;
                    pLat = (pLat * 180) / pi;

                    polygon.push({
                        lat: pLat,
                        lng: pLng
                    });
                }
            }

            if(radio && !this.modalZone.label) this.modalZone.label = `${radio} Km`;
            if(this.modalZone.edit !== undefined)
            {
                if(radio) this.modalZone.polygon = polygon;
                this.$set(this.delivery.zones, this.modalZone.edit, this.modalZone);
                this.SelectPolygon(this.modalZone.edit);
            }
            else
            {
                this.modalZone.polygon = polygon;
                this.delivery.zones.push(this.modalZone);
                this.SelectPolygon(this.delivery.zones.length-1);
            }
            this.modalZone = null;
        },
        AddRule() {            
            if(this.modalRule.edit !== undefined)
            {
                this.$set(this.delivery.rules, this.modalRule.edit, this.modalRule);
            }
            else
            {
                this.delivery.rules.push(this.modalRule);
            }
            this.modalRule = null;
        },
        ImportMaps()
        {
            const reader = new FileReader();

            reader.onload = (e) => {
                console.log(e.target.result);
                var stringXML = e.target.result;
                var domParser = new DOMParser();
                var xmlDocument = domParser.parseFromString(stringXML, "text/xml");

                console.log(xmlDocument);

                var placemarks = xmlDocument.getElementsByTagName('Placemark');
                for(var i = 0; i < placemarks.length; i++)
                {
                    var placemark = placemarks[i];
                    var polygon = placemark.getElementsByTagName('Polygon');
                    if(polygon && polygon.length)
                    {
                        var names = placemark.getElementsByTagName('name');
                        var name = '';
                        if(names && names.length) name = names[0].textContent;
                        console.log(name);
                        
                        var coordinates = polygon[0].getElementsByTagName('coordinates');
                        if(coordinates && coordinates.length) coordinates = coordinates[0].textContent.trim();
                        else coordinates = null;
                        console.log(coordinates);

                        if(coordinates)
                        {
                            var poly = [];
                            var coords = coordinates.split(' ');
                            var pointsMap = {};
                            coords.forEach(point => {
                                if(point)
                                {
                                    var xyz = point.split(',');
                                    if(xyz.length >= 2)
                                    {
                                        var lat = parseFloat(xyz[1]);
                                        var lon = parseFloat(xyz[0]);
                                        if(!isNaN(lat) && !isNaN(lon))
                                        {
                                            if(!pointsMap[lat+','+lon])
                                            {
                                                poly.push({lat: lat, lng: lon});
                                                pointsMap[lat+','+lon] = true;
                                            }
                                        }
                                    }
                                }
                            });
                            if(poly.length)
                            {
                                this.delivery.zones.push({
                                    cost: 0, 
                                    polygon: poly,
                                    label: name ? name : 'Zona importada'
                                });
                                
                            }
                        }

                    }

                    

                };

                this.modalImportMaps = false;
                this.SelectPolygon(this.delivery.zones.length-1);
            };
            reader.readAsText(this.modalImportMaps.file);
        },
        ResetTicketNumber() {
            this.Loading();
            axios.patch(`/local/${this.Local.id}/ticket/reset`).then(res => {
                this.Info('Enúmeración de pedidos reiniciada');
            }).catch(err => {
                this.Response(err);
            });
        },
        SavePya(futureStatus, courier = false) {
            this.Loading();
            
            const url = `/pya/settings/${this.Local.id}/${courier ? '' : 'integration/'}${futureStatus ? 'enable' : 'disable'}`;
            const modalData = courier ? this.modalPya : this.modalPyaIntegration;
            
            axios.patch(url, modalData)
            .then(({ data }) => {
                if (courier) {
                    this.integration_courier = data;
                    this.modalPya = data.is_active ? this.Util.Copy(data) : null;
                } else {
                    this.integration_pya = data;
                    this.modalPyaIntegration = data.is_active ? this.Util.Copy(data) : null;
                }
                
                this.Info(`Integración ${data.is_active ? 'activada' : 'desactivada'}`);
            })
            .catch(err => {
                this.Response(err);
            });
        },
        SavePyaSettings(courier = false){
            this.Loading();
            const modalData = courier ? this.modalPya : this.modalPyaIntegration;

            axios.patch(`/pya/settings/${this.Local.id}`, modalData).then( ({data}) => {
                if(courier){
                    this.integration_courier = data;
                    this.modalPya = this.Util.Copy(data);
                }else{
                    this.integration_pya = data;
                    this.modalPyaIntegration = this.Util.Copy(data);
                }
                this.Info('Datos guardados');
            })
            .catch(err => {
                this.Response(err);
            });
        },
        SaveDataFacturacion(){
            const rutRegex = /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/;

            if(!this.modalFacturacion.rut) return this.Info('RUT es obligatorio');
            if(!this.modalFacturacion.name) return this.Info('Razón social es obligatorio');
            if(!this.modalFacturacion.email) return this.Info('Email es obligatorio');
            if(!this.modalFacturacion.address) return this.Info('Dirección es obligatorio');
            if(!this.modalFacturacion.city) return this.Info('Ciudad es obligatorio');
            if(!this.modalFacturacion.commune) return this.Info('Comuna es obligatorio');
            if(!this.modalFacturacion.line_business) return this.Info('Giro es obligatorio');

            if(!this.modalFacturacion.rut.includes('-')) this.modalFacturacion.rut = Util.Rut(this.modalFacturacion.rut);
            if(!rutRegex.test(this.modalFacturacion.rut)) return this.Info('Rut inválido');

            this.Loading();
            axios.post(`/sii/settings/facturacion/${this.Local.id}/create`, this.modalFacturacion)
            .then( ({data}) => {
                if(data.status){
                    this.facturacion = data.settings_facturacion;
                    this.modalFacturacion = null;
                }
                return this.Info(data.message);
            })
            .catch( err => {
                this.Response(err);
            });
        },
        DeleteDataFacturacion(){
            this.Loading();
            axios.get(`/sii/settings/facturacion/${this.Local.id}/clean`)
            .then( ({data}) => {
                if(data.status){
                    this.facturacion = {};
                    this.modalFacturacion = null;
                }
                return this.Info(data.message);
            })
            .catch( err => {
                this.Response(err);
            });
        },
        UpdateServicePya(config){
            this.Loading();
            let request = {futureStatus: config.futureStatus};
            // CLOSED_TODAY
            if(config.futureStatus == 'CLOSED_UNTIL'){
                let currentDate = new Date();

                let hours = currentDate.getHours() < 10 ? '0' + currentDate.getHours() : currentDate.getHours();
                let minutes = currentDate.getMinutes() < 10? '0' + currentDate.getMinutes() : currentDate.getMinutes();

                let currentTime = this.Util.TimeToMinutes(`${hours}:${minutes}`);
                let futureTime = this.Util.TimeToMinutes(config.closingMinutes);

                let differenceInMinutes = futureTime - currentTime;

                if (differenceInMinutes <= 0) return this.Info('La hora no puede ser inferior o igual a la actual');

                request.closingMinutes = differenceInMinutes;
                request.futureTime = config.closingMinutes;
            }

            axios.post(`/pya/settings/${this.Local.id}/service`, request)
            .then( ({data}) => {
                if(data.status) {
                    this.integration_pya.in_service = config.futureStatus;
                    if(config.futureStatus == 'CLOSED_UNTIL' && data.opening_time) this.integration_pya.opening_time = data.opening_time;
                    this.modalPyaIntegrationStatusService = null;
                }
                console.log(data);
                this.Info(data.message)
                return this.Response(data);
            })
            .catch( err => {
                this.Response(err);
            });
        }
        /*
        updateAutocompletePya(){
            if(!this.integration.is_active) return this.Info('Configuración desactivada.');
            this.Loading();
            axios.patch(`/pya/settings/autocomplete/${this.Local.id}`, this.integration)
            .then( ({data}) => {
                this.Info('Ajustes de PedidosYa Courier actualizados');
            })
            .catch( (err) => {
                console.log(err);
            });
        }
        */
    },
}
</script>

<style>
.logo-image {
    height: 100px;
}
.logo-image img {
    height: 100%;
}
.local-gradient {
    background: linear-gradient(to bottom, rgb(0, 181, 255) 0%,white 15%);
}
</style>