<template>
<div>
<modal :title="modalRefElement ? null : 'Ajustes de Pedidos Online'" :dir="['up', 'center']" :modalClass="['up w100', '']" v-model="modal" hideActions :domElement="modalRefElement && desktop ? modalRefElement : null" :hideBackdrop="modalRefElement && desktop">
    <div :class="`pa20 ${modalRefElement ? 'popover sm' : ''}`">

        <input-toggle
        v-model="iapSoundEnabled"
        @OnValueChanged="Util.StoreLocal('iapSoundEnabled', $event, true)"
        label="Sonido de alerta"
        description="Sonido que se reproduce cuando se recibe un pedido online o cambia de estado">
        </input-toggle>

        <input-toggle class="mt15"
        v-if="canDisablePedidosYaCourier && usePyaCourier !== null"
        v-model="usePyaCourier"
        @OnValueChanged="ChangePyaCourierSetting($event)"
        label="PedidosYa Courier"
        description="Determina si se usará la integración de PedidosYa para pedidos online">
        </input-toggle>

        <div class="flex mt15" v-if="canAutoAcceptIAP">
            <div class="flex-fill mr5">
                <div :class="`bold ${autoAccept ? 'cgreen' : 'cred'}`">Auto-Aceptar Pedidos {{autoAccept ? 'Activado' : 'Desactivado'}}</div>
                <div v-if="!autoAccept" class="secondary-text">Los pedidos online se deben aceptar manualmente</div>
                <div v-else class="secondary-text">Los pedidos online se autoaceptarán <span v-if="autoAcceptTime">(listo {{autoAcceptRange?'entre':'en'}} {{Util.SecondsToTime(autoAcceptTime*60)}}<span v-if="autoAcceptRange"> y {{Util.SecondsToTime((autoAcceptRange+autoAcceptTime)*60)}}</span> <span v-if="autoAcceptSchedule"> , y programados</span>)</span></div>
            </div>
            
            <button v-if="!autoAccept" @click="modal=null;modalAutoAccept={time: 10, range: 0}" class="secondary">Activar</button>
            <button v-else class="secondary" @click="modal=null;DisableAutoAccept()">Desactivar</button>    
        </div>

        <div class="flex mt15" v-if="Local.modules.module_pya && canAutoAcceptPYA">
            <div class="flex-fill mr5">
                <div :class="`bold ${autoAcceptPya ? 'cgreen' : 'cred'}`">Auto-Aceptar Pedidos de PedidosYa {{autoAcceptPya ? 'Activado' : 'Desactivado'}}</div>
                <div v-if="!autoAcceptPya" class="secondary-text">Los pedidos online desde PedidosYa se deben aceptar manualmente</div>
                <div v-else class="secondary-text">Los pedidos online desde PedidosYa se autoaceptarán</div>
            </div>
            
            <button v-if="!autoAcceptPya" @click="modal=null; modalAutoAccept={time: 10, range: 0, pya: true}" class="secondary">Activar</button>
            <button v-else class="secondary" @click="modal=null;DisableAutoAcceptIntegrationPya(null, true)">Desactivar</button>    
        </div>

        <div class="flex mt15" v-if="canDisableIAP">
            <div class="flex-fill mr5">
                <div :class="`bold ${disabledUntil ? 'cred' : 'cgreen'}`">Pedidos Online {{disabledUntil ? 'Deshabilitados' : 'Habilitados'}}</div>
                <div v-if="!disabledUntil" class="secondary-text">Los pedidos online están habilitados</div>
                <div v-else class="secondary-text">Los pedidos online están deshabilitados <span v-if="disabledUntil !== 1">hasta {{Util.Date(disabledUntil, 'HH:mm DD/MM/YYYY')}}</span></div>
            </div>
            <button v-if="!disabledUntil" class="secondary" @click="DisableIAP()">Deshabilitar</button>
            <button v-else class="secondary" @click="modal=null;EnableIAP()">Habilitar</button>
            
        </div>
    </div>
</modal>

<modal v-model="modalAutoAccept" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Auto-Aceptar Pedidos Online">
    <div class="section" v-if="modalAutoAccept && !modalAutoAccept.pya">
        <input-select :items="[
            {text: '10 minutos', value: 10},
            {text: '20 minutos', value: 20},
            {text: '30 minutos', value: 30},
            {text: '40 minutos', value: 40},
            {text: '50 minutos', value: 50},
            {text: '1 hora', value: 60},
            {text: '1 hora 10 minutos', value: 70},
            {text: '1 hora 20 minutos', value: 80},
            {text: '1 hora 30 minutos', value: 90},
            {text: '1 hora 40 minutos', value: 100},
            {text: '1 hora 50 minutos', value: 110},
            {text: '2 horas', value: 120},
            {text: '2 horas 10 minutos', value: 130},
            {text: '2 horas 20 minutos', value: 140},
            {text: '2 horas 30 minutos', value: 150},
            {text: '2 horas 40 minutos', value: 160},
            {text: '2 horas 50 minutos', value: 170},
            {text: '3 horas', value: 180},
        ]" v-model="modalAutoAccept.time" 
        label="Tiempo"
        class="mb15"></input-select>
        <input-select :items="[
            {text: 'Sin rango de tiempo', value: 0},
            {text: 'rango 10 minutos', value: 10},
            {text: 'rango 20 minutos', value: 20},
            {text: 'rango 30 minutos', value: 30},
            {text: 'rango 40 minutos', value: 40},
            {text: 'rango 50 minutos', value: 50},
            {text: 'rango 1 hora', value: 60},
            {text: 'rango 1 hora 10 minutos', value: 70},
            {text: 'rango 1 hora 20 minutos', value: 80},
            {text: 'rango 1 hora 30 minutos', value: 90},
            {text: 'rango 1 hora 40 minutos', value: 100},
            {text: 'rango 1 hora 50 minutos', value: 110},
            {text: 'rango 2 horas', value: 120},
            {text: 'rango 2 horas 10 minutos', value: 130},
            {text: 'rango 2 horas 20 minutos', value: 140},
            {text: 'rango 2 horas 30 minutos', value: 150},
            {text: 'rango 2 horas 40 minutos', value: 160},
            {text: 'rango 2 horas 50 minutos', value: 170},
            {text: 'rango 3 horas', value: 180},
        ]" v-model="modalAutoAccept.range" label="Rango" class="mb15"></input-select>

        <label class="mb15" v-if="type == 'RESTAURANT'">
            <input type="checkbox" v-model="modalAutoAccept.auto_accept_schedule"> Pedidos programados <div class="checkmark"></div>
        </label>
    </div>
    
    <div slot="actions" class="flex-fill secondary-text mr20" v-if="modalAutoAccept && !modalAutoAccept.pya">
        Se le indicará al cliente que su pedido estará listo {{modalAutoAccept.range ? 'entre':'en'}} {{Util.SecondsToTime(modalAutoAccept.time*60)}}<span v-if="modalAutoAccept.range"> y {{Util.SecondsToTime((modalAutoAccept.time+modalAutoAccept.range)*60)}}</span>
    </div>

    <button slot="actions" v-if="!modalAutoAccept || !modalAutoAccept.pya" class="primary purple" @click="EnableAutoAccept(false)">Activar</button>
    <button slot="actions" v-else class="primary purple" @click="EnableAutoAcceptIntegrationPya(false)">Activar</button>
    
</modal>

</div>
</template>

<script>
export default {
    data() {
        return {
            modal: null,
            disabledUntil: null,
            autoAccept: false,
            autoAcceptTime: 0,
            autoAcceptRange: 0,
            autoAcceptPya: false,
            autoAcceptPyaTime: 0,
            autoAcceptPyaRange: 0,
            modalAutoAcceptPya: null,
            modalAutoAccept: null,
            iapSoundEnabled: false,
            modalRefElement: null,
            usePyaCourier: null,
            type: null
        }
    },
    mounted() {
        
    },
    methods: {
        Open(domElement) {

            if(domElement) this.modalRefElement = domElement;

            this.iapSoundEnabled = !!localStorage.iapSoundEnabled;
            this.usePyaCourier = null;
            
            //this.Loading();
            axios.get(`/settings/delivery/${this.Local.id}/iap`).then(res => {
                //this.Response(res);
                this.type = res.data.ecommerce_type;
                this.disabledUntil = res.data.disabled_until;
                this.autoAccept = res.data.auto_accept_iap;                
                this.autoAcceptTime = res.data.auto_accept_iap_time;
                this.autoAcceptRange = res.data.auto_accept_iap_range;
                this.autoAcceptSchedule = res.data.auto_accept_schedule;

                this.autoAcceptPya = res.data.auto_accept_pya;
                this.autoAcceptPyaTime = res.data.auto_accept_pya_time;
                this.autoAcceptPyaRange = res.data.auto_accept_pya_range;
                this.autoAcceptPyaSchedule = res.data.auto_accept_schedule_pya;
                
                this.usePyaCourier = res.data.delivery_pya;
                
            }).catch(err => {
                //this.Response(err);
            });
            this.modal = true;
        },
        DisableIAP(time)
        {
            if(!time) return this.Options([
                {text: 'Indefinidamente', function: () => {this.DisableIAP('FOREVER')}, confirm: '¿Deshabilitar pedidos online indefinidamente?', class: 'error-color'},
                {text: '3 horas', function: () => {this.DisableIAP(180)}, confirm: '¿Deshabilitar pedidos online por 3 horas?', class: 'error-color'},
                {text: '2 horas y 30 minutos', function: () => {this.DisableIAP(150)}, confirm: '¿Deshabilitar pedidos online por 2 horas y 30 minutos?', class: 'error-color'},
                {text: '2 horas', function: () => {this.DisableIAP(120)}, confirm: '¿Deshabilitar pedidos online por 2 horas?', class: 'error-color'},
                {text: '1 hora y 30 minutos', function: () => {this.DisableIAP(90)}, confirm: '¿Deshabilitar pedidos online por 1 hora y 30 minutos?', class: 'error-color'},
                {text: '1 hora', function: () => {this.DisableIAP(60)}, confirm: '¿Deshabilitar pedidos online por 1 hora?', class: 'error-color'},
                {text: '30 minutos', function: () => {this.DisableIAP(30)}, confirm: '¿Deshabilitar pedidos online por 30 minutos?', class: 'error-color'},
                {text: 'Por el resto del día', function: () => {this.DisableIAP('AUTO')}, confirm: '¿Deshabilitar pedidos online por el resto del día?', class: 'error-color'},
            ]);

            this.Loading();
            axios.post(`/settings/delivery/${this.Local.id}/iap/disable`, {time: time}).then(res => {
                this.disabledUntil = res.data.timestamp;
                this.Info(`Pedidos online deshabilitados hasta ${res.data.timestamp === 1 ? 'volver a habilitarlos' : this.Util.Date(res.data.timestamp, 'HH:mm DD/MM/YYYY')}`);
            }).catch(err => {
                this.Response(err);
            });
        },
        EnableIAP(enable)
        {
            if(!enable) return this.Confirm({
                title: '¿Habilitar pedidos online?',
                text: 'Habilitar',
                class: 'selected-color',
                function: () => {this.EnableIAP(true)}
            });

            this.Loading();
            axios.get(`/settings/delivery/${this.Local.id}/iap/enable`).then(res => {
                this.disabledUntil = null;
                this.Info(`Pedidos online habilitados`);
            }).catch(err => {
                this.Response(err);
            });
        },
        DisableAutoAccept(disable)
        {
            if(!disable) return this.Confirm({
                title: `¿Desactivar auto-aceptar pedidos online?`,
                text: 'Desactivar',
                class: 'error-color',
                function: () => {this.DisableAutoAccept(true)}
            });
            this.Loading();
            axios.get(`/settings/delivery/${this.Local.id}/iap/auto/disable`).then(res => {
                this.autoAccept = res.data.auto_accept_iap;
                this.autoAcceptTime = res.data.auto_accept_iap_time;
                this.autoAcceptRange = res.data.auto_accept_iap_range;

                this.Info(`Auto-aceptar pedidos online desactivado`);
            }).catch(err => {
                this.Response(err);
            });
        },
        EnableAutoAccept(enable)
        {
            if(!this.modalAutoAccept || this.modalAutoAccept.time <= 0 || this.modalAutoAccept.range < 0) return this.Info('Parámetros inválidos');
            if(!enable) return this.Confirm({
                title: `¿Activar auto-aceptar pedidos online?`,
                text: 'Activar',
                class: 'selected-color',
                function: () => {this.EnableAutoAccept(true)}
            });
            this.Loading();
            axios.post(`/settings/delivery/${this.Local.id}/iap/auto/enable`, this.modalAutoAccept).then(res => {
                this.autoAccept = res.data.auto_accept_iap;
                this.autoAcceptTime = res.data.auto_accept_iap_time;
                this.autoAcceptRange = res.data.auto_accept_iap_range;
                this.Info(`Auto-aceptar pedidos online activado`);
                this.modalAutoAccept = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        ChangePyaCourierSetting(value)
        {
            this.Loading();
            axios.post(`/settings/delivery/${this.Local.id}/iap/pyacourier`, {enabled: value}).then(res => {
                this.Info(`PedidosYa Courier ${res.data.enabled ? 'activado' : 'desactivado'} en la página de comercio`);
            }).catch(err => {
                this.Response(err);
            });
        },
        EnableAutoAcceptIntegrationPya(enable) {
            if(!enable) return this.Confirm({
                title: `¿Activar auto-aceptar pedidos online para PedidosYa?`,
                text: 'Activar',
                class: 'selected-color',
                function: () => {this.EnableAutoAcceptIntegrationPya(true)}
            });
            this.Loading();
            axios.post(`/settings/delivery/${this.Local.id}/iap/auto/pya/enable`, this.modalAutoAccept).then(({data}) => {
                this.autoAcceptPya = data.auto_accept_pya;
                this.autoAcceptPyaTime = data.auto_accept_pya_time;
                this.autoAcceptPyaRange = data.auto_accept_pya_range;
                this.Info(`Auto-aceptar pedidos online activado para PedidosYa`);
                this.modalAutoAccept = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        DisableAutoAcceptIntegrationPya(disable){
            if(!disable) return this.Confirm({
                title: `¿Desactivar auto-aceptar pedidos online para PedidosYa?`,
                text: 'Desactivar',
                class: 'error-color',
                function: () => {this.DisableAutoAcceptIntegrationPya(true)}
            });
            this.Loading();
            axios.get(`/settings/delivery/${this.Local.id}/iap/auto/pya/disable`).then(({data}) => {
                this.autoAcceptPya = data.auto_accept_pya;
                this.autoAcceptPyaTime = data.auto_accept_pya_time;
                this.autoAcceptPyaRange = data.auto_accept_pya_range;

                this.Info(`Auto-aceptar pedidos online desactivado`);
            }).catch(err => {
                this.Response(err);
            });
        },
    },
    computed: {
        canDisableIAP() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.disable_iap);
        },
        canAutoAcceptIAP() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.auto_accept_iap);
        },
        canAutoAcceptPYA() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.auto_accept_pya);
        },
        canDisablePedidosYaCourier() {
            if(!this.Local.modules.module_pya_courier) return false;
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.disable_iap_pya);
        }
    },
    watch: {
        iapSoundEnabled: function(n, o) 
        {
            this.$emit('IAPSound', !!n);
        }
    }
}
</script>
