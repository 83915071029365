<template>
<tabs v-model="tab" :tabs="['Dashboard', 'Flujo de Caja', 'Actividad']" ref="tabs" keyByTab
@OnTabFlujodeCaja="!data ? Search() : null"
@OnTabActividad="history.length == 0 ? LoadHistory() : null">

    <div class="opt r">
        <input-date :icon="mobile ? 'calendar' : null" 
        inputClass="secondary" 
        v-show="tab == 'Actividad'"
        type="DATE"
        format="timestamp"
        v-model="historyDate"
        modalOnly
        @OnAcceptDate="LoadHistory(true)">
        </input-date>
        <input-select class="ml5" v-show="tab == 'Actividad'"
        :icon="mobile ? 'filter' : null" 
        v-model="historyType" @OnValueChanged="LoadHistory(true)" :items="[
            {text: 'Todas', value: 'ALL'},
            {text: 'Transacciones', value: 'TRANSACTION_'},
            {text: 'Pedidos Online', value: 'IAP'},
            {text: 'Turnos', value: 'TURN'},
            {text: 'Boletas (SII)', value: 'BOLETA'},
            //{text: 'Resumen de Ventas Diarias (SII)', value: 'RVD'},
            {text: 'Inventario', value: 'INVENTORY'},
            {text: 'Gastos', value: 'EXPENSE'},
            {text: 'Pagos Pendientes', value: 'PENDING_PAYMENT'},
            {text: 'Clientes', value: 'CUSTOMER'},
            {text: 'Cupones', value: 'COUPON'},
            {text: 'Mesas', value: 'TABLE'},
            {text: 'Menú', value: 'MENU'},
        ]"
        inputClass="secondary"
        :triggerFirstValueChanged="false"></input-select>

        <input-range v-model="range" 
        ref="range" 
        inputClass="secondary"
        :icon="mobile ? 'calendar' : null" 
        v-show="tab == 'Flujo de Caja'"
        @OnValueChanged="Search()"
        :triggerFirstValueChanged="false"></input-range>

        <i class="options icon-dots-vertical ml5" @click="Options([
            { text: 'Exportar', function: () => {ExportCashFlow()} },
        ])" v-show="tab == 'Flujo de Caja'"></i>
    </div>

    <div class="pa-body" slot="Dashboard">
        <!--<div class="secondary-text ta-center mt10" v-if="!dashboard">No hay datos disponibles</div>-->
        <div class="section" v-if="dashboard">
            <div class="mb10 flex align-center">
                <div class="f18 flex-fill" v-if="dashboard.modules.plan_status == 'UNPAID' && dashboard.modules.extra_time">
                    <div class="flex align-center cred"><i class="icon icon-circle-exclamation mr5"></i>Plan {{Util.Plans[dashboard.modules.plan].title}}</div>
                    <!--<div class="secondary-text">Se cambiará a Plan Gratis el {{Util.Date(dashboard.modules.extra_time, 'DD/MM/YYYY')}}</div>-->
                </div>
                <div class="f18 flex-fill" v-else-if="dashboard.modules.plan_status == 'PAID' && dashboard.modules.expires_soon">
                    <div class="flex align-center cyellow"><i class="icon icon-circle-exclamation mr5"></i>Plan {{Util.Plans[dashboard.modules.plan].title}}</div>
                    <!--<div class="secondary-text">La suscripción expira {{dashboard.modules.expires_soon}}</div>-->
                </div>
                <div class="f18 flex-fill" v-else-if="dashboard.modules.plan_status == 'UNPAID' && !dashboard.modules.extra_time">
                    <div class="flex align-center">Plan Gratis</div>
                    <!--<div class="secondary-text">Se cambió del Plan {{plans[dashboard.modules.plan].title}} por no pago</div>-->
                </div>
                <div v-else-if="dashboard.modules.plan_status == 'DEMO'" class="f18 flex-fill">
                    <div class="flex align-center">Periodo de Prueba</div>
                </div>
                <div v-else class="f18 flex-fill">
                    <div class="flex align-center">Plan {{Util.Plans[dashboard.modules.plan].title}}</div>
                </div>
                <!--
                <button class="secondary" @click="Util.Open('https://influyeapp.cl')">Ver Planes<span v-if="desktop" class="ml5"> y Módulos</span></button>
                -->
                <i class="options icon-question" @click="Util.Open('https://influyeapp.cl')" v-tooltip="'Ver planes y módulos'"></i>
                <div v-if="dashboard.modules.plan_status !== 'DEMO'" class="ml5">
                    <button :class="`${dashboard.modules.plan_status == 'UNPAID' || dashboard.modules.expires_soon ? 'primary purple' : 'secondary'}`" @click="Util.Open(`${Util.backend}subscription/options?code=${dashboard.key}`)">Renovar</button>
                </div>
            </div>

            <div class="hdvm" v-if="dashboard.modules.plan_status !== 'DEMO' || (dashboard.modules.plan_status == 'UNPAID' && !dashboard.modules.extra_time)">
                <div class="well flex align-center" v-if="Util.Plans[dashboard.modules.plan].max_transactions">
                    <avatar class="mr10" style="opacity: 0.5"
                    icon="receipt">
                    </avatar>
                    <div class="bold flex-fill">
                        <div>Transacciones</div>
                        <div class="secondary-text">{{Util.Number(dashboard.modules.transactions_current)}}/{{Util.Number(Util.Plans[dashboard.modules.plan].max_transactions)}}</div>
                    </div>
                    <div class="f18"><span>{{parseInt(100 * dashboard.modules.transactions_current / Util.Plans[dashboard.modules.plan].max_transactions)}}<span class="secondary-text">%</span></span></div>
                </div>

                <div class="well flex align-center" v-if="Util.Plans[dashboard.modules.plan].max_customers">
                    <avatar class="mr10" style="opacity: 0.5"
                    icon="user">
                    </avatar>
                    <div class="flex-fill">
                        <div class="bold">Clientes</div>
                        <div class="secondary-text">{{Util.Number(Math.min(dashboard.modules.customers_current, Util.Plans[dashboard.modules.plan].max_customers))}}/{{Util.Number(Util.Plans[dashboard.modules.plan].max_customers)}}</div>
                    </div>
                    <div class="f18"><span>{{parseInt(100 * dashboard.modules.customers_current / Util.Plans[dashboard.modules.plan].max_customers)}}<span class="secondary-text">%</span></span></div>
                </div>

                <div class="well flex align-center" v-if="Util.Plans[dashboard.modules.plan].max_accounts">
                    <avatar class="mr10" style="opacity: 0.5"
                    icon="staff">
                    </avatar>
                    <div class="flex-fill">
                        <div class="bold">Cuentas</div>
                        <div class="secondary-text">{{Util.Number(dashboard.modules.accounts_current)}}/{{Util.Number(Util.Plans[dashboard.modules.plan].max_accounts)}}</div>
                    </div>
                    <div class="f18"><span>{{parseInt(100 * dashboard.modules.accounts_current / Util.Plans[dashboard.modules.plan].max_accounts)}}<span class="secondary-text">%</span></span></div>
                </div>
            </div>

            <div class="hdvm mt10">
                <div class="well flex column">
                    <div class="bold">Módulos</div>
                    <div :class="`modules-container- ${dashboard.modules.plan_status == 'UNPAID' && !dashboard.modules.extra_time ? 'line-through' : ''}`">
                        <div class="secondary-text" v-if="dashboard.modules.module_store_plus" v-show="!Util.Plans[dashboard.modules.plan].version">Influye Store Plus</div>
                        <div class="secondary-text" v-else-if="dashboard.modules.module_store" v-show="!Util.Plans[dashboard.modules.plan].version">Influye Store</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_service" v-show="!Util.Plans[dashboard.modules.plan].version">Influye Service</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_sii_boleta">SII Boleta Electrónica</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_sii_factura">SII Factura Electrónica</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_virtual_menu">Menú Virtual</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_expenses" v-show="!Util.Plans[dashboard.modules.plan].version">Control de Gastos</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_inventory">Control de Inventario</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_reports" v-show="!Util.Plans[dashboard.modules.plan].version">Reportes y Alertas</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_transaction_stats" v-show="!Util.Plans[dashboard.modules.plan].version">Estadísticas Avanzadas</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_barcode" v-show="!Util.Plans[dashboard.modules.plan].version">Lector Código de Barra</div>
                        <div class="secondary-text" v-if="dashboard.modules.module_pya_courier">PedidosYa Courier</div>
                    </div>
                    
                    <!--
                    <div class="flex-fill"></div>
                    <button class="secondary mt10">Agregar Módulo</button>
                    -->

                </div>
                <div class="flex column" v-if="dashboard.modules.plan_status !== 'DEMO'">
                    <div class="well flex align-center">
                        <avatar class="mr10" style="opacity: 0.5"
                        icon="money">
                        </avatar>
                        <div class="bold flex-fill">Total <span v-if="dashboard.modules.plan_status == 'UNPAID' && !dashboard.modules.extra_time">del Plan anterior</span></div>
                        <div class="f18">{{Util.Number(dashboard.modules.plan_price)}} UF</div>
                    </div>
                    
                    <div v-if="dashboard.modules.plan_expiration" class="flex-fill flex column">

                        <div :class="`well flex align-center mt10 ${dashboard.modules.plan_status == 'UNPAID' ? `cred` : ''}`">
                            <avatar class="mr10" style="opacity: 0.5"
                            icon="calendar">
                            </avatar>
                            <div class="flex-fill">
                                <div class="bold">Facturación</div>
                                <div class="secondary-text">Próxima fecha de pago</div>
                            </div>
                            <div class="f18">{{Util.Date(dashboard.modules.plan_expiration, 'DD/MM/YYYY')}}</div>
                        </div>

                        <div class="ml20 cred" v-if="dashboard.modules.extra_time">
                            <div class="bold">Periodo de gracia</div>
                            <div><i class="icon icon-circle-exclamation mr5"></i>{{Util.Date(dashboard.modules.extra_time, 'DD/MM/YYYY')}}</div>
                        </div>

                        <!--
                        <div class="flex-fill"></div>
                        <button class="primary purple mt10" v-if="dashboard.modules.plan_status == 'UNPAID' && !dashboard.modules.extra_time" @click="OpenPlan()">Reactivar Plan</button>
                        <button :class="`mt10 ${dashboard.modules.expires_soon || dashboard.modules.extra_time ? 'primary purple' : 'secondary'}`" v-else @click="OpenPlan()">Renovar Plan</button>
                        -->
                    </div>
                </div>
                <div class="flex column" v-else>
                    <div :class="`well flex align-center`">
                        <avatar class="mr10" style="opacity: 0.5"
                        icon="calendar">
                        </avatar>
                        <div class="flex-fill">
                            <div class="bold">Duración</div>
                            <div class="secondary-text">Fin del periodo de prueba</div>
                        </div>
                        <div class="f18">{{Util.Date(dashboard.modules.plan_expiration, 'DD/MM/YYYY')}}</div>
                    </div>
                </div>

            </div>

        </div>

        <div v-if="dashboard">
            
            <div class="section flex align-center mt10 cyellow pa15" v-if="dashboard.modules.plan_status == 'UNPAID' && dashboard.modules.extra_time"><i class="icon icon-circle-exclamation mr10"></i> Se cambiará a Plan Gratis el {{Util.Date(dashboard.modules.extra_time, 'DD/MM/YYYY')}}</div>
            <div class="section flex align-center mt10 cyellow pa15" v-else-if="dashboard.modules.plan_status == 'PAID' && dashboard.modules.expires_soon"><i class="icon icon-circle-exclamation mr10"></i> La suscripción expira {{dashboard.modules.expires_soon}}</div>
            <div class="section flex align-center mt10 cred pa15" v-else-if="dashboard.modules.plan_status == 'UNPAID' && !dashboard.modules.extra_time"><i class="icon icon-circle-exclamation mr10"></i> Se cambió del Plan {{Util.Plans[dashboard.modules.plan].title}} por no pago</div>
            <div :class="`section flex align-center mt10 ${warning.type == 'error' ? 'cred' : 'cyellow'} pa15`"  v-for="warning in dashboard.warnings" :key="warning.message"><i :class="`icon icon-${warning.type == 'error' ? 'circle-exclamation' : 'circle-exclamation'} mr10`"></i>{{warning.message}}</div>
            
            <div class="f18 mb20 mt20">General</div>
        </div>

        
        <div class="hdvm mb20" v-if="dashboard">

            <div>
            <div class="section selectable" v-tooltip="pendingTransactions.details" @click="Info(pendingTransactions.details)">
                <div class="flex align-center">
                    <avatar icon="bag" :color="Util.baseColors.yellow" class="mr10"></avatar>
                    <!--<i class="icon icon-bag mr10 cyellow" style="font-size: 26px"></i>-->
                    <div class="flex-fill">
                        <div class="bold">Pedidos</div>
                        <div class="secondary-text">Pendiente {{Util.Price(pendingTransactions.total)}}</div>
                    </div>
                    <div class="bold f18">{{dashboard.active.transactions.length}}</div>
                </div>
            </div>
            </div>

            <div>
            <div class="section">
                <div class="flex align-center">
                    <!--<i class="icon icon-chronometer mr10 cpink" style="font-size: 26px"></i>-->
                    <avatar icon="chronometer" :color="Util.baseColors.pink" class="mr10"></avatar>
                    <div class="flex-fill">
                        <div class="bold">Turnos</div>
                        <div class="secondary-text">Activos</div>
                    </div>
                    <div class="bold f18">{{dashboard.active.turns}}</div>
                </div>
            </div>
            </div>

            <div>
            <div class="section selectable" v-tooltip="connectedAccounts" @click="Info(connectedAccounts)">
                <div class="flex align-center">
                    <!--<i class="icon icon-user mr10 cblue" style="font-size: 26px"></i>-->
                    <avatar icon="user" :color="Util.baseColors.blue" class="mr10"></avatar>
                    <div class="flex-fill">
                        <div class="bold">Cuentas</div>
                        <div class="secondary-text">Conectadas</div>
                    </div>
                    <div class="bold f18">{{dashboard.active.accounts.length}}</div>
                </div>
            </div>
            </div>


        </div>

        <!--
        <div class="hdvm mb20" v-if="dashboard">

            <div>
            <div class="section selectable" v-tooltip="'Ir a Pedidos'">
                <div class="flex align-center">
                    <i class="icon icon-bag mr10 cyellow" style="font-size: 26px"></i>
                    <div class="flex-fill">
                        <div class="bold">Pedidos Online</div>
                        <div class="secondary-text">Pendientes {{dashboard.active.transactions.length}}</div>
                    </div>
                    <div class="bold" style="font-size: 20px">{{dashboard.active.transactions.length}}</div>
                </div>
            </div>
            </div>

            <div>
            <div class="section selectable" v-tooltip="'Ir a Turnos'">
                <div class="flex align-center">
                    <i class="icon icon-chronometer mr10 cpink" style="font-size: 26px"></i>
                    <div class="flex-fill">
                        <div class="bold">Turnos</div>
                        <div class="secondary-text">Activos</div>
                    </div>
                    <div class="bold" style="font-size: 20px">{{dashboard.active.turns}}</div>
                </div>
            </div>
            </div>

            <div>
            <div class="section selectable" v-tooltip="'Ver cuentas conectadas'">
                <div class="flex align-center">
                    <i class="icon icon-user mr10 cblue" style="font-size: 26px"></i>
                    <div class="flex-fill">
                        <div class="bold">Clientes</div>
                        <div class="secondary-text">Nuevos</div>
                    </div>
                    <div class="bold f18">{{dashboard.active.accounts.length}}</div>
                </div>
            </div>
            </div>

        </div>
        -->

        <div v-if="dashboard" >

            <div class="flex align-center mb20">
                <div class="f18 flex-fill">Resumen</div>
                <input-select
                inputClass="secondary"
                v-model="summary"
                :items="[{text: 'Hoy', value: 'today'}, {text: 'Ayer', value: 'yesterday'}, {text: 'Últimos 7 días', value: 'weekly'}, {text: 'Últimos 30 días', value: 'monthly'}]">
                </input-select>
            </div>

            <div class="hdvm mb10">
                <div class="">
                    <div class="section flex align-center h100">
                        <!--<i class="icon icon-right-arrow mr10 cgreen" style="font-size: 26px; transform: rotate(-45deg)"></i>-->
                        <avatar icon="arrow-ne" :color="Util.baseColors.green" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Ingresos</div>
                            <div class="secondary-text">{{Util.Number(dashboard[summary].total_transactions)}} transacciones</div>
                        </div>
                        <div class="bold cgreen f18">{{Util.Price(dashboard[summary].total_earnings)}}</div>
                    </div>
                </div>
                <div class="" v-if="Local.type=='LOCAL' && (Local.modules.module_expenses || Local.modules.module_inventory)">
                    <div class="section flex align-center h100">
                        <!--<i class="icon icon-right-arrow mr10 cred" style="font-size: 26px; transform: rotate(45deg)"></i>-->
                        <avatar icon="arrow-se" :color="Util.baseColors.red" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold">Egresos</div>
                        </div>
                        <div class="bold cred f18">{{Util.Price(dashboard[summary].total_expense)}}</div>
                    </div>
                </div>

                <div v-if="Local.type == 'LOCAL'" :class="` ${dashboard[summary].total_earnings - dashboard[summary].total_expense < 0 ? 'cred' : 'cgreen'}`">
                    <div class="section flex align-center h100">
                        <!--<i class="icon icon-balance mr10 cgreen" style="font-size: 26px"></i>-->
                        <avatar icon="balance" :color="dashboard[summary].total_earnings - dashboard[summary].total_expense < 0 ? Util.baseColors.red : Util.baseColors.green" class="mr10"></avatar>
                        <div class="flex-fill">
                            <div class="bold fc">Balance</div>
                        </div>
                        <div class="bold f18">{{Util.Price(dashboard[summary].total_earnings - dashboard[summary].total_expense)}}</div>
                    </div>
                </div>

            </div>


            <div class="section mb20">

                <!--
                <div class="input-style">
                    <div class="input-label">Clientes (Influye)</div>
                    <div>{{Util.Number(dashboard[summary].customers)}}</div>
                </div>
                -->

                <chart v-if="dashboard[summary].sublocals && Local.type == 'FRANCHISE' && dashboard[summary].sublocals.length"
                type="pie"
                class="mb20 mt20"
                :data="[dashboard[summary].sublocals]"
                dataset="total_money"
                :label="(data) => {return data.data}"
                :singleDataset="false"
                :maxDatasets="7"
                :showLegend="true"
                :xLabelFormat="null"
                :yLabelFormat="null"
                :tooltip="(value, label, index) => {return `${label}: ${Util.Price(value)}`}">
                </chart>

                <chart v-if="dashboard[summary].history.length > 1"
                class="mt20 mb20"
                type="line"
                :data="[dashboard[summary].history]"
                dataset="total_money"
                :label="(data) => {return data.data}"
                :yLabelFormat="(value) => {return Util.Price(value, true)}"
                :tooltip="(value, label, index) => {return `${Util.Price(value)}`}"
                :customColor="[Util.Chart.purpleGradient]">
                </chart>
                
            </div>


            <div v-if="Local.type == 'LOCAL' && dashboard.monthly" >
                <div class="flex align-center mb20">
                    <div class="f18 flex-fill">Flujo de Caja</div>
                    <button class="secondary" @click="$refs.tabs.ChangeTab(1)">Ver más</button>
                </div>

                <div class="section mb20">

                    <chart class="mt20"
                    type="bar"
                    :data="[dashboard.monthly.balance.earnings, dashboard.monthly.balance.expenses]"
                    :dataset="null"
                    :label="dashboard.monthly.balance.data"
                    :singleDataset="!(Local.type == 'LOCAL')"
                    :xLabelFormat="(val) => {return val}"
                    :yLabelFormat="(value) => {return Util.Price(value, true)}"
                    :tooltip="(value, label, index) => {return `${Util.Price(value)}`}"
                    :customColor="[Util.Chart.purple, Util.Chart.yellow]">
                    </chart>
                    
                </div>
            </div>

            <div class="mb20">
                <div class="f18">Tendencias</div>
                <div class="secondary-text">Últimos 30 días</div>
            </div>

            <div class="hdvm mb20" v-if="dashboard.trends">
                <div>
                    <div class="section h100">
                        <div class="f18 mb20">Items más Vendidos</div>
                        <div class="entry flex align-center" v-for="item in dashboard.trends.items" :key="item.data">
                            <avatar class="mr10"
                            :image="`products/${itemsImages[item.data]}`">
                            </avatar>
                            <div>{{item.data}}</div>
                        </div>
                        <!--
                        <chart 
                        :data="[dashboard.trends.items]"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return data.data}"
                        :singleDataset="false"
                        :showLegend="true"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label}`}">
                        </chart>
                        -->
                    </div>
                </div>
                <div>
                    <div class="section h100">
                        <div class="f18 mb20">Secciones más Vendidas</div>
                        <div class="entry flex align-center" v-for="section in dashboard.trends.sections" :key="section.data">
                            <avatar class="mr10"
                            :imageIcon="section.data"
                            defaultImageIcon="546">
                            </avatar>
                            <div>{{section.data}}</div>
                        </div>
                        <!--
                        <chart 
                        :data="[dashboard.trends.sections]"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return data.data}"
                        :singleDataset="false"
                        :showLegend="true"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label}`}">
                        </chart>
                        -->
                    </div>
                </div>
                <!--
                <div>
                    <div class="section">
                        <div class="f18">Top Clientes</div>
                        <chart 
                        :data="[dashboard.trends.clients]"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return data.data}"
                        :singleDataset="false"
                        :showLegend="true"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label}`}">
                        </chart>
                    </div>
                </div>
                -->
            </div>

            <div class="hdvm mb20" v-if="dashboard.trends">
                <div>
                    <div class="section h100">
                        <div class="f18">Top Tipo de Pedido</div>
                        <chart 
                        width="100px"
                        :data="[dashboard.trends.sale]"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return Util.Keywords[data.data] ? Util.Keywords[data.data].label : data.data}"
                        :singleDataset="false"
                        showLegend="auto"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label}`}"
                        :customColor="[null, null]"
                        :keyColors="Util.Chart.orderType">
                        </chart>
                    </div>
                </div>
                <div>
                    <div class="section h100">
                        <div class="f18">Top Método de Pago</div>
                        <chart 
                        :data="[dashboard.trends.pay_methods]"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return Util.Keywords[data.data] ? Util.Keywords[data.data].label : data.data}"
                        :singleDataset="false"
                        showLegend="auto"
                        defaultLegendIcon="59"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label}`}">
                        </chart>
                    </div>
                </div>
                <div>
                    <div class="section h100">
                        <div class="f18">Top Medio de Venta</div>
                        <chart 
                        :data="[dashboard.trends.medium]"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return Util.Keywords[data.data] ? Util.Keywords[data.data].label : data.data}"
                        :singleDataset="false"
                        showLegend="auto"
                        defaultLegendIcon="59"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label}`}"
                        :customColor="[null, null]"
                        :keyColors="Util.Chart.saleType">
                        </chart>
                    </div>
                </div>
            </div>

            <div class="hdvm mb20" v-if="dashboard.trends">
                <div>
                    <div class="section">
                        <div class="f18">Horas Punta</div>
                        <chart 
                        :data="[dashboard.trends.hours]"
                        dataset="total_money"
                        type="bar"
                        :label="(data) => {return data.data+' hrs'}"
                        :singleDataset="false"
                        :showLegend="false"
                        :xLabelFormat="(data) => {return data}"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label}`}"
                        :customColor="[Util.baseColors.purple, Util.baseColors.pink]">
                        </chart>
                    </div>
                </div>
                <div>
                    <div class="section">
                        <div class="f18">Días Punta</div>
                        <chart 
                        :data="[dashboard.trends.days]"
                        dataset="total_money"
                        type="bar"
                        :label="(data) => {return daysOfWeek[data.data]}"
                        :singleDataset="false"
                        :showLegend="false"
                        :xLabelFormat="(data) => {return data}"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return label}"
                        :customColor="[Util.baseColors.purple, Util.baseColors.pink]">
                        </chart>
                    </div>
                </div>
                <!--
                <div>
                    <div class="section">
                        <div class="f18">Top Día</div>
                        <chart 
                        :data="[dashboard.trends.days]"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return data.data}"
                        :singleDataset="false"
                        :showLegend="true"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label}`}">
                        </chart>
                    </div>
                </div>
                -->
            </div>



        </div>
    </div>

    <div slot="Flujo de Caja" class="pa-body">
        <div v-if="!data" class="secondary-text ta-center mt10">No hay datos</div>
        <div v-else>
            <div class="">
                <div class="f18 mb20">Balance</div>
                <div class="hdvm">
                    <div>
                        <div class="section flex align-center">
                            <avatar class="mr10"
                            icon="arrow-ne"
                            :color="Util.baseColors.green">
                            </avatar>
                            <div class="flex-fill">
                                <div class="bold">Ingresos</div>
                                <div class="secondary-text">{{Util.Number(data.total_transactions)}} transacciones</div>
                            </div>
                            <div class="f18 cgreen">{{Util.Price(data.income)}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="section flex align-center">
                            <avatar class="mr10"
                            icon="arrow-se"
                            :color="Util.baseColors.red">
                            </avatar>
                            <div class="bold flex-fill">Egresos</div>
                            <div class="f18 cred">{{Util.Price(data.total_expenses)}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="section flex align-center">
                            <avatar class="mr10"
                            icon="balance"
                            :color="data.income - data.total_expenses > 0 ? Util.baseColors.green : Util.baseColors.red">
                            </avatar>
                            <div class="bold flex-fill">Balance</div>
                            <div :class="`f18 ${data.income - data.total_expenses > 0 ? 'cgreen' : 'cred'}`">{{Util.Price(data.income - data.total_expenses)}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="hdvm">
                    <div v-if="data.pending_payment.transactions">
                        <div class="section flex align-center mt10">
                            <avatar class="mr10"
                            icon="hourglass"
                            :color="Util.baseColors.yellow">
                            </avatar>
                            <div class="flex-fill">
                                <div class="bold">Pagos Pendientes</div>
                                <div class="secondary-text">{{ data.pending_payment.transactions }} Transacciones</div>
                            </div>
                            <div class="f18 cyellow">{{Util.Price(data.pending_payment.total)}}</div>
                        </div>
                    </div>
                    <div v-if="data.external">
                        <div class="section flex align-center mt10" v-tooltip="'No corresponden a ingresos ni egresos dentro del flujo de caja'">
                            <avatar class="mr10"
                            icon="minus"
                            :color="Util.baseColors.yellow">
                            </avatar>
                            <div class="flex-fill">
                                <div class="bold">No contabilizados</div>
                                <div class="secondary-text">Movimientos externos</div>
                            </div>
                            <div class="f18 cyellow">{{Util.Price(data.external)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="mt20"  v-if="data.pay_methods.length">
                <div class="f18 mb20">Ingresos</div>
                <div class="section mb10 flex align-center"
                v-for="(method, index) in data.pay_methods"
                :key="index+method.data">
                    <avatar class="mr10"
                    :color="Util.baseColors.green"
                    :imageIcon="method.data"
                    defaultImageIcon="8">
                    </avatar>
                    <div class="bold flex-fill">{{Util.PayMethod(method.data)}}</div>
                    <div class="bold">{{Util.Price(method.total_money)}}</div>
                </div>
            </div>

            <div class="mt20" v-if="data.expenses.length">
                <div class="f18 mb20">Egresos</div>

                <div class="section mb10 flex align-center"
                v-for="(method, index) in data.expenses_pay_methods"
                :key="index+method.data">
                    <avatar class="mr10"
                    :color="Util.baseColors.red"
                    :imageIcon="method.data"
                    defaultImageIcon="8">
                    </avatar>
                    <div class="bold flex-fill">{{Util.PayMethod(method.data)}}</div>
                    <div class="bold">{{Util.Price(method.total_money)}}</div>
                </div>

                <div class="separator mt20 mb20"></div>

                <div class="section mb10 flex align-center"
                v-for="(expense, index) in data.expenses"
                :key="index+expense.data">
                    <avatar class="mr10"
                    :color="Util.baseColors.red"
                    :imageIcon="expense.data"
                    defaultImageIcon="1727">
                    </avatar>
                    <div class="bold flex-fill">{{expense.data}}</div>
                    <div class="bold">{{Util.Price(expense.total_money)}}</div>
                </div>
            </div>
        </div>
    </div>

     <vertical-content ref="verticalContent" slot="Actividad" @OnBottom="LoadHistory()" class="pa-body">
        <div v-if="history && history.length" class="">
            <div :class="`section mb10 ${logTypes[log.type] && log.id_reference ? 'selectable' : ''}`"
            v-for="(log, index) in history"
            :key="index"
            @click="OpenHistory(log)">
                <div class="flex fill align-center">
                    <!--<img v-show="desktop" height="35" :src="LogIcon(log.type)" class="mr15">-->
                    <avatar class="mr10"
                    :icon="Util.LogTypes[log.type] ? Util.LogTypes[log.type].icon : 'circle-exclamation'"
                    :secondaryIcon="Util.LogTypes[log.type] ? Util.LogTypes[log.type].secondary : ''"
                    :color="Util.LogTypes[log.type] ? Util.LogTypes[log.type].color : ''">
                    </avatar>

                    <div class="flex column flex-fill">
                        <div class="bold">{{Util.Date(log.date, 'LLLL')}}</div>
                        <div class="secondary-text">{{log.title}}</div>
                    </div>
                    <!--<i v-if="logTypes[log.type] && log.id_reference" class="icon icon-arrow-right"></i>-->
                </div>
            </div>
        </div>
        <div class="secondary-text ta-center mt10" v-else>No hay datos.</div>
    </vertical-content>

    <transaction-details ref="transactionDetails"></transaction-details>
    <transactions-list ref="transactionsList"></transactions-list>
    <turn-details ref="turnDetails"></turn-details>
    <expense-details ref="expenseDetails"></expense-details>
    <inventory-details ref="inventoryDetails"></inventory-details>
    <pending-payment-details ref="pendingPaymentDetails"></pending-payment-details>
    <iap-details ref="iapDetails"></iap-details>
    <invoice-details ref="invoiceDetails"></invoice-details>

    <modal v-model="modalPlan" dir="center" modalClass="sm" :title="modalPlan ? `${modalPlan.type == 'RENEWAL' ? 'Renovar' : 'Reactivar'} Plan ${Util.Number(modalPlan.plan)} UF` : ''">
        <div class="pa20" v-if="modalPlan">

            <div :class="`well selectable flex align-center mb10 ${modalPlan.months == option.months ? 'selected' : ''}`" @click="$set(modalPlan, 'months', option.months)"
            v-for="option in modalPlan.options" :key="option.months">
                <div class="flex-fill">
                    <div class="bold">{{option.label}} <span v-if="option.discount" class="secondary-text red thin">{{option.discount}}% DCTO</span></div>
                    <div class="secondary-text" v-if="modalPlan.type == 'REACTIVATION'">Licencia del {{Util.Date(option.start, 'DD/MM/YYYY')}} al {{Util.Date(option.finish, 'DD/MM/YYYY')}}</div>
                    <div class="secondary-text" v-else>Licencia hasta el {{Util.Date(option.finish, 'DD/MM/YYYY')}}</div>
                </div>
                <div class="ta-right">
                    <div class="secondary-text line-through" v-if="option.discount">{{Util.Price(option.subtotal)}}</div>
                    <div class="bold">{{Util.Price(option.total)}}</div>
                </div>
            </div>

            <div class="secondary-text">*Valor UF: {{Util.Price(modalPlan.uf)}}</div>
            <div class="secondary-text">*IVA incluido</div>

        </div>
        <button class="primary purple" slot="actions" @click="Util.Open(`${Util.backend}subscription?code=${dashboard.key}&months=${modalPlan.months}`)">Ir al pago</button>
    </modal>

</tabs>
</template>
<script>
export default {
    data() {
        return {
            dashboard: null,
            tab: null,
            summary: 'today',
            trends: [],
            daysOfWeek: ['Dom', 'Lun', 'Mar', 'Míe', 'Jue', 'Vie', 'Sáb'],
            //activity
            history: [],
            historyType: 'ALL',
            historyDate: null,
            modalPlan: null,
            logTypes: {
                TRANSACTION_CREATED: true,
                TRANSACTION_EDIT: true,
                TRANSACTION_DELIVERY: true,
                TRANSACTION_COMPLETED: true,
                TRANSACTION_CANCELLED: true,
                TRANSACTION_DELETED: true,
                EXPENSE_ADDED: true,
                INVENTORY_ADDED: true,
                INVENTORY_REMOVED: true,
                PENDING_PAYMENT_COMPLETED: true,
                TURN_STARTED: true,
                TURN_ENDED: true,
                IAP_ACCEPTED: true,
                IAP_DISCOUNT: true,
                IAP_REJECTED: true,
                BOLETA_CREATED: true,
                BOLETA_OK: true,
                BOLETA_ERROR_SENT: true,
                BOLETA_RECEIVED: true,
                BOLETA_FAILED: true,
                INVOICE_COMPLETED: true,
                INVOICE_CREATED: true,
                INVOICE_DELETED: true,
                SCHEDULED_DATE_EDIT: true,
            },
            //balance
            range: {
                option: 'MONTH',
                index: 0,
            },
            data: null,
            itemsImages: {}
        }
    },
    mounted() {
        this.Loading();
        axios.get(`/dashboard/${this.Local.id}`).then(res => {
            console.log(res.data);
            this.Response(res);
            this.dashboard = res.data;
            this.trends.push({value: 'items', title: 'Top Items', label: (a) => {return a}});
            this.trends.push({value: 'sections', title: 'Top Secciones del Menú', label: (a) => {return a}});
            this.trends.push({value: 'sale', title: 'Top Venta', label: (a) => {return Util.SaleType[a]}});
            this.trends.push({value: 'pay_methods', title: 'Top Métodos de Pago', label: (a) => {return this.Util.PayMethod(a)}});
            this.trends.push({value: 'medium', title: 'Top Venta por Medio de Venta', label: (a) => {return this.Util.TransactionSource[a].title}});
            this.trends.push({value: 'days', title: 'Top Venta por Día de la semana', label: (a) => {return this.daysOfWeek[parseInt(a)]}});
            this.trends.push({value: 'hours', title: 'Top Venta por Hora', label: (a) => {return (a.length == 1 ? '0'+a : a)+':00 hrs'}});
            this.trends.push({value: 'clients', title: 'Top Clientes', label: (a) => {return a}});
        }).catch(err => {
            this.Response({});
        });

        axios.get(`/menu/images/${this.Local.id}/cache`).then(res => {
            console.log(res.data);
            this.itemsImages = res.data;
        }).catch(err => {

        });

    },
    methods: {
        HistoryLabel(data, type)
        {
            if(type == 'monthly') return this.Util.Date(data, 'D MMM');
            if(type == 'weekly') return this.Util.Date(data, 'dd');
            return `${this.Util.Date(data, 'HH')} hrs`;
        },
        LoadHistory(reset) {
            console.log('LOAD HISTORY');
            console.log(this.historyDate);
            if(reset) 
            {
                this.history = [];
                this.$refs.verticalContent.lastBottom = 0;
            }

            this.Loading();
            this.$nextTick(() => {
                axios.get(`/activity/${this.Local.id}/history/${this.historyType}/${this.historyDate}?skip=${this.history.length}`).then(res => {
                    this.Response(res);
                    this.history = this.history.concat(res.data);
                }).catch(err => {
                    this.Response(err);
                });
            });
            
        },
        OpenHistory(log) {
            if(log.type.indexOf('TRANSACTION') >= 0)
                this.$refs.transactionDetails.Open(log.id_reference);
            else if(log.type.indexOf('TURN') >= 0)
                this.$refs.turnDetails.Open(log.id_reference);
            else if(log.type.indexOf('EXPENSE') >= 0)
                this.$refs.expenseDetails.Open(log.id_reference);
            else if(log.type.indexOf('INVENTORY') >= 0)
                this.$refs.inventoryDetails.Open(log.id_reference);
            else if(log.type.indexOf('PENDING_PAYMENT') >= 0)
                this.$refs.pendingPaymentDetails.Open(log.id_reference);
            else if(log.type.indexOf('BOLETA') >= 0)
                this.$refs.transactionDetails.Open(log.id_reference);
            else if(log.type.indexOf('IAP') >= 0)
                this.$refs.iapDetails.Open(log.id_reference);
            else if(log.type.indexOf('SCHEDULED') >= 0)
                this.$refs.iapDetails.Open(log.id_reference);
            else if(log.type.indexOf('INVOICE') >= 0)
                this.$refs.invoiceDetails.Open(log.id_reference, log.type == 'INVOICE_COMPLETED');
        },
        Search() {
            this.Loading();
            axios.post(`/balance/${this.Local.id}`, this.range).then(res => {
                this.Response(res);
                this.data = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenPlan()
        {
            this.Loading();
            axios.get(`/local/${this.Local.id}/subscription`).then(res => {
                this.Response(res);
                res.data.months = 1;
                this.modalPlan = res.data;
                console.log(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        ExportCashFlow(){
            this.Loading();
            axios.post(`/cashflow/${this.Local.id}/export`, this.range, {responseType: 'blob'})
            .then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `flujo_caja_${Util.Today().date.replace(/\//g, '-')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        }
    },
    computed: {
        pendingTransactions() {

            var ret = {
                total: 0,
                details: '',
            }

            if(!this.dashboard || !this.dashboard.active || !this.dashboard.active.transactions) return ret;

            var details = {};


            this.dashboard.active.transactions.forEach(transaction => {
                ret.total += transaction.total_money;
                var type = this.Util.TransactionType(transaction, true);
                if(!details[type]) details[type] = {count: 0, total: 0};
                details[type].count++;
                details[type].total += transaction.total_money;
            });

            var str = '<ul>';
            for(var key in details)
            {
                var data = details[key];
                str += `<li>${this.Util.Number(data.count)} pedidos ${key}: ${this.Util.Price(data.total)}</li>`
            }
            str += '</ul>';
            ret.details = str;

            return ret;
        },
        connectedAccounts() 
        {
            if(!this.dashboard || !this.dashboard.active || !this.dashboard.active.accounts) return '';
            var ret = '<ul>';
            this.dashboard.active.accounts.forEach(account => {
                ret += `<li>${account.name} (${this.Util.AccountType(account).label})</li>`;
            })
            ret += '</ul>';
            return ret;
        }
    }
}
</script>
<style lang="scss">
.modules-container {
    display: flex;
    flex-wrap: wrap;
    div {
        &::after {
            content: "•";
            margin: 0px 4px;
        }
    }
    div:last-child {
        &::after {
            content: "";
        }
    }
}
</style>