<template>
<div>
<modal v-model="modal" dir="right" :modalClass="['fill', revision ? 'right xl h100' : 'right md h100']" :title="`${mobile ? '' : 'Transacción '}#${transaction ? Util.Zeropad(transaction.id_ticket, 10) : ''}`" hideActions>
	
	<i v-if="canOptions && (isAdmin || (Auth.restrictions && Auth.restrictions.can_reprint))" class="options icon-printing ml5" slot="options" @click="VoucherOptions(transaction)"></i>
	<i v-if="canOptions" class="options icon-dots-vertical ml5" slot="options" @click="Options([
		{text: 'Eliminar Transacción', class: 'error-color', if: canDelete && (Auth.role !== 'local_admin' || (Auth.role == 'local_admin' && !!Auth.restrictions_admin.can_delete_transactions)), function: () => ClickDeleteTransaction()},
		{text: 'Editar Medio de Pago', class: 'error-color', function: () => {OpenEditPayment(transaction)}, if: canDelete && transaction.status == 'COMPLETED'},
		{text: 'Rembolsar', class: 'error-color', if: canRefund, function: RefundTransaction, confirm: `Se generará un gasto por ${Util.Price(transaction.total_money)} ¿Rembolsar transacción?`},
		{text: 'Cancelar Boleta Electrónica', class: 'error-color', confirm: `Se emitirá una nota de crédito, ¿Desea confirmar?`, function: () => {sendNotaCredito()}, if: !!Local.modules.module_sii_boleta && !!Local.modules.module_sii_factura && !!transaction.id_boleta && transaction.custom_data.boleta.status == 'OK' && !transaction.custom_data.nota_credito},
		{text: 'Liquidar Pago Pendiente', function: () => {OpenPayPending()}, if: !!transaction.pending_payment && Local.type == 'LOCAL'},
		{text: 'Generar Voucher Digital', function: () => {ViewDigitalVoucher()}},
		{text: 'Emitir Boleta Electrónica', function: () => {SendBoleta()}, if: !transaction.id_boleta && !transaction.id_factura && !!Local.modules.module_sii_boleta},
		{text: 'Enviar Boleta Electrónica', function: () => {OpenModalSendBoleta()}, if: !!Local.modules.module_sii_boleta && !!transaction.id_boleta },
		{text: 'Enviar Nota de Crédito Electrónica', function: () => {OpenmodalSendDte(61)}, if: !!Local.modules.module_sii_factura && !!transaction.id_nota_credito && !!transaction.id_boleta},
		{text: 'Emitir Factura Electrónica', function: () => {SendFactura()}, if: !transaction.custom_data.factura && !!Local.modules.module_sii_factura && !transaction.id_boleta && (Auth.role.indexOf('admin') > -1 || !!Auth.restrictions.can_create_facturas)},
		{text: 'Enviar Factura Electrónica', function: () => {OpenmodalSendDte()}, if: !!Local.modules.module_sii_factura && !!transaction.id_factura && !transaction.id_boleta },
	])"></i>

	<div class="hdvm ofy copyable">
		<div v-for="(t, index) in previews" :key="index" class="fill ofy pa20" :style="mobile && index == 0 && previews.length > 1 ? {'border-bottom': '2px solid var(--gray-2)'} : {}">
		
			<!-- revisions -->
			<div class="bgbg1 bb mb20 pb20 pt20" v-if="index == 1" :style="desktop ? {position: 'sticky', top: '-20px', 'z-index': 1, 'margin-top': '-20px'} : {}">
				<div class="flex">
					<input-select v-model="revision" :items="revisions" class="flex-fill" inputClass="secondary"></input-select>
					<i class="options icon-cross ml5" @click="revisions=[];revision=null"></i>
				</div>
			</div>

			<div class="" v-if="index == 1 && revision">
				<div class="bold">Revisión</div>
				<div class="mb15 flex column">
					<div>Editada el {{Util.Date(revision.edit_at, 'LLLL')}} por {{revision.edit_by}}</div>
					<div class="secondary-text" v-if="revision.event">Esta revisión se creó debido a que {{Util.TransactionRevisionsEvents[revision.event] ? Util.TransactionRevisionsEvents[revision.event].toLowerCase() : revision.event}}</div>
				</div>

				<div class="" v-if="revision.comment">
					<div class="bold">Comentario</div>
					<div>{{revision.comment}}</div>
				</div>
			</div>

			<div class="">
				<!-- Log -->
				<div v-if="index == 0">

					<div class="f18 mb20" v-tooltip="Auth.role == 'admin' ? `ID: ${t.id}` : ''">Historial</div>

					<div class="mb15">
						<div class="bold">{{Util.Date(t.created_at, 'LLLL')}}</div>
						<div>Transacción ingresada por {{t.custom_data.created_by}} <span v-if="t.paid_at == t.created_at">(Pagada)</span></div>
						<div class="secondary-text" v-if="t.custom_data.real_creation_date">Transacción creada el {{Util.Date(t.custom_data.real_creation_date, 'LLLL')}} con fecha pasada</div>
					</div>

					<div class="mb15" v-if="t.custom_data.preparation_at">
						<div class="bold">{{Util.Date(t.custom_data.preparation_at, 'LLLL')}}</div>
						<div>Pedido empezó a prepararse</div>
					</div>

					<div class="mb15" v-if="t.custom_data.takeaway_ready && t.custom_data.takeaway_ready !== true">
						<div class="bold">{{Util.Date(t.custom_data.takeaway_ready, 'LLLL')}}</div>
						<div>Pedido listo para retirar</div>
					</div>
					
					<div class="mb15" v-if="t.delivery_at">
						<div class="bold">{{Util.Date(t.delivery_at, 'LLLL')}}</div>
						<div>Transacción enviada por {{t.custom_data.delivery_by}}</div>
						<!--<div>Transacción enviada por {{ticket.delivery.account.name}}</div>-->
					</div>

					<div class="mb15" v-if="t.custom_data.delivered_at">
						<div class="bold">{{Util.Date(t.custom_data.delivered_at, 'LLLL')}}</div>
						<div>Transacción marcada como entregada por {{t.custom_data.delivered_by}}</div>
					</div>
					
					<div class="mb15 cgreen" v-if="t.completed_at">
						<div class="bold">{{Util.Date(t.completed_at, 'LLLL')}}</div>
						<div>Transacción completada por {{t.custom_data.completed_by}} <span v-if="t.paid_at == t.completed_at">(Pagada)</span></div>
						<!--<div>Transacción completada por {{ticket.completed.account.name}}</div>-->
					</div>
					<div class="mb15 cred" v-if="t.cancelled_at">
						<div class="bold">{{Util.Date(t.cancelled_at, 'LLLL')}}</div>
						<div>Transacción cancelada por {{t.custom_data.cancelled_by}} <span class="secondary-text" v-if="t.custom_data.cancelled_reason"> ({{t.custom_data.cancelled_reason}})</span></div>
						<!-- <div>Transacción cancelada por {{transaction.ticket.cancelled.account.name}} ({{ticket.cancelled.reason}})</div> -->
					</div>

					<div v-if="t.edit_at && Auth.role.indexOf('admin') > -1" class="flex mb15">
						<div class="flex-fill">
							<div class="bold">Última vez editada</div>
							<div>{{Util.Date(t.edit_at, 'LLLL')}}</div>
						</div>
						<button class="secondary" @click="GetRevision(t)" v-show="previews.length == 1">Ver Ediciones</button>
					</div>

					<div v-if="transactionStatus" :class="` ${transactionStatus.class}`">
						<div class="bold">Estado</div>
						<div>{{transactionStatus.text}}</div>
					</div>

				</div>
				
				<div v-if="t.id_boleta">
					
					<div class="separator mb20 mt20"></div>

					<div class="flex align-center mb20">
						<div class="f18 flex-fill">Boleta Electrónica No. {{Util.Number(t.custom_data.boleta.folio)}} </div>
						<i v-if="Auth.role.indexOf('admin') > -1" class="options icon-dots-vertical" @click="Options([
							{text: 'Ver detalles de envío', function: () => {ShowBoletaLog()}},
							{text: 'Reenviar Boleta', function: () => {ResendBoleta()}, if: t.custom_data.boleta.status == 'RECIEVED_ERROR'}
						])"></i>
					</div>
					
					<div class="hs mb15">
						<div>
							<div class="bold">Fecha</div>
							<div>{{t.custom_data.boleta.fecha_corta}}</div>
						</div>
						<div :class="` ${t.custom_data.boleta.status == 'OK' ? '' : 'cyellow'}`">
							<div class="bold">Estado</div>
							<div>{{boletaStatus[t.custom_data.boleta.status]}}</div>
						</div>
					</div>
					
					<div class="hs">
						<div>
							<div class="bold">Neto</div>
							<div>${{t.custom_data.boleta.neto}}</div>
						</div>
						<div>
							<div class="bold">IVA</div>
							<div>${{t.custom_data.boleta.iva}}</div>
						</div>
						<div>
							<div class="bold">Total</div>
							<div>${{t.custom_data.boleta.total}}</div>
						</div>
					</div>
					
					
				</div>

				<div v-if="t.id_factura">
					<div class="separator mb20 mt20"></div>

					<div class="f18 mb20">Factura Electrónica No. {{Util.Number(t.custom_data.factura.folio)}}</div>
					
					<div class="hs mb15">
						<div>
							<div class="bold">Fecha</div>
							<div>{{t.custom_data.factura.fecha_corta}}</div>
						</div>
						<div :class="` ${t.custom_data.factura.status == 'OK' ? '' : 'cyellow'}`">
							<div class="bold">Estado</div>
							<div>{{Util.FacturaStatus[t.custom_data.factura.status].label }}</div>
						</div>
					</div>

					<div class="hs">
						<div>
							<div class="bold">Neto</div>
							<div>${{t.custom_data.factura.neto}}</div>
							<div v-if="parseFloat(t.custom_data.factura.exento.replace(/\./g, ''))">
								<div class="bold">Exento</div>
								<div>{{Util.Price(parseFloat(t.custom_data.factura.exento.replace(/\./g, '')))}}</div>
							</div>
						</div>
						<div>
							<div class="bold">IVA</div>
							<div>${{t.custom_data.factura.iva}}</div>
						</div>
						<div>
							<div class="bold">Total</div>
							<div>${{t.custom_data.factura.total}}</div>
						</div>
					</div>
				</div>

				<div v-if="t.id_nota_credito">
					<div class="separator mb20 mt20"></div>

					<div class="f18 mb20">Nota de Crédito Electrónica No. {{Util.Number(t.custom_data.nota_credito.folio)}}</div>
					
					<div class="hs mb15">
						<div>
							<div class="bold">Fecha</div>
							<div>{{t.custom_data.nota_credito.fecha_corta}}</div>
						</div>
						<div :class="` ${t.custom_data.nota_credito.status == 'OK' ? '' : 'cyellow'}`">
							<div class="bold">Estado</div>
							<div>{{Util.FacturaStatus[t.custom_data.nota_credito.status].label }}</div>
						</div>
					</div>
				</div>

				<div class="">

					<div class="separator mb20 mt20"></div>

					<div class="f18 mb20">Datos del Pedido</div>

					<div class="mb15">
						<div class="bold">Tipo Pedido</div>
						<div>{{Util.TransactionType(t)}}</div>
					</div>

					<div class="mb15" v-if="(t.custom_data && t.custom_data.customers_number)">
						<div class="bold">N° Comensales</div>
						<div>{{t.custom_data.customers_number}}</div>
					</div>

					<div class="mb15" v-if="t.type == 'SERVE' && t.table_name">
						<div class="bold">Mesero</div>
						<div>{{t.custom_data.waiter ? t.custom_data.waiter.name : t.custom_data.created_by}}</div>
					</div>

					<div class="mb15" v-if="t.type == 'DELIVERY' && t.custom_data.delivery_by">
						<div class="bold">Repartidor</div>
						<div>{{t.custom_data.delivery_by}}</div>
					</div>

					<div class="mb15" v-if="t.custom_data.delivery_time">
						<div class="bold">Hora Entrega</div>
						<div>{{t.custom_data.delivery_time}}</div>
					</div>

					<div class="mb15" v-if="t.custom_data.activation_time && !t.custom_data.shipment">
						<div class="bold">Hora Retiro</div>
						<div>{{t.custom_data.activation_time}}</div>
					</div>

					<div class="mb15" v-if="t.type != 'TAKEAWAY' && t.custom_data.shipment">
						<div class="bold">Envío</div>
						<div v-if="t.custom_data.shipment.shipment_end">entre el {{Util.Date(t.custom_data.shipment.shipment_start, 'D [de] MMMM')}} y el {{Util.Date(t.custom_data.shipment.shipment_end, 'D [de] MMMM')}}</div>
						<div v-else>el {{Util.Date(t.custom_data.shipment.shipment_start, 'D [de] MMMM')}} <span v-if="t.custom_data.shipment.time">{{t.custom_data.shipment.time}}</span></div>
					</div>

					<div class="mb15" v-if="t.type == 'TAKEAWAY' && t.custom_data.shipment">
						<div class="bold">Retiro</div>
						<div v-if="t.custom_data.shipment.shipment_end_takeaway">entre el {{Util.Date(t.custom_data.shipment.shipment_start_takeaway, 'D [de] MMMM')}} y el {{Util.Date(t.custom_data.shipment.shipment_end_takeaway, 'D [de] MMMM')}}</div>
						<div v-else>el {{Util.Date(t.custom_data.shipment.shipment_start_takeaway, 'D [de] MMMM')}} <span v-if="t.custom_data.shipment.time">{{t.custom_data.shipment.time}}</span></div>
					</div>

					<div class="mb15 flex" v-if="t.customer.name">
						<div class="flex-fill">
							<div class="bold">Cliente</div>
							<div>{{t.customer.name}} <i class="icon icon-corbatin" v-if="t.id_user" style="color: rgb(0,151,255)"></i></div>
						</div>
						<i v-if="canBlockCustomers" class="options icon-dots-vertical" @click="Options([
							{text: 'Bloquear Cliente', class: 'error-color', function: () => {BlockCustomer(t)}, confirm: `¿Bloquear cliente con número ${t.customer.phone}?`},
						])"></i>
					</div>
					<div class="mb15 flex" v-if="t.customer.rut">
						<div class="flex-fill">
							<div class="bold">RUT</div>
							<div>{{Util.Rut(t.customer.rut)}}</div>
						</div>	
					</div>
					<div class="mb15 flex" v-if="t.customer.address">
						<div class="flex-fill">
							<div class="bold">Dirección</div>
							<div>{{t.customer.address}}</div>
							<div class="secondary-text" v-if="t.custom_data.maps_address">Dirección registrada en el mapa: {{t.custom_data.maps_address}}</div>
						</div>
						
						<i v-if="canOptions" class="options icon-dots-vertical" @click="CustomerOptions(t, 'ADDRESS')"></i>
						
					</div>



					<div class="mb15 flex" v-if="t.customer.phone">
						<div class="flex-fill">
							<div class="bold">Contacto </div>
							<div>{{t.customer.phone}}</div>
						</div>
						
						<i v-if="canOptions" class="options icon-dots-vertical" @click="CustomerOptions(t, 'PHONE')"></i>
						
					</div>

					<div class="mb15" v-if="t.customer.email">
						<div class="bold">Email </div>
						<div>{{t.customer.email}}</div>
					</div>

					<!--
					<div class="input-style" v-if="t.custom_data.influye_code">
						<div class="input-label">Código Influye </div>
						<div>{{t.custom_data.influye_code}}</div>
					</div>
					-->
					
				</div>

				<div class="" v-if="t.custom_data.pedidosya_courier || t.custom_data.pedidosya_status">
					<div class="separator mb20 mt20"></div>

					<div class="flex mb20 align-center">

						<div class="f18 flex-fill">Datos de PedidosYa Courier</div>

						<i v-if="canOptions && t.custom_data.pedidosya_courier" class="options icon-dots-vertical" @click="Options([
							{text: 'Soporte', function: () => { Util.Open(t.custom_data.pedidosya_courier.onlineSupportUrl) } },
							{text: 'Seguir envío', function: () => { Util.Open(t.custom_data.pedidosya_courier.shareLocationUrl) }, if: t.custom_data.pedidosya_courier.shareLocationUrl },
						])"></i>
					</div>

					<div v-if="t.custom_data.pedidosya_courier">
						<div class="hdvm mb15">
							<div>
								<div class="bold">N° Referencia </div>
								<div>{{t.custom_data.pedidosya_courier.referenceId}}</div>
							</div>
							
							<div>
								<div class="bold">Código de confirmación </div>
								<div>{{t.custom_data.pedidosya_courier.confirmationCode}}</div>
							</div>
						</div>
						
						<div class="hdvm mb15">
							<div>
								<div class="bold">Recolección</div>
								<div>{{Util.Date(t.custom_data.pedidosya_courier.pickupTime, 'HH:mm DD/MM/YYYY')}}</div>
							</div>

							<div>
								<div class="bold">Entrega</div>
								<div>{{Util.Date(t.custom_data.pedidosya_courier.deliveryTime, 'HH:mm DD/MM/YYYY')}}</div>
							</div>
						</div>

						<div class="">
							<div class="bold">Estado </div>
							<div>{{Util.StatusOrderPya[t.custom_data.pedidosya_courier.status]}}</div>
						</div>
					</div>

					<div v-if="t.custom_data.pedidosya_status">
						<div v-if="t.custom_data.pedidosya_status.reason" class="hdvm mb15">
							<div>
								<div class="bold">Motivo</div>
								<div>{{t.custom_data.pedidosya_status.reason}}</div>
							</div>
						</div>

						<div class="">
							<div class="bold">Estado </div>
							<div>{{Util.StatusOrderPya[t.custom_data.pedidosya_status.status]}}</div>
						</div>
					</div>

				</div>

				<!-- items -->
				<div class="">

					<div class="separator mb20 mt20"></div>

					<div class="flex align-center mb20">
						<div class="flex-fill">
							<div class="f18">Pedido</div>
						</div>
						<button class="secondary" @click="getRevisionOrder(t)" v-if="t.edit_at && Auth.role.indexOf('admin') > -1" v-show="previews.length == 1">Ver Ediciones</button>
					</div>

					<div class="well mb10" 
					v-for="(order, index) in t.orders"
					:key="index">
						<div class="order-item">
							<div v-if="!order.type">{{Util.Number(order.quantity)}}</div>
							<div v-if="order.type == 'DELIVERY'"><i class="icon icon-delivery c3"></i></div>
							<div v-if="order.type == 'LEY_REDONDEO'"><i class="icon icon-receipt c3"></i></div>
							<div><span>{{order.title}} <span class="secondary-text" v-if="order.external">(Externo)</span></span> <!--<span class="secondary-text">({{Util.Price(order.price*order.quantity)}})</span>-->
								<div class="secondary-text" v-if="order.method">{{Util.PayMethod(order.method)}}</div>
								<ul>
									<li :class="modifier.type" v-for="(modifier, index) in Util.ModifiersList(order, DictTagDinerName(order.diner))" :key="index">
										{{modifier.value}} 
										<span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
										<span class="secondary-text" v-if="modifier.price">
											{{Util.Price(modifier.price*order.quantity)}}
										</span>
									</li>
									<!-- <li class="order-comment" v-if="order.comment">{{order.comment}}</li> -->
								</ul>
							</div>
							<div><i v-if="order.original_price && order.original_price != order.price" class="icon icon-circle-exclamation cyellow" title="Precio modificado"></i> {{Util.Price(order.total_money)}}</div>
						</div>
					</div>
					<div class="well mb10" v-if="t.comment">
						<div class="bold">Comentario</div>
						<div>{{t.comment}}</div>
					</div>

					<div class="mt20 mb10 flex" v-if="t.total_discount">
						<div class="bold flex-fill">Subtotal</div>
						<div class="bold">{{Util.Price(t.total_money + t.total_discount)}}</div>
					</div>
					<div class="mb10 flex" v-if="t.total_discount">
						<div class="flex column unshrink flex-fill">
							<div class="bold">Descuentos</div>
							<div class="mb5"
							v-for="(discount, indexDiscount) in t.discounts"
							:key="indexDiscount"><i class="icon icon-arrow-right"></i> {{discount.title}}<span class="secondary-text">(-{{Util.Price(discount.discount)}})</span></div>
						</div>
						<div class="bold">-{{Util.Price(t.total_discount)}}</div>
					</div>
					<div :class="`f18 flex ${t.status == 'CANCELLED' ? 'cred' : 'cgreen'}`">
						<div class="bold flex-fill">Total</div>
						<div class="bold">{{Util.Price(t.total_money)}}</div>
					</div>
				</div>
				<!-- metodos de pago -->

				<div class="" v-if="t.custom_data.tips && t.custom_data.tips.length">

					<div class="separator mb20 mt20"></div>

					<div class="f18 mb20">Propinas</div>

					<div>
						<div class="entry flex" v-for="(tip, indexTip) in t.custom_data.tips" :key="'tip'+indexTip">
							<div class="flex-fill">{{Util.PayMethod(tip.method)}} <span class="secondary-text" v-if="tip.index !== null">(Método {{tip.index+1}})</span></div>
							<div class="bold">{{Util.Price(tip.total)}}</div>
						</div>
					</div>
					<div class="mt20 flex">
						<div class="flex-fill bold">Total con propinas</div>
						<div class="bold">{{Util.Price(t.total_money + Util.Sum(t.custom_data.tips, 'total'))}}</div>
					</div>
				</div>

				<div class="">

					<div class="separator mb20 mt20"></div>

					<div class="flex align-center mb20">
						<div class="flex-fill">
							<div class="f18">Métodos de Pago</div>
							<div class="secondary-text"><span v-if="t.payment.length > 1">Pago dividido</span></div>
						</div>
						<i class="options icon-dots-vertical" v-if="PaymentOptions().length" @click="Options(PaymentOptions())"></i>
					</div>
				

					<div class="mb20">
						<div v-for="(method, index2) in t.payment"
						:key="index2"
						class="entry">
							<div class="flex">
								<div class="flex column flex-fill">
									<div class="flex-fill mr10">{{Util.PayMethod(method.method)}}</div>
									<div class="flex-fill mr10 secondary-text" v-if="method.method != 'CASH' && method.n_transfer">N° {{ method.n_transfer }}</div>
									<div style="font-size: 12px" class="cgreen" v-if="method.paid_at">Marcado como pagado <span v-if="method.paid_by">por {{ method.paid_by }}</span> el {{Util.Date(method.paid_at, 'HH:mm DD/MM/YYYY')}} <i class="icon icon-check"></i></div>
									<div class="secondary-text" v-if="method.method == 'ONLINE' && t.custom_data.transbank">Pago Online: {{Util.TransbankPaymentType(t.custom_data.transbank.payment_type)}}</div>
									<div class="secondary-text" v-if="Util.MethodTip(t, index2)">
										<div v-if="method.diner"><span class="bold">Comensal: </span>{{ DictTagDinerName(method.diner) }}</div>
										<div><span class="bold">Subtotal: </span>{{Util.Price(method.subtotal)}}</div>
										<div><span class="bold">Propina: </span>{{Util.Price(Util.MethodTip(t, index2))}}</div>
									</div>
									<div class="flex align-center">
										<div v-if="Util.MethodChange(t, method, index2)" class="secondary-text"><span class="bold">Se paga con: </span>{{Util.Price(method.cash)}}</div>
										<div v-if="Util.MethodChange(t, method, index2)" class="secondary-text"> <i class="icon icon-arrow-right ml5 mr5"></i> <span class="bold">Vuelto: </span> {{Util.Price(Util.MethodChange(t, method, index2))}}</div>
									</div>
								</div>
								
								<div class="bold">{{Util.Price(Util.MethodTip(t, index2) + method.subtotal)}}</div>
								
							</div>
						</div>
					</div>

					<div class="well cgreen mb15" v-if="t.paid_at">
						<div class="bold">Pedido pagado <span v-if="t.custom_data.paid_by">por {{t.custom_data.paid_by}}</span></div>
						<div>{{Util.Date(t.paid_at, 'HH:mm DD/MM/YYYY')}}</div>
					</div>
					<div class="well cyellow mb15" v-if="t.pending_payment">
						<div>El pago de esta transacción está pendiente.</div>
					</div>
					<div class="well cred mb15" v-if="t.custom_data.refund_at">
						<div class="bold">Pago Reembolsado</div>
						<div>El {{Util.Date(t.custom_data.refund_at, 'LLLL')}} por {{t.custom_data.refund_by}}</div>
					</div>
					<div class="well mb15" v-if="t.custom_data.id_flow">
						<div class="bold">Número Orden Flow</div>
						<div>{{t.custom_data.id_flow}}</div>
					</div>
					<div class="well" v-if="t.custom_data.id_refund">
						<div class="bold">Número Orden Reembolso Flow</div>
						<div>{{t.custom_data.id_refund}}</div>
					</div>

				</div>
			</div>

		</div>
	</div>

</modal>

<modal v-model="modalRevisionTransaction" dir="right" :modalClass="['fill', 'right md h100']" title="Registro de Edición" hideActions>
	<div class="pa20">
		<div v-for="(revision, index) in modalRevisionTransaction" :key="index">
			<div class="mb20" v-if="revision.length > 0">
				<div class="flex">
					<div class="flex-fill">
						<div class="f18">{{index.split('-')[1].toUpperCase()}} {{index.split('-')[0].toUpperCase()}}</div>
						<div class="secondary-text mb15">{{Util.Date(parseInt(index.split('-')[2]), 'HH:mm DD/MM/YYYY')}} <span v-if="index.split('-').length >= 4 && index.split('-')[3]">({{Util.TransactionRevisionsEvents[index.split('-')[3]]}})</span></div>
					</div>
				</div>
				<div class="well mb10" 
				v-for="(itemRevision, indexItem) in revision"
				:key="indexItem">
					<div class="order-item">
						<div :class="`bold ${itemRevision.quantity > 0 ? 'cgreen' : 'cred'}`">
							<span v-if="itemRevision.quantity > 0">
								+
							</span>
							{{Util.Number(itemRevision.quantity)}}
						</div>
						<div class="flex-fill">{{itemRevision.title}} 
                            <ul class="secondary-text">
								<li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(itemRevision)" :key="indexMod">{{modifier.value}}</li>
                            </ul>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</modal>

<modal v-model="modalEditPayment" dir="center" modalClass="sm" title="Editar Método de Pago">
	<div v-if="modalEditPayment" class="pa20">
        <order-form
        v-model="modalEditPayment.orders"
        ref="order"
		:settings="settings"
        :hideSaleType="true"
        :hideCustomer="true"
        :hideComment="true"
        :hideActions="true"
		:hideItems="true"
		:hideDiscount="true"
		:fromTransaction="modalEditPayment.transaction"
		:canEditDeliveryCost="false">
        </order-form>
    </div>
    
	<button class="primary error-color" slot="actions" @click="Confirm({
		text: 'Editar',
		class: 'selected-color',
		title: '¿Editar transacción?',
		input: 'Comentario',
		function: EditPaymentMethod
	})">Editar</button>
    
</modal>

<modal v-model="modalPayPending" dir="center" modalClass="sm" title="Pagar Pago Pendiente">
	<div class="section" v-if="modalPayPending">
		<div class="secondary-text mb10">Seleccione el método de pago con el cual se liquidó la transacción.</div>
		
		<div class="well flex mb10">
			<div class="bold flex-fill">Total</div>	
			<div>{{Util.Price(modalPayPending.total)}}</div>
		</div>
		
		<input-select
		:items="modalPayPending.methods"
		v-model="modalPayPending.pay_method"
		label="Método">
		</input-select>
		
	</div>
	
	<button class="primary purple" slot="actions" @click="Confirm({
		text: 'Liquidar',
		title: 'Se quitará esta transacción de Pagos Pendientes y se cambiará el método de pago al seleccionado ¿Liquidar el pago pendiente de esta transacción?',
		class: 'selected-color',
		function: () => {PayPendingPayment()}
	})">Liquidar Pago</button>
	
</modal>

<modal v-model="modalSendBoleta" dir="center" modalClass="sm" title="Enviar Boleta Electrónica">
	<div class="pa20" v-if="modalSendBoleta">
		<input-field class="mb20"
		v-model="modalSendBoleta.email"
		label="Email">
			<button slot="after" class="primary purple" @click="SendBoletaEmail()">Enviar</button>
		</input-field>

		<input-field
		v-model="modalSendBoleta.phone"
		label="Teléfono">
			<button slot="after" class="primary purple" @click="Util.OpenWhatsapp(modalSendBoleta.phone, modalSendBoleta.shareUrl, {'{name}': transaction.customer.name})">Enviar</button>
		</input-field>
	</div>
	
	<button slot="actions" class="secondary" @click="Util.PrintTransaction(transaction, 'BOLETA');">Imprimir</button>
	<button slot="actions" class="secondary" @click="DownloadBoleta()">Descargar</button>
	
</modal>

<modal v-model="modalSendVirtualVoucher" dir="center" modalClass="sm" title="Voucher Digital">
	<div class="pa20" v-if="modalSendVirtualVoucher">

		<div class="ta-center mb20">
			<img :src="modalSendVirtualVoucher.url" style="max-height: 200px">
		</div>

		<input-field
		v-model="modalSendVirtualVoucher.phone"
		label="Teléfono">
			<button class="primary" slot="after" @click="Util.OpenWhatsapp(modalSendVirtualVoucher.phone, modalSendVirtualVoucher.message, {'{name}': transaction.customer.name})">Enviar</button>
		</input-field>
	</div>
	
	<button slot="actions" class="secondary" @click="Util.Open(modalSendVirtualVoucher.shareUrl)">Abrir</button>
	<button slot="actions" class="secondary" @click="Util.Clipboard(modalSendVirtualVoucher.shareUrl)">Copiar Enlace</button>
	
</modal>

<modal v-model="modalSendBankData" dir="center" modalClass="sm" title="Datos Bancarios">
	
	<div class="pa20" v-if="modalSendBankData">

		<div class="well mb20 secondary-text">
			<div><span class="bold">Nombre: </span>{{modalSendBankData.name}}</div>
			<div><span class="bold">RUT: </span>{{modalSendBankData.rut}}</div>
			<div><span class="bold">Banco: </span>{{modalSendBankData.bank}}</div>
			<div><span class="bold">Cuenta: </span>{{modalSendBankData.account}}</div>
			<div><span class="bold">Número Cuenta: </span>{{modalSendBankData.account_number}}</div>
			<div><span class="bold">Email: </span>{{modalSendBankData.email}}</div>
		</div>

		<input-field
		v-model="modalSendBankData.phone"
		label="Teléfono">
			<button class="primary" slot="after" @click="Util.OpenWhatsapp(modalSendBankData.phone, `${modalSendBankData.name}\n${modalSendBankData.rut}\n${modalSendBankData.bank}\n${modalSendBankData.account}\n${modalSendBankData.account_number}\n${modalSendBankData.email}`);">Enviar</button>
		</input-field>
	</div>

	<button class="secondary" slot="actions" @click="Util.Clipboard(`Nombre: ${modalSendBankData.name}\nRUT: ${modalSendBankData.rut}\nBanco: ${modalSendBankData.bank}\nCuenta: ${modalSendBankData.account}\nNúmero Cuenta: ${modalSendBankData.account_number}\nEmail: ${modalSendBankData.email}`)">Copiar</button>

</modal>

<modal v-model="modalSendOnlinePaymentLink" dir="center" modalClass="sm" title="Pago Online">
	<div class="pa20" v-if="modalSendOnlinePaymentLink">

		<div class="ta-center mb20">
			<img :src="`${Util.backend}qr/${modalSendOnlinePaymentLink.paymentUrl.replace(/\//g, '|')}`" style="max-height: 200px">
		</div>

		<input-field
		v-model="modalSendOnlinePaymentLink.phone"
		label="Teléfono">
			<button class="primary" slot="after" @click="Util.OpenWhatsapp(modalSendOnlinePaymentLink.phone, modalSendOnlinePaymentLink.paymentUrl)">Enviar</button>
		</input-field>
	</div>
	
	<button v-if="modalSendOnlinePaymentLink" slot="actions" class="secondary" @click="Util.Open(modalSendOnlinePaymentLink.paymentUrl)">Abrir</button>
	<button v-if="modalSendOnlinePaymentLink" slot="actions" class="secondary" @click="Util.NotifyService('print', {data: [{qr: modalSendOnlinePaymentLink.paymentUrl}], id: Math.random().toString()});">Imprimir</button>
	<button v-if="modalSendOnlinePaymentLink" slot="actions" class="secondary" @click="Util.Clipboard(modalSendOnlinePaymentLink.paymentUrl)">Copiar Enlace</button>
	
</modal>

<modal v-model="modalCancel" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Cancelar Pedido">
    <div class="section" v-if="modalCancel">
        <div class="bold mb10">Mótivo</div>
        <label class="mb10"
        v-for="(reason, index) in modalCancel.reasons"
        :key="index">
            <input type="radio" name="reason" :value="reason.value" v-model="modalCancel.reason"> {{reason.text}} <div class="radio"></div>
        </label>
        <input-field v-model="modalCancel.message" placeholder="Motivo" v-show="modalCancel.reason == 'CUSTOM' || modalCancel.reason == 'OTHER'"></input-field>

		<div class="bold mt15 mb10">Inventario</div>
		<label class="mb10">
			<input type="radio" name="inventory" :value="false" v-model="modalCancel.reduce_inventory"> No afectar inventario <div class="radio"></div>
		</label>
		<label class="cred">
			<input type="radio" name="inventory" :value="true" v-model="modalCancel.reduce_inventory"> Afectar inventario <div class="radio"></div>
		</label>

		<!--
		<div class="" v-if="modalCancel && transaction.payment[0].method == 'ONLINE'">
			<div class="bold mt15 mb10">Pago Online</div>
			<label class="mb10">
				<input type="radio" name="payment" :value="true" v-model="modalCancel.refund"> Con reembolso <div class="radio"></div>
			</label>
			<label class="cred">
				<input type="radio" name="payment" :value="false" v-model="modalCancel.refund"> Sin reembolso <div class="radio"></div>
			</label>
		</div>
		-->
        
	</div>

	<button class="primary error-color" v-if="modalCancel" slot="actions"
	@click="Confirm({
	text: 'Cancelar Pedido', 
	class: 'error-color', 
	cancel: false,
	title: `¿Cancelar pedido #${Util.Zeropad(transaction.id_ticket, 10)} ${modalCancel.reduce_inventory ? '(Afectar el inventario)' : '(No afectar el inventario)'} ${transaction.payment[0].method == 'ONLINE' ? (modalCancel.refund ? '(Con reembolso del pago online)' : '(Sin reembolso del pago online)') : ''}?`,
	function: () => {CancelTransaction()}})">Cancelar Pedido</button>

</modal>

<modal v-model="modalDelivery" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalDelivery && modalDelivery.edit ? 'Cambiar Repartidor' : 'Seleccionar Repartidor'">
    
	<div class="section" v-if="modalDelivery">
        <label class="mb10"
        v-for="(account, index) in accounts"
        :key="index"
        @click="$set(modalDelivery, 'account', account)">
            <input type="radio" name="delivery" :value="account" :checked="modalDelivery.account && account.id==modalDelivery.account.id"> <span class="secondary-text ml5" v-if="modalDelivery.previousAccount && account.id == modalDelivery.previousAccount.id"> (repartidor actual)</span> {{account.display_name}} <div class="radio"></div>
        </label>
    </div>

	<button class="primary error-color" slot="actions"
	v-if="Local.modules.module_pya_courier && modalDelivery && transaction && pedidosYaCourierEnabled"
	@click="openModalPya()">
		<i class="icon icon-pedidosya mr10"></i>
		<span>PedidosYa Courier</span>
	</button>
    
	<button class="primary purple" slot="actions"
	v-if="modalDelivery && transaction"
	:disabled="modalDelivery.edit && modalDelivery.previousAccount && modalDelivery.account.id == modalDelivery.previousAccount.id" @click="Confirm({
		text: modalDelivery.edit ? 'Cambiar Repartidor' : 'Enviar',
		title: modalDelivery.edit ? `¿Cambiar el repartidor de la transacción #${Util.Zeropad(transaction.id_ticket, 10)} a ${modalDelivery.account.display_name}?` : `¿Asignar el envió de la transacción #${Util.Zeropad(transaction.id_ticket, 10)} a ${modalDelivery.account.display_name}?`,
		class: 'selected-color',
		input: modalDelivery.edit ? 'Comentario' : null,
		function: DeliverTransaction,
	})">{{modalDelivery.edit ? 'Cambiar Repartidor' : 'Enviar Pedido'}}</button>
    
</modal>

<modal v-model="modalComplete" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Entregar Pedido">
    <div v-if="modalComplete" class="pa20">
        <order-form
        v-model="modalComplete.orders"
        ref="orderComplete"
		:settings="settings"
        :hideSaleType="true"
        :hideCustomer="true"
        :hideComment="true"
        :hideActions="true"
		:hideItems="true"
		:hidePayments="false"
		:hideDiscount="true"
		:hideAddTip="true"
		:fromTransaction="transaction"
		:canEditPayment="!!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_transactions_payment)">
        </order-form>
    </div>
    
	<button slot="actions" class="secondary" @click="$refs.orderComplete.OpenTip()">Agregar Propina</button>
	<button slot="actions" class="primary purple" @click="CompleteTransaction()">Entregar Pedido</button>
    
</modal>

<modal v-model="modalTicket" dir="center" modalClass="sm" :title="transaction?('#'+Util.Zeropad(transaction.id_ticket, 10)):''">
	<i slot="options" class="options icon-dots-vertical" @click="Options(PaymentOptions().concat([{text: 'Generar Voucher Digital', function: () => {ViewDigitalVoucher(transaction.customer.phone)}}]))"></i>
    <div v-if="modalTicket" class="flex column pa20">
        <!--<div class="f18 bold ta-center">{{this.Local.name}}</div>-->
        <!--<div class="secondary-text ta-center">#{{Util.Zeropad(modalTicket.id_ticket, 10)}}</div>-->
        <!--<div class="separator mt10 mb10"></div>-->
        <div class="flex column ofy">
            <div class="order-item mb5" style="align-items: flex-start"
            v-for="(item, index) in transaction.orders"
            :key="index">
                <div v-if="!item.type">{{Util.Number(item.quantity)}}</div>
                <div v-if="item.type == 'DELIVERY'"><i class="icon icon-delivery c3"></i></div>
                <div v-if="item.type == 'LEY_REDONDEO'"><i class="icon icon-receipt c3"></i></div>
                <div>
                    <div>{{item.title}}</div>
                    <ul>
                        <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">{{modifier.value}}</li>
                    </ul>
                </div>
                <div>{{Util.Price(item.total_money)}}</div>
            </div>
        </div>
        <div class="separator mb10 mt10"></div>
        <div class="flex" v-if="transaction.total_discount > 0">
            <div class="flex-fill">Subtotal</div>
            <div>{{Util.Price(transaction.total_discount + transaction.total_money)}}</div>
        </div>
        <div class="flex" v-if="transaction.total_discount > 0">
            <div class="flex-fill">Descuento</div>
            <div>- {{Util.Price(transaction.total_discount)}}</div>
        </div>
        <div class="flex mb10">
            <div class="flex-fill bold">Total</div>
            <div class="bold">{{Util.Price(transaction.total_money)}}</div>
        </div>
        <!--<div v-if="modalTicket.custom_data.influye_code" class="secondary-text ta-center mb5">Código Influye: {{modalTicket.custom_data.influye_code}}</div>-->
        
    </div>
    <button slot="actions" class="secondary" v-if="modalTicket && !transaction.id_boleta && !!Local.modules.module_sii_boleta && !transaction.id_factura" @click="Confirm({
            title: '¿Emitir e imprimir boleta electrónica?',
            text: 'Emitir',
            class: 'selected-color',
            function: () => {SendBoleta()}
    })">Emitir Boleta</button>
    <button slot="actions" class="secondary" v-if="modalTicket && !!Local.modules.module_sii_boleta && !!transaction.id_boleta" @click="OpenModalSendBoleta()">Enviar Boleta</button>
	<button slot="actions" class="secondary" v-if="modalTicket && !transaction.id_factura && !!Local.modules.module_sii_factura && !transaction.id_boleta && (Auth.role.indexOf('admin') > -1 || !!Auth.restrictions.can_create_facturas)" @click="Confirm({
            title: '¿Emitir factura electrónica?',
            text: 'Emitir',
            class: 'selected-color',
            function: () => {SendFactura()}
    })">Emitir Factura</button>
    <button slot="actions" class="secondary" v-if="modalTicket && Local.modules.module_sii_factura && !!transaction.id_factura" @click="OpenmodalSendDte()">Enviar Factura</button>
    <button slot="actions" class="secondary" v-if="!!Local.modules.module_service"
    @click="Options([
		{text: 'Imprimir Boleta Electrónica', function: () => {Util.PrintTransaction(transaction, 'BOLETA')}, if: !!transaction.id_boleta},
		{text: 'Imprimir Voucher', function: () => {Util.PrintTransaction(transaction, 'DEFAULT')}, if: !!Local.modules.module_service},
		{text: 'Imprimir para Cocina', function: () => {Util.PrintTransaction(transaction, 'PENDING')}, if: !!Local.modules.module_service},
		{text: 'Imprimir para Delivery', function: () => {Util.PrintTransaction(transaction, 'DELIVERY')}, if: !!Local.modules.module_service},
		{text: 'Imprimir Todos', function: () => {Util.PrintTransaction(transaction)}, if: !!Local.modules.module_service},
	])">Imprimir</button>
</modal>

<!-- Moda para confirmación de eliminacion de transacción -->
<modal v-model="modalConfirmDeleteTransaction.show" modalClass="sm">	
	<div class="pa20">
		<div class="ta-center mb20" v-html="'Eliminar Transacción'"></div>
		<label class="mb10">
			<input type="radio" name="inventory" :value="false" v-model="modalConfirmDeleteTransaction.rollback"> No afectar inventario <div class="radio"></div>
		</label>
		<label class="cred">
			<input type="radio" name="inventory" :value="true" v-model="modalConfirmDeleteTransaction.rollback"> Afectar inventario <div class="radio"></div>
		</label>
	</div>
	<button slot="actions" @click="modalConfirmDeleteTransaction.show = false" class="secondary">Cancelar</button>
	<button slot="actions" class="primary error-color" @click="DeleteTransaction()">Eliminar transacción</button>
</modal>
<!-- Moda para confirmación de eliminacion de transacción -->

<modal-estimate-pya ref="estimatePya" :order="this.transaction"></modal-estimate-pya>

<modal-factura-electronica ref="facturaElectronica" @generateFactura="SentFactura($event)" :order="this.transaction"></modal-factura-electronica>

<transaction-edit ref="transactionEdit" 
@OnTransactionEdit="EditItems($event)"
@OnChangePlatform="$emit('OnChangePlatform')"
:menu="menu"
:overwrites="overwrites"
:inventory="inventory"
:timestamp="timestamp"
></transaction-edit>
</div>
</template>

<script>
export default {
	props: {
		menu: {
			type: Array,
			default: null
		},
		overwrites: {
			type: Array,
			default: null
		},
		inventory: {
			type: [Object, Array], 
			default: null
		},
		timestamp: {
			type: Number,
			default: 0
		},
	},
	data() {
		return {
			modal: null,
			transaction: null,
			modalEditPayment: null,
			methods: null,
			pendingPaymentsMethods: {},
			modalDivide: null,
			methodsFix: false,
			editPaymentCallback: null,
			modalDiscount: false,
			modalPayPending: null,
			modalSendBoleta: null,
			boletaStatus: {
				CREATED: 'Boleta emitida',
				SENT: 'Enviada al SII',
				RECIEVED: 'Recibo confirmado por el SII',
				OK: 'Boleta recibida exitosamente por SII',
				SEND_ERROR: 'Error en el envío',
				RECIEVED_ERROR: 'Error en el recibo',
				FAILED: 'Boleta no fue recibida por el SII',
				CANCELLED_SENT: 'Anulación emitida',
				CANCELLED_RECIEVED: 'Anulación recibida',
				CANCELLED: 'Anulada'
			},
			modalCancel: null,
			modalDelivery: null,
			accounts: null,
			revision: null,
			revisions: [],
			settings: null,
			modalComplete: null,
			modalSendVirtualVoucher: null,
			modalTicket: null,
			modalSendBankData: null,
			modalSendOnlinePaymentLink: null,
			modalRevisionTransaction: null,
			pedidosYaCourierEnabled: false,
			modalConfirmDeleteTransaction : {show : false, rollback : false },
			defaultMessages: null,
		}
	},
	methods: {
		Open(transaction) {
			this.revision = null;
			this.revisions = [];
			if(typeof transaction == 'number')
			{
				this.Loading();
				axios.get(`/transaction/${transaction}`).then(res => {
					this.transaction = res.data;
					this.modal = true;
					this.Response(res);
				}).catch(err => {
					this.Response(err);
				});
			}
			else
			{
				this.modal = true;
				this.transaction = transaction;
			}

			/* this.getRevisionsTransactions(transaction.id); */
		},
		Close() {
			this.modal = false;
			this.transaction = null;
			this.modalPayPending = null;
			this.modalEditPayment = null;
		},
		UpdateTransaction(transaction)
		{
			if(this.modal && this.transaction && this.transaction.id === transaction.id) this.transaction = transaction;
		},
		OpenItems(transaction)
		{
			this.transaction = transaction;
			this.$refs.transactionEdit.Open(transaction);
		},
		ClickDeleteTransaction(){
			this.modalConfirmDeleteTransaction = { show : true, rollback : false}
		},
		DeleteTransaction() {
			this.$root.$emit('Loading');
			let data = {
				'data' : {
					'rollback' :  this.modalConfirmDeleteTransaction.rollback
				}
			}
			axios.delete(`/transaction/${this.transaction.id}`, data).then(res => {
			this.$root.$emit('Response', res);
			if(!res.data.error)
			{
				this.modal = false;
				this.modalConfirmDeleteTransaction = {show : false, rollback : false }
				this.$emit('OnTransactionDeleted', this.transaction.id);
			}
			}).catch(err => {
				this.$root.$emit('Response', err);
			});
		},
		async OpenEditPayment(transaction, completeCallback) 
		{
			let transactionCopy = this.Util.Copy(transaction);
			this.editPaymentCallback = completeCallback;
			if(!this.settings)
			{
				this.Loading();
				this.settings = (await axios.get(`/methods/${this.Local.id}`)).data;
				this.settings.no_payment_select = false;
				this.Response({});
			}
			this.modalEditPayment = {
				orders: {},
				transaction: transactionCopy
			};
			this.$nextTick(() => {

				var deliveryCost = 0;
				transactionCopy.orders.forEach(item => {
                    if(item.type == 'DELIVERY') 
					{
						this.$refs.order.deliveryCost = item.price;
						this.$refs.order.deliveryPaymentMethod = item.method;
						deliveryCost = item.price;
						if(item.external) this.$refs.order.isExternalDelivery = true;
					}
                });

				this.$set(this.modalEditPayment, 'orders', {
					'ORDER': {
						title: 'Pedido',
						total_money: transactionCopy.total_money - deliveryCost,
						price: transactionCopy.total_money - deliveryCost,
						quantity: 1
					}
				});
				setTimeout(() => {
					this.$refs.order.paymentMethods = [];
                    this.$refs.order.sale = transactionCopy.type;
					if(transactionCopy.custom_data.tips) this.$refs.order.tips = transactionCopy.custom_data.tips;
                    this.$nextTick(() => {
                        this.$refs.order.paymentMethods = transactionCopy.payment;
                    });
				}, 10);
				
			});
		},
		EditPaymentMethod(comment)
		{
			var order = this.$refs.order.GetOrder();
			if(!order) return;
			
			var data = {
				methods: order.payment, 
				edit_comment: comment,
				deliveryPaymentMethod: this.$refs.order.deliveryPaymentMethod,
				tips: order.tips
			};

			this.Loading();
			axios.patch(`/transaction/${this.transaction.id}/methods`, data).then(res => {
				this.Response(res);
				this.transaction = res.data;
				this.$emit('OnTransactionEdit', res.data);
				this.modalEditPayment = null;

				if(this.editPaymentCallback)
				{
					this.editPaymentCallback(res.data);
				}

			}).catch(err => {
				this.Response(err);
			});
		},
		EditItems(transaction)
		{
			this.$emit('OnTransactionEdit', transaction);
		},
		/*
		Abre el modal de pagos pendientes, se descargan los medios de pagos y se configuran los posibles para liquidar la transacción. 
		Se verfica también el total en caso de pagos pendientes con pagos dividos
		@return Info en caso de que no existen pagos pendientes se muestra mensaje de error
		*/
		async OpenPayPending()
		{
			if(!this.settings)
			{
				this.Loading();
				this.settings = (await axios.get(`/methods/${this.Local.id}`)).data;
				this.Response({});
			}

			var pendingMethods = {};
			var methods = [];
			methods.push({value: 'CASH', text: 'Efectivo'});
			methods.push({value: 'Transferencia', text: 'Transferencia'});
			this.settings.payment_methods.forEach(method => {
				if(!method.pending_payment) methods.push({value: method.title, text: method.title});
				else pendingMethods[method.title] = true;
			});
			
			if(methods.length === 0) return this.Info(`No hay métodos de pago para liquidar esta transacción`);
			
			var method = null;
			for(var i = 0; i < this.transaction.payment.length; i++)
			{
				if(pendingMethods[this.transaction.payment[i].method])
				{
					method = this.transaction.payment[i];
					break;
				}
			}

			if(!method) return this.Info(`No existe el método de pago pendiente o no está configurado como Pago Pendiente`);

			this.modalPayPending = {
				methods: methods,
				pay_method: methods[0].value,
				total: method.subtotal,
				method: method.method
			};

		},
		/*
		Request para liquidar transaccion individual
		@endpoint POST transaction/{id}/pay
		*/
		PayPendingPayment()
		{
			this.Loading();
			axios.post(`/transaction/${this.transaction.id}/pay`, this.modalPayPending).then(res => {
				this.$root.$emit('PendingPaymentPaid');
				this.Info(`Transacción liquidada exitosamente`);
				this.modalPayPending = null;
			}).catch(err => {
				this.Response(err);
			});
		},
		/*
		Genera un reembolso por el total de la transacción
		@endpoint GET transaction/{id_transaction}/refund
		*/
		RefundTransaction()
		{
			this.Loading();
			axios.get(`/transaction/${this.transaction.id}/refund`).then(res => {
				this.transaction = res.data;
				this.Info(`Rembolso generado exitosamente`);
			}).catch(err => {
				this.Response(err);
			});
		},
		
		SendBoleta(id)
		{
			this.Loading();
			axios.post(`/transaction/${id ? id : this.transaction.id}/boleta`).then(res => {
				res.data.edit_type = 'BOLETA';
				this.transaction = res.data;
				this.Info(`Boleta emitida exitosamente`);
				this.OpenModalSendBoleta();
				this.$emit('OnTransactionEdit', res.data);
				this.Util.PrintTransaction(this.transaction, 'BOLETA', 'CREATED');

			}).catch(err => {
				this.Response(err);
			});
		},

		ResendBoleta(transaction)
		{
			if(!transaction) transaction = this.transaction;
			this.Loading();
			axios.post(`/transaction/${transaction.id}/boleta/resend`).then(res => {
				this.transaction = res.data;
				this.Info(`Boleta reenviada exitosamente`);
			}).catch(err => {
				this.Response(err);
			});
		},

		ShowBoletaLog(transaction)
		{
			if(!transaction) transaction = this.transaction;
			this.Loading();
			axios.get(`/transaction/${transaction.id}/boleta/log`).then(res => {
				this.Info(res.data.log ? res.data.log : 'No hay datos');
			}).catch(err => {
				this.Response(err);
			});
		},

		async OpenModalSendBoleta()
		{
			if(!this.defaultMessages)
			{
				this.Loading();
				this.defaultMessages = {};
				this.defaultMessages = (await axios.get(`/local/${this.Local.id}/messages`)).data;
				this.Response({error: false});
			}
			
			let baseUrl = process.env.NODE_ENV === 'production' ? `https://sii.influye.app` : `${this.Util.backend}boleta/preview`;
			let shareUrl = encodeURIComponent(`${baseUrl}?rut=${this.transaction.custom_data.boleta.rut}&number=${this.transaction.custom_data.boleta.folio}&date=${this.transaction.custom_data.boleta.fecha_corta.replace(/\//g, '-')}&total=${this.transaction.custom_data.boleta.total.replace(/\./g, '')}`);
			this.modalSendBoleta = {
				email: this.transaction.customer && this.transaction.customer.email ? this.transaction.customer.email : '',
				phone: this.transaction.customer && this.transaction.customer.phone ? this.transaction.customer.phone : '+569',
				shareUrl: this.defaultMessages.default_boleta_message ? `${this.defaultMessages.default_boleta_message}\n${shareUrl}` : shareUrl,
			};
		},

		sendNotaCredito(){
			this.Loading();
            axios.post(`/sii/nota-credito/${this.Local.id}/create`, this.transaction)
            .then( ({data}) => {
                if(!data.status) return this.Info(data.message);
				if(data.transaction) this.transaction = data.transaction;
				this.OpenmodalSendDte(61);
                return this.Info(data.message);
            })
            .catch( err => {
                this.Response(err);
            });
		},

		SendFactura(){
            this.$refs.facturaElectronica.Open();
		},
		SentFactura(transaction){
			this.transaction = transaction;
		},
		OpenmodalSendDte(type = 33){
            this.$refs.facturaElectronica.OpenmodalSendDte(this.transaction, type);
		},

		async ViewDigitalVoucher(phone)
		{
			this.Loading();

			if(!this.defaultMessages) 
			{	
				this.defaultMessages = {};
				this.defaultMessages = (await axios.get(`/local/${this.Local.id}/messages`)).data;
			}

			axios.get(`/transaction/${this.transaction.id}/voucher`).then(res => {
				var url = this.Util.backend + 'voucher/' + res.data;
				let shareUrl = (process.env.NODE_ENV === 'production' ? `https://voucher.influye.app/` : `${this.Util.backend}voucher/preview/`) + res.data;
				this.modalSendVirtualVoucher = {
					url: url, 
					phone: this.transaction.customer && this.transaction.customer.phone ? this.transaction.customer.phone : '+569',
					shareUrl: shareUrl,
					message: this.defaultMessages.default_voucher_message ? `${this.defaultMessages.default_voucher_message}\n${shareUrl}` : shareUrl,
				};
				this.Response(res.data);
			}).catch(err => {
				this.Response(err);
			});
		},

		SendDepositData(phone)
		{
			this.Loading();
			axios.get(`/local/${this.Local.id}/bank`).then(res => {
				this.Response(res);
				//this.Util.OpenWhatsapp(phone, `${res.data.name}\n${res.data.rut}\n${res.data.bank}\n${res.data.account}\n${res.data.account_number}\n${res.data.email}`);
				res.data.phone = phone ? phone : '+569';
				this.modalSendBankData = res.data;
				
			}).catch(err => {
				this.Response(err);
			})
		},

		SendBoletaEmail()
		{
			this.Loading();
			axios.post(`/transaction/${this.transaction.id}/boleta/send`, this.modalSendBoleta).then(res => {
				this.Info(`Boleta enviada a ${this.modalSendBoleta.email}`);
				//this.modalSendBoleta = null;
			}).catch(err => {
				this.Response(err);
			});
		},

		DownloadBoleta()
		{
			this.Loading();
            axios.get(`/transaction/${this.transaction.id}/boleta/download`, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }).then(res => {
                    this.Response(res);
                    this.Util.DownloadFile(res, `dte_39_folio_${this.transaction.custom_data.boleta.folio}.pdf`);
            }).catch(err => {
                this.Response(err);
            });
		},

		OpenCancel(transaction)
		{
			this.transaction = transaction;
			var modal = {reduce_inventory: false, refund: false};
			if(transaction.source == 'UBEREATS')
			{
				modal.reasons = [
					{text: 'Sin stock', value: 'OUT_OF_ITEMS'},
					{text: 'Cocina cerrada', value: 'KITCHEN_CLOSED'},
					{text: 'Cliente llamó para cancelar', value: 'CUSTOMER_CALLED_TO_CANCEL'},
					{text: 'Restaurant muy ocupado', value: 'RESTAURANT_TOO_BUSY'},
					{text: 'No puede completar las notas del cliente', value: 'CANNOT_COMPLETE_CUSTOMER_NOTE'},
					{text: 'Otro (escribir el motivo)', value: 'OTHER'}
				];
			}
			else if(transaction.custom_data && transaction.custom_data.integration_pya) {
				modal.reasons = [
					{text: 'Alta demanda', value: 'TOO_BUSY'},
					{text: 'Restaurant cerrado', value: 'CLOSED'},
					{text: 'Problemas para interactuar con pedido', value: 'NO_RESPONSE'},
					{text: 'Inconvenientes con productos', value: 'MENU_ACCOUNT_SETTINGS'},
				];

			} else {
				modal.reasons = [
					{text: 'Cliente no encontrado', value: 'Cliente no encontrado'},
					{text: 'Cliente disconforme', value: 'Cliente disconforme'},
					{text: 'Pedido incorrecto', value: 'Pedido incorrecto'},
					{text: 'Rechazado por demora', value: 'Rechazado por demora'},
					{text: 'Pedido duplicado', value: 'Pedido duplicado'},
					{text: 'Otro (escribir el motivo)', value: 'OTHER'}
				];
			}
			modal.reason = modal.reasons[0].value;
			this.modalCancel = modal;
		},

		/*
        Cancela una transacción entregando un motivo
        @params modalCancel
        @param reason <String> motivo de cancelación
        @param reduce_inventory <Bool> indica si se hará una reduccón de inventario
        @param refund <Bool> [Optional] para IAP con pago online, indica si se hará un reembolso del pago
        */
        CancelTransaction()
        {

            var reason = this.modalCancel.reason == 'CUSTOM' || this.modalCancel.reason == 'OTHER' ? this.modalCancel.message : this.modalCancel.reason;
            if(!reason) return this.Info('Motivo inválido');

            var data = {
                reason: reason,
                reduce_stock: this.modalCancel.reduce_inventory
            };

            if(this.transaction.payment[0].method == 'ONLINE')
            {
                data.refund = this.modalCancel.refund;
            }

            this.Loading();
            axios.post(`/transaction/${this.transaction.id}/cancel`, data).then(res => {
                this.Response(res);
                this.modalCancel = null;
				this.$emit('OnTransactionCanceled', res.data);
            }).catch(err => {
                this.Response(err);
            });
        },

		async OpenComplete(transaction, tip)
		{
			console.log(transaction);
			this.transaction = this.Util.Copy(transaction);
			if(tip)
			{
				if(!this.settings)
				{
					this.Loading();
					this.settings = (await axios.get(`/methods/${this.Local.id}`)).data;
					this.settings.no_payment_select = false;
					this.Response({});
				}
				this.modalComplete = {
					orders: {}
				};
				let deliveryItem = null;
				this.$nextTick(() => {
					
					var total = this.transaction.total_money;
					var delivery = 0;

					deliveryItem = this.transaction.orders.find(el => el.type == 'DELIVERY');
					if(deliveryItem)
					{
						delivery = deliveryItem.total_money;
					}
					total -= delivery;

					this.$set(this.modalComplete, 'orders', {
						'ORDER': {
							title: 'Pedido',
							total_money: total,
							price: total,
							quantity: 1
						}
					});
					if(this.transaction.custom_data.tips) this.$refs.orderComplete.tips = this.transaction.custom_data.tips;
					this.$refs.orderComplete.paymentMethods = [];
					this.$refs.orderComplete.sale = this.transaction.type;
					this.$refs.orderComplete.deliveryCost = delivery;
				});
				
				setTimeout(() => {
					console.log(this.transaction);
					this.$nextTick(() => {
						this.$refs.orderComplete.paymentMethods = this.transaction.payment;
						this.$nextTick(() => {
							if(deliveryItem && deliveryItem.method) this.$refs.orderComplete.deliveryPaymentMethod = deliveryItem.method;
						});
						console.log(this.$refs.orderComplete);
					});
				}, 10);

			}
			else
			{
				this.Confirm({
					title: `¿Confirmar que el pedido #${this.Util.Zeropad(transaction.id_ticket, 10)} ha sido entregado?`,
					text: 'Pedido Entregado',
					class: 'selected-color',
					function: () => {this.CompleteTransaction()}
				})
			}
		},

		CompleteTransaction(transaction)
		{
			
			if(transaction) this.transaction = transaction;

			if(this.transaction.payment.some(method => method.method == 'NO_SELECT'))
				return this.Info(`Debe seleccionar un método de pago.`);

			var completeData = null;
			if(this.modalComplete)
			{
				completeData = {
					tips: this.$refs.orderComplete.tips,
					payment: this.$refs.orderComplete.paymentMethods,
					deliveryPaymentMethod: this.$refs.orderComplete.deliveryPaymentMethod,
					pendingPayment: this.$refs.orderComplete.transaction.pending_payment,
				}

				if(completeData.payment.some(method => method.method == 'NO_SELECT') || completeData.deliveryPaymentMethod == 'NO_SELECT')
					return this.Info(`Debe seleccionar un método de pago.`);
			}

			this.Loading();
            axios.post(`/transaction/${this.transaction.id}/complete`, completeData).then(res => {
                this.Response(res);
                this.$emit('OnTransactionCompleted', res.data);
				this.modalComplete = null;
            }).catch(err => {
                this.Response(err);
            });
		},

		/*
		abre el modal para despachar un pedido
		*/	
		async OpenDelivery(transaction)
		{
			if(!this.accounts) 
			{
				this.Loading();
				var data = (await axios.get(`/accounts/${this.Local.id}/delivery?v2=true`)).data; 
				this.accounts = data.accounts;
				this.pedidosYaCourierEnabled = data.pya; 
				this.Response({});
			}

			var account = this.accounts.length ? this.accounts[0] : null;
			var previousAccount = null;
			if(transaction.status == 'DELIVERY')
			{
				for(var i = 0; i < this.accounts.length; i++)
				{
					if(this.accounts[i].id == transaction.id_delivery_by)
					{
						account = this.accounts[i];
						previousAccount = this.accounts[i];
						break;
					}
				}
			}

			this.transaction = transaction;
			this.modalDelivery = {
				account: account,
				edit: transaction.status == 'DELIVERY',
				previousAccount: previousAccount
			};
		},

		/*
		despacha la transaccion
		@params modalDelivery
		@param account la cuenta repartidor
		*/
		DeliverTransaction(comment)
        {
			if(!this.modalDelivery.account) return this.Info('Repartidor inválido');
            this.Loading();
            axios.post(`/transaction/${this.transaction.id}/deliver`, {id_delivery: this.modalDelivery.account.id, edit_comment: comment}).then(res => {
                this.Response(res);
                this.modalDelivery = null;
				this.$emit('OnTransactionDelivery', res.data);
            }).catch(err => {
                this.Response(err);
            });
        },

		GetRevision(transaction)
		{
			this.Loading();
			axios.get(`/transaction/${transaction.id}/revision`).then(res => {
				var revisions = [];
				console.log(res.data);
				var index = res.data.length;
				res.data.forEach(rev => {
					revisions.push({
						text: `Versión ${index--} (editada ${this.Util.Date(rev.edit_at, 'DD/MM/YYYY HH:mm')})`,
						value: rev
					});
				});
				this.revisions = revisions;
				if(res.data.length) this.revision = res.data[0];
				this.Response(res);
			}).catch(err => {
				this.Response(err);
			});
		},
		VoucherOptions(transaction)
		{
			this.transaction = transaction;
			let options = [
				{text: 'Imprimir Factura Electrónica', function: () => {this.Util.NotifyService('factura', {data: this.transaction.custom_data.factura, id: Math.random().toString()});}, if: !!this.transaction.custom_data.factura},
				{text: 'Imprimir Boleta Electrónica', function: () => {this.Util.PrintTransaction(this.transaction, 'BOLETA')}, if: !!this.transaction.id_boleta},
				{text: 'Imprimir Pre Cuenta', function: () => {this.Util.NotifyService('reprint', {data: this.transaction, id: Math.random().toString(), types: 'PRE'});}, if: !!this.Local.modules.module_service && (this.transaction.type == 'SERVE' && this.transaction.table_name) && (this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.reprint_options.includes("precuenta"))},
				{text: 'Imprimir para Delivery', function: () => {this.Util.PrintTransaction(this.transaction, 'DELIVERY')}, if: !!this.Local.modules.module_service && (this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.reprint_options.includes("delivery"))},
				{text: `Imprimir para Cocina`, function: () => {this.Util.PrintTransaction(this.transaction, 'PENDING')}, if: !!this.Local.modules.module_service && (this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.reprint_options.includes("kitchen"))},
				{text: 'Imprimir Voucher', function: () => {this.Util.PrintTransaction(this.transaction, 'DEFAULT')}, if: !!this.Local.modules.module_service && (this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.reprint_options.includes("voucher"))},
				{text: 'Imprimir Todos', function: () => {this.Util.PrintTransaction(this.transaction)}, if: !!this.Local.modules.module_service && (this.Auth.role.indexOf('admin') > -1 || ['voucher', 'kitchen', 'delivery'].every(value => this.Auth.restrictions.reprint_options.includes(value)))},
			];
			this.Options(options);
		},

		async CustomerOptions(transaction, type)
		{
			if(!transaction) transaction = this.transaction;
			else this.transaction = transaction;
			let options = [];

			if((!type || type == 'PHONE') && transaction.customer && transaction.customer.phone)
				options.push({text: 'Llamar', function: () => {this.Util.Open(`tel:${transaction.customer.phone}`)}});

			if(!type || type == 'ADDRESS')
			{
				if(transaction.customer && transaction.customer.address)
				{
					if(this.mobile) options.push({text: 'Buscar en Waze', function: () => {this.Util.OpenWaze(transaction.customer.address)}});
					options.push({text: 'Buscar en Google Maps', function: () => {this.Util.OpenGoogleMaps(transaction.customer.address)}});
				}
				if(transaction.custom_data.longitude) options.push({text: 'Abrir Ubicación en Google Maps', function: () => {this.Util.OpenGoogleMaps(transaction.custom_data)}});
			}

			if(!type || type == 'PHONE')
			{
				if(transaction.customer && transaction.customer.phone)
				{
					options.push({text: 'Enviar Voucher (Whatsapp)', function: () => {this.ViewDigitalVoucher(transaction.customer.phone)}});
					if(this.canSendDepositData) options.push({text: 'Enviar Datos de Transferencia (Whatsapp)', function: () => {this.SendDepositData(transaction.customer.phone)}});
					if(!this.defaultMessages) 
					{	
						this.Loading();
						this.defaultMessages = {};
						this.defaultMessages = (await axios.get(`/local/${this.Local.id}/messages`)).data;
						this.Response({error: false});
					}
					console.log(this.defaultMessages);
					if(transaction.type == 'DELIVERY' && this.defaultMessages.default_delivery_message)
						options.push({text: 'Enviar Mensaje por Defecto (Whastapp)', function: () => {this.Util.OpenWhatsapp(transaction.customer.phone, this.defaultMessages.default_delivery_message, {'{name}': transaction.customer.name, '{address}': transaction.customer.address})}});
					else if(this.defaultMessages.default_difussion_message)
						options.push({text: 'Enviar Mensaje por Defecto (Whastapp)', function: () => {this.Util.OpenWhatsapp(transaction.customer.phone, this.defaultMessages.default_difussion_message, {'{name}': transaction.customer.name})}});
					options.push({text: 'Abrir en Whastapp', function: () => {this.Util.OpenWhatsapp(transaction.customer.phone, '')}});
				}
			}

			this.Options(options);
			
		},

		VirtualVoucherOption(transaction)
		{
			return {
				text: 'Generar Voucher Digital', 
				function: () => {
					this.transaction = transaction;
					this.ViewDigitalVoucher()
				},
			};
		}
		,
		BoletaOption(transaction)
		{
			if(this.Local.modules.module_sii_boleta)
			{
				if(transaction.id_boleta)
				{
					return {
						text: 'Enviar Boleta Electrónica', 
						function: () => {
							this.transaction = transaction;
							this.OpenModalSendBoleta();
						}
					};
				}
				else
				{
					return {
						text: 'Emitir Boleta Electrónica', 
						function: () => {
							this.transaction = transaction;
							this.SendBoleta();
						}
					};
				}
			}
			return null;
		},
		FacturaOption(transaction){
			if(this.Local.modules.module_sii_factura){
				if(transaction.custom_data.factura){
					return {
						text: 'Enviar Factura Electrónica', 
						function: () => {
							this.transaction = transaction;
							this.OpenmodalSendDte();
						}
					};
				}else{
					return {
						text: 'Emitir Factura Electrónica', 
						function: () => {
							this.transaction = transaction;
							this.SendFactura();
						}
					};
				}
			}
			return null;

		},

		OpenTicket(transaction)
		{
			this.transaction = transaction;
			this.modalTicket = true;
		},

		PaymentOptions(transaction)
		{
			if(transaction) this.transaction = transaction;
			var ret = [];
			if(this.canSendDepositData)
			{
				ret.push({text: 'Enviar Datos Bancarios', function: () => {this.SendDepositData(this.transaction.customer && this.transaction.customer.phone ? this.transaction.customer.phone : '+569')}});
			}
			if(this.transaction && this.transaction.payment.length == 1 && this.transaction.payment[0].method == 'ONLINE' && !this.transaction.paid_at)
			{
				ret.push({text: 'Enviar Link de Pago', function: () => {this.modalSendOnlinePaymentLink = {
					phone: this.transaction.customer && this.transaction.customer.phone ? this.transaction.customer.phone : '+569',
					paymentUrl: process.env.NODE_ENV === 'production' ? `https://payment.influye.app/local/${this.transaction.code}` : `${this.Util.backend}webpay/pos/${this.transaction.code}`,
				}}});
			}
			/*
			if(this.transaction && this.transaction.payment.length == 1 && this.transaction.payment[0].method == 'ONLINE' && this.transaction.paid_at && this.transaction.custom_data.transbank)
			{
				ret.push({text: 'Reembolsar Pago Online', function: () => {this.RefundTransbankPayment()}})
			}
			*/

			return ret;
		},

		RefundTransbankPayment(transaction)
		{
			if(transaction) this.transaction = transaction;
			this.Loading();
			axios.post(`/transaction/${this.transaction.id}/transbank/refund`).then(res => {
				console.log(res.data);
				this.Response(res);
			}).catch(err => {
				this.Response(err);
			});
		},
		BlockCustomer(ticket)
        {
            var data = {
                name: ticket.customer.name,
                phone: ticket.customer.phone, 
                email: ticket.customer.email,
                ip: null
            };
            this.Loading();
            axios.post(`/customers/${this.Local.id}/iap/block`, data).then(res => {
                this.Info('Cliente bloqueado exitosamente');
            }).catch(err => {
                this.Response(err);
            });
        },
		openModalPya(){
			this.$refs.estimatePya.Open(this.transaction);
			this.modalDelivery = null;
		},
		getRevisionsTransactions(transaction){
			this.Loading();
			axios.get(`/transaction/${transaction.id}/revision`).then(res => {
				var revisions = [];
				res.data.forEach(rev => {
					revisions.push({
						text: `Versión ${this.Util.Date(rev.created_at, 'DD/MM/YYYY HH:mm')}`,
						value: rev
					})
				});
				this.revisions = revisions;
				if(res.data.length) this.revision = res.data[0];
				this.Response(res);
			}).catch(err => {
				this.Response(err);
			});
			
			this.Loading();
			axios.get(`transaction/${transaction}/revision`).then( ({data}) => {
				if(data) this.$set(this.transaction, 'modifications', data);
				this.Response(data);
				return 
			}).catch(err => {
				this.Response(err);
				return;
			});
		},
		getRevisionOrder(transaction){
			this.Loading();
			axios.get(`/transaction/${transaction.id}/detail/revision`).then( ({data}) => {
				this.Response(data);
				if(!data.status) return this.Info(data.msg);
				this.modalRevisionTransaction = data.revisions;
			}).catch(err => {
				this.Response(err);
			});
		},
		DictTagDinerName(value){
            if((!this.transaction && !this.transaction.custom_data['diners_names']) || !value) return
            let diner = this.transaction.custom_data['diners_names'][value]
            return diner
        },
	},
	computed: {
		canOptions() {
			if(this.transaction && this.transaction.status.indexOf('DELETED-') > -1) return false;
			return this.Auth.role != 'delivery';
		},
		canDelete() {
			if(!this.transaction) return false;
			if(this.Local.type !== 'LOCAL') return false;
			//if(this.transaction.iap) return false;
			if(this.Auth.role.indexOf('admin') == -1) return false;
			return true;
			//var moment = require('moment');
			//return this.transaction.created_at >= moment().unix() - (60*60*24*2);
		},
		canEditPayment() 
		{
			return false;
			if(this.transaction.iap && this.transaction.payment[0].method == 'ONLINE') return false;
			if(this.Auth.role.indexOf('admin') == -1 && !this.Auth.restrictions.edit_transactions) return false;
			if(this.transaction === null) return false;
			if(this.transaction.status !== 'PENDING' && this.transaction.status !== 'DELIVERY') return false;
			return true;
		},
		//@computed <Bool> determina si la transacción se puede reembolsar
		canRefund()
		{
			if(this.Local.type !== 'LOCAL') return false;
			return this.transaction && this.transaction.status == 'COMPLETED' && this.Auth.role.indexOf('admin') > -1 && !this.transaction.custom_data.refund_at;
		},
		transactionStatus() 
		{
			if(!this.transaction) return null;
			if(this.transaction.status.indexOf('DELETED-') > -1) return {text: 'Eliminada', class: 'cred'};
			if(this.transaction.status == 'DELIVERY' && this.transaction.custom_data.delivered_at) return {text: 'En tránsito (Marcada como entregada)', class: 'cyellow'};
			var status = {
				PENDING: {text: 'Pendiente', class: 'cyellow'},
				DELIVERY: {text: 'En tránsito', class: 'cblue'},
				CANCELLED: {text: 'Cancelada', class: 'cred'},
				COMPLETED: {text: 'Completada', class: 'cgreen'}
			}
			return status[this.transaction.status];
		},
		canSendDepositData()
		{
			if(!this.transaction) return false;
			for(var i = 0; i < this.transaction.payment.length; i++)
				if(this.transaction.payment[i].method.toUpperCase().indexOf('TRANSFERENCIA') > -1 || this.transaction.payment[i].method == 'BANK_DEPOSIT') return true;
			return false;
		},
		previews() {
			if(!this.transaction) return [];
			return this.revision ? [this.transaction, this.revision.data] : [this.transaction];
		},
		canBlockCustomers() {
			if(!this.transaction) return false;
            return (this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.block_customers) && this.transaction.source == 'INFLUYE_WEB';
        }
	}
}
</script>
<style lang="scss">
.method {
	border-bottom: 1px solid whitesmoke;
	margin-bottom: 10px;
	padding-bottom: 10px;
}
.method:last-child {
	margin-bottom: 0px;
	padding-bottom: 0px;
	border-bottom: none;
}
.customer-divide {
	margin-left: 5px;
	border-radius: 50px;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--white);
	background-color: var(--gray-2);
	font-weight: bold;
	cursor: pointer;
}
.customer-divide.selected {
	background-color: var(--blue-2) !important;
}

.tag-total-modifiers {
	font-size: 12px;
    font-weight: normal;
    background-color: var(--gray-2-5);
    color: var(--color-preparation-pending);
    padding: 1px 5px;
    border-radius: 5px;
}

.dark {
	.customer-divide {
		background-color: var(--dark-bg-0);
	}
	.tag-total-modifiers {
		background-color: var(--dark-bg-3);
		color: var(--color-preparation-pending);
	}
}
</style>