<template>
<modal v-model="modal" dir="right" :modalClass="['fill', 'md right h100']" :title="!isNew?originalTitle:(item&&item.type==0?'Nuevo Producto':'Nuevo Combo')">
    
    <!--
    <i v-if="tab=='Modificadores'" class="icon icon-dots-vertical" slot="options" @click="Options([
        {text: 'Ordenar Grupos', function: () => {drag=true}, if: drag==false},
        {text: 'Terminar de Ordenar Grupos', function: () => {drag=false}, if: drag==true},
        {text: 'Nuevo Grupo de Modificadores', function: () => {modalModifierGroup={title:'', min:0, max: 0}}}
    ])"></i>
    -->

    <button v-if="tab == 'Modificadores'" slot="actions" @click="ToggleSelectDefault()" :class="mobile ? 'options icon-check-list' : `secondary`">
        <span v-if="desktop">{{ item.selectDefault? 'Mostrar precios' : 'Selección inicial' }}</span>
    </button>
    <button v-show="tab == 'Modificadores'" slot="actions" @click="modalSearchGroup={}" :class="mobile ? 'options icon-search' : `secondary`">
        <span v-if="desktop">Buscar grupo</span>
    </button>
    <button v-show="tab == 'Modificadores'" :class="mobile ? 'options icon-plus' : `secondary`" slot="actions" @click="modalModifierGroup={title:'', min:0, max: 0}">
        <span v-if="desktop">Crear Grupo</span>
    </button>
    <button v-show="tab == 'Inventario'" class="secondary" slot="actions" @click="modalStock={item: '', reference: item, required: false, bypass_performance: false}">Enlazar Inventario</button>
    <button class="primary purple" slot="actions" @click="Save()">Guardar</button>

    <tabs v-model="tab" :tabs="tabs" :keyByTab="true" v-if="modal">

        <!-- General -->
        <div slot="General">
            <div class="pa20" v-if="item">

                <input-image class="mb15"
                label="Imagen"
                path="products/"
                v-model="item.image"
                :crop="true">
                </input-image>

                <input-field class="mb15"
                v-model="item.title"
                label="Nombre">
                </input-field>
                
                <input-field class="mb15"
                v-model="item.description"
                :lines="3"
                label="Descripción">
                </input-field>
                
                <input-field class="mb15"
                v-model="item.price"
                label="Precio"
                type="int+">
                </input-field>

                <!--
                <input-field
                v-model="item.margin"
                label="Margen"
                type="number"
                placeholder="(Opcional)">
                </input-field>
                -->

                <div v-if="isLocal" class="mb15">
                    <div class="bold">Disponibilidad</div>
                    <div class="secondary-text mb10">Define si el ítem esta disponible actualmente. Los items no disponibles no pueden ingresarse en caja y también aparecer deshabilitados a los clientes.</div>
                    <label class="">
                        <input type="checkbox" v-model="item.unavailable">No disponible <div class="checkmark"></div>
                    </label>
                </div>

                <div class="bold">Visibilidad</div>
                <div class="secondary-text mb10">Define en que menús se muestra</div>
                <label class="mb10">
                    <input type="checkbox" v-model="item.hide_pos">POS <div class="checkmark reverse"></div>
                </label>
                <label class="mb10">
                    <input type="checkbox" v-model="item.invisible">Ecommerce <div class="checkmark reverse"></div>
                </label>
                <label class="">
                    <input type="checkbox" v-model="item.hide_virtual_menu">Menú Virtual <div class="checkmark reverse"></div>
                </label>

                <div v-if="item.type==0" class="mt15">
                    <div class="bold">Tipo de Pedido</div>
                    <div class="secondary-text mb10">Define en cual tipo de pedidos se muestra</div>
                    <label class="mb10">
                        <input type="checkbox" v-model="item.hide_takeaway">Retirar/Servir <div class="checkmark reverse"></div>
                    </label>
                    <label>
                        <input type="checkbox" v-model="item.hide_delivery">Delivery <div class="checkmark reverse"></div>
                    </label>
                </div>

                <div class="separator mb20 mt20"></div>
  
                <label class="">
                    <input type="checkbox" v-model="item.autocomplete"><div class="flex column">
                        <div class="bold">Autocompletar</div>
                        <div class="secondary-text">Los pedidos con items que se completan automáticamente pasan directamente a ser completados en vez de pasar a pedidos pendientes.</div>        
                    </div> <div class="checkmark"></div>
                </label>
                
                <label class="mt15" v-if="item.type==0">
                    <input type="checkbox" v-model="item.float_quantity"><div class="flex column">
                        <div class="bold">Cantidad decimal</div>    
                        <div class="secondary-text">Opción para ingresar una cantidad decimal al hacer un pedido (por ejemplo: 2,4)</div>
                    </div> <div class="checkmark"></div>
                </label>
                <input-field v-model="item.float_quantity_measure" v-show="item.float_quantity" class="ml35" v-if="item.type==0"
                description="Unidad de Medida"
                placeholder="(Opcional)">
                </input-field>

                <div class="mt15">
                    <label class="">
                        <input type="checkbox" v-model="item.anticipation"><div class="flex column">
                            <div class="bold">Reserva</div>
                            <div class="secondary-text">Define sí el ítem requiere un tiempo mínimo de reserva (el pedido debe ser agendado).</div>
                        </div> <div class="checkmark"></div>
                    </label>

                    <input-field class="ml35 mt10" v-if="item.anticipation"
                    v-model="item.anticipation_time"
                    placeholder="0"
                    type="int+">
                        <div class="append unshrink" slot="after">{{ item.anticipation_time > 1 ? 'Hrs' : 'Hr' }}</div>
                    </input-field>
                </div>

                <div class="mt15">
                    <label class="mt15">
                        <input type="checkbox" v-model="item.max_quantity_enabled">
                        <div class="flex column">
                            <div class="bold">Cantidad máxima</div>
                            <div class="secondary-text">Opción para ingresar la cantidad máxima en el pedido</div>
                        </div> 
                        <div class="checkmark"></div>
                    </label>

                    <input-field v-model="item.max_quantity" v-show="item.max_quantity_enabled" class="ml35"
                    description="Cantidad"
                    type="int+"
                    placeholder="0">
                    </input-field>
                </div>

                <label class="mt15" v-if="item.type==0">
                    <input type="checkbox" v-model="item.comment_required"><div class="flex column">
                        <div class="bold">Comentario obligatorio</div>    
                        <div class="secondary-text">Indica si el campo de comentario es obligatorio como una instrucción adicional</div>
                    </div><div class="checkmark"></div>
                </label>
                <input-field v-model="item.comment_instructions" v-show="item.comment_required" class="mb15 ml35" v-if="item.type==0"
                description="Instrucciones de comentario"
                placeholder="(Opcional)">
                </input-field>

                <input-field v-model="item.printer_description" class="mt15" v-if="item.type==0"
                :lines="-3"
                label="Descripción Impresa"
                description="Descripción que se imprime en el voucher cocina y nombre del item en los voucher impresos"
                :placeholder="`[Nombre item](Opcional)\n(Descripción Opcional)`"
                info="Puedes reemplazar el nombre del producto en el voucher impreso agregando en la primera linea entre corchetes [Nuevo Nombre], luego en las líneas siguientes agregar una descripción opcionalmente">
                </input-field>

                <input-field v-model="item.sku" v-show="Local.modules.module_barcode" class="mt15" v-if="item.type==0"
                label="SKU"
                description="Identificador para leer con una pistola de código de barras"
                placeholder="(Opcional)">
                </input-field>

                <div class="bold mt20 mb10">Color en cocina</div>
                <input-color class="mb10"
                    label=""
                    v-model="item.color"
                    :canRemove="true"
                    >
                </input-color>
                    
            </div>

        </div>
        
            

        <!-- Catgorias -->
        <div slot="Categorias" v-if="item && item.type == 0">
            <div class="pa20" v-if="categories">
                <label class="mb10"
                v-for="(category, index) in categories" 
                :key="index">
                    <input type="checkbox" v-model="item.categories[category.id]">{{category.title}} <div class="checkmark"></div>
                </label>
            </div>
        </div>

        <!-- Modificadores -->
        <div slot="Modificadores" v-if="item && item.type == 0 && showModifiers" class="flex column fill ofy">
            
            <div class="secondary-text ta-center mt10" v-if="item.modifiers.length == 0">No hay modificadores</div>
            
            <div v-if="item.modifiers.length > 0" class="fill flex-fill flex column ofy">

                <draggable
                :list="item.modifiers"
                handle=".handle"
                :animation="200"
                @start="inMotionGroups = true"
                @end="inMotionGroups = false"
                group="groups">

                    <div class=" pa20"
                    v-for="(group, groupIndex) in item.modifiers"
                    :key="groupIndex">
                        <div :class="`sticky bgbg1 flex align-center f18 mb15 pt10 pb10`">
                            <i class="icon icon-drag-handle handle"></i>
                            <div class="flex column flex-fill selectable" @click="EditModifierGroup(group, groupIndex)">
                                <div class="flex align-center">
                                    <i v-if="group.id" class="icon icon-link cgray1 mr5" title="Grupo de modificadores referenciado"></i>
                                    <div class="flex column">
                                        <div class="bold">{{group.title}}</div>
                                        <div class="flex">
                                            <span class="menu-tag pos" v-if="!group.hide_pos && !item.hide_pos"></span>
                                            <span class="menu-tag ec" v-if="!group.invisible && !item.invisible"></span>
                                            <span class="menu-tag vm" v-if="!group.hide_virtual_menu && !item.hide_virtual_menu"></span>
                                        </div>
                                    </div>
                                    <span :class="`secondary-text ml5 ${group.errorSelectModifier && !group.replacement ? 'cred' : ''}`">{{Util.ModifierGroupLimits(group)}}</span>
                                    <i v-if="group.multi_select" class="icon icon-folder-closed-plus-minus cgray1 ml5" title="Grupo de selección múltiple"></i>
                                    <i v-if="group.replacement" class="icon icon-folder-closed-exchange cgray1 ml5" title="Grupo de reemplazo"></i>
                                    <i v-if="group.expand" class="icon icon-folder-closed-window cgray1 ml5" title="Grupo expansible"></i>
                                    <!-- <i v-if="group.invisible" class="icon icon-invisible c3 ml5" title="Invisible a los clientes"></i>-->
                                    <!--<span class="secondary-text ml5 cred" v-if="group.errorSelectModifier && !group.replacement">(Se necesita modificar)</span>-->
                                </div>
                                
                            </div>
                            <i class="options icon-dots-vertical" @click="Options([
                                {text: 'Eliminar Grupo de Modificadores', class: 'error-color', function: () => {item.modifiers.splice(groupIndex, 1)}, confirm: '¿Eliminar grupo y todos los modificadores que contiene?'},
                                {text: 'Duplicar Grupo de Modificadores', function: () => {DuplicateModifiersGroup(group)}},
                                {text: 'Editar Grupo de Modificadores', function: () => {EditModifierGroup(group, groupIndex)}},
                                {text: 'Crear a partir de otro Modificador', class: 'selected-color', function: () => { modalSearchModifier={group: group, groupIndex: groupIndex}}},
                                {text: 'Crear Nuevo Modificador', class: 'selected-color', function: () => {modalModifier={title: '', price: 0, group: group, groupIndex: groupIndex, inventory_references: []}}},
                            ])"></i>
                        </div>

                        <div>
                            <draggable
                            :list="group.modifiers"
                            v-show="!inMotionGroups"
                            handle=".handle"
                            :animation="200"
                            group="modifiers">
                                <div :class="`entry selectable`"
                                v-for="(modifier, modIndex) in group.modifiers"
                                :key="modIndex">
                                    <div class="flex fill align-center">
                                        <i class="icon icon-drag-handle handle"></i>
                                        <div class="flex flex-fill align-center" 
                                        @click="EditModifier(modifier, group, modIndex)">
                                            <div class="flex column flex-fill">
                                                <div class="flex align-center">

                                                    <i class="icon icon-link cgray1 mr5" v-if="modifier.id" title="Modificador referenciado"></i>
                                                    <div class="flex column">
                                                        <span class="">
                                                            {{modifier.title}} 
                                                            <i v-if="group.replacement" class="icon-exchange ml5" @click.stop="OpenReplacementOptions(modifier, modIndex, group, groupIndex)"></i> 
                                                            <span class="ml5 secondary-text" style="color: var(--update-color)" v-if="modifier.auto_select && !item.selectDefault">
                                                                <i v-if="modifier.auto_select === true" class="icon icon-check"></i>
                                                                <span v-else><i class="icon icon-check"></i> {{ modifier.auto_select }}</span>
                                                            </span>
                                                        </span>
                                                        <div class="flex">
                                                            <span class="menu-tag pos" v-if="!modifier.hide_pos && !group.hide_pos && !item.hide_pos"></span>
                                                            <span class="menu-tag ec" v-if="!modifier.invisible && !group.invisible && !item.invisible"></span>
                                                            <span class="menu-tag vm" v-if="!modifier.hide_virtual_menu && !group.hide_virtual_menu && !item.hide_virtual_menu"></span>
                                                        </div>
                                                    </div>
                                                    
                                                    <!--<span class="secondary-text ml5" v-if="modifier.unavailable">(No disponible)</span>-->
                                                </div>

                                                <div class="secondary-text" v-if="group.replacement && modifier.replacement_options && Util.Arr(modifier.replacement_options).length"><span class="bold">Reemplazable por: </span>{{Util.CommaList(modifier.replacement_options, 'key')}}</div>
                                                <div class="secondary-text">{{modifier.description}}</div>
                                                
                                            </div>
                                            <i class="icon icon-forbidden mr5 cred" v-if="modifier.unavailable" title="No disponible"></i>
                                            <div class="flex align-center" v-show="!item.selectDefault">
                                                <div class="bold" v-if="modifier.price != 0">{{Util.Price(modifier.price)}}</div>
                                                <div class="secondary-text unshrink" v-else>Sin Costo</div>
                                                <!-- <i v-if="group.replacement" class="options icon-exchange ml5" @click.stop="modifierReplacement && modifierReplacement.modifier == modifier ? modifierReplacement = null : modifierReplacement={group: group, groupIndex: groupIndex, modifier: modifier, modIndex: modIndex}"></i>-->
                                                <div class="options icon-dots-vertical" @click.stop="() => {Options([
                                                    {text: 'Eliminar Modificador', class: 'error-color', function: () => {group.modifiers.splice(modIndex, 1)}},
                                                    {text: 'Duplicar Modificador', function: () => {group.modifiers.push(Util.Copy({...modifier, auto_select: null}))}},
                                                    {text: 'Elegir Opciones de Reemplazo', function: () => {OpenReplacementOptions(modifier, modIndex, group, groupIndex)}, if: !!group.replacement},
                                                    {text: 'Editar Modificador', function: () => {EditModifier(modifier, group, modIndex)}}
                                                ], modifier.title)}"></div>
                                            </div>
                                        </div>
                                        <div v-if="item.selectDefault">
                                            <div class="flex align-center" v-if="group.multi_select">
                                                <button class="icon secondary sm mr10" @click.stop="!modifier.auto_select? null : ModifyQuantityModifier(group, groupIndex, modIndex, -1)"><i class="icon icon-minus"></i></button>
                                                <span class="bold" style="color: var(--update-color)">{{modifier.auto_select || 0}}</span>
                                                <button class="icon secondary sm ml10" @click.stop="ModifyQuantityModifier(group, groupIndex, modIndex, 1)"><i class="icon icon-plus"></i></button>
                                            </div>
                                            <div class="flex align-center" v-else-if="group.replacement">
                                            </div>
                                            <div class="flex align-center" v-else>
                                                <label>
                                                    <input type="checkbox" :checked="modifier.auto_select"
                                                    @click="ModifyQuantityModifier(group, groupIndex, modIndex, $event)"><div class="checkmark"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </draggable>

                            <div class="mt20" v-if="group.replacement">
                            <div class="bold mb20">Opciones de Reemplazo</div>
                            <draggable class="well pl0 pr0"
                            :list="group.modifiers_replacement"
                            handle=".handle"
                            :animation="200"
                            group="modifiers">
                                <div :class="`entry selectable`"
                                v-for="(modifier, modIndex) in group.modifiers_replacement"
                                :key="'replacement-'+modIndex">
                                    <div class="flex fill align-center">
                                        <i class="icon icon-drag-handle handle"></i>
                                        <div class="flex flex-fill align-center" 
                                        @click="EditModifier(modifier, group, modIndex, 'modifiers_replacement')">
                                            <div class="flex column flex-fill">
                                                <div class="flex align-center">
                                                    
                                                    <i class="icon icon-link cgray1 mr5" v-if="modifier.id" title="Modificador referenciado"></i>
                                                    <div class="flex column">
                                                        <span class="">{{modifier.title}}</span>
                                                        <div class="flex">
                                                            <span class="menu-tag pos" v-if="!modifier.hide_pos && !group.hide_pos && !item.hide_pos"></span>
                                                            <span class="menu-tag ec" v-if="!modifier.invisible && !group.invisible && !item.invisible"></span>
                                                            <span class="menu-tag vm" v-if="!modifier.hide_virtual_menu && !group.hide_virtual_menu && !item.hide_virtual_menu"></span>
                                                        </div>
                                                    </div>
                                                    <!-- <span class="secondary-text ml5" v-if="modifier.unavailable">(No disponible)</span>-->
                                                </div>
                                                
                                                <div class="secondary-text">{{modifier.description}}</div>
                                            </div>
                                            <i class="icon icon-forbidden mr5 cred" v-if="modifier.unavailable" title="No disponible"></i>
                                            <div class="bold" v-if="modifier.price != 0">{{Util.Price(modifier.price)}}</div>
                                            <div class="secondary-text unshrink" v-else>Sin Costo</div>
                                            <div class="options icon-dots-vertical" @click.stop="() => {Options([
                                                {text: 'Eliminar Modificador', class: 'error-color', function: () => {group.modifiers_replacement.splice(modIndex, 1)}},
                                                {text: 'Duplicar Modificador', function: () => {group.modifiers_replacement.push(Util.Copy(modifier))}},
                                                {text: 'Editar Modificador', function: () => {EditModifier(modifier, group, modIndex, 'modifiers_replacement')}}
                                            ], modifier.title)}"></div>
                                        </div>
                                    </div>
                                </div>
                            </draggable>
                            </div>


                        </div>

                    </div>

                </draggable>
                
            </div>

        </div>
    
        <div slot="Inventario" v-if="item && item.type == 0" class="pa20">
            
            <div class="f18 mb20">Item</div>
            <div v-if="!item.inventory_references.length" class="secondary-text ta-center mt10">No hay referencias.</div>
            <div>
                <div class="entry selectable"
                v-for="(stock, index) in item.inventory_references"
                :key="index"
                @click="modalStock={edit: stock, item: stock.inventory_item, quantity: stock.quantity, required: !!stock.required, bypass_performance: !!stock.bypass_performance}">
                    <div class="flex fill align-center">
                        <div class="bold"><i class="icon icon-box cgray1 mr5" title="Inventario requerido" v-if="stock.required"></i><i class="icon icon-forbidden cgray1 mr5" title="Sin rendimiento" v-if="stock.bypass_performance"></i>{{stock.inventory_item}}</div>
                        <div class="flex-fill"></div>
                        <div class="flex"><span class="bold">{{Util.Number(stock.quantity)}}</span> <div v-if="inventory && inventory.items && inventory.items[stock.inventory_item]" class="secondary-text ml5">{{inventory.items[stock.inventory_item].unit}}</div></div>
                        <div class="options icon-trash ml10" @click.stop="() => {Confirm({
                            text: 'Eliminar', 
                            title: '¿Eliminar referencia a inventario?',
                            class: 'error-color', 
                            function: () => {item.inventory_references.splice(index, 1)}})}"></div>
                    </div>
                </div>
            </div>

            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Modificadores</div>
            <div v-if="!modifierInventory.length" class="secondary-text ta-center mt10">No hay referencias.</div>
            <div>
                <div class="entry selectable"
                v-for="(stock, index) in modifierInventory"
                :key="index"
                @click="modalStock={edit: stock.reference, item: stock.inventory_item, quantity: stock.quantity, required: !!stock.required, bypass_performance: !!stock.bypass_performance}">
                    <div class="flex fill align-center">
                        <div class="flex column">
                            <div class="secondary-text">{{stock.group}}{{stock.replacement ? ' (Reemplazo)' : ''}} - {{stock.modifier}}</div>
                            <div class="bold"><i class="icon icon-box cgray1 mr5" title="Inventario requerido" v-if="stock.required"></i><i class="icon icon-forbidden cgray1 mr5" title="Sin rendimiento" v-if="stock.bypass_performance"></i>{{stock.inventory_item}}</div>
                        </div>
                        <div class="flex-fill"></div>
                        <div class="flex"><span class="bold">{{Util.Number(stock.quantity)}}</span> <div v-if="inventory && inventory.items && inventory.items[stock.inventory_item]" class="secondary-text ml5">{{inventory.items[stock.inventory_item].unit}}</div></div>
                        <div class="options icon-trash ml10" @click.stop="() => {Confirm({
                            text: 'Eliminar', 
                            title: '¿Eliminar referencia a inventario?',
                            class: 'error-color', 
                            function: ()=> {item.modifiers[stock.groupIndex].modifiers[stock.modifierIndex].inventory_references.splice(stock.stockIndex, 1)}
                        })}"></div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Combo: Productos -->
        <div slot="Productos">
            <div class="fill" style="overflow-y: auto">
                <items-selection 
                bodyClass="pa20"
                :menu="menu"
                :itemType="0"
                :showSelected="false"
                callbackOnly
                :currentlySelected="currentlySelectedSubitems"
                :hideComment="true"
                @OnItemAdded="AddComboSubItem($event)">
                    <div class="section pb0" v-if="item.products">
                        <div class="f18 flex">Seleccionados <span class="counter ml5">{{item.products.length}}</span></div>
                        <div>
                            <draggable 
                            :list="item.products"
                            handle=".handle"
                            :animation="200">
                                <div v-for="(subitem, index) in item.products" :key="index" class="well mt10 flex align-center">
                                    <i class="icon icon-drag-handle handle"></i>
                                    <div class="flex-fill bold">
                                        <div class="mb5"><i v-if="!dictItems[subitem.id]" class="icon icon-circle-exclamation cyellow mr5" v-tooltip="'Este item no se encuentra en el menú'"></i>{{subitem.title}}</div>
                                        <input-field v-model="subitem.info" placeholder="Descripción adicional (Opcional)"></input-field>
                                    </div>
                                    <i @click="item.products.splice(index, 1)" class="options icon-trash ml5"></i>
                                    <!--
                                    <div class="flex align-center">
                                        <i class="options icon-minus mr10" @click="item.quantity > 1 ? $set(item, 'quantity', item.quantity-1) : $delete(selecteditems, index)"></i>
                                        <span class="bold">{{item.quantity}}</span>
                                        <i class="options icon icon-plus ml10" @click="$set(item, 'quantity', item.quantity+1)"></i>
                                    </div>
                                    -->
                                </div>
                            </draggable>
                        </div>
                    </div>
                </items-selection>
            </div>
        </div>

    </tabs>

    
    <modal v-model="modalModifierGroup" :dir="['right', 'right']" :modalClass="['fill', 'sm right h100']" :title="modalModifierGroup ? (modalModifierGroup.originalTitle ? `${modalModifierGroup.originalTitle}` : 'Nuevo Grupo de Modificadores') : ''">
        <div class="pa20" v-if="modalModifierGroup">
            
            <input-field class="mb15"
            v-model="modalModifierGroup.title"
            label="Título"
            :after="modalModifierGroup.id ? 'link' : ''">
            </input-field>
            <!--
            <input-select label="Título" :canType="true" :items="modifiersGroups"
            v-model="modalModifierGroup.title">
            </input-select>
            -->
            
            <div class="hs">
                <input-field
                v-model="modalModifierGroup.min"
                label="Mínimo"
                type="int+">
                </input-field>
                
                <input-field
                v-model="modalModifierGroup.max"
                label="Máximo"
                type="int+">
                </input-field>
            </div>

            <div class="secondary-text mt5 well" v-if="Util.ModifierGroupLimits(modalModifierGroup)">Se deben escoger {{Util.ModifierGroupLimits(modalModifierGroup).replace('(', '').replace(')','').toLowerCase()}} modificadores de este grupo.</div>

            <div class="bold mb10 mt15">Visibilidad</div>
            <label class="mb10">
                <input type="checkbox" v-model="modalModifierGroup.hide_pos"> POS <div class="checkmark reverse"></div>
            </label>
            <label class="mb10">
                <input type="checkbox" v-model="modalModifierGroup.invisible"> Ecommerce <div class="checkmark reverse"></div>
            </label>
            <label class="">
                <input type="checkbox" v-model="modalModifierGroup.hide_virtual_menu"> Menú Virtual <div class="checkmark reverse"></div>
            </label>

            <div class="separator mb20 mt20"></div>

            <label class="mb10">
                <input type="checkbox" v-model="modalModifierGroup.expand"> Grupo expansible <div class="checkmark"></div>
            </label>
            <label v-show="!modalModifierGroup.replacement" class="mb10">
                <input type="checkbox" v-model="modalModifierGroup.multi_select"> Grupo de selección múltiple <div class="checkmark"></div>
            </label>
            <label v-show="!modalModifierGroup.multi_select" class="">
                <input type="checkbox" v-model="modalModifierGroup.replacement"> Grupo de modificadores de reemplazo <div class="checkmark"></div>
            </label>
            
            

            
            

        </div>
        
        <!--<button slot="actions" v-if="modalModifierGroup && !modalModifierGroup.edit" @click="modalSearchGroup={}" class="secondary">Buscar grupo</button>-->
        <button slot="actions" v-if="modalModifierGroup" class="primary purple" @click="AddModifierGroup()">{{modalModifierGroup&&modalModifierGroup.edit?'Editar Grupo':'Crear Grupo'}}</button>
        
    </modal>
    
    <modal v-model="modalModifier" dir="right" :modalClass="['fill', 'sm right h100']" :title="modalModifier&&modalModifier.originalTitle?modalModifier.originalTitle:'Nuevo Modificador'">
        <div class="pa20" v-if="modalModifier">
            
            <input-field class="mb15"
            v-model="modalModifier.title"
            label="Título"
            :after="modalModifier.id ? 'link' : ''">
            </input-field>
            
            <input-field class="mb15"
            v-model="modalModifier.price"
            label="Precio"
            type="int">
            </input-field>
            
            <input-field class="mb15"
            v-model="modalModifier.description"
            label="Descripción" placeholder="(Opcional)">
            </input-field>

            <label class="mb15" v-if="isLocal">
                <input type="checkbox" v-model="modalModifier.unavailable"> No disponible <div class="checkmark"></div>
            </label>
            
            <div class="bold">Visibilidad</div>
            <div class="secondary-text mb10">Define en que menús se muestra esta modificador</div>
            <label class="mb10">
                <input type="checkbox" v-model="modalModifier.hide_pos"> POS <div class="checkmark reverse"></div>
            </label>
            <label class="mb10">
                <input type="checkbox" v-model="modalModifier.invisible"> Ecommerce <div class="checkmark reverse"></div>
            </label>
            <label class="">
                <input type="checkbox" v-model="modalModifier.hide_virtual_menu"> Menú Virtual <div class="checkmark reverse"></div>
            </label>

            <input-field v-model="modalModifier.printer_description" class="mt15"
            :lines="-3"
            label="Descripción Impresa"
            description="Descripción que se imprime en el voucher cocina y nombre del item en los voucher impresos"
            :placeholder="`[Nombre item](Opcional)\n(Descripción Opcional)`"
            info="Puedes reemplazar el nombre del producto en el voucher impreso agregando en la primera linea entre corchetes [Nuevo Nombre], luego en las líneas siguientes agregar una descripción opcionalmente">
            </input-field>
            

            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Inventario</div>    
            <div class="entry selectable"
            v-for="(stock, index) in modalModifier.inventory_references"
            :key="index"
            @click="modalStock={edit:stock, item:stock.inventory_item, quantity: stock.quantity, required: !!stock.required, bypass_performance: !!stock.bypass_performance}">
                <div class="flex fill align-center">
                    <div class="bold"><i class="icon icon-box cgray1 mr5" title="Inventario requerido" v-if="stock.required"></i><i class="icon icon-forbidden cgray1 mr5" title="Sin rendimiento" v-if="stock.bypass_performance"></i>{{stock.inventory_item}}</div>
                    <div class="flex-fill"></div>
                    <div class="mr10"><span class="bold">{{Util.Number(stock.quantity)}}</span><span class="secondary-text" v-if="inventoryUnits[stock.inventory_item]"> {{inventoryUnits[stock.inventory_item]}}</span></div>
                    <div class="options icon-trash" @click.stop="() => {Confirm({
                        text: 'Eliminar',
                        title: '¿Eliminar referencia a inventario?',
                        class: 'error-color', 
                        function: () => {modalModifier.inventory_references.splice(index, 1)}
                    })}"></div>
                </div>
            </div>
            <div class="secondary-text ta-center mt10" v-if="modalModifier.inventory_references.length == 0">No hay modificadores</div>
            
        </div>
        
        <!--<button slot="actions" class="secondary" @click="modalSearchModifier={}" v-if="modalModifier && !modalModifier.edit">Buscar modificador</button>-->
        <button slot="actions" @click="modalStock={reference: modalModifier}" class="secondary">Enlazar Inventario</button>
        <button slot="actions" class="primary purple" @click="AddModifier()" v-if="modalModifier">{{modalModifier&&modalModifier.edit?'Editar Modificador':'Crear Modificador'}}</button>
        
    </modal>

    <modal v-model="modalStock" dir="center" modalClass="sm" :title="modalStock ? (modalStock.edit ? modalStock.edit.inventory_item : 'Enlazar Inventario') : ''">
        <div class="pa20" v-if="modalStock">

            <input-select v-if="isLocal" class="mb15"
            label="Item de Inventario"
            v-model="modalStock.item"
            :items="inventoryReferences"
            :canType="true">
            </input-select>
            
            <input-field v-else
            v-model="modalStock.item">
            </input-field>

            <input-field class="mb15"
            v-model="modalStock.quantity"
            label="Cantidad"
            :placeholder="`(${inventoryUnits[modalStock.item] ? inventoryUnits[modalStock.item] : 'Cantidad'})`"
            type="float"
            :suffix="inventoryUnits[modalStock.item]?' '+inventoryUnits[modalStock.item]:''">
            </input-field>

            <div class="flex">

                <label class="mr10">
                    <input type="checkbox" v-model="modalStock.required"> Requerido <div class="checkmark"></div>
                </label>
                
                <label class="">
                    <input type="checkbox" v-model="modalStock.bypass_performance"> Sin rendimiento <div class="checkmark"></div>
                </label>

            </div>

            <div v-if="modalStock.required" class="secondary-text mt10 well">Se marcará como no disponible si no hay inventario necesario</div>
            <div v-if="modalStock.bypass_performance" class="secondary-text mt10 well">Se descontará inventario sin aplicar rendimiento</div>
            <!--
            <div class="secondary-text mt10 mb5">Si la cantidad en inventario es menor o igual al umbral, se marcará el item o modificador como no disponible</div>
            <input-field
            v-model="modalStock.availability_threshold"
            label="Umbral de Disponibilidad"
            :placeholder="`(${inventoryUnits[modalStock.item] ? inventoryUnits[modalStock.item] : 'Cantidad'})`"
            type="number">
            </input-field>
            -->

        </div>
        
        <button slot="actions" @click="AddStock()" class="primary purple">{{modalStock.edit?'Editar':'Agregar'}}</button>
        
    </modal>

    <modal v-model="modalSearchGroup" :dir="['right', 'right']" :modalClass="['fill', 'md right h100']" title="Grupos de Modificadores" hideActions>
        
        <input-field v-if="modalSearchGroup" 
        v-model="modalSearchGroup.search" 
        placeholder="Buscar..."
        slot="options"
        pre="search"
        cleareable
        inputClass="secondary"
        :timeout="300"
        :icon="mobile ? 'search' : ''"
        useHeader>
        </input-field>
        
        <div class="pa20" v-if="modalSearchGroup">
            <div class="secondary-text mt10 ta-center" v-if="!modifiersGroups.length">No hay grupos de modificadores</div>
            <div class="entry selectable flex align-center" v-for="(entry, index) in modifiersGroups" :key="index"
            @click="Options([
                {text: 'Copiar Grupo', function: () => {CopyGroup(entry)}},
                {text: 'Referenciar Grupo', function: () => {ReferenceGroup(entry)}},
            ])">
                <div class="flex column flex-fill">
                    <div class="flex align-center"><i v-show="entry.group.id" class="icon icon-link mr5 cgray1"></i>{{entry.group.title}} </div>
                    <div class="secondary-text">{{entry.item.title}} - {{entry.section.title}}</div>    
                </div>
                
                    <div class="bold">{{entry.group.modifiers.length}}</div>
                
                
            </div>
        </div>
    </modal>

    <modal v-model="modalSearchModifier" :dir="['right', 'right']" :modalClass="['fill', 'md right h100']" title="Modificadores" hideActions>
        
        <input-field v-if="modalSearchModifier" 
        v-model="modalSearchModifier.search" 
        inputClass="secondary"
        slot="options"
        pre="search"
        cleareable
        :timeout="300"
        :icon="mobile ? 'search' : ''"
        useHeader>
        </input-field>
        
        <div class="pa20" v-if="modalSearchModifier">
            <div class="entry selectable" v-for="(entry, index) in modifiers" :key="index"
            @click="Options([
                {text: 'Copiar Modificador', function: () => {CopyModifier(entry)}},
                {text: 'Referenciar Modificador', function: () => {ReferenceModifier(entry)}},
            ])">
                <div class="flex fill align-center">
                    <div class="flex column flex-fill">
                        <div><i v-show="entry.modifier.id" class="icon icon-link mr5 cgray1"></i>{{entry.modifier.title}}</div>
                        <div class="secondary-text">{{entry.group.title}} - {{entry.item.title}} - {{entry.section.title}}</div>
                    </div>
                    <div class="bold">{{Util.Price(entry.modifier.price)}}</div>
                </div>
            </div>
        </div>
    </modal>

    <modal v-model="modalModifierReplacement" :dir="['right', 'right']" :modalClass="['fill', 'sm right h100']" title="Opciones de Reemplazo">

        <div class="pa20" v-if="modalModifierReplacement">

            <div class="mb20 flex align-center bgbg1 sticky pb10 pt10">
                <div class="flex-fill">
                    <div class="bold">{{modalModifierReplacement.modifier.title}}</div>
                    <div class="secondary-text">{{modalModifierReplacement.group.title}}</div>
                </div>
                <div class="bold">{{Util.Price(modalModifierReplacement.modifier.price)}}</div>
            </div>

            <div class="entry selectable" @click="AddReplacementOption(modifier)"
            v-for="(modifier, modIndex) in modalModifierReplacement.group.modifiers_replacement"
            :key="'replacement-'+modIndex">
                <div class="flex fill align-center">
                    <div :class="`check ${modalModifierReplacement.modifier.replacement_options[modifier.title] ? 'selected' : ''} mr10`"></div>
                    <div class="flex flex-fill align-center">
                        <div class="flex column flex-fill">
                            <div class="flex align-center">
                                
                                <i class="icon icon-link cgray1 mr5" v-if="modifier.id" title="Modificador referenciado"></i>
                                <div class="flex column">
                                    <span class="">{{modifier.title}}</span>
                                    <div class="flex">
                                        <span class="menu-tag pos" v-if="!modifier.hide_pos"></span>
                                        <span class="menu-tag ec" v-if="!modifier.invisible"></span>
                                        <span class="menu-tag vm" v-if="!modifier.hide_virtual_menu"></span>
                                    </div>
                                </div>
                                <!-- <span class="secondary-text ml5" v-if="modifier.unavailable">(No disponible)</span>-->
                            </div>
                            
                            <div class="secondary-text">{{modifier.description}}</div>
                        </div>
                        <i class="icon icon-forbidden mr5 cred" v-if="modifier.unavailable" title="No disponible"></i>
                        <div class="bold" v-if="modifier.price != 0">{{Util.Price(modifier.price)}}</div>
                        <div class="secondary-text unshrink" v-else>Sin Costo</div>
                    </div>
                </div>
            </div>
        </div>

        <button class="secondary" slot="actions" @click="AddReplacementOption('ALL')">Seleccionar Todos</button>
        <button class="primary purple" slot="actions" @click="modalModifierReplacement = null">Aceptar</button>

    </modal>

</modal>
</template>

<script>
export default {
    props: {
        categories: {
            type: Array,
            default: null
        },
        inventory: {
            type: Object,
            default: null
        },
        menu: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            tab: null,
            drag: false,
            modal: false,
            isNew: false,
            type: 0,
            product: {},
            item: null,
            categorySearch: '',
            modifierTitle: null,
            modifierPrice: null,
            modalModifierCategory: false,
            modalModifier: null,
            modalModifierGroup: null,
            editCategory: {},
            editModifier: {},
            currentModifierCategory: null,
            modalStock: false,
            currentStockItem: null,
            editStock: {},
            dictStockItem: {},
            menuSection: null,
            originalTitle: null,
            showModifiers: false,
            modalSearchGroup: null,
            modalSearchModifier: null,
            modalModifierReplacement: null,
            inMotionGroups: false,
        }
    },
    mounted() {
        
    },
    methods: {
        Open(item) {
            this.modal = true;
            this.drag = false;
            this.isNew = false;
            var copy = JSON.parse(JSON.stringify(item));
            this.originalTitle = copy.title;
            if(Array.isArray(copy.categories)) copy.categories = {};
            if(copy.type == 1) 
            {
                if(!copy.products) copy.products = [];
                else if(this.Util.IsObject(copy.products)) copy.products = Object.values(copy.products);
                var products = [];
                copy.products.forEach(item => {
                    for(var i = 0; i < item.quantity; i++)
                    {
                        products.push({id: item.id, title: item.title, quantity: 1, info: item.info ? item.info : ''});
                    }
                });
                copy.products = products;
            }

            this.item = copy;
            this.modifierReplacement = null;
        },
        New(data)
        {
            this.modal = true;
            this.drag = false;
            this.isNew = true;
            this.item = {
                type: data.type,
                modifiers: [],
                categories: {},
                inventory_references: [],
                image: 'NO_IMAGE.png',
                hide_delivery: false,
                hide_takeaway: false,
                products: data.type == 1 ? [] : null
            };
            this.menuSection = data.sectionIndex;

        },
        Save() {
            let hasErrorModifiersDefault = false;

            this.item.modifiers.forEach(group => {
                if (group.errorSelectModifier) {
                    this.Info(`El grupo ${group.title} requiere modificar sus valores por defecto`);
                    return hasErrorModifiersDefault = true;
                }
            });

            if(hasErrorModifiersDefault) return;

            if(!this.item.title) return this.Info('Nombre inválido');
            if(this.item.anticipation && !this.item.anticipation_time) return this.Info('El tiempo de anticipacion es requerido');
            if(this.item.max_quantity_enabled && !this.item.max_quantity) return this.Info('La cantidad máxima es requerida');
            if(isNaN(this.item.price) || this.item.price < 0) return this.Info('Precio inválido');
            this.$root.$emit('Loading');
            if(this.isNew)
            {
                axios.put(`/menu/item/${this.Local.id}/${this.menuSection}`, this.item).then(res => {
                    this.$root.$emit('Response', res);
                    this.$emit('UpdateMenu', res.data);
                    this.modal = false;
                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            }
            else
            {
                axios.patch(`/menu/item/${this.item.id}`, this.item).then(res => {
                    this.$root.$emit('Response', res);
                    this.$emit('UpdateMenu', res.data);
                    this.modal = false;
                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            }
        },
        AddModifier()
        {
            var source = this.modalModifier && this.modalModifier.source ? this.modalModifier.source : 'modifiers';
            //if(this.modalModifier.title.trim() == '' || this.modalModifier.group.filter((a) => {return (this.modalModifier.originalTitle !== a.title.trim()) && a.title.trim() == this.modalModifier.title.trim()}).length > 0)
            if(this.modalModifier.title.trim() == '')
                return this.$root.$emit('Response', {info: 'Nombre de modificador inválido.'});

            if(this.modalModifier.edit)
            {
                var mod = {
                    title: this.modalModifier.title,
                    price: parseInt(this.modalModifier.price),
                    invisible: this.modalModifier.invisible,
                    hide_pos: !!this.modalModifier.hide_pos,
                    hide_virtual_menu: !!this.modalModifier.hide_virtual_menu,
                    unavailable: this.modalModifier.unavailable,
                    inventory_references: this.modalModifier.inventory_references,
                    description: this.modalModifier.description,
                    replacement_options: this.modalModifier.replacement_options ? this.modalModifier.replacement_options : null,
                    printer_description: this.modalModifier.printer_description ? this.modalModifier.printer_description : null,
                    id: this.modalModifier.id,
                    auto_select: this.modalModifier.auto_select ? this.modalModifier.auto_select : null
                };

                if(this.modalModifier.group.id)
                {
                    this.$set(this.modalModifier.group[source], this.modalModifier.editIndex, mod);
                    for(var i = 0; i < this.item.modifiers.length; i++)
                    {
                        if(this.item.modifiers[i].id == this.modalModifier.group.id)
                        {
                            this.$set(this.item.modifiers, i, this.modalModifier.group);
                        }
                    }
                }
                else if(mod.id)
                {
                    for(var i = 0; i < this.item.modifiers.length; i++)
                    {
                        var group = this.item.modifiers[i];
                        for(var j = 0; j < group.modifiers.length; j++)
                        {
                            if(group.modifiers[j].id == mod.id)
                                this.$set(this.item.modifiers[i].modifiers, j, mod);
                        }
                        if(group.modifiers_replacement)
                        {
                            for(var j = 0; j < group.modifiers_replacement.length; j++)
                            {
                                if(group.modifiers_replacement[j].id == mod.id)
                                    this.$set(this.item.modifiers[i].modifiers_replacement, j, mod);
                            }
                        }
                    }
                }
                else
                    this.$set(this.modalModifier.group[source], this.modalModifier.editIndex, mod);

            }
            else
            {
                this.modalModifier.group[source].push({
                    title: this.modalModifier.title,
                    price: parseInt(this.modalModifier.price),
                    invisible: this.modalModifier.invisible,
                    hide_pos: !!this.modalModifier.hide_pos,
                    hide_virtual_menu: !!this.modalModifier.hide_virtual_menu,
                    unavailable: this.modalModifier.unavailable,
                    inventory_references: this.modalModifier.inventory_references,
                    description: this.modalModifier.description,
                    printer_description: this.modalModifier.printer_description ? this.modalModifier.printer_description : null,
                    auto_select: this.modalModifier.auto_select ? this.modalModifier.auto_select : null
                });
            }
            this.modalModifier = false;
        },
        EditModifier(modifier, group, index, source) {
            
            if(!source) source = 'modifiers';
            var data = JSON.parse(JSON.stringify(modifier));
            data.edit = true;
            data.group = group;
            data.editIndex = index;
            data.originalTitle = data.title;
            data.source = source;
            if(!data.inventory_references) data.inventory_references = [];
            this.modalModifier = data;
        },
        AddModifierGroup()
        {
            if(this.modalModifierGroup.title.trim() == '' || (this.item.modifiers.filter((a) => {return (this.modalModifierGroup.originalTitle !== a.title.trim()) && a.title.trim().toUpperCase() == this.modalModifierGroup.title.trim().toUpperCase()})).length > 0)
                return this.$root.$emit('Response', {info: 'Título del grupo de modificadores inválido'});

            if(isNaN(this.modalModifierGroup.min) || isNaN(this.modalModifierGroup.max) || (this.modalModifierGroup.max > 0 && this.modalModifierGroup.min > this.modalModifierGroup.max)) return this.Info('Mínimo/Máximo inválido');

            if(this.modalModifierGroup.edit)
            {
                var group = {
                    title: this.modalModifierGroup.title,
                    max: parseInt(this.modalModifierGroup.max),
                    min: parseInt(this.modalModifierGroup.min),
                    modifiers: this.modalModifierGroup.modifiers,
                    invisible: this.modalModifierGroup.invisible,
                    hide_pos: !!this.modalModifierGroup.hide_pos,
                    hide_virtual_menu: !!this.modalModifierGroup.hide_virtual_menu,
                    inventory_references: [],
                    multi_select: this.modalModifierGroup.multi_select,
                    replacement: this.modalModifierGroup.replacement,
                    modifiers_replacement: this.modalModifierGroup.modifiers_replacement ? this.modalModifierGroup.modifiers_replacement : [],
                    expand: !!this.modalModifierGroup.expand,
                    id: this.modalModifierGroup.id
                };
                if(group.id)
                {
                    for(var i = 0; i < this.item.modifiers.length; i++)
                    {
                        if(this.item.modifiers[i].id == group.id)
                        {
                            this.$set(this.item.modifiers, i, group);
                        }
                    }
                }
                else
                {
                    this.$set(this.item.modifiers, this.modalModifierGroup.editIndex, group);
                }
            }
            else
            {
                this.item.modifiers.push({
                    title: this.modalModifierGroup.title,
                    max: parseInt(this.modalModifierGroup.max),
                    min: parseInt(this.modalModifierGroup.min),
                    modifiers: this.modalModifierGroup.modifiers ? this.modalModifierGroup.modifiers : [],
                    invisible: this.modalModifierGroup.invisible,
                    hide_pos: !!this.modalModifierGroup.hide_pos,
                    hide_virtual_menu: !!this.modalModifierGroup.hide_virtual_menu,
                    inventory_references: [],
                    multi_select: this.modalModifierGroup.multi_select,
                    expand: !!this.modalModifierGroup.expand,
                    replacement: !!this.modalModifierGroup.replacement,
                    modifiers_replacement: this.modalModifierGroup.modifiers_replacement ? this.modalModifierGroup.modifiers_replacement : [],
                });
                console.log(this.modalModifierGroup);
            }

            this.item.modifiers.forEach(group => {
                group.modifiers.forEach(modifier => {
                    if(group.multi_select) {
                        if(modifier.auto_select === true) {
                            this.$set(modifier, 'auto_select', 1);
                        }
                    } else if(group.replacement) {
                        this.$set(modifier, 'auto_select', false);
                    } else {
                        this.$set(modifier, 'auto_select', modifier.auto_select > 0? true : false);
                    }
                });

                let totalAutoSelect = this.Util.Copy(group.modifiers).reduce((sum, modifier) => {
                    return sum + (modifier.auto_select || 0);
                }, 0)

                this.$set(group, 'errorSelectModifier', !totalAutoSelect? false : (group.min !== 0 && totalAutoSelect < group.min) || (group.max !== 0 && totalAutoSelect > group.max));
            });
            
            this.modalModifierGroup = null;
        },
        EditModifierGroup(group, index) {
            var data = JSON.parse(JSON.stringify(group));
            data.edit = true;
            data.editIndex = index;
            data.originalTitle = group.title;
            this.modalModifierGroup = data;
        },
        AddStock()
        {
            console.log(this.modalStock);
            if(!this.modalStock.item || this.modalStock.item.trim() == '' || isNaN(this.modalStock.quantity) || !this.modalStock.quantity)
                return this.$root.$emit('Response', {info: 'Datos inválidos'});

            //var availability_threshold = isNaN(this.modalStock.availability_threshold) ? null : (this.modalStock.availability_threshold < 0 ? 0 : this.modalStock.availability_threshold);

            if(this.modalStock.reference)
            {
                this.modalStock.reference.inventory_references.push({
                    'inventory_item': this.modalStock.item.trim().toUpperCase(), 
                    'quantity': this.modalStock.quantity,
                    'required': !!this.modalStock.required,
                    'bypass_performance': this.modalStock.bypass_performance,
                    //'availability_threshold': availability_threshold
                });
            }
            else if(this.modalStock.edit)
            {
                this.$set(this.modalStock.edit, 'inventory_item',this.modalStock.item.trim().toUpperCase());
                this.$set(this.modalStock.edit, 'quantity',this.modalStock.quantity);
                this.$set(this.modalStock.edit, 'required', !!this.modalStock.required);
                this.$set(this.modalStock.edit, 'bypass_performance', !!this.modalStock.bypass_performance);
                //this.$set(this.modalStock.edit, 'availability_threshold', availability_threshold);
            }
            this.modalStock = false;
        },
        OnDropGroup(data) {
            if(data.addedIndex === data.removedIndex) return;
            var group = this.item.modifiers.splice(data.removedIndex, 1)[0];
            this.item.modifiers.splice(data.addedIndex, 0, group);
        },
        OnDropModifier(drop, group) {
            if(drop.addedIndex === drop.removedIndex) return;
            var modifier = group.modifiers.splice(drop.removedIndex, 1)[0];
            group.modifiers.splice(drop.addedIndex, 0, modifier);
        },
        DuplicateModifiersGroup(group)
        {
            var copy = this.Util.Copy(group);
            this.item.modifiers.push(copy);
        },
        CopyGroup(entry)
        {
            var copy = this.Util.Copy(entry.group);
            copy.modifiers.forEach(modifier => {
                if(copy.replacement && modifier.replacement_options && Array.isArray(modifier.replacement_options))
                {
                    let dictReplacement = {};
                    modifier.replacement_options.forEach(replacement => {
                        dictReplacement[replacement.title] = true;
                    });
                    modifier.replacement_options = dictReplacement;
                }
                else
                {
                    modifier.replacement_options = null;
                }
            });
            this.modalModifierGroup = copy;
            this.modalSearchGroup = null;
        },
        ReferenceGroup(entry)
        {

            if(!entry.group.id)
            {
                this.Confirm({
                    title: '¿Se creará un enlace al grupo de modificadores seleccionado?',
                    text: 'Continuar',
                    class: 'selected-color',
                    function: () => {
                        this.Loading();
                        axios.patch(`/menu/${this.Local.id}/group/reference`, {item: entry.item.id, group: entry.indexGroup}).then(res => {
                            this.item.modifiers.push(res.data.group);
                            if(entry.item.id == this.item.id)
                                this.$set(this.item.modifiers, entry.indexGroup, this.Util.Copy(res.data.group));
                            this.modalSearchGroup = null;
                            this.modalModifierGroup = null;
                            this.$emit('UpdateMenu', res.data.menu);
                            this.Response(res);
                        }).catch(err => {
                            this.Response(err);
                        });
                    }
                });
            }
            else
            {
                var copy = this.Util.Copy(entry.group);
                this.item.modifiers.push(copy);
                this.modalSearchGroup = null;
                this.modalModifierGroup = null;
            }
        },
        CopyModifier(entry)
        {
            var group = this.modalSearchModifier.group;
            var copy = this.Util.Copy(entry.modifier);
            delete copy.id;
            copy.group = group;
            this.modalModifier = copy;
            this.modalSearchModifier = null;
        },
        ReferenceModifier(entry)
        {
            if(!entry.modifier.id)
            {
                this.Confirm({
                    title: '¿Se creará un enlace al modificador seleccionado?',
                    text: 'Continuar',
                    class: 'selected-color',
                    function: () => {
                        this.Loading();
                        axios.patch(`/menu/${this.Local.id}/modifier/reference`, {item: entry.item.id, group: entry.indexGroup, modifier: entry.indexModifier}).then(res => {
                            var group = this.Util.Copy(this.modalSearchModifier.group);
                            group.originalTitle = group.title;
                            group.modifiers.push(res.data.modifier);
                            group.edit = true;
                            group.editIndex = this.modalSearchModifier.groupIndex;
                            if(entry.item.id == this.item.id)
                                this.$set(this.item.modifiers[entry.indexGroup].modifiers, entry.indexModifier, this.Util.Copy(res.data.modifier));
                            this.modalModifierGroup = group;
                            this.AddModifierGroup();
                            this.modalSearchModifier = null;
                            this.modalModifier = null;
                            this.$emit('UpdateMenu', res.data.menu);
                            this.Response(res);
                        }).catch(err => {
                            this.Response(err);
                        });
                    }
                });
            }
            else
            {
                var group = this.Util.Copy(this.modalSearchModifier.group);
                group.originalTitle = group.title;
                group.modifiers.push(entry.modifier);
                group.edit = true;
                group.editIndex = this.modalSearchModifier.groupIndex;
                this.modalModifierGroup = group;
                this.AddModifierGroup();
                this.modalSearchModifier = null;
                this.modalModifier = null;
            }
        },
        AddReplacementOption(modifier)
        {
            var parentMod = this.modalModifierReplacement.modifier;
            if(!parentMod.replacement_options) this.$set(parentMod, 'replacement_options', {});

            if(modifier == 'ALL')
            {
                this.modalModifierReplacement.group.modifiers_replacement.forEach(replacementMod => {
                    this.$set(parentMod.replacement_options, replacementMod.title, true);        
                });
                return;
            }

            var key = modifier.title;
            if(!parentMod.replacement_options[key]) this.$set(parentMod.replacement_options, key, true);
            else this.$delete(parentMod.replacement_options, key);
        },
        OpenReplacementOptions(modifier, modIndex, group, groupIndex)
        {
            console.log(modifier);
            if(!modifier.replacement_options || Array.isArray(modifier.replacement_options)) this.$set(modifier, 'replacement_options', {});

            this.modalModifierReplacement = {group: group, groupIndex: groupIndex, modifier: modifier, modIndex: modIndex};
        },
        AddComboSubItem(subitem)
        {
            for(var i = 0; i < subitem.quantity; i++)
                this.item.products.push({id: subitem.id, title: subitem.title, quantity: 1});
        },
        ToggleSelectDefault(){
            this.$set(this.item, 'selectDefault', this.item.selectDefault === undefined? true : !this.item.selectDefault);
        },
        ModifyQuantityModifier(group, groupIndex, modifierIndex, quantity){
            let totalAutoSelect = group.modifiers.reduce((sum, mod) => sum + (mod.auto_select || 0), 0);
            
            const modifier = this.item.modifiers[groupIndex].modifiers[modifierIndex];

            modifier.auto_select = modifier.auto_select !== undefined ? modifier.auto_select : (group.multi_select ? 0 : false);

            const newValue = group.multi_select? modifier.auto_select + quantity : !modifier.auto_select;

            group.multi_select? totalAutoSelect += quantity : (newValue === true? totalAutoSelect++ : totalAutoSelect--);
            const isInvalidOperation = !totalAutoSelect? false : (group.min !== 0 && totalAutoSelect < group.min) || (group.max !== 0 && totalAutoSelect > group.max);
            
            const updatedModifier = {
                ...modifier,
                auto_select: group.multi_select ? 
                    (newValue >= 0 ? newValue : 0) : 
                    newValue
            };

            this.$set(group, 'errorSelectModifier', isInvalidOperation);
            this.$set(this.item.modifiers[groupIndex].modifiers, modifierIndex, updatedModifier);
            // this.$set(modifier, 'auto_select', group.multi_select? (newValue >= 0 ? newValue : 0) : newValue);
        }
    },
    computed: {
        isLocal() {
            return this.Local.type == 'LOCAL';
        },
        tabs() {
            var ret = [];
            if(!this.item) return ret;
            if(this.item.type == 1) return ['General', 'Productos'];
            ret.push('General', 'Modificadores');
            if(this.Local.modules.module_inventory) ret.push('Inventario');
            ret.push('Categorias');
            return ret;
        },
        inventoryReferences(){
            if(!this.inventory) return [];
            var ret = [];
            for(var key in this.inventory.items)
                ret.push({text: `${this.inventory.items[key].title}`, value: this.inventory.items[key].title});
            return ret;
        },
        inventoryUnits(){
            if(!this.inventory) return {};
            var ret = {};
            for(var key in this.inventory.items)
                ret[this.inventory.items[key].title] = this.inventory.items[key].unit;
            return ret;
        },
        modifierInventory() {
            if(!this.item || !this.item.modifiers) return [];
            var ret = [];
            for(var i = 0; i < this.item.modifiers.length; i++)
            {
                for(var j = 0; j < this.item.modifiers[i].modifiers.length; j++)
                {
                    if(this.item.modifiers[i].modifiers[j].inventory_references)
                    {
                        for(var k = 0; k < this.item.modifiers[i].modifiers[j].inventory_references.length; k++)
                        {
                            ret.push({
                                groupIndex: i,
                                modifierIndex: j,
                                stockIndex: k,
                                reference: this.item.modifiers[i].modifiers[j].inventory_references[k],
                                inventory_item: this.item.modifiers[i].modifiers[j].inventory_references[k].inventory_item,
                                quantity: this.item.modifiers[i].modifiers[j].inventory_references[k].quantity,
                                required: !!this.item.modifiers[i].modifiers[j].inventory_references[k].required,
                                bypass_performance: !!this.item.modifiers[i].modifiers[j].inventory_references[k].bypass_performance,
                                group: this.item.modifiers[i].title,
                                modifier: this.item.modifiers[i].modifiers[j].title,
                                source: 'modifiers'
                            });
                        }
                    }
                }

                if(this.item.modifiers[i].replacement && this.item.modifiers[i].modifiers_replacement)
                {
                    for(var j = 0; j < this.item.modifiers[i].modifiers_replacement.length; j++)
                    {
                        if(this.item.modifiers[i].modifiers_replacement[j].inventory_references)
                        {
                            for(var k = 0; k < this.item.modifiers[i].modifiers_replacement[j].inventory_references.length; k++)
                            {
                                ret.push({
                                    groupIndex: i,
                                    modifierIndex: j,
                                    stockIndex: k,
                                    reference: this.item.modifiers[i].modifiers_replacement[j].inventory_references[k],
                                    inventory_item: this.item.modifiers[i].modifiers_replacement[j].inventory_references[k].inventory_item,
                                    quantity: this.item.modifiers[i].modifiers_replacement[j].inventory_references[k].quantity,
                                    required: !!this.item.modifiers[i].modifiers_replacement[j].inventory_references[k].required,
                                    group: this.item.modifiers[i].title,
                                    modifier: this.item.modifiers[i].modifiers_replacement[j].title,
                                    source: 'modifiers_replacement',
                                    replacement: true
                                });
                            }
                        }
                    }
                }
            }
            return ret;
        },
        modifiersGroups() {
            if(!this.menu) return [];
            var ret = [];
            var search = this.modalSearchGroup && this.modalSearchGroup.search ? this.modalSearchGroup.search : '';
            for(var indexSection = 0; indexSection < this.menu.length; indexSection++)
            {
                var section = this.menu[indexSection];
                section.items.forEach(item => {
                    for(var indexGroup = 0; indexGroup < item.modifiers.length; indexGroup++)
                    {
                        var group = item.modifiers[indexGroup]
                        if(this.Util.InString(group.title, search))
                            ret.push({group: group, item: item, section: section, indexSection: indexSection, indexGroup: indexGroup});
                    }
                });
            };
            return ret;
        },
        modifiers() {
            if(!this.menu) return [];
            var ret = [];
            var search = this.modalSearchModifier && this.modalSearchModifier.search ? this.modalSearchModifier.search : '';
            for(var indexSection = 0; indexSection < this.menu.length; indexSection++)
            {
                var section = this.menu[indexSection];
                section.items.forEach(item => {
                    for(var indexGroup = 0; indexGroup < item.modifiers.length; indexGroup++)
                    {
                        var group = item.modifiers[indexGroup]
                        for(var indexModifier = 0; indexModifier < group.modifiers.length; indexModifier++)
                        {
                            var modifier = group.modifiers[indexModifier];
                            if(this.Util.InString(modifier.title, search))
                                ret.push({group: group, item: item, section: section, indexSection: indexSection, indexGroup: indexGroup, modifier: modifier, indexModifier: indexModifier});
                        }
                    }
                });
            };
            return ret;
        },
        currentlySelectedSubitems()
        {
            var ret = {};
            if(!this.item || this.item.type == 0 || !this.item.products) return ret;
            this.item.products.forEach(subitem => {
                console.log(subitem);
                if(!ret[subitem.id]) ret[subitem.id] = subitem.quantity;
                else ret[subitem.id] += subitem.quantity;
            });
            console.log(ret);
            return ret;
        },
        dictItems() {
            var ret = {};
            if(!this.menu || !this.item || this.item.type !== 1) return ret;
            this.menu.forEach(section => {
                section.items.forEach(item => {
                    ret[item.id] = true;
                });
            });
            return ret;
        }
    },
    watch: {
        tab: function(n, o) {
            if(n == 'Modificadores') {
                setTimeout(() => {
                    this.showModifiers = true;
                }, 100);
            }
            else
            {
                this.showModifiers = false;
            }
        }
    }
}
</script>

<style>
.category-tag {
    display: inline-block;
    background-color: #00b5ff;
    border: 2px solid white;
    color: var(--white);
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 3px;
}

.category-tag-unselected {
    display: inline-block;
    background-color: var(--white);
    border: 2px solid #00b5ff;
    color: #00b5ff;
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 3px;
}
</style>
