<template>
<div :class="`flex-fill fill flex column horizontal-scroll scroll-no-spacing ${Auth.role == 'waiter' ? 'no-padding' : ''}`" ref="mainContainer" id="pending-main-container">
    
    <sales-view v-show="view == 'POS'" ref="salesView">
    </sales-view>

    <iap-controller v-model="iap" v-if="Auth.role !== 'waiter'"
    :screenNotification="view == 'IAP'" 
    @UpdateTransactions="Update()"
    ref="iapController"></iap-controller>

    <div class="opt r" v-show="view !== 'POS' && view !== 'DELIVERY'">
        
        <button v-if="view == 'TABLES' && Auth.role == 'waiter' && !mobile" class="error-color mr10" @click="LogOut(true)"><i class="icon icon-exit mr10"></i>Cerrar sesión</button>
        
        <input-field class="" v-if="view == 'TABLES'"
        v-model="searchTable"
        inputClass="secondary"
        placeholder="Buscar mesa, fecha o nombre de reserva..."
        pre="search"
        cleareable
        :icon="mobile ? 'search' : ''"
        useHeader>
        </input-field>


        <input-select class="ml5" v-if="view == 'TABLES'" ref="selectTablesSort"
        v-model="tablesSort"
        inputClass="secondary"
        :items="itemsTablesOrderBy"
        :icon="mobile ? 'sort' : ''">
        </input-select>
        

        <input-field inputClass="secondary"
        v-show="view !== 'TABLES' && view !== 'IAP' && view !== 'SCHEDULED'"
        class="ml5"
        placeholder="Buscar"
        pre="search"
        cleareable
        v-model="searchPending"
        :icon="mobile ? 'search' : ''"
        useHeader>
        </input-field>

        <input-select ref="selectFilter" inputClass="secondary"
        v-show="view !== 'KITCHEN' && view !== 'TABLES' && view !== 'IAP' && view !== 'SCHEDULED'"
        class="ml5"
        v-model="filter"
        :icon="mobile ? 'filter' : ''"
        :items="itemsFilterBy">
        </input-select>

        <input-select ref="selectSort"
        v-show="view !== 'TABLES' && view !== 'IAP' && view !== 'SCHEDULED'"
        class="ml5" inputClass="secondary"
        v-model="sort"
        :icon="mobile ? 'sort' : ''"
        :items="itemsOrderBy">
        </input-select>
        
        <input-field
        v-show="view == 'IAP' || view == 'SCHEDULED'"
        class="ml5"
        placeholder="Buscar"
        v-model="searchIAP"
        inputClass="secondary"
        pre="search"
        cleareable
        :icon="mobile ? 'search' : ''"
        useHeader>
        </input-field>

        <i v-show="view == 'IAP'" class="ml5 options icon-dots-vertical" title="Ajustes de Pedidos Online" ref="btnIAPSettings" @click="$refs.iapController.OpenSettings($refs.btnIAPSettings)"></i>

        <popover-menu ref="popoverCalendar" icon="datetime ml5" v-show="view == 'SCHEDULED'" body="popover pa0">

            <div v-if="scheduledDateFilter" class="well flex align-center ml20 mt20 mr20" style="margin-bottom: -10px">
                <div class="flex-fill">
                    <div>{{scheduledDateFilter}}</div>
                    <div class="secondary-text">{{Util.Date(scheduledDateFilter, 'dddd D [de] MMMM', null, 'DD/MM/YYYY')}}</div>
                </div>
                <i class="options icon-cross" @click="$refs.popoverCalendar.Close(); scheduledDateFilter=null"></i>
            </div>
            
            <calendar v-model="scheduledDateFilter"
            :counters="scheduledDays">
            </calendar>

        </popover-menu>

        <!--
        <i v-if="canComplete || canCancel"  class="ml5 options icon-dots-vertical"
        @click="Options([
            {text: 'Cancelar Pedidos', class: 'error-color', function: CancelMultiple, if: canCancel && visibleTickets.length > 0},
            {text: 'Completar Pedidos', class: 'selected-color', function: CompleteMultiple, confirm: `Se intentarán completar ${visibleTickets.length} pedidos ¿Desea continuar?`, if: canComplete && visibleTickets.length > 0},
        ])"></i>
        -->

        <popover-menu class="ml5" icon="dots-vertical" maxWidth="340px" v-show="view !== 'IAP' && view !== 'SCHEDULED'">
            <div>

                <div v-if="(view !== 'TABLES' && view !== 'KITCHEN') && (canComplete || canCancel) && visibleTickets.length > 0">
                    
                    <button class="selected-color"
                    v-if="canComplete"
                    @click="Confirm({
                        title: `Se intentarán completar ${visibleTickets.length} pedidos ¿Desea continuar?`,
                        text: 'Completar Pedidos',
                        function: () => {CompleteMultiple()}
                    })">Completar Pedidos</button>

                    <button class="error-color justify-center" 
                    v-if="canCancel"
                    @click="CancelMultiple()">Cancelar Pedidos</button>

                    <div class="separator mb20 mt20"></div>
                </div>

                <div v-if="view == 'KITCHEN'">
                    <div class="flex align-center mb10">
                        <div class="bold flex-fill">Tamaño letra</div>
                        <i class="options icon-minus mr5" title="Disminuir tamaño de letra" @click="ChangeFontSize(-1)"></i>
                        <i class="options icon-plus" title="Aumentar tamaño de letra" @click="ChangeFontSize(1)"></i>
                    </div>

                    
                    <div v-if="view == 'KITCHEN'" class="flex align-center mb10 selectable" @click="OpenModalHideItemsInDisplay()" title="Selecciona productos/secciones que se ocultarán en el Display de Cocina">
                        <div class="bold flex-fill">Seleccionar items visibles</div>
                        <i class="options icon-cog"></i>
                    </div>

                    <input-toggle class="mb10"
                    v-model="kitchenShowPrepared"
                    @OnValueChanged="Util.StoreLocal('kitchenShowPrepared', $event, true)"
                    label="Mostrar items preparados">
                    </input-toggle>

                    <input-toggle class="mb10"
                    v-model="kitchenShowDelivered"
                    @OnValueChanged="Util.StoreLocal('kitchenShowDelivered', $event, true)"
                    label="Mostrar items entregados">
                    </input-toggle>

                    <div class="separator mb20 mt20"></div>
                </div>

                <div class="">
                    <input-toggle class="mb10"
                    v-model="autoPrintOnEdit"
                    @OnValueChanged="Util.StoreLocal('autoPrintOnEdit', $event, true)"
                    label="Impresión automática pedidos modificados"
                    description="Se imprimirá automáticamente cuando se modifique un pedido">
                    </input-toggle>

                    <input-toggle class="mb10"
                    v-model="autoPrintNewOnly"
                    v-if="autoPrintOnEdit"
                    @OnValueChanged="Util.StoreLocal('autoPrintNewOnly', $event, true)"
                    label=""
                    description="Imprimir sólo productos nuevos agregados al pedido">
                    </input-toggle>

                    <input-toggle class="mb10"
                    v-model="autoPrintTableOnEdit"
                    @OnValueChanged="Util.StoreLocal('autoPrintTableOnEdit', $event, true)"
                    label="Impresión automática mesas modificadas"
                    reverse
                    description="Se imprimirá automáticamente cuando se modifique el pedido de una mesa">
                    </input-toggle>

                    <input-toggle class="mb10"
                    v-model="autoPrintTableNewOnly"
                    v-if="!autoPrintTableOnEdit"
                    @OnValueChanged="Util.StoreLocal('autoPrintTableNewOnly', $event, true)"
                    reverse
                    description="Imprimir sólo productos nuevos agregados a la mesa">
                    </input-toggle>

                    <input-toggle class="mb10"
                    v-model="autoPrintCancelled"
                    @OnValueChanged="Util.StoreLocal('autoPrintCancelled', $event, true)"
                    label="Impresión automática pedidos cancelados"
                    description="Se imprimirá automáticamente cuando se cancele un pedido">
                    </input-toggle>

                    <input-toggle v-model="attentionSoundEnabled" class="mb10"
                    @OnValueChanged="Util.StoreLocal('attentionSoundEnabled', $event, true)"
                    label="Sonido de Alerta Pedido Ingresado"
                    description="Sonido que reproduce cuando se ingresa un pedido">
                    </input-toggle>


                    <input-toggle v-model="attentionPreparedSoundEnabled" class="mb10"
                    @OnValueChanged="Util.StoreLocal('attentionPreparedSoundEnabled', $event, true)"
                    label="Sonido de Alerta Productos Preparados"
                    description="Sonido que reproduce cuando un producto se marca como Preparado en display de Cocina">
                    </input-toggle>

                    <input-toggle v-model="showCreatedByEnabled"
                    @OnValueChanged="Util.StoreLocal('showCreatedByEnabled', $event, true)"
                    label="Mostrar usuario que ingresa pedido"
                    description="Se mostrará el usuario que ingresó el pedido">
                    </input-toggle>

                </div>

                

            </div>
        </popover-menu>

    </div>

    <!-- Left Menu -->
    <div class="left-menu" v-if="Auth.role !== 'waiter' && Auth.role !== 'delivery'">
        <i v-if="showPos" title="Punto de Venta" @click="view='POS'" :class="`options icon icon-cashier ${view=='POS' ? 'selected' : 'unselected'}`" v-tooltip.right="'Punto de Venta'"></i>
        <i title="Vista lista" @click="view='LIST'" :class="`options icon icon-list ${view=='LIST' ? 'selected' : 'unselected'}`" v-tooltip.right="'Lista'"></i>
        <i v-show="desktop" title="Vista tabla" @click="view='TABLE'" :class="`options icon icon-grid ${view=='TABLE' ? 'selected' : 'unselected'}`"  v-tooltip.right="'Tabla'"></i>
        <i title="Vista tickets" @click="view='TICKETS'" :class="`options icon icon-receipt ${view=='TICKETS' ? 'selected' : 'unselected'}`"  v-tooltip.right="'Tickets'"></i>
        <div v-if="Local.modules.module_tables" title="Vista Mesas" @click="view='TABLES'" :class="`relative options icon icon-table ${view=='TABLES' ? 'selected' : 'unselected'}`" v-tooltip.right="'Mesas'">
            <div class="notif" v-show="usedTablesCount">{{usedTablesCount}}</div>
        </div>
        <i title="Display Cocina" @click="view='KITCHEN'" :class="`options icon icon-spatula ${view=='KITCHEN' ? 'selected' : 'unselected'}`" v-tooltip.right="'Display de Cocina'"></i>
        <i v-if="showDelivery" title="Pedidos Desapachados" @click="view='DELIVERY'" :class="`options icon icon-delivery ${view=='DELIVERY' ? 'selected' : 'unselected'}`"  v-tooltip.right="'Pedidos Despachados'"></i>
        <div v-if="iap && (Local.modules.module_store || Local.modules.module_store_plus)" title="Pedidos Online" @click="view='IAP';$refs.iapController.Checked()" :class="`relative options ${view=='IAP' ? 'selected' : 'unselected'}`" v-tooltip.right="'Pedidos Online'">
            <i :class="`icon icon-attention-bell ${iap.playBell ? 'animated heartBeat infinite cpink' : 'cgray1'}`"></i>
            <div class="notif" v-show="iap.pending.length">{{iap.pending.length}}</div>
        </div>
        <div v-if="iap" title="Pedidos Agendados" @click="view='SCHEDULED'" :class="`relative options icon icon-datetime ${view=='SCHEDULED' ? 'selected' : 'unselected'}`" v-tooltip.right="'Pedidos Agendados'">
            <div class="notif" v-show="iap.scheduled.length">{{iap.scheduled.length}}</div>
        </div>
    </div>

    <!-- KITCHEN Mansonry columns -->
    <div v-if="view == 'KITCHEN'" class="pa10 pr0">
        <div class="secondary-text ta-center mt10" v-if="!visibleTickets.length">No hay pedidos pendientes</div>
        <div class="fill flex" v-else>

        <div class="fill flex column mr10 mr0-mobile" :style="{'font-size': `${kitchenFontSize}px !important`}"
        ref="columns"
        v-for="column in columns"
        :key="`column${column}`">
            <div :class="`transaction-ticket ${ticket.status}-ticket`"  v-for="(ticket, index) in ColumnTickets(column)" :key="`ticket${index}${column}`"
            style="margin-bottom: 20px; width: 100% !important; min-width: 100% !important; max-width: 100% !important">
                <div :style="{'background-color': OrderColor(ticket)}" class="pa10 cw flex ticket-header selectable"
                @click="!settings.preparation_step || ticket.custom_data.preparation_at && ticket.status !== 'CANCELLED' ? OrderItemsUpdated('prepared', 'ALL', null, ticket) : StartPreparation(ticket)">
                    <i v-if="ticket.status == 'CANCELLED'" class="icon icon-trash mr10 animated heartBeat infinite"></i>
                    <div class="flex-fill">#{{Util.Zeropad(ticket.id_ticket, 0)}}</div>
                    <div v-html="TicketType(ticket)"></div>
                </div>
                <div class="pa10 flex column c2">
                    <div class="relative cgray1">
                        <div class="flex fill column">
                            <div><span class="bold" v-if="ticket.customer.name">Nombre: </span>{{ticket.customer.name}}</div>
                            <div><span class="bold">Ingresado: </span>{{Util.Date(ticket.created_at, 'HH:mm')}}</div>
                            <div v-if="showCreatedByEnabled"><span class="bold" >Ingresado por: </span>{{ ticket.custom_data['created_by']}} </div>
                            <div v-if="ticket.custom_data.preparation_at"><span class="bold">Preparando: </span>{{Util.Date(ticket.custom_data.preparation_at, 'HH:mm')}}</div>
                            <div v-if="ticket.custom_data && ticket.custom_data.pedidosya_courier"><span class="bold">Estado: </span>{{Util.StatusOrderPya[ticket.custom_data.pedidosya_courier.status]}}</div>
                            <div v-if="ticket.type == 'TAKEAWAY' && ticket.custom_data.activation_time"><span class="bold">Retirar: </span>{{ticket.custom_data.activation_time}} hrs</div>
                            <div v-if="ticket.type == 'DELIVERY' && ticket.custom_data.delivery_time"><span class="bold">Entregar: </span>{{ticket.custom_data.delivery_time}} hrs</div>
                            <div v-if="ticket.status=='CANCELLED'"><span class="bold">Cancelado: </span>{{Util.Date(ticket.cancelled_at, 'HH:mm')}}</div>
                            <div class="mt5 fc" v-if="ticket.status != 'CANCELLED'">
                                <div title="Tiempo desde que se empezó a preparar" v-if="ticket.custom_data.preparation_at && ticket.status != 'DELIVERY'" class="well mini ta-center"><i class="icon icon-spatula"></i> {{ElapsedTime(timer - ticket.custom_data.preparation_at)}}</div>
                                <div class="well mini ta-center" title="Tiempo desde que se ingresó" v-else><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.created_at)}}</div>                              
                            </div>
                        </div>
                    </div>

                    <div class="" style="">
                        
                        <div class="separator"></div>

                        <div class="fill column flex">
                            <div :style="`color : ${KitchenTimesDelays(ticket, order)};`" :class="`order-item selectable flex-fill pa5 ${order.delivered ? 'ready' : (order.prepared ? 'ready attention' : '')} `"
                            v-show="ShowKitchenOrder(order, kitchenShowPrepared, kitchenShowDelivered)"
                            v-for="(order, index2) in ticket.orders"
                            :key="index2"
                            @click="!order.autocomplete && !order.delivered && !order.prepared && ticket.status !== 'CANCELLED' ? OrderItemsUpdated('prepared', order, index2, ticket) : null">
                                <div>{{Util.Number(order.quantity)}}</div>
                                <div>{{order.title}}
                                    <ul class="cgray1" style="margin-left: -40px;">
                                        <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(order)" :key="indexMod">
                                            {{modifier.value}}
                                            <span class="tag-total-modifiers" style="font-size: inherit" v-if="modifier.count > 1">{{modifier.count}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>                

                    <div class="well" v-if="ticket.comment">
                        <div>{{ticket.comment}}</div>
                    </div>

                </div>
            </div>
        </div>
        </div>
    </div>

    <!-- LIST VIEW -->
    <div :class="`fill flex column ${desktop ? 'pa10' : 'pt10 pb10'}`" v-if="view == 'LIST'" style="overflow-x: auto;">
        
        <div v-if="!visibleTickets.length" class="secondary-text ta-center mt10">No hay pedidos pendientes</div>

        <draggable 
        ref="containerTickets"
        :class="`flex-fill flex column`"
        :list="visibleTickets"
        :animation="200"
        handle=".ticket-handle"
        @change="DropTicket">

            <div :class="`${ticket.status}-ticket ticket-row mb10`" style="min-width: 1000px"
            v-for="(ticket, index) in visibleTickets"
            :key="index">
                <div class="flex">
                <div :style="{'background-color': OrderColor(ticket)}" :class="`ticket-color pa10 cw flex column align-center ${sort == 'CUSTOM' ? 'ticket-handle' : ''}`" style="width: 10%">
                    <div class="ticket-type" v-html="TicketType(ticket)"></div>
                    <div>#{{ticket.id_ticket}} <i v-show="ticket.id_boleta || ticket.id_factura" class="icon icon-invoice"></i></div>
                    <i :class="`icon icon-${Util.TransactionSource[ticket.source].icon} mt5`" v-if="ticket.iap && Util.TransactionSource[ticket.source]"></i>
                    <i v-else-if="ticket.status == 'CANCELLED'" class="icon icon-trash mt5 animated heartBeat infinite"></i>
                </div>
                
                <div class="relative w20 br">
                    <div class="pya-banner-ticket" v-if="ticket.custom_data && ticket.custom_data.pedidosya_courier">#{{ticket.custom_data.pedidosya_courier.referenceId}} - {{Util.StatusOrderPya[ticket.custom_data.pedidosya_courier.status]}} - {{Util.Date(ticket.custom_data.pedidosya_courier.lastUpdated, 'HH:mm DD/MMM')}}</div>
                    <div class="pya-banner-ticket" v-if="ticket.custom_data && ticket.custom_data.integration_pya">{{Util.StatusOrderIntegrationPya[ticket.custom_data.integration_pya.status]}}</div>
                    <div class="flex fill column pa10">
                        <!--
                        <div class="flex column" v-if="ticket.custom_data.pedidosya_courier">
                            <div v-if="ticket.status == 'PENDING' && ticket.custom_data.pedidosya_courier.pickupTime"><span class="bold"><i class="icon icon-pedidosya"></i> Recolección: </span>{{Util.Date(ticket.custom_data.pedidosya_courier.pickupTime, 'DD/MM/YYYY HH:mm')}}</div>
                            <div class="mb5" v-if="!ticket.custom_data.delivered_at && ticket.custom_data.pedidosya_courier.deliveryTime"><span class="bold"><i class="icon icon-pedidosya"></i> Entrega: </span>{{Util.Date(ticket.custom_data.pedidosya_courier.deliveryTime, 'DD/MM/YYYY HH:mm')}}</div>
                        </div>
                        -->
                        <div><span class="bold">Ingresado: </span>{{Util.Date(ticket.created_at, 'HH:mm')}} <span class="secondary-text" title="Tiempo desde que se ingresó"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.created_at)}}</span></div>
                        <div v-if="showCreatedByEnabled"><span class="bold" >Ingresado por: </span>{{ ticket.custom_data['created_by']}} </div>
                        <div v-if="ticket.custom_data.preparation_at"><span class="bold">Preparando: </span>{{Util.Date(ticket.custom_data.preparation_at, 'HH:mm')}} <span title="Tiempo desde que se empezó a preparar" v-if="ticket.custom_data.preparation_at && ticket.status != 'DELIVERY'" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.custom_data.preparation_at)}}</span></div>
                        <div v-if="ticket.delivery_at"><span class="bold">Enviado: </span>{{Util.Date(ticket.delivery_at, 'HH:mm')}} <span title="Tiempo desde que se envió" v-if="ticket.delivery_at" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.delivery_at)}}</span></div>
                        <div v-if="ticket.custom_data.delivered_at"><span class="bold">Entregado: </span>{{Util.Date(ticket.custom_data.delivered_at, 'HH:mm')}} <span title="Tiempo desde que se marcó como entregado" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.custom_data.delivered_at)}}</span></div>
                        <div v-if="ticket.custom_data.takeaway_ready"><span class="bold">Listo: </span>{{Util.Date(ticket.custom_data.takeaway_ready, 'HH:mm')}} <span title="Tiempo desde que está listo para retirar" v-if="ticket.custom_data.takeaway_ready" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.custom_data.takeaway_ready)}}</span></div>
                        <div v-if="ticket.cancelled_at"><span class="bold">Cancelado: </span>{{Util.Date(ticket.cancelled_at, 'HH:mm DD/MM/YYYY')}}</div>
                        <div class="flex column mt5" v-show="ticket.status !== 'CANCELLED'">
                            <div v-if="ticket.type != 'TAKEAWAY' && ticket.custom_data.shipment && ticket.custom_data.shipment.shipment_end"><span class="bold">Enviar: </span>entre el {{Util.Date(ticket.custom_data.shipment.shipment_start, 'D [de] MMM')}} y el {{Util.Date(ticket.custom_data.shipment.shipment_end, 'D [de] MMM')}}</div>
                            <div v-if="ticket.type != 'TAKEAWAY' && ticket.custom_data.shipment && !ticket.custom_data.shipment.shipment_end"><span class="bold">Enviar: </span>el {{Util.Date(ticket.custom_data.shipment.shipment_start, 'D [de] MMMM')}} <span v-if="ticket.custom_data.shipment.time">{{ticket.custom_data.shipment.time}}</span></div>
                            <div v-if="ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.pickupTime"><span class="bold">Recolección: </span>{{Util.Date(ticket.custom_data.pedidosya_courier.pickupTime, 'DD/MM HH:mm')}} <i class="icon icon-pedidosya" style="color: var(--color-pya);"></i></div>
                            <div v-if="ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.deliveryTime"><span class="bold">Entregar: </span>{{Util.Date(ticket.custom_data.pedidosya_courier.deliveryTime, 'DD/MM HH:mm')}} <i class="icon icon-pedidosya" style="color: var(--color-pya);"></i></div>
                            <div v-else-if="ticket.type == 'DELIVERY' && ticket.custom_data.delivery_time"><span class="bold">Entregar: </span>{{ticket.custom_data.delivery_time}}</div>
                            <div v-if="ticket.type == 'TAKEAWAY' && ticket.custom_data.activation_time && !ticket.custom_data.shipment"><span class="bold">Retirar: </span>{{ticket.custom_data.activation_time}}</div><!-- Pedido con retiro que no sea shipment) -->
                            <!-- Retiro desde Store -->
                            <div v-if="ticket.type == 'TAKEAWAY' && ticket.custom_data.shipment && ticket.custom_data.shipment.shipment_start_takeaway && !ticket.custom_data.shipment.shipment_end_takeaway"><span class="bold">Retirar: </span>{{Util.Date(ticket.custom_data.shipment.shipment_start_takeaway, `DD/MM/YYYY${ticket.custom_data.shipment.time? ' HH:mm' : ''}`)}}</div>
                            <div v-if="ticket.type == 'TAKEAWAY' && ticket.custom_data.shipment && !ticket.custom_data.shipment.time && ticket.custom_data.shipment.shipment_start_takeaway && ticket.custom_data.shipment.shipment_end_takeaway"><span class="bold">Retirar: </span>entre el {{Util.Date(ticket.custom_data.shipment.shipment_start_takeaway, 'D [de] MMM')}} y el {{Util.Date(ticket.custom_data.shipment.shipment_end_takeaway, 'D [de] MMM')}}</div>
                            <!-- <div v-if="ticket.type == 'TAKEAWAY' && ticket.custom_data.shipment && ticket.custom_data.shipment.shipment_start_takeaway && !ticket.custom_data.shipment.shipment_end_takeaway"><span class="bold">Programado: </span>{{Util.Date(ticket.custom_data.shipment.shipment_start_takeaway, `DD/MM/YYYY${ticket.custom_data.shipment.time? 'HH:mm' : ''}`)}}</div> -->
                            <div v-if="ticket.custom_data.scheduled_at && !ticket.custom_data.shipment"><span class="bold">Programado: </span>{{Util.Date(ticket.custom_data.scheduled_at, 'DD/MM/YYYY HH:mm')}}</div>
                            <!--<div v-if="ticket.custom_data.influye_code"><span class="bold">Código Influye: </span>{{ticket.custom_data.influye_code}}</div>-->
                            <div v-if="ticket.custom_data.delivery_by"><span class="bold">Repartidor: </span>{{ticket.custom_data.delivery_by}}</div>
                        </div>
                    </div>
                </div>

                <div class="pa10 w20 br" @click="$refs.transactionDetails.CustomerOptions(ticket)">
                    <div class="fill flex column selectable relative" v-if="ticket.customer && (ticket.customer.name || ticket.customer.phone || ticket.customer.address)">
                        <div class="flex column">
                            <div v-if="ticket.customer.name"><span class="bold">Nombre: </span>{{ticket.customer.name}}</div>
                            <div v-if="ticket.customer.phone"><span class="bold">Contacto: </span>{{ticket.customer.phone}}</div>
                            <div v-if="ticket.customer.rut"><span class="bold">RUT: </span>{{Util.Rut(ticket.customer.rut)}}</div>
                            <div class="lh1" v-if="ticket.type == 'DELIVERY' && ticket.customer.address">
                                <div><span class="bold">Dirección: </span>{{ticket.customer.address}}</div>
                                <div v-if="ticket.custom_data.maps_address" class="secondary-text">{{Util.Address(ticket.custom_data.maps_address)}}</div>
                            </div>
                        </div>
                        <i class="icon icon-dots-vertical absolute" style="right: 0px; top: 0px" v-if="ticket.customer && (ticket.customer.phone || ticket.customer.address)"></i>
                    </div>
                    <div v-else class="secondary-text">Sin datos de cliente</div>
                </div>

                <div class="relative w30 br contain" style="width: 30%;">
                    <div class="fill flex column">
                        <div :class="`pt5 pb5 order-item selectable ${order.delivered || order.type ? 'ready' : (order.prepared || order.autocomplete ? 'attention' : '')} ${order.type ? 'cost' : ''}`"
                        v-for="(order, index2) in ticket.orders"
                        :key="index2"
                        style="padding-left: 10px"
                        @click="!order.delivered && !order.type && ticket.status !== 'CANCELLED' ? OrderItemsUpdated('delivered', order, index2, ticket) : ( (order.delivered || (order.type && order.type != 'DELIVERY' && order.type != 'LEY_REDONDEO')) && !order.prepared ? OrderItemsUpdated('remove_delivered', order, index2, ticket) : null)">
                            <div v-if="!order.type">{{Util.Number(order.quantity)}}</div>
                            <div v-if="order.type == 'DELIVERY'"><i class="icon icon-delivery"></i></div>
                            <div v-if="order.type == 'LEY_REDONDEO'"><i class="icon icon-receipt"></i></div>
                            <div>{{order.title}}
                                <ul class="secondary-text">
                                    <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(order)" :key="indexMod">{{modifier.value}}
                                        <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                                    </li>
                                </ul>
                            </div>
                            <!--<div>{{Util.Price(order.total_money)}}</div>-->
                        </div>

                        <div class="secondary-text pa10" v-if="ticket.comment">{{ticket.comment}}</div>
                    </div>
                </div>
                
                <div class="pa10 w20 br">
                    <div :class="`fill flex column ${!ticket.paid_at ? 'selectable' : ''}`">
                        <div :class="`flex ${ticket.paid_at ? 'cgreen' : ''}`" @click="ticket.paid_at ? null : Confirm({
                            text: 'Pagado',
                            title: '¿Marcar pedido como pagado?',
                            class: 'selected-color',
                            function: () => {Paid(ticket)}
                        })">
                            <div class="flex-fill bold">Total <i class="icon icon-bill" v-show="ticket.paid_at"></i></div>
                            <div class="bold">{{Util.Price(ticket.total_money)}}</div>
                        </div>
                        <div class="flex selectable" @click="method.method == 'CASH'  ? null : Confirm({
                            text: 'Guardar',
                            input : 'N°',
                            input_value : method.n_transfer,
                            title: 'Ingresa n° de pago/transferencia',
                            class: 'selected-color',
                            function: (n) => {SaveNTransfer(ticket, n, indexMethod)}
                        })" v-show="!ticket.table_name"
                        v-for="(method, indexMethod) in ticket.payment"
                        :key="indexMethod" :class="{'cgreen' : method.paid_at}" :title="method.paid_at ? `Marcado como pagado ${Util.Date(method.paid_at, 'LLL')}` : ''">
                            <div class="flex-fill flex column">{{Util.PayMethod(method.method)}}
                                <div class="secondary-text" v-if="method.n_transfer">
                                    N°:
                                    <span class="">{{ method.n_transfer }}</span>
                                </div>
                            </div>
                            <div class="">{{ticket.payment.length == 1 ? '' : Util.Price(method.subtotal)}}</div>
                        
                            <!-- <div class="flex-fill">{{Util.PayMethod(method.method)}}</div>
                            <div class="">{{ticket.payment.length == 1 ? '' : Util.Price(method.subtotal)}}</div> -->
                        </div>

                    </div>
                </div>

                
                <div class="ta-center">
                    <i class="options icon-dots-vertical" @click="Options(TransactionActions(ticket))"></i>
                    <i class="options icon-external" @click="$refs.transactionDetails.Open(ticket)"></i>
                </div>
                </div>

            </div>
        </draggable>
        
    </div>

    <!-- TABLE VIEW -->
    <div class="fill flex" v-if="view == 'TABLE'">

        <div v-if="!visibleTickets.length" class="secondary-text ta-center" style="margin-top: 20px; width: 100%">No hay pedidos pendientes</div>
        <div class="flex-fill ofy" v-if="visibleTickets.length">
            <div class="flex bgbg bb cgray1" style="position: sticky; top: 0; z-index: 2">
                <div class="pa10 bold w10 br">Tipo</div>
                <div class="pa10 bold w15 br">#</div>
                <div class="pa10 bold w10 br">Ingresado</div>
                <div class="pa10 bold w30 br">Cliente</div>
                <div class="pa10 bold w15 br">Total</div>
                <div class="pa10 bold w20">Método Pago</div>
            </div>
            <draggable 
            ref="containerTickets"
            :class="`flex-fill flex column`"
            :list="visibleTickets"
            :animation="200"
            handle=".ticket-handle"
            @change="DropTicket">
                
                <div :class="`${ticket.status}-ticket ticket-table ${idSelectedTicket == ticket.id ? 'selected' : ''}`"
                v-for="(ticket, index) in visibleTickets"
                :key="index">
                    <div class="flex" @click="idSelectedTicket = ticket.id">

                        <div :style="{'background-color': OrderColor(ticket)}" :class="`pa10 cw ${sort == 'CUSTOM' ? 'ticket-handle' : ''} w10 relative`">
                            <div class="flex align-center">
                                <i v-if="ticket.status == 'CANCELLED'" class="icon icon-trash mr5 animated heartBeat infinite"></i>
                                <div v-html="TicketType(ticket)"></div>
                                <div class="pya-banner-ticket mini" v-if="ticket.custom_data && ticket.custom_data.pedidosya_courier">{{Util.StatusCodeOrderPya[ticket.custom_data.pedidosya_courier.status] ? Util.StatusCodeOrderPya[ticket.custom_data.pedidosya_courier.status].substr(0, 3) : ' '}}</div>
                                <div class="pya-banner-ticket mini" v-if="ticket.custom_data && ticket.custom_data.integration_pya">{{Util.StatusOrderIntegrationPya[ticket.custom_data.integration_pya.status]}}<!--  - {{Util.Date(ticket.custom_data.integration_pya.lastUpdated, 'HH:mm DD/MMM')}} --></div>
                            </div>
                        </div>
                        <div class="pa10 br w15 flex align-center">
                            <i :class="`icon icon-${Util.TransactionSource[ticket.source].icon} mr5`" v-if="ticket.iap && Util.TransactionSource[ticket.source]"></i>
                            <span class="flex-fill">#{{ticket.id_ticket}}</span> 
                            <i class="icon icon-invoice ml5" v-show="ticket.id_boleta || ticket.id_factura"></i>
                        </div>
                        <div class="pa10 w10 br">{{Util.Date(ticket.created_at, 'HH:mm')}}</div>
                        <div class="pa10 w30 br">
                            <div v-if="ticket.customer && ticket.customer.name">{{ticket.customer.name}}</div>
                            <div v-if="ticket.customer && ticket.customer.rut">{{Util.Rut(ticket.customer.rut)}}</div>
                        </div>
                        
                        <div :class="`pa10 bold ${ticket.paid_at ? 'cgreen' : ''} w15 br`"><i class="icon icon-bill" v-show="ticket.paid_at"></i> {{Util.Price(ticket.total_money)}}</div>


                        <div class="pa10 w20">
                            <div v-if="!ticket.table_name">{{Util.PayMethod(ticket.payment[0].method)}}</div>
                        </div>
                    </div>

                </div>
                
            </draggable>
        </div>

        <div :class="`ofy bgbg1 h100 unshrink ${dark ? '' : 'bl'}`" style="min-width: 300px; overflow-x: hidden" v-if="visibleTickets.length && desktop">
            <div v-if="!selectedTicket" class="secondary-text ta-center mt10">Seleccione una transacción</div>
            <div :class="`ALL-${desktop ? 'desktop' : 'mobile'}`" v-if="selectedTicket" style="padding: 0;  width: 300px; min-width: 300px; max-width: 300px">
                <div :class="`${selectedTicket.status}-ticket`" style="margin: 0;">
                    <div :style="{'background-color': OrderColor(selectedTicket)}" :class="`pa10 cw flex align-center ticket-header`">
                        <i v-if="selectedTicket.status == 'CANCELLED'" class="icon icon-trash mr10 animated heartBeat infinite"></i>
                        <div class="flex-fill"><i :class="`icon icon-${Util.TransactionSource[selectedTicket.source].icon} mr5 ml0`" v-if="selectedTicket.iap && Util.TransactionSource[selectedTicket.source]"></i> #{{selectedTicket.id_ticket}} <i v-show="selectedTicket.id_boleta || selectedTicket.id_factura" class="icon icon-invoice"></i></div>
                        <div v-html="TicketType(selectedTicket)"></div>
                        <i class="icon icon-external ml10 selectable" @click="$refs.transactionDetails.Open(selectedTicket)"></i>
                    </div>
                    <div class="pya-banner-ticket" v-if="selectedTicket.custom_data && selectedTicket.custom_data.pedidosya_courier">#{{selectedTicket.custom_data.pedidosya_courier.referenceId}} - {{Util.StatusOrderPya[selectedTicket.custom_data.pedidosya_courier.status]}} - {{Util.Date(selectedTicket.custom_data.pedidosya_courier.lastUpdated, 'HH:mm DD/MMM')}}</div>
                    <div class="pya-banner-ticket" v-if="selectedTicket.custom_data && selectedTicket.custom_data.integration_pya">{{Util.StatusOrderIntegrationPya[selectedTicket.custom_data.integration_pya.status]}}<!--  - {{Util.Date(ticket.custom_data.integration_pya.lastUpdated, 'HH:mm DD/MMM')}} --></div>
                    <div class="pa10 flex column c2">
                        <div class="relative">
                            <div class="flex fill column">
                                <!--
                                <div class="flex column" v-if="selectedTicket.custom_data.pedidosya_courier">
                                    <div v-if="selectedTicket.status == 'PENDING' && selectedTicket.custom_data.pedidosya_courier.pickupTime"><span class="bold"><i class="icon icon-pedidosya"></i> Recolección: </span>{{Util.Date(selectedTicket.custom_data.pedidosya_courier.pickupTime, 'DD/MM/YYYY HH:mm')}}</div>
                                    <div class="mb5" v-if="!selectedTicket.custom_data.delivered_at && selectedTicket.custom_data.pedidosya_courier.deliveryTime"><span class="bold"><i class="icon icon-pedidosya"></i> Entrega: </span>{{Util.Date(selectedTicket.custom_data.pedidosya_courier.deliveryTime, 'DD/MM/YYYY HH:mm')}}</div>
                                </div>
                                -->
                                <div><span class="bold">Ingresado: </span>{{Util.Date(selectedTicket.created_at, 'HH:mm')}} <span class="secondary-text" title="Tiempo desde que se ingresó"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - selectedTicket.created_at)}}</span></div>
                                <div v-if="showCreatedByEnabled"><span class="bold" >Ingresado por: </span>{{ selectedTicket.custom_data['created_by']}} </div>
                                <div v-if="selectedTicket.custom_data.preparation_at"><span class="bold">Preparando: </span>{{Util.Date(selectedTicket.custom_data.preparation_at, 'HH:mm')}} <span title="Tiempo desde que se empezó a preparar" v-if="selectedTicket.custom_data.preparation_at && selectedTicket.status != 'DELIVERY'" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - selectedTicket.custom_data.preparation_at)}}</span></div>
                                <div v-if="selectedTicket.delivery_at"><span class="bold">Enviado: </span>{{Util.Date(selectedTicket.delivery_at, 'HH:mm')}} <span title="Tiempo desde que se envió" v-if="selectedTicket.delivery_at" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - selectedTicket.delivery_at)}}</span></div>
                                <div v-if="selectedTicket.custom_data.delivered_at"><span class="bold">Entregado: </span>{{Util.Date(selectedTicket.custom_data.delivered_at, 'HH:mm')}} <span title="Tiempo desde que se marcó como entregado" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - selectedTicket.custom_data.delivered_at)}}</span></div>
                                <div v-if="selectedTicket.custom_data.takeaway_ready"><span class="bold">Listo: </span>{{Util.Date(selectedTicket.custom_data.takeaway_ready, 'HH:mm')}} <span title="Tiempo desde que está listo para retirar" v-if="selectedTicket.custom_data.takeaway_ready" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - selectedTicket.custom_data.takeaway_ready)}}</span></div>
                                <div v-if="selectedTicket.cancelled_at"><span class="bold">Cancelado: </span>{{Util.Date(selectedTicket.cancelled_at, 'HH:mm DD/MM/YYYY')}}</div>
                                <div class="flex column mt5" v-show="selectedTicket.status !== 'CANCELLED'">
                                    <div v-if="selectedTicket.type != 'TAKEAWAY' && selectedTicket.custom_data.shipment && selectedTicket.custom_data.shipment.shipment_end"><span class="bold">Enviar: </span>entre el {{Util.Date(selectedTicket.custom_data.shipment.shipment_start, 'D [de] MMM')}} y el {{Util.Date(selectedTicket.custom_data.shipment.shipment_end, 'D [de] MMM')}}</div>
                                    <div v-if="selectedTicket.type != 'TAKEAWAY' && selectedTicket.custom_data.shipment && !selectedTicket.custom_data.shipment.shipment_end"><span class="bold">Enviar: </span>el {{Util.Date(selectedTicket.custom_data.shipment.shipment_start, 'D [de] MMMM')}} <span v-if="selectedTicket.custom_data.shipment.time">{{selectedTicket.custom_data.shipment.time}}</span></div>
                                    <div v-if="selectedTicket.custom_data.pedidosya_courier && selectedTicket.custom_data.pedidosya_courier.pickupTime"><span class="bold">Recolección: </span>{{Util.Date(selectedTicket.custom_data.pedidosya_courier.pickupTime, 'DD/MM/YYYY HH:mm')}} <i class="icon icon-pedidosya" style="color: var(--color-pya);"></i></div>
                                    <div v-if="selectedTicket.custom_data.pedidosya_courier && selectedTicket.custom_data.pedidosya_courier.deliveryTime"><span class="bold">Entregar: </span>{{Util.Date(selectedTicket.custom_data.pedidosya_courier.deliveryTime, 'DD/MM/YYYY HH:mm')}} <i class="icon icon-pedidosya" style="color: var(--color-pya);"></i></div>
                                    <div v-else-if="selectedTicket.type == 'DELIVERY' && selectedTicket.custom_data.delivery_time"><span class="bold">Entregar: </span>{{selectedTicket.custom_data.delivery_time}}</div>
                                    <div v-if="selectedTicket.type == 'TAKEAWAY' && selectedTicket.custom_data.activation_time && !selectedTicket.custom_data.shipment"><span class="bold">Retirar: </span>{{selectedTicket.custom_data.activation_time}}</div>
                                    <div v-if="selectedTicket.type == 'TAKEAWAY' && selectedTicket.custom_data.shipment && selectedTicket.custom_data.shipment.shipment_start_takeaway && !selectedTicket.custom_data.shipment.shipment_end_takeaway"><span class="bold">Retirar: </span>{{Util.Date(selectedTicket.custom_data.shipment.shipment_start_takeaway, `DD/MM/YYYY${selectedTicket.custom_data.shipment.time? ' HH:mm' : ''}`)}}</div>
                                    <div v-if="selectedTicket.type == 'TAKEAWAY' && selectedTicket.custom_data.shipment && !selectedTicket.custom_data.shipment.time && selectedTicket.custom_data.shipment.shipment_start_takeaway && selectedTicket.custom_data.shipment.shipment_end_takeaway"><span class="bold">Retirar: </span>entre el {{Util.Date(selectedTicket.custom_data.shipment.shipment_start_takeaway, 'D [de] MMM')}} y el {{Util.Date(selectedTicket.custom_data.shipment.shipment_end_takeaway, 'D [de] MMM')}}</div>

                                    <div v-if="selectedTicket.custom_data.scheduled_at && !selectedTicket.custom_data.shipment"><span class="bold">Programado: </span>{{Util.Date(selectedTicket.custom_data.scheduled_at, 'DD/MM/YYYY HH:mm')}}</div>
                                    <!--<div v-if="selectedTicket.custom_data.influye_code"><span class="bold">Código Influye: </span>{{selectedTicket.custom_data.influye_code}}</div>-->
                                    <div v-if="selectedTicket.custom_data.delivery_by"><span class="bold">Repartidor: </span>{{selectedTicket.custom_data.delivery_by}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="separator"></div>

                        <div class="selectable relative" v-if="selectedTicket.customer && (selectedTicket.customer.name || selectedTicket.customer.phone || selectedTicket.customer.address)" @click="$refs.transactionDetails.CustomerOptions(selectedTicket)">
                            <div class="fill flex column">
                                <div class="flex column">
                                    <div v-if="selectedTicket.customer.name"><span class="bold">Nombre: </span>{{selectedTicket.customer.name}}</div>
                                    <div v-if="selectedTicket.customer.phone"><span class="bold">Contacto: </span>{{selectedTicket.customer.phone}}</div>
                                    <div v-if="selectedTicket.customer.rut"><span class="bold">RUT: </span>{{Util.Rut(selectedTicket.customer.rut)}}</div>
                                    <div v-if="selectedTicket.type == 'DELIVERY' && selectedTicket.customer.address" class="lh1">
                                        <div><span class="bold">Dirección: </span>{{selectedTicket.customer.address}}</div>
                                        <div v-if="selectedTicket.custom_data.maps_address" class="secondary-text">{{Util.Address(selectedTicket.custom_data.maps_address)}}</div>
                                    </div>
                                </div>
                            </div>
                            <i class="icon icon-dots-vertical absolute" v-if="selectedTicket.customer && (selectedTicket.customer.phone || selectedTicket.customer.address)" style="right: 0; top: 0"></i>
                            <div class="separator"></div>
                        </div>

                        <div class="relative">
                            <div class="fill flex column">
                                <div :class="`order-item selectable well mb5 ${order.delivered || order.type ? 'ready' : (order.prepared || order.autocomplete ? 'attention' : '')} ${order.type ? 'cost' : ''}`"
                                v-for="(order, index2) in selectedTicket.orders"
                                :key="index2"
                                @click="!order.delivered && !order.type && selectedTicket.status !== 'CANCELLED' ? OrderItemsUpdated('delivered', order, index2, selectedTicket) : ( (order.delivered || (order.type && order.type != 'DELIVERY' && order.type != 'LEY_REDONDEO')) && !order.prepared ? OrderItemsUpdated('remove_delivered', order, index2, selectedTicket) : null)">
                                    <div v-if="!order.type">{{Util.Number(order.quantity)}}</div>
                                    <div v-if="order.type == 'DELIVERY'"><i class="icon icon-delivery"></i></div>
                                    <div v-if="order.type == 'LEY_REDONDEO'"><i class="icon icon-receipt"></i></div>
                                    <div>{{order.title}}
                                        <ul class="secondary-text">
                                            <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(order)" :key="indexMod">{{modifier.value}}
                                                <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <!--<div>{{Util.Price(order.total_money)}}</div>-->
                                </div>
                            </div>
                        </div>

                        <div class="well" v-if="selectedTicket.comment">
                            <div class="bold">Comentario</div>
                            <div>{{selectedTicket.comment}}</div>
                        </div>

                        <!-- <div :class="`input-style ${canEdit && !ticket.table_name ? 'selectable' : ''}`" @click="canEdit && !ticket.table_name && $refs.transactionDetails.OpenPayment(ticket)"> -->
                        <div class="separator"></div>
                        <div :class="` ${!selectedTicket.paid_at ? 'selectable' : ''}`" >
                            <div class="fill flex column" >
                                <div @click="selectedTicket.paid_at ? null : Confirm({
                            text: 'Pagado',
                            title: '¿Marcar pedido como pagado?',
                            class: 'selected-color',
                            function: () => {Paid(selectedTicket)}
                        })" :class="`f18 flex ${selectedTicket.paid_at ? 'cgreen' : ''}`">
                                    <div class="flex-fill bold">Total <i class="icon icon-bill" v-show="selectedTicket.paid_at"></i></div>
                                    <div class="bold">{{Util.Price(selectedTicket.total_money)}}</div>
                                </div>
                                <div class="flex mt5 selectable" v-show="!selectedTicket.table_name"
                                v-for="(method, indexMethod) in selectedTicket.payment"
                                :key="indexMethod" :class="{'cgreen' : method.paid_at}" :title="method.paid_at ? `Marcado como pagado ${Util.Date(method.paid_at, 'LLL')}` : ''" @click="method.method == 'CASH'  ? null : Confirm({
                                        text: 'Guardar',
                                        input : 'N°',
                                        input_value : method.n_transfer,
                                        title: 'Ingresa n° de pago/transferencia',
                                        class: 'selected-color',
                                        function: (n) => {SaveNTransfer(selectedTicket, n, indexMethod)}
                                    })">
                                <div class="flex-fill flex column">{{Util.PayMethod(method.method)}}
                                        <div class="secondary-text" v-if="method.n_transfer">
                                            N°:
                                            <span>{{ method.n_transfer }}</span>
                                        </div>
                                    </div>
                                
                                    <!-- <div class="flex-fill">{{Util.PayMethod(method.method)}}</div> -->
                                    <div class="">{{selectedTicket.payment.length == 1 ? '' : Util.Price(method.subtotal)}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="flex column pa10 reverse" style="padding-top: 0">
                        <button v-for="(action, index) in TransactionActions(selectedTicket)" :key="index"
                        :class="`secondary mb5 ${action.class}`"
                        @click="action.confirm ? Confirm({title: action.confirm, text: action.text, function: action.function, class: action.class}) : action.function()">{{action.text}}</button>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    <!-- TICKETS VIEW -->
    <div class="fill flex column" v-if="view == 'TICKETS'">
        <div v-if="!visibleTickets.length" class="secondary-text ta-center" style="margin-top: 20px;">No hay pedidos pendientes</div>
        <draggable 
        ref="containerTickets"
        :class="`flex-fill flex ${mobile ? 'column' : 'pa10'}`"
        :list="visibleTickets"
        :animation="200"
        handle=".ticket-handle"
        @change="DropTicket">
            <div :class="`ALL-${desktop ? 'desktop' : 'mobile'}`"
            v-for="(ticket, index) in visibleTickets"
            :key="index">
                <div :class="`transaction-ticket ${ticket.status}-ticket`">
                    <div :style="{'background-color': OrderColor(ticket)}" :class="`pa10 cw flex ticket-header align-center ${sort == 'CUSTOM' ? 'ticket-handle' : ''}`">
                        <i v-if="ticket.status == 'CANCELLED'" class="icon icon-trash mr10 animated heartBeat infinite"></i>
                        <div class="flex-fill"><i :class="`icon icon-${Util.TransactionSource[ticket.source].icon} mr5 ml0`" v-if="ticket.iap && Util.TransactionSource[ticket.source].icon"></i> #{{ticket.id_ticket}} <i v-show="ticket.id_boleta || ticket.id_factura" class="icon icon-invoice"></i></div>
                        <div v-html="TicketType(ticket)"></div>
                        <i class="icon icon-external ml10 selectable" @click="$refs.transactionDetails.Open(ticket)"></i>
                    </div>
                    <div class="pya-banner-ticket" v-if="ticket.custom_data && ticket.custom_data.pedidosya_courier">#{{ticket.custom_data.pedidosya_courier.referenceId}} - {{Util.StatusOrderPya[ticket.custom_data.pedidosya_courier.status]}} - {{Util.Date(ticket.custom_data.pedidosya_courier.lastUpdated, 'HH:mm DD/MMM')}}</div>
                    <div class="pya-banner-ticket" v-if="ticket.custom_data && ticket.custom_data.integration_pya">{{Util.StatusOrderIntegrationPya[ticket.custom_data.integration_pya.status]}}<!--  - {{Util.Date(ticket.custom_data.integration_pya.lastUpdated, 'HH:mm DD/MMM')}} --></div>
                    <div class="pa10 flex column">
                        <div class="relative">
                            <div class="flex fill column relative selectable" @click="Options(TransactionActions(ticket, 'secondary'))">
                                <div><span class="bold">Ingresado: </span>{{Util.Date(ticket.created_at, 'HH:mm')}} <span class="secondary-text" title="Tiempo desde que se ingresó"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.created_at)}}</span></div>
                                <div v-if="showCreatedByEnabled"><span class="bold" >Ingresado por: </span>{{ ticket.custom_data['created_by']}} </div>
                                <div v-if="ticket.custom_data.preparation_at"><span class="bold">Preparando: </span>{{Util.Date(ticket.custom_data.preparation_at, 'HH:mm')}} <span title="Tiempo desde que se empezó a preparar" v-if="ticket.custom_data.preparation_at && ticket.status != 'DELIVERY'" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.custom_data.preparation_at)}}</span></div>
                                <div v-if="ticket.delivery_at"><span class="bold">Enviado: </span>{{Util.Date(ticket.delivery_at, 'HH:mm')}} <span title="Tiempo desde que se envió" v-if="ticket.delivery_at" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.delivery_at)}}</span></div>
                                <div v-if="ticket.custom_data.delivered_at"><span class="bold">Entregado: </span>{{Util.Date(ticket.custom_data.delivered_at, 'HH:mm')}} <span title="Tiempo desde que se marcó como entregado" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.custom_data.delivered_at)}}</span></div>
                                <div v-if="ticket.custom_data.takeaway_ready"><span class="bold">Listo: </span>{{Util.Date(ticket.custom_data.takeaway_ready, 'HH:mm')}} <span title="Tiempo desde que está listo para retirar" v-if="ticket.custom_data.takeaway_ready" class="secondary-text"><i class="icon icon-chronometer"></i> {{ElapsedTime(timer - ticket.custom_data.takeaway_ready)}}</span></div>
                                <div v-if="ticket.cancelled_at"><span class="bold">Cancelado: </span>{{Util.Date(ticket.cancelled_at, 'HH:mm DD/MM/YYYY')}}</div>
                                <div class="flex column mt5" v-show="ticket.status !== 'CANCELLED'">
                                    <div v-if="ticket.type != 'TAKEAWAY' && ticket.custom_data.shipment && ticket.custom_data.shipment.shipment_end"><span class="bold">Enviar: </span>entre el {{Util.Date(ticket.custom_data.shipment.shipment_start, 'D [de] MMM')}} y el {{Util.Date(ticket.custom_data.shipment.shipment_end, 'D [de] MMM')}}</div>
                                    <div v-if="ticket.type != 'TAKEAWAY' && ticket.custom_data.shipment && !ticket.custom_data.shipment.shipment_end"><span class="bold">Enviar: </span>el {{Util.Date(ticket.custom_data.shipment.shipment_start, 'D [de] MMMM')}} <span v-if="ticket.custom_data.shipment.time">{{ticket.custom_data.shipment.time}}</span></div>
                                    <div v-if="ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.pickupTime"><span class="bold">Recolección: </span>{{Util.Date(ticket.custom_data.pedidosya_courier.pickupTime, 'DD/MM/YYYY HH:mm')}} <i class="icon icon-pedidosya" style="color: var(--color-pya);"></i></div>
                                    <div v-if="ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.deliveryTime"><span class="bold">Entregar: </span>{{Util.Date(ticket.custom_data.pedidosya_courier.deliveryTime, 'DD/MM/YYYY HH:mm')}} <i class="icon icon-pedidosya" style="color: var(--color-pya);"></i></div>
                                    <div v-else-if="ticket.type == 'DELIVERY' && ticket.custom_data.delivery_time"><span class="bold">Entregar: </span>{{ticket.custom_data.delivery_time}}</div>
                                    <div v-if="ticket.type == 'TAKEAWAY' && ticket.custom_data.activation_time && !ticket.custom_data.shipment"><span class="bold">Retirar: </span>{{ticket.custom_data.activation_time}}</div>
                                    <div v-if="ticket.type == 'TAKEAWAY' && ticket.custom_data.shipment && ticket.custom_data.shipment.shipment_start_takeaway && !ticket.custom_data.shipment.shipment_end_takeaway"><span class="bold">Retirar: </span>{{Util.Date(ticket.custom_data.shipment.shipment_start_takeaway, `DD/MM/YYYY${ticket.custom_data.shipment.time? ' HH:mm' : ''}`)}}</div>
                                    <div v-if="ticket.type == 'TAKEAWAY' && ticket.custom_data.shipment && !ticket.custom_data.shipment.time && ticket.custom_data.shipment.shipment_start_takeaway && ticket.custom_data.shipment.shipment_end_takeaway"><span class="bold">Retirar: </span>entre el {{Util.Date(ticket.custom_data.shipment.shipment_start_takeaway, 'D [de] MMM')}} y el {{Util.Date(ticket.custom_data.shipment.shipment_end_takeaway, 'D [de] MMM')}}</div>
                                    <div v-if="ticket.custom_data.scheduled_at"><span class="bold">Programado: </span>{{Util.Date(ticket.custom_data.scheduled_at, 'DD/MM/YYYY HH:mm')}}</div>
                                    <!--<div v-if="ticket.custom_data.influye_code"><span class="bold">Código Influye: </span>{{ticket.custom_data.influye_code}}</div>-->
                                    <div v-if="ticket.custom_data.delivery_by"><span class="bold">Repartidor: </span>{{ticket.custom_data.delivery_by}}</div>
                                </div>
                                <i class="icon icon-dots-vertical absolute" style="right: 0; top: 3px"></i>
                            </div>
                            
                            <div class="separator mt10 mb10"></div>
                        </div>

                        <div class="selectable relative" v-if="ticket.customer && (ticket.customer.name || ticket.customer.phone || ticket.customer.address)" @click="$refs.transactionDetails.CustomerOptions(ticket)">
                            
                            <div v-if="ticket.customer.name"><span class="bold">Nombre: </span>{{ticket.customer.name}}</div>
                            <div v-if="ticket.customer.phone"><span class="bold">Contacto: </span>{{ticket.customer.phone}}</div>
                            <div v-if="ticket.customer.rut"><span class="bold">RUT: </span>{{Util.Rut(ticket.customer.rut)}}</div>
                            <div v-if="ticket.type == 'DELIVERY' && ticket.customer.address" class="lh1">
                                <div><span class="bold">Dirección: </span>{{ticket.customer.address}}</div>
                                <div v-if="ticket.custom_data.maps_address" class="secondary-text">{{Util.Address(ticket.custom_data.maps_address)}}</div>
                            </div>
                            <i v-if="ticket.customer && (ticket.customer.phone || ticket.customer.address)" class="icon icon-dots-vertical absolute" style="right: 0; top: 3px"></i>
                            <div class="separator mt10 mb10"></div>
                        </div>

                        <div class="relative">
                            <div class="fill flex column">
                                <div :class="`pa5 order-item selectable ${order.delivered || order.type ? 'ready' : (order.prepared || order.autocomplete ? 'attention' : '')} ${order.type ? 'cost' : ''}`"
                                v-for="(order, index2) in ticket.orders"
                                :key="index2"
                                @click="!order.delivered && !order.type && ticket.status !== 'CANCELLED' ? OrderItemsUpdated('delivered', order, index2, ticket) : ( (order.delivered || (order.type && order.type != 'DELIVERY' && order.type != 'LEY_REDONDEO')) && !order.prepared ? OrderItemsUpdated('remove_delivered', order, index2, ticket) : null)">
                                    <div v-if="!order.type">{{Util.Number(order.quantity)}}</div>
                                    <div v-if="order.type == 'DELIVERY'"><i class="icon icon-delivery"></i></div>
                                    <div v-if="order.type == 'LEY_REDONDEO'"><i class="icon icon-receipt"></i></div>
                                    <div>{{order.title}}
                                    <span class="secondary-text">{{order.added_by}}</span>
                                        <ul class="secondary-text">
                                            <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(order)" :key="indexMod">{{modifier.value}}
                                                <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <!--<div>{{Util.Price(order.total_money)}}</div>-->
                                </div>
                            </div>

                            <div class="well secondary-text" v-if="ticket.comment">
                                <i class="icon icon-comment"></i> {{ticket.comment}}
                            </div>

                            <div class="separator mt10 mb10"></div>
                        </div>



                        <!-- <div :class="`input-style ${canEdit && !ticket.table_name ? 'selectable' : ''}`" @click="canEdit && !ticket.table_name && $refs.transactionDetails.OpenPayment(ticket)"> -->
                        <div :class="`${!ticket.paid_at ? 'selectable' : ''}`" >
                            <div class="fill flex column" >
                                <div :class="`f18 flex ${ticket.paid_at ? 'cgreen' : ''}`" @click="ticket.paid_at ? null : Confirm({
                            text: 'Pagado',
                            title: '¿Marcar pedido como pagado?',
                            class: 'selected-color',
                            function: () => {Paid(ticket)}
                        })">
                                    <div class="flex-fill bold">Total <i class="icon icon-bill" v-show="ticket.paid_at"></i></div>
                                    <div class="bold">{{Util.Price(ticket.total_money)}}</div>
                                </div>

                                <div class="flex selectable" v-show="!ticket.table_name"
                                    v-for="(method, indexMethod) in ticket.payment"
                                    :key="indexMethod" :class="{'cgreen' : method.paid_at}" :title="method.paid_at ? `Marcado como pagado ${Util.Date(method.paid_at, 'LLL')}` : ''" 
                                    @click="method.method == 'CASH'  ? null : Confirm({
                                        text: 'Guardar',
                                        input : 'N°',
                                        input_value : method.n_transfer,
                                        title: 'Ingresa n° de pago/transferencia',
                                        class: 'selected-color',
                                        function: (n) => {SaveNTransfer(ticket, n, indexMethod)}
                                    })">
                                    <div class="flex-fill flex column">{{Util.PayMethod(method.method)}}
                                        <div class="secondary-text" v-if="method.n_transfer">
                                            N°:
                                            <span>{{ method.n_transfer }}</span>
                                        </div>
                                    </div>
                                    <div class="">  {{ticket.payment.length == 1 ? '' : Util.Price(method.subtotal)}} </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="flex column pa10" style="padding-top: 0" v-if="ticket.status !== 'CANCELLED'">
                        <button v-for="(action, index) in TransactionActions(ticket, 'primary')" :key="index"
                        :class="`secondary mb5 ${action.class}`"
                        @click="action.confirm ? Confirm({title: action.confirm, text: action.text, function: action.function, class: action.class}) : action.function()">{{action.text}}</button>
                    </div>
                </div>
            </div>
            
        </draggable>
    </div>

     <!-- TABLES -->
    <div class="pa15" v-if="view == 'TABLES'">

        <div class="flex column flex-fill">
            <div class="flex column unshrink mb20"
            v-for="(section, index) in availableTables" :key="index">
                <div class="f18 mb15">{{section.name}}</div>
                <div class="secondary-text" v-if="!section.tables.length">No hay mesas</div>
                <div class="flex" style="flex-wrap: wrap">
                    <div :class="`table ${table.status} ${table.children && table.children.length ? 'PARENT' : ''}`"
                    v-for="table in section.tables" :key="table.name"
                    @click="ClickOnTable(table)">
                        <div class="mb5 table-name">{{table.name}}</div>
                        <div v-if="table.waiter && (Auth.role !== 'waiter' || table.waiter.id !== Auth.id)" class="label mb10 lh1" style="font-size: 12px">{{table.waiter.name}}</div>
                        <!-- <div v-if="table.items.ready || table.items.pending" class="label bold" style="color: #ec5555">{{table.items.ready ? `${table.items.ready} / ` : ''}}{{table.items.pending + table.items.ready}}</div> -->
                        <div class="flex justify-center">
                            <div v-if="table.items.ready" class="label mr5"><i class="icon icon-check"></i> {{table.items.ready}}</div>
                            <div v-if="table.items.pending" class="label ml5"><i class="icon icon-spatula"></i> {{table.items.pending}}</div>
                        </div>
                        <div v-if="table.reservation" class="label"><i class="icon icon-datetime"></i> {{table.reservation}}</div>
                        <div v-if="table.parent_table" class="label" style="font-size: 12px"><i class="icon icon-table"></i> Unida a {{table.parent_table}}</div>
                        <div v-if="!table.available" class="label" style="font-size: 12px">No disponible</div>
                        <div v-if="table.children" class="child-tables">
                            <div v-if="table.children.length == 1"><i class="icon icon-table"></i> {{table.children[0].name}}</div>
                            <div v-else><i class="icon icon-table"></i> {{table.children.length}} mesas unidas</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    <modal v-model="modalTable" :title="modalTable ? `${modalTable.table_name.length < 4 ? `Mesa ` : ''}${modalTable.table_name}` : ''" dir="right" :modalClass="['fill', 'md right h100']">

        <i v-if="isAdmin || (Auth.restrictions && Auth.restrictions.can_reprint)" slot="options" class="options icon-printing" @click="Options([
            {text: 'Voucher Cliente', function: () => {Util.PrintTransaction(modalTable, 'DEFAULT')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || Auth.restrictions.reprint_options.includes('voucher'))},
            {text: 'Voucher Cocina Pedidos Pendientes', function: () => {Util.PrintTransaction(modalTable, 'PENDING')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || Auth.restrictions.reprint_options.includes('kitchen'))},
            {text: 'Voucher Cocina Todos los Pedidos', function: () => {Util.PrintTransaction(modalTable, 'PENDING-ALL')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || Auth.restrictions.reprint_options.includes('kitchen'))},
            {text: 'Voucher Cocina Últimos Pedidos', function: () => {Util.PrintTransaction(modalTable, 'PENDING-LAST')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || Auth.restrictions.reprint_options.includes('kitchen'))},
            {text: 'Pre Cuenta Total', function: () => {PrintTableSummary(modalTable)}, if: Auth.role.indexOf('admin') > -1 || Auth.restrictions.reprint_options.includes('precuenta')},
            {text: 'Pre Cuenta Pendientes', function: () => {PrintTableSummary(modalTable, true)}, if: Auth.role.indexOf('admin') > -1 || Auth.restrictions.reprint_options.includes('precuenta')}
        ])"></i>

        <i class="options icon-dots-vertical ml5" slot="options" @click="Options([
            {text: 'Cancelar Pedido', class: 'error-color', function: () => {$refs.transactionDetails.OpenCancel(modalTable)}, if: canCancel},
            {text: 'Ver Detalles', function: () => {$refs.transactionDetails.Open(modalTable)}},
            {text: 'Unir a otra Mesa', function: () => {modalJoinTable={source: modalTable.table_name, target: null}}},
            {text: 'Cambiar Número de Comensales', function: () => OpenModalChangeDiner(modalTable)},
            {text: 'Cambiar Tipo de Pedido', function: () => {
                Options([
                    {text: 'Cambiar para Servir', function: () => {ChangeType(modalTable, 'SERVE')}},
                    {text: 'Cambiar para Llevar', function: () => {ChangeType(modalTable, 'TAKEAWAY')}},
                    {text: 'Cambiar para Delivery', function: () => {ChangeType(modalTable, 'DELIVERY')}},
                ]);
            }, if: Auth.role !== 'waiter' && (Auth.role.indexOf('admin') > -1 || Auth.restrictions.edit_transactions)},
            {text: 'Cambiar Mesero', function: OpenChangeWaiter, if: Auth.role.indexOf('admin') > -1 || !!Auth.restrictions.change_waiter},
            {text: 'Cambiar Mesa', function: () => {modalChangeTable={selected: modalTable.table_name, previous: modalTable.table_name}}, if: canTransferTables},
            {text: 'Pago Parcial', function: () => {OpenPartialPayment()}}
        ])"></i>

        <div class="pa20" v-if="modalTable">

            <div class="flex">

                <div class="mr20">
                    <div class="bold"><i class="icon icon-table"></i> {{modalTable.table_name}}</div>
                    <div>{{modalTable.custom_data.waiter ? modalTable.custom_data.waiter.name : modalTable.custom_data.created_by}}</div>
                </div>

                <div class="ml10" v-if="modalTable.custom_data && modalTable.custom_data.customers_number">
                    <div class="bold" v-if="!mobile">N° Comensales </div>
                    <div v-else><i class="icon icon-user"></i></div>
                    <div>{{modalTable.custom_data.customers_number}}</div>
                </div>
                
                <div class="ml20">
                    <div class="bold">Abierta </div>
                    <div class="flex align-center">
                        <div>{{Util.Date(modalTable.created_at, 'HH:mm')}}</div>
                    </div>
                </div>

                <div class="ml10">
                    <div class="bold">Editada </div>
                
                    <div>{{Util.Date(modalTable.edit_at, 'HH:mm')}}</div>
                    
                </div>
                

            </div>

            <div v-if="childrenTables.length">

                <div class="separator"></div>

                <div class="f18 mb10">Mesas Unidas</div>

                <div class="flex">
                    <div class="well selectable mr5 mt5" v-for="child in childrenTables" :key="child.name" 
                    @click="modalDisjoinTable = {child, parentDiners: modalTable.custom_data.customers_number? modalTable.custom_data.customers_number : 1}">
                        <div class="bold" >{{child.name}}</div>
                    </div>
                </div>
            </div>

            <div v-if="modalTable">

                <div class="separator mt20 mb10"></div>

                <div class="flex align-center sticky bgbg1 pb10 pt10 mb10">
                    <div class="flex align-center flex-fill">
                        <div v-if="tableItemsSelection" class="flex align-center">
                            <i @click="tableItemsSelection=null" class="options icon-cross mr5"></i>
                            <div><span>({{Util.Arr(tableItemsSelection).length}})</span> Seleccionados</div>
                        </div>
                        <div v-else class="flex column">
                            <div class="flex align-center flex-fill" v-if="modalTable.custom_data['diners_names'] && dictDiners">
                                <input-select class="mr10 input-shorter"
                                v-model="modalTable.value_diner"
                                @OnValueChanged="DictTagDinerName"
                                :items="dinersList"
                                ></input-select>
                            </div>
                            <div v-else class="f18">Pedido</div>
                        </div>
                    </div>
                    <div class="secondary-text mr10" v-show="desktop || !tableItemsSelection" v-if="canEditTables">Orden {{ modalTable.custom_data.type_order? typeOrder[modalTable.custom_data.type_order] : 'automático' }}</div>
                    <i v-if="canEditTables" class="options icon-sort" @click="Options([
                        {text: 'Ordenar manualmente', function: () => { OrderByItem('custom') } },
                        {text: 'Ordenar por secciones', function: () => { OrderByItem('section') } },
                        {text: 'Ordenar automáticamente', function: () => { OrderByItem('automatically') } },
                    ])"></i>
                    <i class="options icon-dots-vertical" @click="Options([
                        //{text: 'Cancelar Selección', class: 'error-color', function: () => {tableItemsSelection=null}},
                        {text: 'Seleccionar Todos', function: () => {SelectTableItems('ALL')}},
                        {text: 'Seleccionar Pendientes de Entrega', function: () => {SelectTableItems('UNDELIVERED')}},
                        {text: 'Seleccionar Entregados', function: () => {SelectTableItems('DELIVERED')}},
                        {text: 'Seleccionar Pendientes de Pago', function: () => {SelectTableItems('UNPAID')}},
                        {text: 'Seleccionar Pagados', function: () => {SelectTableItems('PAID')}},
                        {text: 'Seleccionar Últimos Ingresados', function: () => {SelectTableItems('LAST')}}
                    ])"></i>
                    <button v-if="tableItemsSelection && Util.Arr(tableItemsSelection).length" class="primary purple icon ml5" @click="Options([
                        {text: 'Quitar pedidos', color: 'error-color', function: () => {OrderItemsUpdated('remove', 'SELECTED', null, modalTable)}, if: !!(canEditTables && canEditOld)},
                        {text: 'Marcar como pagado', function: () => {OpenPartialPayment()}, if: !!canChangeOrderStatus},
                        {text: 'Marcar como entregado', function: () => {OrderItemsUpdated('delivered', 'SELECTED', null, modalTable)}, if: !!canChangeOrderStatus},
                        {text: 'Desmarcar como entregado', function: () => {OrderItemsUpdated('remove_delivered', 'SELECTED', null, modalTable)}, if: !!canChangeOrderStatus},
                        {text: 'Transferir a otra mesa', function: () => {OrderItemsUpdated('transfer', 'SELECTED', null, modalTable)}, if: !!canTransferTables},
                        {text: 'Transferir a otro comensal', function: () => {TransferItemsSelection()}, if: dinersList.length > 1},
                    ])"><i class="icon icon-arrow-right"></i></button>

                </div>

                <div class="secondary-text ta-center" v-if="!modalTable.orders.length">No hay items</div>
                <draggable
                :list="orderedItems"
                handle=".handle"
                :animation="200"
                group="sections"
                @change="OnTableItemsOrderChanged($event, modalTable)">
                    <div class="well mb10 selectable" v-for="(order, index2) in orderedItems" :key="index2" @click="tableItemsSelection ? (tableItemsSelection[order.index]?$delete(tableItemsSelection, order.index):$set(tableItemsSelection, order.index, order.id)) : Options([
                        {text: 'Quitar del pedido', function: () => {OrderItemsUpdated('remove', order, order.index, modalTable)}, class: 'error-color', if: !!(canEditTables && canEditOld)},
                        {text: 'Separar Items', function: () => {OrderItemsUpdated('split', order, order.index, modalTable)}, if: !!(canEditTables && !order.autocomplete && order.quantity > 1)},
                        {text: 'Seleccionar', function: () => {tableItemsSelection={}; $set(tableItemsSelection, order.index, order.id)}},
                        {text: 'Transferir a otra mesa', function: () => {OrderItemsUpdated('transfer', order, order.index, modalTable)}, if: canTransferTables},
                        {text: 'Transferir a otro comensal', function: () => {modalChangeDinerFromItem = order}, if: dinersList.length > 1},
                        {text: 'Marcar como Pagado', function: () => {tableItemsSelection={}; $set(tableItemsSelection, order.index, order.id); OpenPartialPayment()}, class: 'selected-color', if: canChangeOrderStatus && !order.paid},
                        {text: 'Marcar como Entregado', function: () => {OrderItemsUpdated('delivered', order, order.index, modalTable)}, class: 'selected-color', if: canChangeOrderStatus && !order.delivered && !order.paid},
                        {text: 'Desmarcar como Entregado', function: () => {OrderItemsUpdated('remove_delivered', order, order.index, modalTable)}, class: 'selected-color', if: canChangeOrderStatus && !!order.delivered && !order.paid},
                    ])">
                        <div class="flex fill">
                            <i v-if="sortByItem == 'custom'" class="icon icon-drag-handle handle"></i>
                            <div>{{Util.Number(order.quantity)}}</div>
                            <div class="flex-fill flex column ml20">
                                <div :class="`order-item ${ItemTag(order)}`">
                                    <div class="flex-fill">{{order.title}}
                                        <ul class="secondary-text">
                                            <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(order, DictTagDinerName(order.diner,true))" :key="indexMod">{{modifier.value}}
                                                <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div v-if="tableItemsSelection" :class="`selectable ml10 check ${tableItemsSelection[order.index] ? 'selected' : ''}`"></div>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>

        </div>

        <!--<button slot="actions" @click="OpenPartialPayment()">Pago Parcial</button>-->
        <button slot="actions" v-if="modalTable && canComplete && modalTable.orders.length && (!modalTable.selected_diner || modalTable.selected_diner.value == 'ALL')" class="secondary" @click="OpenCloseTable(modalTable)">Cerrar Mesa {{Util.Price(modalTable.total_money)}}</button>
        <button slot="actions" v-if="modalTable && canComplete && modalTable.selected_diner && modalTable.selected_diner.value != 'ALL' && TotalDiner(orderedItems) > 0" class="secondary" @click="OpenCloseTable(modalTable)">Cuenta {{modalTable.selected_diner.text}} {{Util.Price(TotalDiner(orderedItems))}}</button>
        
        <button slot="actions" v-if="modalTable && canEditTables" class="primary purple" @click="AddItems()">Agregar Items</button>
        
        <!--
        <div v-if="modalTable && tableItemsSelection" class="modal-actions">
            <button @click="tableItemsSelection=null" class="error-color">Cancelar Selección</button>
            <button v-if="canEditTables" @click="OrderItemsUpdated('remove', 'SELECTED', null, modalTable)">Quitar</button>
            <button v-if="canChangeOrderStatus" @click="OpenPartialPayment()">Pagado</button>
            <button v-if="canChangeOrderStatus" @click="OrderItemsUpdated('delivered', 'SELECTED', null, modalTable)">Entregado</button>
            <button v-if="canTransferTables" @click="OrderItemsUpdated('transfer', 'SELECTED', null, modalTable)">Transferir</button>
        </div>
        -->
    </modal>  
    
    <modal v-model="modalMobileTables" dir="right" maxSize="80vw">
        <div class="pa10 flex column ofy" style="width: 80vw" ref="mobileTables">

        </div>
    </modal>

    <modal v-model="modalDisjoinTable" title="Separar mesas" dir="center" modalClass="sm">
        <div v-if="modalDisjoinTable" class="pa20">
            
            <div class="ta-center">¿Separar {{modalDisjoinTable.child.name}} de {{modalDisjoinTable.child.parent_table}}?</div>
            
            <input-field class="flex-fill mr10 mt10"
            v-model="modalDisjoinTable.parentDiners"
            type="int+"
            :label="`Comensales en ${modalDisjoinTable.child.parent_table}`"
            info="La cantidad de comensales debe ser mayor a 0">
            </input-field>
        </div>
        
        <button slot="actions" v-if="modalDisjoinTable" class="secondary" @click="modalDisjoinTable=null">Cancelar</button>
        <button slot="actions" 
            v-if="modalDisjoinTable" 
            :disabled="!modalDisjoinTable.parentDiners" 
            class="primary error-color" 
            @click="DisjoinTable(modalDisjoinTable)">
            Separar
        </button>
    </modal>

    <modal v-model="modalChangeDiners" title="Cambiar número de comensales" dir="center" modalClass="sm">
        <div v-if="modalChangeDiners" class="pa20">
            <input-field class="flex-fill mr10 mt10"
            v-model="modalChangeDiners.customers_number"
            type="int+"
            label="Número de Comensales"
            info="La cantidad de comensales debe ser mayor a 0"
            >
            </input-field>
        </div>

        <div class="flex-fill pr20 pl20 pb20" v-if="modalChangeDiners">
            <div v-for="(diner, index) in modalChangeDiners.add_diners_names" :key="index"> 
                <input-field
                    v-if="modalChangeDiners.add_diners_names[index] != null"
                    class="flex-fill mr10 mt10"
                    :value="diner"
                    v-model="modalChangeDiners.add_diners_names[index]"
                    :label="`Comensal ${index+1}`"
                    placeholder="(Opcional)"
                >
                <button slot="after" v-tooltip="'Eliminar comensal'" class="secondary border bl0" @click.stop="DeleteDiner(index, diner)"><i class="icon icon-trash"></i></button>
                </input-field>
            </div>

        </div>
        
        <button slot="actions" v-if="modalChangeDiners" class="secondary" @click="modalChangeDiners=null">Cancelar</button>
        <button slot="actions" 
            v-if="modalChangeDiners" 
            class="primary purple" 
            @click="ChangeCustomersNumber(modalChangeDiners)">
            Cambiar
        </button>
    </modal>

    <modal v-model="modalMobileTableOptions" dir="right" maxSize="80vw">
        <div class="pa10 flex column ofy fill" style="width: 80vw" ref="mobileTableOptions">

        </div>
    </modal>

    <!-- IAP -->
    <div class="pa10" v-if="(view == 'IAP' || view == 'SCHEDULED') && iap" style="overflow-x: auto;" @mousemove="view == 'IAP' ? $refs.iapController.Checked() : null">
        
        <div v-if="!iapViewTickets.length" class="secondary-text ta-center mt10">{{view == 'IAP' ? 'No hay pedidos online pendientes' : 'No hay pedidos agendados'}}</div>

        <div :class="`${ticket.status}-ticket ticket-row mb10`"
        v-for="(ticket, index) in iapViewTickets"
        :key="index">
            <div class="flex column-mobile">
                
            <div :style="{'background-color': OrderColor(ticket), 'border-radius': desktop ? '10px 0px 0px 10px' : '10px 10px 0px 0px'}" :class="`pa10 cw flex align-center ${desktop ? 'w10 column' : 'w100 flex reverse'}`">

                <div class="ticket-label mb5 mb0-mobile" v-if="ticket.status == 'WAITING'">Confirmación</div>
                <div class="ticket-label mb5 mb0-mobile" v-if="ticket.status == 'WAITING_PAYMENT'">Pago online</div>
                <div class="ticket-label mb5 mb0-mobile" v-if="ticket.status == 'WAITING_BANK_DEPOSIT' && ticket.payment_method == 'BANK_DEPOSIT' ">Transferencia <i class="icon icon-check" v-if="ticket.custom_data.bank_deposit_at"></i></div>
                <div class="ticket-label mb5 mb0-mobile" v-else-if="ticket.status == 'WAITING_BANK_DEPOSIT'">Pago {{ticket.payment_method}} <i class="icon icon-check" v-if="ticket.custom_data.bank_deposit_at"></i></div>
                <div class="ticket-label mb5 mb0-mobile" v-show="view == 'IAP'" v-if="ticket.status == 'SCHEDULED'">Agendado</div>
                <div class="ticket-label reverse mb5 mb0-mobile" v-if="ticket.status == 'ACCEPTED'">Ingresado</div>
                <div class="ticket-label mb5 mb0-mobile" v-if="ticket.status == 'UBEREATS_CREATED'">UberEats</div>
                <div class="ticket-label reverse mb5 mb0-mobile" v-if="ticket.status == 'PYA_COURIER_ERROR'">Error PedidosYa Courier</div>

                <div :class="`flex align-center ${mobile ? 'flex-fill' : ''}`">
                    <i v-if="Util.TransactionSource[ticket.source]" :class="`mr5 icon icon-${Util.TransactionSource[ticket.source].icon}`"></i>
                    <div class="align-center" style="flex-direction: column-reverse" v-html="TicketType(ticket)"></div>
                </div>

                <div v-if="ticket.scheduled_at" class="mt10">
                    <i v-show="view == 'IAP'" class="icon icon-datetime" style="font-size: 20px"></i>
                    <div class="ta-center" v-show="view == 'SCHEDULED'" v-if="desktop">{{Util.Date(ticket.scheduled_at, 'DD/MM/YYYY')}}<br>{{Util.Date(ticket.scheduled_at, 'HH:mm')}}</div>
                </div>

            </div>
            
            <div :class="`relative ${desktop ? 'w20 br' : 'w100 bb'}`">

                <div class="pya-banner-ticket" v-if="ticket.custom_data && ticket.custom_data.pedidosya_courier">#{{ticket.custom_data.pedidosya_courier.referenceId}} - {{Util.StatusOrderPya[ticket.custom_data.pedidosya_courier.status]}} - {{Util.Date(ticket.custom_data.pedidosya_courier.lastUpdated, 'HH:mm DD/MMM')}}</div>
                <div class="pya-banner-ticket" v-if="ticket.custom_data && ticket.custom_data.integration_pya">{{Util.StatusOrderIntegrationPya[ticket.custom_data.integration_pya.status]}}<!--  - {{Util.Date(ticket.custom_data.integration_pya.lastUpdated, 'HH:mm DD/MMM')}} --></div>

                <div class="flex fill column pa10">

                    <div class="flex column mb5" v-if="ticket.custom_data.pedidosya_courier">
                        <div v-if="ticket.custom_data.pedidosya_courier.pickupTime"><span class="bold">Recolección: </span>{{Util.Date(ticket.custom_data.pedidosya_courier.pickupTime, 'DD/MM/YYYY HH:mm')}} <i class="icon icon-pedidosya" style="color: var(--color-pya);"></i></div>
                        <div v-if="ticket.custom_data.pedidosya_courier.deliveryTime"><span class="bold">Entregar: </span>{{Util.Date(ticket.custom_data.pedidosya_courier.deliveryTime, 'DD/MM/YYYY HH:mm')}} <i class="icon icon-pedidosya" style="color: var(--color-pya);"></i></div>
                    </div>
                    <!--
                    <div v-if="ticket.status == 'WAITING'">Esperando confirmación</div>
                    <div v-if="ticket.status == 'WAITING_PAYMENT'">Esperando pago online</div>
                    <div v-if="ticket.status == 'WAITING_BANK_DEPOSIT'">Esperando transferencia bancaria</div>
                    <div v-if="ticket.custom_data && ticket.custom_data.bank_deposit_at">Transferencia confirmada por el cliente</div>
                    <div v-if="ticket.status == 'ACCEPTED'">{{ticket.payment_at ? 'Pago confirmado e ingresado' : 'Confirmado por el cliente e ingresado'}}</div>
                    -->
                    <div><span class="bold">{{view == 'SCHEDULED' ? 'Ingresado' : 'Recibido'}}:</span> {{Util.Date(ticket.created_at, 'HH:mm DD/MM/YYYY')}}</div>
                    <div v-if="ticket.custom_data && ticket.custom_data.bank_deposit_at"><span class="bold">Pago realizado:</span> {{Util.Date(ticket.custom_data.bank_deposit_at, 'HH:mm DD/MM/YYYY')}}</div>
                    <div v-if="ticket.takeaway_time && ticket.takeaway_time !== 'SCHEDULE'"><span class="bold">Entrega:</span> {{ticket.takeaway_time == 'AUTO' ? 'Lo antes posible' : (ticket.takeaway_time + ' hrs')}}</div>
                    <div v-if="ticket.scheduled_at"><span class="bold">Programado:</span> {{Util.Date(ticket.scheduled_at, 'ddd DD [de] MMM  HH:mm')}}</div>
                    <div v-if="ticket.custom_data.shipment && ticket.type != 'TAKEAWAY'"><span class="bold">Envío:</span> {{Util.Date(ticket.custom_data.shipment.shipment_start, 'ddd DD [de] MMM')}} <span v-if="ticket.custom_data.shipment.shipment_end">- {{Util.Date(ticket.custom_data.shipment.shipment_end, 'ddd DD [de] MMM')}}</span></div>
                </div>
            </div>

            <div :class="`pa10 selectable ${desktop ? 'w20 br' : 'w100 bb'} relative`" @click="$refs.iapController.CustomerOptions(ticket)">
                <div class="fill flex column">
                    <div v-if="ticket.customer_name"><span class="bold">Nombre: </span>{{ticket.customer_name}}</div>
                    <div v-if="ticket.phone"><span class="bold">Contacto: </span>{{ticket.phone}}</div>
                    <div v-if="ticket.custom_data.rut"><span class="bold">RUT: </span>{{ Util.Rut(ticket.custom_data.rut) }}</div>
                    <div v-if="ticket.address && ticket.type == 'DELIVERY'" class="lh1">
                        <div><span class="bold">Dirección: </span>{{Util.Address(ticket.address, ticket.address_comment)}}</div>
                        <div v-if="ticket.custom_data.input_address" class="secondary-text">{{ticket.custom_data.input_address}}</div>
                    </div>
                </div>
                <i v-if="ticket.phone || ticket.address" class="icon icon-dots-vertical absolute" style="right: 5px; top: 10px"></i>
            </div>

            <div :class="`relative ${desktop ? 'w30 br' : 'w100 bb'} pt5 pb5`">
                <div class="fill flex column">
                    <div :class="`order-item pt5 pb5`"
                    v-for="(order, index2) in ticket.orders"
                    :key="index2"
                    style="padding-left: 10px">
                        <div>{{Util.Number(order.quantity)}}</div>
                        <div>{{order.title}}
                            <ul class="secondary-text">
                                <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(order)" :key="indexMod">{{modifier.value}}</li>
                            </ul>
                        </div>
                        <!--<div>{{Util.Price(order.total_money)}}</div>-->
                    </div>

                    <div class="secondary-text pa10" v-if="ticket.comment"><i class="icon icon-comment"></i> {{ticket.comment}}</div>
                </div>
            </div>
            
            <div :class="`${desktop ? 'w20 br' : 'w100 bb'}`">
                <div :class="`pa10 flex column ${ticket.status == 'SCHEDULED' && !ticket.payment_at ? 'selectable' : ''}`">
                    <div class="flex">
                        <div class="flex-fill">Subtotal</div>
                        <div>{{Util.Price(ticket.total.order)}}</div>
                    </div>
                    <div class="flex" v-if="ticket.total.round">
                        <div class="flex-fill">Ley del Redondeo</div>
                        <div>{{Util.Price(ticket.total.round)}}</div>
                    </div>
                    <div class="flex" v-if="ticket.type != 'TAKEAWAY'">
                        <div class="flex-fill">Envío</div>
                        <div>{{Util.Price(ticket.total.delivery)}}</div>
                    </div>
                    <div class="flex" v-if="ticket.total.discount">
                        <div class="flex-fill">Descuento</div>
                        <div>{{Util.Price(ticket.total.discount)}}</div>
                    </div>
                    <div class="flex" v-if="ticket.total.tip">
                        <div class="flex-fill">Propina</div>
                        <div>{{Util.Price(ticket.total.tip)}}</div>
                    </div>
                    <div :class="`flex bold ${ticket.payment_at ? 'cgreen' : ''}`" @click="ticket.status == 'SCHEDULED' && !ticket.payment_at ? $refs.iapController.Paid(ticket) : null">
                        <div class="flex-fill">Total <i class="icon icon-bill" v-if="ticket.payment_at"></i></div>
                        <div>{{Util.Price(ticket.total.total)}}</div>
                    </div>
                    <div v-if="ticket.custom_data.payment && ticket.custom_data.payment.length > 0">
                        <div v-for="(payment, indexPay) in ticket.custom_data.payment" 
                            class="flex-fill flex column mt5 selectable" 
                            @click="ticket.status == 'SCHEDULED' && payment.method != 'CASH' ? Confirm({
                                text: 'Guardar',
                                input: 'N°',
                                input_value: payment.n_transfer,
                                title: 'Ingresa n° de pago/transferencia',
                                class: 'selected-color',
                                function: (n) => { SaveNTransferScheduled(ticket, n, indexPay) }
                            }) : null" 
                            :key="indexPay" :class="{'cgreen' : payment.paid_at}" :title="payment.paid_at ? `Marcado como pagado ${Util.Date(payment.paid_at, 'LLL')}` : ''">
                            {{ Util.PayMethod(payment.method) }}
                            <div class="secondary-text" v-if="payment.method != 'CASH' && payment.n_transfer">
                                N°:
                                <span>{{ payment.n_transfer }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex-fill flex column mt5">
                        {{ Util.PayMethod(ticket.payment_method) }}
                    </div>
                </div>
                
            </div>

            
            <div :class="`flex ${desktop ? 'column' : ''}`">
                <div class="flex-fill" v-if="mobile"></div>
                <i v-if="canCreateTransactions && (ticket.status == 'PENDING' || ticket.status == 'UBEREATS_CREATED')" class="options icon-check marginless" @click="$refs.iapController.Accept(ticket)" v-tooltip.left="'Aceptar pedido'"></i>
                <i v-if="canCreateTransactions && ticket.status == 'PYA_COURIER_ERROR'" class="options icon-check marginless" @click="modalConfirmAccept = ticket" v-tooltip.left="'Reintentar Aceptar pedido'"></i>
                <i v-if="ticket.status == 'PENDING' && (Auth.role.indexOf('admin') > -1 || !!Auth.restrictions.can_reject_online_orders)"
                class="options icon-forbidden marginless" @click="$refs.iapController.Reject(ticket)" v-tooltip.left="'Rechazar pedido'"></i>
                <i v-if="ticket.status == 'ACCEPTED'" class="options icon-check marginless" @click="$refs.iapController.HideAccepted(ticket)" v-tooltip.left="'Quitar de la lista e imprimir voucher'"></i>
                <i v-if="canCreateTransactions &&  ticket.status == 'WAITING_BANK_DEPOSIT'" class="options icon-check marginless" @click="$refs.iapController.ConfirmBankDeposit(ticket)" v-tooltip.left="'Confirmar pago'"></i>
                <i v-if="ticket.status == 'WAITING_BANK_DEPOSIT'" class="options icon-cross marginless" @click="$refs.iapController.CancelScheduled(ticket)" v-tooltip.left="'Cancelar pedido'"></i>
                <i v-if="canCreateTransactions && ticket.status == 'SCHEDULED'" class="options icon-check marginless" @click="$refs.iapController.ConfirmScheduled(ticket)" v-tooltip.left="'Ingresar pedido agendado'"></i>
                <i v-if="ticket.status == 'SCHEDULED' && (!ticket.custom_data.pedidosya_courier || ticket.custom_data.pedidosya_courier.status == 'COMPLETED')" class="options icon-cross marginless" @click="$refs.iapController.CancelScheduled(ticket)" v-tooltip.left="'Cancelar pedido'"></i>
                <i v-if="ticket.status == 'WAITING_PAYMENT'" class="options icon-cross marginless" @click="$refs.iapController.CancelScheduled(ticket)" v-tooltip.left="'Cancelar pedido'"></i>
                <i  class="options icon-dots-vertical" @click="Options([
                    {text: 'Cancelar Envío PedidosYa Courier', class: 'error-color', function: () => {modalCancelPyaCourier = {id: ticket.custom_data.pedidosya_courier.id, reason: ''}}, if: !!(ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.status == 'CONFIRMED')},
                    {text: 'Ver Detalles', function: () => {$refs.iapDetails.Open(ticket, view)}},
                    {text: 'Editar Fecha', function: () => {$refs.iapController.OpenEditScheduled(ticket)}, if: ticket.status == 'SCHEDULED' && canEditScheduled && !ticket.custom_data.pedidosya_courier},
                    {text: 'Marcar como pagado', function: () => {$refs.iapController.Paid(ticket)}, if: ticket.status == 'SCHEDULED' && !ticket.payment_at},
                    {text: 'Imprimir', function: () => {Options([
                        {text: 'Imprimir Voucher', function: () => {Util.PrintTransaction(Util.InfluyeTransactionToTransactionV2(ticket), 'DEFAULT')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || view == 'IAP' ? true :  Auth.restrictions.reprint_options.includes('voucher'))},
                        {text: 'Imprimir para Cocina', function: () => {Util.PrintTransaction(Util.InfluyeTransactionToTransactionV2(ticket), 'PENDING')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || view == 'IAP' ? true : Auth.restrictions.reprint_options.includes('kitchen'))},
                        {text: 'Imprimir para Delivery', function: () => {Util.PrintTransaction(Util.InfluyeTransactionToTransactionV2(ticket), 'DELIVERY')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || view == 'IAP' ? true : Auth.restrictions.reprint_options.includes('delivery'))},
                        {text: 'Imprimir Todos', function: () => {Util.PrintTransaction(Util.InfluyeTransactionToTransactionV2(ticket))}, if: Auth.role.indexOf('admin') > -1 || view == 'IAP' ? true : ['voucher', 'kitchen', 'delivery'].every(value => Auth.restrictions.reprint_options.includes(value))},
                    ])}, if: !!(Auth.role.indexOf('admin') > -1 || (Auth.restrictions && view == 'IAP' ? Auth.restrictions.print_iap_voucher : Auth.restrictions.can_reprint))}
                ])"></i>
            </div>
            </div>

        </div>
        
    </div>

    <!-- DELIVERY -->
    <delivery-view v-if="showDelivery" v-show="view == 'DELIVERY'"></delivery-view>


<transaction-details ref="transactionDetails" v-if="saleData"
@OnTransactionEdit="OnTransactionEdit($event)"
@OnChangePlatform="ChangePlatform()"
@OnTransactionDelivery="OnTransactionDelivery"
@OnTransactionCanceled="OnTransactionCanceled"
:menu="saleData.menu"
:overwrites="saleData.overwrites"
:inventory="inventory"
:timestamp="saleData.timestamp">
</transaction-details>
<iap-details ref="iapDetails"></iap-details>

<modal v-model="modalConfirmAccept" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Aceptar Orden">
    <div class="section" v-if="modalConfirmAccept">
        <div class="bold text mb10">¿Desea intentar nuevamente aceptar la transacción?</div>

        <label>
            <input type="checkbox" v-model="modalConfirmAccept.skipPya"> Usar reparto Propio <div class="checkmark"></div>
        </label>
    </div>
    <!--<button slot="actions" v-if="modalConfirmAccept" class="primary error-color" @click="$refs.iapController.ReTryAcceptIAP(modalConfirmAccept); modalConfirmAccept = null">Aceptar con PedidosYa</button>-->
    <button slot="actions" v-if="modalConfirmAccept" :class="`primary ${'purple'}`" @click="$refs.iapController.ReTryAcceptIAP(modalConfirmAccept, modalConfirmAccept.skipPya); modalConfirmAccept = null">Aceptar</button>
</modal>


<modal v-model="modalTableReservation" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalTableReservation && modalTableReservation.status == 'RESERVED' ? 'Editar Reserva' : 'Reservar Mesa'">
    <div class="section" v-if="modalTableReservation">

        <div class="bold">Mesa</div>
        <div class="mb15">{{modalTableReservation.name}}</div>
        
        <input-date class="mb15"
        label="Fecha"
        v-model="modalTableReservation.reservation_data.date"
        format="timestamp">
        </input-date>
        
        <input-field class="mb15"
        v-model="modalTableReservation.reservation_data.name"
        label="Nombre"
        placeholder="(A nombre de quien hacer la reserva. Opcional)">
        </input-field>

        <input-field class="mb15"
        v-model="modalTableReservation.reservation_data.phone"
        label="Teléfono"
        placeholder="(Número de contacto. Opcional)"
        type="phone">
        </input-field>

        <input-field class="mb15"
        v-model="modalTableReservation.reservation_data.email"
        label="Email"
        type="email"
        placeholder="(Email de contacto. Opcional)">
        </input-field>

        <input-field
        v-model="modalTableReservation.reservation_data.comment"
        label="Comentario"
        placeholder="(Comentario adicional. Opcional)"
        :lines="3">
        </input-field>

    </div>
    
    <button slot="actions" v-if="modalTableReservation && modalTableReservation.status == 'RESERVED'" class="primary error-color" @click="DeleteReservation(modalTableReservation)">Eliminar</button>
    <button slot="actions" v-if="modalTableReservation" :class="modalTableReservation.status == 'AVAILABLE' ? `primary purple` : 'secondary'" @click="TableReservation()">{{modalTableReservation.status == 'AVAILABLE' ? 'Reservar' : 'Editar'}}</button>
    <button slot="actions" v-if="modalTableReservation && modalTableReservation.status == 'RESERVED' && canEditTables" class="primary purple" @click="modalOpenTable = modalTableReservation">Abrir Mesa</button>
    
</modal>

<modal v-model="modalCloseTable" :dir="['right', 'right']" :modalClass="['fill', 'sm right h100']" :title="modalTable && modalTable.selected_diner && modalTable.selected_diner.value != 'ALL' ? `Cerrar cuenta ${modalTable.selected_diner.text}`: 'Cerrar Mesa'">
    <div class="pa20" v-if="modalCloseTable">
        <order-form
        v-model="tableItems"
        ref="order"
        :baseBenefits="saleData.promotions"
        :settings="saleData.settings"
        :categories="saleData.categories"
        :tables="[]"
        :hideSaleType="true"
        :hideCustomerCreation="true"
        :hideComment="true"
        :hideActions="true"
        :canEditItems="false"
        :hideLastOrder="true">
        </order-form>
    </div>
    
    <button slot="actions" class="secondary" @click="PrintTableSummary(modalCloseTable)">Imprimir Pre Cuenta</button>
    <!--<button @click="$refs.order.OpenTip()">Agregar Propina</button>-->
    <button slot="actions" class="primary purple" v-if="showBtnDiner" @click="CloseTable()">{{ modalTable && modalTable.selected_diner && modalTable.selected_diner.value != 'ALL' ? 'Cerrar cuenta': 'Cerrar Mesa'}}</button>
    
</modal>

<modal v-model="modalUpdateItems" dir="center" modalClass="sm" :title="modalUpdateItems ? (UpdateItemActions[modalUpdateItems.action].title) : ''">
    <div class="section" v-if="modalUpdateItems && Util.isInt(modalUpdateItems.item.quantity)">
        <div class="bold">Cantidad</div>
        <div class="secondary-text mb10">Seleccione la cantidad de {{modalUpdateItems.item.title}} {{UpdateItemActions[modalUpdateItems.action].instruction}}</div>
        <div class="flex align-end">
            <i class="options icon-minus mr5" @click="modalUpdateItems.quantity > 1 ? $set(modalUpdateItems, 'quantity', modalUpdateItems.quantity-1) : null"></i>
            <input-field class="flex-fill"
            v-model="modalUpdateItems.quantity"
            type="float">
            </input-field>
            <i class="ml5 options icon-plus" @click="modalUpdateItems.quantity < modalUpdateItems.item.quantity ? $set(modalUpdateItems, 'quantity', modalUpdateItems.quantity+1) : null"></i>
        </div>
    </div>
    
    <button v-if="modalUpdateItems" slot="actions" class="primary purple" @click="OrderItemsUpdated(modalUpdateItems.action, modalUpdateItems.item, modalUpdateItems.index, modalUpdateItems.transaction, modalUpdateItems.quantity, modalUpdateItems.table)">{{UpdateItemActions[modalUpdateItems.action].action}}</button>
    
</modal>

<modal v-model="modalEdit" dir="right" :modalClass="['fill', 'xl right h100']" :title="modalEdit ? `Editar ${'#'+Util.Zeropad(modalEdit.id_ticket, 10)}` : ''" hideActions>
    <input-select
    v-show="platforms && platforms.length"
    slot="options"
    :icon="mobile? 'menu': ''"
    :items="platforms"
    :triggerFirstValueChanged="false"
    v-model="selectedPlatform"
    @OnValueChanged="ChangePlatform($event)"
    inputClass="secondary">
    </input-select>

    <div class="flex-fill fill relative flex column" v-if="saleData">
        <sales-form ref="salesForm"
        :transaction="modalEdit"
        :menu="saleData.menu" 
        :categories="saleData.categories" 
        :promotions="saleData.promotions" 
        :settings="saleData.settings" 
        :tables="saleData.tables"
        :overwrites="filteredOverwrite"
        :timestamp="timestamp"
        :inventory="inventory"
        @OnTransactionEdit="OnTransactionEdit($event)"
        @OnOutOfDateTransaction="modalEdit = null; $nextTick(() => {modalEdit = $event})">
        </sales-form>
    </div>
</modal>

<modal v-model="modalChangeWaiter" title="Cambiar Mesero" dir="center" modalClass="sm">
    
    <div class="section" v-if="modalChangeWaiter">

        <!--
        <div class="bold">Mesero actual</div>
        <div class="mb15">{{modalChangeWaiter.previous}}</div>
        -->

        <label class="entry"
        v-for="(account, index) in waiterAccounts"
        :key="index"
        @click="$set(modalChangeWaiter, 'selected', account.id)">
            <input type="radio" :value="account" :checked="modalChangeWaiter.selected == account.id"> <span class="secondary-text ml5" v-if="modalChangeWaiter.previous == account.id"> (mesero actual)</span> {{account.display_name}} <div class="radio"></div>
        </label>
    </div>
    
    <button slot="actions" class="primary purple" v-if="modalChangeWaiter" :disabled="modalChangeWaiter.selected === modalChangeWaiter.previous" @click="ChangeWaiter">Cambiar</button>
    
</modal>

<modal v-model="modalChangeTable" :title="modalChangeTable && modalChangeTable.transfer ? 'Transferir Pedidos' : 'Cambiar Mesa'" dir="center" modalClass="sm">
    <div v-if="modalChangeTable" class="pa20">

        <div class="bold">Mesa actual</div>
        <div class="mb15">{{modalChangeTable.previous}}</div>


        <input-select label="Nueva mesa"
        v-model="modalChangeTable.selected"
        :items="TransferAvailableTables"
        canType="search" pre="table"
        coverInput>
        </input-select>
       
    </div>
    
    
    <button v-if="modalChangeTable && modalChangeTable.transfer" slot="actions" class="primary purple" :disabled="modalChangeTable.selected === modalChangeTable.previous" @click="OrderItemsUpdated('transfer', modalChangeTable.item, modalChangeTable.index, modalChangeTable.transaction, modalChangeTable.quantity, modalChangeTable.selected)">Transferir</button>
    <button v-if="modalChangeTable && !modalChangeTable.transfer" slot="actions" class="primary purple" :disabled="modalChangeTable.selected === modalChangeTable.previous" @click="ChangeTable">Cambiar</button>
    
</modal>

<modal v-model="modalJoinTable" :title="`Unir mesa`" dir="center" modalClass="sm">
    <div v-if="modalJoinTable" class="pa20">

        <div class="bold">Mesa</div>
        <div class="mb15">{{modalJoinTable.source}}</div>


        <input-select label="Unir a"
        v-model="modalJoinTable.target"
        :items="JoinAvailableTables"
        canType="search" pre="table"
        coverInput>
        </input-select>

    </div>
    
    
    <button slot="actions" v-if="modalJoinTable" class="primary purple" :disabled="!modalJoinTable.target" @click="JoinTable()">Unir Mesas</button>
    
</modal>

<modal v-model="modalOpenTable" :title="modalOpenTable && modalOpenTable.name? `Abrir ${modalOpenTable.name}` : 'Abrir mesa'" dir="center" modalClass="sm">
    <div v-if="modalOpenTable" class="pa20">
        
        <div class="ta-center">¿Abrir {{modalOpenTable.name}}?</div>

        <input-field class="flex-fill mr10 mt10"
        v-model="modalOpenTable.diners"
        type="int+"
        label="Comensales"
        placeholder="(Opcional)"
        info="La cantidad mínima debe ser 1 comensal.">
        </input-field>
        <label class="mt15">
            <input type="checkbox" @click="CheckChangeSplitTable" v-model="modalOpenTable.split_table" >Ingresar nombres de comensales<div class="checkmark"></div>
        </label>
        <div  class="flex-fill mt15" v-if="modalOpenTable.split_table">
            <input-field
                v-for="(diner, index) in modalOpenTable.diners"
                :key="index"
                class="flex-fill mt10"
                v-model="modalOpenTable.customers[index]"
                :placeholder="`Nombre comensal ${diner}`"
                :label="`Comensal #${diner}`"
            >
            </input-field>
        </div>


    </div>
    
    <button slot="actions" v-if="modalOpenTable" class="secondary" @click="modalOpenTable=null">Cancelar</button>
    <button slot="actions" v-if="modalOpenTable" class="primary purple" @click="OpenTable(modalOpenTable)">Abrir Mesa</button>
</modal>

<!-- MODAL CHANGE DINER  -->
<modal v-model="modalChangeDinerFromItem" :title="modalChangeDinerFromItem && !tableItemsSelection ? `Transferir ${modalChangeDinerFromItem.title} `: 'Transferir Items Seleccionados'" dir="center" modalClass="sm">
    <div v-if="modalChangeDinerFromItem" class="pa20">
        
        <div v-if="modalChangeDinerFromItem.diner && modalChangeDinerFromItem.diner != 'ALL' && !tableItemsSelection" class="mb20">
            <div class="bold">Comensal actual</div>
            <div class="mb15">{{ DictTagDinerName(modalChangeDinerFromItem.diner, true)}}</div>
        </div>
        
        <input-select 
            class="flex-fill"
            label="Seleccionar comensal"
            v-model="modalChangeDinerFromItem.new_diner"
            :items="dinersListTransfer"
        ></input-select>

    </div>
    
    <button slot="actions" v-if="modalChangeDinerFromItem" class="secondary" @click="modalChangeDinerFromItem=null">Cancelar</button>
    <button slot="actions" v-if="modalChangeDinerFromItem" class="primary purple" @click="ChangeDinerToItem(modalChangeDinerFromItem,modalChangeDinerFromItem.new_diner)">Cambiar</button>
</modal>
<!-- MODAL CHANGE DINER  -->

<modal v-model="modalCancelMultiple" :dir="['center', 'center']" :modalClass="['sm', 'sm']" title="Cancelar Pedidos">
    <div class="section" v-if="modalCancelMultiple">
    
    
        <input-field v-model="modalCancelMultiple.reason" label="Motivo"
        class="mb15">
        </input-field>
    
		<div class="bold mb10">Inventario</div>
        
        <label class="mb10">
            <input type="radio" name="inventory" :value="false" v-model="modalCancelMultiple.reduce_inventory"> No reducir inventario <div class="radio"></div>
        </label>
        <label class="cred">
            <input type="radio" name="inventory" :value="true" v-model="modalCancelMultiple.reduce_inventory"> Reducir inventario <div class="radio"></div>
        </label>
        
	</div>

    
    <button class="primary error-color" v-if="modalCancelMultiple" slot="actions"
    @click="Confirm({
    text: 'Cancelar Pedidos', 
    class: 'error-color', 
    cancel: false,
    title: `¿Cancelar ${modalCancelMultiple.transactions.length} pedidos? ${modalCancelMultiple.reduce_inventory ? '(Reduciendo el inventario)' : '(Sin reducir inventario)'}`,
    function: () => {CancelMultiple(modalCancelMultiple)}})">Cancelar Pedido</button>

</modal>

<modal v-model="modalPartialPayment" title="Pago Parcial" :dir="['right', 'center']" :modalClass="['fill', 'sm']">
    <div class="pa20" v-if="modalPartialPayment">
        <div class="flex mb20">

            <div class="mr20">
                <div class="bold">Total</div>
                <div>{{Util.Price(modalPartialPayment.total)}}</div>
            </div>
 
            <div class="mr20">
                <div class="bold">Registrado</div>
                <div>{{Util.Price(modalPartialPayment.totalPaid)}}</div>
                <div class="cgreen">{{Util.Price(partialPaymentTotal)}}</div>
            </div>

            <div>
                <div class="bold">Restante</div>
                <div>{{Util.Price(modalPartialPayment.total - modalPartialPayment.totalPaid)}}</div>
                <div class="cred">{{Util.Price(modalPartialPayment.total - modalPartialPayment.totalPaid - partialPaymentTotal)}}</div>
            </div>
            
        </div>


        <input-select 
            class="mb15"
            v-if="modalTable.custom_data['diners_names'] && dictDiners"
            v-model="modalPartialPayment.valueDiner"
            @OnValueChanged="ChangeDinerPartialPayment"
            :items="dinersList"
            label="Comensales"
        ></input-select>


        <input-field label="Parte a Pagar" 
        v-model="modalPartialPayment.subtotal" 
        v-if="!tableItemsSelection" 
        info="`<ul><li>Porcentaje: 10% ó 10x ó 10X equivale al 10% del total</li><li>Resto: -10000 equivale al $10.000 - el total</li><li>Fijo: 2500 equivale a un fijo de $2.500</li></ul>`"
        class="mb15">
           <div class="append unshrink" slot="after">{{Util.Price(partialPaymentTotal)}}</div> 
        </input-field>

        
        <input-select v-model="modalPartialPayment.tipTotal" 
        :items="modalPartialPayment.totalTips" 
        label="Propina"
        :class="modalPartialPayment.tipTotal != 'NO_TIP' ? `input-top` : 'mb15'"></input-select>

        <input-field class="input-bottom mb15"
        v-model="modalPartialPayment.tip" 
        v-show="modalPartialPayment.tipTotal != 'NO_TIP'"
        info="<ul><li>Porcentaje: 10% ó 10x ó 10X equivale al 10% del total</li><li>Resto: -10000 equivale al $10.000 - el total</li><li>Fijo: 2500 equivale a un fijo de $2.500</li></ul>">
            <div class="append unshrink" slot="after">{{Util.Price(partialPaymentTip)}}</div>
        </input-field>

            
        <input-select :items="Util.SettingsToPaymentMethods(saleData.settings)" 
        v-model="modalPartialPayment.method" 
        label="Método de Pago"
        :class="modalPartialPayment.method == 'CASH' ? 'input-top' : ''"></input-select>
        <input-field v-if="modalPartialPayment.method == 'CASH'" class="input-bottom"
        v-model="modalPartialPayment.cash" type="int+" placeholder="Efectivo (Opcional)">
            <div v-if="modalPartialPayment.method == 'CASH' && modalPartialPayment.cash && modalPartialPayment.cash > (partialPaymentTotal + partialPaymentTip)" slot="after" class="append unshrink"><span class="bold mr5">Vuelto </span>{{Util.Price(modalPartialPayment.cash - partialPaymentTotal + partialPaymentTip)}}</div>
        </input-field>
        

    </div>
    
    <button slot="actions" class="primary purple" @click="RegisterPartialPayment()">Registrar {{Util.Price(partialPaymentTotal + partialPaymentTip)}}</button>
    
</modal>

<modal v-model="modalItemsStatusUpdate" dir="center" modalClass="sm" title="Marcar Pedido">

    <div class="pa20" v-if="modalItemsStatusUpdate">

        <input-select label="Items"
        v-model="modalItemsStatusUpdate.selection"
        description="Determina que items serán marcados como entregado"
        :items="[{text: 'Todos', value: 'ALL'}, {text: 'Preparados', value: 'PREPARED'}]">
        </input-select>

    </div>

    <button class="primary purple" slot="actions" @click="OrderItemsUpdated(modalItemsStatusUpdate.status, modalItemsStatusUpdate.selection, null, modalItemsStatusUpdate.transaction)">Marcar</button>

</modal>

<modal v-model="modalPrintEdit" dir="center" modalClass="sm">
    <div class="pa20" v-if="modalPrintEdit">
        <div class="ta-center">¿Imprimir voucher de cocina del pedido modificado?</div>
        <div class="secondary-text mt10">Puedes imprimir sólo los <span class="bold">Nuevos</span> productos agregados al pedido o <span class="bold">Todos</span> (nuevos y agregados anteriormente)</div>
    </div>
    <button slot="actions" class="secondary" @click="modalPrintEdit = null">Cancelar</button>
    <button v-if="modalPrintEdit" slot="actions" class="primary yellow" :disabled="!modalPrintEdit.shouldPrintNewOnly" @click="Util.PrintTransaction(modalPrintEdit.newItems, 'PENDING');modalPrintEdit=null">Nuevos</button>
    <button slot="actions" class="primary purple" @click="Util.PrintTransaction(modalPrintEdit.allItems, 'PENDING');modalPrintEdit=null">Todos</button>
</modal>

<modal v-model="modalCancelPyaCourier" :dir="['center', 'center']" :modalClass="['sm', 'sm']" title="Cancelar Envío">
    <div class="section" v-if="modalCancelPyaCourier">
    
    
        <input-field v-model="modalCancelPyaCourier.reason" 
        label="Motivo"
        description="Breve descripción del motivo de cancelación"
        placeholder="(Requerido)">
        </input-field>
    
	</div>

    
    <button class="primary error-color" v-if="modalCancelPyaCourier" slot="actions"
    @click="CancelPyaCourier">Cancelar Envío</button>

</modal>

<!-- MODAL PARA OCULTAR/MOSTRAR SECCIONES/ITEMS EN DISPLAY -->
<modal v-model="modalHideItemsInDisplay" dir="right" :modalClass="['fill', 'md right h100']" title="Visibilidad Display Cocina">
    <input-field 
        slot="options" 
        class="mr10" 
        inputClass="secondary"
		v-model="searchHideItemsInDisplay"
		placeholder="Buscar..."
		pre="search"
		cleareable
		:timeout="300"
		:icon="mobile ? 'search' : ''"
		useHeader>
	</input-field>
    <div class="section" v-if="modalHideItemsInDisplay">
        <div v-for="item in searchHideItems" :key="item.id">
            <label class="mb10 restriction-option">
                <input type="checkbox" v-model="modalHideItemsInDisplay.selections[item.title]"><span class="bold" style="font-size: 20px;">{{ item.title }}</span><div class="checkmark reverse"></div>
            </label>
            <label class=" mb10 restriction-option" v-for="(sub) in item.items" :key="sub.id" v-show="!modalHideItemsInDisplay.selections[item.title]"
            style="margin-left: 30px">
                <input type="checkbox" v-model="modalHideItemsInDisplay.selections[sub.id]"><span class="secondary">{{ sub.title }}</span><div class="checkmark reverse"></div>
            </label>
            <div class="separator mb20 mt20"></div>
        </div>
	</div>
    <button class="primary" slot="actions" @click="SaveHideItems()">Guardar</button>
</modal>
<!-- MODAL PARA OCULTAR/MOSTRAR SECCIONES/ITEMS EN DISPLAY -->
</div>
</template>

<script>
import SalesView from '../views/SalesView.vue';
import DeliveryView from '../views/DeliveryView.vue';
export default {
    components: {SalesView, DeliveryView},
    data() {
        return {
            tickets: [],
            updating: {},
            accounts: [],
            modalDelivery: false,
            modalConfirm: false,
            modalAccept: null,
            modalReject: null,
            showInfo: true,
            fontSize: 16,
            columns: 0, //masonry layout
            intervalOfflineUpdate: null,
            offlineTickets: [],
            cancelReduceStock: false,
            timer: 0,
            intervalReference: null,
            config: {
                show_cancelled: false,
                cancelled_time: 2
            },
            modalConfig: null,
            settings: {},
            modalUpdateItems: null,
            tables: null,
            showPos: true,
            view: 'POS',
            dayMinutes: null,
            modalEdit: false,
            //Datos de ingreso de pedidos para editar pedidos (menu, medio de pagos, etc)
            saleData: null,
            inventory: null,
            kitchenFontSize: 12,
            audioAttention: null,
            attentionSoundEnabled: false,
            audioPreparedAttention: null,
            attentionPreparedSoundEnabled: false,
            //iapSoundEnabled: false,

            //MESAS
            currentTable: null,
            modalTableReservation: null,
            searchTable: '',
            tablesSort: 'DEFAULT',
            modalMobileTables: false,
            modalMobileTableOptions: false,
            modalCloseTable: null,
            saleData: null,
            tableItems: {},
            modalTable: null,
            modalChangeWaiter: null,
            waiterAccounts: null,
            modalChangeTable: null,
            tableItemsSelection: null,
            modalPartialPayment: null,
            sortByItem: null,
            selectedDiner : null,
            
            autoPrintOnEdit: false,
            autoPrintNewOnly: false,
            autoPrintTableOnEdit: false,
            autoPrintTableNewOnly: false,
            autoPrintCancelled: false,
            
            modalJoinTable: null,

            idSelectedTicket: null,
            filter: 'ALL',
            iap: null,
            sort: 'CUSTOM',
            timestamp: null,
            modalCancelMultiple: null,
            searchPending: '',
            searchIAP: '',

            modalItemsStatusUpdate: null,

            UpdateItemActions: {
                prepared: {
                    title: 'Marcar como preparado',
                    instruction: ' que se han preparado',
                    action: 'Marcar como preparado',
                    confirm: function(str) {return `¿Marcar ${str} como preparados?`},     
                },
                delivered: {
                    title: 'Marcar como entregado',
                    instruction: ' que se han entregado',
                    action: 'Marcar como entregado',
                    confirm: function(str) {return `¿Marcar ${str} como entregados?`},
                },
                remove_delivered: {
                    title: 'Desmarcar como entregado',
                    instruction: ' que se han entregado',
                    action: 'Desmarcar como entregado',
                    confirm: function(str) {return `¿Desmarcar ${str} como entregados?`},
                },
                paid: {
                    title: 'Marcar como pagado',
                    instruction: ' que se han pagado',
                    action: 'Marcar como pagado',
                    confirm: function(str) {return `¿Marcar ${str} como pagados?`},
                },
                remove: {
                    title: 'Quitar del pedido',
                    instruction: ' a eliminar del pedido',
                    action: 'Quitar',
                    confirm: function(str) {return `¿Quitar ${str} del pedido?`},
                },
                transfer: {
                    title: 'Transferir a otra mesa',
                    instruction: ' a transferir a otra mesa',
                    action: 'Transferir',
                    confirm: function(str, table) {return `¿Transferir ${str} a la mesa ${table}?`},
                },
                split: {
                    title: 'Separar item',
                    instruction: ' a separar (seleccionarlos todos separará todos los items)',
                    action: 'Separar',
                    confirm: function(str) {return `¿Separar ${str}?`},
                },
            },

            itemsOrderBy: [
                {text: 'Ordenar manualmente', value: 'CUSTOM'},
                {text: 'Ordenar por número', value: 'TICKET'},
                {text: 'Ordenar próximos a entregar', value: 'SCHEDULED'},
                {text: 'Ordenar por total', value: 'TOTAL'},
                /*{text: 'Ordenar por número de items', value: 'ITEMS'},*/
            ],
            itemsFilterBy: [
                {text: 'Mostrar todos', value: 'ALL'},
                {text: 'Mostrar en preparación', value: 'PREPARING'},
                {text: 'Mostrar listos', value: 'READY'},
                {text: 'Mostrar para delivery', value: 'DELIVERY'},
                {text: 'Mostrar despachados', value: 'ON_DELIVERY'},
                {text: 'Mostrar para llevar', value: 'TAKEAWAY'},
                {text: 'Mostrar listos para retirar', value: 'TAKEAWAY_READY'},
                {text: 'Mostrar para servir', value: 'SERVE'},
                {text: 'Mostrar pagados', value: 'PAID'},
                {text: 'Mostrar no pagados', value: 'NOT_PAID'},
            ],
            itemsTablesOrderBy: [
                {value: 'DEFAULT', text: 'Ordenar por Sección'},
                {value: 'STATUS', text: 'Ordenar por Estado'},
            ],
            typeOrder: {
                custom: 'personalizado',
                section: 'por secciones',
                automatically: 'automático',
            },
            scheduledDateFilter: null,
            modalPrintEdit: null,
            modalCancelPyaCourier: null,
            modalOpenTable: null,
            modalDisjoinTable: null,
            modalChangeDiners: null,
            typeSale: null,
            kitchenShowPrepared: false,
            kitchenShowDelivered: false,
            dictPlatforms: {
                SCHEDULE: 'Ninguno',
                pedidos_ya: 'PedidosYa',
                uber_eats: 'Uber Eats',
            },
            typeMenu: 'SCHEDULE',
            selectedPlatform: null,
            showCreatedByEnabled : false,
            modalChangeDinerFromItem: null,
            dictDiners : {},
            showBtnDiner : true,
            newTotalMoney : 0,
            modalConfirmAccept: null,
            modalHideItemsInDisplay : null,
            searchHideItemsInDisplay : null,
            hideItemsInDisplay : {}
        }
    },
    mounted() {

        this.showPos = this.Local.modules.module_pos;
        if(!this.showPos) this.view = 'LIST';
        
        if(this.Auth.role == 'waiter') 
        {
            this.view = 'TABLES';
            this.tablesSort = 'STATUS';
        }
        else if(this.Auth.role == 'delivery')
        {
            return this.view = 'DELIVERY';
        }
        else if(this.Auth.role == 'kitchen')
        {
            this.view = 'KITCHEN';
        }
        else if(this.Auth.role == 'cashier' && !this.Auth.restrictions.create_transactions)
        {
            this.view = 'LIST';
        }

        var previousTab = this.$store.state.ordersTab;
        if(previousTab) this.view = previousTab;

        var moment = require('moment');
        this.timer = moment().unix();

        this.Loading();
        axios.get(`/pending/${this.Local.id}`).then(res => {
            this.accounts = res.data.delivery;
            this.settings = res.data.settings;
            this.tables = res.data.tables;
            this.Response(res);
        }).catch(err => {
            this.Response(err);
        });
        this.Update();

        this.intervalReference = setInterval(() => {
            this.timer += 10;
            if(this.timestamp) this.timestamp += 10;
            for(var i = this.tickets.length-1; i >= 0; i--)
            {
                //console.log(`${this.timer} - ${this.tickets[i].cancelled_at} = ${this.timer - this.tickets[i].cancelled_at} >= ${this.settings.cancelled_transactions_time * 60}`);
                if(this.tickets[i].status == 'CANCELLED' && this.timer - this.tickets[i].cancelled_at >= (this.settings.cancelled_transactions_time * 60))
                    this.tickets.splice(i, 1);
            }

            if(!this.$socket.connected) this.Update();

            var date = new Date();
            this.dayMinutes = date.getHours()*60 + date.getMinutes();

        }, 1000*10);

        var date = new Date();
        this.dayMinutes = date.getHours()*60 + date.getMinutes();

        //cargar los tamños de fuentes
        if(localStorage.kitchenFontSize && !isNaN(localStorage.kitchenFontSize) && localStorage.kitchenFontSize >= 8) this.kitchenFontSize = parseInt(localStorage.kitchenFontSize); 
        if(localStorage.attentionSoundEnabled) this.attentionSoundEnabled = true;
        if(localStorage.attentionPreparedSoundEnabled) this.attentionPreparedSoundEnabled = true;
        if(localStorage.showCreatedByEnabled) this.showCreatedByEnabled = true;
        //if(localStorage.iapSoundEnabled) this.iapSoundEnabled = true;
        if(localStorage.autoPrintOnEdit) this.autoPrintOnEdit = true;
        if(localStorage.autoPrintNewOnly) this.autoPrintNewOnly = true;
        if(localStorage.autoPrintTableOnEdit) this.autoPrintTableOnEdit = true;
        if(localStorage.autoPrintTableNewOnly) this.autoPrintTableNewOnly = true;
        if(localStorage.autoPrintCancelled) this.autoPrintCancelled = true;

        if(localStorage.kitchenShowPrepared) this.kitchenShowPrepared = true;
        if(localStorage.kitchenShowDelivered) this.kitchenShowDelivered = true;

        let hideItemsFromStorage = JSON.parse(localStorage.getItem('hideElementsDisplayKitchen'));
        if(hideItemsFromStorage) this.hideItemsInDisplay = hideItemsFromStorage;

        this.audioAttention = new Audio(require('../../assets/attention.mp4'));
        this.audioPreparedAttention = new Audio(require('../../assets/bell_prepared.mp4'));

        this.UpdateMenu();
        this.UpdateInventory();

        if(this.Auth.role !== 'cashier' || this.Auth.restrictions.cashier_shared_transactions)
            this.itemsFilterBy.push({text: 'Mostrar ingresados por mí', value: 'CREATED_BY'});
    },
    methods: {
        Update() {
            console.log('update transactions');
            axios.get(`/transactions/${this.Local.id}/active`).then(res => {
                this.tickets = res.data;
                this.Response(res);
            }).catch(err => {
                this.updating = {};
                if(err.message !== 'Network Error') this.Response(err);
            });

        },
        UpdateTransaction(transaction)
        {
            for(var i = 0; i < this.tickets.length; i++)
            {
                if(this.tickets[i].id_ticket == transaction.id_ticket)
                {
                    if(transaction.status == 'CANCELLED')
                    {
                        if(this.settings.cancelled_transactions_time > 0)
                            return this.$set(this.tickets, i, transaction);
                        else
                            return this.tickets.splice(i, 1);
                    }
                    else if(transaction.status == 'COMPLETED')
                        return this.tickets.splice(i, 1);
                    else
                        return this.$set(this.tickets, i, transaction);
                        
                }
            }
            this.$forceUpdate();
        },
        UpdateMenu() {
            axios.get(`/sale/${this.Local.id}`).then(res => {
                /*
                var deliveryCosts = [];
                res.data.settings.delivery_costs.forEach(e => {
                    deliveryCosts.push({text: (e.title ? e.title+': ' : '') + Util.Price(e.cost), value: e.cost});
                });
                res.data.settings.delivery_costs = deliveryCosts;
                */
                this.timestamp = res.data.timestamp;
                this.saleData = res.data;
                this.tables = res.data.tables;
            }).catch(err => {
                console.log(err);
            });
        },
        UpdateInventory() {
            axios.get(`/inventory/${this.Local.id}/current`).then(res => {
                this.inventory = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
        OnTransactionCanceled(transaction)
        {
            console.log('ON TRANSACTION CANCELED');
            this.UpdateTransaction(transaction);
            this.modalMobileTableOptions = false;
            this.modalTable = null;

            if(this.autoPrintCancelled)
            {
                this.Util.PrintTransaction(transaction, 'PENDING');
            }

        },
        OnTransactionDelivery(transaction)
        {
            console.log('ON TRANSACTION DELIVERY');
            this.UpdateTransaction(transaction);
            if(this.settings.default_delivery_message && transaction.customer.phone)
            {
                this.Confirm({
                    title: 'El pedido ha sido enviado. ¿Enviar mensaje al cliente?',
                    text: 'Enviar Mensaje por Whatsapp',
                    class: 'selected-color',
                    function: () => {this.Util.OpenWhatsapp(transaction.customer.phone, this.settings.default_delivery_message, {'{name}': transaction.customer.name, '{address}': transaction.customer.address});}
                });
            }
        },
        CompleteTransaction(transaction)
        {
            this.Loading();
            axios.post(`/transaction/${transaction.id}/complete`, {}).then(res => {
                this.Response(res);
                this.UpdateTransaction(res.data);
                this.modalMobileTableOptions = false;

                if(this.Local.modules.module_sii_boleta && !res.data.id_boleta)
                {
                    this.Confirm({
                        title: 'Esta transacción no tiene boleta electrónica ¿emitir e imprimir una ahora?',
                        text: 'Emitir boleta electrónica',
                        class: 'selected-color',
                        function: () => {this.$refs.transactionDetails.SendBoleta(transaction.id)}
                        //function: () => {this.$refs.modalTicket.SendBoleta(transaction.id)}
                    });
                }

            }).catch(err => {
                this.Response(err);
            });
        },
        DropTicket(data)
        {
            var orderData = {};
            var tmpTickets = JSON.parse(JSON.stringify(this.visibleTickets));
            
            for(var i = 0; i < tmpTickets.length; i++)
                orderData[tmpTickets[i].id] = i+1;
            
            this.ReorderTickets(orderData);

            axios.post(`/transactions/${this.Local.id}/reorder`, orderData).catch(err => {
                this.Response(err);
            });

        },
        ReorderTickets(order)
        {
            for(var i = 0; i < this.tickets.length; i++)
            {
                if(order[this.tickets[i].id]) this.$set(this.tickets[i].custom_data, 'priority', parseInt(order[this.tickets[i].id]));
            }
        },
        OrderColor(ticket)
        {
            //ticket iap
            if('id_transaction' in ticket)
            {
                if(ticket.source == 'UBEREATS') return '#3FC060';
                if(ticket.type == 'DELIVERY') return 'var(--color-delivery-transit)';
                if(ticket.type == 'TAKEAWAY') return 'var(--color-takeaway-time)';
                if(ticket.type == 'SHIPMENT') return 'var(--color-shipment-transit)';
            }

            if(!ticket || !this.settings) return '';
            if(ticket.status == 'CANCELLED') return 'var(--color-cancelled)';
            if(this.settings.preparation_step && !ticket.custom_data.preparation_at) return 'var(--color-preparation-pending)';
            if(ticket.custom_data.shipment){
                if(ticket.custom_data.is_shipment_takeaway || ticket.custom_data.activation_time) return ticket.custom_data.takeaway_ready ? 'var(--color-takeaway-time)' : 'var(--color-takeaway-pending)';
                if(ticket.custom_data.delivered_at) return 'var(--color-shipment-delivered)';
                return ticket.status == 'DELIVERY' ? 'var(--color-shipment-transit)' : 'var(--color-shipment)';
            } 
            if(ticket.type == 'DELIVERY')
            {
                if(ticket.custom_data.delivered_at) return 'var(--color-delivered)';
                return ticket.status == 'DELIVERY' ? 'var(--color-delivery-transit)' : 'var(--color-delivery)';
            } 
            if(ticket.type == 'SERVE') return ticket.table_name ? 'var(--color-table)' : 'var(--color-serve)';
            if(ticket.type == 'TAKEAWAY') return ticket.custom_data.activation_time ? (ticket.custom_data.takeaway_ready ? 'var(--color-takeaway-time)' : 'var(--color-takeaway-pending)') : 'var(--color-takeaway)';
            
            return this.settings ? this.settings.color : 'gray';
        },
        ChangeToColumns()
        {
            this.columns = Math.floor(this.$refs.mainContainer.getBoundingClientRect().width / 230);
        },
        ColumnTickets(column)
        {
            var ret = [];
            for(var i = column-1; i < this.visibleTickets.length; i+=this.columns)
            {
                ret.push(this.visibleTickets[i]);
            }
            return ret;
        },
        OnTransactionEdit(data, printOptions)
        {
            console.log('TRANSACTION EDITTTT');
            var transaction = data.transaction ? data.transaction : data;
            for(var i = 0; i < this.tickets.length; i++)
            {
                if(this.tickets[i].id == transaction.id)
                {
                    this.$set(this.tickets, i, transaction);
                    this.$refs.transactionDetails.UpdateTransaction(transaction);
                    break;
                }
            }
            var oldTransaction = this.Util.Copy(this.modalEdit);

            if(this.modalEdit && this.modalEdit.id == transaction.id)
            {
                if(printOptions !== false) this.modalEdit = null;
            }

            if(this.modalTable && this.modalTable.id == transaction.id)
            {
                this.modalTable = transaction;
                if(this.Auth.role == 'waiter' && this.Auth.id !== this.modalTable.id_created_by)
                {
                    this.modalTable = false;
                }
            }

            console.log('OnTransactionEdit');
            console.log(data);
            console.log(transaction);
            console.log(printOptions);

            if(printOptions === false || transaction.edit_type == 'BOLETA') return;

            //Imprimir voucher cocina si es mesa
            if(transaction.table_name && data.new)
            {
                if(this.view == 'TABLES'){
                    if(this.selectedDiner) transaction.value_diner = this.selectedDiner.value
                    this.modalTable = transaction;
                }

                if(Object.values(data.new).length)
                {
                    var dummy = this.Util.Copy(transaction);
                    if(dummy.custom_data.waiter) dummy.custom_data.created_by = dummy.custom_data.waiter.name;
                    dummy.orders = Object.values(data.new);
                    var moment = require('moment');
                    dummy.created_at = moment().unix();
                    if(!data.old || !data.old.length) dummy.edit_at = null;

                    if(!this.autoPrintTableOnEdit)
                    {
                        this.Util.PrintTransaction(!this.autoPrintTableNewOnly ? dummy : transaction, 'PENDING');
                    }
                    else
                    {
                        /*
                        this.Confirm({
                            text: 'Imprimir',
                            title: `¿Imprimir voucher cocina con los nuevos pedidos?`,
                            function: () => {
                                this.Util.PrintTransaction(dummy, 'PENDING');
                            }
                        });
                        */
                        this.modalPrintEdit = {newItems: dummy, allItems: transaction};
                    }
                    
                }
            }
            else
            {
                console.log('print non table');
                var dummy = this.Util.Copy(transaction);

                var oldItems = {};
                var newItems = {};
                var newOrder = [];
                oldTransaction.orders.forEach(item => {
                    var key = this.Util.OrderItemKey(item);
                    if(!oldItems[key]) oldItems[key] = item;
                    else oldItems[key].quantity += item.quantity;
                });
                dummy.orders.forEach(item => {
                    var key = this.Util.OrderItemKey(item);
                    if(!newItems[key]) newItems[key] = item;
                    else newItems[key].quantity += item.quantity;
                });

                for(var key in newItems)
                {
                    if(!oldItems[key])
                    {
                        newOrder.push(newItems[key]);
                    }
                    else if(newItems[key].quantity > oldItems[key].quantity)
                    {
                        newItems[key].quantity = newItems[key].quantity - oldItems[key].quantity;
                        newOrder.push(newItems[key]);
                    }
                }

                dummy.orders = newOrder;
                

                console.log(oldTransaction);
                console.log(transaction);
                let shouldPrintNewOnly = dummy.orders.length > 0;

                if(this.autoPrintOnEdit)
                {
                    console.log('> autoprint');
                    if(this.autoPrintNewOnly && !shouldPrintNewOnly) return;
                    this.Util.PrintTransaction(this.autoPrintNewOnly ? dummy : transaction, 'PENDING');
                }
                else
                {
                   console.log('> open modal');
                   this.modalPrintEdit = {newItems: dummy, allItems: transaction, shouldPrintNewOnly: shouldPrintNewOnly};
                }
                
            }

        },

        OrderItemsUpdated(action, item, index, transaction, quantity, table)
        {
            if(!this.canChangeOrderStatus) return;

            if(action == 'transfer' && !table)
            {
                return this.modalChangeTable = {
                    selected: transaction.table_name, 
                    previous: transaction.table_name, 
                    transfer: true,
                    transaction: transaction,
                    quantity: quantity,
                    item: item,
                    index: index,
                    action: action    
                };
            }

            var hasComment = ['remove', 'transfer'].indexOf(action) > -1;

            //if(this.Auth.role == 'waiter' && !this.canEditTables) return;
            if(item === 'ALL')
            {
                return this.Confirm({
                    text: 'Aceptar',
                    input: hasComment ? 'Comentario' : null,
                    title: this.UpdateItemActions[action].confirm(`todos los pedidos de ${transaction.table_name ? transaction.table_name : '#'+Util.Zeropad(transaction.id_ticket, 10)}`, table),
                    function: (comment) => {
                        this.modalChangeTable = null;
                        this.Loading();
                        axios.post(`/transaction/${transaction.id}/items/${action}`, {all: true, table: table, comment: comment}).then(res => {
                            this.$set(transaction, 'orders', res.data);
                            console.log(res);
                            this.modalUpdateItems = null;
                            this.Response(res);
                            this.modalItemsStatusUpdate = null;
                        }).catch(err => {
                            console.log(err);
                            this.Response(err);
                        });
                    }
                });
            }
            else if(item === 'PREPARED')
            {
                return this.Confirm({
                    text: 'Aceptar',
                    input: hasComment ? 'Comentario' : null,
                    title: this.UpdateItemActions[action].confirm(`los pedidos preparados de ${transaction.table_name ? transaction.table_name : '#'+Util.Zeropad(transaction.id_ticket, 10)}`, table),
                    function: (comment) => {
                        this.modalChangeTable = null;
                        this.Loading();
                        axios.post(`/transaction/${transaction.id}/items/${action}`, {prepared: true, table: table, comment: comment}).then(res => {
                            this.$set(transaction, 'orders', res.data);
                            console.log(res);
                            this.modalUpdateItems = null;
                            this.Response(res);
                            this.modalItemsStatusUpdate = null;
                        }).catch(err => {
                            console.log(err);
                            this.Response(err);
                        });
                    }
                });
            }
            else if(item === 'SELECTED')
            {
                if(!this.tableItemsSelection) return this.Info('No hay items seleccionados');
                var selectedItems = [];
                for(var key in this.tableItemsSelection)
                {
                    selectedItems.push({
                        id: this.tableItemsSelection[key],
                        index: parseInt(key)
                    });
                }
                if(!selectedItems.length) return this.Info('No hay items seleccionados');
                selectedItems.sort((a, b) => {return a.index - b.index});
                return this.Confirm({
                    text: 'Aceptar',
                    input: hasComment ? 'Comentario' : null,
                    title: this.UpdateItemActions[action].confirm(`${selectedItems.length} item(s) de ${transaction.table_name ? transaction.table_name : '#'+Util.Zeropad(transaction.id_ticket, 10)}`, table),
                    function: (comment) => {
                        this.modalChangeTable = null;
                        this.Loading();
                        axios.post(`/transaction/${transaction.id}/items/${action}`, {selected: selectedItems, table: table, comment: comment}).then(res => {
                            this.$set(transaction, 'orders', res.data);
                            console.log(res);
                            this.modalUpdateItems = null;
                            this.tableItemsSelection = null;
                            this.Response(res);
                        }).catch(err => {
                            console.log(err);
                            this.Response(err);
                        });
                    }
                });
            }

            if(item.quantity == 1) quantity = 1;
            else if(!this.Util.isInt(item.quantity)) quantity = item.quantity;

            if(quantity) 
            {
                if(this.Util.isInt(item.quantity))
                {
                    quantity = parseInt(quantity);
                    if(quantity < 1) return this.Info('Cantidad inválida');
                    if(quantity > item.quantity) quantity = item.quantity;
                }

                /*
                this.Options([
                    {text: 'Cancelar', function: () => {}},
                    {text: 'Aceptar', class: 'selected-color', function: () => {
                        this.modalChangeTable = null;
                        this.Loading();
                        axios.post(`/transaction/${transaction.id}/items/${action}`, {index: index, item: item.id, quantity: quantity, table: table}).then(res => {
                            this.$set(transaction, 'orders', res.data);
                            console.log(res);
                            this.modalUpdateItems = null;
                            this.Response(res);
                        }).catch(err => {
                            console.log(err);
                            this.Response(err);
                        });
                    }}
                ], null, this.UpdateItemActions[action].confirm(`(${quantity}) ${item.title}`, table));
                */
                this.Confirm({
                    text: 'Aceptar',
                    input: hasComment ? 'Comentario' : null,
                    title: this.UpdateItemActions[action].confirm(`(${quantity}) ${item.title}`, table),
                    function: (comment) => {
                        this.modalChangeTable = null;
                        this.Loading();
                        axios.post(`/transaction/${transaction.id}/items/${action}`, {index: index, item: item.id, quantity: quantity, table: table, comment: comment}).then(res => {
                            this.$set(transaction, 'orders', res.data);
                            console.log(res);
                            this.modalUpdateItems = null;
                            this.Response(res);
                        }).catch(err => {
                            console.log(err);
                            this.Response(err);
                        });
                    }
                });
            }
            else
            {
                this.modalUpdateItems = {
                    item: item,
                    quantity: item.quantity,
                    action: action,
                    index: index,
                    transaction: transaction,
                    table: table
                }
            }
        },
        TicketType(ticket)
        {
            //ticket iap
            if('id_transaction' in ticket)
            {
                if(ticket.type == 'DELIVERY') return 'Delivery';
                if(ticket.type == 'TAKEAWAY') return 'Retirar';
                if(ticket.type == 'SHIPMENT') return 'Encargo';
                if(ticket.type == 'UBER_DELIVERY') return 'Uber Delivery';
            }

            //if(ticket.iap) return ticket.type == 'DELIVERY' ? 'Delivery <i class="icon icon-corbatin"></i>' : 'Retirar <i class="icon icon-corbatin"></i>';
            if(ticket.custom_data.pedidosya_courier) return 'Delivery <i class="icon icon-pedidosya notif"></i>';
            // if(ticket.type == 'DELIVERY' && ticket.custom_data.is_shipment && (ticket.custom_data.delivery_time || ticket.custom_data.scheduled_at)) return 'Encargo <i class="icon icon-cargo"></i>';
            if(ticket.type == 'DELIVERY' && !ticket.custom_data.shipment) return 'Delivery <i class="icon icon-delivery"></i>';
            if(ticket.type == 'DELIVERY' && ticket.custom_data.shipment) return 'Encargo <i class="icon icon-cargo"></i>';
            if(ticket.type == 'TAKEAWAY' && ticket.custom_data.is_shipment_takeaway) return 'Retirar <i class="icon icon-chronometer"></i>';
            if(ticket.type == 'TAKEAWAY' && ticket.custom_data.activation_time) return 'Retirar <i class="icon icon-chronometer"></i>';
            if(ticket.type == 'TAKEAWAY' && !ticket.custom_data.activation_time) return 'Llevar <i class="icon icon-bag"></i>';
            if(ticket.type == 'SERVE' && !ticket.table_name) return 'Servir <i class="icon icon-serve"></i>';
            if(ticket.type == 'SERVE' && ticket.table_name) return ticket.table_name + ' <i class="icon icon-table"></i>';
        },
        
        /*
        Confirmar que un pedido para retirar a traves de la app esta listo
        @param ticket: la transacción para retirar
        */
        TakeawayReady(ticket)
        {
            this.Loading();
            axios.post(`/transaction/${ticket.id}/takeaway/ready`).then(res => {
                this.Response(res);
                this.UpdateTransaction(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },

        /*
        Abre el modal para editar pedidos y descarga los datos de ingreso de pedidos (medios de pago, menu, etc)
        */
        async OpenEdit(ticket)
        {
            let platform = localStorage.getItem('platform') || 'SCHEDULE';
            let isValidPlatform = this.platforms.some(p => p.value === platform);
            this.typeMenu = this.selectedPlatform = isValidPlatform ? platform : 'SCHEDULE';
            
            this.Loading();
            if(!this.saleData || this.updateMenu) {
                var data = (await axios.get(`/sale/${this.Local.id}`)).data;
                /*
                var deliveryCosts = [];
                data.settings.delivery_costs.forEach(e => {
                    deliveryCosts.push({text: (e.title ? e.title+': ' : '') + Util.Price(e.cost), value: e.cost});
                });
                data.settings.delivery_costs = deliveryCosts;
                */
                this.timestamp = data.timestamp;
                this.saleData = data;
            }

            if(!this.inventory || this.updateInventory)
            {
                this.inventory = (await axios.get(`/inventory/${this.Local.id}/current`)).data;
            }

            this.Response({});
            this.modalEdit = this.Util.Copy(ticket);
        },
        /*
        @method StartPreparation marca un pedido como preparandose
        @param ticket: la transacción para marcar como en preparación
        */
        StartPreparation(ticket)
        {
            this.Confirm({
                text: 'Preparando', 
                title: `¿Confirmar que el pedido #${Util.Zeropad(ticket.id_ticket, 10)} se ha empezado a preparar?`,
                class: 'primary purple', 
                function: () => {
                    this.Loading();
                    axios.post(`/transaction/${ticket.id}/preparation`).then(res => {
                        this.Response(res);
                        this.UpdateTransaction(res.data);
                    }).catch(err => {
                        this.Response(err);
                    });
                }}
            );
        },
        /*
        @method ElapsedTime formatea segundos en un display de tiempo
        @param seconds <Integer> los segundos
        @return <String> tiempo en formato (dias)d (horas)h (minutos)m
        */
        ElapsedTime(seconds)
        {
            if(seconds < 60) return '1m';
            var days = Math.floor(seconds / 86400);
            seconds -= (days * 86400);
            var hours = Math.floor(seconds / 3600);
            seconds -= (hours * 3600);
            var min = Math.floor(seconds / 60);
            //seconds -= (min * 60);
            return `${days ? days+'d ' : ''}${hours ? hours + 'h ' : ''}${min ? min + 'm' : ''}`;
        },
        /*
        @method ChangeFontSize cambia el tamaño de fuente para cada vista y guarda el valor en localstorage
        @param value <Integer> la cantidad en que se aumentara o disminuira
        */
        ChangeFontSize(value)
        {
            if(this.view == 'KITCHEN')
            {
                this.kitchenFontSize = Math.max(8, this.kitchenFontSize+value);
                localStorage.kitchenFontSize = this.kitchenFontSize;
            }
        },
        ToggleAttentionSound(key)
        {
            if(localStorage[key]) localStorage.removeItem(key);
            else localStorage.setItem(key, true);
            this[key] = !!localStorage[key];
        },
        //MESAS
        
        OpenTable(table) 
        {
            if(table.diners == 0) return this.Info('La cantidad mínima de comensales debe ser 1.');
            if(!table.diners) table.diners = 1;

            let data = {
                table: table.name,
                diners : table.diners,
            }
            
            let newInputs = {};
            for (let i = 1; i <= table.diners; i++) {
                let key = `C${i}`;
                let customer = table.customers ? table.customers[i - 1] : ""; // Asigna el cliente correspondiente o una cadena vacía.
                if (customer === undefined) customer = "";

                // Asigna el valor correcto al nuevo objeto
                newInputs[key] = customer === "" ? key : customer;
            }
            data.customers = newInputs

            this.Loading();
            axios.post(`/tables/${this.Local.id}/open`, data).then(res => {
                this.Response(res);
                this.modalTableReservation = null;
                this.modalOpenTable = null;
                this.modalTable = res.data;
                if(res.data.custom_data['diners_names']){
                    this.dictDiners = res.data.custom_data['diners_names']
                }


                //abrir modal de agregar items si la mesa esta vacia
                if(this.canEditTables && !this.modalTable.orders.length && !table.split_table)
                    this.$refs.transactionDetails.OpenItems(this.modalTable);

            }).catch(err => {
                this.Response(err);
            });
        },
        KitchenTimesDelays(ticket, order){
            let color = ''

            if(this.dictItems[order.id])
            {
                // Se asign el color de la seccion
                if (!!this.dictItems[order.id].section_color) {
                    return this.dictItems[order.id].section_color;
                }

                // Se asigna el color del item
                if (!!this.dictItems[order.id].color) {
                    return this.dictItems[order.id].color;
                }
            }

            //Si tiempos estan activos
            if (this.settings.active_kitchen_delay) {
                var initial_time = order.added_at ? order.added_at : ticket.created_at;

                // Si atrasado es 0 o no es numero
                if (this.settings.kitchen_late_time == 0 || isNaN(this.settings.kitchen_late_time)) {
                    //Tiempo es mayor o igual es rojo
                    if (this.timer >= initial_time + (this.settings.kitchen_very_late_time * 60)) {
                        return 'var(--red) !important';
                    }
                    //Si muy atrasado es 0 o no es un numero
                } else if (this.settings.kitchen_very_late_time == 0 || isNaN(this.settings.kitchen_very_late_time)) {
                    //Timpo mayor o igual a atrsado es amarillo
                    if (this.timer >= initial_time + (this.settings.kitchen_late_time * 60)) {
                        return 'var(--yellow) !important';
                    }
                } else {
                    //Si tiempos de atrasado y muy atrasado son validos
                    //Tiempo entre atrasado y muy atrasado es amarillo
                    if (this.timer >= initial_time + (this.settings.kitchen_late_time * 60) && this.timer < initial_time + (this.settings.kitchen_very_late_time * 60)) {
                        return 'var(--yellow) !important';
                    }
                    //Timpo mayor o igual a muy atrasado es rojo
                    if (this.timer >= initial_time + (this.settings.kitchen_very_late_time * 60)) {
                        return 'var(--red) !important';
                    }
                }
            }

            return color;
        },
        TableReservation()
        {
            this.Confirm({
                text: 'Reservar',
                title: `¿Reservar ${this.modalTableReservation.name}?`,
                function: () => {
                    this.Loading();
                    axios.post(`/tables/${this.Local.id}/reservation`, this.modalTableReservation).then(res => {
                        this.Response(res);
                        this.modalTableReservation = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
            
        },
        DeleteReservation(table)
        {
            this.Confirm({
                text: 'Eliminar Reserva',
                class: 'error-color',
                title: `¿Eliminar reserva de ${table.name}?`,
                function: () => {
                    this.Loading();
                    axios.post(`/tables/${this.Local.id}/unreserve`, {table: table.name}).then(res => {
                        this.Response(res);
                        this.modalTableReservation = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
        },
        OnTableItemsOrderChanged(event, table){
            if(!event) return;
            let eventData = null;

            if(event.moved && table){
                eventData = {
                    event: 'ITEM_MOVED', 
                    typeOrder: this.sortByItem,
                    from: event.moved.oldIndex, 
                    to: event.moved.newIndex, 
                    transaction: table,
                    title: event.moved.element.title,
                    item: event.moved.element
                };
            }

            if(eventData){
                eventData.idTransaction = table.id;
                axios.patch(`/table/transaction/items/${this.Local.id}/reorder`, eventData).then(res => {
                    this.modalTable = res.data.transaction;
                    this.Update();
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        async OpenCloseTable(transaction) {
            var items = {};
            this.showBtnDiner = true;
            this.newTotalMoney = 0;
            let isDiner = this.modalTable && this.modalTable.selected_diner && this.modalTable.selected_diner.value != 'ALL'; // TRUE cuando es un comensal, FALSE cuando se selecciona "pedido" (ALL)
            // Construcción inicial de los items
            for (var i = 0; i < transaction.orders.length; i++) {
                items[i] = transaction.orders[i];
            }

            // Filtra los items asignados por comensal
            if (isDiner) {
                items = Object.fromEntries(
                    Object.entries(items).filter(
                        ([key, item]) =>
                            item.diner == this.modalTable.selected_diner.value &&
                            (!item.paid && !item.paid_at)
                    )
                );
            }

            if (!this.saleData) {
                let platform = localStorage.getItem('platform') || 'SCHEDULE';
                let isValidPlatform = this.platforms.some(p => p.value === platform);
                this.typeMenu = this.selectedPlatform = isValidPlatform ? platform : 'SCHEDULE';

                this.Loading();
                this.saleData = (await axios.get(`/sale/${this.Local.id}`)).data;
                this.Response({ error: false });
            }

            // Configuración de la tabla modal
            this.modalCloseTable = transaction;

            this.$nextTick(() => {
                this.tableItems = items;              
                let tips = [];
                this.$refs.order.dictDiners = this.dictDiners;

                if (transaction.custom_data && transaction.custom_data.tips) {
                    tips = this.Util.Copy(transaction.custom_data.tips);
                }
                let payments = this.Util.Copy(transaction.payment);

                // Ordena los pagos de menor a mayor por paid_at
                // Se ordenan para cuando se le asigna el index a la propina por defecto, para que el ultimo pago se asigne a la propina
                payments.sort((a, b) => {
                    if (a.paid_at === undefined) return 1; // Mover elementos sin paid_at al final
                    if (b.paid_at === undefined) return -1; // Mover elementos con paid_at hacia adelante
                    return a.paid_at - b.paid_at; // Ordenar de menor a mayor
                });
                let copyOfPayments = payments;

                let indexDinerPartialPayments = [];
                let dinerPartialPayments = [];
                if (isDiner) {
                    copyOfPayments.filter((pay, index) => {
                        // Los pagos que se han realizado por pago parcial del comensal. No cuando se cierra la cuenta
                        if (pay.paid_at && !pay.item_paid && pay.diner == this.modalTable.selected_diner.value) {
                            dinerPartialPayments.push(pay);
                            indexDinerPartialPayments.push(index);
                        }
                    });
                }
                // Configuración de propinas por defecto

                // Total no pago del comensal
                let totalDinerNotPaid = 0;
                // Calcular el total no pagado
                //Si es por comensal, verifica los pagados y el restante es propina del ultimo pago
                Object.keys(items).forEach(key => {
                    if (!items[key].paid_at) {
                        totalDinerNotPaid += items[key].total_money;
                    }
                });

                // Cuando esta activada la opcion de propina por defecto 10%
                if (this.settings.default_tip_table) {

                    //Cuando es seleccionado algun comensal. NO "PEDIDO"
                    if (isDiner) {
                        // Si no hay pagos parciales de comensal, la propina se inicializa vacio
                        if(!dinerPartialPayments.length) tips = [];
                        // Se inicializa vacio
                        payments = [];
                    } 
                    else {
                        //Cuando esta seleccionado PEDIDO
                        // Si no es por comensale, verifica los pagaods realizados

                        let total_paid = 0;
                        total_paid =  payments.reduce((sum, pay) => {
                            return pay.paid_at ? sum + pay.subtotal : sum;
                        }, 0)

                        totalDinerNotPaid = transaction.total_money - total_paid;
                    }

                    // Agregar propina calculada
                    // Si total propina es distinto a 0 Y dinerPartialPayments NO esta VACIO (no tiene pago parcial de comensal)
                    if(totalDinerNotPaid != 0 && !dinerPartialPayments.length) {
                        tips.push({
                            index: copyOfPayments.length == 0 ? 0 : copyOfPayments.length - 1,
                            method: '',
                            total: totalDinerNotPaid * (10 / 100),
                            percentage: '10%',
                        });
                    }

                    this.$refs.order.paymentMethods = payments;
                }
                else {
                    // CUANDO LA PROPINA NO ESTA POR DEFECTO 

                    //Si se selecciona un comensal, se envia vacio.
                    //Si se selecciona "pedido" (ALL), se envia los pagos que tenga. (Puede ser el inicial o los pagos que se hayan realizado anteriormente)
                    this.$refs.order.paymentMethods = isDiner ? [] : payments;
                    if (isDiner) tips = []; 
                }
                
                if (isDiner) {
                    this.$refs.order.hasDinerSelected = true;
                    let totalDinerPartialPayments = dinerPartialPayments.reduce((sum, fil) => sum + fil.subtotal, 0);
                    let partialPaymentsNotDiner = copyOfPayments.filter(pay => !pay.diner && pay.paid_at);

                    // Si hay pagos parciales de comensal. NO CIERRES DE CUENTA DEL COMENSAL
                    if(dinerPartialPayments.length>0){
                        
                        if(totalDinerNotPaid - totalDinerPartialPayments < 0){
                            // Puede ser que el comensal haya pagado un mayor monto en pago parcial de lo que el tiene en sus productos

                            this.showBtnDiner = false;
                            dinerPartialPayments = [{}];
                            tips = []
                        }else if(totalDinerNotPaid - totalDinerPartialPayments > 0) {
                            // Se muestran los items no pagados. Se muestran los pagos parciales del comensal y las propinas correspondientes 
                            //Es mayor a 0

                            let newArray = [];
                            
                            if(indexDinerPartialPayments.length) tips = tips.filter(item => indexDinerPartialPayments.includes(item.index));
                            
                            // Se crea un nuevo array 
                            for (let index = 0; index < copyOfPayments.length; index++) {
                                indexDinerPartialPayments.includes(index) ? newArray.push(copyOfPayments[index]) : newArray.push({});
                            }

                            dinerPartialPayments = newArray;
                            dinerPartialPayments.push({'method' : 'CASH', 'subtotal' : totalDinerNotPaid - totalDinerPartialPayments});
                            if(this.settings.default_tip_table){
                                tips.push({
                                    index: dinerPartialPayments.length-1,
                                    method: 'CASH',
                                    total: (totalDinerNotPaid - totalDinerPartialPayments) * (10 / 100),
                                    percentage: '10%',
                                });
                            }

                            this.newTotalMoney = totalDinerNotPaid - totalDinerPartialPayments;
                        }
                        this.$refs.order.paymentMethods = dinerPartialPayments;
                    }
                }
                this.$refs.order.tips = tips;
                this.$refs.order.sale = 'SERVE';
            });
        },

        CloseTable()
        {
            
            if(this.modalTable && this.modalTable.selected_diner && this.modalTable.selected_diner.value != 'ALL'){
                // Limpia el array de los objetos vacios
                let nonEmptyObjects = this.$refs.order.paymentMethods.filter(item => 
                    typeof item === 'object' && 
                    item !== null && 
                    Object.keys(item).length > 0
                );
                this.$refs.order.paymentMethods = nonEmptyObjects
            }
            var order = this.$refs.order.GetOrder();

            if(!order) return;
            var transaction = this.$refs.order.transaction;
            var total = this.$refs.order.total;
            var discounts = this.$refs.order.discounts;
            var customer = this.$refs.order.customer;
            var user = this.$refs.order.customerAccount;

            if(order.payment && order.payment.some(method => method.method == 'NO_SELECT'))
                return this.Info(`Debe seleccionar un método de pago.`);
            
            let title_confirm = '';
            if(this.modalTable && this.modalTable.selected_diner && this.modalTable.selected_diner.value != 'ALL') title_confirm = `¿Cerrar cuenta de ${this.modalTable.selected_diner.text}?`
            else if (!this.modalTable || (this.modalTable && !this.modalTable.selected_diner) || (this.modalTable && this.modalTable.selected_diner && this.modalTable.selected_diner.value === 'ALL'))
 title_confirm = `¿Cerrar ${this.modalCloseTable.table_name}?`
            this.Confirm({
                title: title_confirm,
                text: 'Cerrar', 
                function: () => {
                    this.Loading();
                    if(!this.modalTable || (this.modalTable && !this.modalTable.selected_diner) || (this.modalTable && this.modalTable.selected_diner && this.modalTable.selected_diner.value === 'ALL')){
                        axios.post(`/tables/${this.Local.id}/close`, {
                            table: this.modalCloseTable.table_name,
                            categories: transaction.categories,
                            discounts: transaction.discounts,
                            orders: transaction.orders,
                            payment: transaction.payment,
                            pending_payment: transaction.pending_payment,
                            total_money: order.total_money,
                            total_discount: discounts.totalDiscount,
                            customer: customer,
                            user: user ? user.id : null,
                            coupons: discounts.coupons,
                            tips: order.tips,
                            diner: this.modalTable && this.modalTable.selected_diner ? this.modalTable.selected_diner : null
                        }).then(res => {
                            this.Response(res);
                            this.modalCloseTable = null;
                            this.modalTable = null
                            this.TransactionSuccess(res.data);
                        }).catch(err => {
                            this.Response(err);
                        });
                    }else if(this.modalTable && this.modalTable.selected_diner){
                        // Cuando hago para para 1 comensal
                        axios.post(`/tables/${this.Local.id}/close/diner`, {
                            table: this.modalCloseTable.table_name,
                            payment_methods : transaction.payment,
                            orders: transaction.orders,
                            tips: this.newTotalMoney == 0 ? order.tips : [order.tips.pop()],
                            total_money: this.newTotalMoney == 0 ? order.total_money : this.newTotalMoney,
                            diner: this.modalTable && this.modalTable.selected_diner ? this.modalTable.selected_diner : null,
                        }).then(res => {
                            this.Response(res);
                            let r_transaction = res.data
                            this.modalCloseTable= null;
                            this.modalTable.orders = r_transaction.orders;
                            this.modalTable.payment = r_transaction.payment;
                            this.modalTable.custom_data = r_transaction.custom_data;
                            
                            r_transaction.orders = transaction.orders
                            r_transaction.total_money = order.total_money
                            this.Info(`Pago realizado de ${this.modalTable.selected_diner.text}`)
                            // this.TransactionSuccess(r_transaction);
                        }).catch(err => {
                            this.Response(err);
                        });
                    }


                }
            });
        },
        TransactionSuccess(transaction) 
        {
            this.$refs.transactionDetails.OpenTicket(transaction);
        },
        PrintTableSummary(data, pending = false)
        {
            let transaction = this.Util.Copy(data);
            if(transaction && transaction.selected_diner && transaction.selected_diner.value != 'ALL'){
                let filterByDiner = transaction.orders.filter(order => order.diner === transaction.value_diner)
                transaction.orders = filterByDiner
                transaction.total_money = this.TotalDiner(filterByDiner)
            }

            if(pending){
                let filterPaid = transaction.orders.filter(order => !order.paid_at );
                transaction.orders = filterPaid;
                transaction.total_money = this.TotalDiner(filterPaid);
                transaction.payment = [{method: 'CASH', subtotal: 0}];
            }

            if(this.$refs.order)
            {
                // transaction = this.Util.Copy(transaction);
                var order = this.$refs.order.GetOrder();
                if(!order) return;
                transaction.customer = order.customer;
                transaction.orders = order.orders;
                transaction.payment = order.payment; 
                transaction.discounts = order.discounts;
                transaction.custom_data.tips = order.tips;
                transaction.total_money = this.$refs.order.totalMoney;
                transaction.total_discount = order.total_discount;
            }
            this.Util.NotifyService('reprint', {data: transaction, id: Math.random().toString(), types: 'PRE'});
        },

        SaveNTransfer(ticket, n, index){
            this.Loading();
            axios.post(`/transaction/${ticket.id}/ntransfer`, {n_transfer: n, index_payment: index}).then(res => {
                this.Response(res);
                // Encuentra directamente el ticket correspondiente en visibleTickets
                let ticket_pay = this.visibleTickets.find(t => t.id === ticket.id);
                if (ticket_pay) {
                    // Usa this.$set para asegurarte que Vue reacciona al cambio
                    this.$set(ticket_pay.payment, index, {
                        ...ticket_pay.payment[index],
                        n_transfer: n
                    });
                }
                // this.UpdateTransaction(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveNTransferScheduled(ticket, n, indexPay){
            this.Loading();
            axios.post(`/transaction/scheduled/${ticket.id}/ntransfer`, {n_transfer: n, index : indexPay}).then(res => {
                this.Response(res);

                let ticket_pay = this.iapViewTickets.find(t => t.id === ticket.id);
                if (ticket_pay) {
                    this.$set(ticket_pay.custom_data.payment[indexPay], 'n_transfer', n);
                }
                // this.UpdateTransaction(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        //marcar ticket cmo pagado
        Paid(ticket)
        {
            this.Loading();
            axios.post(`/transaction/${ticket.id}/paid`).then(res => {
                this.Response(res);
                this.UpdateTransaction(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },

        TransactionActions(ticket, type)
        {
            var ret = [];
            if(this.Auth.role == 'admin' && ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.status !== 'COMPLETED' && (!type || type == 'secondary'))
                ret.push({text: 'PYA Forzar Entregado', class: 'csupport', icon: 'lifebuoy', function: () => {this.SupportPYAForceComplete(ticket)}});

            if(ticket.status !== 'CANCELLED' && (!ticket.custom_data.pedidosya_courier || ticket.custom_data.pedidosya_courier.status == 'COMPLETED') && this.canCancel && (!type || type == 'secondary'))
                ret.push({text: 'Cancelar Pedido', class: 'error-color', function: () => {this.$refs.transactionDetails.OpenCancel(ticket)}});

            if(ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.status == 'CONFIRMED' && (!type || type == 'secondary'))
                ret.push({text: 'Cancelar Envío PedidosYa Courier', class: 'error-color', function: () => {this.modalCancelPyaCourier = {id: ticket.custom_data.pedidosya_courier.id, reason: ''}}});
            
            if(!type || type == 'secondary')
                ret.push({text: 'Ver Detalles', function: () => {this.$refs.transactionDetails.Open(ticket)}});
                
            if((!type || type == 'primary') && (this.isAdmin || (this.Auth.restrictions && this.Auth.restrictions.can_reprint))){
                ret.push({text: 'Imprimir', function: () => {this.$refs.transactionDetails.VoucherOptions(ticket)}});
            }

            if((!type || type == 'secondary') && ticket.status !== 'CANCELLED')
            {
                var opt = this.$refs.transactionDetails.VirtualVoucherOption(ticket);
                if(opt) ret.push(opt);
            }
            
            if((!type || type == 'secondary') && ticket.status !== 'CANCELLED' && !ticket.custom_data.factura)
            {
                var opt = this.$refs.transactionDetails.BoletaOption(ticket);
                if(opt) ret.push(opt);
            }

            if((!type || type == 'secondary') && ticket.status !== 'CANCELLED' && !ticket.custom_data.boleta && (this.Auth.role.indexOf('admin') > -1 || this.Auth.role == 'cashier' && this.Auth.restrictions.can_create_facturas))
            {
                var opt = this.$refs.transactionDetails.FacturaOption(ticket);
                if(opt) ret.push(opt);
            }

            if(!ticket.paid_at && (!type || type == 'secondary') && ticket.status !== 'CANCELLED')
            {
                ret.push({
                    text: 'Marcar como pagado',
                    function: () => {this.Paid(ticket)},
                    confirm: '¿Marcar pedido como pagado?'
                })
            }
            
            if(ticket.status !== 'CANCELLED' && !ticket.custom_data.pedidosya_courier && !ticket.custom_data.integration_pya && this.canChangeOrderStatus && (!type || type == 'secondary'))
            {
                ret.push({
                    text: 'Marcar como entregado', 
                    function: () => {this.modalItemsStatusUpdate = {status: 'delivered', selection: 'ALL', transaction: ticket}}
                    //function: () => {this.OrderItemsUpdated('delivered', 'ALL', null, ticket)}
                });

            }

            if(ticket.table_name && this.canEditTables && (!type || type == 'primary') && ticket.status !== 'CANCELLED')
                ret.push({text: 'Editar Pedido', function: () => {this.$refs.transactionDetails.OpenItems(ticket)}});

            var canEditOnline = this.Auth.role.indexOf('admin') > -1 || (!!this.Auth.restrictions.edit_transactions_payment_online && !!ticket.payment.find(t => t.method === 'ONLINE'))

            if(ticket.status !== 'CANCELLED' && this.canEdit && !ticket.table_name && !ticket.custom_data.pedidosya_courier && ticket.source.indexOf('INFLUYE') > -1 && (!type || type == 'secondary') && (canEditOnline || !ticket.payment.find(t => t.method === 'ONLINE'))) 
             
                ret.push({text: 'Editar Pedido', function: () => {this.OpenEdit(ticket)}});

            if(ticket.status == 'DELIVERY' && (!type || type == 'secondary') && !ticket.custom_data.pedidosya_courier)
            {
                ret.push({text: 'Cambiar Repartidor', function: () => {this.$refs.transactionDetails.OpenDelivery(ticket)}});
                if(ticket.custom_data.delivery_phone)
                    ret.push({text: 'Abrir Repartidor en Whatsapp', function: () => {this.Util.OpenWhatsapp(ticket.custom_data.delivery_phone, '')}});
            }

            if(this.canChangeOrderStatus && ticket.status !== 'CANCELLED' && (!this.settings.preparation_step || ticket.custom_data.preparation_at) && ticket.type == 'TAKEAWAY' && !ticket.custom_data.integration_pya && (ticket.custom_data.activation_time || ticket.custom_data.is_shipment_takeaway) && !ticket.custom_data.takeaway_ready && (!type || type == 'primary'))
                ret.push({text: 'Pedido Listo', class: 'selected-color', function: () => {this.TakeawayReady(ticket)}, confirm: `¿Confirmar que el pedido #${this.Util.Zeropad(ticket.id_ticket, 10)} está listo para ser retirado? Se le notificará al cliente.`});

            if(this.canComplete && !ticket.custom_data.pedidosya_courier && (!this.settings.preparation_step || ticket.custom_data.preparation_at) && ((!ticket.custom_data.is_shipment_takeaway && !ticket.custom_data.activation_time) || ticket.custom_data.takeaway_ready) && ((ticket.status == 'PENDING' && ticket.type != 'DELIVERY') || ticket.status == 'DELIVERY' || ticket.source == 'UBEREATS') && !ticket.table_name && (!type || type == 'primary'))
                ret.push({text: 'Entregar Pedido', class: 'selected-color', function: () => {this.$refs.transactionDetails.OpenComplete(ticket, ticket.type === 'DELIVERY')}});

            if(this.canChangeOrderStatus && ticket.status !== 'CANCELLED' && (!this.settings.preparation_step || ticket.custom_data.preparation_at) && !ticket.custom_data.delivery_ready && ticket.custom_data.integration_pya && ticket.custom_data.integration_pya.preparedUrl && (!type || type == 'primary'))
                ret.push({text: 'Pedido Listo', class: 'selected-color', function: () => {this.TakeawayReady(ticket)}, confirm: `¿Confirmar que el pedido #${this.Util.Zeropad(ticket.id_ticket, 10)} está listo para ser ${ticket.type === 'DELIVERY'? 'enviado' : 'retirado'}? Se le notificará al ${ticket.type === 'DELIVERY'? 'repartidor' : 'cliente'}.`});

            // integration_pya
            if(ticket.custom_data && ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.status == 'COMPLETED')
                ret.push({text: 'Completar Pedido', class: 'selected-color', function: () => { this.$refs.transactionDetails.CompleteTransaction(ticket) }, confirm: 'El pedido ya ha sido entregado por PedidosYa ¿Completar pedido?'});

            if(ticket.custom_data && ticket.custom_data.integration_pya && ticket.custom_data.integration_pya.status == 'order_prepared')
                ret.push({text: 'Completar Pedido', class: 'selected-color', function: () => { this.$refs.transactionDetails.CompleteTransaction(ticket) }, confirm: `El pedido ya ha sido entregado ${ticket.type === 'DELIVERY'? 'por PedidosYa' : ''} ¿Completar pedido?`});

            if(this.canChangeOrderStatus && ticket.status !== 'CANCELLED' && this.settings.preparation_step && !ticket.custom_data.preparation_at && (!type || type == 'primary'))
                ret.push({text: 'Preparar Pedido', class: 'selected-color', function: () => {this.StartPreparation(ticket)}});

            if(this.canComplete && ticket.type == 'SERVE' && ticket.table_name && ticket.status == 'PENDING' && ticket.orders.length && (!type || type == 'primary'))
                ret.push({text: 'Cerrar Mesa', class: 'selected-color', function: () => {this.OpenCloseTable(ticket)}});

            // || (ticket.custom_data.integration_pya && ticket.custom_data.integration_pya.type == 'VendorDelivery')
            if(this.canSend && ticket.status == 'PENDING' && ticket.type == 'DELIVERY' && !ticket.custom_data.pedidosya_courier && (!ticket.custom_data.integration_pya) && (!this.settings.preparation_step || ticket.custom_data.preparation_at) && ticket.source !== 'UBEREATS' && (!type || type == 'primary'))
                ret.push({text: 'Enviar Pedido', class: 'selected-color', function: () => {this.$refs.transactionDetails.OpenDelivery(ticket)}});
            
            return ret;

        },

        ClickOnTable(table)
        {
            this.sortByItem = null;
            this.dictDiners = {};
            this.selectedDiner = null
            if(table.id_transaction) 
            {
                var transaction = this.GetTableTransaction(table.name);
                if(transaction)
                {
                    this.modalTable = transaction;
                    this.dictDiners = this.modalTable.custom_data['diners_names'] ? this.modalTable.custom_data['diners_names'] : {}
                    if(this.modalTable.value_diner) this.modalTable.value_diner = 'ALL'
                    
                    //abrir modal de agregar items si la mesa esta vacia
                    if(this.canEditTables && !this.modalTable.orders.length && !this.dictDiners)
                        this.$refs.transactionDetails.OpenItems(this.modalTable);
                }
                else
                {
                    return this.Info('No se encontró el pedido asociado a la mesa');
                }
            }
            else if(table.parent_table)
            {
                var parentTable = this.tables[table.parent_table];
                if(!parentTable) return this.Info(`La mesa ${table.parent_table} no existe`)
                this.ClickOnTable(parentTable);
                /*
                var transaction = this.GetTableTransaction(table.parent_table);
                if(transaction)
                {
                    this.modalTable = this.GetTableTransaction(transaction);
                    //abrir modal de agregar items si la mesa esta vacia
                    if(this.canEditTables && !this.modalTable.orders.length)
                        this.$refs.transactionDetails.OpenItems(this.modalTable);
                }
                else
                {

                }
                */
            }
            else if(table.reservation_data)
            {
                this.modalTableReservation = this.Util.Copy(table);
            }
            else if(table.available)
            {
                this.Options([
                    {text: 'Reservar Mesa', function: () => {
                        var t = this.Util.Copy(table);
                        t.reservation_data = {};
                        this.modalTableReservation = t;
                    }},
                    {text: 'Unir a otra Mesa', function: () => {this.modalJoinTable={source: table.name, target: null}}},
                    {text: 'Abrir Mesa', class: 'selected-color', function: () => { this.modalOpenTable = table }, if: this.canEditTables},
                ])
            }
        },

        GetTableTransaction(tableName) {
            for(var i = 0; i < this.tickets.length; i++)
            {
                if(this.tickets[i].table_name == tableName)
                    return this.tickets[i];
            }
            return null;
        },

        ChangeType(transaction, type)
        {
            this.Confirm({
                text: 'Cambiar',
                title: '¿Cambiar tipo de pedido?',
                input: 'Comentario',
                function: (comment) => {
                    this.Loading();
                    axios.patch(`/transaction/${transaction.id}/order/type`, {type: type, edit_comment: comment}).then(res => {
                        this.Response(res);
                        this.OnTransactionEdit(res.data);
                        this.modalTable = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            })
        },

        async OpenChangeWaiter()
        {
            if(!this.waiterAccounts)
            {
                this.Loading();
                this.waiterAccounts = (await axios.get(`/accounts/${this.Local.id}/waiter`)).data;
                this.Response({error: false});
            }

            if(!this.waiterAccounts.length) return this.Info('No hay cuentas de mesero');

            this.modalChangeWaiter = {
                selected: this.modalTable.id_created_by,
                previous: this.modalTable.id_created_by
            };
        },

        ChangeWaiter()
        {
            this.Loading();
            axios.patch(`/transaction/${this.modalTable.id}/waiter/change`, this.modalChangeWaiter).then(res => {
                this.OnTransactionEdit(res.data, false);
                this.modalChangeWaiter = null;
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            })
        },

        ChangeTable()
        {
            this.Loading();
            axios.patch(`/transaction/${this.modalTable.id}/table/change`, this.modalChangeTable).then(res => {
                this.OnTransactionEdit(res.data, false);
                this.modalChangeTable = null;
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            })
        },

        CancelMultiple(data)
        {
            if(!data)
            {
                var ids = [];
                this.visibleTickets.forEach(ticket => {
                    if(ticket.status !== 'CANCELLED') ids.push(ticket.id);
                });
                return this.modalCancelMultiple = {transactions: ids, reason: 'Cancelación masiva de pedidos', reduce_inventory: false};
            }

            this.Loading();
            axios.post(`/transactions/${this.Local.id}/multiple/cancel`, data).then(res => {
                this.modalCancelMultiple = null;
                this.Info(`Se han cancelado ${res.data.count} transacciones`);
            }).catch(err => {
                this.Response(err);
            });
        },

        CompleteMultiple() 
        {
            var ids = [];
            this.visibleTickets.forEach(ticket => {
                if(((ticket.status == 'PENDING' && ticket.type != 'DELIVERY') || ticket.status == 'DELIVERY') && !ticket.table_name)
                    ids.push(ticket.id);
            });

            this.Loading();
            axios.post(`/transactions/${this.Local.id}/multiple/complete`, {transactions: ids}).then(res => {
                this.Info(`Se han completado ${ids.length} transacciones`);
            }).catch(err => {
                this.Response(err);
            });

        },

        //ALL, PAID, UNPAID, DELIVERED, UNDELIVERED, LAST
        SelectTableItems(filter)
        {
            if(!this.modalTable) return;
            if(!this.tableItemsSelection) this.tableItemsSelection = {};
            var orders = this.orderedItems;
            if(!orders.length) return;
            var last = orders[0].added_at;
            console.log(orders);
            for(var i = 0; i < orders.length; i++)
            {
                var item = orders[i];
                if(filter == 'ALL' || filter == 'PAID' && item.paid_at || filter == 'UNPAID' && !item.paid_at || filter == 'DELIVERED' && item.delivered || filter == 'UNDELIVERED' && !item.delivered || filter == 'LAST' && item.added_at == last)
                    this.$set(this.tableItemsSelection, item.index, item.id);
            }
        },

        ItemTag(item)
        {
            if(item.paid_at) return 'paid';
            if(item.delivered) return 'ready';
            if(item.prepared || item.autocomplete) return 'attention';
            return '';
        },

        OpenPartialPayment()
        {
            if(!this.modalTable) return;

            var totalPaid = 0;

            this.modalTable.payment.forEach(payment => {
                if(payment.paid_at) totalPaid += payment.subtotal;
            });

            var totalTips = [];
            totalTips.push({text: `Sin propina`, value: 'NO_TIP'});
            totalTips.push({text: `Total Pago Parcial`, value: 'AUTO'});
            if(this.modalTable.total_discount)
            {
                totalTips.push({text: `Subtotal Pedido: ${this.Util.Price(this.modalTable.total_money)}`, value: this.modalTable.total_money});
                totalTips.push({text: `Total Pedido: ${this.Util.Price(this.modalTable.total_money - this.modalTable.total_discount)}`, value: this.modalTable.total_money - this.modalTable.total_discount});
            }
            else
                totalTips.push({text: `Total Pedido: ${this.Util.Price(this.modalTable.total_money)}`, value: this.modalTable.total_money});
            
            var subtotal = this.modalTable.total_money - totalPaid;
            if(this.tableItemsSelection)
            {
                var totalSelected = 0;
                for(var key in this.tableItemsSelection)
                {
                    totalSelected += this.modalTable.orders[key].total_money;
                }
                subtotal = totalSelected;
            }

            this.modalPartialPayment = {
                total: this.modalTable.total_money,
                totalPaid: totalPaid,
                totalTips: totalTips,
                subtotal: subtotal,
                tip: '10%',
                tipTotal: 'AUTO'
            };
        },

        RegisterPartialPayment()
        {
            if(!this.modalTable || !this.modalPartialPayment) return;
            if(!this.partialPaymentTotal > this.modalPartialPayment.total) return this.Info('El pago parcial no puede ser mayor al total');
            if(!this.partialPaymentTotal > this.modalPartialPayment.total - this.modalPartialPayment.totalPaid) return this.Info('El pago parcial no puede ser mayor a lo que resta por pagar');
            
            var cash = null;
            if(this.modalPartialPayment.method == 'CASH' && this.modalPartialPayment.cash && this.modalPartialPayment.cash > this.partialPaymentTotal + this.partialPaymentTip) cash = parseInt(this.modalPartialPayment.cash);

            var data = {
                total: this.partialPaymentTotal,
                tip: this.partialPaymentTip,
                method: this.modalPartialPayment.method,
                selected: this.tableItemsSelection,
                cash: cash
            };
            if(this.modalPartialPayment.valueDiner) data.diner = this.modalPartialPayment.valueDiner
            this.Loading();
            axios.post(`/transaction/${this.modalTable.id}/partial/payment`, data).then(res => {
                this.Response(res);
                this.modalPartialPayment = null;
                this.tableItemsSelection = null;
            }).catch(err => {
                this.Response(err);
            });
        },

        ToggleAutoPrintOnEdit()
        {
            if(localStorage.autoPrintOnEdit) localStorage.removeItem('autoPrintOnEdit');
            else localStorage.setItem('autoPrintOnEdit', 'true');
            this.autoPrintOnEdit = !!localStorage.autoPrintOnEdit;
        },

        JoinTable()
        {
            this.Confirm({
                text: 'Unir mesas',
                title: `Se unirá ${this.modalJoinTable.source} con ${this.modalJoinTable.target}. En caso de que hayan mesas abiertas se unirán los pedidos. En caso de que la mesa a unir tenga un reserva se perderá esta información. ¿Desea continuar?`,
                function: () => {
                    this.Loading();
                    axios.post(`/tables/${this.Local.id}/join`, this.modalJoinTable).then(res => {
                        this.modalJoinTable = null;
                        this.modalTable = null;
                        this.Response(res);
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
            
        },

        DisjoinTable(table)
        {
            this.Loading();
            axios.post(`/tables/${this.Local.id}/disjoin`, {
                name: table.child.name,
                parent_table: table.child.parent_table,
                parentDiners: table.parentDiners,
            }).then(({data}) => {
                this.Response(data);
                this.modalDisjoinTable = null;
                return this.Info(data.msg);
            }).catch(err => {
                this.Response(err);
            });
        },
        CancelPyaCourier()
        {
            //if(!ticket.custom_data.pedidosya_courier || !ticket.custom_data.pedidosya_courier.status == 'CONFIRMED') return this.Info('No se puede cancelar la orden de envío de este pedido');
            if(!this.modalCancelPyaCourier.reason) return this.Info('Motivo requerido');
            this.Confirm({
                title: '¿Cancelar orden de envío de PedidosYa Courier?',
                text: 'Cancelar',
                class: 'error-color',
                function: () => {
                    this.Loading();
                    axios.post(`/pya/order/${this.Local.id}/cancel`, this.modalCancelPyaCourier).then(res => {
                        this.Info('Orden de envío cancelada exitosamente');
                        this.modalCancelPyaCourier = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
        },
        ChangeCustomersNumber(table){
            if(!table.customers_number || table.customers_number == 0) return this.Info('Debe añadir una cantidad de comensales.');
            if(this.dictDiners) {
                let newDict = {}
                // Si hay algun comensal eliminado
                table.add_diners_names.forEach((value, index) => {
                    if ((value || value == "") && value !== 'null' && value !== null) { // Ignorar valores null o 'null'
                        let key = `C${index + 1}`;
                        if(value == "") newDict[key] = key
                        else newDict[key] = value;
                    }
                });
                table.newDict = newDict

            }

            this.Loading();
            axios.post(`/table/${this.Local.id}/change/diners`, table).then( ({data}) => {
                this.modalChangeDiners = null;
                if(!data.status) return this.Info('Error');
                this.$set(this.modalTable.custom_data, 'customers_number', data.customers_number);
                this.$set(this.modalTable.custom_data, 'diners_names', data.diners_names);
                this.$set(this.modalTable, 'orders', data.orders);
                this.$set(this.modalTable, 'payment', data.payment);
                this.dictDiners = data.diners_names
                // this.$set(this.dictDiners, data.diners_names)
                return this.Info(data.msg);
            }).catch(err => {
                this.Response(err);
            });
        },
        AuxAddTable(arr, table) {
            if(table.id_transaction)
            {
                if(this.Auth.role == 'cashier')
                {
                    if(this.Auth.restrictions.cashier_shared_transactions || (table.waiter && table.waiter.id == this.Auth.id))
                        arr.push(table);
                }
                else if(this.Auth.role == 'waiter')
                {
                    if(this.Auth.restrictions.waiter_multi_tables || (table.waiter && table.waiter.id == this.Auth.id))
                        arr.push(table);
                } 
                else 
                    arr.push(table);
            }
            else
            {
                if(table.status == 'UNAVAILABLE')
                {
                    if(this.Auth.role.indexOf('admin') > -1) arr.push(table);
                }
                else
                    arr.push(table);
            }
        },
        OrderByItem(typeOrder){
            let orders = this.modalTable.orders;
            var items = JSON.parse(JSON.stringify(orders));
            let ret = null;

            if(!typeOrder || typeOrder == 'automatically'){
                for(var i = 0; i < items.length; i++){
                    items[i].index = i;
                    items[i].order = items[i].added_at ? items[i].added_at : 2;
                    if(items[i].delivered)
                        items[i].order = 0;
                    else if(items[i].autocomplete || items[i].prepared)
                        items[i].order = 1
                }

                ret = items.sort((a, b) => {
                    return b.order - a.order;
                });
            }

            if(typeOrder == 'section'){
                const titlesMenu = this.saleData.menu.map(item => item.title);
                const compareItemsByTitle = (a, b) => {
                    const indexA = titlesMenu.indexOf(a.section);
                    const indexB = titlesMenu.indexOf(b.section);
                    
                    return indexA - indexB;
                };

                ret = items.sort(compareItemsByTitle);
            }

            if(typeOrder != 'custom'){
                let eventData = {
                    event: 'ITEM_ORDER',
                    typeOrder: typeOrder,
                    idTransaction: this.modalTable.id,
                    items: ret,
                    transaction: this.modalTable
                };

                axios.patch(`/table/transaction/items/${this.Local.id}/reorder`, eventData).then(res => {
                    this.modalTable = res.data.transaction;
                    this.Update();
                }).catch((err) => {
                    this.Response(err);
                    if(err.response.data.transaction){
                        let transaction = err.response.data.transaction;
                        this.modalTable = transaction;
                        this.Update();
                    }
                });
            }

            this.sortByItem = typeOrder;
        },
        ShowKitchenOrder(order, showPrepared, showDelivered)
        {

            if(this.hideItemsInDisplay) {
                if(this.hideItemsInDisplay[order.section]) return false; // Verifica si en obj existe la seccion 
                if(this.hideItemsInDisplay[order.id]) return false; // Verifica si en el obj existe el ID
            }

            if(order.autocomplete) return false;
            //if(order.prepared && order.delivered) return this.kitchenShowPrepared || this.kitchenShowDelivered;
            if(order.prepared && !order.delivered) return showPrepared;
            if(order.delivered) return showDelivered;
            return true; 
        },
        SupportPYAForceComplete(transaction)
        {
            this.Loading();
            axios.patch(`/transaction/${transaction.id}/pya/complete/force`).then(res => {
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        ChangePlatform(platform){
            if(!platform) {
                let localStoragePlatform = localStorage.getItem('platform') || 'SCHEDULE';
                let isValidPlatform = this.platforms.some(p => p.value === localStoragePlatform);
                platform = this.selectedPlatform = isValidPlatform ? localStoragePlatform : 'SCHEDULE';
            } 
            this.typeMenu = platform;
            localStorage.setItem('platform', platform);
            this.$refs.salesView.ChangePlatform(platform);
        },
        PickedUpPya(transaction) {
            this.Loading();
            axios.post(`/transaction/pedidosya/${this.Local.id}/pickedup`, transaction).then(({data}) => {
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        LogOut(promt) {
            if(promt)
            {
                this.Confirm({
                    text: 'Cerrar Sesión',
                    title: '¿Cerrar sesión en este dispositivo?',
                    class: 'error-color',
                    function: () => {this.LogOut(false)}
                });
            }
            else
            {
                NativeStorage.remove('token', success => {
                    this.$socket.client.disconnect();
                    this.$store.commit('logout');
                    setTimeout(() => {
                        //this.$router.push('/');
                        location.href = '/loginwaiter';
                    }, 100);
                }, err => {

                });
            }
        },
        OpenModalHideItemsInDisplay(){
            this.modalHideItemsInDisplay = {};
            // Se hace copia de la referencia
            let copyHide = this.Util.Copy(this.hideItemsInDisplay);
            this.$set(this.modalHideItemsInDisplay, 'selections', this.hideItemsInDisplay.length === 0 ? {} : copyHide);
        },
        SaveHideItems(){
            let copyToHide = Util.Copy(this.modalHideItemsInDisplay.selections);

            //Se limpia de el obj de los valores "FALSE"
            copyToHide = Object.fromEntries(
                Object.entries(copyToHide).filter(([key, value]) => value)
            );

            this.hideItemsInDisplay = copyToHide;
            // Se guarda en el storage
            localStorage.setItem('hideElementsDisplayKitchen', JSON.stringify(copyToHide));
            this.modalHideItemsInDisplay = null;
            this.searchHideItemsInDisplay = null;
        },
        CheckChangeSplitTable(event) {
            this.modalOpenTable.customers = []            
            // No deja cambiar el check si no hay un numero definido en diners
            if((this.modalOpenTable.diners == undefined || isNaN(this.modalOpenTable.diners) || this.modalOpenTable.diners == 0) && !this.modalOpenTable.split_table) {
                this.Info('El n° de comensales debe ser mayor a 1.');                
                event.preventDefault(); // Evita que el checkbox cambie de estado
                return
            }

            //Si diners cambia a 1 cuando split_table es true, se cierra
            if(this.modalOpenTable.diners < 2 && this.modalOpenTable.split_table) return this.modalOpenTable.split_table = false
        },
        OpenModalChangeDiner(modalTable){
            this.modalChangeDiners = modalTable
            this.$set(this.modalChangeDiners, 'customers_number', modalTable.custom_data['customers_number'])
            this.$set(this.modalChangeDiners, 'add_diners_names', [])
            this.$set(this.modalChangeDiners, 'deleted_diners', [])
            // let arrayOfObjects = Object.values(this.dictDiners);

            // Del diccionario que recibe, alma el array add_diners_names para mostrar los nombres
            let keys = Object.keys(this.dictDiners)
                .filter(key => key.startsWith('C')) // Filtrar solo claves que empiecen con 'C'
                .sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1))); // Ordenar numéricamente

            // Determinar el tamaño del array basado en la clave más alta
            let size = keys.length > 0 ? Math.max(...keys.map(key => parseInt(key.slice(1)))) : 0;

            // Construir el array y asignar valores
            let result = Array(size).fill(null); // Inicializar con `null`
            keys.forEach(key => {
                let index = parseInt(key.slice(1)) - 1; // Obtener índice (base 0)
                result[index] = this.dictDiners[key];
            });
            
            this.modalChangeDiners.add_diners_names = [...result]
        },
        TotalDiner(order){
            let total = 0
            order.forEach(element => {
                if(!element.paid && !element.paid_at) total += element.total_money
            });
            return total
        },
        ChangeDinerToItem(order, diner){
            let data = {
                index : order.index,
                id_item : order.id,
                new_diner : diner,
                added_at : order.added_at
            }
            if(order.selected_items) data.selected_items = order.selected_items
            this.Loading();
            axios.post(`/transaction/${this.modalTable.id}/diner/change`, data).then(res => {
                this.modalTable.orders = res.data.transaction.orders;
                this.modalChangeDinerFromItem = null
                this.tableItemsSelection = null
                this.Info("Transferencia de comensal realizada.")
            }).catch((err) => {
                this.Response(err);
            });
        },
        DictTagDinerName(value, showName=false){
            if(!this.dictDiners) return
            let diner = this.dictDiners[value]
            if(showName) return diner
            this.$set(this.modalTable, 'selected_diner', {value:value, text : diner});
        },
        DeleteDiner(index, diner) {
            let keyToRemove = null;

            // Encontrar la clave asociada al diner
            for (let key in this.dictDiners) {
                if (this.dictDiners[key] === diner) {
                    keyToRemove = key;
                    break;
                }
            }
            // Verificar si la clave está en `dictDiners` original
            if (keyToRemove) {
                if (this.dictDiners[keyToRemove]) {
                    // Añadir la clave a `deleted_diners`
                    this.modalChangeDiners.deleted_diners.push(keyToRemove);
                    this.modalChangeDiners.deleted_diners = [...new Set(this.modalChangeDiners.deleted_diners)]
                }
            }

            this.$set(this.modalChangeDiners.add_diners_names, index, null);
            let newNumber = this.modalChangeDiners.add_diners_names.filter(name => name != null)
            this.modalChangeDiners.customers_number = newNumber.length
        },

        AddItems(){
            this.$refs.transactionDetails.OpenItems(this.modalTable)
            this.selectedDiner = this.modalTable.selected_diner        
        },
        TransferItemsSelection(){
            this.modalChangeDinerFromItem = {};
            let selectedItems = [];
            for(var key in this.tableItemsSelection)
            {
                selectedItems.push({
                    id: this.tableItemsSelection[key],
                    index: parseInt(key)
                });
            }
            this.modalChangeDinerFromItem.selected_items = selectedItems;
        },
        ChangeDinerPartialPayment(event){
            if(this.tableItemsSelection) return
            let copy_orders = this.Util.Copy(this.modalTable.orders);
            let copy_payments = this.Util.Copy(this.modalTable.payment);
            let total_to_pay = 0 

            let total_comensal = copy_orders.reduce((sum, order) => {
                return order.diner == event ? sum + order.total_money : sum;
            }, 0);

            if(event != 'ALL'){
                // En el caso que el comensal ya tenga pagos
                if(copy_payments.some(payment => payment.diner == event)){
                    // console.log("hay algun diner en payments");
                    let total_diner = copy_payments.reduce((sum, payment) => {
                        return payment.diner == event ? sum + payment.subtotal : sum;
                    }, 0);
                    total_to_pay = total_comensal - total_diner
                    this.modalPartialPayment.totalPaid = total_diner
                }else{
                    // Comensal no tiene pagos
                    total_to_pay = total_comensal
                    this.modalPartialPayment.totalPaid = 0
                }
            }else {
                let total_order = copy_payments.reduce((sum, payment) => {
                    return payment.paid_at ? sum + payment.subtotal : sum;
                }, 0);
                total_to_pay = this.modalTable.total_money - total_order 
                this.modalPartialPayment.totalPaid = total_order
            }
            this.modalPartialPayment.total = event != 'ALL' ? total_comensal : this.modalTable.total_money
            this.modalPartialPayment.subtotal = total_to_pay
        },
    },
    computed: {
        canCancel() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.cancel_transactions);
        },
        canComplete() {
            if(this.Auth.role.indexOf('admin') > -1) return true;
            if(!this.Auth.restrictions.complete_transactions) return false;
            return !!(!this.Auth.restrictions.complete_transactions_turn_only || this.Turn);
        },
        canSend() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.send_transactions);
        },
        canEdit() {
            return !!(this.Auth.role.indexOf('admin') > -1 || (this.Auth.restrictions.edit_transactions || this.Auth.restrictions.edit_transactions_payment));
        },
        canEditTables() {
            return !!(this.Auth.role.indexOf('admin') > -1 || (this.Auth.restrictions.create_transactions && (!this.Auth.restrictions.transactions_turn_only || this.Turn)) )
        },
        canEditOld() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_previous_items);
        },
        canChangeOrderStatus() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_order_status);
        },
        canTransferTables() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.change_table);
        },
        canCreateTransactions() {
            if(!this.Auth) return false;
            if(this.Auth.role.indexOf('admin') > -1) return true;
            return !!(!this.Auth.restrictions.transactions_turn_only || this.Turn);
        },
        canEditScheduled() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_transactions);
        },
        showDelivery() {
            return this.Auth.delivery_enabled;
        },
        visibleTickets() {
            var ret = [];
            /*
            {text: 'Mostrar todos', value: 'ALL'},
            {text: 'Mostrar en preparación', value: 'PREPARING'},
            {text: 'Mostrar listos', value: 'READY'},
            {text: 'Mostrar para delivery', value: 'DELIVERY'},
            {text: 'Mostrar despachados', value: 'ON_DELIVERY'},
            {text: 'Mostrar para llevar', value: 'TAKEAWAY'},
            {text: 'Mostrar para retirar', value: 'TAKEAWAY_READY'},
            {text: 'Mostrar para servir', value: 'SERVE'},
            */
            var filter = this.view == 'KITCHEN' ? 'PREPARING' : this.filter;

            for(var i = 0; i < this.tickets.length; i++)
            {
                var ticket = this.tickets[i];

                var search = this.searchPending.trim();
                var matchesSearch = false;
                if(search)
                {
                    if( (ticket.customer && ticket.customer.name && this.Util.InString(ticket.customer.name, search)) ||
                        (ticket.customer && ticket.customer.address && this.Util.InString(ticket.customer.address, search)) || 
                        (ticket.customer && ticket.customer.phone && this.Util.InString(ticket.customer.phone, search)) ||
                        (this.Util.InString(ticket.id_ticket.toString(), search))
                    )
                        matchesSearch = true;
                }
                else
                {
                    matchesSearch = true;
                }

                if(this.Auth.role == 'cashier')
                {
                    if(!this.Auth.restrictions.cashier_shared_transactions && (ticket.id_created_by === 0 || ticket.id_created_by !== this.Auth.id)) matchesSearch = false;
                }
            
                if(matchesSearch)
                {
                    if(filter == 'ALL')
                    {
                        ret.push(ticket);
                    }
                    else if(filter == 'PREPARING' || filter == 'READY')
                    {
                        var showPreparing = this.view == 'KITCHEN' && this.kitchenShowPrepared;
                        var showDelivered = this.view == 'KITCHEN' && this.kitchenShowDelivered;

                        var showTicket = false;
                        if(ticket.status !== 'DELIVERY')
                        {
                            //mostrar solo si tiene items pendientes
                            for(var k = 0; k < ticket.orders.length; k++)
                            {
                                var item = ticket.orders[k];
                                if(this.ShowKitchenOrder(item, showPreparing, showDelivered))
                                {
                                    showTicket = true;
                                    break;
                                }
                            }

                            //mostrar solo si cumple las condiciones de tiempo de entrega
                            var activation_time = null;
                            if(ticket.type == 'TAKEAWAY') activation_time = this.tickets[i].custom_data.activation_time;
                            else if(ticket.type == 'DELIVERY') activation_time = this.tickets[i].custom_data.delivery_time;

                            if(this.settings.kitchen_activation_time && activation_time)
                            {
                                var mins = this.Util.TimeToMinutes(activation_time);
                                if(mins !== null && (mins - this.dayMinutes  > this.settings.kitchen_activation_time))
                                    showTicket = false;
                            }

                            //mostrar solo si no esta listo para retirar
                            if(ticket.custom_data.takeaway_ready) showTicket = false;    
                        }

                        if(filter == 'PREPARING' && showTicket) ret.push(ticket);
                        if(filter == 'READY' && !showTicket) ret.push(ticket);
                    }
                    else if(filter == 'ON_DELIVERY')
                    {
                        if(ticket.status == 'DELIVERY') ret.push(ticket);
                    }
                    else if(filter == 'TAKEAWAY_READY')
                    {
                        if(ticket.custom_data.takeaway_ready) ret.push(ticket);
                    }
                    else if(filter == 'PAID' && ticket.paid_at)
                    {
                        ret.push(ticket);
                    }
                    else if(filter == 'NOT_PAID' && !ticket.paid_at)
                    {
                        ret.push(ticket);
                    }
                    else if(filter == 'CREATED_BY' && (ticket.id_created_by == this.Auth.id || ticket.id_created_by === 0))
                    {
                        ret.push(ticket);
                    }
                    else
                    {
                        if(ticket.type == filter) ret.push(ticket);
                    }
                }
            }

            var sort = this.sort;
            var ScheduledTime = (ticket) => {
                if(ticket.custom_data.scheduled_at) return ticket.custom_data.scheduled_at * 60;
                if(ticket.type == 'DELIVERY' && ticket.custom_data.pedidosya_courier && ticket.custom_data.pedidosya_courier.pickupTime) return this.Util.Date(ticket.custom_data.pedidosya_courier.pickupTime, 'timestamp') * 60;
                if(ticket.type == 'DELIVERY' && ticket.custom_data.delivery_time) return this.Util.TimeToMinutes(ticket.custom_data.delivery_time);
                if(ticket.type == 'TAKEAWAY' && ticket.custom_data.activation_time && !ticket.custom_data.shipment) return this.Util.TimeToMinutes(ticket.custom_data.activation_time);
                if(ticket.type == 'DELIVERY' && ticket.custom_data.shipment) return ticket.custom_data.shipment.shipment_start;
                if(ticket.type == 'TAKEAWAY' && ticket.custom_data.shipment) return ticket.custom_data.shipment.shipment_start_takeaway;
                return ticket.created_at * 600;
            }

            ret.sort((a, b) => {
                if(sort == 'CUSTOM')
                    return a.custom_data.priority == b.custom_data.priority ? (b.created_at - a.created_at) : (a.custom_data.priority - b.custom_data.priority);
                else if(sort == 'SCHEDULED')
                    return ScheduledTime(a) - ScheduledTime(b);
                else if(sort == 'TOTAL')
                    return b.total_money - a.total_money;
                else if(sort == 'ITEMS')
                {
                    var qttyA = 0, qttyB = 0;
                    a.orders.forEach(item => {qttyA += item.quantity});
                    b.orders.forEach(item => {qttyB += item.quantity});
                    return qttyB - qttyA;
                }
                else
                    return b.id_ticket - a.id_ticket;
            });
            return ret;
        },
        //MESAS
        availableTables() {
            var ret = [];
            if(!this.tables) return ret;
            
            var sections = {undefined: {tables: [], name: this.searchTable ? 'Búsqueda' : 'Otras mesas'}};

            if(this.tablesSort == 'STATUS' && !this.searchTable)
            {
                sections.unavailable = {name: 'No disponible', tables: []};
                sections.available = {name: 'Disponible', tables: []};
                sections.reserved = {name: 'Reservadas', tables: []};
                sections.active = {name: 'Abiertas', tables: []};
                sections.joined = {name: 'Unidas', tables: []};
            }

            var mapTables = this.Util.Copy(this.tables);

            for(var key in mapTables)
            {
                //var table = JSON.parse(JSON.stringify(this.tables[key]));
                var table = mapTables[key];
                table.status = 'AVAILABLE';
                
                if(table.reservation_data) 
                {
                    table.reservation = this.Util.Date(table.reservation_data.date, 'DD/MM');
                    table.status = 'RESERVED';
                }
                var items = {
                    ready: 0,
                    pending: 0
                };
                var waiter = null;

                if(table.id_transaction)
                {
                    for(var i = 0; i < this.tickets.length; i++)
                    {
                        var transaction = this.tickets[i];
                        if(transaction.id == table.id_transaction)
                        {
                            waiter = transaction.custom_data.waiter ? transaction.custom_data.waiter : {id: transaction.id_created_by, name: transaction.custom_data.created_by};
                            for(var k = 0; k < transaction.orders.length; k++)
                            {
                                var order = transaction.orders[k];
                                if(!order.delivered && !order.prepared) items.pending+=order.quantity;
                                if(!order.delivered && (order.prepared || order.autocomplete)) items.ready+=order.quantity;
                            }
                            break;
                        }
                    }
                    
                    if(items.ready) table.status = 'READY';
                    else if(items.pending) table.status = 'PENDING';
                    else table.status = 'OPEN';
                }

                if(table.parent_table)
                {
                    var parent = mapTables[table.parent_table];
                    if(parent)
                    {
                        if(!parent.children) parent.children = [];
                        parent.children.push(table);
                        for(var i = 0; i < this.tickets.length; i++)
                        {
                            var transaction = this.tickets[i];
                            if(transaction.id == parent.id_transaction)
                            {
                                waiter = transaction.custom_data.waiter ? transaction.custom_data.waiter : {id: transaction.id_created_by, name: transaction.custom_data.created_by};
                                break;
                            }
                        }
                    }
                    
                }

                table.items = items;
                table.waiter = waiter;

                if(table.parent_table) table.status = 'JOINED';
                if(!table.available) table.status = 'UNAVAILABLE';

                if(this.searchTable)
                {
                    if(this.Util.InString(table.name, this.searchTable) || this.Util.InString(table.reservation, this.searchTable, false) || this.Util.InString(table.reservation_data ? table.reservation_data.name : null, this.searchTable, false))
                    {
                        this.AuxAddTable(sections.undefined.tables, table);
                    }
                }
                else
                {
                    if(this.tablesSort == 'STATUS')
                    {
                    
                        if(!table.available) sections.unavailable.tables.push(table);
                        else if(table.reserved) sections.reserved.tables.push(table);
                        else if(table.parent_table) sections.joined.tables.push(table);
                        else if(table.id_transaction) this.AuxAddTable(sections.active.tables, table);
                        else sections.available.tables.push(table);
                    }
                    else
                    {
                        if(table.section)
                        {
                            if(!sections[table.section]) sections[table.section] = {name: table.section, tables: []};
                            this.AuxAddTable(sections[table.section].tables, table);
                        }
                        else
                        {
                            this.AuxAddTable(sections.undefined.tables, table);
                        }   
                    }
                }
                //ret.push(table);
            }

            if(this.tablesSort == 'STATUS' && !this.searchTable)
            {
                sections.active.tables.sort((a, b) => {
                    return (b.items.pending+b.items.ready) - (a.items.pending+a.items.ready);
                });
                this.Util.NaturalSort(sections.available.tables, 'name');
                sections.reserved.tables.sort((a, b) => {
                    return a.reservation_data.date - b.reservation_data.date;
                });
                this.Util.NaturalSort(sections.unavailable.tables, 'name');
                this.Util.NaturalSort(sections.joined.tables, 'parent_table');

                var tablesSections = [
                    sections.active,
                    sections.available,
                    sections.joined,
                    sections.reserved,
                ];
                if(this.Auth.role !== 'waiter')
                    tablesSections.push(sections.unavailable);
                sections = tablesSections;
            }
            else
            {
                sections = Object.values(sections);

                var defaultSection = sections.shift();

                this.Util.NaturalSort(sections, key);
                sections.push(defaultSection);

                sections.forEach(section => {
                    this.Util.NaturalSort(section.tables, 'name');
                });
            }

            return sections;
        },
        sectionTables() {
            if(!this.tables) return [];
            
            var sections = {undefined: {tables: [], name: 'Otras mesas'}};

            for(var key in this.tables)
            {
                var table = this.tables[key];
                if(table.section)
                {
                    if(!sections[table.section]) sections[table.section] = {name: table.section, tables: []};
                    sections[table.section].tables.push(table);
                }
                else
                {
                    sections.undefined.tables.push(table);
                }
            }

            sections = Object.values(sections);

            var defaultSection = sections.shift();

            this.Util.NaturalSort(sections, key);
            sections.push(defaultSection);

            sections.forEach(section => {
                this.Util.NaturalSort(section.tables, 'name');
            });
            
            return sections;
        },
        usedTablesCount() {
            if(!this.tables) return 0;
            var count = 0;
            for(var key in this.tables)
                if(this.tables[key].id_transaction) count++;
            return count;
        },
        childrenTables() {
            if(!this.tables || !this.modalTable) return [];
            var ret = [];
            for(var key in this.tables)
                if(this.tables[key].parent_table == this.modalTable.table_name) ret.push(this.tables[key]);
            return ret;
        },
        selectedTicket() {
            if(!this.idSelectedTicket) return null;
            for(var i = 0; i < this.tickets.length; i++)
                if(this.tickets[i].id == this.idSelectedTicket) return this.tickets[i];
            return null;
        },
        iapViewTickets() {
            if(!this.iap) return [];
            var tickets = [];
            if(this.view == 'IAP') 
            {
                tickets = this.iap.pending;
            }
            else if(this.view == 'SCHEDULED')
            {
                tickets = this.iap.scheduled;
            }

            var ret = [];

            var search = this.searchIAP.trim();
            tickets.forEach(ticket => {
                if( !search ||
                    (ticket.customer_name && this.Util.InString(ticket.customer_name, search)) ||
                    (ticket.address && this.Util.InString(ticket.address, search)) ||
                    (ticket.phone && this.Util.InString(ticket.phone, search))
                )
                {
                    if(this.view == 'SCHEDULED')
                    {
                        if(this.scheduledDateFilter)
                        {
                            if(this.scheduledDateFilter == this.Util.Date(ticket.scheduled_at, 'DD/MM/YYYY'))
                                ret.push(ticket);
                        }
                        else
                        {
                            ret.push(ticket);
                        }
                    }
                    else
                    {
                        ret.push(ticket);
                    }
                }
                    
            });

            return ret;
        },
        partialPaymentTotal() {
            if(!this.modalTable || !this.modalPartialPayment) return 0;
            if(this.tableItemsSelection) return this.modalPartialPayment.subtotal;
            
            var remaining = this.modalPartialPayment.total - this.modalPartialPayment.totalPaid;
            var subtotal = this.Util.Partial(this.modalPartialPayment.subtotal, remaining);
            return subtotal;

        },
        partialPaymentTip() {
            if(!this.modalTable || !this.modalPartialPayment || !this.partialPaymentTotal) return 0;
            if(this.modalPartialPayment.tipTotal == 'NO_TIP') return 0;
            let tip = this.modalPartialPayment.tip;
            if(!tip.trim()) tip = 0;
            return this.Util.Partial(tip, this.modalPartialPayment.tipTotal == 'AUTO' ? this.partialPaymentTotal : this.modalPartialPayment.tipTotal);
        },
        TransferAvailableTables()
        {

            /*
             <div class="section" v-for="section in sectionTables" :key="section.name">
            <div class="bold">{{section.name}}</div>
            <label class="input-style"
            v-show="(modalChangeTable.transfer && table.available) || ((table.available && !table.id_transaction) || table.name == modalChangeTable.previous)"
            v-for="table in section.tables"
            :key="section.name + table.name"
            @click="$set(modalChangeTable, 'selected', table.name)">
                <input type="radio" :value="table.name" :checked="modalChangeTable.selected == table.name"> 
                <span class="secondary-text ml5" v-if="table.reserved"> (reservada)</span>
                <span class="secondary-text ml5" v-if="table.id_transaction"> (en uso)</span>
            </label>
            </div>
            */
            var ret = [];
            if(!this.modalChangeTable) return ret;

            this.sectionTables.forEach(section => {
                section.tables.forEach(table => {
                    if(table.name != this.modalChangeTable.previous && ((this.modalChangeTable.transfer && table.available) || (table.available && !table.id_transaction)) )
                    {
                        var description = section.name;
                        if(table.reserved) description += ' (Reservada)';
                        if(table.id_transaction) description += ' (En uso)';
                        ret.push({text: table.name, value: table.name, description: description});
                    }
                });
            });

            return ret;

        },
        
        JoinAvailableTables()
        {

            /*
            <div class="section" v-for="section in sectionTables" :key="section.name">
            <div class="bold">{{section.name}}</div>
            <label class="input-style"
            v-for="table in section.tables"
            :key="section.name + table.name"
            v-show="table.name != modalJoinTable.source && table.parent_table !== modalJoinTable.source"
            @click="$set(modalJoinTable, 'target', table.name)">
                <input type="radio" :value="table.name" :checked="modalJoinTable.target == table.name"> 
                <span class="secondary-text ml5" v-if="table.reserved"> (reservada)</span>
                <span class="secondary-text ml5" v-if="table.id_transaction"> (en uso)</span>
                <span class="secondary-text ml5" v-if="table.parent_table"> (unida a {{table.parent_table}})</span> {{table.name}} <div class="radio"></div>
            </label>
            </div>
            */
            var ret = [];
            if(!this.modalJoinTable) return ret;

            this.sectionTables.forEach(section => {
                section.tables.forEach(table => {
                    if(table.available && table.name != this.modalJoinTable.source && table.parent_table !== this.modalJoinTable.source)
                    {
                        var description = section.name;
                        if(table.reserved) description += ' (Reservada)';
                        if(table.id_transaction) description += ' (En uso)';
                        if(table.parent_table) description += ` (Unida a ${table.parent_table})`;
                        ret.push({text: table.name, value: table.name, description: description});
                    }
                });
            });

            return ret;

        },
        scheduledDays() {
            if(!this.iap || !this.iap.scheduled) return {};
            var ret = {};
            this.iap.scheduled.forEach(ticket => {
                var date = this.Util.Date(ticket.scheduled_at, 'DD/MM/YYYY');
                ret[date] = ret[date] ? ret[date]+1 : 1;
            });
            return ret;
        },
        orderedItems(){
            if(!this.modalTable) return;
            let orders = this.modalTable.orders;
            let ret = null;
            if(this.modalTable.selected_diner && this.modalTable.selected_diner.value != 'ALL'){
                let filterByDiner = orders.filter(order => order.diner == this.modalTable.value_diner)
                orders = filterByDiner
            }

            let bySort = this.sortByItem == 'custom'? this.sortByItem : this.modalTable.custom_data.type_order? this.modalTable.custom_data.type_order : null;

            var items = JSON.parse(JSON.stringify(orders));

            for(var i = 0; i < items.length; i++){
                items[i].index = i;
                items[i].order = items[i].added_at ? items[i].added_at : 2;
                if(items[i].delivered)
                    items[i].order = 0;
                else if(items[i].autocomplete || items[i].prepared)
                    items[i].order = 1
            }

            if(!bySort || bySort == 'automatically'){
                ret = items.sort((a, b) => {
                    return b.order - a.order;
                });
            }

            if(bySort == 'custom') ret = items;

            if(bySort == 'section'){
                const titlesMenu = this.saleData.menu.map(item => item.title);
                const compareItemsByTitle = (a, b) => {
                    const indexA = titlesMenu.indexOf(a.section);
                    const indexB = titlesMenu.indexOf(b.section);
                    
                    return indexA - indexB;
                };

                ret = items.sort(compareItemsByTitle);
            }

            return ret;
        },
        filteredOverwrite(){
            if(!this.saleData || !this.saleData.overwrites) return;
            if(!this.typeMenu) return this.saleData.overwrites;
            let overwrites = Util.Copy(this.saleData.overwrites);
            const filtered = overwrites.filter(overwrite => overwrite.type  == this.typeMenu);
            return filtered;
        },
        platforms(){
            if(!this.saleData || !this.saleData.overwrites) return [];
            let overwrites = Util.Copy(this.saleData.overwrites);

            const uniqueTypes = [...new Set(overwrites.map(overwrite => overwrite.type))];

            if(!uniqueTypes.length || (uniqueTypes.length == 1 && uniqueTypes[0] == "SCHEDULE")) return []; //Sí sólo tiene horarios SCHEDULE

            const index = uniqueTypes.indexOf("SCHEDULE");
            if(index !== -1) uniqueTypes.splice(index, 1);

            uniqueTypes.unshift("SCHEDULE");

            const formattedTypes = uniqueTypes.map(type => {
                return {
                    value: type,
                    text: this.dictPlatforms[type]? this.dictPlatforms[type] : type
                };
            });

            return formattedTypes;
        },
        dictItems() {
            var ret = {};
            if(!this.saleData || !this.saleData.menu) return ret;
            this.saleData.menu.forEach(section => {
                section.items.forEach(item => {
                    ret[item.id] = item;
                    ret[item.id]['section_color'] = section.color
                });
            });
            return ret;
        },
        searchHideItems(){
            //Si el modal es null
            if(!this.modalHideItemsInDisplay) return;
            let ret = []; 
            let menu = Util.Copy(this.saleData.menu);
            let arraySect = [];

            // Si se comienza a buscar
            if(this.searchHideItemsInDisplay){
                for (let index = 0; index < menu.length; index++) {
                    let arraySub = [];
                    for (let is = 0; is < menu[index].items.length; is++) {
                        const subitem = menu[index].items[is];
                        
                        // Busca coincidencia por el nombre del item de la seccion
                        let exist = this.Util.InString(subitem.title, this.searchHideItemsInDisplay);
                        // Retorna true si existe coincidencia y se agrega al array
                        if(exist) arraySub.push(subitem);
                    }
                    // Se agrega el array al items de la seccion
                    menu[index].items = arraySub;

                    // La seccion se agrega a otro array con las items buscados
                    arraySect.push(menu[index]);              
                }
                ret = arraySect;
            }else {
                // Si no hay busqueda, se retorna el menu completo
                ret = menu
            }
            return ret;
        },
        dinersList() {
            if (this.modalTable.custom_data['diners_names']) {
                let data = this.modalTable.custom_data['diners_names'];
                data = Object.entries(data).map(([key, value]) => ({
                    value: key,
                    text: value
                }));
                data.unshift({ value: 'ALL', text: 'Pedido' });
                return data;
            }else{
                return {}
            }
        },
        dinersListTransfer() {
            if(!this.modalChangeDinerFromItem) return
            if (this.modalTable.custom_data['diners_names'] && this.modalChangeDinerFromItem) {

                let not_diner = this.modalChangeDinerFromItem && this.modalChangeDinerFromItem.diner ? this.modalChangeDinerFromItem.diner : null; // Puede ser null
                
                let data = this.modalTable.custom_data['diners_names'];

                data = Object.entries(data).map(([key, value]) => ({
                    value: key,
                    text: value
                }))
                .filter(item => not_diner ? item.value != not_diner : true);
                
                //Si el diner del item es distinto a "ALL", no añade la opcion al selector de "transferir a comensal"
                if (this.modalChangeDinerFromItem.diner && this.modalChangeDinerFromItem.diner != 'ALL') {
                    data.unshift({ value: 'ALL', text: 'Pedido' });
                }
                return data;
            }
        },
        addDinerName() {
            if (!this.modalChangeDiners || !this.dictDiners) return [];
            return this.modalChangeDiners.add_diners_names || [];
        }
    },
    watch: {
        view: function(n, o) {
            if(n == 'KITCHEN') this.ChangeToColumns();
            this.$store.commit('setOrdersTab', n);
        },
        modalMobileTables: function(n, o) {
            if(n)
            {
                this.$nextTick(() => {
                    this.$refs.mobileTables.appendChild(this.$refs.tablesSelection);
                });
            }
        },
        modalMobileTableOptions: function(n, o) {
            if(n)
            {
                this.$nextTick(() => {
                    this.$refs.mobileTableOptions.appendChild(this.$refs.tableOptions);
                });
            }
        },
        modalTable: function(n, o)
        {
            if(!n)
            {
                this.tableItemsSelection = null;
            }
        },
        'modalChangeDiners.customers_number': {
            handler(newCount) { 
                if (!newCount) return;
                if (!this.modalChangeDiners || !Array.isArray(this.modalChangeDiners.add_diners_names)) return;
                let initialDiners = Object.values(this.dictDiners) // Obtén los valores iniciales
                let copy = { ...this.dictDiners }
                if(this.modalChangeDiners.deleted_diners && this.modalChangeDiners.deleted_diners.length > 0) {
                    this.modalChangeDiners.deleted_diners.forEach(deletedKey => {
                        delete copy[deletedKey]; // Eliminar las claves especificadas en deleted_diners
                    });
                }

                let currentCount = this.modalChangeDiners.add_diners_names.filter(item=> item != null).length;
                
                //cuando  newCount es mayor
                if (newCount > currentCount) {
                    for (let i = currentCount; i < newCount; i++) {

                        if(this.modalChangeDiners.deleted_diners && this.modalChangeDiners.deleted_diners.length > 0) {
                            if (this.modalChangeDiners.deleted_diners.some(deletedKey => Object.keys(this.dictDiners).includes(deletedKey))) {            
                                let name = Object.values(copy)[i] || "";
                                this.modalChangeDiners.add_diners_names.push(name);
                            }else {
                                this.modalChangeDiners.add_diners_names.push("");
                            }
                            
                        }else {
                            this.modalChangeDiners.add_diners_names.push(
                                i < initialDiners.length ? initialDiners[i] : "" // Nombres iniciales o vacío
                            );
                        }
                    }
                } else if (newCount < currentCount) {
                      //cuando  newCount es menor
                    if(this.modalChangeDiners.deleted_diners && this.modalChangeDiners.deleted_diners.length > 0) this.modalChangeDiners.add_diners_names = this.modalChangeDiners.add_diners_names.filter(item => item != null)
                    this.modalChangeDiners.add_diners_names.splice(newCount); // Elimina desde el nuevo límite
                }
            }
            
        }
    },
    sockets: {
        connect()
        {
            if(this.Auth.role == 'delivery') return;
            this.Update();
            this.UpdateMenu();
            this.UpdateInventory();
        },
        TRANSACTION_CREATED(data) {
            if(this.Auth.role == 'delivery') return;
            this.tickets.unshift(JSON.parse(data.transaction));
            if(this.attentionSoundEnabled) this.audioAttention.play();
        },
        TRANSACTION_CANCELLED(data) {
            if(this.Auth.role == 'delivery') return;

            if(this.modalTable && this.modalTable.id == data.id_transaction)
            {
                this.Info(`El pedido de ${this.modalTable.table_name} fue cancelado`);
                this.modalTable = null;
            }

            if(this.modalEdit && this.modalEdit.id == data.id_transaction)
            {
                this.Info(`El pedido #${this.Util.Zeropad(this.modalEdit.id_ticket, 10)} fue cancelado`);
                this.modalEdit = null;
            }

            for(var i = 0; i < this.tickets.length; i++)
            {
                if(parseInt(data.id_transaction) == this.tickets[i].id)
                {
                    this.$set(this.tickets[i].custom_data, 'cancelled_reason', data.reason);
                    this.$set(this.tickets[i], 'cancelled_at', parseInt(data.cancelled_at));
                    this.$set(this.tickets[i].custom_data, 'cancelled_by', data.cancelled_by);
                    this.$set(this.tickets[i], 'status', 'CANCELLED');
                    this.$refs.transactionDetails.UpdateTransaction(this.tickets[i]);
                    return;
                }
            }
        },
        TRANSACTION_DELIVERY(data) {
            if(this.Auth.role == 'delivery') return;
            for(var i = 0; i < this.tickets.length; i++)
            {
                if(data.id_transaction == this.tickets[i].id)
                {
                    this.$set(this.tickets[i].custom_data, 'delivery_by', data.delivery_by);
                    this.$set(this.tickets[i], 'status', 'DELIVERY');
                    this.$refs.transactionDetails.UpdateTransaction(this.tickets[i]);
                    return;
                }
            }
        },
        TRANSACTION_COMPLETED(data) {
            if(this.Auth.role == 'delivery') return;

            if(this.modalTable && this.modalTable.id == data.id_transaction)
            {
                this.Info(`El pedido de ${this.modalTable.table_name} fue completado`);
                this.modalTable = null;
            }

            if(this.modalEdit && this.modalEdit.id == data.id_transaction)
            {
                this.Info(`El pedido #${this.Util.Zeropad(this.modalEdit.id_ticket, 10)} fue completado`);
                this.modalEdit = null;
            }

            for(var i = 0; i < this.tickets.length; i++)
            {
                if(data.id_transaction == this.tickets[i].id)
                {
                    this.$set(this.tickets[i], 'status', 'COMPLETED');
                    this.$refs.transactionDetails.UpdateTransaction(this.tickets[i]);
                    return this.tickets.splice(i, 1);
                }
            }
        },
        TRANSACTIONS_REORDER(data) {
            if(this.Auth.role == 'delivery') return;
            this.ReorderTickets(data);
        },
        TRANSACTION_EDIT(data) {
            if(this.Auth.role == 'delivery') return;
            var t = JSON.parse(data.transaction);
            let transaction = t.transaction ? t.transaction : t;
            console.log(this.modalEdit);
            if(this.modalEdit && this.modalEdit.edit_at !== transaction.edit_at && !this.modalEdit.isLocalEdit)
            {
                this.Info(`El pedido #${this.Util.Zeropad(this.modalEdit.id_ticket, 10)} fue editado`);
                this.modalEdit = null;
                this.$nextTick(() => {
                    this.modalEdit = transaction;
                });
            }
            if(this.modalEdit) this.modalEdit.isLocalEdit = false;
            
            // Abria que sacarlo ?
            let some = transaction.orders.some(order => order.prepared)
            if(some && this.attentionPreparedSoundEnabled) this.audioPreparedAttention.play();
            
            return this.OnTransactionEdit(t, false);
        },
        /*
        UPDATE_TABLES(data)
        {
            this.tables = JSON.parse(data.tables);
        },
        */
        INVENTORY_UPDATED() {
            if(this.Auth.role == 'delivery') return;
            this.UpdateInventory();
        },
        MENU_UPDATED() {
            if(this.Auth.role == 'delivery') return;
            this.UpdateMenu();
        },
        TABLES_UPDATED() {
            if(this.Auth.role == 'delivery') return;
            this.UpdateMenu();
        }
    },
    beforeDestroy() {
        if(this.intervalReference) clearInterval(this.intervalReference);
    }
}
</script>

<style lang="scss">
    #pending-main-container {
        padding-left: 56px;
    }
    #pending-main-container .left-menu {
        position: absolute;
        left: 0;
        top: 56px;
        bottom: 0;
        width: 56px;
        padding-top: 10px;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        border-right: 1px solid var(--gray-2);
    }
    #pending-main-container.no-padding {
        padding: 0
    }
    
    .pending-column {
        width: 200px; 
        height: 100px; 
        min-width: 200px; 
        max-width: 200px;
        padding: 0px 2px;
    }
    .pending-column > div {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
    .ticket-dragged {
        box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.4);
        transition: box-shadow 0.1 linear;
        cursor: grabbing;
    }
    .horizontal-scroll {
        overflow-x: auto;
    }
    .ticket-handle {
        cursor: grab;
    } 
    .CANCELLED-ticket {
        opacity: 0.7;
    }
    
    .CANCELLED-ticket .ticket-header, .CANCELLED-ticket .ticket-handle {
        color: var(--black) !important;
    }
    
    .order-item.ready {
        position: relative;
        font-weight: normal !important;
        // cursor: unset !important;
        
        & > div:first-child {
            color: var(--gray-1);
        }
        & > div:nth-child(2) {
            color: var(--gray-1);
        }
    
    }
    
    .order-item.ready::after {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 16px;
        font-family: "influye-admin-font";
        content: "&";
        color: var(--blue-2);
    }
    .order-item.paid {
        position: relative;
        
        & > div:first-child {
            color: var(--gray-1);
        }
        & > div:nth-child(2) {
            color: var(--gray-1);
        }
    
    }
    
    .order-item.paid::after {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 16px;
        font-family: "influye-admin-font";
        content: ".";
        color: var(--green);
    }
    .order-item.cost::after {
        content: "" !important;
    }
    
    .order-item.attention {
        position: relative;
        & > div:first-child {
            
        }
        & > div:nth-child(2) {
            font-weight: 500;
        }
    }
    .order-item.attention::after {
        font-weight: normal;
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 16px;
        font-family: "influye-admin-font";
        content: "T";
        color: var(--yellow);
        
    }
    
    .ticket-header {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }
    
    .order-comment::before {
        content: "7";
    }
    
    .order-item.selectable:not(.ready):hover {
        background-color: var(--gray-3);
        border-radius: 10px;
        outline: 3px solid var(--gray-3);
    }
    
    .ALL-desktop {
        max-width: 310px; 
        width: 310px; 
        min-width: 310px; 
        padding-right: 10px;
    }
    .ALL-desktop .transaction-ticket {
        width: 300px !important; 
        min-width: 300px !important; 
        max-width: 300px !important;
    }
    .ALL-desktop:last-child {
        width: 100%;
    }
    
    .ALL-mobile {
        width: 100%;
        margin-bottom: 20px;
    }
    .ALL-mobile:first-child {
        margin-top: 5px;
    }
    .ALL-mobile .transaction-ticket {
        width: 100% !important;
    }
    .timer {
        background-color: whitesmoke;
        padding: 4px;
        border-radius: 2px;
        margin-bottom: 2px;
        text-align: right;
    }
    .ticket-row {
        background-color: var(--white); 
        box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
        border-radius: 10px;
        //min-width: 800px;
    
        .ticket-color {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    
    }
    
    .ticket-table {
        background-color: var(--white);
        border-bottom: 1px solid var(--gray-2);
        cursor: pointer;
    }
    .ticket-table:hover {
        background-color: var(--gray-3);
    }
    
    .ticket-table.selected, .ticket-table.selected .ticket-handle {
        background-color: var(--gray-25);
        box-shadow: 0 2px 0 var(--purple) inset,
                    0px -2px 0 var(--purple) inset;
        z-index: 1;
        * {
            border-color: var(--purple);
        }
    }
    
    .tables-separator {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        color: rgba(0,0,0,0.2);
    }
    .table {
        text-align: center;
        background-color: var(--white);
        color: black;
        padding: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 10px;
        position: relative;
        transition: 0.1s all linear;
        margin-bottom: 7px;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
        border: 4px solid transparent;
        width: calc(33% - 10px);
        max-width: 150px;
        height: 150px;
        position: relative;
    
        &:not(.UNAVAILABLE):hover {
            transform: scale(1.05);
        }
    
    }
    
    /*
    .table.selected {
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
        transform: translate(-2px, -2px);
        border: 1px solid rgba(0,0,0,0.3);
    }
    */
    
    .table.READY {
        background-color: rgba(var(--red-rgb),0.2);
        border-color: var(--red);
        box-shadow: 0px 10px 20px -10px rgba(var(--red-rgb),0.5);
    }
    .table.UNAVAILABLE {
        cursor: not-allowed;
        opacity: 0.4;
        box-shadow: none;
    }
    .table.JOINED {
        background-color: var(--gray-3);
        border-color: var(--gray-1);
        border-style: double;
    }
    
    .table .counter {
        color: var(--white);
        font-size: 10px;
        font-weight: bold;
        margin: 0px 2px;
    }
    
    .table.RESERVED {
        background-color: rgba(var(--blue-2-rgb),0.1);
        border-color: var(--blue-2);
        box-shadow: none;
        border-style: dashed;
    }
    .table.OPEN {
        background-color: rgba(var(--green-rgb),0.2);
        border-color: var(--green);
        box-shadow: 0px 10px 20px -10px rgba(var(--green-rgb),0.5);
        
    }
    .table.PENDING {
        background-color: rgba(var(--yellow-rgb),0.2);
        border-color: var(--yellow);
        box-shadow: 0px 10px 20px -10px rgba(var(--yellow-rgb),0.5);
    }
    .table.PARENT {
        border-style: double;
        border-width: 8px;
    }
    
    .table .label {
        font-size: 14px;
        font-weight: normal;
    }
    .child-tables {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 10px;
        font-weight: normal;
        border-top: 1px solid var(--gray-1);
        color: var(--black);
        padding: 5px;
    }
    
    .ticket-label {
        font-size: 12px;
        color: var(--black);
        background-color: rgba(255,255,255,0.8);
        padding: 3px 5px;
        border-radius: 5px;
        width: 100%;
        text-align: center;
    }
    .ticket-label.reverse {
        background-color: rgba(0, 0, 0,0.8);;
        color: var(--white);
    }
    
    .options .notif {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--purple);
        color: var(--white);
        position: absolute;
        right: -5px;
        bottom: -5px;
        font-size: 10px;
        font-weight: bold;
        padding: 1px 5px;
        border-radius: 10px;
        min-width: 25px;
        border: 3px solid white;
        z-index: 1;
    }
    
    
    @media (max-width: 820px) {
        .ticket-label {
            max-width: 140px;
        }
        #pending-main-container {
            padding-left: 0;
            padding-top: 56px;
        }
        #pending-main-container .left-menu {
            right: 0;
            width: 100%;
            height: 56px;
            bottom: unset;
            flex-direction: row;
            padding-top: 0px;
            align-items: center;
            padding-left: 10px;
            border-bottom: 1px solid var(--gray-2);
        }
        .ticket-row {
            margin: 0px 0px 7px 0px;
        }
        .ticket-row .ticket-handle {
            position: sticky;
            left: 0;
            z-index: 1;
        }
        .options .notif {
            top: 4px;
            bottom: unset;
        }
    
        .ticket-type {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            i {
                margin-bottom: 5px;
            }
        }
    
        .table-name {
            font-size: 14px;
        }
    
    }
    
    .dark {
        #pending-main-container .left-menu {
            background-color: var(--dark-bg-1);
            border-right: none;
        }
        .options .notif {
            background-color: var(--red);
            border: 3px solid var(--dark-bg-1);
        }
        .ticket-row {
            background-color: var(--dark-bg-1); 
        }
        .order-item.selectable:not(.ready):hover {
            background-color: var(--dark-bg-2);
            outline: 3px solid var(--dark-bg-2);
        }
        .ticket-table {
            background-color: var(--dark-bg-0);
            border-bottom: 1px solid var(--dark-outline);
        }
        .ticket-table:hover {
            background-color: var(--dark-bg-2);
        }
    
        .ticket-table.selected, .ticket-table.selected .ticket-handle {
            background-color: var(--dark-purple-2);
            box-shadow: 0 2px 0 var(--cyan) inset,
                        0px -2px 0 var(--cyan) inset;
            z-index: 1;
            * {
                border-color: var(--cyan);
            }
        }
        .transaction-ticket {
            background-color: var(--dark-bg-1);
        }
        .table {
            background-color: var(--dark-bg-1);
            color: var(--dark-fc);
        }
    
        .child-tables {
            color: var(--dark-fc-1);
        }

    }

    .pya-banner-ticket {
        display: flex;
        flex-direction: column;
        background-color: var(--color-pya);
        color: var(--white);
        font-size: 12px;
        padding: 3px 10px;
        text-align: center;
        i {
            transform: scale(1.3);
        }

        &.mini {
            font-size: 8px;
            padding: 0px 2px;
            border-radius: 5px;
            margin-left: 2px;
            position: absolute;
            right: 10px;
        }
    }
    
    </style>
    