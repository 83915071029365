<template>
<div class="flex-fill fill flex column row-desktop">

<!--
<div v-if="menu.length" class="ofy hide-mobile" style="min-width: 150px; max-width: 150px; font-size: 11px">
    <div class="section pa10">
        <div class="input-style selectable align-center"
        v-for="section in this.menu"
        :key="section.title"
        @click="$refs.itemsSelection.GoToSection(section.title)">{{section.title}}</div>
    </div>
</div>
-->

<items-selection class="ofy flex-fill"
ref="itemsSelection"
bodyClass="bgbg1 pl5 pr5"
:menu="menu"
:showSelected="false"
:showUnavailable="false"
:selectModifiers="true"
:callbackOnly="true"
@OnItemAdded="AddItem($event)"
:currentlySelected="selectedItemsID"
:disableUnavailable="true"
:favorite="customer && customer.items ? customer.items : null"
:canClickItems="canEditItems"
:overwrites="overwrites"
:timestamp="timestamp"
:inventory="inventory"
:typeSale="sale"
@OnSelectionClosed="currentEditItem=null">
    <!--
    <div class="section flex" v-if="desktop">
        <input-field class="flex-fill" ref="searchBox" v-model="search" @Enter="Search()" placeholder="Buscar...">
            <i class="icon icon-search c4" slot="pre"></i>
            <i v-if="search" class="icon icon-cross c4 selectable" slot="after" @click="search=''"></i>
        </input-field>
        <i v-if="desktop && $refs.itemsSelection" :class="`ml10 options icon-${$refs.itemsSelection.viewGrid?'list':'grid'}`" @click="$refs.itemsSelection.ToggleView()"></i>
    </div>
    -->
</items-selection>

<div class="bottom-info pa10"
v-if="$refs.order && mobile">
    <button @click="modalSaleData={}" class="primary purple f18 pa20">{{Util.Price($refs.order.total.totalMoney)}}</button>
</div>

<div v-if="editSettings" v-show="desktop || (mobile && modalSaleData)" class="ofy flex-fill pa10 bl" style="min-width: 320px; max-width: 320px;">
    <order-form
    ref="order"
    :showRut="showRut"
    :baseBenefits="promotions"
    :settings="editSettings"
    :categories="categories"
    :tables="availableTables"
    :fromTransaction="transaction"
    @TransactionSuccess="TransactionSuccess($event)"
    @AddItem="$refs.itemsSelection.SearchAddItem($event)"
    @OnEditItem="OpenEditItem($event)"
    @OnCustomerChanged="customer=$event"
    @OnSaleChanged="sale=$event"
    v-model="selectedItems"
    :hideActions="true"
    :hideSaleTypeButtons="transaction ? (transaction.status == 'DELIVERY' ? true : false) : false"
    :canEditItems="canEditItems"
    :editItemPricePOS="true"
    :canEditPayment="canEditPayment">
        <div slot="actions" v-if="transaction">
            <button :class="`primary purple w100`" @click="Confirm({
            text: 'Editar',
            title: '¿Editar pedido?',
            class: 'selected-color',
            input: 'Comentario',
            function: EditTransaction
        })">Editar Pedido</button>
        </div>
    </order-form>
</div>

<modal v-model="modalSaleData" dir="up" modalClass="fill borderless" title="Datos del Pedido" hideActions>
    <div ref="mobileOrder" class="pa10"></div>
</modal>

</div>
</template>

<script>
export default {
    props: {
        menu: {
            type: Array,
            default: () => []
        },
        promotions: {
            type: Array,
            default: () => []
        },
        settings: {
            type: Object,
            default: null
        },
        categories: {
            type: Object,
            default: null
        },
        tables: {
            type: [Object, Array],
            default: null
        },
        transaction: {
            type: Object,
            default: null,
        },
        overwrites: {
            type: Array,
            default: () => []
        },
        timestamp: {
            type: Number,
            default: null
        },
        inventory: {
            type: [Array, Object],
            default: null
        }
    },
    data() {
        return {
            selectedItems: {},
            modalSaleData: null,
            showDiscountsDetails: false,
            search: '',
            customer: null,
            editSettings: null,
            currentEditItem: null,
            sale: null,
            showRut : false
        }
    },
    mounted() {

        //Según la variable en LS, se asgina a showRut
        localStorage.getItem('rut') ? this.showRut = true : this.showRut = false; 

        if(this.transaction)
        {
            //Cargar datos de la transaccion en el componente order-form
            
            var settings = this.Util.Copy(this.settings);
            
            this.editSettings = settings;
            this.editSettings.delivery_rules = []
            this.sale = this.transaction.type;

            this.$nextTick(() => {
                let deliveryItem = null;
                this.LoadOrder(this.transaction.orders);
                this.transaction.orders.forEach(item => {
                    if(item.type == 'DELIVERY')
                    { 
                        this.$refs.order.deliveryCost = item.price;
                        this.$refs.order.deliveryPaymentMethod = item.method;
                        deliveryItem = item;
                        if(item.external) this.$refs.order.isExternalDelivery = true;
                    }
                });
                if(this.transaction.comment) this.$refs.order.comment = this.transaction.comment;
                this.$refs.order.customer = Array.isArray(this.transaction.customer) ? {} : this.transaction.customer;
                this.$refs.order.table = this.transaction.table_name ? this.transaction.table_name : null;
                this.$refs.order.takeaway = this.transaction.custom_data.activation_time ? this.transaction.custom_data.activation_time : '';
                // this.$refs.order.takeaway = this.transaction.custom_data.activation_time ? this.transaction.custom_data.activation_time : (this.transaction.custom_data.is_shipment_takeaway && this.transaction.custom_data.shipment && this.transaction.custom_data.shipment.shipment_start_takeaway? this.Util.Date(this.transaction.custom_data.shipment.shipment_start_takeaway, 'DD/MM/YYY') : '');
                if(this.transaction.custom_data &&  this.transaction.custom_data.delivery_time) this.$refs.order.deliveryTime = this.transaction.custom_data.delivery_time;
                
                if(this.transaction.customer.rut) this.transaction.customer.rut = Util.Rut(this.transaction.customer.rut)
                
                if(this.transaction.id_user)
                {
                    this.$refs.order.customerSearch = 'ID:'+this.transaction.id_user;
                    this.$refs.order.SearchCustomer();
                }
                if(this.transaction.custom_data && this.transaction.custom_data.tips) this.$refs.order.tips = this.transaction.custom_data.tips;
                if(this.transaction.custom_data && (this.transaction.custom_data.shipment && Object.keys(this.transaction.custom_data.shipment).length)) //Se añade validación lenght dado que entraba cuando tenía shipment pero éste estaba vacío
                {
                    this.$refs.order.wasShipment = this.transaction.custom_data.shipment.time ? 'DATETIME' : 'DATE'; //¿Diferencia entre activation_time y time?
                    if(this.transaction.custom_data.shipment.shipment_start) this.$refs.order.schedule = this.transaction.custom_data.shipment.shipment_start;
                    else if(this.transaction.custom_data.shipment.shipment_start_takeaway) this.$refs.order.schedule = this.transaction.custom_data.shipment.shipment_start_takeaway;
                    else this.$refs.order.schedule = this.timestamp;
                }else if(this.transaction.custom_data && this.transaction.custom_data.scheduled_at){ //Esta validación se añadió principalmente porque le envía el schedule sólo si es encargo y como tal, todos los pedidos pueden tener horario programado
                    this.$refs.order.schedule = this.transaction.custom_data.scheduled_at;
                }

                //ver descuento ingresado en caja
                for(var i = 0; i < this.transaction.discounts.length; i++)
                {
                    var discount = this.transaction.discounts[i];
                    if(discount.pos_discount)
                    {
                        this.$refs.order.customDiscount = discount.pos_discount;
                    }
                    else if(discount.title == "Dcto ingresado en caja")
                    {
                        this.$refs.order.SetCustomDiscount(discount.discount);
                        return;
                    }
                    else if(discount.id_coupon_code)
                    {
                        axios.get(`/coupon/code/${discount.id_coupon_code}`).then(res => {
                            this.$refs.order.coupon = res.data;
                        }).catch(err => {
                            console.log(err);
                        });
                    }
                }

                let payments = this.Util.Copy(this.transaction.payment);
                //Si tiene n_transfer, se agrega la propiedad para que muestre n_transferr en el orderform al editar
                payments.forEach((payment) => {
                    if(payment.n_transfer) {
                        payment.showBtnNTransfer = true
                    }
                });
                //un delay para los metodos de pago debido al nextTick en order-form
                setTimeout(() => {
                    this.$refs.order.paymentMethods = [];
                    this.$refs.order.sale = this.transaction.type;
                    this.$nextTick(() => {
                        this.$refs.order.paymentMethods = payments;
                        let deliveryMethod = deliveryItem && deliveryItem.method ? deliveryItem.method : (this.transaction.payment.length ? this.transaction.payment[0].method : null);
                        this.$refs.order.deliveryPaymentMethod = deliveryMethod;
                        this.$refs.order.settings.no_payment_select = false
                    });
                }, 10);

            });
        }
        else
        {
            this.editSettings = this.Util.Copy(this.settings);
        }
    },
    methods: {
        AddItem(item)
        {
            this.$delete(item, 'scanned');
            if(this.currentEditItem)
            {
                this.$delete(this.selectedItems, this.currentEditItem.key);
                this.currentEditItem = null;
            }


            this.$nextTick(() => {
                var key = item.key;
                if(this.selectedItems[key])
                {
                    var itemData = item;
                    let quantity = item.quantity;

                    if(item.max_quantity_enabled){
                        if(quantity > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                        if(this.orderGroupById && quantity + this.orderGroupById[item.id] > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                    }

                    itemData.quantity += this.selectedItems[key].quantity;
                    itemData.total_money += this.selectedItems[key].total_money;
                    this.$set(this.selectedItems, key, itemData)
                }
                else
                {
                    if(item.max_quantity_enabled && this.orderGroupById && item.quantity + this.orderGroupById[item.id] > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                    this.$set(this.selectedItems, key, item);
                }
            })

        },
        LoadOrder(order) 
        {
            for(var i = 0; i < order.length; i++)
            {
                //this.$refs.itemsSelection.SearchAddItem(order[i], false, true);
                this.$refs.itemsSelection.PushItem(order[i], `order-${i+1}`);
            }
            
        },
        Search() 
        {
            var search = this.search;
            this.search='';
            this.$refs.itemsSelection.ClickFirst();
        },
        EditTransaction(comment)
        {
            var edit = this.$refs.order.GetOrder();
            if(edit)
            {
                this.transaction.isLocalEdit = true;
                edit.edit_comment = comment;
                edit.edit_at = this.transaction.edit_at;
                this.Loading();
                axios.patch(`/transaction/${this.transaction.id}/edit`, edit).then(res => {
                    this.Response(res);
                    console.log('emit on transaction edit');
                    this.$emit('OnTransactionEdit', res.data);
                }).catch(err => {
                    this.Response(err);
                    var transaction = err.response.data.transaction;
                    if(transaction)
                    {
                        this.$emit('OnOutOfDateTransaction', transaction);
                    }
                });
            }
            
        },
        OpenEditItem(item)
        {
            this.$refs.itemsSelection.SearchAddItem(item, true);
            this.currentEditItem = item;
        }
    },
    computed: {
        canEditItems() {
            if(!this.transaction) return true;
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_transactions);
        },
        canEditPayment() {
            if(!this.transaction) return true;
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_transactions_payment);
        },
        selectedItemsID() {
            var ret = {};
            for(var key in this.selectedItems)
            {
                var id = this.selectedItems[key].id;
                if(ret[id]) ret[id] += this.selectedItems[key].quantity;
                else ret[id] = this.selectedItems[key].quantity;
            }
            return ret;
        },
        availableTables() {
            var ret = [{text: 'Sin seleccionar', value: null}];
            if(!this.tables) return ret;
            
            for(var key in this.tables)
            {
                var table = this.tables[key];
                if(table.available && !table.id_transaction && !table.reserved)
                {
                    ret.push({
                        text: table.name,
                        value: table.name
                    });
                }
            }

            return ret;
        },
        orderGroupById(){
            if(!this.selectedItems || !Object.keys(this.selectedItems).length) return;
            const groupedData = {};

            for(let index in this.selectedItems){
                if(this.selectedItems.hasOwnProperty(index)){
                    const tmpItem = this.selectedItems[index];
                    const id = tmpItem.id;
                    const quantity = tmpItem.quantity;

                    if (!groupedData[id]) {
                        groupedData[id] = 0;
                    }
                    
                    groupedData[id] += quantity;
                }
            }
            return groupedData;
        }
    },
    watch: {
        modalSaleData: function(n, o) {
            if(n) 
            {
                this.$nextTick(() => {
                    this.$refs.mobileOrder.appendChild(this.$refs.order.$el);
                    this.$refs.order.SetupScrollListener();
                });
            }
        },
        search: function(n, o) {
            if(n.length == 4 && !isNaN(parseInt(n)))
            {  
                var section = parseInt(n.substring(0, 2))-1;
                var item = parseInt(n.substring(2, 4))-1;
                if(section >= 0 && item >= 0 && section < this.menu.length)
                {
                    if(item < this.menu[section].items.length)
                    {
                        this.$refs.itemsSelection.Search('*'+this.menu[section].items[item].id);
                    }
                }
            }
            else
                this.$refs.itemsSelection.Search(n);
        }
    }
}
</script>

<style>

</style>
