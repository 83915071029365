<template>
<div>

<modal v-model="modal" dir="right" :modalClass="['fill', 'lg right h100']" :title="view == 'SCHEDULED' ? 'Pedidos Agendados' : 'Pedidos Online'" hideActions>
    <i v-if="canEditIAPSettings && view == 'ONLINE'" class="options icon-cog" slot="options" @click="OpenSettings()" title="Ajustes de Pedidos Online"></i>
    <div class="pa20" @mousemove="view == 'ONLINE' ? Checked() : null" @click="view == 'ONLINE' ? Checked() : null">
        <div class="secondary-text mt10 ta-center" v-if="!(view == 'SCHEDULED' ? state.scheduled : state.pending).length">No hay pedidos</div>
        <div :class="`${ticket.status}-ticket ticket-row mb10`"
        v-for="(ticket, index) in (view == 'SCHEDULED' ? state.scheduled : state.pending)"
        :key="index">
            <div class="flex column">
               
                <div :style="{'background-color': OrderColor(ticket)}" :class="`flex align-center cw pa10`" style="border-radius: 10px 10px 0px 0px">

                    <div class="ticket-label mr5" v-if="ticket.status == 'WAITING'" style="width:auto">Confirmación</div>
                    <div class="ticket-label mr5" v-if="ticket.status == 'WAITING_PAYMENT'" style="width:auto">Pago online</div>
                    <div class="ticket-label mr5" v-if="ticket.status == 'WAITING_BANK_DEPOSIT'" style="width:auto">Transferencia <i v-if="ticket.custom_data.bank_deposit_at" class="icon icon-check"></i></div>
                    <div class="ticket-label mr5" v-if="view !== 'SCHEDULED' && ticket.status == 'SCHEDULED'" style="width:auto">Agendado</div>
                    <div class="ticket-label reverse mr5" v-if="ticket.status == 'ACCEPTED'" style="width:auto">Ingresado</div>

                    <div v-if="ticket.takeaway_time && ticket.takeaway_time !== 'SCHEDULE' && !ticket.scheduled_at">{{ticket.takeaway_time == 'AUTO' ? 'Lo antes posible' : (ticket.takeaway_time + ' hrs')}}</div>
                    <div v-if="ticket.scheduled_at" class="flex align-center"><i class="icon icon-datetime mr5"></i> {{Util.Date(ticket.scheduled_at, 'ddd DD [de] MMM  HH:mm')}}</div>
                    <div v-if="ticket.custom_data && ticket.custom_data.shipment">{{Util.Date(ticket.custom_data.shipment.shipment_start, 'ddd DD [de] MMM')}} <span v-if="ticket.custom_data.shipment.shipment_end">- {{Util.Date(ticket.custom_data.shipment.shipment_end, 'ddd DD [de] MMM')}}</span></div>

                    <div class="flex-fill"></div>

                    <div class="flex align-center">
                        
                        <div class="align-center" style="flex-direction: column-reverse" v-html="TicketType(ticket)"></div>
                        <i :class="`ml5 icon icon-${Util.TransactionSource[ticket.source].icon}`"></i>
                    </div>

                </div>

                <div :class="`flex ${mobile ? 'column' : ''}`">
                    <div :class="`pa10 selectable ${mobile ? 'bb' : 'w35 br'}`" @click="CustomerOptions(ticket)">
                        <div class="fill flex column relative">
                            <div><span class="bold">Recibido:</span> {{Util.Date(ticket.created_at, 'HH:mm')}} hrs</div>                            
                            <div v-if="ticket.customer_name">{{ticket.customer_name}}</div>
                            <div v-if="ticket.phone">{{ticket.phone}}</div>
                            <div v-if="ticket.address && ticket.type == 'DELIVERY'" class="lh1">
                                <div><span>{{Util.Address(ticket.address, ticket.address_comment)}}</span></div>
                                <div v-if="ticket.custom_data.input_address" class="secondary-text">{{ticket.custom_data.input_address}}</div>
                            </div>
                            <i v-if="ticket.phone || ticket.address" class="icon icon-dots-vertical absolute" style="right: 0; top: 0"></i>
                        </div>
                    </div>

                    <div :class="`relative ${mobile ? 'bb' : 'w40 br'}`">
                        <div class="fill flex column">
                            <div :class="`order-item pa5`"
                            v-for="(order, index2) in ticket.orders"
                            :key="index2"
                            style="padding-left: 10px">
                                <div>{{Util.Number(order.quantity)}}</div>
                                <div>{{order.title}}
                                    <ul class="secondary-text">
                                        <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(order)" :key="indexMod">{{modifier.value}}</li>
                                        <!-- <li class="order-comment" v-if="order.comment">{{order.comment}}</li> -->
                                    </ul>
                                </div>
                                <!--<div>{{Util.Price(order.total_money)}}</div>-->
                            </div>

                            <div class="secondary-text pa10" v-if="ticket.comment">{{ticket.comment}}</div>
                        </div>
                    </div>
                    
                    <div :class="mobile ? 'bb' : 'w25 br'">
                        <div :class="`pa10 flex column ${ticket.status == 'SCHEDULED' && !ticket.payment_at ? 'selectable' : ''}`"
                        @click="ticket.status == 'SCHEDULED' && !ticket.payment_at ? Paid(ticket) : null">
                            <div class="flex">
                                <div class="flex-fill">Subtotal</div>
                                <div>{{Util.Price(ticket.total.order)}}</div>
                            </div>
                            <div class="flex" v-if="ticket.total.round">
                                <div class="flex-fill">Ley del Redondeo</div>
                                <div>{{Util.Price(ticket.total.round)}}</div>
                            </div>
                            <div class="flex" v-if="ticket.type != 'TAKEAWAY'">
                                <div class="flex-fill">Envío</div>
                                <div>{{Util.Price(ticket.total.delivery)}}</div>
                            </div>
                            <div class="flex" v-if="ticket.total.discount">
                                <div class="flex-fill">Descuento</div>
                                <div>{{Util.Price(ticket.total.discount)}}</div>
                            </div>
                            <div class="flex" v-if="ticket.total.tip">
                                <div class="flex-fill">Propina</div>
                                <div>{{Util.Price(ticket.total.tip)}}</div>
                            </div>
                            <div :class="`flex bold ${ticket.payment_at ? 'cgreen' : ''}`">
                                <!--<div class="flex-fill">Total <i class="icon icon-check" v-if="ticket.payment_at"></i></div>-->
                                <div class="flex-fill">Total</div>
                                <div>{{Util.Price(ticket.total.total)}}</div>
                            </div>
                            <div class="mt5">{{Util.PayMethod(ticket.payment_method)}}</div>
                        </div>
                        
                    </div>

                    
                    <div :class="`flex ${desktop ? 'column' : ''} align-center flex-fill`">
                        <div class="flex-fill" v-if="mobile"></div>
                        <i v-if="ticket.status == 'PENDING' && canCreateTransactions" class="options icon-check marginless" @click="Accept(ticket)" title="Aceptar pedido"></i>
                        <i v-if="ticket.status == 'PENDING'" class="options icon-cross marginless" @click="Reject(ticket)" title="Rechazar pedido"></i>
                        <i v-if="ticket.status == 'ACCEPTED'" class="options icon-check marginless" @click="HideAccepted(ticket)" title="Quitar de la lista e imprimir voucher"></i>
                        <i v-if="ticket.status == 'WAITING_BANK_DEPOSIT' && canCreateTransactions" class="options icon-check marginless" @click="ConfirmBankDeposit(ticket)" title="Confirmar transferencia bancaria"></i>
                        <i v-if="ticket.status == 'WAITING_BANK_DEPOSIT'" class="options icon-cross marginless" @click="CancelScheduled(ticket)" title="Cancelar pedido"></i>
                        <i v-if="ticket.status == 'SCHEDULED' && canCreateTransactions" class="options icon-check marginless" @click="ConfirmScheduled(ticket)" title="Ingresar pedido agendado"></i>
                        <i v-if="ticket.status == 'SCHEDULED'" class="options icon-cross marginless" @click="CancelScheduled(ticket)" title="Cancelar pedido"></i>
                        <i v-if="ticket.status == 'WAITING_PAYMENT'" class="options icon-cross marginless" @click="CancelScheduled(ticket)" title="Cancelar pedido"></i>
                        <i class="options icon-dots-vertical" v-if="ticket.status == 'SCHEDULED' && !ticket.payment_at" @click="Options([
                            {text: 'Marcar como pagado', function: () => {Paid(ticket)}}
                        ])"></i>
                    </div>
                </div>
            </div>

        </div>
    </div>
</modal>

<modal v-model="modalAccept" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Aceptar Pedido">
    <div class="pa20" v-if="modalAccept">

        <div>

            <div v-if="modalAccept.iap.takeaway_time !== 'SCHEDULE'">
                <div class="bold">Tiempo</div>

                <!--
                <div v-if="modalAccept.iap.custom_data.pya_courier_delivery">
                    <div class="secondary-text">El cliente espera poder recibir el pedido aproximadamente a las <span class="bold">{{Util.Date(modalAccept.iap.custom_data.pya_courier_delivery, 'HH:mm')}} hrs</span></div>
                    <input-select class="input-top mt10"
                    v-model="modalAccept.time"
                    :items="arriveTime">
                    </input-select>

                    <input-field v-if="modalAccept.time === 'CUSTOM'" :class="iapCustomTime ? 'input-middle' : 'input-bottom'"
                    v-model="modalAccept.custom"
                    placeholder="Hora o Tiempo"
                    info="Indica la hora o tiempo en que estará listo el pedido, ejemplo:<br><ul><li>11:30, 11.30, 11 30 (en formato 24 horas por lo que equivale a las 11:30 AM)</li><li>5pm, 17, 5 30 pm</li><li>40 min, 40m, 1 hora, 1h (el pedido será retirado en 40 minutos o 1 hora)</li><li>1 hora 20 min, 1h 20m, 1 hora 20, 1h 20, en 1 20 (el pedido será retirado en 1 hora 20 minutos)</li></ul>">
                    </input-field>

                    <input-select v-if="iapCustomTime" class="input-bottom"
                    v-model="modalAccept.range"
                    :items="timeRangeOptions">
                    </input-select>
                        
                </div>
                <div v-else>
                    -->
                    <div class="flex column" v-if="!modalAccept.autocomplete || modalAccept.iap.type !== 'TAKEAWAY'">
                    
                        <div class="secondary-text" v-if="modalAccept.iap.type != 'SHIPMENT'">
                            <div class="" v-if="modalAccept.iap.takeaway_time == 'AUTO'">El cliente espera poder {{modalAccept.iap.type == 'TAKEAWAY' ? 'retirar' : 'recibir'}} el pedido <span class="bold">lo antes posible</span>.</div>
                            <div class="" v-else>El cliente espera poder {{modalAccept.iap.type == 'TAKEAWAY' ? 'retirar' : 'recibir'}} el pedido aproximadamente a las <span class="bold">{{modalAccept.iap.takeaway_time}} hrs</span>.</div>
                        </div>
                        
                        
                        <input-select class="input-top mt10"
                        v-model="modalAccept.time"
                        :items="arriveTime">
                        </input-select>
                        
                        <input-field v-if="modalAccept.time === 'CUSTOM'" :class="iapCustomTime ? 'input-middle' : 'input-bottom'"
                        v-model="modalAccept.custom"
                        placeholder="Hora o Tiempo"
                        info="Indica la hora o tiempo en que estará listo el pedido, ejemplo:<br><ul><li>11:30, 11.30, 11 30 (en formato 24 horas por lo que equivale a las 11:30 AM)</li><li>5pm, 17, 5 30 pm</li><li>40 min, 40m, 1 hora, 1h (el pedido será retirado en 40 minutos o 1 hora)</li><li>1 hora 20 min, 1h 20m, 1 hora 20, 1h 20, en 1 20 (el pedido será retirado en 1 hora 20 minutos)</li></ul>">
                        </input-field>
                        

                        <input-select v-if="iapCustomTime" class="input-middle"
                        v-model="modalAccept.range"
                        :items="timeRangeOptions">
                        </input-select>

                        <div class="well mini bottom border secondary-text" v-if="modalAccept.iap.type == 'DELIVERY' && iapCustomTime">El pedido le llegará al cliente aproximadamente a las <span class="bold">{{iapCustomTime}} hrs</span></div>
                        <div class="well mini bottom border secondary-text" v-if="modalAccept.iap.type == 'TAKEAWAY' && iapCustomTime">El pedido estará disponible para retirar en el local aproximadamente a las <span class="bold">{{iapCustomTime}} hrs</span></div>

                    </div>

                    <div class="flex column" v-else>
                        <div class="secondary-text">Este pedido está listo para retirar</div>
                    </div>
                <!--</div>-->

            </div>

            <div v-else>
                <div class="bold">Fecha Programada</div>
                <div>Pedido programado para el {{Util.Date(modalAccept.iap.scheduled_at, 'D [de] MMMM [a las] HH:mm [hrs]')}}</div>
            </div>
        </div>

        <div v-if="(Auth.role.indexOf('admin') > -1 || Auth.restrictions.apply_discount)" class="mt15">
            
            <input-field :class="`${Util.DiscountString(modalAccept.discount, modalAccept.iap.total.total) ? 'input-top' : ''}`"
            v-model="modalAccept.discount"
            label="Descuento"
            description="Opcionalmente puedes agregar un descuento adicional sobre el total del pedido."
            placeholder="(Opcional)"
            info="<ul><li>-500 aplicará 500 pesos de descuento sobre el total</li><li>500 aplicará 500 pesos de descuento sobre el total</li><li>50% aplicará un 50% de descuento sobre el total</li><li>50x aplicará 50% de descuento sobre el total</li></ul>">
                <div class="append unshrink" slot="after" v-if="Util.DiscountString(modalAccept.discount, modalAccept.iap.total.total)"><span class="bold mr5">Descuento </span>{{Util.Price(Util.DiscountString(modalAccept.discount, modalAccept.iap.total.total).discount)}}</div>
            </input-field>

            <div class="well mini border bottom flex align-center secondary-text" v-if="Util.DiscountString(modalAccept.discount, modalAccept.iap.total.total)">
                <span class="bold mr5">Total anterior</span> 
                <span>{{Util.Price(modalAccept.iap.total.total)}}</span> 
                <i class="icon icon-arrow-right ml10 mr10 secondary-text"></i> 
                <span class="bold mr5">Nuevo total</span>
                <span>{{Util.Price(Util.DiscountString(modalAccept.discount, modalAccept.iap.total.total).total)}}</span>
            </div>

            <!--
            <div class="flex column mt10" v-if="Util.DiscountString(modalAccept.discount, modalAccept.iap.total.total)">
                <div class="flex align-center">
                    <div class="input-style flex-fill">
                        <div class="input-label">Total Anterior</div>
                        <div>{{Util.Price(modalAccept.iap.total.total)}}</div>
                    </div>
                    <i class="icon icon-arrow-right ml10 mr10"></i>
                    <div class="input-style flex-fill">
                        <div class="input-label">Nuevo Total</div>
                        <div>{{Util.Price(Util.DiscountString(modalAccept.discount, modalAccept.iap.total.total).total)}}</div>
                    </div>
                </div>
            </div>
            -->

        </div>
        
    </div>

    <!--
    <div class="flex column mb10" v-if="modalAccept && modalAccept.iap.takeaway_time !== 'SCHEDULE' && (!modalAccept.autocomplete || modalAccept.iap.type !== 'TAKEAWAY')">
        <div class="secondary-text ta-center mb10" v-if="modalAccept.time===0">¡Si no especificas el tiempo el cliente asumirá que el pedido llegará lo antes posible!</div>
        <div class="secondary-text ta-center mb10" v-if="modalAccept.time !== 0 && !(modalAccept.iap.type == 'TAKEAWAY' && modalAccept.iap.takeaway_time == iapCustomTime)">Al pedirle confirmación al cliente el pedido quedará pendiente hasta que el cliente acepte.</div>
    </div>
    -->

    
    <button slot="actions" v-if="modalAccept && modalAccept.iap.takeaway_time == 'SCHEDULE'" @click="AcceptIAP()" class="primary purple">Aceptar Pedido Programado</button>
    <button slot="actions" v-if="modalAccept && modalAccept.iap.takeaway_time != 'SCHEDULE' && !(modalAccept.time === 0 || (modalAccept.iap.takeaway_time == iapCustomTime)) && !(modalAccept.autocomplete && modalAccept.iap.type == 'TAKEAWAY')" @click="Confirm({
        title: '¿Pedirle al cliente que acepte la hora ingresada? Al pedirle confirmación al cliente el pedido quedará pendiente hasta que el cliente acepte.',
        text: 'Pedir Confirmación',
        class: 'selected-color',
        function: () => {AcceptIAP()}
    })" class="primary purple">Pedir Confirmación</button>
    <button slot="actions" v-if="modalAccept && modalAccept.iap.takeaway_time != 'SCHEDULE'" @click="Confirm({
        title: modalAccept.autocomplete && modalAccept.iap.type == 'TAKEAWAY' ? '¿Aceptar pedido listo para retirar?' : '¿Aceptar pedido sin pedirle confirmación al cliente?',
        text: 'Aceptar',
        class: 'selected-color',
        function: () => {AcceptIAP(true)}
    })" class="primary purple">Aceptar Pedido</button>
    


</modal>

<modal v-model="modalRejectMessage" dir="center" modalClass="sm" title="Rechazar Pedido">

    <div class="section" v-if="modalRejectMessage">
        <input-field class="mb10"
        v-model="modalRejectMessage.message"
        label="Mensaje"
        :lines="3"
        :description="`Escribe un mensaje explicando porque el pedido de ${modalRejectMessage.transaction.customer_name} fue rechazado. (mínimo 10 caracteres, máximo 150 caracteres)`">
        </input-field>
        <div class="well mini">
            <div class="secondary-text">Caracteres: {{modalRejectMessage.message.length}}</div>
        </div>
    </div>

    <!--<button @click="modalRejectMessage = null">Cancelar</button>-->
    <button class="primary error-color" slot="actions" v-if="modalRejectMessage"
    :disabled="modalRejectMessage.message.length < 10 || modalRejectMessage.message.length > 150" 
    @click="RejectIAP(modalRejectMessage.transaction, modalRejectMessage.message);">Rechazar Pedido</button>

</modal>

<transaction-details ref="transactionDetails"></transaction-details>

<!-- ventana de alerta -->
<transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut faster">
<div v-if="desktop && !inFocus && playBell && screenNotification" class="absolute fill bgbg1" style="left: 0; top: 0; z-index: 200">
    <div class="fill flex justify-center align-center alert-screen bgbg1">
        <div class="flex justify-center align-center animated pulse infinite bgbg1" style="width: 320px; height: 320px; border-radius: 100%">
            <i class="icon icon-attention-bell animated heartBeat infinite cpurple" style="font-size: 170px;"></i>
        </div>
    </div>
</div>
</transition>

<iap-quick-settings ref="iapQuickSettings" @IAPSoundEnabled="soundNotification = $event"></iap-quick-settings>

<!-- modal para editar fecha de programacion -->
<modal v-model="modalEditScheduled" dir="center" modalClass="sm" title="Editar Pedido">
    <div class="section" v-if="modalEditScheduled">
        <input-date
        v-model="modalEditScheduled.scheduled_at"
        label="Fecha de programación"
        format="timestamp">
        </input-date>
    </div>

    <button class="primary" slot="actions"
    @click="EditScheduled()">Editar Pedido</button>

</modal>

</div>
</template>

<script>
export default {
    props: {
        screenNotification: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            modal: false,
            influyeTransactions: [],
            bell: null,
            playBell: false,
            bellInterval: null,
            modalAccept: null,
            lastInfluyeTransactionStatus: {},
            soundNotification: true,
            modalRejectMessage: null,
            //variables para determinar si esta en foco para desplegar una alerta de iap
            timerFocus: null,
            inFocus: true,
            //timer para actualizar las IAP si es que se perdio la conexión al ws
            timerUpdate: null,
            /* settingsDeliveryPya: null, Era la variable que validaba sí la opción de pedidos ya estaba activada o no. (se le asigna en this.getSettingsDeliveryPya) */
            view: 'ONLINE',
            modalEditScheduled: null,
            timeRangeOptions: [
                {text: 'Sin rango de tiempo', value: null},
                {text: 'rango 10 minutos', value: 10},
                {text: 'rango 20 minutos', value: 20},
                {text: 'rango 30 minutos', value: 30},
                {text: 'rango 40 minutos', value: 40},
                {text: 'rango 50 minutos', value: 50},
                {text: 'rango 1 hora', value: 60},
                {text: 'rango 1 hora 10 minutos', value: 70},
                {text: 'rango 1 hora 20 minutos', value: 80},
                {text: 'rango 1 hora 30 minutos', value: 90},
                {text: 'rango 1 hora 40 minutos', value: 100},
                {text: 'rango 1 hora 50 minutos', value: 110},
                {text: 'rango 2 horas', value: 120},
                {text: 'rango 2 horas 10 minutos', value: 130},
                {text: 'rango 2 horas 20 minutos', value: 140},
                {text: 'rango 2 horas 30 minutos', value: 150},
                {text: 'rango 2 horas 40 minutos', value: 160},
                {text: 'rango 2 horas 50 minutos', value: 170},
                {text: 'rango 3 horas', value: 180},
            ],
        }
    },
    mounted() {

        this.soundNotification = !!localStorage.iapSoundEnabled;

        this.UpdateInfluyeTransactions();

        this.bell = new Audio(require('../../assets/bell.mp4'));
        this.bellInterval = setInterval(() => {
            if(!this.playBell || !this.influyeTransactions.length || !this.soundNotification) return;
            this.bell.pause();
            this.bell.currentTime = 0;
            this.bell.play();
        }, 1500);

        
        //actualizar iap si no esta conectado a ws cada 10 segundos
        this.timerUpdate = setInterval(() => {
            this.UpdateInfluyeTransactions(true);
        }, 10000);

        this.Focus();
        
        document.body.addEventListener('mousemove', this.Focus);
        document.body.addEventListener('touchstart', this.Focus);

    },
    methods: {
        Open(view) {
            if(!view) view = 'ONLINE';
            this.view = view;
            this.modal = true;
            if(view == 'ONLINE')
                this.playBell = false;
        },
        Accept(iap)
        {
            console.log(iap);
            this.playBell=false; 

            var autocomplete = true;
            for(var key in iap.orders)
            {
                var order = iap.orders[key];
                if(!order.autocomplete)
                {
                    autocomplete = false;
                    break;
                }
            }

            var data = {
                iap: iap, 
                time: 'CUSTOM', 
                custom: iap.takeaway_time !== 'AUTO' ? iap.takeaway_time : '', 
                discount: '',
                autocomplete: autocomplete
            };

            /*
            if(iap.custom_data.pya_courier_delivery && iap.takeaway_time == 'AUTO')
            {
                data.custom = '1h';
            }

            if(data.iap.custom_data.pya_courier_delivery) data.custom = Util.Date(data.iap.custom_data.pya_courier_delivery, 'HH:mm');
            */

            this.modalAccept = data;
        },
        Reject(iap)
        {
            this.playBell=false; 
            this.Options([
                {text: 'Escribir mensaje', function: () => {this.modalRejectMessage = {transaction: iap, message: ''} } },
                {text: 'No podemos completar el pedido', function: () => {this.RejectIAP(iap, 'CANT_COMPLETE')}, class: 'error-color'},
                {text: 'Temporalmente sin delivery', function: () => {this.RejectIAP(iap, 'NO_DELIVERY')}, class: 'error-color', if: iap.type == 'DELIVERY'},
                {text: 'Pronto a cerrar', function: () => {this.RejectIAP(iap, 'CLOSE_SOON')}, class: 'error-color'},
                {text: 'Alta demanda', function: () => {this.RejectIAP(iap, 'BUSY')}, class: 'error-color'},
                {text: 'Pedido duplicado', function: () => {this.RejectIAP(iap, 'DUPLICATED')}, class: 'error-color'},
            ]);
        },
        UpdateInfluyeTransactions(offlineOnly)
        {
            if(offlineOnly && this.$socket.connected) return;

            axios.get(`/transactions/influye/${this.Local.id}`).then(res => {
                this.influyeTransactions = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
        AcceptIAP(skipConfirmation)
        {
            if(!this.iapCustomTime) return this.Info(`Tiempo inválido`);

            var discount = this.Util.DiscountString(this.modalAccept.discount, this.modalAccept.iap.total.total);
            var data = {
                time: this.iapCustomTime, 
                discount: discount ? discount.discount : null, 
                skipConfirmation: !!skipConfirmation
            };

            this.Loading();
            axios.post(`/transaction/influye/accept/${this.modalAccept.iap.id}`, data).then(res => {
                if(res.data.error) return this.Info(`Ha ocurrido un error (${res.data.reason})`);
                this.modalAccept = null;
                if(res.data.confirmation) return this.Info(`Se le ha solicitado la confirmación al usuario. ${res.data.new_cost_delivery? 'El valor del envío cambió a '+this.Util.Price(res.data.new_cost_delivery):''}`);
                this.Response(res);
                this.TransactionSuccess(res.data);
                this.$refs.menuInfluyeTransactions.Close();
                this.UpdateInfluyeTransactions(true);
                
            }).catch(err => {
                this.Response(err);
            });
        },
        RejectIAP(transaction, reason)
        {
            this.Loading();
            axios.post(`/transaction/influye/reject/${transaction.id}`, {reason: reason}).then(res => {
                this.Response(res);
                this.modalRejectMessage = null;
                this.UpdateInfluyeTransactions(true);
            }).catch(err => {
                this.Response(err);
            });
        },
        TransactionDetails(id)
        {
            console.log(id);
            this.$refs.transactionDetails.Open(id);
        },
        HideAccepted(transaction)
        {
            this.playBell = false;
            this.Loading();
            axios.get(`/transaction/influye/hide/${transaction.id}`).then(res => {
                this.TransactionSuccess(res.data);
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        ConfirmBankDeposit(iap)
        {
            this.Confirm({
                title: `¿Confirmar que has recibido la transferencia bancaria de ${Util.Price(iap.total.total)} por parte de ${iap.customer_name}?`,
                text: 'Confirmar Transferencia',
                class: 'selected-color',
                function: () => {
                    this.Loading();
                    axios.get(`/transaction/deposit/accept/${iap.id}`).then(res => {
                        this.TransactionSuccess(res.data);
                        this.Response(res);
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            })
            
        },
        ConfirmScheduled(transaction)
        {
            var msg = transaction.source == 'INFLUYE_ADMIN' ? '' : ' Se le notificará al cliente que el pedido se esta preparando.';
            this.Confirm({
                title: '¿Ingresar pedido a Pedidos Pendientes?'+msg,
                text: 'Agregar a Pedidos Pendientes',
                class: 'selected-color',
                function: () => {
                    this.playBell = false;
                    this.Loading();
                    axios.get(`/transaction/scheduled/confirm/${transaction.id}`).then(res => {
                        this.TransactionSuccess(res.data);
                        this.Response(res);
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            })
        },
        CancelScheduled(transaction)
        {
            var msg = transaction.source == 'INFLUYE_ADMIN' ? '' : ' Se le notificará al cliente que el pedido fue cancelado. En caso de que el pedido haya sido pagado deberás gestionar el reembolso al cliente.';
            this.Options([
                {text: 'Escribir mensaje', function: () => {this.modalRejectMessage = {transaction: transaction, message: ''} } },
                {text: 'Pedido duplicado', class: 'error-color', function: () => {this.RejectIAP(transaction, 'DUPLICATED')}, confirm: '¿Cancelar un pedido programado?'+msg},
                {text: 'Cancelado por el cliente', class: 'error-color', function: () => {this.RejectIAP(transaction, 'Cancelado por el cliente')}, confirm: '¿Cancelar un pedido programado?'+msg},
                {text: 'No podremos entregar el pedido', class: 'error-color', function: () => {this.RejectIAP(transaction, 'No podremos entregar el pedido')}, confirm: '¿Cancelar un pedido programado?'+msg},
                
            ]);
        },
        Focus()
        {
            if(this.mobile) return;

            this.inFocus = true;
            clearTimeout(this.timerFocus);
            this.timerFocus = setTimeout(this.LostFocus, 30000);
        },
        LostFocus() 
        {
            this.inFocus = false;
        },
        Checked()
        {
            this.playBell = false;
        },

        SendDepositData(phone)
        {
            this.Loading();
			axios.get(`/local/${this.Local.id}/bank`).then(res => {
				this.Response(res);
				this.Util.OpenWhatsapp(phone, `${res.data.name}\n${res.data.rut}\n${res.data.bank}\n${res.data.account}\n${res.data.account_number}\n${res.data.email}`);
				console.log(res.data);
			}).catch(err => {
				this.Response(err);
			})
        },

        TransactionSuccess(transaction)
        {
            if(transaction.orders)
            {
                this.$refs.transactionDetails.OpenTicket(transaction);
                this.Util.NotifyService('transaction', {data: transaction});
            }
        },

        OrderColor(ticket)
        {
            if(ticket.type == 'DELIVERY') return 'rgb(0, 181, 255)';
            if(ticket.type == 'TAKEAWAY') return '#c34b4b';
            if(ticket.type == 'SHIPMENT') return '#e2ba1b';
        },

        TicketType(ticket)
        {
            if(ticket.type == 'DELIVERY') return 'Delivery';
            if(ticket.type == 'TAKEAWAY') return 'Retirar';
            if(ticket.type == 'SHIPMENT') return 'Encargo';
        },
        
        Paid(ticket)
        {
            this.Confirm({
                text: 'Pagado',
                title: '¿Marcar pedido como pagado?',
                class: 'selected-color',
                function: () => {
                    this.Loading();
                    axios.post(`/transaction/influye/${ticket.id}/paid`).then(res => {
                        this.Response(res);
                        this.UpdateInfluyeTransactions(true);
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
        },

        OpenSettings(ref)
        {
            this.$refs.iapQuickSettings.Open(ref);
        },

        CustomerOptions(ticket)
        {
            this.Options([
                {text: 'Bloquear Cliente', class: 'error-color', function: () => {this.BlockCustomer(ticket)}, if: !!(this.canBlockCustomers && ticket.phone && ticket.source == 'INFLUYE_WEB'), confirm: `¿Bloquear cliente con número ${ticket.phone}?`},
                {text: 'Llamar', function: () => {this.Util.Open(`tel:${ticket.phone}`)}, if: !!ticket.phone},
                {text: 'Abrir en Whastapp', function: () => {this.Util.OpenWhatsapp(ticket.phone, '')}, if: !!ticket.phone},
                {text: 'Buscar en Waze', function: () => {this.Util.OpenWaze(ticket.custom_data.input_address)}, if: !!(this.mobile && ticket.address && ticket.custom_data.input_address)},
                {text: 'Buscar en Waze', function: () => {this.Util.OpenWaze(ticket.address)}, if: !!(this.mobile && ticket.address && !ticket.custom_data.input_address)},
                {text: 'Buscar en Google Maps', function: () => {this.Util.OpenGoogleMaps(ticket.custom_data.input_address)}, if: !!(ticket.address && ticket.custom_data.input_address)},
                {text: 'Buscar en Google Maps', function: () => {this.Util.OpenGoogleMaps(ticket.address)}, if: !!(ticket.address && !ticket.custom_data.input_address)},
                {text: 'Abrir Ubicación en Google Maps', function: () => {this.Util.OpenGoogleMaps(ticket)}, if: !!ticket.longitude},
            ]);
        },

        BlockCustomer(ticket)
        {
            var data = {
                name: ticket.customer_name,
                phone: ticket.phone, 
                email: ticket.custom_data.email,
                ip: ticket.custom_data.ip
            };
            this.Loading();
            axios.post(`/customers/${this.Local.id}/iap/block`, data).then(res => {
                this.Info('Cliente bloqueado exitosamente');
            }).catch(err => {
                this.Response(err);
            });
        },

        OpenEditScheduled(ticket)
        {
            this.modalEditScheduled = this.Util.Copy(ticket);
        },

        EditScheduled()
        {
            var data = {scheduled_at: this.modalEditScheduled.scheduled_at};
            this.Loading();
            axios.post(`/transaction/scheduled/${this.modalEditScheduled.id}/edit`, data).then(res => {
                this.Response(res);
                this.UpdateInfluyeTransactions(true);
                this.modalEditScheduled = null;
            }).catch(err => {
                this.Response(err);
            });
        }

    },
    computed: {
        arriveTime() {
            if(!this.modalAccept) return [];
            var ret = [
                //{text: 'Sin especificar', value: 0},
                {text: 'Ingresar Hora o Tiempo', value: 'CUSTOM'},
                {text: '30 minutos', value: 30},
                {text: '40 minutos', value: 40},
                {text: '50 minutos', value: 50},
                {text: '1 hora', value: 60},
                {text: '1 hora 10 minutos', value: 70},
                {text: '1 hora 20 minutos', value: 80},
                {text: '1 hora 30 minutos', value: 90},
                {text: '1 hora 40 minutos', value: 100},
                {text: '1 hora 50 minutos', value: 110},
                {text: '2 horas', value: 120},
                {text: '2 horas 10 minutos', value: 130},
                {text: '2 horas 20 minutos', value: 140},
                {text: '2 horas 30 minutos', value: 150},
                {text: '2 horas 40 minutos', value: 160},
                {text: '2 horas 50 minutos', value: 170},
                {text: '3 horas', value: 180},
            ];

            return ret;
        },
        iapCustomTime() {
            if(!this.modalAccept) return null;

            if(this.modalAccept.autocomplete && this.modalAccept.iap.type == 'TAKEAWAY')
                return this.Util.StringToTime('1 min');

            var time = null
            if(this.modalAccept.time !== 'CUSTOM')
                time = Util.StringToTime(this.modalAccept.time + ' min');
            else 
                time = Util.StringToTime(this.modalAccept.custom);
            if(!time) return null;

            if(this.modalAccept.range)
            {
                var mins = Util.TimeToMinutes(time) + this.modalAccept.range;
                return `${time} - ${Util.MinutesToTime(mins)}`;
            }

            return time;

        },
        state() {

            var scheduled = [];
            var pending = [];
            //var actionRequired = [];

            this.influyeTransactions.forEach(iap => {
                if(iap.status == 'SCHEDULED') 
                {
                    if(iap.source == 'INFLUYE_ADMIN' || this.iapEnabled)
                        scheduled.push(iap);
                    if(iap.source !== 'INFLUYE_ADMIN' && this.iapEnabled)
                    {
                        var schedule = this.Util.Date(iap.scheduled_at, 'DD/MM/YYYY');
                        var today = this.Util.Date('now', 'DD/MM/YYYY');
                        if(schedule == today)
                            pending.push(iap);
                    }
                }
                else
                {
                    if(this.iapEnabled)
                        pending.push(iap);
                }
                //if(['WAITING', 'BANK_DEPOSIT', 'ACCEPTED'].indexOf(iap.status) > -1) actionRequired.push(iap);
            });

            scheduled.sort((a, b) => {
                return a.scheduled_at - b.scheduled_at;
            });

            return {
                all: this.influyeTransactions,
                pending: pending,
                scheduled: scheduled, 
                //actionRequired: actionRequired,
                playBell: this.playBell,
            }
        },
        iapEnabled() {
            return this.Local.modules.module_store || this.Local.modules.module_store_plus;
        },
        canCreateTransactions() {
            if(!this.Auth) return false;
            if(this.Auth.role.indexOf('admin') > -1) return true;
            return !!(!this.Auth.restrictions.transactions_turn_only || this.Turn);
        },
        canEditIAPSettings() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.disable_iap || this.Auth.restrictions.auto_accept_iap);
        },
        canBlockCustomers() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.block_customers);
        }
    },
    watch: {
        state: function(n, o) {
            this.$emit('input', n);
            
            if(!this.iapEnabled) return false;
            var alert = false;
            

            for(var i = 0; i < n.pending.length; i++)
            {
                var transaction = n.pending[i];
                var newStatus = transaction.status == 'ACCEPTED' ? 'PREPARING' : transaction.status;
                if(transaction.custom_data && transaction.custom_data.bank_deposit_at) newStatus = 'BANK_DEPOSIT_READY';

                if(newStatus == 'PENDING' || newStatus == 'PREPARING' || newStatus == 'BANK_DEPOSIT_READY')
                {
                    var oldStatus = this.lastInfluyeTransactionStatus[transaction.id];
                    if(!oldStatus || oldStatus !== newStatus)
                    {
                        this.lastInfluyeTransactionStatus[transaction.id] = newStatus;
                        alert = true;
                    }
                }
            };
            //if(!this.$refs.menuInfluyeTransactions.isOpen && newStatus !== this.lastInfluyeTransactionStatus)
            if(alert)
                this.playBell = true;

        },
    },
    sockets: {
        connect() {
            this.UpdateInfluyeTransactions();
        },
        IAP() {
            this.UpdateInfluyeTransactions();
        },
    },
    beforeDestroy() {
        if(this.bellInterval) clearInterval(this.bellInterval);
        if(this.timerFocus) clearTimeout(this.timerFocus);
        if(this.timerUpdate) clearInterval(this.timerUpdate);
        document.body.removeEventListener('mousemove', this.Focus);
        document.body.removeEventListener('touchstart', this.Focus);
    }
}
</script>

<style>

.alert-screen {
    animation: anim-alert-screen 700ms ease-in-out 0s infinite alternate;
}
@keyframes anim-alert-screen {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: var(--purple);
    }
}

.alert-header {
    animation: anim-alert-header 700ms ease-in-out 0s infinite alternate;
}
@keyframes anim-alert-header {
    0% {
        background-color: #3b3b3b;
    }
    100% {
        background-color: rgb(0, 181, 255);
    }
}

</style>
