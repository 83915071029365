<template>
<div class="pa-body">

    <div class="opt r" v-show="Auth.role == 'admin' || Auth.role == 'sub_admin'">
        <i class="options icon-dots-vertical" @click="Options([
            {text: 'Anexar Sub Local', function: () => {modalAttach = {name: ''}}, if: Auth.role == 'admin' || Auth.role == 'sub_admin'},
            //{text: 'Crear Sub Local', function: () => {modalNew = {}} , if: Auth.role == 'admin' || Auth.role == 'sub_admin'},
        ])"></i>
    </div>

    <div class="mb20" v-if="attachedFranchises.length">
        <div class="f18 mb20">Franquicias Anexadas</div>
        <div :class="`section mb10 ${access[franchise.id] ? 'selectable' : ''}`"
        @click="access[franchise.id] ? GoToSublocal(franchise) : Info('Sin acceso')"
        v-for="(franchise, index) in attachedFranchises"
        :key="index">
            <div class="flex fill align-center">
                <div class="flex flex-fill align-center">
                    <div class="flex column">
                        <div class="c2 bold">{{franchise.name}}</div>
                        <div class="secondary-text">Creado el {{Util.Date(franchise.created_at)}}</div>
                    </div>
                </div>
                <i class="options icon-trash" v-if="Auth.role == 'admin' || Auth.role == 'sub_admin'" @click="Confirm({
                    text: 'Eliminar',
                    class: 'error-color',
                    title: '¿Quitar franquicia anexada?',
                    function: () => {DeattachSublocal(index, franchise.type)}
                })"></i>
            </div>
        </div>
    </div>

    <div v-if="!locals.length && !attachedSublocals.length" class="secondary-text ta-center mt10">No hay sub locales</div>
    <div class="mb20" v-if="locals.length">
        <div class="f18 mb20">Sub Locales</div>
        <div :class="`section mb10 ${access[local.id] ? 'selectable' : ''}`"
        @click="access[local.id] ? GoToSublocal(local) : null"
        v-for="(local, index) in locals"
        :key="index">
            <div class="flex fill align-center">
                <div class="flex flex-fill align-center">
                    <div class="flex column">
                        <div class="c2 bold">{{local.name}}</div>
                        <div :class="`secondary-text ${local.plan_status == 'UNPAID' ? 'cred' : ''}`">Plan {{Util.Number(local.plan_price)}} UF expira {{ Util.Date(local.plan_expiration) }}</div>
                    </div>
                </div>
                <button class="secondary" @click.stop="Util.Open(SubscriptionUrl(local))">Renovar{{ desktop ? ' Plan' : '' }}</button>
                <i class="options icon-trash" v-if="Auth.role == 'admin' && !attachedFranchises.length" @click="Confirm({
                    text: 'Eliminar',
                    class: 'error-color',
                    title: '¿Eliminar sub local permantemente?',
                    function: () => {DeleteSublocal(index)}
                })"></i>
            </div>
        </div>
    </div>

    <div class="" v-if="attachedSublocals.length">
        <div class="f18 mb20">Sub Locales Anexados</div>
        <div :class="`section mb10 ${access[local.id] ? 'selectable' : ''}`"
        @click="access[local.id] ? GoToSublocal(local) : Info('Sin acceso')"
        v-for="(local, index) in attachedSublocals"
        :key="index">
            <div class="flex fill align-center">
                <div class="flex flex-fill align-center">
                    <div class="flex column">
                        <div class="c2 bold">{{local.name}}</div>
                        <div :class="`secondary-text ${local.plan_status == 'UNPAID' ? 'cred' : ''}`">Plan {{Util.Number(local.plan_price)}} UF expira {{ Util.Date(local.plan_expiration) }}</div>
                        <!--<div class="secondary-text">Creado el {{Util.Date(local.created_at)}}</div>-->
                    </div>
                </div>
                <button class="secondary" @click.stop="Util.Open(SubscriptionUrl(local))">Renovar{{ desktop ? ' Plan' : '' }}</button>
                <!--<i v-if="access[local.id]" class="icon icon-arrow-right-o c4"></i>-->
                <i class="options icon-trash" v-if="(Auth.role == 'admin' || Auth.role == 'sub_admin') && !attachedFranchises.length" @click="Confirm({
                    text: 'Eliminar',
                    class: 'error-color',
                    title: '¿Quitar sub local anexado?',
                    function: () => {DeattachSublocal(index)}
                })"></i>
            </div>
        </div>
    </div>

    <modal v-model="modalNew" dir="center" modalClass="sm" title="Nuevo Sub Local">
        <div class="section" v-if="modalNew">
            <input-field
            v-model="modalNew.name"
            label="Nombre">
            </input-field>
            <!--
            <input-field
            v-model="modalNew.address"
            label="Dirección"
            placeholder="(Opcional)">
            </input-field>
            <div class="hdvm">
                <input-field
                v-model="modalNew.latitude"
                type="number"
                label="Latitud"
                placeholder="(Opcional)">
                </input-field>
                <input-field
                v-model="modalNew.longitude"
                type="number"
                label="Longitud"
                placeholder="(Opcional)">
                </input-field>
            </div>
            -->
        </div>
        
        <button slot="actions" class="primary purple" @click="CreateSublocal()">Crear</button>
        
    </modal>

    <modal v-model="modalAttach" dir="center" modalClass="sm" title="Anexar Sub Local">
        <div class="section" v-if="modalAttach">
            <input-field
            v-model="modalAttach.name"
            label="Nombre">
            </input-field>
        </div>
        
        <button slot="actions" class="primary purple" @click="AttachSublocal()">Aceptar</button>
        
    </modal>

</div>
</template>

<script>
export default {
    data() {
        return {
            locals: [],
            attachedSublocals: [],
            attachedFranchises: [],
            access: {},
            modalNew: null,
            modalAttach: null
        }
    },
    mounted() {
        this.Init();
    },
    methods: {
        Init(){
            this.Loading();
            axios.get(`/sublocals/${this.Local.id}`).then(res => {
                console.log(res.data);
                this.locals = res.data.sublocals;
                this.attachedSublocals = res.data.attached;
                this.attachedFranchises = res.data.attachedFranchises;
                this.access = res.data.access.length == 0 ? {} : res.data.access;
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        CreateSublocal() {
            this.Loading();
            axios.put(`/sublocal/${this.Local.id}`, this.modalNew).then(res => {
                this.Info('Sub local creado exitósamente');
                this.locals.push(res.data);
                this.modalNew = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteSublocal(index)
        {
            var local = this.locals[index];
            this.Loading();
            axios.delete(`/sublocal/${local.id}`).then(res => {
                this.Info('Sub local eliminado exitósamente');
                this.locals.splice(index, 1);
            }).catch(err => {
                this.Response(err);
            });
        },
        AttachSublocal()
        {
            this.Loading();
            axios.post(`/sublocal/${this.Local.id}/attach`, this.modalAttach).then(({data}) => {
                if(data.type == 'FRANCHISE'){
                    this.Info('Franquicia anexado exitosamente');
                    this.attachedFranchises.push(data);

                    if(data.sub_locals.length){
                        data.sub_locals.forEach(subLocal => {
                            this.attachedSublocals.push(subLocal);
                        });
                    }
                }else{
                    this.Info('Sub local anexado exitosamente');
                    this.attachedSublocals.push(data);
                }
                this.modalAttach = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        DeattachSublocal(index, type = null)
        {
            var sublocal = type == 'FRANCHISE'? this.attachedFranchises[index] : this.attachedSublocals[index];
            this.Loading();
            axios.delete(`/sublocal/${this.Local.id}/deattach/${sublocal.id}`).then(res => {
                this.Info(`Se ha quitado ${type == 'FRANCHISE'? 'la franquicia' : 'el sublocal'} exitosamente`);
                type == 'FRANCHISE'? this.Init() : this.attachedSublocals.splice(index, 1);
            }).catch(err => {
                this.Response(err);
            });
        },
        //local admin entrar al sub local de la franquicia
        GoToSublocal(local)
        {
            this.Loading();
            axios.get(`/sublocal/${local.id}/enter`).then(res => {
                console.log(res.data);
                this.Response(res);
                var sublocal = res.data.local;
                sublocal.franchise = this.Local;
                this.$store.commit('setLocal', sublocal);
                this.$store.commit('setTurn', res.data.turn);
                //this.$socket.client.emit('CONNECT_TO_LOCAL', local.id);
                this.$router.push('/dashboard');
            }).catch(err => {
                this.Response(err);
            });
        },
        SubscriptionUrl(local)
        {
            return process.env.NODE_ENV === 'production' ? `https://subscription.influye.app/options?code=${local.service_key}` : `${this.Util.backend}subscription/options?code=${local.service_key}`;
        }
    }
}
</script>