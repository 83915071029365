<template>
<div class="pa-body">

    <div class="f18 mb20">General</div>
    <div class="section mb20" v-if="settings">
        <div class="secondary-text mb10">Determina si el sitio web del menú virtual está habilitado.</div>
        <label class="mb15">
            <input type="checkbox" v-model="settings.is_active"> Activado <div class="checkmark"></div>
        </label>        

        <input-field class="mb15"
        v-model="settings.url"
        label="Dominio"
        description="Pagina web del menú virtual"
        placeholder="dominio.cl (sin www, sin http://)">
            <div class="append" slot="after" v-if="Auth.role=='admin' || Auth.role == 'sub_admin'">
                <i class="options icon-dots-vertical" @click="Options([
                    {text: 'Instalar SSL', function: () => {CreateSSLEntry()}, confirm: 'Se instalará un certificado SSL del dominio configurado. El dominio debe estar previamente funcionando (DNS propagados).'},
                    {text: 'Crear Dominio', function: () => {UpdateDomain()}, confirm: `Se crearán las entradas del dominio en el servidor. Si hay un certificado SSL configurado se sobreescribirá.`},
                ])"></i>
            </div>
        </input-field>

    </div>


    <div class="f18 mb20">Personalización</div>
    <div v-if="settings" class="section mb20">

        <div class="hdvm mini">

            <input-image class="mb15"
            label="Banner"
            v-model="settings.banner"
            path="banners/">
            </input-image>

            <div>
                <div class="bold mb10">Carusel</div>
                <div class="flex">
                    <div class="flex column mr5 relative" v-for="i in 4" :key="`carousel-${i}`">
                        <input-image 
                        v-model="settings.carousel[i-1].image"
                        path="banners/">
                        </input-image>
                        <div class="flex absolute w100 carousel-controls">
                            <i class="icon selectable icon-arrow-left pa10" @click.stop="MoveCarousel(i-1, -1)" v-show="i > 1"></i>
                            <div class="flex-fill"></div>
                            <i class="icon selectable icon-arrow-right pa10" @click.stop="MoveCarousel(i-1, 1)" v-show="i < 4"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <input-field
        label="Descripción"
        description="Descripción de la página web presente en buscadores y al compartir enlace"
        v-model="settings.description">
        </input-field>

        <div class="flex mt20">
            <div class="flex-fill">
                <div class="bold">Personalizar Apariencia</div>
                <div class="secondary-text">Cambia los colores, tipografía y formas de la página web</div>
            </div>
            <button class="secondary" @click="$refs.customizationForm.Open(settings, 'STYLE', 'VIRTUAL_MENU', settings.url)">Editar</button>
        </div>

    </div>

    <div class="section mb20" v-if="settings">

            <input-select class="mb15"
            label="Precio del menú"
            description="Determina como se mostrará el precio de los productos en la página"
            :items="optionsItemPrice"
            v-model="settings.item_price_display">
            </input-select>

            <input-select class="mb15"
            label="Visualización del menú"
            description="Determina como se mostrará el menú en la página"
            :items="[
                {text: 'Todos los productos', value: 'ALL', description: 'Se muestran todos los productos a la vez, para desplazarse entre secciones se hace scroll'},
                {text: 'Expandir secciones', value: 'EXPAND', description: 'Se muestran las secciones que se pueden expandir para mostrar los productos'},
                {text: 'Separar secciones', value: 'TABS', description: 'Sólo se muestran los productos de la sección seleccionada'}
            ]"
            v-model="settings.menu_display">
            </input-select>

            <input-toggle class="mb15"
            v-model="settings.item_details"
            label="Ver detalles de productos"
            description="Permite seleccionar un producto que abre una ventana con la información y modificadores">
            </input-toggle>

            <div class="flex column">
                <div class="flex align-center">
                    <label>
                        <input type="checkbox" v-model="settings.show_supersection">
                        <div class="checkmark"></div>
                    </label>
                    <div class="flex-fill">
                        <div class="bold">Mostrar super secciones </div>
                        <div class="secondary-text">Al estar activado no se mostrará la sección "Más Vendidos"</div>
                    </div>
                </div>
            </div>

        </div>

    <div class="f18 mb20">Ajustes</div>
    <div class="section" v-if="settings">
        
        <input-field class="mb15"
        v-model="settings.google_analytics"
        label="Google Analytics ID"
        placeholder="G-XXXXXXXXXXX (Opcional)">
        </input-field>
        
        <input-field class="mb15"
        v-model="settings.facebook_pixel"
        label="Facebook Pixel ID"
        placeholder="123456789012345 (Opcional)">
        </input-field>

        <input-field
        v-model="settings.facebook_pixel_access_token"
        label="Facebook Pixel Token de Acceso"
        placeholder="Token de Acceso (Opcional)">
        </input-field>
    </div>

    <customization-form
    ref="customizationForm"
    @OnSaved="SaveCustomization($event)">
    </customization-form>

</div>
</template>

<script>
import CustomizationForm from '../common/CustomizationForm.vue';

export default {
    components: { CustomizationForm },
    data() {
        return {
            settings: null,
            optionsItemPrice: [
                {value: 'ITEM_PRICE', text: 'Producto', description: 'Precio del producto'},
                {value: 'MODIFIER_PRICE', text: 'Modificador', description: 'Precio del primer modificador, si no hay se mostrará el precio del producto'},
                {value: 'AUTO', text: 'Auto', description: 'Precio del producto, si no hay se mostrará el precio de un modificador, si no hay se oculta'},
                {value: 'HIDDEN_ZERO', text: 'Productos con precio', description: 'Precio del producto pero no se mostrará si el precio es cero'},
                {value: 'HIDDEN_ALL', text: 'Oculto', description: 'No mostrará el precio'},
            ],
        }
    },
    mounted() {        
        
    },
    methods: {    
        Init(force)
        {
            if(force || !this.settings)
            {
                this.Loading();
                axios.get(`/settings/virtualmenu/${this.Local.id}`).then(res => {
                    this.Response(res);
                    if(!res.data.carousel) res.data.carousel = [{image: null},{image: null},{image: null},{image: null}];
                    this.settings = res.data;
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        Save() {
            
            if(this.settings.url)
            {
                this.settings.url = this.settings.url.trim().toLowerCase();
                var invalidDomains = ['influye.app', 'influye.online', 'influyeapp.cl'];
                if(this.settings.url.indexOf('://') > -1 || 
                    this.settings.url.indexOf('www.') > -1 ||
                    (this.settings.url.split('.').length > 3 || this.settings.url.split('.').length < 2) || 
                    invalidDomains.indexOf(this.settings.url) > -1
                    )
                        return this.Info('Dominio inválido');
            }

            this.Loading();
            axios.post(`/settings/virtualmenu/${this.Local.id}`, this.settings).then(res => {
                this.Info('Configuración guardada.');
            }).catch(err => {
                this.Response(err);
            });

        },
        UpdateDomain()
        {
            this.Loading();
            axios.get(`/settings/virtualmenu/${this.Local.id}/domain/update`).then(res => {
                this.Info('Se han creado las configuraciones en el servidor para el dominio');
            }).catch(err => {
                this.Response(err);
            });
        },
        CreateSSLEntry()
        {
            this.Loading();
            axios.get(`/settings/virtualmenu/${this.Local.id}/domain/ssl`).then(res => {
                this.Info('Se instalará un certificado SSL para el dominio');
            }).catch(err => {
                this.Response(err);
            });
        },
        MoveCarousel(index, mov)
        {
            mov = index + mov;
            if(mov < 0) mov = 3;
            if(mov > 3) mov = 0;
            var tmp = this.settings.carousel[mov];
            this.$set(this.settings.carousel, mov, this.settings.carousel[index]);
            this.$set(this.settings.carousel, index, tmp);
        },
        SaveCustomization(data) {
            this.settings = data;
            this.Save();
            this.$refs.customizationForm.Close();
        }
    },
}
</script>