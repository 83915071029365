<template>
<div class="pa-body">

    <popover-menu class="opt r" icon="dots-vertical" maxWidth="340px">
        <div>
            <input-toggle v-model="deliveryAttentionSoundEnabled"
            @OnValueChanged="Util.StoreLocal('deliveryAttentionSoundEnabled', $event, true)"
            label="Sonido de Alerta"
            description="Sonido que reproduce cuando se ingresa un pedido">
            </input-toggle>
        </div>
    </popover-menu>

    <div class="secondary-text ta-center mt10" v-if="!visibleTransactions.length">No hay pedidos</div>
    <div class="" v-else ref="orderContainer">
        <div class="section mb10 selectable relative"
        v-for="(transaction, index) in visibleTransactions"
        :key="transaction.id"
        @click="OpenTransaction(transaction)">
            <div class="fill flex align-center" :id="`t-${transaction.id}`">
                <div class="flex-fill flex column">
                    <div class="bold">#{{Util.Zeropad(transaction.id_ticket, 10)}}</div>
                    <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(transaction.delivery_at, 'HH:mm')}}</div>
                    <div class="secondary-text" v-if="transaction.custom_data.delivery_time"><span class="bold">Entrega: </span>{{transaction.custom_data.delivery_time}} hrs</div>
                    <div class="secondary-text" v-if="transaction.customer && transaction.customer.name"><span class="bold">Nombre: </span>{{transaction.customer.name}}</div>
                    <div class="secondary-text" v-if="transaction.customer && transaction.customer.address"><span class="bold">Dirección: </span>{{transaction.customer.address}}</div>
                </div>
                <i @click.stop="MoveUpOrder(index, `t-${transaction.id}`)" class="options icon-arrow-up absolute secondary-text" style="right: 0px; top: 0px;" title="Subir pedido" v-show="index != 0"></i>
                <i @click.stop="MoveDownOrder(index,`t-${transaction.id}`)" class="options icon-arrow-down absolute secondary-text" style="right: 0px; bottom: 0px;" title="Bajar pedido" v-show="index != visibleTransactions.length-1"></i>
            </div>
        </div>
    </div>

    <!--
    <div style="width: 50vw" v-if="false && desktop">
        <map-component></map-component>
    </div>
    -->

    <modal v-model="modalDetails" :dir="['right', 'right']" :modalClass="['fill', 'md right h100']" :title="modalDetails?`#${Util.Zeropad(modalDetails.id_ticket, 10)}`:''">
        <i class="options icon-dots-vertical" slot="options" @click="Options([
            {text: 'Ver Detalles', function: () => {$refs.transactionDetails.Open(modalDetails)}},
            {text: 'Marcar como Entregada', function: () => {MarkAsDelivered()}, if: !modalDetails.custom_data.delivered_at}
            //{text: 'Editar Método de Pago', function: () => {$refs.transactionDetails.OpenEditPayment(modalDetails, OnPaymentEdit)}, if: !!(Auth.role.indexOf('admin') > -1 || Auth.restrictions.edit_transactions_payment)}
        ])"></i>
        <div v-if="modalDetails" class="pa20">
            <div class="">
                <!--<div class="f18 mb20">General</div>-->
                <div v-if="modalDetails.custom_data.delivery_time" class="mb15">
                    <div class="bold">Entrega programada</div>
                    <div>{{modalDetails.custom_data.delivery_time}} hrs</div>
                </div>
                <div v-if="modalDetails.customer && modalDetails.customer.name" class="mb15">
                    <div class="bold">Nombre</div>
                    <div>{{modalDetails.customer.name}}</div>
                </div>
                <div v-if="modalDetails.customer.address" class="flex mb15">
                    <div class="flex-fill">
                        <div class="bold">Dirección</div>
                        <div>{{modalDetails.customer.address}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click="$refs.transactionDetails.CustomerOptions(modalDetails, 'ADDRESS')"></div>
                </div>
                <div v-if="modalDetails.customer.phone" class="flex mb15">
                    <div class="flex-fill">
                        <div class="bold">Contacto</div>
                        <div>{{modalDetails.customer.phone}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click="$refs.transactionDetails.CustomerOptions(modalDetails, 'PHONE')"></div>
                </div>

                
                
                <div class="f18 flex">
                    <div class="bold flex-fill">Total</div>
                    <div class="bold">{{Util.Price(modalDetails.total_money)}}</div>
                </div>
                <div class="flex" v-for="(tip, indexTip) in modalDetails.custom_data.tips" :key="'tip'+indexTip">
                    <div class="flex-fill">Propina ({{Util.PayMethod(tip.method)}})</div>
                    <div>{{Util.Price(tip.total)}}</div>
                </div>
                <div class="f18 flex mt15" v-if="modalDetails.custom_data.tips">
                    <div class="bold flex-fill">Total con propinas</div>
                    <div class="bold">{{Util.Price(modalDetails.total_money + Util.Sum(modalDetails.custom_data.tips, 'total'))}}</div>
                </div>

                <div class=""
                v-for="(method, index) in modalDetails.payment" :key="index">
                    <div class="flex fill">
                        <div class="flex column flex-fill">
                            <div class="flex-fill mr10">{{Util.PayMethod(method.method)}} ({{Util.Price(method.subtotal)}})</div>
                            <div v-if="Util.MethodChange(modalDetails, method, index)" class="secondary-text"><span class="bold">Se paga con: </span>{{Util.Price(method.cash)}}</div>		
                        </div>
                        <div class="flex column" style="text-align: right">
                            <div class="bold">{{Util.Price(Util.MethodTip(modalDetails, index) + method.subtotal)}}</div>
                            <div v-if="Util.MethodChange(modalDetails, method, index)" class="secondary-text"><span class="bold">Vuelto: </span> {{Util.Price(Util.MethodChange(modalDetails, method, index))}}</div>
                        </div>
                    </div>
                </div>   

                
            </div>
            <div class="mt20" v-if="modalDetails.location!==null">
                <!--<div class="f18 mb20">Ubicación</div>-->
                <div class="separator"></div>
                <div v-if="modalDetails.location==='loading'" class="secondary-text ta-center mt10">Buscando ubicación</div>
                <div v-if="modalDetails.location===false" class="secondary-text ta-center mt10">No se encontró la ubicación</div>
                <div style="width: 100%; height: 300px" v-if="modalDetails.location && modalDetails.location!=='loading'">
                    <div class="secondary-text ta-center mt10">{{modalDetails.location.long_address}}</div>
                    <map-component
                    v-model="modalDetails.location" :useMarker="true"
                    :path="modalDetails.path">
                    </map-component>
                </div>
            </div>
        </div>
        
        <button slot="actions" class="primary error-color" v-if="Auth.role.indexOf('admin') > -1 || Auth.restrictions.cancel_transactions" @click="$refs.transactionDetails.OpenCancel(modalDetails)">Cancelar Pedido</button>
        <button slot="actions" class="primary purple" v-if="canComplete" @click="$refs.transactionDetails.OpenComplete(modalDetails, true)">Entregar Pedido</button>
        <button slot="actions" class="primary purple" v-if="!canComplete && modalDetails && !modalDetails.custom_data.delivered_at" @click="MarkAsDelivered()">Marcar como Entregado</button>
        
    </modal>
    <transaction-details ref="transactionDetails"
    @OnTransactionCompleted="OnTransactionCompleted">
    </transaction-details>

</div>
</template>

<script>
export default {
    data() {
        return {
            transactions: [],
            modalDetails: null,
            position: null,
            modalCancel: null,
            deliveryAttentionSoundEnabled: false,
            audioAttention: null,
            initialLoad: false,
            settings: null,
        }
    },
    mounted() {
        if(localStorage.deliveryAttentionSoundEnabled) this.deliveryAttentionSoundEnabled = true;
        this.audioAttention = new Audio(require('../../assets/attention.mp4'));
        this.Update();

        axios.get(`/delivery/settings/${this.Local.id}`).then(res => {
            this.settings = res.data;
        }).catch(err => {

        });

    },
    methods: {
        Update(hideLoading) {
            if(!hideLoading) this.Loading();
            axios.get('/delivery/transactions').then(res => {
                this.transactions = res.data;
                this.GetOrderFromLocalStorage();
                if(!hideLoading) this.Response(res);
            }).catch(err => {
                if(!hideLoading) this.Response(err);
            });
        },
        OpenTransaction(transaction) 
        {
            this.position = null;
            this.modalDetails = this.Util.Copy(transaction);
            this.SearchAddress();
        },
        SearchAddress() {
            this.$set(this.modalDetails, 'location', 'loading');
            if(this.modalDetails.customer.address) {
                //Geocode
                axios.get(`/delivery/transaction/${this.modalDetails.id}/location`).then(res => {
                    console.log(res.data);
                    this.$set(this.modalDetails, 'location', res.data);
                    if(res.data.path)
                    {
                        res.data.polyline = res.data.directions.geometry;
                        this.$set(this.modalDetails, 'path', res.data);
                    }
                }).catch(err => {
                    this.$set(this.modalDetails, 'location', false);
                    console.log(err);
                });
            }
            else
            {
                this.$set(this.modalDetails, 'location', null);
            }
        },
        OnTransactionCompleted(transaction)
        {
            this.Info('Se ha completado la transacción');
            this.Update(true);
            this.modalDetails = null;
        },
        CancelTransaction()
        {
            var data = {
                reason: this.modalCancel.reason,
                reduce_stock: this.modalCancel.reduce_inventory
            };

            if(this.modalCancel.ticket.payment[0].method == 'ONLINE')
            {
                data.refund = this.modalCancel.refund;
            }

            this.Loading();
            axios.post(`/transaction/${this.modalCancel.id}/cancel`, data).then(res => {
                this.Info(`Se ha cancelado la transacción`);
                this.modalCancel = null;
                this.modalDetails = null;
                this.Update(true);
            }).catch(err => {
                this.Response(err);
            });
        },

        SendDepositData(phone)
        {
            this.Loading();
			axios.get(`/local/${this.Local.id}/bank`).then(res => {
				this.Response(res);
				this.Util.OpenWhatsapp(phone, `${res.data.name}\n${res.data.rut}\n${res.data.bank}\n${res.data.account}\n${res.data.account_number}\n${res.data.email}`);
				console.log(res.data);
			}).catch(err => {
				this.Response(err);
			})
        },

        OnPaymentEdit(data)
        {
            this.Info('Se ha editado la transacción');
            this.modalDetails = data;
        },

        MarkAsDelivered()
        {
            this.Confirm({
                text: 'Entregado',
                title: '¿Marcar el pedido como entregado?',
                function: () => {
                    this.Loading();
                    axios.post(`/transaction/${this.modalDetails.id}/delivered`).then(res => {
                        this.Info(`Pedido marcado como entregado`);
                        this.modalDetails = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
        },
        MoveUpOrder(index, key){
            if (index > 0) {
                let selected = this.$refs.orderContainer.querySelectorAll('.cpurple');
                selected.forEach(el => {
                    el.classList.remove('cpurple');
                });
                setTimeout(() => {
                    if(key)
                    {
                        let el = document.getElementById(key);
                        el.classList.add('cpurple');
                    }
                }, 10);

                let toUp = this.transactions[index]; // Item que sube
                let toDown = this.transactions[index - 1]; // Item que baja
                this.$set(this.transactions, index - 1, toUp);
                this.$set(this.transactions, index, toDown);

                setTimeout(() => {
                    if(key)
                    {
                        let el = document.getElementById(key);
                        el.classList.remove('cpurple');
                    }
                }, 2000);
                this.SaveOrderOnLocalStorage();
            }
        },
        MoveDownOrder(index, key) {
            if (index < this.transactions.length - 1) {

                let selected = this.$refs.orderContainer.querySelectorAll('.cpurple');
                selected.forEach(el => {
                    el.classList.remove('cpurple');
                });
                setTimeout(() => {
                    if(key)
                    {
                        let el = document.getElementById(key);
                        el.classList.add('cpurple');
                    }
                }, 10);

                let toUp = this.transactions[index + 1]; // Item que sube
                let toDown = this.transactions[index]; //Item que baja. Seleccionado

                this.$set(this.transactions, index + 1, toDown); 
                this.$set(this.transactions, index, toUp);

                setTimeout(() => {
                    if(key)
                    {
                        let el = document.getElementById(key);
                        el.classList.remove('cpurple');
                    }
                }, 2000);
                this.SaveOrderOnLocalStorage();
            }
        },
        SaveOrderOnLocalStorage(tickets = null) {
            if(!tickets) tickets = this.transactions.map(transaction => transaction.id_ticket);
            localStorage.setItem('orderOrdersDelivery', JSON.stringify(tickets));
        },
        GetOrderFromLocalStorage() {
            // El orden guardado en LS
            let saveOrdersLS = JSON.parse(localStorage.getItem('orderOrdersDelivery'));

            let idTicketsOrder = this.transactions.map(transaction => transaction.id_ticket); 
            if(!saveOrdersLS) {
                localStorage.setItem('orderOrdersDelivery', JSON.stringify(idTicketsOrder));
                return;
            }

            // Verifica si hay transacciones que no esta en la lista de ordenar
            // De no existe el ticket en el array de orden, se agrega al final
            let updatedLS = false;
            this.transactions.forEach(trans => {
                if(!saveOrdersLS.includes(trans.id_ticket)){ 
                    saveOrdersLS.push(trans.id_ticket);
                    updatedLS = true;
                }
            });

            // Elimina TICKETS de saveOrderLS que ya no existen en las transacciones actuales
            let filteredSaveOrders = saveOrdersLS.filter(id => idTicketsOrder.includes(id));
            if (filteredSaveOrders.length !== saveOrdersLS.length) {
                updatedLS = true;
                saveOrdersLS = filteredSaveOrders;
            }

            // Se actualiza el localstorage
            if(updatedLS) this.SaveOrderOnLocalStorage(saveOrdersLS);

            // Ordena las trasacciones segun el orden guardado en localstorage
            this.transactions.sort((a, b) => {
                return saveOrdersLS.indexOf(a.id_ticket) - saveOrdersLS.indexOf(b.id_ticket);
            });         
        }

    },
    computed: {
        canComplete() 
        {
            if(this.Auth.role.indexOf('admin') > -1) return true;
            if(!this.Auth.restrictions.complete_transactions) return false;
            return true;
        },
        canSendDepositData()
        {
            if(!this.modalDetails) return false;
			for(var i = 0; i < this.modalDetails.payment.length; i++)
				if(this.modalDetails.payment[i].method.toUpperCase().indexOf('TRANSFERENCIA') > -1) return true;
			return false;
        },
        visibleTransactions()
        {
            var ret = [];
            this.transactions.forEach(transaction => {
                if((transaction.paid_at && this.Auth.restrictions.complete_transactions_unpaid) || !transaction.custom_data.delivered_at || this.canComplete ) 
                    ret.push(transaction)
            });
            return ret;
        }
    },
    watch: {
        visibleTransactions: function(n, o)
        {
            if(!this.initialLoad) 
            {
                this.initialLoad = true;
                return;
            }

            if(n && o && n.length > o.length)
            {
                if(this.deliveryAttentionSoundEnabled) this.audioAttention.play();
            }
        }
    },
    sockets: {
        TRANSACTION_CANCELLED(data) {
            this.Update(true);
        },
        TRANSACTION_DELIVERY(data) {
            this.Update(true);
        },
        TRANSACTION_COMPLETED(data) {
            this.Update(true);
        },
        TRANSACTION_EDIT(data) {
            this.Update(true);
        },
    }
}
</script>

<style>

</style>
