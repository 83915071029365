<template>
<div class="flex-fill fill flex column">

    <div class="opt r" v-if="isLocal">

        <input-range ref="range" inputClass="secondary"
        v-show="(Auth.role.indexOf('admin') > -1 || Auth.restrictions.view_transactions == 'ALL')"
        v-model="range"
        @OnValueChanged="Search($event)"
        :icon="mobile ? 'datetime' : ''">
        </input-range>

        <i class="options icon-dots-vertical ml5"
        @click="Options([
            {text: 'Exportar', function: () => {stats.completed.length ? Export() : Info('No hay datos en este rango de tiempo')}, if: !!(Auth.role.indexOf('admin') > -1)},
            {text: 'Buscar Transacción', function: () => {modalTransactionSearch=true}},
        ])"></i>
    </div>

    <div class="opt r" v-if="Local.type == 'FRANCHISE'">

        <input-range ref="range"
        v-if="(Auth.role.indexOf('admin') > -1 || Auth.restrictions.view_transactions == 'ALL')"
        class="flex-fill"
        v-model="range"
        @OnValueChanged="Search($event)">
        </input-range>

        <input-select
        ref="selectLocals"
        v-if="sublocals.length"
        v-show="desktop"
        class="mr5"
        :items="sublocals"
        @OnValueChanged="LocalChanged($event)">
        </input-select>
        
        <i class="options icon-dots-vertical" @click="$refs.selectLocals.Open()"></i>
    </div>

    <tabs ref="tabs" v-if="tabs"
    class="flex-fill"
    :tabs="tabs"
    :keyByTab="true"
    v-model="currentTab"
    @OnTabPedidosOnline="SearchOnline(range)">

        <!-- General -->
        <div slot="General" class="pa-body">
            <!-- Transacciones Completadas -->
            <div v-if="stats && (stats.completed.length > 0 || stats.cancelled.length > 0)">
                
                <div class="section mb20">
                    <chart
                    type="line"
                    :data="stats.chartHistory"
                    dataset="total_money"
                    label="data"
                    height="200px"
                    :yLabelFormat="(value) => {return Util.Price(value, true)}"
                    :tooltip="(value, label, index) => {return `${Util.Price(value)}`}"
                    :customColor="[Util.Chart.purpleGradient]">
                    </chart>
                </div>

                <div class="flex column mb20">

                    <div class="hdvm mb10">
                        <div>
                            <div :class="`section flex align-center ${isLocal && stats.completed.length ? 'selectable' : ''} h100`" @click="isLocal ? $refs.list.Open(stats.completed) : null">
                                <avatar class="mr10"
                                icon="bag"
                                :color="Util.baseColors.green">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Total</div>
                                    <div class="secondary-text">{{Util.Number(stats.completed.length)}} transacciones</div>
                                    <div class="secondary-text" v-if="stats.completed.length">
                                        <span class="bold">Ticket Promedio</span> {{(Util.Price(Math.floor(stats.total_money_customers_number/stats.completed.length)))}}
                                    </div>
                                </div>
                                <div class="f18">{{Util.Price(stats.total_money)}}</div>
                            </div>
                        </div>

                        <div v-if="isLocal">
                            <div :class="`section flex align-center ${stats.edited.length ? 'selectable' : ''} h100`" @click="$refs.list.Open(stats.edited)">
                                <avatar class="mr10"
                                icon="edit"
                                :color="Util.baseColors.yellow">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Editadas</div>
                                    <div class="secondary-text">Transacciones</div>
                                </div>
                                <div class="f18">{{Util.Number(stats.edited.length)}}</div>
                            </div>
                        </div>

                        <div v-if="isLocal">
                            <div :class="`section flex align-center ${stats.cancelled.length ? 'selectable' : ''} h100`" @click="detailsTransactionsCanceled(stats.cancelled)">
                                <avatar class="mr10"
                                icon="forbidden"
                                :color="Util.baseColors.red">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Canceladas</div>
                                    <div class="secondary-text">Transacciones</div>
                                </div>
                                <div class="f18 cred">{{Util.Number(stats.cancelled.length)}}</div>
                            </div>
                        </div>
                        
                    </div>

                    <div class="hdvm mb10">
                        <div>
                            <div :class="`section flex align-center h100 ${stats.discount_transactions.length ? 'selectable' : ''}`" @click="$refs.list.Open(stats.discount_transactions)">
                                <avatar class="mr10"
                                icon="discount"
                                :color="Util.baseColors.yellow">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Descuentos</div>
                                    <div class="secondary-text">{{Util.Number(stats.discount_transactions.length)}} transacciones</div>
                                </div>
                                <div class="f18">{{Util.Price(stats.total_discount)}}</div>
                            </div>
                        </div>
                        <div>
                            <div :class="`section flex align-center h100 ${stats.tips_transactions.length ? 'selectable' : ''}`" @click="$refs.list.Open(stats.tips_transactions)">
                                <avatar class="mr10"
                                icon="coins"
                                :color="Util.baseColors.yellow">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Propinas</div>
                                    <div class="secondary-text">{{Util.Number(stats.tips_transactions.length)}} transacciones</div>    
                                </div>
                                <div class="f18">{{Util.Price(stats.total_tip)}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="hdvm mb10">

                        <div>
                            <div :class="`section flex align-center h100 ${stats.delivery_transactions.length ? 'selectable' : ''}`" @click="$refs.list.Open(stats.delivery_transactions)">
                                <avatar class="mr10"
                                icon="delivery"
                                :color="Util.baseColors.blue">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Reparto</div>
                                    <div class="secondary-text">{{Util.Number(stats.delivery_transactions.length)}} transacciones</div>
                                </div>
                                <div class="f18">{{Util.Price(stats.total_delivery)}}</div>
                            </div>
                        </div>

                        <div>
                            <div :class="`section flex align-center h100 ${stats.delivery_external_transactions.length ? 'selectable' : ''}`" @click="$refs.list.Open(stats.delivery_external_transactions)">
                                <avatar class="mr10"
                                icon="cargo"
                                :color="Util.baseColors.blue">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Reparto Externo</div>
                                    <div class="secondary-text">{{Util.Number(stats.delivery_external_transactions.length)}} transacciones</div>
                                </div>
                                <div class="f18">{{Util.Price(stats.total_delivery_external)}}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="hdvm mb10">
                        <div>
                            <div :class="`section flex align-center h100 ${stats.boletas.length ? 'selectable' : ''}`" @click="$refs.list.Open(stats.boletas)">
                                <avatar class="mr10"
                                icon="invoice"
                                :color="Util.baseColors.green">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Boletas</div>
                                    <div class="secondary-text"><span class="bold">Total</span> {{Util.Price(stats.total_boleta)}}</div>
                                    <div class="secondary-text"><span class="bold">IVA</span> {{Util.Price(stats.total_iva)}}</div>
                                </div>
                                <div class="f18">{{Util.Number(stats.boletas.length)}}</div>
                            </div>
                        </div>

                        <div>
                            <div :class="`section flex align-center h100 ${stats.boletas_rejected.length ? 'selectable' : ''}`" @click="$refs.list.Open(stats.boletas_rejected)">
                                <avatar class="mr10"
                                icon="circle-exclamation"
                                :color="Util.baseColors.red">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Rechazadas</div>
                                    <div class="secondary-text">Boletas</div>
                                </div>
                                <div class="f18 cred">{{Util.Number(stats.boletas_rejected.length)}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="hdvm" v-if="stats.facturas">
                        <div>
                            <div :class="`section flex align-center h100 ${stats.facturas.length ? 'selectable' : ''}`" @click="$refs.list.Open(stats.facturas)">
                                <avatar class="mr10"
                                icon="invoice"
                                :color="Util.baseColors.green">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Facturas</div>
                                    <div class="secondary-text"><span class="bold">Total</span> {{Util.Price(stats.facturas_total)}}</div>
                                    <div class="secondary-text"><span class="bold">IVA</span> {{Util.Price(stats.facturas_total_iva)}}</div>
                                </div>
                                <div class="f18">{{Util.Number(stats.facturas.length)}}</div>
                            </div>
                        </div>

                        <div>
                            <div :class="`section flex align-center h100 ${stats.facturas.length ? 'selectable' : ''}`" @click="$refs.list.Open(stats.facturas_rejected)">
                                <avatar class="mr10"
                                icon="circle-exclamation"
                                :color="Util.baseColors.red">
                                </avatar>
                                <div class="flex-fill">
                                    <div class="bold">Rechazadas</div>
                                    <div class="secondary-text">Facturas</div>
                                </div>
                                <div class="f18 cred">{{Util.Number(stats.facturas_rejected.length)}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" v-if="stats.completed.length > 0">
                    <div class="section mb10"
                    v-for="(group, index) in stats.history"
                    :key="index">
                        <div class="flex fill align-center">
                            <div class="percentage-icon relative" :style="{'background-color': dark ? '' : 'var(--gray-2)'}">
                                <div :style="{'background-color': 'var(--purple)', 'border-radius': '50%', opacity: group.total_money / stats.total_money}" class="fill"></div>
                                <div :class="`absolute cw`">{{Percent(group.total_money, stats.total_money)}}%</div>
                            </div>
                            <!--
                            <avatar class="mr10" style="font-size: 12px"
                            :color="Util.baseColors.purple"><span :style="{color: !dark ? 'black' : 'white'}">{{Percent(group.total_money, stats.total_money)}}<span style="opacity: 0.5">%</span></span></avatar>
                            -->
                            <div class="flex flex-fill column">
                                <div class="bold">{{group.data}}</div>
                                <!--<div class="secondary-text"><span class="bold">Total:</span> {{Util.Price(group.total_money)}}</div>-->
                                <div class="secondary-text"><span class="bold">Transacciones:</span> {{Util.Number(group.count)}}</div>
                            </div>
                            <div class="bold">{{Util.Price(group.total_money)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="secondary-text ta-center mt10" v-else>No hay datos</div>
        </div>
        
        <!-- Items -->
        <div slot="Menu" class="pa-body">

            <div v-if="stats && stats.categories.length > 0">
                <div class="f18 mb20">Categorias</div>
                <div class="section hdvm mb20">
                    <chart class="mb10"
                    :data="stats.chartCategories"
                    dataset="count"
                    type="pie"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    height="200px"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_categories)}%`}">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(category, index) in stats.categories"
                        :key="index"
                        @click="isLocal ? $refs.list.Open(category.transactions, null, {stats: true, label: category.data, total: category.total_money, color: Util.ChartColor(index, stats.categories.length)}, 'categories') : null">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, stats.categories.length)}">{{Percent(category.count, stats.total_categories)}}%</div>
                                <div class="flex column">
                                    <span class="bold">{{category.data}}</span>
                                    <div class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(category.transactions.length)}}</div>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold">{{Util.Price(category.total_money)}}</span>
                                <!--<i v-if="isLocal" class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="stats && stats.sections.length > 0" class="mb20">
                <div class="flex align-center mb20">
                    <div class="f18 flex-fill">Secciones</div>
                    <input-field inputClass="secondary"
                    v-model="searchSections"
                    placeholder="Buscar..."
                    pre="search"
                    cleareable
                    :icon="mobile ? 'search' : ''"
                    :timeout="200">
                    </input-field>
                </div>
                <div class="section hdvm">
                    <chart
                    :data="stats.chartSections"
                    dataset="total_money"
                    type="pie"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    height="200px"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_sections)}%`}">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(section, index) in stats.sections"
                        :key="index"
                        v-show="Util.InString(section.data, searchSections)"
                        @click="isLocal ? $refs.list.Open(section.transactions, null, {prev_sections: section.data , sections: false, stats: true, label: section.data, total: section.total_money, color: Util.ChartColor(index, stats.sections.length)}, 'sections') : null">
                            <!-- @click="isLocal ? $refs.list.Open(category.transactions, `Transacciones con ${category.data}`) : null" -->
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, stats.sections.length)}">{{Percent(section.total_money, stats.total_sections)}}%</div>
                                <span class="bold">{{section.data}}</span>
                                <div class="flex-fill"></div>
                                <span class="bold">{{Util.Price(section.total_money)}}</span>
                                <!-- <i v-if="isLocal" class="icon icon-arrow-right-o c4"></i> -->
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div v-if="stats && stats.items.length > 0" class="mb20">
                <div>
                    <div class="flex align-center mb20">
                        <div class="f18 flex-fill">Items <div class="secondary-text">Cantidad: {{Util.Sum(stats.items, 'count')}}</div></div>
                        <input-field inputClass="secondary"
                        v-model="searchItems"
                        placeholder="Buscar..."
                        pre="search"
                        cleareable
                        :icon="mobile ? 'search' : ''"
                        :timeout="200">
                        </input-field>
                    </div>

                    <div class="section hdvm">
                        <chart
                        :data="stats.chartItems"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return data.data}"
                        :singleDataset="false"
                        :maxDatasets="7"
                        :showLegend="false"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_money)}%`}"
                        height="200px">
                        </chart>

                        <div class="scroll-container">
                            <div :class="`entry ${isLocal ? 'selectable' : ''}`"
                            v-for="(item, index) in stats.items"
                            :key="index"
                            @click="isLocal ? $refs.list.Open(item.transactions, null, {prev_items: item.data, sections: false, items: false, stats: true, label: item.data, total: item.total_money, color: Util.ChartColor(index, stats.items.length)}, 'items') : null"
                            v-show="Util.InString(item.data, searchItems)">
                                <div class="flex fill align-center">
                                    <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.items.length)}">{{Percent(item.total_money,stats.total_money)}}%</div>
                                    <div class="flex column flex-fill">
                                        <span class="bold">{{item.data}}</span>
                                        <span class="secondary-text"><span class="bold">Cantidad: </span>{{Util.Number(item.count)}}</span>
                                        <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(item.transactions.length)}}</span>
                                    </div>
                                    <span class="bold mr10">{{Util.Price(item.total_money)}}</span>
                                    <!--<i v-if="isLocal" class="icon icon-arrow-right-o c4"></i>-->
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>

            <!-- Modificadores -->
            <div v-if="stats && stats.modifiers.length > 0">
                <div class="">
                    <div class="flex align-center mb20">
                        <div class="f18 flex-fill">Modificadores</div>
                        <input-field inputClass="secondary"
                        v-model="searchModifiers"
                        placeholder="Buscar..."
                        pre="search"
                        cleareable
                        :icon="mobile ? 'search' : ''"
                        :timeout="200">
                        </input-field>
                    </div>
                    
                    <!--
                    <chart class="mb10"
                    :data="[stats.modifiers]"
                    dataset="total_money"
                    type="pie"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="true"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return label}"
                    height="200px">
                    </chart>
                    -->

                    <div class="section ofy" style="max-height: 500px">
                        <div :class="`entry mb10`"
                        v-for="(modifier, index) in stats.modifiers"
                        :key="index"
                        v-show="Util.InString(modifier.data, searchModifiers)">
                            <div class="flex fill align-center">
                                <!-- <div class="percentage-icon" :style="{'background-color':Util.ChartColor(index, stats.items.length)}">{{Percent(item.total_money,stats.total_money)}}%</div>-->
                                <div class="flex column flex-fill">
                                    <span class="bold">{{modifier.data}}</span>
                                    <span class="secondary-text"><span class="bold">Cantidad: </span>{{Util.Number(modifier.count)}}</span>
                                    <!-- <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(item.transactions.length)}}</span>-->
                                </div>
                                <span class="bold mr10">{{Util.Price(modifier.total_money)}}</span>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
            <div v-if="stats && !stats.categories.length && !stats.items.length" class="secondary-text ta-center mt10">No hay datos</div>
        </div>

        <div slot="Pedidos Online" class="pa-body">

            <div v-if="ordersOnline && (ordersOnline.completed.count > 0 || ordersOnline.rejected.count > 0)" class="mb20">
                <div class="f18 mb20">Total de pedidos</div>
                <div class="section hdvm">
                    <chart
                    :data="ordersOnline.chartGeneral"
                    dataset="count"
                    type="pie"
                    :label="(order) => {return order.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    height="200px"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, (ordersOnline.rejected.count + ordersOnline.completed.count) )}%`}">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(order, index) in ordersOnline.general"
                        :key="index"
                        @click="isLocal ? $refs.iapTransactionsList.Open(order.transactions) : null">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, ordersOnline.general.length)}">{{Percent(order.count, (ordersOnline.rejected.count + ordersOnline.completed.count))}}%</div>
                                <span class="bold">{{order.data}}</span>
                                <div class="flex-fill"></div>
                                <span class="bold">{{order.count}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div v-if="ordersOnline && ordersOnline.rejected && ordersOnline.rejected.count > 0" class="mb20">
                <div class="f18 mb20">Motivos de rechazo</div>
                <div class="section hdvm">
                    <chart
                    :data="ordersOnline.chartRejectedReasons"
                    dataset="count"
                    type="pie"
                    :label="(reason) => {return Util.CancelledReason(reason.data)}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    height="200px"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, ordersOnline.rejected.count)}%`}">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(reason, index) in ordersOnline.rejected.rejected_reasons"
                        :key="index"
                        @click="isLocal ? $refs.iapTransactionsList.Open(reason.transactions) : null">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, ordersOnline.rejected.rejected_reasons.length)}">{{Percent(reason.count, ordersOnline.rejected.count)}}%</div>
                                <span class="bold">{{Util.CancelledReason(reason.data)}}</span>
                                <div class="flex-fill"></div>
                                <span class="bold">{{reason.count}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="ordersOnline && ordersOnline.rejected && ordersOnline.rejected.count > 0" class="mb20">
                <div class="f18 mb20">Items rechazados</div>
                <div class="section hdvm">
                    <chart
                    :data="ordersOnline.chartRejectedItems"
                    dataset="count"
                    type="pie"
                    :label="(order) => {return order.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    height="200px"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, ordersOnline.rejected.count_items )}%`}">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        style="height: auto;"
                        v-for="(item, index) in ordersOnline.rejected.items"
                        :key="index"
                        @click="isLocal ? $refs.iapTransactionsList.Open(item.transactions) : null">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, ordersOnline.rejected.items.length)}">{{Percent(item.count, ordersOnline.rejected.count_items)}}%</div>
                                <span class="bold">{{item.data}}</span>
                                <div class="flex-fill"></div>
                                <span class="bold">{{item.count}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div v-if="ordersOnline && ordersOnline.completed && ordersOnline.completed.count > 0" class="mb20">
                <div class="f18 mb20">Items completados</div>
                <div class="section hdvm">
                    <chart
                    :data="ordersOnline.chartCompletedItems"
                    dataset="count"
                    type="pie"
                    :label="(order) => {return order.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    height="200px"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, ordersOnline.completed.count_items )}%`}">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(item, index) in ordersOnline.completed.items"
                        :key="index"
                        @click="isLocal ? $refs.iapTransactionsList.Open(item.transactions) : null">
                        
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, ordersOnline.completed.items.length)}">{{Percent(item.count, ordersOnline.completed.count_items)}}%</div>
                                <span class="bold">{{item.data}}</span>
                                <div class="flex-fill"></div>
                                <span class="bold">{{item.count}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div v-if="!ordersOnline || (ordersOnline.completed.count <= 0 && ordersOnline.rejected.count <= 0)" class="secondary-text ta-center mt10">No hay datos</div>    
        </div>

        <!-- Horarios --> 
        <div slot="Horarios" v-if="stats" class="pa-body">

            <!-- Dias -->
            <div v-if="stats && stats.days.length > 0" class="mb20">
                
                <div class="f18 mb20">Días</div>

                <div class="section hdvm">

                    <chart
                    :data="stats.chartDays"
                    dataset="total_money"
                    type="pie"
                    :label="(data) => {return days[data.data]}"
                    :singleDataset="false"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_money)}%`}"
                    height="200px"
                    :customColor="[null, null]"
                    :keyColors="Util.Chart.days">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(day, index) in stats.days"
                        :key="index"
                        @click="isLocal ? $refs.list.Open(day.transactions, null, {stats: true, days: false, label: days[day.data], total: day.total_money, color: Util.ChartColor(index, stats.days.length, Util.Chart.days, day.data)}) : null">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.days.length, Util.Chart.days, day.data)}">{{Percent(day.total_money, stats.total_money)}}%</div>
                                <div class="flex column">
                                    <span class="bold">{{days[day.data]}}</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(day.count)}}</span>
                                    <span class="secondary-text"><span class="bold">Promedio Venta: </span>{{Util.Price(Math.floor(day.total_money/day.transactions.length))}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(day.total_money)}}</span>
                                <!--<i v-if="isLocal" class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>
              
            </div>
            
            <div v-if="stats && stats.hours.length > 0">
                
                <div class="f18 mb20">Horas</div>

                <div class="section hdvm">

                    <chart
                    :data="stats.chartHours"
                    dataset="total_money"
                    type="pie"
                    :label="(data) => {return data.data + ' hrs'}"
                    :singleDataset="false"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_money)}%`}"
                    height="200px"
                    :customColor="[null, null]"
                    :keyColors="Util.Chart.hours">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(hour, index) in stats.hours"
                        :key="index"
                        @click="isLocal? $refs.list.Open(hour.transactions, null, {stats: true, hours: false, label: hour.data + ' hrs', total: hour.total_money, color: Util.ChartColor(index, stats.hours.length, Util.Chart.hours, hour.data)}) : ''">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.hours.length, Util.Chart.hours, hour.data)}">{{Percent(hour.total_money, stats.total_money)}}%</div>
                                <div class="flex column">
                                    <span class="bold">{{hour.data}} hrs</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(hour.count)}}</span>
                                    <span class="secondary-text"><span class="bold">Promedio Venta: </span>{{Util.Price(Math.floor(hour.total_money/hour.transactions.length))}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(hour.total_money)}}</span>
                                <!--<i v-if="isLocal" class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>
  
            </div>
            <div v-if="stats.hours.length == 0 && stats.days.length == 0" class="secondary-text ta-center mt10">No hay datos</div>    
        </div>

        
        <div slot="Venta" v-if="stats" class="pa-body">
            <!--tipo de venta -->
            <div v-if="stats.sale.length > 0" class="mb20">
                <div class="f18 mb20">Tipo de Venta</div>

                <div class="section hdvm">

                    <chart
                    :data="stats.chartSale"
                    dataset="total_money"
                    type="pie"
                    :label="(data) => {return saleType[data.data]}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_money)}%`}"
                    height="200px"
                    :customColor="[null, null]"
                    :keyColors="Util.Chart.orderType">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(sale, index) in stats.sale"
                        :key="index"
                        @click="isLocal ? $refs.list.Open(sale.transactions, null, {stats: true, sale: false, label: saleType[sale.data], total: sale.total_money, color: Util.ChartColor(index, stats.sale.length, Util.Chart.orderType, sale.data)}) : null">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.sale.length, Util.Chart.orderType, sale.data)}">{{Percent(sale.total_money, stats.total_money)}}%</div>
                                <div class="flex column">
                                    <span class="bold">{{saleType[sale.data]}}</span>
                                    <!-- <span class="secondary-text"><span class="bold">Total: </span>{{Util.Price(sale.total_money)}}</span> -->
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(sale.count)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(sale.total_money)}}</span>
                                <!--<i v-if="isLocal" class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>
   
            </div>

            <div v-if="stats.tables.length > 0" class="mb20">
                <div class="f18 mb20">Mesas</div>

                <div class="section">
                    <div class="hdvm">

                        <chart
                        :data="stats.chartTables"
                        dataset="total_money"
                        type="pie"
                        :label="(data) => {return data.data}"
                        :singleDataset="false"
                        :maxDatasets="7"
                        :showLegend="false"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_money)}%`}"
                        height="200px">
                        </chart>

                        <div class="scroll-container">
                            <div :class="`entry ${isLocal?'selectable':''}`"
                            v-for="(table, index) in stats.tables"
                            :key="index"
                            @click="isLocal ? $refs.list.Open(table.transactions, null, {stats: true, sale: false, label: table.data, total: table.total_money, color: Util.ChartColor(index, stats.tables.length)}) : null">
                                <div class="flex align-center fill">
                                    <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.tables.length)}">{{Percent(table.total_money, stats.total_tables)}}%</div>
                                    <div class="flex column">
                                        <span class="bold">{{table.data}}</span>
                                        <!-- <span class="secondary-text"><span class="bold">Total: </span>{{Util.Price(sale.total_money)}}</span> -->
                                        <span class="secondary-text" v-tooltip="'Promedio por comensal'"><span class="bold">Comensales: </span>{{Util.Number(table.customers)}} <span v-if="table.customers">({{Util.Price(table.total_money / table.customers)}})</span></span>
                                        <span class="secondary-text" v-tooltip="'Ticket promedio de la mesa'"><span class="bold">Transacciones: </span>{{Util.Number(table.transactions.length)}} <span v-if="table.transactions.length">({{Util.Price(table.total_money / table.transactions.length)}})</span></span>
                                    </div>
                                    <div class="flex-fill"></div>
                                    <span class="bold mr10">{{Util.Price(table.total_money)}}</span>
                                    <!--<i v-if="isLocal" class="icon icon-arrow-right-o c4"></i>-->
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="separator mb20 mt20"></div>

                    <div class="hdvm">
                        <div class="well flex align-center">
                            <avatar class="mr10"
                            icon="table"
                            :color="Util.baseColors.green">
                            </avatar>
                            <div class="flex-fill">
                                <div class="bold">Total</div>
                                <div class="secondary-text" v-if="stats.table_transactions.length"><span class="bold">Ticket Promedio:</span> {{Util.Price(stats.total_tables / stats.table_transactions.length)}}</div>
                            </div>
                            <div class="ta-right">
                                <div class="f18">{{Util.Price(stats.total_tables)}}</div>
                                <div class="secondary-text" v-if="stats.table_transactions.length">{{Util.Number(stats.table_transactions.length)}} transacciones</div>
                            </div>
                            
                        </div>

                        <div class="well flex align-center">
                            <avatar class="mr10"
                            icon="user"
                            :color="Util.baseColors.green">
                            </avatar>
                            <div class="flex-fill">
                                <div class="bold">Comensales</div>
                                <div class="secondary-text" v-if="stats.total_customers_tables"><span class="bold">Promedio por comensal:</span> {{Util.Price(stats.total_tables / stats.total_customers_tables)}}</div>
                            </div>
                            <div class="f18">{{Util.Number(stats.total_customers_tables)}}</div>
                        </div>

                    </div>

                </div>
   
            </div>

            <!-- Metodo de pago -->
            <div v-if="stats.pay_methods.length > 0" class="mb20">
                <div class="f18 mb20">Métodos de Pago</div>

                <div class="section hdvm">

                    <chart
                    :data="stats.chartPayMethods"
                    dataset="total_money"
                    type="pie"
                    :label="(data) => {return Util.PayMethod(data.data)}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_money)}%`}"
                    height="200px">
                    </chart>

                    <div class="scroll-container">
                        <div class="entry selectable"
                        v-for="(method, index) in stats.pay_methods"
                        :key="index"
                        @click="$refs.list.Open(method.transactions, null, {stats: true, method: false, label: Util.PayMethod(method.data), total: method.total_money, color: Util.ChartColor(index, stats.pay_methods.length)})">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.pay_methods.length)}">{{Percent(method.total_money,stats.total_money)}}%</div>
                                <div class="flex column">
                                    <span class="bold">{{Util.PayMethod(method.data)}}</span>
                                    <!-- <span class="secondary-text"><span class="bold">Total: </span>{{Util.Price(method.total_money)}}</span> -->
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(method.transactions.length)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(method.total_money)}}</span>
                                <!--<i class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <!-- medio de venta -->
            <div v-if="stats.medium.length > 0">
                <div class="f18 mb20">Medio de Venta</div>

                <div class="section hdvm">

                    <chart
                    :data="stats.chartMedium"
                    dataset="total_money"
                    type="pie"
                    :label="(data) => {return mediums[data.data]}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_money)}%`}"
                    height="200px"
                    :customColor="[null, null]"
                    :keyColors="Util.Chart.saleType">
                    </chart>

                    <div class="scroll-container">
                        <div :class="`entry ${isLocal?'selectable':''}`"
                        v-for="(medium, index) in stats.medium"
                        :key="index"
                        @click="isLocal ? $refs.list.Open(medium.transactions, null, {stats: true, source: false, label: mediums[medium.data], total: medium.total_money, color: Util.ChartColor(index, stats.medium.length, Util.Chart.saleType, medium.data)}) : null">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.medium.length, Util.Chart.saleType, medium.data)}">{{Percent(medium.total_money, stats.total_money)}}%</div>
                                <div class="flex column">
                                    <span class="bold">{{mediums[medium.data]}}</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(medium.count)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(medium.total_money)}}</span>
                                <!--<i v-if="isLocal" class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>
   
            </div>

            <div v-if="stats && !stats.sale.length && !stats.pay_methods.length" class="secondary-text ta-center mt10">No hay datos</div>    
        </div>

         <!-- Ingresado Por -->
        <div slot="Personal" v-if="stats" class="pa-body">
            
            <div v-if="stats && stats.creation.length > 0" class="mb20">
                
                <div class="f18 mb20">Ingresada por</div>

                <div class="section hdvm">

                    <chart class="mb10"
                    :data="stats.chartCreation"
                    dataset="count"
                    type="pie"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Math.round(100*value/stats.completed.length)}%`}"
                    height="200px">
                    </chart>

                    <div class="scroll-container">
                        <div class="entry selectable"
                        v-for="(data, index) in stats.creation"
                        :key="index"
                        @click="$refs.list.Open(data.transactions, null, {stats: true, label: data.data, total: data.total_money, color: Util.ChartColor(index, stats.creation.length)})">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.creation.length)}">{{Math.round(100*data.count/stats.completed.length)}}%</div>
                                <div class="flex column">
                                    <span class="bold">{{data.data}}</span>
                                    <!--<span class="secondary-text"><span class="bold">Total: </span>{{Util.Price(data.total_money)}}</span>-->
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(data.count)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(data.total_money)}}</span>
                                <!--<i class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>
            
            </div>

            <!-- Delivery -->
            <div v-if="stats && stats.delivery_methods.length" class="mb20">
                <div class="f18 mb20">Reparto</div>

                <div class="section mb10">
                    <div>
                        <div class="entry selectable"
                        v-for="(data, index) in stats.delivery_staff"
                        :key="index+'staff'"
                        @click="$refs.list.Open(data.transactions)">
                            <div class="flex align-center fill">
                                <avatar class="mr10"
                                :label="data.data"
                                :color="Util.baseColors.blue">
                                </avatar>
                                <div class="flex column">
                                    <span class="bold">{{data.data}}</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(data.count)}}</span>
                                    <span class="secondary-text" v-if="data.count" v-tooltip="'Tiempo entrega promedio'"><span class="bold">Entrega: </span>{{Util.SecondsToTime(data.time/data.count)}}</span>
                                    <span class="secondary-text" v-for="(method, keyMethod) in data.methods" :key="keyMethod" v-show="method.total_money"><span class="bold">{{Util.PayMethod(method.data)}}: </span>{{Util.Price(method.total_money)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(data.delivery)}}</span>
                                <!--<i class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>

                <div class="section">
                    <!--<div class="f18 mb20">Método de Pago</div>-->

                    <div>
                        <div class="entry selectable"
                        v-for="(data, index) in stats.delivery_methods"
                        :key="index+'methods'"
                        @click="$refs.list.Open(data.transactions)">
                            <div class="flex align-center fill">
                                <avatar class="mr10"
                                :imageIcon="data.data"
                                defaultImageIcon="59">
                                </avatar>
                                <div class="flex column">
                                    <span class="bold">Total {{Util.PayMethod(data.data)}}</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(data.count)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(data.delivery)}}</span>
                                <!--<i class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>

            <!-- Delivery Externo -->
            <div v-if="stats && stats.delivery_external_methods.length" class="mb20">
                
                <div class="f18 mb20">Reparto Externo</div>

                <div class="section mb10">

                    <div>
                        <div class="entry selectable"
                        v-for="(data, index) in stats.delivery_external_staff"
                        :key="index+'staff'"
                        @click="$refs.list.Open(data.transactions)">
                            <div class="flex align-center fill">
                                <avatar class="mr10"
                                :label="data.data"
                                :color="Util.baseColors.blue">
                                </avatar>
                                <div class="flex column">
                                    <span class="bold">{{data.data}}</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(data.count)}}</span>
                                    <span class="secondary-text" v-for="(method, keyMethod) in data.methods" :key="keyMethod" v-show="method.total_money"><span class="bold">{{Util.PayMethod(method.data)}}: </span>{{Util.Price(method.total_money)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(data.delivery)}}</span>
                                <!--<i class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>

                    <!--<div class="f18 mb20">Método de Pago</div>-->
                <div class="section">
                    <div>
                        <div class="entry selectable"
                        v-for="(data, index) in stats.delivery_external_methods"
                        :key="index+'methods'"
                        @click="$refs.list.Open(data.transactions)">
                            <div class="flex align-center fill">
                                <avatar class="mr10"
                                :imageIcon="data.data"
                                defaultImageIcon="59">
                                </avatar>
                                <div class="flex column">
                                    <span class="bold">Total {{Util.PayMethod(data.data)}}</span>
                                    <!--<span class="secondary-text"><span class="bold">Total: </span>{{Util.Price(data.total_money)}}</span>-->
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(data.count)}}</span>
                                    <!-- <span class="secondary-text"><span class="bold">Monto transladado: </span>{{Util.Price(data.total_money)}}</span> -->
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(data.delivery)}}</span>
                                <!--<i class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>
               
            </div>

            <!-- Propinas -->
            <div v-if="stats && stats.tips_methods.length > 0">
                <div class="f18 mb20">Propinas</div>
          
                <div class="section mb10">
        
                    <div>
                        <div class="entry selectable"
                        v-for="(data, index) in stats.tips_staff"
                        :key="index+'tip_staff'"
                        @click="$refs.list.Open(data.transactions)">
                            <div class="flex align-center fill">
                                <avatar class="mr10"
                                :label="data.data"
                                color="AUTO">
                                </avatar>
                                <div class="flex column">
                                    <span class="bold">{{data.data}}</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(data.transactions.length)}}</span>
                                    <span class="secondary-text" v-for="(method, keyMethod) in data.methods" :key="keyMethod"><span class="bold">{{Util.PayMethod(method.data)}}: </span>{{Util.Price(method.total_money)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(data.total_money)}}</span>
                                <!--<i class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>

                    <!--<div class="f18 mb20">Método de Pago</div>-->
                
                <div class="section">
                    <div>
                        <div class="entry selectable"
                        v-for="(data, index) in stats.tips_methods"
                        :key="index+'tip_method'"
                        @click="$refs.list.Open(data.transactions)">
                            <div class="flex align-center fill">
                                <avatar class="mr10"
                                :imageIcon="data.data"
                                defaultImageIcon="59">
                                </avatar>
                                <div class="flex column">
                                    <span class="bold">Total {{Util.PayMethod(data.data)}}</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(data.transactions.length)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(data.total_money)}}</span>
                                <!--<i class="icon icon-arrow-right-o c4"></i>-->
                            </div>
                        </div>
                    </div>

                </div>
            
            </div>

            
        

        </div>

        <!-- Clientes -->
        <div slot="Clientes" v-if="stats" class="pa-body">

            <div v-if="stats.clients.length > 0" class="mb20">

                <div class="flex align-center mb20">
                    <div class="f18 flex-fill">Clientes <div class="counter">{{stats.clients.length}}</div></div>
                    <input-field inputClass="secondary"
                    v-model="searchCustomers"
                    placeholder="Buscar..."
                    pre="search"
                    cleareable
                    :icon="mobile ? 'search' : ''"
                    :timeout="200">
                    </input-field>
                </div>

                <div class="section hdvm">
                    <chart class="mb10"
                    :data="stats.chartClients"
                    dataset="total_money"
                    type="pie"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="5"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Math.round(100*value/stats.total_clients)}%`}"
                    height="200px">
                    </chart>

                    <!-- <div class="scroll-container"> MIGUEL: CAMBIAR IMAGEN CON INICIALES POR PORCENTAJE CORRESPONDIENTE EN LA LISTA DE ABAJO
                        <div class="entry selectable"
                        v-for="(data, index) in stats.clients"
                        :key="index">
                            <div class="flex align-center fill">
                                <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.clients.length)}">{{Math.round(100*data.total_money/stats.total_clients)}}%</div>
                                <div class="flex column">
                                    <span class="bold">{{data.data}}</span>
                                    <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(data.total_money)}}</span>
                                </div>
                                <div class="flex-fill"></div>
                                <span class="bold mr10">{{Util.Price(data.total_money)}}</span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div v-if="stats.clients.length > 0">
                <div>
                    <div :class="`section mb10 ${isLocal?'selectable':''}`"
                    v-for="(client, index) in stats.clients"
                    :key="index"
                    v-show="Util.InString(client.data, searchCustomers)"
                    @click="isLocal ? $refs.list.Open(client.transactions, null, {stats: true, label: client.data, total: client.total_money, color: Util.ChartColor(index, stats.clients.length)}) : ''">
                        <div class="flex align-center fill">
                            <div class="percentage-icon cw m10" :style="{'background-color': Util.ChartColor(index, stats.clients.length)}">{{Math.round(100*client.total_money/stats.total_clients)}}%</div>
                            <div class="flex column">
                                <span class="bold">{{client.data}} <i class="icon icon-star" style="color: rgb(0, 151, 255)" v-if="client.influye"></i></span>
                                <!--<span class="secondary-text"><span class="bold">Total: </span>{{Util.Price(client.total_money)}}</span>-->
                                <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(client.count)}}</span>
                                <span class="secondary-text"><span class="bold">Última Visita: </span>{{Util.Date(client.last_transaction, desktop ? 'LL' : 'DD/MM/YY')}}</span>
                                <!--<span class="secondary-text"><span class="bold">Primera Visita: </span>{{Util.Date(client.first_transaction,  desktop ? 'LL' : 'DD/MM/YY')}}</span>-->
                            </div>
                            <div class="flex-fill"></div>
                            <span class="bold mr10">{{Util.Price(client.total_money)}}</span>
                            <!--<i v-if="isLocal" class="icon icon-arrow-right-o c4"></i>-->
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text ta-center mt10">No hay datos</div>    
        </div>

        <!-- sublocales -->
        <div slot="Locales" v-if="stats" class="pa-body">
            <div v-if="stats.locals.length" class="section">
                <chart class="mb10"
                :data="stats.chartLocals"
                dataset="total_money"
                type="pie"
                :label="(data) => {return data.data}"
                :singleDataset="false"
                :maxDatasets="7"
                :showLegend="true"
                :xLabelFormat="null"
                :yLabelFormat="null"
                :tooltip="(value, label, index) => {return `${label} ${Percent(value, stats.total_money)}%`}"
                height="200px">
                </chart>

                <div class="well mb5"
                v-for="(local, index) in stats.locals"
                :key="index">
                    <div class="flex align-center fill">
                        <div class="percentage-icon cw" :style="{'background-color':Util.ChartColor(index, stats.locals.length)}">{{Percent(local.total_money, stats.total_money)}}%</div>
                        <div class="flex column">
                            <span class="bold">{{local.data}}</span>
                            <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(local.count)}}</span>
                        </div>
                        <div class="flex-fill"></div>
                        <span class="bold mr10">{{Util.Price(local.total_money)}}</span>
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text ta-center mt10">No hay datos</div>    
        </div>

    </tabs>

<!--Modal Transaction Search -->
<modal v-model="modalTransactionSearch" dir="center" modalClass="sm" title="Buscar Transacción">
    <div class="section">
        <input-field
        v-model="transactionNumber"
        label="Número Ticket"
        type="int+"
        prefix="# "
        @OnEnter="SearchTransaction(0)">
        </input-field>
    </div>
    
    <button slot="actions" class="primary purple" @click="SearchTransaction(0)">Buscar</button>
    
</modal>

<modal v-model="modalDetails" dir="right" :modalClass="['fill borderless', 'md right h100']" :title="modalDetails && modalDetails.title? modalDetails.title : 'Detalles'" hideActions>
    <i slot="pre" v-tooltip="'Atrás'" v-if="modalDetails && modalDetails.back" class="options icon-arrow-left mr5" @click="modalDetails=itemDetails"></i>
    <i slot="pre" v-tooltip="'Atrás'" v-if="modalDetails && modalDetails.back_group" class="options icon-arrow-left mr5" @click="modalDetails=groupModifiersDetails"></i>
    <div v-if="modalDetails && modalDetails.items.length == 0">
        <div class="secondary-text ta-center mt10">No hay datos</div>
    </div>
    <div v-else>
        <div  v-if="modalDetails" class="pa20">
            <div>
                <chart
                    v-if="modalDetails.total_items != 0"
                    :data="modalDetails.chart"
                    dataset="total_money"
                    type="pie"
                    class="mb20"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="false"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    height="200px"
                    :tooltip="(value, label, index) => {return `${label} ${Percent(value, modalDetails.total_items)}%`}">
                </chart>
                <div :class="`entry ${modalDetails && modalDetails.level!= 2?'selectable':''}`" v-for="(item, index) in modalDetails.items" 
                    :key="index"
                    @click="modalDetails? selectedLevelDetails(modalDetails.level, modalDetails.items, item.data) : null">
                    <div class="flex align-center fill">
                        <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, modalDetails.items.length)}">{{modalDetails.total_items == 0? 0 : Percent(item.total_money, modalDetails.total_items)}}%</div>
                        <span class="bold">{{item.data}}</span>
                        <div class="flex-fill"></div>
                        <span class="bold">{{Util.Price(item.total_money)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>

<modal v-model="modalTransactionsCanceled" dir="right" :modalClass="['fill borderless', 'lg right h100']" :title="'Detalles de transacciones canceladas'">
    
    <div class="pa20" v-if="modalTransactionsCanceled && modalTransactionsCanceled.count > 0">
        
        <div class="mb20">
            <div class="f18 mb10">Motivos de rechazo</div>
            <div class="hdvm">
                <chart
                :data="modalTransactionsCanceled.chartReasons"
                dataset="count"
                type="pie"
                :label="(reason) => {return Util.CancelledReason(reason.data)}"
                :singleDataset="false"
                :maxDatasets="7"
                :showLegend="false"
                :xLabelFormat="null"
                :yLabelFormat="null"
                height="200px"
                :tooltip="(value, label, index) => {return `${label} ${Percent(value, modalTransactionsCanceled.count)}%`}">
                </chart>

                <div class="scroll-container">
                    <div :class="`entry ${isLocal?'selectable':''}`"
                    v-for="(reason, index) in modalTransactionsCanceled.rejected_reasons"
                    :key="index"
                    @click="$refs.list.Open(reason.transactions)">
                        <div class="flex align-center fill">
                            <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, modalTransactionsCanceled.rejected_reasons.length)}">{{Percent(reason.count, modalTransactionsCanceled.count)}}%</div>
                            <span class="bold">{{Util.CancelledReason(reason.data)}}</span>
                            <div class="flex-fill"></div>
                            <span class="bold">{{reason.count}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb20">
            <div class="f18 mb10">Ítems cancelados</div>
            <div class="hdvm">
                <chart
                :data="modalTransactionsCanceled.chartItems"
                dataset="count"
                type="pie"
                :label="(item) => {return item.data}"
                :singleDataset="false"
                :maxDatasets="7"
                :showLegend="false"
                :xLabelFormat="null"
                :yLabelFormat="null"
                height="200px"
                :tooltip="(value, label, index) => {return `${label} ${Percent(value, modalTransactionsCanceled.count_items)}%`}">
                </chart>

                <div class="scroll-container">
                    <div :class="`entry ${isLocal?'selectable':''}`"
                    v-for="(item, index) in modalTransactionsCanceled.items"
                    :key="index"
                    @click="$refs.list.Open(item.transactions)">
                        <div class="flex align-center fill">
                            <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, modalTransactionsCanceled.items.length)}">{{Percent(item.count, modalTransactionsCanceled.count_items)}}%</div>
                            <span class="bold">{{item.data}}</span>
                            <div class="flex-fill"></div>
                            <span class="bold">{{item.count}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="f18 mb10">Personas que cancelaron</div>
            <div class="hdvm">
                <chart
                :data="modalTransactionsCanceled.chartPeople"
                dataset="count"
                type="pie"
                :label="(person) => {return person.data}"
                :singleDataset="false"
                :maxDatasets="7"
                :showLegend="false"
                :xLabelFormat="null"
                :yLabelFormat="null"
                height="200px"
                :tooltip="(value, label, index) => {return `${label} ${Percent(value, modalTransactionsCanceled.count)}%`}">
                </chart>

                <div class="scroll-container">
                    <div :class="`entry ${isLocal?'selectable':''}`"
                    v-for="(person, index) in modalTransactionsCanceled.people"
                    :key="index"
                    @click="$refs.list.Open(person.transactions)">
                        <div class="flex align-center fill">
                            <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, modalTransactionsCanceled.people.length)}">{{Percent(person.count, modalTransactionsCanceled.count)}}%</div>
                            <span class="bold">{{person.data}}</span>
                            <div class="flex-fill"></div>
                            <span class="bold">{{person.count}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button slot="actions" class="secondary" @click="$refs.list.Open(stats.cancelled)">Ver todas las transacciones</button>
</modal>


<transaction-details ref="transactionDetails" @OnTransactionDeleted="OnTransactionDeleted"></transaction-details>

<transactions-list ref="list" @OnTransactionDeleted="OnTransactionDeleted"></transactions-list>

<iap-transactions-list ref="iapTransactionsList"></iap-transactions-list>

</div>
</template>

<script>
var moment = require('moment');
moment.locale('es');
export default {
    props: {
        showDetails: {
            type: Boolean,
            default: true
        },
        limitRange: {
            type: Boolean,
            default: false
        },
        ticket_id: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            data: [],
            dataGroup: null,
            colors: Util.colors,
            stats: null,
            range: {
                option: 'MONTH',
                index: 0
            },
            status: null,
            transactionNumber: 0,
            transaction: null,
            filter: null,
            currentPage: 0,
            collection: {
				heightType: 'automatic',
				itemHeight: 50,
            },
            currentTransactions: [],
            //variables para cargar datos on demand
            currentTab: null,
            maxDataPerTab: 10,
            currentDataPerTab: 10,
            dataStep: 10,
            loadingMoreData: false,
            modalTransactionSearch: false,
            saleType: {
                TAKEAWAY: 'Llevar',
                SERVE: 'Servir',
                DELIVERY: 'Delivery'
            },
            days: {
                0: 'Domingo',
                1: 'Lunes',
                2: 'Martes',
                3: 'Miércoles',
                4: 'Jueves',
                5: 'Viernes',
                6: 'Sábado'
            },
            sublocals: [],
            idLocal: null,
            mediums: {
                INFLUYE_ADMIN: 'Punto de Venta',
                INFLUYE_APP: 'Influye App',
                INFLUYE_WEB: 'Ecommerce'
            },
            tabs: null,
            items: null,
            modalDetails: null,
            itemDetails: null,
            groupModifiersDetails: null,
            ordersOnline: null,
            RangeOrdersOnline: null,
            modalTransactionsCanceled: null,
            searchModifiers: '',
            searchItems: '',
            searchSections: '',
            searchCustomers: ''
        }
    },
    mounted() {

        this.tabs = [];
        this.tabs.push('General');
        if(this.Local.modules.module_transaction_stats) this.tabs.push('Menu');
        if(this.Local.modules.module_store) this.tabs.push('Pedidos Online');
        if(this.Local.modules.module_transaction_stats) this.tabs.push('Horarios');
        if(this.Local.modules.module_transaction_stats) this.tabs.push('Venta');
        if(this.Local.modules.module_transaction_stats) this.tabs.push('Clientes');
        if(this.Local.modules.module_transaction_stats && this.Local.type == 'LOCAL') this.tabs.push('Personal');
        if(this.Local.type == 'FRANCHISE') this.tabs.push('Locales');

        this.idLocal = this.Local.id;
        /*
        this.$nextTick(() => {
            var moment = require('moment');
            this.range = {
                option: 'DAY',
                index: 0,
                label: 'Hoy',
                rangeStart: moment().startOf('day').unix(),
                rangeEnd: moment().unix(),
            };
            this.Search();

            if(this.Local.type == 'FRANCHISE')
            {
                axios.get(`/sublocals/${this.Local.id}`).then(res => {
                    console.log(res.data);
                    var sublocals = [{text: this.Local.name, value: this.Local.id}];
                    res.data.sublocals.forEach(sublocal => {
                        sublocals.push({text: sublocal.name, value: sublocal.id});
                    });
                    res.data.attached.forEach(sublocal => {
                        sublocals.push({text: sublocal.name, value: sublocal.id});
                    });
                    this.sublocals = sublocals;
                }).catch(err => {
                    console.log(err);
                });
            }

        });
        */
    },
    methods: {
        Search(range) {
            this.$root.$emit('Loading');

            if(this.currentTab == 'Pedidos Online'){
                this.SearchOnline(range);
            }
            
            this.searchCustomers = '';
            this.searchItems = '';
            this.searchSections = '';
            this.searchModifiers = '';

            axios.post(`/transactions/${this.idLocal}/stats`, range).then(res => {                

                var total_categories = 0;
                for(var i = 0; i < res.data.categories.length; i++)
                    total_categories += res.data.categories[i].count;
                res.data.total_categories = total_categories;

                var total_sections = 0;
                for(var i = 0; i < res.data.sections.length; i++)
                    total_sections += res.data.sections[i].total_money;
                res.data.total_sections = total_sections;

                //generar lista de modificadores
                var modifiers = [];
                for(var i = 0; i < res.data.items.length; i++)
                {
                    var item = res.data.items[i];
                    for(var key in item.modifiers)
                    {
                        var modifier = item.modifiers[key];
                        if(modifier.data !== 'Sin Modificadores')
                        {
                            modifiers.push({
                                data: `${item.data} - ${modifier.data}`,
                                count: modifier.count,
                                total_money: modifier.total_money
                            });
                        }
                    };
                };
                modifiers.sort((a, b) => {
                    return b.total_money - a.total_money;
                });
                res.data.modifiers = modifiers;

                let totalClients = 0;
                for(let i = 0; i < res.data.clients.length; i++)
                    totalClients += res.data.clients[i].total_money;
                res.data.total_clients = totalClients;

                if(res.data && res.data.history && res.data.history.length)
                {
                    console.log(res.data.history);
                }

                res.data.chartHistory = [res.data.history];
                res.data.chartCategories = [res.data.categories];
                res.data.chartSections = [res.data.sections];
                res.data.chartItems = [res.data.items];
                res.data.chartDays = [res.data.days];
                res.data.chartHours = [res.data.hours];
                res.data.chartSale = [res.data.sale];
                res.data.chartPayMethods = [res.data.pay_methods];
                res.data.chartMedium = [res.data.medium];
                res.data.chartCreation = [res.data.creation];
                res.data.chartClients = [res.data.clients];
                res.data.chartLocals = [res.data.locals];
                res.data.chartTables = [res.data.tables];

                let arrTablesTransactions = [];
                res.data.tables.forEach(table => {
                    arrTablesTransactions = arrTablesTransactions.concat(table.transactions);
                });
                arrTablesTransactions.sort((a, b) => {return b - a});
                res.data.table_transactions = arrTablesTransactions;
                

                this.stats = res.data;
                this.items = res.data.items;

                this.$root.$emit('Response', res);
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        SearchTransaction(value)
        {
            if(value) this.transactionNumber = parseInt(this.transactionNumber) + value;

            this.$root.$emit('Loading');
            axios.get(`/local/${this.Local.id}/transaction/${this.transactionNumber}`).then(res => {
                if(!res.data.error)
                    this.$refs.transactionDetails.Open(res.data.transaction);
                this.$root.$emit('Response', res);
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        DeleteTransaction()
        {
            if(!this.transaction) return;
            this.$root.$emit('Loading');
            axios.delete(`/rest/transaction/${this.transaction.transaction.id}`).then(res => {
                this.$root.$emit('Response', res);
                this.transaction = null;
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        LoadMoreTabData(tab) {
            if(!this.loadingMoreData)
            {
                this.loadingMoreData = true;
                this.currentDataPerTab += this.dataStep;
                setTimeout(() => {
                    this.loadingMoreData = false;
                }, 100);
            }
        },
        Export() 
        {
            this.Loading();
            axios.post(`/transactions/${this.Local.id}/export?v2=true`, this.range, {responseType: 'blob'}).then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `transacciones_${this.Util.Date(this.range.rangeStart, 'DD-MM-YYYY')}_${this.Util.Date(this.range.rangeEnd, 'DD-MM-YYYY')}.xlsx`);
            }).catch(err => {
                this.Response(err);
            });
        },
        Percent(amount, total) 
        {
            if(total <= 0) return '';
            return Math.round(100*amount/total);
        },
        LocalChanged(id)
        {
            console.log(id);
            this.idLocal = id;
            this.Search();
        },
        openModalDetails(section, total_section){
            let records = [];

            for(let i in this.items){
                if(this.items[i].section === section){
                    records.push(this.items[i]);
                }
            }
            this.modalDetails = {};
            this.modalDetails.title = 'Detalle de '+section;
            this.modalDetails.items = records;
            this.modalDetails.chart = [records];
            this.modalDetails.total_items = total_section;
            this.modalDetails.level = 0;

            this.itemDetails = this.modalDetails;
        },
        openModalModifierGroupDetails(item){
            let groups = [];
            let totalItem = 0;

            for(let indexItems in this.items){
                for(let indexItem in this.items[indexItems].modifiers){
                    let itemsModifiers = this.items[indexItems].modifiers;

                    if(itemsModifiers[indexItem].item == item){
                        let response = groups.find(group => group.data == itemsModifiers[indexItem].group);
                        if (!response) {
                            let newGroup = {
                                data: itemsModifiers[indexItem].group,
                                total_money: itemsModifiers[indexItem].total_money,
                                modifier: [
                                    [
                                        itemsModifiers[indexItem].data,
                                        itemsModifiers[indexItem].total_money
                                    ]
                                ]
                            };
                            
                            groups.push(newGroup);
                        }else{
                            response.total_money += itemsModifiers[indexItem].total_money;
                            response.modifier.push([itemsModifiers[indexItem].data, itemsModifiers[indexItem].total_money]);
                        }

                        totalItem += itemsModifiers[indexItem].total_money;
                    }
                }
            }

            groups.sort(function (a, b) {
                return b.total_money - a.total_money;
            });

            this.modalDetails = {};
            this.modalDetails.title = 'Detalle de '+item;
            this.modalDetails.items = groups;
            this.modalDetails.chart = [groups];
            this.modalDetails.total_items = totalItem;
            this.modalDetails.item = item;
            this.modalDetails.back = true;
            this.modalDetails.level = 1;
            
            this.groupModifiersDetails = this.modalDetails;
        },
        openModalModifierDetails(modifiersGroup, dataModifier){
            let modifiers = [];
            let total_modifier = 0;

            modifiersGroup.forEach(modifierGroup => {
                if (modifierGroup.data === dataModifier) {
                    modifierGroup.modifier.forEach( modifier => {
                        modifiers.push({
                            data: modifier[0],
                            total_money: modifier[1]
                        });
                        total_modifier += modifier[1];
                    })
                }
            });

            this.modalDetails = {};
            this.modalDetails.title = 'Detalle de '+dataModifier;
            this.modalDetails.items = modifiers;
            this.modalDetails.chart = [modifiers];
            this.modalDetails.total_items = total_modifier;
            this.modalDetails.back_group = true;
            this.modalDetails.level = 2;

        },
        selectedLevelDetails(level, modalIteml, item){
            switch (level) {
                case 0:
                    return this.openModalModifierGroupDetails(item);
                case 1:
                    return this.openModalModifierDetails(modalIteml, item);
                default:
                    break;
            }
        },
        SearchOnline(range){
            if(this.RangeOrdersOnline == range) return;

            this.Loading();

            axios.post(`/transactions/online/${this.idLocal}/stats`, range).then( ({data}) => {
                data.general = Object.values(data);
                data.general.sort((a, b) => {return b.count - a.count});

                data.chartGeneral = [data.general];
                data.chartRejectedReasons = [Object.values(data.rejected.rejected_reasons)];
                data.chartRejectedItems = [Object.values(data.rejected.items)];
                data.chartCompletedItems = [Object.values(data.completed.items)];

                this.ordersOnline = data;
                this.RangeOrdersOnline = range;
                this.Response(this.ordersOnline);
            }).catch(err => {
                this.Response(err);
            });
        },
        detailsTransactionsCanceled(transactionsCanceled){
            if(transactionsCanceled.length == 0) return;

            this.Loading();

            axios.post(`/transactions/canceled/${this.Local.id}`, {
                ids: transactionsCanceled
            }).then( ({data}) => {
                data.chartReasons = [Object.values(data.rejected_reasons)];
                data.chartItems = [Object.values(data.items)];
                data.chartPeople = [Object.values(data.people)];
                this.modalTransactionsCanceled =  data;
                this.Response(this.modalTransactionsCanceled);
            }).catch(err => {
                this.Response(err);
            });
        },
        OnTransactionDeleted(id)
        {
            this.Search(this.range);
        },
    },
    computed: {
        selectedTransactions() {
            var transactions = this.filter ? this.filter.transactions : this.data;
            var start = this.currentPage*25;
            var finish = Math.min(start + 25, transactions.length);
            return transactions.slice(start, finish);
        },
        totalPages() {
            var transactions = this.filter ? this.filter.transactions : this.data;
            return Math.ceil(transactions.length/25);
        },
        deliveryTransactions() {
            if(!this.stats) return null;
            for(var i = 0; i < this.stats.sale.length; i++)
            {
                if(this.stats.sale[i].data == 'DELIVERY') return this.stats.sale[i];
            }
            return null;
        },
        isLocal() {
            return this.Local.type == 'LOCAL';
        }
    },
    watch: {
        currentTab: function(n, o) {
            this.currentDataPerTab = this.maxDataPerTab;
        }
    }
}
</script>

<style lang="scss">
.img-round-mini {
    border-radius: 5px;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background-color: whitesmoke;
}
.percentage-icon {
    background-color: var(--gray-3);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-1);
    font-weight: 500;
    font-size: 12px;
    flex-shrink: 0;
    text-shadow: 0px 0px 5px black;
}

.scroll-container {
    max-height: 300px;
    height: 300px;
    overflow-y: auto;
    padding: 10px;
    margin: -10px !important;
}

.dark {
    .percentage-icon {
        background-color: var(--dark-bg-2);
        color: var(--dark-fc-2);
    }
}

@media (max-width: 820px) {
    .scroll-container {
        padding: 0px;
        margin: 0px;
        height: auto;
    }
}

</style>
