<template>
<div class="fill">

    <div class="fill flex column" v-if="views[currentRoute]">

        <!--Header -->
        <div :class="`flex align-center header-bar top-bar ${route.fullscreen===true || mobile? 'fullscreen' : ''}`" id="header-bar">
            <i v-if="route.showMenuBarsLeft" @click="OpenSideMenu()" class="options icon-bars selectable ml5"></i> 
            <div v-html="views[currentRoute].title" class="ml10" style="font-size: 18px" id="header-title"></div>
            <div class="flex-fill"></div>
            <slot name="options"></slot>
            <i v-if="mobile && forceSubMenu===false" @click="OpenSideMenu()" class="options icon-bars" style="margin-left: 8px"></i>
        </div>
        <!-- Mobile Side Menu -->
        <side-menu ref="mobileMenu" size="250px" :fixed="route.forceSideMenuDesktop!==false && desktop"  :position="mobile?'right':'left'">
            <div class="fill flex column contain left-menu">

                <div v-if="announcement">
                    <div class="header-message flex align-center" :style="{marginBottom: '-10px', color: announcement.color, backgroundColor: announcement.background, cursor: announcement.description ? 'pointer' : ''}" @click="Info(announcement.description)">
                        <i v-if="announcement.icon" :class="`icon icon-${announcement.icon} mr5`"></i> 
                        <div class="flex-fill" v-html="announcement.title"></div>
                        <i v-if="announcement.description" class="icon icon-external"></i>
                    </div>
                    <div  style="margin-bottom: -10px; pointer-events: none;"></div>
                </div>

                <div v-else-if="updateAvailable">
                    <div class="header-message flex align-center selectable" style="background-color: var(--update-color)" @click="Confirm({
                        title: `Hay una nueva versión de la app disponible. ¿Desea actualizar? Esto recargará la página`,
                        text: 'Actualizar',
                        function: Refresh
                    })">
                        <i :class="`icon icon-star mr5`"></i> 
                        <div class="flex-fill">¡Actualización disponible!</div>
                    </div>
                    <div  style="margin-bottom: -10px; pointer-events: none;"></div>
                </div>

                <div class="relative flex column unshrink w100 pa15">
                    <div class="flex">
                        <img :src="require(`../../assets/${dark ? 'logo-login' : 'logo-color'}.png`)" style="height: 20px; padding: 10px 0px">
                        <div class="flex-fill"></div>
                        <div class="flex csupport align-center selectable" v-if="updatesLog && updatesLog.newArticles > 0 && !updatesLog.read" @click="modalUpdatesLog=true">
                            <div style="font-size: 10px">¡Novedades!</div>
                            <i class="icon icon-sparkle ml5 animated pulse infinite" style="font-size: 16px"></i>
                        </div>
                        <i v-if="Local && !$socket.connected" class="icon icon-no-wifi absolute" style="font-size: 18px; color: var(--red); right: 15px; top: 25px" title="Sin conexión"></i>

                    </div>
                    <div class="lh1" style="font-weight: 500; font-size: 16px;" v-if="Local" v-tooltip="Auth && Auth.role == 'admin' ? `ID: ${Local.id}` : ''">
                        <i v-if="Local && (Auth.role == 'admin' || Auth.role == 'sub_admin')" class="icon-exit mr5 selectable" @click="ExitLocal()"></i>
                        <i v-if="Local && (Auth.role == 'local_admin' && Local.franchise)" class="icon-exit mr5 selectable" @click="ExitSublocal()"></i>
                        {{Local.name}}
                        <!--
                        <div v-if="Local" class="cw flex align-center" style="font-weight: 500; font-size: 16px;">
                            <span v-if="Auth.role == 'admin' || Auth.role == 'sub_admin'">
                                <i class="absolute options icon-exit" @click="ExitLocal()" style="top: 3px; left: 0"></i>
                            </span> 
                            <span v-if="Auth.role == 'local_admin' && Local.franchise">
                                <i class="absolute options icon-exit" @click="ExitSublocal()" style="top: 3px; left: 0"></i>
                            </span> 
                            <span>{{Local.name}}</span>
                        </div>
                        -->
                    </div>
                    
                </div>
                <div class="flex-fill flex column ofy">
                    
                    <account-turn-menu v-if="Local && Local.type == 'LOCAL' && Auth && Auth.turns_enabled" 
                    @ClickMenu="CloseSideMenu($event)"
                    @OnTimerChanged="turnActiveTime = $event"
                    ref="accountTurn"></account-turn-menu>
                    
                    <div v-for="(item, index) in items"
                    :key="index">
                        <!-- <router-link class="menu-item" active-class="menu-selected" v-if="views[item]" :to="{name: item}"><i :class="`icon icon-${views[item].icon}`"></i> {{views[item].title}}</router-link> -->
                        <div @click="CloseSideMenu(views[item])" :class="`menu-item ${currentRoute == item ? 'menu-selected' : ''}`" v-if="views[item]"><i :class="`icon icon-${views[item].icon}`"></i> {{views[item].title}}</div>
                        <div v-else class="separator"></div>
                    </div>
                    <div class="flex-fill"></div>
                    
                </div>
                <div v-if="Auth">
                    <div class="menu-item" ref="btnUserOptions"  @click="modalUserOptions=true">
                        <div class="flex align-center relative">
                            
                            <avatar class="mr10" :style="{'margin-left': '-5px', transform: Turn ? 'scale(0.8) translateY(4px)' : ''}"
                            :label="Turn ? '' : Auth.name"
                            :color="Turn ? '#E601EB' : Util.AccountType(Auth).color">
                                <div class="spin"  style="animation-duration: 5s" v-if="Turn">
                                    <i class="icon icon-chronometer-handle" style="transform: translateY(-5px); font-size: 14px"></i>
                                </div>
                                <i v-if="Turn" class="icon icon-chronometer-empty absolute" style="font-size: 44px; transform: translateY(-3px)"></i>
                            </avatar>
                                
                            <div class="flex column fill">
                                <div class="lh1 fc">{{Auth.name}}</div>
                                <div class="secondary-text lh1">{{Util.AccountType(Auth).label}}</div>
                                <div class="secondary-text lh1" v-if="Turn">{{Util.Date(Turn.started_at, 'HH:mm')}} ({{turnActiveTime}})</div>
                            </div>
                            <badge class="absolute" :style="{top: Turn ? '5px' : '10px', right: '5px'}" v-if="notificationsCount > 0" :count="notificationsCount" pulse="auto" :max="9"></badge>
                            <!--<button class="options icon-dots-vertical"></button>-->
                        </div>
                    </div>
                </div>
            </div>
        </side-menu>

        <!-- Body -->
        <div id="main-content" :class="`flex column flex-fill main-container ofy ${desktop?'main-content':'mobile-main-content'} ${route.fullscreen===true? 'fullscreen' : ''}`">
            <div :class="`ofy flex-fill fill flex column ${views[currentRoute].fullscreen ? '' : 'pt10-desktopp'}`">
                <slot></slot>
            </div>
        </div>

        <!-- Mobile sub menu -->
        <div v-if="(mobile && !keyboard && route.forceSubMenu!==false) ||  route.forceSubMenu===true" class="flex align-center header-bar bottom-bar">
            <div class="flex fill align-center">
                <router-link class="flex-fill flex column ta-center nav"
                :to="{name: item}"
                v-for="(item, index) in itemsMobile"
                :key="index">
                    <i :class="`${views[item].icon} ${currentRoute == item ? 'selected' : ''}`"></i>
                    <!--<div :style="{color: currentRoute == item ? 'white' : '', 'font-size': '9px', 'margin-top': '-5px'}">{{views[item].title}}</div>-->
                </router-link>
                <div @click="OpenSideMenu()" class="flex-fill ta-center nav relative">
                    <i class="icon icon-bars"></i>
                    <!--<badge class="absolute" :style="{top: '-3px', right: '23px'}" v-if="notificationsCount > 0" :count="notificationsCount" pulse="auto" :max="9"></badge>-->
                    <div class="menu-notifier" v-if="notificationsCount > 0" v-show="showMenuNotifier"></div>
                    <div class="menu-notifier" style="outline-color: var(--update-color)" v-else-if="updateAvailable" v-show="showMenuNotifier"></div>
                </div>
            </div>
        </div>

    </div>

    <div class="fill flex column" v-else>
        <slot></slot>
    </div>

    <modal v-model="currentNotification" desktopWidth="50vw" :title="currentNotification ? (mobile ? 'Notificación' : currentNotification.title) : ''" dir="up">
        <div class="section" v-if="currentNotification">
            <div class="bold mb10" v-show="mobile" v-html="currentNotification.title"></div>
            <div v-html="currentNotification.description"></div>
        </div>
        <div class="modal-actions">
            <button @click="currentNotification=null">Ok</button>
        </div>
    </modal>

    <modal v-model="modalChangePassword" title="Cambiar Contraseña" dir="center" modalClass="sm">
        <div class="section" v-if="modalChangePassword">
            
            <input-field class="mb15"
            label="Contraseña"
            v-model="modalChangePassword.password" 
            type="password">
            </input-field>
            
            <input-field label="Repetir Contraseña" 
            v-model="modalChangePassword.password_confirm" 
            type="password">
            </input-field>
        </div>
        
        <button class="primary purple" slot="actions" v-if="modalChangePassword" :disabled="modalChangePassword.password.length < 6 || modalChangePassword.password !== modalChangePassword.password_confirm" @click="Confirm({
            title: '¿Seguro que desea cambiar su contraseña?',
            text: 'Cambiar',
            class: 'selected-color',
            function: ChangePassword
        })">Cambiar Contraseña</button>
        
    </modal>

    <modal v-model="modalUserOptions" :hideBackdrop="desktop" :domElement="$refs.btnUserOptions" hideActions :emitEvents="false" :dir="['up', 'center']" :modalClass="['up w100', '']">
        <div class="input-popover" style="max-height: 100%" v-if="modalUserOptions===true">

            <button @click="modalSupport=true;modalUserOptions=false"><i class="icon icon-lifebuoy mr10"></i>Ayuda</button>

            <button @click="OpenLocalOptions();modalUserOptions=false"><i class="icon icon-cog mr10"></i>Opciones</button>

            <button @click="CloseSideMenu(views.notifications)"><i class="icon icon-bell mr10"></i>Notificaciones <div class="flex-fill"></div><badge v-if="notificationsCount > 0" :count="notificationsCount" :round="false"></badge></button>
            
            <button class="error-color" @click="LogOut(true)"><i class="icon icon-exit mr10"></i>Cerrar sesión</button>

            <div v-if="$refs.accountTurn && $refs.accountTurn.turnOptions.length">
                <div class="separator"></div>
                <button v-for="(option, index) in $refs.accountTurn.turnOptions" :key="index" :class="option.class" @click="option.function"><i :class="`icon icon-${option.icon} mr10`" v-if="option.icon"></i>{{option.text}}</button>
            </div>

        </div>
    </modal>

    <modal v-model="modalSupport" :hideBackdrop="desktop" :domElement="$refs.btnUserOptions" hideActions :dir="['up', 'center']" :modalClass="['up w100', '']">
        
        <div class="input-popover">
            <button disabled><i class="icon icon-information mr10"></i>Build {{build}}</button>
            <!--<button>Condiciones y Privacidad</button>-->
            <button @click="Util.Open('https://service.influye.app/')"><i class="icon icon-download mr10"></i>Descargar Influye Service</button>
            <!--<button>Capacitaciones Virtuales</button>-->
            <button @click="modalUpdatesLog=true"><i class="icon icon-sparkle mr10"></i>Últimas Actualizaciones</button>
            <button @click="Util.Open('https://influye.tawk.help/')"><i class="icon icon-book mr10"></i>Manual del Usuario</button>
            <button @click="Util.OpenWhatsapp('+56984088047')"><i class="icon icon-whatsapp mr10"></i>Contáctanos (Whatsapp)</button>
        </div>
    </modal>

    <modal v-model="modalLocalOptions" :hideBackdrop="desktop" :domElement="$refs.btnUserOptions" hideActions :dir="['up', 'center']" :modalClass="['up w100', '']">
        <div class="input-popover" v-if="modalLocalOptions">

            <button class="relative flex">
                <div class="flex-fill ta-left">Tema Oscuro</div>
                <input-toggle v-model="modalLocalOptions.dark" style="transform: scale(0.7)"
                @OnValueChanged="SetDarkMode($event)">
                </input-toggle>
            </button>
            <button class="relative" v-if="desktop">
                <div class="flex-fill ta-left">Whatsapp Escritorio</div>
                <input-toggle v-model="modalLocalOptions.nativeWhatsapp" style="transform: scale(0.7)"
                @OnValueChanged="SetLocalOption('nativeWhatsapp', $event)">
                </input-toggle>
            </button>
            <button v-if="desktop" @click="OpenFullScreen(!modalLocalOptions.fullscreen)">{{modalLocalOptions.fullscreen ? 'Salir de' : 'Abrir'}} Pantalla Completa</button>
            <button @click="modalChangePassword={password: '', password_confirm: ''}">Cambiar Contraseña</button>

        </div>
    </modal>

    <modal v-model="modalUpdatesLog" modalClass="md" hideActions title="¿Que hay de nuevo?">
        <div class="pa20" v-if="modalUpdatesLog">
            <div :class="`well selectable mb10 ${entry.isNew ? 'csupport' : ''}`" v-for="entry in updatesLog.articles" :key="entry.title" @click="Util.Open(entry.url)">
                <div class="f18 mb5"><i class="icon icon-sparkle mr5 animated pulse infinite" v-if="entry.isNew"></i>{{entry.title}}</div>
                <div>{{entry.description}}</div>
            </div>
        </div>
    </modal>

</div>
</template>

<script>
export default {
    props: {
        state: {
            type: String,
            default: ''
        },
        fixed: {
            type: Boolean,
            default: false
        },
        bodyClass: {
            type: String,
            default: ''
        },
        mobileTitle: {
            type: String,
            default: null
        },
        forceHeader: {
            type: Boolean,
            default: null
        },
        forceSideMenu: {
            type: Boolean,
            default: null
        },
        forceSubMenu: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            views: {
                dashboard: {
                    title: 'Resumen',
                    icon: 'icon icon-line-chart',
                    path: 'dashboard'
                },
                activity: {
                    title: 'Actividad',
                    icon: 'icon icon-history',
                    path: 'activity',
                    forceHeaderDesktop: true
                },
                transactions: {
                    title: 'Transacciones',
                    icon: 'icon icon-receipt',
                    path: 'transactions',
                    forceHeaderDesktop: true
                },
                inventory: {
                    title: 'Inventario',
                    path: 'inventory',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-box'
                },
                expenses: {
                    title: 'Gastos',
                    path: 'expenses',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-money'
                },
                facturas: {
                    title: 'Facturas Electrónicas',
                    path: 'facturas',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-invoice'
                },
                payments: {
                    title: 'Pagos Pendientes',
                    path: 'payments',
                    icon: 'icon icon-hourglass'
                },
                customers: {
                    title: 'Clientes',
                    icon: 'icon icon-user',
                    path: 'customers',
                    forceHeaderDesktop: true,
                },
                menu: {
                    title: 'Menú',
                    path: 'menu',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-menu'
                },
                levels: {
                    title: 'Niveles',
                    path: 'levels',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-star'
                },
                store: {
                    title: 'Corbatines',
                    path: 'store',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-corbatin'
                },
                promotions: {
                    title: 'Promociones',
                    path: 'promotions',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-percentage'
                },
                stamps: {
                    title: 'Sellos',
                    path: 'stamps',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-sticker'
                },
                sales: {
                    title: 'Punto de Venta',
                    forceSideMenuDesktop: false,
                    forceHeaderDesktop: true,
                    fullscreen: true,
                    showMenuBarsLeft: true,
                    forceSubMenu: false,
                    path: 'sales',
                    icon: 'icon icon-cashier'
                },
                pending: {
                    title: 'Cocina y Entrega',
                    path: 'pending',
                    forceSideMenuDesktop: false,
                    forceHeaderDesktop: true,
                    showMenuBarsLeft: true,
                    forceSubMenu: false,
                    fullscreen: true,
                    icon: 'icon icon-spatula',
                    hideTitleMobile: true,
                },
                /*
                tables: {
                    title: 'Mesas',
                    path: 'tables',
                    forceSideMenuDesktop: false,
                    forceHeaderDesktop: true,
                    showMenuBarsLeft: true,
                    forceSubMenu: false,
                    fullscreen: true,
                    icon: 'icon icon-table'
                },
                */
                profile: {
                    title: 'Perfil',
                    path: 'profile',
                    forceHeaderDesktop: true,
                },
                staff: {
                    title: 'Personal',
                    path: 'staff',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-staff'
                },
                settings: {
                    title: 'Ajustes',
                    path: 'settings',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-cog'
                },
                modules: {
                    title: 'Modulos',
                    path: 'modules',
                    forceHeaderDesktop: true
                },
                sublocals: {
                    title: 'Locales',
                    path: 'sublocals',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-shop'
                },
                balance: {
                    title: 'Flujo de Caja',
                    path: 'balance',
                    forceHeaderDesktop: true,
                    icon: 'icon icon-balance'
                },
                turns: {
                    title: 'Turnos',
                    path: 'turns',
                    icon: 'icon icon-chronometer'
                },
                coupons: {
                    title: 'Cupones',
                    path: 'coupons',
                    icon: 'icon icon-coupon',
                    forceHeaderDesktop: true,
                },
                notifications: {
                    title: 'Notificaciones',
                    path: 'notifications',
                    icon: 'icon icon-bell'
                },
                promos: {
                    title: 'Promociones',
                    path: 'promos',
                    icon: 'icon icon-coupon',
                    forceHeaderDesktop: true,
                },


                //Cashier views
                turn: {
                    title: 'Turno Actual',
                    path: 'turn'
                },

                //Delivery views
                deliveryPending: {
                    title: 'Pedidos',
                    path: 'deliveryPending',
                    icon: 'icon icon-delivery'
                },

                //Admin views
                locals: {
                    title: 'Locales',
                    path: 'locals',
                    icon: 'icon icon-shop',
                    hideHeader: true,
                    forceHeaderDesktop: true,
                },
                adminDashboard: {
                    title: 'Dashboard',
                    icon: 'icon icon-chart',
                    path: 'adminDashboard',
                    forceHeaderDesktop: true,
                },
                adminActivity: {
                    title: 'Actividad',
                    path: 'adminActivity',
                    icon: 'icon icon-history'
                },
                globalSettings: {
                    title: 'Configuración',
                    path: 'globalSettings',
                    icon: 'icon icon-cog'
                },
                accounts: {
                    title: 'Cuentas',
                    path: 'accounts',
                    icon: 'icon icon-staff',
                    forceHeaderDesktop: true
                },
                news: {
                    title: 'Noticias',
                    path: 'news',
                    icon: 'icon icon-newspaper'
                },
                bulletin: {
                    title: 'Boletín',
                    icon: 'icon icon-line-chart',
                    path: 'bulletin',
                    forceHeaderDesktop: true
                },
                salesman_profile: {
                    title: 'Perfil',
                    icon: 'icon icon-user',
                    path: 'salesman_profile',
                    forceHeaderDesktop: true
                },
                orders: {
                    title: 'Pedidos',
                    path: 'orders',
                    forceSideMenuDesktop: false,
                    forceHeaderDesktop: true,
                    showMenuBarsLeft: true,
                    forceSubMenu: false,
                    fullscreen: true,
                    icon: 'icon icon-bag',
                    hideTitleMobile: true,
                },
            },
            modalUserOptions: false,
            modalSupport: false,
            modalManual: false,
            tutorials: null,
            modalVideo: null,
            searchTutorial: '',
            version: null,
            introVideo: null,
            modalLocalOptions: null,
            notifications: [],
            currentNotification: null,
            modalLogo: null,
            modalChangePassword: null,
            turnActiveTime: null,
            build: null,
            updatesLog: null,
            modalUpdatesLog: false,
            updateAvailable: false,
            showMenuNotifier: true,
        }
    },
    mounted() {
        this.version = process.env.VERSION;
        this.build = this.Util.Date(process.env.BUILD, 'YYMMDDHm');
        this.$root.$on('LogOut', this.LogOut);

        if(window.updatefound) this.updateAvailable = true;
		document.addEventListener('UpdateFound', () => {
			this.updateAvailable = true;
		});

    },
    methods: {
        CloseSideMenu(redirect) {
            this.$refs.mobileMenu.Close();
            this.modalUserOptions = false;
            
            if(redirect)
            {
                console.log(redirect);
                setTimeout(() => {
                    this.$router.push({name: redirect.path});
                }, 10);
            }
        },
        OpenSideMenu() {
            this.$refs.mobileMenu.Open();
            this.showMenuNotifier = false;
        },
        LogOut(promt) {
            if(promt)
            {
                this.Confirm({
                    text: 'Cerrar Sesión',
                    title: '¿Cerrar sesión en este dispositivo?',
                    class: 'error-color',
                    function: () => {this.LogOut(false)}
                });
            }
            else
            {
                NativeStorage.remove('token', success => {
                    this.$socket.client.disconnect();
                    this.$store.commit('logout');
                    setTimeout(() => {
                        //this.$router.push('/');
                        location.href = '/';
                    }, 100);
                }, err => {

                });
            }
        },
        ExitLocal() {
            this.CloseSideMenu();
            this.$nextTick(() => {
                this.$store.commit('setLocal', null);
                //this.$socket.client.emit('DISCONNECT_FROM_LOCAL');
                this.Util.SetServer('DEFAULT', 'DEFAULT');
                this.$router.push('/locals');
            });
        },
        ExitSublocal() {
            this.CloseSideMenu();
            this.$nextTick(() => {
                var franchise = this.Local.franchise;
                this.$store.commit('setLocal', franchise);
                //this.$socket.client.emit('CONNECT_TO_LOCAL', franchise.id);
                this.$router.push('/sublocals');
            });
        },
        OpenLocalOptions()
        {
            /*
            - General: nativeWhatsapp
            - POS: pedidos guardados (order_{id_local}_{key})
            - POS: defaultItemsView
            - Gestion Pedidos: kitchenFontSize
            - Gestion Pedidos: attentionSoundEnabled
            - Gestion Pedidos: defaultView
            */

           /*
            var savedOrders = [];
            var keys = Object.keys(localStorage);
            keys.forEach(key => {
                if(key.indexOf(`order_${this.Local.id}_`) >= 0)
                    savedOrders.push(key.replace(`order_${this.Local.id}_`, ''));
            });
            */

            this.modalLocalOptions = {
                nativeWhatsapp: localStorage.nativeWhatsapp === undefined ? false : true,
                dark: this.dark,
                fullscreen: window.innerHeight == screen.height,
                //defaultItemsView: localStorage.defaultItemsView === undefined ? 'GRID' : localStorage.defaultItemsView,
                //kitchenFontSize: localStorage.kitchenFontSize === undefined ? 12 : localStorage.kitchenFontSize,
                //attentionSoundEnabled: localStorage.attentionSoundEnabled == undefined ? false: localStorage.attentionSoundEnabled,
                //defaultKitchenView: localStorage.defaultKitchenView === undefined ? 'ALL' : localStorage.defaultKitchenView,
                //savedOrders: savedOrders
            }

            console.log(this.localOptions);

        },

        SaveLocalOptions()
        {
            for(var key in this.localOptions)
            {
                var value = this.localOptions[key];
                if(key == 'kitchenFontSize')
                {
                    value = parseInt(value);
                    if(isNaN(value)) value = 12;
                    else if(value < 8) value = 8;
                }
                if(value === false) localStorage.removeItem(key);
                else if(key !== 'savedOrders') localStorage.setItem(key, value);
            }
            this.Info(`Opciones locales guardadas`);
            this.$refs.optionsMenu.Close();
        },

        DeleteSavedOrder(index)
        {
            var key = `order_${this.Local.id}_${this.localOptions.savedOrders[index]}`;
            this.localOptions.savedOrders.splice(index, 1);
            localStorage.removeItem(key);
        },

        ChangePassword()
        {
            this.Loading();
            axios.patch(`/account/${this.Auth.id}/password`, this.modalChangePassword).then(res => {
                this.Info('Contraseña cambiada exitosamente');
                this.modalChangePassword = null;
            }).catch(err => {
                this.Response(err);
            });
        },

        StartTawkChat()
        {
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/61b756de80b2296cfdd174d3/1fmq2mlp3';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();
        },

        SetLocalOption(key, value)
        {
            if(value === false) localStorage.removeItem(key);
            else localStorage.setItem(key, value);
            
            if(this.modalLocalOptions) 
            {
                this.$set(this.modalLocalOptions, key, value);
            }
        },

        CheckNotifications()
        {
            axios.get('/notifications/unread').then(res => {
                this.$store.commit('notificationsCount', res.data);
            }).catch(err => {
                console.log(err);
            });
        },

        SetDarkMode(value)
        {
            this.SetLocalOption('dark', value);
            this.$store.commit('setDarkMode', value);
        },

        OpenFullScreen(value)
        {
            if(value) document.documentElement.requestFullscreen();
            else document.exitFullscreen();
            this.modalLocalOptions = null;
        },
		Refresh() 
		{
			window.location.reload();
		},

    },
    computed: {
        items() {
            var ret = [];
            var Auth = this.Auth;
            if(!Auth) return ret;
            var Local = this.Local;
            if(!this.Local)
            {
                //Menu Admin
                if(this.Auth.role == 'admin')
                {
                    ret.push('bulletin');
                    if(this.Auth.is_salesman)
                        ret.push('salesman_profile');
                    //ret.push('adminActivity');
                    //ret.push('adminDashboard');
                    ret.push('locals');
                    ret.push('accounts');
                    //ret.push('news');
                    ret.push('globalSettings');         
                }
                else if(this.Auth.role == 'sub_admin')
                {
                    ret.push('bulletin');
                    if(this.Auth.is_salesman)
                        ret.push('salesman_profile');
                    ret.push('locals');
                }
            }
            else
            {
                if(this.Auth.role == 'admin' || this.Auth.role == 'local_admin' || this.Auth.role == 'sub_admin')
                {
                    const restrictionsAdmin = this.Auth.restrictions_admin;
                    if(this.Auth.role == 'local_admin'){
                        if(restrictionsAdmin.dashboard_enabled) ret.push('dashboard');
                        if(restrictionsAdmin.transactions_enabled) ret.push('transactions');
                        if(restrictionsAdmin.inventory_enabled && this.Local.modules.module_inventory) ret.push('inventory');
                        if(restrictionsAdmin.expenses_enabled && this.Local.type == 'LOCAL' && this.Local.modules.module_expenses) ret.push('expenses');
                        if(restrictionsAdmin.facturas_enabled && this.Local.type == 'LOCAL' && this.Local.modules.module_sii_factura) ret.push('facturas');
                        if(restrictionsAdmin.payments_enabled && this.Local.type == 'LOCAL') ret.push('payments');
                        if(restrictionsAdmin.turns_enabled && this.Local.type == 'LOCAL' && this.Local.modules.module_turns) ret.push('turns');
                        if(restrictionsAdmin.locals_enabled && this.Local.type == 'FRANCHISE') ret.push('sublocals');
                        if(restrictionsAdmin.customers_enabled) ret.push('customers');
                        if(restrictionsAdmin.menu_enabled) ret.push('menu');
                        if(restrictionsAdmin.coupons_enabled && this.Local.type == 'LOCAL') ret.push('coupons');
                        if(restrictionsAdmin.settings_enabled) ret.push('settings');
                        if(restrictionsAdmin.orders_enabled && this.Local.type == 'LOCAL') ret.push('orders');
                    }else{
                        ret.push('dashboard');
                        ret.push('transactions');
                        if(this.Local.modules.module_inventory) ret.push('inventory');
                        if(this.Local.type == 'LOCAL' && this.Local.modules.module_expenses) ret.push('expenses');
                        if(this.Local.type == 'LOCAL' && this.Local.modules.module_sii_factura) ret.push('facturas');
                        if(this.Local.type == 'LOCAL') ret.push('payments');
                        if(this.Local.type == 'LOCAL' && this.Local.modules.module_turns) ret.push('turns');
                        if(this.Local.type == 'FRANCHISE') ret.push('sublocals');
                        ret.push('customers');
                        ret.push('menu');
                        if(this.Local.type == 'LOCAL' || this.Local.type == 'FRANCHISE') ret.push('coupons');
                        ret.push('settings');
                        if(this.Local.type == 'LOCAL') ret.push('orders');
                    }
                    
                    //ret.push('Local');
                    //ret.push('staff');
                }
                else
                {
                    if(this.Auth.role == 'cashier')
                    {
                        var r = this.Auth.restrictions;

                        ret.push('orders');
                        if(this.Local.type == 'LOCAL' && this.Local.modules.module_inventory && (this.Auth.restrictions.create_inventory || this.Auth.restrictions.reduce_inventory)) ret.push('inventory');
                        if(this.Local.type == 'LOCAL' && this.Local.modules.module_expenses && this.Auth.restrictions.create_expenses) ret.push('expenses');
                        if(this.Local.type == 'LOCAL' && (this.Auth.restrictions.pay_pending_payments || this.Auth.restrictions.pay_pending_inventory_invoices)) ret.push('payments');
                        if(this.Local.type == 'LOCAL' && this.Auth.restrictions.edit_items_availability) ret.push('menu');
                        if(this.Local.type == 'LOCAL' && this.Auth.restrictions.can_create_facturas) ret.push('facturas');
                    }
                    else if(this.Auth.role == 'delivery')
                    {
                        ret.push('deliveryPending');
                    }

                    var r = this.Auth.restrictions;
                    if(r.view_transactions !== 'NONE')
                    {
                        ret.push('transactions');
                    }
                }

                
                
            }

            return ret;
        },
        itemsMobile() {
            var ret = [];
            if(!this.Local)
            {

                //Menu Admin
                if(this.Auth.role == 'admin')
                {
                    //ret.push('adminActivity');
                    ret.push('bulletin');
                    if(this.Auth.is_salesman)
                        ret.push('salesman_profile');
                    ret.push('locals');
                    ret.push('globalSettings');
                }
                else if(this.Auth.role == 'sub_admin')
                {
                    ret.push('bulletin');
                    if(this.Auth.is_salesman)
                        ret.push('salesman_profile');
                    ret.push('locals');
                }
            }
            else
            {
                //Menu Local
                if(this.Auth.role == 'admin' || this.Auth.role == 'local_admin' || this.Auth.role == 'sub_admin')
                {
                    ret.push('dashboard');
                    ret.push('transactions');
                    ret.push('customers');
                }
                else if(this.Auth.role == 'cashier')
                {
                    ret.push('orders');
                    if(this.Local.type == 'LOCAL' && this.Local.modules.module_inventory && (this.Auth.restrictions.create_inventory || this.Auth.restrictions.reduce_inventory)) ret.push('inventory');
                    if(this.Local.type == 'LOCAL' && this.Local.modules.module_expenses && this.Auth.restrictions.create_expenses) ret.push('expenses');
                    
                    if(this.Local.type == 'LOCAL' && this.Auth.restrictions.edit_items_availability) ret.push('menu');
                }
            }

            return ret;
        },
        currentRoute() {
            return this.$route.name;
        },
        route() {
            return !this.views[this.currentRoute] ? {path: this.currentRoute} : this.views[this.currentRoute];
        },
        tutorialsList() {
            if(!this.tutorials) return [];

            if(this.searchTutorial)
            {
                var selected = [];
                for(var i = 0; i < this.tutorials.length; i++)
                {
                    if(this.Util.InString(this.tutorials[i].title, this.searchTutorial))
                        selected.push(this.tutorials[i]);
                }
                return [
                    {
                        title: 'Búsqueda',
                        tutorials: selected
                    }
                ];
            }

            var ret = {};
            var categories = this.Util.TutorialCategories;
            for(var i = 0; i < categories.length; i++)
            {
                ret[categories[i].path] = {
                    title: categories[i].title,
                    tutorials: []
                }
            }

            for(var i = 0; i < this.tutorials.length; i++)
                ret[this.tutorials[i].section].tutorials.push(this.tutorials[i]);
            
            var currentSection = null;
            if(ret[this.route.path])
            {
                currentSection = ret[this.route.path];
                delete ret[this.route.path];
            }

            ret = Object.values(ret);
            if(currentSection)
                ret.unshift(currentSection);

            return ret;
        },
        videoLink() {
            if(!this.modalVideo) return '';
            return this.modalVideo.replace('watch?v=', 'embed/') + '?start=1';
        },
        defaultKitchenViewOptions()
        {
            if(!this.Local) return null;
            var ret = [
                {value: 'TICKETS', text: 'Vista Tickets'},
                {value: 'LIST', text: 'Vista Lista'},
                {value: 'TABLE', text: 'Vista Tabla'},
            ];
            ret.push({value: 'KITCHEN', text: 'Display Cocina'});
            if(this.Local.modules.module_tables) ret.push({value: 'TABLES', text: 'Mesas'});
            return ret;
        },
        notificationsCount() {
            return this.$store.state.unreadNotifications;
        },
        announcement() {
            return this.$store.state.announcement;
        },
    },
    watch: {
        Auth: function(n, o) {
            if(n)
            {
                this.notifications = this.$store.state.notifications;
                if(this.notifications.length)
                    this.currentNotification = this.notifications.shift();

                this.CheckNotifications();

                //check updates
                axios.get(`/updates/log/account`).then(res => {
                    var newArticlesCount = 0;
                    res.data.articles.forEach(article => {
                        if((!res.data.last_read && article.date) || (article.date && article.date > res.data.last_read))
                        {
                            newArticlesCount++;
                            article.isNew = true;
                        }
                    });
                    res.data.newArticles = newArticlesCount;
                    this.updatesLog = res.data;
                    console.log("UPDATES LOGOGG");
                    console.log(this.updatesLog);
                }).catch(err => {
                    console.log(err);
                });


            }
        },
        currentNotification: function(n, o) {
            if(!n && this.notifications.length)
            {
                setTimeout(() => {
                    this.currentNotification = this.notifications.shift();
                }, 100);
            }
        },
        modalUpdatesLog: function(n, o)
        {
            if(n && !this.updatesLog.read)
            {
                this.$set(this.updatesLog, 'read', true);
                axios.patch('/updates/log/read').then(res => {});
            }
        }
    },
    sockets: {
        /*
        connect: function() {
            this.connectionStatus = true;
            this.$socket.emit('LOCAL_CONNECTED', {id: Local.id, local: Local.name, account: Auth.display_name, view: window.location.pathname});
            this.$emit('OnConnected');
        },
        disconnect: function() {
            this.connectionStatus = false;
        },
        connect_error: function(err){
            this.connectionStatus = false;
        },
        */
        NEW_NOTIFICATION: function(data) {
            this.CheckNotifications();
            this.showMenuNotifier = true;
        }
    },
    /*
    watch: {
        connectionStatus: function(n, o) {
            this.$emit('input', n);
        }
    }
    */
}
</script>

<style lang="scss">
.header-bar {
    height: 56px;
    padding-right: 10px;
    background-color: var(--white);
    transition: background-color 0.2s linear;
    border-bottom: 1px solid var(--gray-2);
    padding: 0px 10px; 
    font-size: 15px; 
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 250px;
}
.dark .header-bar {
    background-color: var(--dark-bg);
    border-bottom: none;
}

.left-menu {
    background-color: var(--white);
    border-right: 1px solid var(--gray-2);
}


.main-content {
    margin-left: 250px;
    display: flex;
    align-items: center;
}
.main-content > div {
    width: 100%;
    
    /*height: min-content;*/
}

.mobile-main-content {
    padding: 0px;
    overflow-x: hidden;
    /*position: relative;*/
}

.menu-header {
    font-size: 9px;
    text-transform: uppercase;
    padding: 5px 15px;
    margin: 3px 0px;
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--white);
}
.fullscreen {
    margin: 0px !important;
    padding: 0px !important;
}
.fullscreen > div {
    max-width: 100%;
}

.menu-item {
    color: var(--gray-1);
    display: block;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 15px;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:not(.menu-selected):hover {
        color: var(--cyan);

        > i {
            transform: scale(1.4) rotate(-10deg);
        }

    }

}
.menu-item > i {
    margin-right: 10px;
    font-weight: normal;
    transition: all 0.1s linear;
}
.menu-selected {
    color: var(--purple);
}


.bottom-bar {
    border-bottom: none;
    margin-left: 0px;
    border-top: 1px solid var(--gray-2);

    .nav {
        font-size: 20px; 
        color: var(--gray-1); 
        text-decoration: none;
        .selected {
            color: var(--purple);
        }
    }

}

.header-message {
    padding: 5px 10px;
    font-size: 12px;
    width: 100%;
    background-color: var(--purple);
    color: var(--white);
    z-index: 1;
    position: relative;
}

.menu-notifier {
    position: absolute;
    width: 29px;
    height: 28px;
    /* background: var(--purple); */
    left: 31px;
    top: 0px;
    border-radius: 50%;
    outline: 3px solid var(--pink);
    animation: anim-menu-notifier 1s ease-out 0s infinite;
}
@keyframes anim-menu-notifier {
    0% {
        transform: scale(0.5);
        opacity: 0;
        
    }
    30% {
        opacity: 0.8;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

.dark
{
    .menu-item {
        color: var(--dark-fc-2);
        &:not(.menu-selected):hover {
            color: var(--dark-purple);
        }
    }
    .menu-selected {
        color: var(--cyan);
    }
    .bottom-bar {
        border-top: none;
        .nav {
            color: var(--dark-fc-2); 
            .selected {
                color: var(--cyan);
            }
        }
    }
    .left-menu {
        background-color: var(--dark-bg-1) !important;
        border-right-color: transparent !important;
        border-right: none;
    }
    //Mobile
    @media (max-width: 820px) {
        .left-menu {
            background-color: var(--dark-bg) !important;
            border: none !important;
        }
    }
}
</style>
