<template>
<modal v-model="inventory" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Transacción de Inventario" hideActions>

    <i class="options icon-dots-vertical" slot="options" @click="Options([
        {text: `Eliminar y ${inventory.quantity > 0 ? 'reducir' : 'aumentar'} inventario`, class: 'error-color', function: () => {Delete(true)}, confirm: `¿Eliminar transacción de inventario?`, if: canDelete},
        {text: 'Eliminar sin afectar inventario', function: () => {Delete(false)}, confirm: `¿Eliminar transacción de inventario?`, if: canDelete},
        {text: 'Imprimir Voucher', function: () => PrintInventoryVoucher()},
    ])"></i>

    <div class="section copyable" v-if="inventory">
        <div class="mb15">
            <div class="bold">Item</div>
            <div>{{inventory.item}}</div>
        </div>
        
        <div class="mb15">
            <div class="bold">Ingresado</div>
            <div>{{Util.Date(inventory.date, 'LLL')}}</div>
            <div>{{inventory.custom_data.created_by}}</div>
        </div>
        
        <div class="mb15" v-if="inventory.custom_data.provider">
            <div class="bold">Proveedor</div>
            <div>{{inventory.custom_data.provider.name}} - {{Util.Rut(inventory.custom_data.provider.rut)}}</div>
        </div>
        <div :class="`mb15 ${reference ? 'selectable' : ''} flex align-center`" @click="reference ? reference() : null" v-if="inventory.comment">
            <div class="flex-fill">
                <div class="bold">Comentario</div>
                <div class="flex fill align-center">
                    <div class="flex-fill">{{inventory.comment}}</div>
                    <!--<i v-if="reference" class="icon icon-arrow-right-o c4"></i>-->
                </div>
            </div>
            <button v-if="reference" class="secondary">Ver</button>
        </div>

        
        <div class="flex mb15">
            <div class="mr20">
                <div class="bold">Cantidad</div>
                <div>{{Util.Number(parseFloat(inventory.quantity))}} <span class="secondary-text" v-if="unit">{{unit}}</span></div>
                <div class="secondary-text">{{Util.Number(parseFloat(inventory.quantity_from))}} <span class="secondary-text" v-if="unit">{{unit}}</span> <i class="icon icon-arrow-right ml5 mr5"></i> {{Util.Number(parseFloat(inventory.quantity_to))}} <span class="secondary-text" v-if="unit">{{unit}}</span></div>
            </div>

            
        </div>
        

        <div class="flex">

            <div class="mr20" v-if="inventory.money > 0">
                <div class="bold">Total</div>
                <div>{{Util.Price(inventory.money)}}</div>
            </div>

            <div class="ml20" v-if="inventory.unit_cost > 0">
                <div class="bold">Costo Unitario</div>
                <div>${{Util.Number(inventory.unit_cost)}} <span class="secondary-text ml5" v-if="unit">pesos/{{unit}}</span></div>
            </div>

        </div>
        
        <div class="flex mt15">
            <div class="mr20" v-if="inventory.custom_data.iva">
                <div class="bold">IVA</div>
                <div>{{Util.Price(inventory.custom_data.iva)}}</div>
            </div>
            <div class="ml20" v-if="inventory.custom_data.tax">
                <div class="bold">Impuesto adicional</div>
                <div>{{Util.Price(inventory.custom_data.tax)}}</div>
            </div>
        </div>

        <div class="mt15" v-if="inventory.money > 0">
            <div class="bold">Método de Pago</div>
            <div>{{Util.PayMethod(inventory.pay_method)}}</div>
        </div>

        <input-files v-if="inventory.custom_data.files && inventory.custom_data.files.length" 
        label="Documentos" class="mt15"
        v-model="inventory.custom_data.files" 
        readonly></input-files>
        
        <!--<button v-if="inventory.id_transaction" @click="$refs.transactionDetails.Open(inventory.id_transaction)">Ver Transacción</button>-->
    </div>

    <transaction-details ref="transactionDetails"></transaction-details>
    <invoice-details ref="invoiceDetails" @OnEditInvoice="$emit('OnEditInvoice', $event)"></invoice-details>

</modal>
</template>
<script>
export default {
    props: {
        units: {
            type: [Object, Array],
            default: null
        }
    },
    data() {
        return {
            inventory: null
        }
    },
    methods: {
        async Open(inventory) 
        {
            if(typeof inventory == 'number')
            {
                this.Loading();
                try {
                    inventory = (await axios.get(`/inventory/transaction/${inventory}`)).data;    
                    this.Response({error: false});
                } catch (error) {
                    this.Response({error: true, reason: 'DATA_NULL'});
                }
                
            }
            this.inventory = inventory;
        },
        Delete(rollback)
        {
            this.Loading();
            axios.delete(`/inventory/${this.inventory.id}/delete${rollback ? '/rollback' : ''}`).then(res => {
                this.Info('Transacción eliminada');
                this.$emit('OnTransactionDeleted', this.inventory);
                this.inventory = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        PrintInventoryVoucher(transaction)
        {
            var data = transaction ? transaction : this.inventory;
            if(!Array.isArray(data)) data = [data];
    
            if(!data) return;

            var moment = require('moment');
            var now = moment();
            var arr = [
                //{text: [this.Local.name, now.format('HH:mm DD/MM')]},
                //{text: `Ingreso: ${data.custom_data.created_by} - ${this.Util.Date(data.date,'HH:mm DD/MM')}`},
                {text: this.Local.name},
                {text: `Fecha impresión: ${now.format('HH:mm DD/MM/YYYY')}`},
                {text: `Fecha movimiento: ${this.Util.Date(data[0].date,'HH:mm DD/MM/YYYY')}`},
                {text: `Ingresado por: ${data[0].custom_data.created_by}`},
            ];

            // if(data.comment) arr.push({text: `Comentario: ${data.comment}`});
            if(data[0].custom_data.provider) arr.push({text: `Proveedor: ${data[0].custom_data.provider.name} - ${data[0].custom_data.provider.rut}`});
            arr.push({line: true});

            data.forEach(element => {
                arr.push({text: [`${element.item}`, (element.quantity > 0 ? '+' : '')+this.Util.Number(element.quantity)]});
                arr.push({text: [``, `${this.Util.Number(element.quantity_from)} -> ${this.Util.Number(element.quantity_to)}`]});

                if(element.money)
                {
                    arr.push({line: true});
                    arr.push({text: `Costo unitario: $${this.Util.Number(element.unit_cost)}`});
                    arr.push({text: `Total: ${this.Util.Price(element.money)}`});
                }

                if(data.length > 1) arr.push({line: true});
            });

            // ------ POR ITEM ---- 
            this.Util.NotifyService('print', {data: arr, id: Math.random().toString()});
        }
    },
    computed: {
        canDelete() {
            if(!this.inventory) return false;
            if(this.Auth.role.indexOf('admin') == -1) return false;
            if(this.inventory.id_invoice) return false;
            return true;
            //var moment = require('moment');
            //return this.inventory.date >= moment().unix() - (60*60*24*2);
        },
        unit() {
            if(!this.units || !this.inventory || !this.units[this.inventory.item]) return null;
            return this.units[this.inventory.item].unit;
        },
        reference() {
            if(!this.inventory) return null;
            if(this.inventory.id_transaction) return () => {this.$refs.transactionDetails.Open(this.inventory.id_transaction)};
            if(this.inventory.id_invoice) return () => {this.$refs.invoiceDetails.Open(this.inventory.id_invoice)};
        }
    }
}
</script>