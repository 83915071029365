<template>
<div class="flex column fill relative">
    
    <div class="" v-if="!hideSaleType">

        <div class="flex mb15 input-group" v-if="!hideSaleTypeButtons">
            <button v-if="settings.accept_serve" @click="ChangeSale('SERVE')" class="w33 secondary" :style="{'background-color': sale=='SERVE'?(table?'var(--color-table)':'var(--color-serve)'):'', color: sale=='SERVE'?'white':''}">{{table?'Mesa':'Servir'}}</button>
            <button v-if="settings.accept_takeaway" @click="ChangeSale('TAKEAWAY')" class="w33 secondary" :style="{'background-color': sale=='TAKEAWAY'?(takeaway || wasShipment ? 'var(--color-takeaway-time)' : 'var(--color-takeaway)'):'', color: sale=='TAKEAWAY'?'white':''}">{{takeaway?'Retirar':'LLevar'}}</button>
            <button v-if="settings.accept_delivery" @click="ChangeSale('DELIVERY')" class="w33 secondary" :style="{'background-color': sale=='DELIVERY'?(wasShipment?'var(--color-shipment-transit)':'var(--color-delivery-transit)'):'', color: sale=='DELIVERY'?'white':''}">{{wasShipment ? 'Encargo' : 'Delivery'}}</button>
        </div>
        
        <input-select 
        v-if="sale =='SERVE' && Local.modules.module_tables"
        v-show="tables.length > 1"
        v-model="table"
        :items="tables"
        canType="search"
        pre="table"
        cleareable>
        </input-select>

        <input-field :inputClass="Util.StringToTime(takeaway) ? 'input-top' : ''"
        v-if="sale == 'TAKEAWAY' && !schedule"
        v-model="takeaway"
        placeholder="Hora Retiro (Opcional)"
        pre="chronometer"
        info="Indica la hora en que se pasará a retirar el pedido, ejemplo:<br><ul><li>11:30, 11.30, 11 30 (en formato 24 horas por lo que equivale a las 11:30 AM)</li><li>5pm, 17, 5 30 pm</li><li>40 min, 40m, 1 hora, 1h (el pedido será retirado en 40 minutos o 1 hora)</li><li>1 hora 20 min, 1h 20m, 1 hora 20, 1h 20, en 1 20 (el pedido será retirado en 1 hora 20 minutos)</li></ul>">
            <!--
            <div class="append" slot="after">
                
                <i slot="after" class="c4 icon icon-question selectable" @click="Info(``)"></i>
                <i slot="after" class="ml5 c4 icon icon-datetime selectable" @click="$refs.dateSchedule.Open()" title="Agendar pedido"></i>
                
            </div>-->
            <button @click="schedule = Util.Timestamp()" class="secondary border bl0" slot="after">Agendar</button>
        </input-field>
        <div v-show="sale == 'TAKEAWAY' && !schedule" class="secondary-text well mini border bottom" v-if="Util.StringToTime(takeaway)">El pedido se retirará a las {{Util.StringToTime(takeaway)}} hrs</div>

        <input-field :inputClass="Util.StringToTime(deliveryTime) ? 'input-top' : ''"
        v-if="sale == 'DELIVERY' && !schedule && !pedidosYa"
        v-model="deliveryTime"
        placeholder="Hora Entrega (Opcional)"
        pre="chronometer"
        info="Indica la hora en que se entregará el pedido, ejemplo:<br><ul><li>11:30, 11.30, 11 30 (en formato 24 horas por lo que equivale a las 11:30 AM)</li><li>5pm, 17, 5 30 pm</li><li>40 min, 40m, 1 hora, 1h (el pedido será retirado en 40 minutos o 1 hora)</li><li>1 hora 20 min, 1h 20m, 1 hora 20, 1h 20, en 1 20 (el pedido será retirado en 1 hora 20 minutos)</li></ul>">
            <button @click="schedule = Util.Timestamp()" class="secondary border bl0" slot="after">Agendar</button>
            <button slot="after" v-if="Local.modules.module_pya_courier && settings.pya && !hideActions" v-tooltip="'Enviar por PedidosYa'" class="secondary border bl0 btn-pya" @click="openModalPya()"><i class="icon icon-pedidosya"></i></button>
        </input-field>
        <div v-show="sale == 'DELIVERY' && !schedule" class="secondary-text well mini border bottom" v-if="Util.StringToTime(deliveryTime)">El pedido se entregará a las {{Util.StringToTime(deliveryTime)}} hrs</div>

        <input-date
        ref="dateSchedule"
        v-if="sale!=='SERVE' && schedule && !pedidosYa"
        v-model="schedule"
        :type="wasShipment ? wasShipment : 'DATETIME'"
        format="timestamp"
        :minutesStep="5"
        minDate="today">
            <div v-if="!wasShipment" class="append" slot="after">
                <i  class="icon icon-cross selectable c4" title="Quitar fecha" @click.stop="schedule=null"></i>
            </div>
        </input-date>

        <div class="mt10 flex column" v-if="sale == 'DELIVERY' && pedidosYa">
            <div class="well mini input-top flex align-center" style="color: var(--white); background-color: var(--color-pya)">
                <i class="icon icon-pedidosya mr5"></i>
                <span class="flex-fill">Envio por PedidosYa</span>
                <i  class="icon icon-cross selectable" v-tooltip="'Eliminar envio de PedidosYa'" @click.stop="pedidosYa=null; deliveryCost=null;$delete(customer, 'email')"></i>
            </div>
            <div class="well mini input-bottom border">
                
                <span v-if="schedule"><i class="icon icon-calendar" style="margin-right: 6px"></i> {{Util.Date(schedule, 'HH:mm DD/MM/YYYY')}}</span>
                <span v-else><i class="icon icon-chronometer" style="margin-right: 6px"></i> Inmediato <span v-if="pedidosYa.time_offset" class="secondary-text">({{Util.SecondsToTime(pedidosYa.time_offset * 60)}})</span></span>
            </div>


		</div>

        <input-select v-model="customer.name"
        placeholder="Nombre (Opcional)"
        coverInput class="mt10"
        canType
        pre="user"
        :cleareable="true"
        :readonly="isInputReadonlyName"
        @OnItemSelected="SelectCustomer($event)"
        @OnClearItem="ClearFields('name')"
        :api="{
            url: `/customers/${this.Local.id}/results/name/{SEARCH}`,
            text: 'name',
            description: 'phone'
        }">
        </input-select>
            
        <input-select canType v-model="customer.phone"
        placeholder="Teléfono (Opcional)"
        coverInput class="mt10"
        pre="phone"
        :cleareable="true"
        :readonly="isInputReadonlyName"
        @OnItemSelected="SelectCustomer($event)"
        @OnClearItem="ClearFields('phone')"
        :api="{
            url: `/customers/${this.Local.id}/results/phone/{SEARCH}`,
            text: 'phone',
            description: 'name'
        }">            
        
            <button v-if="customer.phone && !isInputReadonlyName" v-tooltip="'Editar teléfono'" @click.stop="modalEditPhone = true" class="secondary border bl0" slot="after"><i class="icon icon-edit"></i></button>
            <button v-if="customer.phone && showLastOrders" v-tooltip="'Pedidos anteriores'" @click.stop="$emit('OnCustomerLastOrders', customer.phone)" class="secondary border bl0" slot="after"><i class="icon icon-history"></i></button>
        </input-select>

        <input-select v-model="customer.rut"
        v-if="showRut"
        placeholder="RUT (Opcional)"
        coverInput 
        class="mt10"
        canType
        pre="staff"
        :cleareable="true"
        :readonly="isInputReadonlyName"
        @OnItemSelected="SelectCustomer($event)"
        @OnClearItem="ClearFields('rut')"
        :api="{
            url: `/customers/${this.Local.id}/results/rut/{SEARCH}`,
            text: 'rut',
            description: 'name',
        }">
        </input-select>
        
        <input-field class="mt10" v-if="pedidosYa && sale == 'DELIVERY'"
        v-model="customer.email"
        pre="mail"
        placeholder="Email (Opcional)">
        </input-field>

        <div class="mt10" v-if="sale=='DELIVERY'">

            
            <input-field class="input-top"
            v-model="customer.address"
            pre="google-maps"
            placeholder="Dirección (Opcional)"
            :readonly="pedidosYa? true : false">
            </input-field>
            <!--
            <input-select canType v-model="customer.address"
            v-if="sale == 'DELIVERY'"
            placeholder="Dirección (Opcional)"
            coverInput class="mt10 input-top"
            cleareable
            pre="google-maps"
            @OnItemSelected="SelectSearchAddress($event)"
            @OnEnterItem="$set(customer, 'latitude', null); $set(customer, 'longitude', null)"
            @OnClearItem="$set(customer, 'latitude', null); $set(customer, 'longitude', null)"
            :api="{
                url: `/address/${this.Local.id}/search/{SEARCH}`,
                text: 'title',
                description: 'name'
            }">
            </input-select>
            -->

            <input-field class="input-middle" v-if="pedidosYa"
            v-model="pedidosYa.address_additional"
            pre="comment"
            placeholder="Comentario de Dirección (Opcional)">
            </input-field>
            <input-field class="input-middle" v-if="pedidosYa"
            v-model="pedidosYa.instructions"
            pre="comment"
            placeholder="Instrucciones de Reparto (Opcional)">
            </input-field>

            <input-select v-if="canEditDeliveryCost && !pedidosYa && !activeRule" class="input-bottom"
            v-model="deliveryCost"
            :items="settings.delivery_costs"
            placeholder="Costo de Reparto (Opcional)"
            :canType="true"
            ref="selectDeliveryCost"
            pre="delivery"
            coverInput
            :customValueFunction="Util.InputSelectCustomValuePrice">
            </input-select>
            <input-field class="input-bottom"
                v-if="canEditDeliveryCost && !pedidosYa && activeRule"
                :readonly="true"
                :value="`${selectRule.text}`"
                pre="delivery"
                :info="`<ul><li>Los repartos tendrán costo ${Util.Price(selectRule.cost)} si el total del pedido es mayor o igual a ${Util.Price(selectRule.condition)}</li></ul>`">
            </input-field>
            <div class="well mini input-bottom border" v-if="pedidosYa">
                <i class="icon icon-delivery" style="margin-right: 6px"></i>
                <span>{{Util.Price(deliveryCost)}}</span>
                <span v-if="pedidosYa.discount" class="secondary-text ml10">-{{Util.Price(pedidosYa.discount)}} DCTO</span>
            </div>
        </div>

        <div class="separator mt20 mb20"></div>

    </div>


    <div class="">

        <div v-if="!hideItems || !hideComment" class="f18 mb10 flex align-center">
            <div class="flex-fill">Pedido</div>
            <i v-if="editItemPricePOS && canEditItemPricePOS && orderedItems.length" class="options icon-edit-price ml5" v-tooltip="'Editar precio unitario de productos'" @click="OpenModalChangePriceItem()"></i>
        </div>

        <div :class="`well relative mb10 ${canEditItems && ((item.key && item.key.indexOf('order-') == -1) || canEditOld) ? 'selectable' : ''}`"
        v-show="!hideItems"
        v-for="(item, itemIndex) in orderedItems" 
        :key="`${item.key}-${itemIndex}`"
        @click="canEditItems && ((item.key && item.key.indexOf('order-') == -1) || canEditOld) ? SetItem(item, item.key, item.quantity-1) : null"
        v-longclick="() => {canEditItems  && ((item.key && item.key.indexOf('order-') == -1) || canEditOld) ? EditItem(item, item.key) : null}">
            <div class="order-item">
                <div>{{Util.Number(item.quantity)}}</div>
                <div>
                    <div>{{item.title}}</div>
                    <ul>
                        <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">{{modifier.value}}
                            <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                        </li>
                    </ul>
                </div>
                <div>{{Util.Price(item.total_money)}}</div>
                <div v-if="item.tag" :class="`absolute tag-order-form tag-${item.tag}`"></div>
            </div>
        </div>

        <input-field v-if="!hideComment"
        v-model="comment"
        inputClass="secondary"
        placeholder="(Comentario opcional)"
        :lines="3">
        </input-field>

        <div v-if="!hideItems || !hideComment" class="separator mt20 mb20"></div>

        <div class="f18 mb10 flex align-center">
            <div class="flex-fill">Total</div>
            <i v-if="!hideDiscount && (canAddDiscount || canAddCoupon) && !hasDinerSelected" class="options icon-discount ml5" v-tooltip="'Agregar cupón o descuento'" @click="modalDiscount={amount: customDiscount?customDiscount:''}"></i>
            <i v-if="canEditTips && !hideAddTip" class="options icon-coins ml5" v-tooltip="'Agregar propina'" @click="OpenTip()"></i>
        </div>
        
        <div class="flex align-center">
            <div class="flex-fill">Subtotal</div>
            <div>{{Util.Price(total.order)}}</div>
        </div>

        <div class="flex" v-if="sale == 'DELIVERY'">
            <div class="flex-fill">Costo Reparto <i v-if="pedidosYa" class="icon icon-pedidosya" style="color: var(--color-pya)"></i></div>
            <div>{{Util.Price(deliveryCost ? deliveryCost : 0)}}</div>
        </div>
        
        <!--
        <div class="selectable" v-show="discounts.totalDiscount > 0"
        @click="showDiscountDetails = !showDiscountDetails">
            <div class="flex column fill">
                <div class="fill flex align-center">
                    <div class="flex-fill"><i :class="`icon icon-${showDiscountDetails ? 'invisible' : 'eye'} mr5`"></i>Descuento</div>
                    <div>-{{Util.Price(discounts.totalDiscount)}}</div>
                </div>
                <div class="secondary-text" v-if="showDiscountDetails">
                    <div v-for="(discount, index) in discounts.arrDiscounts"
                    :key="index">{{discount.title}} ({{Util.Price(discount.discount)}})
                    </div>
                </div>
            </div>
        </div>
        -->
        <div class="selectable" v-if="discounts.totalDiscount"
        @click="showDiscountDetails = !showDiscountDetails">
            <div class="flex column fill">
                <div class="fill flex align-center">
                    <div class="flex-fill"><i :class="`icon icon-${showDiscountDetails ? 'invisible' : 'eye'} mr5`"></i>Descuento</div>
                    <div>- {{Util.Price(discounts.totalDiscount)}}</div>
                </div>
                <div class="secondary-text" v-if="showDiscountDetails">
                    <div v-for="(discount, index) in discounts.arrDiscounts"
                    :key="index">{{discount.title}} ({{Util.Price(discount.discount)}})
                    </div>
                </div>
            </div>
        </div>
        <div :class="`well mb10 selectable ${discounts.codeUsed ? 'selected cpurple' : ''}`" v-if="coupon" @click="modalCouponDetails=coupon">
            <div class="flex column fill">
                <div class="flex fill">
                    <div class="flex column flex-fill">
                        <div class="bold">{{CouponDiscountType(coupon)}}</div>
                        <div class="secondary-text"><span class="bold">Código: </span>{{coupon.code}}</div>
                    </div>
                    <div>
                        <i class="options icon-cross" v-tooltip="'Quitar cupón'" @click.stop="Confirm({
                            title: `¿Quitar cupón?`,
                            text: 'Quitar',
                            class: 'error-color',
                            function: () => {coupon = null}
                        })"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex" v-if="roundTotal">
            <div class="flex-fill">Ley Redondeo</div>
            <div>${{roundTotal}}</div>
        </div>

        <div class="w100 flex" v-for="(tipEntry, index) in tips" :key="`tip${index}`">
            <div class="flex column flex-fill">
                <div><i v-if="canEditTips" @click="tips.splice(index, 1)" class="icon icon-cross selectable mr5"></i>Propina <span class="secondary-text ml5" v-if="tipEntry.index !== null">(Método {{tipEntry.index+1}})</span></div>
                <div class="secondary-text">{{Util.PayMethod(tipEntry.method)}}</div>
            </div>
            <div>{{Util.Price(tipEntry.total)}}</div>
        </div>

        <div class="f18">
            <div class="fill flex align-center">
                <div class="bold flex-fill">Total</div>
                <div class="bold">{{Util.Price(totalMoney)}}</div>
            </div>
        </div>

        
        <div class="f18" v-if="totalTips">
            <div class="fill flex align-center">
                <div class="bold flex-fill">Total con propina</div>
                <div class="bold">{{Util.Price(totalTips + totalMoney)}}</div>
            </div>
        </div>

    </div>

    <div class="flex column" v-if="paymentMethods.length > 0" v-show="!(sale == 'SERVE' &&  table) && !hidePayments">

        <div class="separator mb20 mt20"></div>

        <div class="f18 mb10 flex align-center">
            <div class="flex-fill">Método de Pago</div>
            <i class="options icon-calculator" v-tooltip="'Dividir pago'" v-if="canEditPayment && !hasDinerSelected" @click="OpenDividePayment()"></i>
            <i class="options icon-plus ml5" v-if="canEditPayment && (!paymentMethods.length || paymentMethods[0].method !== 'ONLINE') && !hasDinerSelected" @click="paymentMethods.push({method: availablePaymentMethods[0].value, subtotal: null, showBtnNTransfer : false})" v-tooltip="'Agregar método de pago'"></i>
        </div>
        <template v-for="(method, index) in paymentMethods">
            <div class="mb20" v-if="Object.keys(method).length > 0" :key="index">
                <div class="well mini border top flex align-center secondary-text" v-if="(method.paid_at || paymentMethods.length > 1)">
                    <div class="flex-fill unshrink">{{ method.diner && method.diner != 'ALL' ? DictTagDinerName(method.diner) : `Método ${index+1}` }}<i v-if="(paymentMethods.length > 1 && paymentMethods[index].subtotal > 0)" :class="`icon-bill selectable ml5 ${method.paid_at ? 'cgreen' : ''}`" @click="!method.paid_at ? RegisterPartialPayment(index) : DeletePartialPayment(index)" :title="!method.paid_at ? 'Marcar como pagado' : 'Desmarcar pagado'"></i></div>
                    <div v-if="method.paid_at">
                        <div class="flex-fill">Parte pagada {{Util.Date(method.paid_at, 'HH:mm')}}</div>
                    </div>
                    <i v-if="canEditPayment && paymentMethods.length > 1" @click="paymentMethods.splice(index, 1)" class="icon-cross selectable ml10"></i>
                    
                </div>

                <input-field v-show="paymentMethods.length > 1" :inputClass="paymentMethods.length > 1 || method.paid_at ? 'input-middle' : (method.method == 'CASH' ? 'input-top' : '')"
                v-model="method.subtotal"
                @OnChange="CalculateSubtotals(index)"
                :placeholder="`(Subtotal a pagar con ${Util.PayMethod(method.method)})`"
                :readonly="!canEditPayment"
                type="number">                    
                    <div slot="after" class="append unshrink" v-if="MethodTip(index) !== false"><span class="bold mr5">con propina </span>{{Util.Price(MethodTip(index) + method.subtotal)}}</div>
                    <!--
                    <div slot="after" class="append pa0">
                        <i v-if="canEditPayment && paymentMethods.length > 1" @click="paymentMethods.splice(index, 1)" class="options icon-cross ha"></i>
                    </div>
                    -->
                </input-field>

                <input-select  class="flex-fill" :inputClass="paymentMethods.length > 1 || method.paid_at ? (method.method == 'CASH' || method.showBtnNTransfer ? 'input-middle' : 'input-bottom') : (method.method == 'CASH' || method.showBtnNTransfer ? 'input-top' : '')"
                v-model="method.method"
                :items="availablePaymentMethods"
                :readonly="!canEditPayment"
                canType="search"
                @OnValueChanged="OnPaymentMethodChanged($event, index)">
                    <!--TODO: hacer una clase para ese style ? y algo para el input-select y no tener que ocupar click.stop -->
                    <button slot="after" v-if="method.method != 'CASH' && method.method != 'NO_SELECT'" v-tooltip="'N° pago/transferencia'" class="secondary border bl0"   :class="method.showBtnNTransfer ? 'input-top' : null" 
                    :style="method.showBtnNTransfer ? (paymentMethods.length > 1 ? 'border-bottom-right-radius: 0px !important; border-top-right-radius: 0px !important;' : 'border-bottom-right-radius: 0px !important;')  : (paymentMethods.length > 1 ? 'border-top-right-radius: 0px !important;' : null)"  
                    @click.stop="ToggleBtnNTransfer(index)"><i class="icon icon-sheet"></i></button>
                </input-select>

                <input-field v-if="method.method == 'CASH'" inputClass="input-bottom"
                v-model="method.cash"
                prefix="Efectivo: $"
                placeholder="Efectivo (Opcional)"
                :readonly="!canEditPayment"
                type="int+">
                    <div slot="after" class="append primary" v-if="MethodChange(method, index) !== false"><span class="bold mr5">Vuelto </span>{{Util.Price(MethodChange(method, index))}}</div>
                    <!-- <div slot="after" class="flex" v-if="!isNaN(method.cash) && method.cash-method.subtotal > 0"><span class="bold mr5">Vuelto </span>{{Util.Price(method.cash - method.subtotal)}}</div> -->
                </input-field>

                <input-field v-if="method.method != 'CASH' && method.showBtnNTransfer && method.method != 'NO_SELECT'" inputClass="input-bottom"
                v-model="method.n_transfer"            
                :ref="'nTransfer_' + index"
                placeholder="N° pago/transferencia (Opcional)"
                before=" "
                pre=" ">
                <span class="icon" slot="inner-before">
                    N°
                </span>
                </input-field>
            </div>
        </template>
        <div v-if="availableDeliveryPaymentMethods" class="mb20">
            <div class="well mini secondary-text border input-top"><i class="icon icon-delivery mr5"></i> Método de Pago Reparto ({{Util.Price(deliveryCost)}})</div>
            <input-select inputClass="input-bottom"
            v-model="deliveryPaymentMethod"
            :items="availableDeliveryPaymentMethods"
            canType="search"
            :readonly="!canEditPayment">
            </input-select>
        </div>
        
    </div>

    <div class="flex-fill"></div>
    
    <div class="bottom-actions" :style="mobile ? {bottom: '10px'} : null">
        <button v-show="hasScroll && !isScrollAtBottom" @click="Util.ScrollToBottom(parentScrollEl)" class="absolute primary blue mr5" style="z-index: 100; left: 0px; top: -50px; border-radius: 50%" v-tooltip="'Bajar'"><i class="icon icon-arrow-down"></i></button>
        <button v-if="!hideActions" :disabled="total.items <= 0 || (settings.no_payment_select && paymentMethods.some(method => method.method == 'NO_SELECT')) || sale === null" :class="`w100 primary purple`" @click="RegisterOrder()" style="">Ingresar Pedido <span v-if="total.items > 0" class="ml5">{{Util.Price(total.totalMoney - discounts.totalDiscount + roundTotal)}}</span></button>
        <slot name="actions"></slot>
    </div>

    <!-- MODALS VVVV -->

    <modal v-model="modalItemQuantity" dir="up" maxSize="80vh">
        <div class="pa10 flex" v-if="modalItemQuantity">
            <i @click="modalItemQuantity.quantity--" class="options icon-minus"></i>
            <input-field class="flex-fill ml10 mr10"
            v-model="modalItemQuantity.quantity"
            type="number"
            label="Cantidad">
            </input-field>
            <i @click="modalItemQuantity.quantity++" class="options icon-plus"></i>
        </div>
        <div class="modal-actions" v-if="modalItemQuantity">
            <button :class="`${modalItemQuantity.quantity <= 0 ? 'error-color' : 'selected-color'} outline`" 
            @click="SetItem(modalItemQuantity.item, modalItemQuantity.key, modalItemQuantity.quantity);modalItemQuantity=null">Aceptar</button>
        </div>
    </modal>

    <modal v-model="modalDiscount" dir="center" modalClass="sm" :title="`${canAddCoupon && canAddDiscount ? 'Cupón / Descuento' : (canAddCoupon ? 'Cupón' : 'Descuento')}`">
        <div class="section" v-if="modalDiscount">
            <input-field
            v-model="modalDiscount.amount"
            :label="`${canAddCoupon && canAddDiscount ? 'Cupón / Descuento' : (canAddCoupon ? 'Cupón' : 'Descuento')}`"
            :description="`${canAddCoupon && canAddDiscount ? 'Ingresa el código de un cupón o el monto de descuento, puede ser un número fijo o un porcetaje' : (canAddCoupon ? 'Ingresa el código de un cupón' : 'Ingresa el monto de descuento, puede ser un número fijo o un porcetaje')}`"
            :placeholder="`${canAddCoupon && canAddDiscount ? '(Ejemplo: 20%)' : (canAddCoupon ? '' : '(Ejemplo: 20%)')}`">
            </input-field>
        </div>
        
        <button slot="actions" v-if="customDiscount" class="primary error-color" @click="customDiscount=null; modalDiscount=null">Eliminar</button>
        <button slot="actions" v-if="canAddDiscount" class="primary purple" @click="SetCustomDiscount()">Ingresar <span v-if="canAddCoupon && modalDiscount && modalDiscount.amount.length >= 5 && !isNaN(modalDiscount.amount)" class="ml5">DCTO</span></button>
        
        <button v-if="(canAddCoupon && !canAddDiscount) || (canAddCoupon && modalDiscount && modalDiscount.amount.length >= 5 && !isNaN(modalDiscount.amount))" slot="actions" class="primary purple" @click="SetCustomDiscount(null, true)">Ingresar Cupón</button>
        
    </modal>

    <modal v-model="modalDivide" dir="right" :modalClass="['fill', 'md right h100']" title="Dividir Pago">
        <div class="section" v-if="modalDivide">
            
            <div class="entry"
            v-for="(item, index) in modalDivide.items" :key="index">
                <div class="fill hdvm">
                    <div class="flex column flex-fill justify-center">
                        <div></div>
                        <div>{{item.title}}</div>
                        <ul class="secondary-text"><li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">{{modifier.value}}</li></ul>
                    </div>
                    <div class="flex align-center" style="width: auto !important">
                        <div :class="`customer-divide ${item['customer-'+i] ? 'selected' : ''}`"
                        @click="$set(item, 'customer-'+i, item['customer-'+i] ? false : true)"
                        v-for="i in modalDivide.customers" :key="`item-${index}-customer-${i}`">{{i}}</div>
                        <i @click="SelectAllCustomers(item)" title="Seleccionar todos" class="options icon-check ml5"></i>
                    </div>
                </div>
            </div>
            
        </div>
        
        <div slot="actions" class="flex algin-center flex-fill mr20" v-if="modalDivide">
        
            <i class="options icon-minus mr5" @click="modalDivide.customers > 2 ? $set(modalDivide, 'customers', modalDivide.customers-1) : null"></i>
            <input-field class="flex-fill"
            v-model="modalDivide.customers"
            type="int+"
            suffix=" clientes">
            </input-field>
            <i class="options icon-plus ml5" @click="$set(modalDivide, 'customers', modalDivide.customers+1)"></i>
            
        </div>
        <button slot="actions" class="primary purple" @click="DividePayment()">Dividir</button>
        
    </modal>

    <modal v-model="modalTips" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Agregar Propina">
        <div class="section" v-if="modalTips">
            <input-select class="mb15"
            label="Total"
            description="Indica sobre que monto se calculará la propina"
            @OnValueChanged="$set(modalTips, 'method', $event.method)"
            v-model="modalTips.subtotal"
            :items="modalTips.subtotals">
            </input-select>
            <!--
            <div class="input-style">
                <div class="input-label">Total</div>
                <div>{{Util.Price(total.totalMoney - discounts.totalDiscount)}}</div>
            </div>
            -->
            <input-field v-model="modalTips.amount" label="Propina" class="mb15" 
            info="<ul><li>Porcentaje: 10% ó 10x ó 10X equivale al 10% del total</li><li>Resto: -10000 equivale al $10.000 - el total</li><li>Fijo: 2500 equivale a una propina fija de $2.500</li></ul>">
            </input-field>

            <input-select v-model="modalTips.method" 
            :items="modalTips.methods" 
            label="Método de Pago"
            canType="search">
            </input-select>
        </div>
        
        <button slot="actions" class="primary purple" :disabled="!tip" @click="AddTip()">Agregar Propina <span v-if="tip" class="ml5">{{Util.Price(tip)}}</span></button>
        
    </modal>

    <modal v-model="modalCouponDetails" title="Detalles del Cupón" dir="center" modalClass="md" hideActions>
        <div class="pa20" v-if="modalCouponDetails">
            <div class="mb20">
                <div class="bold">{{CouponDiscountType(modalCouponDetails)}}</div>
                <div v-if="modalCouponDetails.minimun"><span class="bold">Mínimo: </span>{{Util.Price(modalCouponDetails.minimun)}}</div>
                <div v-if="modalCouponDetails.method !== 'ANY'"><span class="bold">Método de pago: </span>{{Util.PayMethod(modalCouponDetails.method)}}</div>
                <div v-if="modalCouponDetails.sale !== 'ANY'"><span class="bold">Compras: </span>para {{Util.SaleType[modalCouponDetails.sale]}}</div>
                <div v-if="CouponItemsRule(modalCouponDetails)">
                    <div><span class="bold">Aplica: </span>{{CouponItemsRule(modalCouponDetails)}} {{ modalCouponDetails.not_apply_discount ? '(sujeto a restricción horaria)' : null }}</div>
                </div>
            </div>
            <div>
                <div class="bold">Productos del Cupón</div>
                <table class="summary">
                    <tr>
                        <th>Item</th>
                        <th>Modificadores Requeridos</th>
                        <th>Aplica a modificadores</th>
                    </tr>
                    <tr v-for="(val, section) in modalCouponDetails.sections && Object.keys(simplifiedData).length > 0? modalCouponDetails.sections : {}" :key="section">
                        <td>Productos de la sección {{section}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr v-for="(item, index) in modalCouponDetails.items" :key="index">
                        <td>{{item.title}}</td>
                        <td>
                            <div v-for="(modifier, modIndex) in item.modifiers" :key="`item-${index}-mod-${modIndex}`">{{modifier.group}} - {{modifier.title}}</div>
                        </td>
                        <td>{{item.include_modifiers ? 'Si' : 'No'}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </modal>

    <modal v-model="modalEditPhone" title="Editar teléfono cliente" :dir="['right', 'center']" :modalClass="['fill', 'sm']" >
        <div class="pa20" v-if="modalEditPhone">
            <div class="flex-fill mb15">
                <div class="bold">Nombre</div>
                <div>{{customer.name}}</div>
            </div>
            <div class="flex-fill mb15">
                <div class="bold">Teléfono Actual</div>
                <div>{{customer.phone}}</div>
            </div>
            <input-field 
            class=""
            v-model="customer.newPhone"
            label="Nuevo teléfono"
            >
            </input-field>
            
        </div>
        <button slot="actions" @click="EditCustomerPhone()" class="primary purple">Editar</button>
    </modal>

    <modal v-model="modalChangePriceItem" title="Cambiar Precio Unitario" dir="right" :modalClass="['fill', 'md right h100']"  >
        <div class="pa20" v-if="modalChangePriceItem">
            <div class="well relative mb10 hdvm"
            v-for="(item, itemIndex) in modalChangePriceItem.items" 
            :key="`${item.key}-${itemIndex}`">
                
                    <div class="flex column">
                        <div class="flex"><span style="min-width: 30px; display: inline-block">{{item.quantity}}</span>{{item.title}}</div>
                        <ul style="margin-left: 30px">
                            <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">
                                {{modifier.value}}
                            </li>
                        </ul>
                    </div>
                    <div class="flex column">
                        <input-field v-model="item.value_price" @OnChange="$set(item, 'price', item.original_price - Util.ApplyModifier(item.original_price, item.value_price))"
                        info="<ul><li>Porcentaje: 10% ó 10x ó 10X equivale al 10% del total</li><li>Resto: -10000 equivale al $10.000 - el total</li><li>Fijo: 2500 equivale a un fijo de $2.500</li></ul>">
                            <i slot="after" class="append selectable icon icon-revert" v-tooltip="'Revertir a precio original'" @click="item.value_price = item.original_price.toString()"></i>
                            <div slot="after" class="append primary bold">{{Util.Price(item.price * item.quantity)}}</div>
                        </input-field>
                        <div class="secondary-text mt5">Precio original {{Util.Price(item.original_price)}}</div>
                    </div>
                </div>
            
        </div>
        <div slot="actions" class="bold f18 align-center mr10">Total {{Util.Price(totalModalChangePriceItem)}} </div>
        <button slot="actions" class="primary purple" @click="ChangePriceTempItem()">Cambiar Precios</button>
    </modal>

    <modal-estimate-pya ref="estimatePya" 
    :pos="true" 
    @CompleteData="FillFields($event)">
    </modal-estimate-pya>

    

</div>
</template>
<script>
export default {
    props: {
        value: {
            type: [Object, Array],
            default: () => {}
        },
        fromTransaction: {
            type: [Object, Array],
            default: null
        },
        baseBenefits: {
            type: Array,
            default: () => []
        }, 
        settings: {
            type: Object,
            default: null
        },
        categories: {
            type: [Object, Array],
            default: () => {}
        },
        tables: {
            type: Array,
            default: () => []
        },
        hideSaleType: {
            type: Boolean,
            default: false
        },
        hideCustomer: {
            type: Boolean,
            default: false,
        },
        hideCustomerCreation: {
            type: Boolean,
            default: false
        },
        hideComment: {
            type: Boolean,
            default: false
        },
        hideActions: {
            type: Boolean,
            default: false
        },
        canEditItems: {
            type: Boolean,
            default: true
        },
        hideLastOrder: {
            type: Boolean,
            default: true
        },
        hideSaleTypeButtons: {
            type: Boolean,
            default: false
        },
        canEditPayment: {
            type: Boolean,
            default: true
        },
        canEditDeliveryCost: {
            type: Boolean,
            default: true,
        },
        hideItems: {
            type: Boolean,
            default: false
        },
        hidePayments: {
            type: Boolean,
            default: false
        },
        hideDiscount: {
            type: Boolean,
            default: false
        },
        hideAddTip: {
            type: Boolean,
            default: false
        },
        showLastOrders: {
            type: Boolean,
            default: false
        },
        showRut: {
            type: Boolean,
            default: false
        },
        editItemPricePOS: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDiscountDetails: false,
            modalItemQuantity: null,
            customerAccount: null,

            sale: 'SERVE',
            customer: {},
            comment: null,
            paymentMethods: [],
            customDiscount: null,
            modalDiscount: null,
            table: null,
            modalDivide: null,
            deliveryCost: null,
            takeaway: '',
            //resultados de la bsusqueda de clientes
            customerResults: [],
            //el temporizador para buscar clientes
            timerSearch: null,
            //control para ocultar la lista de busqueda de clientes
            hideSearchBox: true,
            //@data <String> el string de tiempo para tiempo a retirar
            deliveryTime: '',
            coupon: null,
            schedule: null,
            tips: [],
            modalTips: null,
            deliveryPaymentMethod: null,
            isExternalDelivery: false,
            wasShipment: false,
            modalCouponDetails: null,
            pedidosYa: null,
            isInputReadonlyName: false,
            activeRule : false,
            selectRule : null,
            simplifiedData : {},
            modalEditPhone : false,
            dictDiners : null,
            hasDinerSelected : false,
            modalChangePriceItem : null,
            observer: null,
            hasScroll: false,
            parentScrollEl: null,
            isScrollAtBottom: false,
        }
    },
    mounted() {
        this.Reset();
        this.SetupScrollListener();
    },
    methods: {
        Reset() {
            this.$emit('input', {});
            this.table = null;
            this.sale = null;
            this.customer = {};
            this.comment = null;
            this.paymentMethods = [];
            this.deliveryCost = null;
            this.$nextTick(() => {
                var availableSaleTypes = [];
                if(this.settings.accept_serve) availableSaleTypes.push('SERVE');
                if(this.settings.accept_takeaway) availableSaleTypes.push('TAKEAWAY');
                if(this.settings.accept_delivery) availableSaleTypes.push('DELIVERY');
                if(!this.hideSaleType) 
                {
                    this.sale = availableSaleTypes.indexOf(this.settings.default_sale) > -1 ? this.settings.default_sale : (availableSaleTypes.length ? availableSaleTypes[0] : 'SERVE');
                    if(this.settings.default_sale == 'NO_DEFAULT') this.sale = null;
                }
                if(!this.paymentMethods.length) {
                    this.paymentMethods = [{method: this.availablePaymentMethods[0].value, subtotal: null, showBtnNTransfer : false}];
                }else {
                    // Se asegura que el ultimo metodo de pago que tenga el paymentMethods, tenga como metodo seleccionado el metodo predeterminado
                    let lastPaymentMethodIndex = this.paymentMethods.length - 1;
                    if(!this.paymentMethods[lastPaymentMethodIndex].paid_at) this.paymentMethods[lastPaymentMethodIndex].method = this.availablePaymentMethods[0].value;
                    
                }
                if(!this.fromTransaction) this.ChangeSale(this.sale);
            });
            this.customerAccount = null;
            this.customDiscount = null;
            this.takeaway = '';
            this.deliveryTime = '';
            this.coupon = null;
            this.schedule = null;
            this.tips = [];
            this.deliveryPaymentMethod = null;
            this.wasShipment = false;
            this.pedidosYa = null;
            this.isInputReadonlyName = false;
        },
        SetItem(item, key, quantity)
        {
            quantity = this.Util.RoundFloat(quantity);
            if(quantity <= 0)
                this.$delete(this.value, key);
            else
            {
                this.$set(item, 'quantity', quantity);
                this.$set(item, 'total_money', parseInt(item.price * quantity) );
            }
        },
        OnPaymentMethodChanged(method, index)
        {
            //cambiar método de pago reparto
            if(index == 0 && this.availableDeliveryPaymentMethods && this.paymentMethods.length == 1)
            {
                this.deliveryPaymentMethod = method;
            }

            let tip = this.tips.find(t => t.index === index);
            if(tip) tip.method = this.paymentMethods[index].method;
        },
        CalculateSubtotals(index)
        {
            if(this.modalDivide) return;
            if(this.paymentMethods.length == 2)
            {
                var method = this.paymentMethods[index];
                if(method.subtotal)
                {
                    var total = this.total.totalMoney - this.discounts.totalDiscount;
                    var method2 = this.paymentMethods[(index+1)%2];
                    if(this.settings.default_tip_table && this.tips && this.tips.length){
                        if(index == 0) this.tips[0].total = (total-method2.subtotal) * (10 / 100)
                        else this.tips[0].total = (total-method.subtotal) * (10 / 100)
                    }
                    if(total - method.subtotal > 0)
                        this.$set(method2, 'subtotal', total-method.subtotal);
                }
            }
            else if(index  < this.paymentMethods.length-1)
            {
                var subtotal = 0;
                for(var i = 0; i < this.paymentMethods.length-1; i++)
                {
                    if(this.paymentMethods[i].subtotal)
                        subtotal += this.paymentMethods[i].subtotal;
                }

                var method = this.paymentMethods[this.paymentMethods.length-1];
                var total = this.total.totalMoney - this.discounts.totalDiscount;
                if(total-subtotal > 0)
                    this.$set(method, 'subtotal', total-subtotal);
                // else 
                //     this.$set(method, 'subtotal', 0);
                
                if(this.settings.default_tip_table && this.tips && this.tips.length){
                    if(index == 0) {
                        this.tips[0].total = this.paymentMethods[0].subtotal * (10 / 100)
                    }
                }
                
            }
            
        },
        GetOrder()
        {
            if(typeof this.transaction == 'string') return this.Info(this.transaction);
            var data = JSON.parse(JSON.stringify(this.transaction));

            data.total_money = this.total.totalMoney + this.roundTotal;
            data.total_discount = this.discounts.totalDiscount;
            data.type = this.sale;
            data.customer = this.customer;

            if(this.sale !== 'DELIVERY')
                data.customer.address = null;
            //if(this.sale !== 'DELIVERY' && (this.sale !== 'TAKEAWAY' && this.takeawayTime))
            //data.customer.phone = null;
        
            data.comment = this.comment ? this.comment : null;
            
            if(this.sale == 'SERVE' && this.table !== null)
                data.table = this.table;
            else if(this.sale == 'TAKEAWAY' && this.Util.StringToTime(this.takeaway) && !this.schedule)
                data.activation_time = this.Util.StringToTime(this.takeaway);
            else if(this.sale == 'DELIVERY' && this.Util.StringToTime(this.deliveryTime) && !this.schedule)
                data.delivery_time = this.Util.StringToTime(this.deliveryTime);

            if(this.schedule){ //Sí trae schedule es pedido programado
                data.schedule = this.schedule;
                if(this.sale == 'TAKEAWAY' && !this.wasShipment) data.activation_time = this.Util.StringToTime(this.Util.Date(this.schedule, 'HH:mm')); //Sí es pedido programado, lo correcto es que activation_time conserve la hora y minutos en que se retirará
                if(this.sale == 'DELIVERY' && !this.wasShipment) data.delivery_time = this.Util.StringToTime(this.Util.Date(this.schedule, 'HH:mm')); //Sí es pedido programado, lo correcto es que delivery_time conserve la hora y minutos en que se repartirá
            }

            var categories = [];

            if(this.customerAccount && this.customerAccount.id_user)
            {
                data.user = this.customerAccount.id_user;
                data.coupons = this.discounts.coupons;
            }

            
            data.tips = this.tips;

            if(this.sale == 'DELIVERY' && this.pedidosYa)
            {
                data.pedidosYa = this.pedidosYa;
                data.pedidosYa.name = this.customer.name;
                data.pedidosYa.phone = this.customer.phone;
                data.pedidosYa.address = this.customer.address;
                data.pedidosYa.email = this.customer.email;
            } 
            console.log("GET ORDER DATA");
            console.log(data);
            return data;
        },
        RegisterOrder(checkErrors)
        {
            if(this.settings.no_payment_select && this.paymentMethods.some(method => method.method == 'NO_SELECT')) return this.Info(`Debe seleccionar un método de pago.`);
            if(this.sale == null) return this.Info('Debe seleccionar un Tipo de Pedido');

            if(this.settings.no_payment_select && this.deliveryPaymentMethod == 'NO_SELECT') return this.Info(`Debe seleccionar un método de pago para pagar el costo reparto.`);

            if(this.minDate.anticipation){
                if(!this.schedule) {
                    this.schedule = this.minDate.dateStart;
                    return this.Info(`Se ha actualizado la fecha del pedido agendado a ${this.minDate.dateStartParse} debido a horas de reserva de productos`);
                }

                if(this.schedule && this.schedule < this.minDate.dateStart){
                    this.schedule = this.minDate.dateStart;
                    return this.Info(`Se ha actualizado la fecha del pedido agendado a ${this.minDate.dateStartParse} debido a horas de reserva de productos`);
                }
            }

            if(checkErrors===undefined) checkErrors = true;
            if(this.pedidosYa && (!this.customer.phone || !this.customer.name)) return this.Info('Nombre y teléfono del cliente son requeridos para Envios por PedidosYa');

            if(checkErrors)
            {
                if(typeof this.transaction == 'string') return this.Info(this.transaction);
                /*
                if(this.sale == 'DELIVERY' && !this.customer.address) return this.Confirm({
                    title: '¿Ingresar un pedido delivery sin dirección?',
                    text: 'Ingresar',
                    class: 'selected-color',
                    function: () => {this.RegisterOrder(false)}
                });
                */
            }

            if(this.schedule)
            {
                var now = this.Util.Date('now', 'timestamp');
                if(this.schedule >= now)
                {
                    this.Confirm({
                        text: 'Agendar Pedido',
                        title: `¿Agendar pedido para el ${this.Util.Date(this.schedule, 'HH:mm DD/MM/YYYY')}?`,
                        function: () => {
                            this.Loading();
                            let orderData = this.GetOrder();
                            orderData.uid = this.Util.UUIDv4();
                            axios.put(`/transaction/${this.Local.id}/scheduled`, orderData).then( ({data}) => {
                                if(data.error){
                                    if(data.new_estimate){
                                        this.pedidosYa.deliveryOfferId = data.new_estimate.deliveryOffers[0].deliveryOfferId;
                                        this.pedidosYa.estimateId = data.new_estimate.estimateId;
                                        this.pedidosYa.price = data.new_estimate.deliveryOffers[0].pricing;

                                        this.deliveryCost = data.new_estimate.deliveryOffers[0].pricing.total; 
                                    }
                                    return this.Info(data.message);
                                }
                                this.Reset();
                                this.Info(`Pedido agendado exitosamente`);
                            }).catch(err => {
                                if(err.message == 'Network Error')
                                    this.CheckIfTransactionCreated(orderData.uid, 'Pedido agendado exitosamente');
                                else
                                    this.Response(err);
                            });
                        }
                    });
                    return;
                }
                else
                {
                    if(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.create_past_transactions || this.pedidosYa)
                    {
                        this.Confirm({
                            text: 'Crear Pedido',
                            title: `¿Crear pedido con fecha pasada ${this.Util.Date(this.schedule, 'HH:mm DD/MM/YYYY')}? El pedido será ingresado como completado`,
                            function: () => {
                                this.Loading();
                                let orderData = this.GetOrder();
                                orderData.uid = this.Util.UUIDv4();
                                axios.put(`/transaction/${this.Local.id}/past`, orderData).then(res => {
                                    this.Reset();
                                    this.Info(`Pedido creado exitosamente`);
                                }).catch(err => {
                                    if(err.message == 'Network Error')
                                        this.CheckIfTransactionCreated(orderData.uid, 'Pedido creado exitosamente');
                                    else
                                        this.Response(err);
                                });
                            }
                        });
                    }
                    else
                    {
                        this.Info('Fecha inválida');
                    }
                    return;
                }
                
            }

            var func = (data) => {
                this.Loading();

                axios.put(`/transaction/${this.Local.id}`, data).then( ({data}) => {
                    if(data.error){
                        if(data.new_estimate){ //Cambió el valor del envío
                            //Se actualiza pedidosya
                            this.pedidosYa.deliveryOfferId = data.new_estimate.deliveryOffers[0].deliveryOfferId;
                            this.pedidosYa.estimateId = data.new_estimate.estimateId;
                            this.pedidosYa.price = data.new_estimate.deliveryOffers[0].pricing;
                            
                            //Se actualiza valor de delivery
                            this.deliveryCost = data.new_estimate.deliveryOffers[0].pricing.total;
                        }
                        return this.Info(data.message);
                    }
                    this.Reset();
                    this.$emit('TransactionSuccess', data);
                    this.Response(data);
                }).catch(err => {
                    if(err.message == 'Network Error')
                        this.CheckIfTransactionCreated(data.uid);
                    else
                        this.Response(err);
                });
            };

            let data = this.GetOrder();
            //identificador para verificar pedidos duplicados
            data.uid = this.Util.UUIDv4();
            let deliveryMessage = this.sale == 'DELIVERY' && !this.customer.address;

            if(this.settings.default_paid == 'CUSTOM')
            {
                var options = [
                    {
                        text: 'Ingresar Pedido Pagado', 
                        class: deliveryMessage ? 'error-color' : 'selected-color', 
                        function: () => {
                            data.paid = true;
                            func(data);
                        },
                        confirm: deliveryMessage ? '¿Ingresar un pedido delivery sin dirección?' : ''
                    },
                    {
                        text: 'Ingresar Pedido', 
                        class: deliveryMessage ? 'error-color' : '', 
                        function: () => {
                            data.paid = false;
                            func(data);
                        },
                        confirm: deliveryMessage ? '¿Ingresar un pedido delivery sin dirección?' : ''
                    }
                ];
                this.Options(options);
            }
            else
            {
                data.paid = this.settings.default_paid == 'CREATED';
                if(deliveryMessage)
                {
                    this.Confirm({
                        title: '¿Ingresar un pedido delivery sin dirección?',
                        text: 'Ingresar',
                        class: 'error-color',
                        function: () => {func(data)}
                    });
                }
                else
                    func(data);
            }


        },
        FillFields(parameters){
            this.pedidosYa = parameters;
            this.takeaway = Util.Date(parameters.date, 'HH:mm');
            this.$set(this.customer, 'name', parameters.name);
            this.$set(this.customer, 'phone', parameters.phone);
            this.$set(this.customer, 'address', parameters.address);
            this.$set(this.customer, 'email', parameters.email ? parameters.email : null);
            this.deliveryCost =  parameters.price.total;
            this.schedule = parameters.scheduled ? parameters.date : null;
        },
        LoadTransaction(id) 
        {
            this.Loading();
            axios.get(`/transaction/${id}/orders`).then(res => {
                this.Response(res);
                console.log(res.data);
                if(res.data.length > 0)
                {
                    for(var i = 0; i < res.data.length; i++)
                        this.$emit('AddItem', res.data[i]);
                }
            }).catch(err => {
                this.Response(err);
            });
        },
        SetCustomDiscount(value, isCoupon)
        {
            if(value)
            {
                this.customDiscount = value;
            }
            else
            {
                var code = this.modalDiscount.amount;
                if(isCoupon || (isNaN(code) && code.length >= 5))
                {
                    //buscar codigo de cupon
                    if(!this.canAddCoupon) return this.Info('No se puede agregar cupones de descuento');
                    
                    this.Loading();
                    axios.post(`/coupon/${this.Local.id}/code`, {code: code}).then(res => {
                        this.Response(res);
                        this.coupon = res.data;
                        this.modalDiscount = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                    return;
                }

                if(!this.canAddDiscount) return this.Info('No se puede agregar descuento');
                if(!this.modalDiscount.amount || isNaN(this.modalDiscount.amount.toString().toUpperCase().replace('%', '').replace('X', ''))) return this.Info('Cantidad inválida');
                this.customDiscount = this.modalDiscount.amount.replace('-', '');
                this.modalDiscount = null;
            }
        },
        OpenDividePayment() 
        {
            var order = Object.values(this.value);
            if(!order.length) return this.Info('Pedido inválido');
            var items = [];
            order.forEach(order => {
                for(var i = 0; i < order.quantity; i++)
                    items.push(JSON.parse(JSON.stringify(order)));
            });
            
            this.discounts.arrDiscounts.forEach(discount => {
                items.push({title: `DESCUENTO ${discount.item}: -${this.Util.Price(discount.discount)}`, price: -discount.discount});
            });

            this.modalDivide = {
                items: items,
                customers: 2
            }
        },
        DividePayment()
        {
            var customers = parseInt(this.modalDivide.customers);
            if(isNaN(customers) || customers < 2) return this.Info('Número de clientes inválido');

            var subtotals = [];
            for(var i = 0; i < customers; i++)
                subtotals.push({money: 0, items: []});
            
            for(var i = 0; i < this.modalDivide.items.length; i++)
            {
                var item = this.modalDivide.items[i];
                
                var itemCustomers = [];
                for(var j = 1; j <= customers; j++)
                {
                    if(item['customer-'+j])
                        itemCustomers.push(j);
                }
                if(!itemCustomers.length) return this.Info('Debe haber almenos un cliente seleccionado en todos los items');
            
                
                if(itemCustomers.length == 1)
                {
                    subtotals[itemCustomers[0]-1].money += item.price;
                    subtotals[itemCustomers[0]-1].items.push(`100% ${item.title}: ${Util.Price(item.price)}`);
                }
                else
                {
                    var subtotal = parseInt(item.price / itemCustomers.length);
                    //2000 / 3 = 666
                    //660 + 660 = 1320
                    //680
                    var round = Math.floor(subtotal/10)*10;
                    var first = item.price - (round * (itemCustomers.length-1));
                    
                    var isFirst = true;
                    itemCustomers.forEach(customer => {
                        subtotals[customer-1].money += isFirst ? first : round;
                        subtotals[customer-1].items.push(`${Math.floor(round/item.price*100)}% ${item.title}: ${Util.Price(isFirst ? first : round)}`);
                        isFirst = false;
                    });

                }
                console.log(subtotals);
            }

            var methods = [];
            for(var i = 0; i < subtotals.length; i++)
            {
                methods.push({method: 'CASH', subtotal: subtotals[i].money, cash: null});
            }
            this.paymentMethods = methods;
            this.$nextTick(() => {
                this.modalDivide = null;   
            });
            
        },
        SelectAllCustomers(item)
        {
            for(var i = 1; i <= this.modalDivide.customers; i++)
                this.$set(item, 'customer-'+i, true);
        },
        /*
        Selecciona un cliente de la lista de busqueda. En caso de ser un cliente influye, descarga los cupones
        */
        SelectCustomer(customer)
        {
            this.hideSearchBox = true;

            if(Array.isArray(customer.items)) customer.items = {};
            this.customerAccount = customer;
            this.$set(this.customer, 'name', customer.name);
            if(customer.phone) this.$set(this.customer, 'phone', customer.phone);
            if(customer.rut) this.$set(this.customer, 'rut', customer.rut);
            if(customer.address && !this.pedidosYa) this.$set(this.customer, 'address', customer.address);
            if(customer.zone && this.settings.customer_auto_select_delivery_cost)
            {
                var zoneValue = null;
                for(var i = 0; i < this.settings.delivery_costs.length; i++)
                {
                    var option = this.settings.delivery_costs[i];
                    if(customer.zone == option.title)
                    {
                        zoneValue = option.value;
                        break;
                    }
                }
                if(zoneValue !== null && this.$refs.selectDeliveryCost)
                    this.$refs.selectDeliveryCost.SelectItem(zoneValue);
            }

            this.isInputReadonlyName = (!customer.name || (this.isAdmin || this.Auth.restrictions.can_edit_name_client))? false : true;
            console.log(customer);
        },
        EditItem(item, index)
        {
            if(!this.canEditItems) return;
            //this.SetItem(item, index, 0);
            this.$emit('OnEditItem', item);
        },
        OpenTip()
        {
            var subtotals = [];
            if(this.total.order !== this.totalMoney)
            {
                subtotals.push({text: `Subtotal Pedido: ${this.Util.Price(this.total.order)}`, value: {total: this.total.order, method: this.paymentMethods[0].method, index: null}});
                subtotals.push({text: `Total Pedido: ${this.Util.Price(this.totalMoney)}`, value: {total: this.totalMoney, method: this.paymentMethods[0].method, index: null}});
            }
            else
                subtotals.push({text: `Total Pedido: ${this.Util.Price(this.totalMoney)}`, value: {total: this.totalMoney, method: this.paymentMethods[0].method, index: null}});
            
            if(this.paymentMethods.length > 1)
            {
                for(var i = 0; i < this.paymentMethods.length; i++)
                {
                    var method = this.paymentMethods[i];
                    if(!isNaN(parseInt(method.subtotal)))
                        subtotals.push({text: `Método Pago ${i+1} (${this.Util.PayMethod(method.method)}): ${this.Util.Price(method.subtotal)}`, value: {total: method.subtotal, method: method.method, index: i}});
                };
                for(var i = 0; i < this.paymentMethods.length; i++)
                {
                    var method = this.paymentMethods[i];
                    subtotals.push({text: `Total: ${this.Util.Price(this.totalMoney)} (al Método Pago ${i+1})`, value: {total: this.totalMoney, method: method.method, index: i}});
                };
                subtotals.push({text: `Total: ${this.Util.Price(this.totalMoney)} (dividido en ${this.paymentMethods.length} métodos de pago)`, value: {total: this.totalMoney, method: 'AUTO', index: 'SPLIT'}});
                subtotals.push({text: `Total: ${this.Util.Price(this.totalMoney)} (a cada método de pago)`, value: {total: this.totalMoney, method: 'AUTO', index: 'DUPLICATE'}});
            }
        
            var methods = this.Util.Copy(this.availablePaymentMethods);
            methods.push({text: 'Auto', value: 'AUTO'});

            this.modalTips = {
               subtotal: subtotals[0].value,
               subtotals: subtotals,
               amount: '10%', 
               method: subtotals.length ? subtotals[0].value.method : this.availablePaymentMethods[0].value,
               methods: methods
            };
        },
        AddTip()
        {
            if(this.modalTips.method == 'NO_SELECT') return this.Info("Debe seleccionar un método de pago para la propina.")
            if(this.modalTips.method == 'AUTO' && (this.settings.no_payment_select && this.paymentMethods.some(method => method.method == 'NO_SELECT'))) return this.Info("Debe seleccionar un método de pago para el pedido.")

            if(!this.tip) return this.Info('Propina inválida');
            var tips = [];
            if(this.modalTips.subtotal.index == 'DUPLICATE')
            {
                for(var i = 0; i < this.paymentMethods.length; i++)
                {
                    tips.push({
                        method: this.modalTips.method,
                        total: this.tip,
                        index: i
                    });
                }
            }
            else if(this.modalTips.subtotal.index == 'SPLIT')
            {
                var subtotals = this.Util.DivideInteger(this.tip, this.paymentMethods.length);
                for(var i = 0; i < this.paymentMethods.length; i++)
                {
                    tips.push({
                        method: this.modalTips.method,
                        total: subtotals[i],
                        index: i
                    });    
                }
            }
            else
            {
                tips.push({
                    method: this.modalTips.method,
                    total: this.tip,
                    index: this.modalTips.subtotal.index
                });
            }

            tips.forEach(tip => {
                if(tip.method == 'AUTO')
                {
                    if(tip.index === null)
                        tip.method = this.paymentMethods[0].method;
                    else
                        tip.method = this.paymentMethods[tip.index].method;
                }
                if(tip.method == 'CASH' && tip.total > 0) tip.total = Util.RoundPesos(tip.total);
                this.tips.push(tip);
            });

            this.modalTips = null;
        },
        MethodChange(method, index)
        {
            var cash = parseInt(method.cash);
            if(isNaN(cash)) return false;
            
            var total = this.paymentMethods.length == 1 ? this.total.totalMoney-this.discounts.totalDiscount : parseInt(method.subtotal);
            if(isNaN(total)) return false;

            if(this.paymentMethods.length == 1)
                total += this.totalTips;
            else
            {
                total += this.MethodTip(index);
            }

            return cash >= total ? cash - total : false;
        },
        MethodTip(index)
        {
            var totalTip = 0;
            for(var i = 0; i < this.tips.length; i++)
            {
                if(this.tips[i].index === index)
                    totalTip += this.tips[i].total;
            }
            return totalTip > 0 ? totalTip : false;
        },
        CouponDiscountType(coupon)
        {
            if(coupon.discount == 'DELIVERY') return 'Delivery Gratis';
            if(coupon.discount.indexOf('%') > -1) return `${coupon.value}% DCTO ${coupon.discount.indexOf('ITEM') > -1 ? '(en productos)' : '(sobre el total)'}`;
            else if(coupon.discount == 'ITEM_MINUS') return `Productos a ${Util.Price(coupon.value)}`;
            return `${Util.Price(coupon.value)} DCTO ${coupon.discount.indexOf('ITEM') > -1 ? '(en productos)' : '(sobre el total)'}`;
        },
        CouponItemsRule(coupon)
        {
            if(coupon.discount.indexOf('ITEM') == -1)
            {
                if(coupon.items_rule == 'ALL') return 'Si contiene todos los productos';
                else if(coupon.items_rule == 'SOME') return 'Si contiene al menos un producto';
            }
            else
            {
                if(coupon.items_rule == 'ALL') return 'A cada producto';
                if(coupon.items_rule == 'INDEX_1') return 'Al primero de cada producto';
                if(coupon.items_rule == 'INDEX_2') return 'Al segundo de cada producto';
                if(coupon.items_rule == 'INDEX_3') return 'Al tercero de cada producto';
                if(coupon.items_rule == 'INDEX_4') return 'Al cuarto de cada producto';
                if(coupon.items_rule == 'EVERY_2') return 'Cada dos productos iguales';
                if(coupon.items_rule == 'EVERY_3') return 'Cada tres productos iguales';
                if(coupon.items_rule == 'INDEX_1_SELECTION') return 'Al primer producto de los seleccionados';
                if(coupon.items_rule == 'INDEX_2_SELECTION') return 'Al segundo producto de los seleccionados';
                if(coupon.items_rule == 'INDEX_3_SELECTION') return 'Al tercer producto de los seleccionados';
                if(coupon.items_rule == 'INDEX_4_SELECTION') return 'Al cuarto producto de los seleccionados';
                if(coupon.items_rule == 'EVERY_2_SELECTION') return 'Cada dos productos de los seleccionados';
                if(coupon.items_rule == 'EVERY_3_SELECTION') return 'Cada tres productos de los seleccionados';
            }
            return '';
        },

        SelectSearchAddress(event)
        {
            console.log(event);
            if(!event || typeof event == 'string')
            {
                event = {
                    address: event, 
                    lat: null,
                    lon: null
                };
            }
            this.$set(this.customer, 'address', event.title);
            this.$set(this.customer, 'latitude', event.lat);
            this.$set(this.customer, 'longitude', event.lon);
        },

        openModalPya(){
            var data = {
                customer: this.customer
            };
            this.$refs.estimatePya.Open(data);
        },
        ChangeSale(type){
            this.sale = type;
            this.$emit('OnSaleChanged', type);
            
            if(type == 'DELIVERY'){
                this.CheckDeliveryRues(this.total.order)
            }
        },
        ClearFields(field){
            if(this.isAdmin || this.Auth.restrictions.can_edit_name_client){
                this.customer[field] = null;
            }else{
                this.isInputReadonlyName = false;
                this.customer.name = null;
                this.customer.phone = null;
                this.customer.rut = null;
            }
        },
        CheckDeliveryRues(totalOrder) {

            const selected = this.settings.delivery_rules.filter(element => totalOrder >= element.condition);
            this.selectRule = selected[selected.length - 1] || null

            if(this.selectRule != null){
                this.deliveryCost = this.selectRule.cost
                this.activeRule = true
                
            }else{
                if(this.activeRule) this.deliveryCost = null
                this.activeRule = false
            }
        },
        NoLetterAllowed(index){
            let onlyNumbers = this.paymentMethods[index].n_transfer.replace(/\D/g, '');
            this.paymentMethods[index].n_transfer = onlyNumbers
            if (this.$refs[`nTransfer_${index}`]) {
                this.$refs[`nTransfer_${index}`].currentValue = onlyNumbers
            }
        },
        
        EditCustomerPhone(){
            this.Loading();
            axios.patch(`/customer/${this.Local.id}/${this.customer.phone}`, this.customer).then(res => {
                this.Response(res);
                this.modalEditPhone = false;
                this.SelectCustomer(res.data)
                // this.$emit('OnCustomerEdit', res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        RegisterPartialPayment(index){
            if(!this.paymentMethods[index].subtotal || this.paymentMethods[index].subtotal == 0 || this.paymentMethods[index].paid_at) return
            this.$set(this.paymentMethods[index], 'paid_at', Util.Date('now', 'timestamp'))
            this.$set(this.paymentMethods[index], 'paid_by', this.Auth.name)
        },
        DeletePartialPayment(index){
            if(!this.paymentMethods[index].subtotal || this.paymentMethods[index].subtotal == 0 || !this.paymentMethods[index].paid_at) return
            this.$delete(this.paymentMethods[index], 'paid_at')
            this.$delete(this.paymentMethods[index], 'paid_by')
        },
        ToggleBtnNTransfer(index){
            this.$set(this.paymentMethods[index], 'showBtnNTransfer', !this.paymentMethods[index].showBtnNTransfer);
        },
        DictTagDinerName(value){
            if(!this.dictDiners) return
            let diner = this.dictDiners[value]
            return diner
        },
        ChangePriceTempItem(){

            //Checkear si hay algun cambio de precio
            let hasChangeItemPrice = Object.values(this.modalChangePriceItem.items).some(item => item.price !== item.original_price);
            let hasInvalidPrice = Object.values(this.modalChangePriceItem.items).some(item => item.price < 1 || !item.price);

            if(hasChangeItemPrice) this.Info("Precios han sido modificados");
            if(hasInvalidPrice) return this.Info("Precio inválido.")

            Object.values(this.modalChangePriceItem.items).forEach(item => {
                item.total_money = item.quantity * item.price;
                if(item.modifiers.length > 0) {
                    item.modifiers.forEach(modifier => {
                        // Se asigna 0 al precio del modificador para que en la impresión no se sume con el precio del item.
                        modifier.price = 0;
                    });
                }
            });

            //Este evento permite modificar el "value" del componente
            this.$emit('input', this.modalChangePriceItem.items);
            
            this.modalChangePriceItem = null;
        },
        OpenModalChangePriceItem(){
            let copyItems = this.Util.Copy(this.value);

            Object.values(copyItems).forEach(item => {
                // Se agregan las variables cuando no existen los original_price y value_price
                if(!item.original_price) item.original_price = item.price;
                if(!item.value_price) item.value_price = item.price.toString();
            });

            // this.$emit('input', copyItems);
            this.modalChangePriceItem = {items : copyItems };
        },
        SetupScrollListener()
        {
            if(this.observer)
            {
                this.observer.disconnect();
                this.observer = null;   
            }
            if(this.parentScrollEl) this.parentScrollEl.removeEventListener('scroll', this.IsScrolledToBottom);

            this.parentScrollEl = this.Util.GetNearestScrollableParent(this.$el);
            this.IsScrolledToBottom();
            this.hasScroll = this.parentScrollEl.scrollHeight > this.parentScrollEl.clientHeight;
            this.observer = new MutationObserver(() => {
                this.hasScroll = this.parentScrollEl.scrollHeight > this.parentScrollEl.clientHeight;
                this.IsScrolledToBottom();
            });
            this.observer.observe(this.parentScrollEl, {childList: true, subtree: true, characterData: true});
            this.parentScrollEl.addEventListener('scroll', this.IsScrolledToBottom);
        },
        IsScrolledToBottom()
        {
            this.isScrollAtBottom = this.Util.IsScrolledToBottom(this.parentScrollEl);
        },
        CheckIfTransactionCreated(uid, successMessage)
        {
            axios.get(`/transaction/check/${uid}`).then(res => {
                this.Reset();
                if(successMessage)
                {
                    this.Info(successMessage);
                }
                else
                {
                    if(res.data.transaction)
                        this.$emit('TransactionSuccess', res.data.transaction);
                    this.Response(res.data);
                }
            }).catch(err => {
                if(err.message == 'Network Error')
                {
                    setTimeout(() => {
                        this.CheckIfTransactionCreated(uid, successMessage);
                    }, 2000);
                } 
                else
                {
                    console.log(err);
                    this.Response(err);
                }
            });
        },
    },
    computed: {
        orderedItems() {
            var items = Object.values(this.value);

            let deletedItems = "";

            if(this.sale){
                items.forEach(item => {
                    const typeKey = (this.sale == 'DELIVERY') ? 'hide_delivery' : 'hide_takeaway';
                    
                    if (item.combo) {
                        let deleteCombo = false;
                        item.modifiers.forEach(subitem => {
                            if (subitem['section_' + typeKey] || (!subitem['section_' + typeKey] && subitem[typeKey]) || (subitem.anticipation && this.sale == 'SERVE')) deleteCombo = true;
                        });
                        if(item.anticipation && this.sale == 'SERVE') deleteCombo = true;
                        if (deleteCombo) {
                            deletedItems += `<li>${item.title}</li>`;
                            this.$delete(this.value, item.key);
                        }
                    } else {
                        if (item['section_' + typeKey] || (!item['section_' + typeKey] && item[typeKey]) || (item.anticipation && this.sale == 'SERVE')) {
                            deletedItems += `<li>${item.title}</li>`;
                            this.$delete(this.value, item.key);
                        }
                    }
                });
            }

            if(deletedItems != "") this.Info(`<div class='mb10 bold ta-center'>Se quitaron items del pedido no disponibles para ${this.Util.SaleType[this.sale]}</div><ul>${deletedItems}</ul>`);

            return Object.values(this.value).sort((a, b) => {
                return a.timestamp - b.timestamp;
            });
        },
        total() {
            var total = 0;
            var count = 0;
            for(var key in this.value)
            {
                count += this.value[key].quantity;
                total += this.value[key].total_money;
            }

            var delivery = 0;
            if(this.sale == 'DELIVERY' && this.deliveryCost && this.deliveryCost > 0)
                delivery = parseInt(this.deliveryCost);
            
            if(this.sale == 'DELIVERY' && this.settings.delivery_rules && this.settings.delivery_rules.length > 0 && !this.pedidosYa){
                //TODO: no se deberia recalcular cosas en una computada!
                this.CheckDeliveryRues(total);
                delivery = this.deliveryCost;
            }
        
            return {totalMoney: total + delivery, items: count, order: total};
        },
        discounts() {


            var selectedBenefit = {
                coupons: [],
                totalMoney: 0,
                arrItems: [],
                arrDiscounts: [],
                totalDiscount: 0,
                codeUsed: null
            };

            /*
            var benefits = [];
            benefits = benefits.concat(this.baseBenefits);
            if(this.customerAccount && this.customerAccount.coupons)
                benefits = benefits.concat(this.customerAccount.coupons);
            var bestDiscount = 0;
            
            for(var i = 0; i < benefits.length; i++)
            {
                var benefitData = benefits[i].benefit_data ? benefits[i].benefit_data : benefits[i];
                if(this.Util.BenefitConditions(benefitData.conditions, this.customerAccount))
                {
                    var discount = this.Util.BenefitDiscount(this.value, benefitData.config);
                    discount.coupons = benefits[i].id ? [benefits[i].id] : [];
                    if(discount.totalDiscount > bestDiscount)
                    {
                        bestDiscount = discount.totalDiscount;
                        selectedBenefit = discount;
                    }
                }
            }
            */
            
           //Cupon o codigo promocional
           if(this.coupon)
           {

                var onTotal = this.coupon.discount.indexOf('ITEM') == -1;
                var onAllSelectedItems = !onTotal && this.coupon.items_rule.indexOf('_SELECTION') > -1;
                //restricciones de aplicabilidad
                if(  (this.total.order >= this.coupon.minimun) && 
                    (this.coupon.method == 'ANY' || (this.paymentMethods.length == 1 && this.paymentMethods[0].method == this.coupon.method)) &&
                    (this.coupon.sale == 'ANY' || (this.coupon.sale == this.sale))
                 )
                {
                    var amount = 0;
                    var items = [];

                    for(var key in this.value)
                    {
                        var item = this.value[key];
                        if(!item.float_quantity || parseInt(item.quantity) == parseFloat(item.quantity))
                        {
                            for(var i = 0; i < item.quantity; i++)
                                items.push(item);
                        }
                        else
                        {
                            items.push(item);
                        }
                    }
                    
                    items.sort((a, b) => {
                        return onAllSelectedItems ? (b.price - a.price) : (b.id == a.id ? b.price - a.price : a.id - b.id);
                    });

                    //console.log(items);

                    //check si el item esta en el cupon
                    var CheckItemInCoupon = (item) => {
                        var couponItem = null;
                        if(this.coupon.sections && this.coupon.sections[item.section]) couponItem = {include_modifiers: true};
                        else
                        {
                            couponItem = this.coupon.items.find(itemCoupon => {
                                return itemCoupon.id == item.id && itemCoupon.modifiers.every(modCoupon => {
                                    return !!item.modifiers.find(modItem => {
                                        return modItem.group == modCoupon.group && modItem.title == modCoupon.title;
                                    });
                                });
                            });
                        }
                        return couponItem;
                    }

                    //CUPON FRANQUICIA. Las secciones vienen en formarto {id_local}-seccion
                    //Se limpia y solo se deja el nombre de la seccion. Se agrega la seccion que corresponda al local
                    for (let key in this.coupon.sections) {
                        const [number, category] = key.split('-');
                        //Si se envia sin formato id_local-seccion, se rompe el foreach y sigue el resto del codigo                        
                        if(!category) break; 
                        
                        number = number.replace(/[{}]/g, '');
                        console.log(category, number, this.Local.id);
                        if(this.Local.id == number) this.simplifiedData[category] =  this.coupon.sections[key]
                    }
                    //Se asigna las secciones limpias
                    if(Object.keys(this.simplifiedData).length > 0) this.coupon.sections = this.simplifiedData
                         

                    if(onTotal)
                    {
                        var doesApply = true;
                        if((this.coupon.items && this.coupon.items.length) || this.coupon.sections)
                        {
                            if(this.coupon.items_rule == 'ALL')
                            {
                                for(var i = 0; i < items.length; i++)
                                {
                                    var exists = CheckItemInCoupon(items[i]);
                                    if(!exists)
                                    {
                                        doesApply = false;
                                        break;
                                    }
                                }
                            }
                            else if(this.coupon.items_rule == 'SOME')
                            {
                                doesApply = false;
                                for(var i = 0; i < items.length; i++)
                                {
                                    var exists = CheckItemInCoupon(items[i]);
                                    if(exists)
                                    {
                                        doesApply = true;
                                        break;
                                    }
                                }
                            }
                        }

                        if(doesApply)
                        {
                            if(this.coupon.discount == 'DELIVERY' && parseInt(this.deliveryCost) > 0)
                            {
                                amount = parseInt(this.deliveryCost);
                            }
                            else if(this.coupon.discount == '%')
                            {
                                amount = parseInt((this.total.order + (this.coupon.include_delivery ? this.deliveryCost : 0)) * (this.coupon.value / 100));
                            }
                            else if(this.coupon.discount == '$')
                            {
                                amount = Math.min(this.coupon.value, this.coupon.include_delivery ? this.total.order+this.deliveryCost : this.total.order);
                            }
                        }
                    }
                    else
                    {

                        var itemIndex = 0;
                        var lastItem = null;
                        var couponItems = this.coupon.items;

                        for(var i = 0; i < items.length; i++)
                        {
                            var doesApply = true;
                            var item = items[i];

                            //verifica si el item agregado a ORDERFORM tiene restriccion horaria y si su precio esta sobrescrito
                            if(this.coupon.not_apply_discount && item.overwrite){
                                doesApply = false
                            }

                            if(!onAllSelectedItems)
                            {
                                itemIndex++;
                                
                                if(lastItem == null || lastItem.id !== item.id)
                                {
                                    lastItem = item;
                                    itemIndex = 1;
                                }
                            }

                            //check si el item esta en el cupon
                            var couponItem = null;
                            if(this.coupon.sections && this.coupon.sections[item.section]) couponItem = {include_modifiers: true};
                            else
                            {
                                couponItem = this.coupon.items.find(itemCoupon => {
                                    return itemCoupon.id == item.id && itemCoupon.modifiers.every(modCoupon => {
                                        return !!item.modifiers.find(modItem => {
                                            return modItem.group == modCoupon.group && modItem.title == modCoupon.title;
                                        });
                                    });
                                });
                            }
                            
                            if(onAllSelectedItems && couponItem) itemIndex++;

                            if(this.coupon.items_rule.indexOf('INDEX') > -1)
                            {
                                var index = parseInt(this.coupon.items_rule.replace('INDEX_', '').replace('_SELECTION', ''));
                                if(itemIndex != index) doesApply = false;
                            }
                            else if(this.coupon.items_rule.indexOf('EVERY') > -1)
                            {
                                var index = parseInt(this.coupon.items_rule.replace('EVERY_', '').replace('_SELECTION', ''));
                                if(itemIndex % index != 0) doesApply = false;
                            }

                            if(couponItem && doesApply)
                            {
                                let ow_modifiers = [] 
                                item.modifiers.forEach(element => {if(element.overwrite) ow_modifiers.push(element)})
                                
                                var includeModifiers = !!couponItem.include_modifiers;
                                var price = includeModifiers ? item.price : item.item_price;
                                
                                if(this.coupon.not_apply_discount && includeModifiers){
                                    let ow_price = 0
                                    ow_modifiers.forEach(element => {
                                        ow_price += element.price 
                                    });
                                    price = price - ow_price
                                }

                                if(this.coupon.discount == 'ITEM_%')
                                {
                                    amount += parseInt(price * this.coupon.value / 100);
                                }
                                else if(this.coupon.discount == 'ITEM_MINUS')
                                {
                                    amount += this.coupon.value;
                                }
                                else if(this.coupon.discount == 'ITEM_$')
                                {
                                    amount += Math.max(0, price - this.coupon.value);
                                }
                            }
                        }
                    }

                    if(amount > 0)
                    {
                        selectedBenefit.arrDiscounts.push({
                            discount: amount,
                            title: 'Código ' + this.coupon.code,
                            id_coupon_code: this.coupon.id_coupon_code
                        });
                        selectedBenefit.totalDiscount += amount;
                        selectedBenefit.codeUsed = this.coupon.id_coupon_code;
                    }
               }
           }

            if(this.customDiscount)
            {
                var amount = 0;
                if(this.customDiscount.toString().indexOf('%') > -1 || this.customDiscount.toString().toUpperCase().indexOf('X') > -1)
                    amount = this.total.totalMoney * (parseInt(this.customDiscount.toString().toUpperCase().replace('%', '').replace('X', ''))/100);
                else
                    amount = parseInt(this.customDiscount);

                selectedBenefit.arrDiscounts.push({
                    discount: amount,
                    title: 'Dcto ingresado en caja',
                    pos_discount: this.customDiscount
                });
                selectedBenefit.totalDiscount += amount;
            }

            if(this.pedidosYa && this.pedidosYa.discount)
            {
                selectedBenefit.arrDiscounts.push({
                    discount: this.pedidosYa.discount,
                    title: 'Descuento Costo de Reparto',
                });
                selectedBenefit.totalDiscount += this.pedidosYa.discount;
            }

            selectedBenefit.totalDiscount = parseInt(selectedBenefit.totalDiscount);

            if(selectedBenefit.totalDiscount > this.total.totalMoney)
                selectedBenefit.totalDiscount = this.total.totalMoney;

            return selectedBenefit;
        },
        availablePaymentMethods() {
            var ret = [];

             if(this.settings.no_payment_select)
                ret.push({text: `${this.Util.PayMethod('NO_SELECT')}`, value: 'NO_SELECT'});

            if(this.settings.accept_cash || this.missingPaymentMethods['CASH'])
                ret.push({text: `${this.Util.PayMethod('CASH')}`, value: 'CASH'});

            if(this.settings.accepts_bank_deposit || this.missingPaymentMethods['BANK_DEPOSIT'] || this.missingPaymentMethods['Transferencia'])
                ret.push({text: `${this.Util.PayMethod('BANK_DEPOSIT')}`, value: 'BANK_DEPOSIT'});

            if(this.settings.accepts_online_payment || this.missingPaymentMethods['ONLINE'])
                ret.push({text: `${this.Util.PayMethod('ONLINE')}`, value: 'ONLINE'});

            for(var i = 0; i < this.settings.payment_methods.length; i++)
                ret.push({text: `${this.Util.PayMethod(this.settings.payment_methods[i].title)}`, value: this.settings.payment_methods[i].title});

            //missing payment methods
            if(this.missingPaymentMethods)
            {
                for(let key in this.missingPaymentMethods)
                {
                    //if(key == 'Transferencia') key = 'BANK_DEPOSIT';
                    if(!ret.find(el => {return el.value == key})) ret.push({text: key, value: key});
                }
            }

            if(this.settings.payment_methods_order.length == 0){
                return ret
            }

            let auxArrayMethods = []
            auxArrayMethods = ret
            let indexMap = {};
            this.settings.payment_methods_order.forEach((obj, index) => {
                indexMap[obj] = index;
            });

            ret = auxArrayMethods.sort((x, y) => indexMap[x.value] - indexMap[y.value]);

            return ret
        },
        availableDeliveryPaymentMethods() {
            if(this.sale !== 'DELIVERY' || !this.deliveryCost || !this.availablePaymentMethods || !this.availablePaymentMethods.length) return null;
            return this.availablePaymentMethods;
        },
        pendingPaymentMethods() {
            var ret = {'CASH': false};
            for(var i = 0; i < this.settings.payment_methods.length; i++)
                ret[this.settings.payment_methods[i].title] = this.settings.payment_methods[i].pending_payment;
            return ret;
        },
        transaction() {
            var orders = [];
            var discounts = [];
            var payment = [];
            var categories = {};

            /*
            for(var key in this.value)
            {
                var item = this.value[key];
                var itemData = {
                    id: item.id,
                    title: item.title,
                    price: parseInt(item.price),
                    modifiers: item.modifiers,
                    quantity: item.quantity,
                    total_money: parseInt(item.total_money),
                    autocomplete: item.autocomplete,
                    comment: item.comment ? item.comment : null,
                    section: item.section ? item.section : null
                };

                if(item.tag) itemData[item.tag] = true;

                orders.push(itemData);
                for(var categoryID in item.categories)
                    categories[categoryID] = this.categories[categoryID].title;
            }
            */
            this.orderedItems.forEach(item => {
                var itemData = {
                    id: item.id,
                    title: item.title,
                    price: parseInt(item.price),
                    original_price : parseInt(item.original_price),
                    value_price : item.value_price,
                    modifiers: item.modifiers,
                    quantity: item.quantity,
                    total_money: parseInt(item.total_money),
                    autocomplete: item.autocomplete,
                    comment: item.comment ? item.comment : null,
                    section: item.section ? item.section : null,
                    added_at: item.added_at ? item.added_at : null,
                    created_at: item.created_at ? item.created_at : null
                };

                if(item.tag) itemData[item.tag] = true;
                if(item.combo) itemData.combo = true;

                orders.push(itemData);
                for(var categoryID in item.categories)
                    categories[categoryID] = this.categories[categoryID].title;
            });
            
            if(orders.length == 0) return 'No hay items seleccionados';

            //Agregar costo de delivery
            var deliveryCost = this.deliveryCost && this.deliveryCost > 0 ? parseInt(this.deliveryCost) : 0;
            if(this.sale == 'DELIVERY' && deliveryCost > 0)
            {

                var zone = null;
                for(var i = 0; i < this.settings.delivery_costs.length; i++)
                {
                    if(deliveryCost == this.settings.delivery_costs[i].value)
                    {
                        zone = this.settings.delivery_costs[i].title;
                        break;
                    }
                }
                var deliveryOrder = {
                    id: null,
                    title: 'Reparto',
                    price: deliveryCost,
                    modifiers: [],
                    quantity: 1,
                    total_money: deliveryCost,
                    autocomplete: 1,
                    zone: zone,
                    type: 'DELIVERY',
                    method: this.availableDeliveryPaymentMethods ? this.deliveryPaymentMethod : (this.paymentMethods && this.paymentMethods.length ? this.paymentMethods[0].method : null)
                };
                if(this.isExternalDelivery) deliveryOrder.external = true;
                orders.push(deliveryOrder);
            }

            
            //for(var i = 0; i < this.discounts.arrDiscounts.length; i++)
            //    discounts.push({title: this.discounts.arrDiscounts[i].item, discount: this.discounts.arrDiscounts[i].discount});
            discounts = this.discounts.arrDiscounts;
            let paymentMethods = this.Util.Copy(this.paymentMethods);
            if(paymentMethods.length == 1)
            {
                var payment1 = paymentMethods[0];
                payment1.subtotal = this.total.totalMoney-this.discounts.totalDiscount;
                payment = [payment1];
            } 
            else {
                payment = paymentMethods;  
            }
                

            var pendingPayment = false;
            var totalPayment = 0;
            for(var i = 0; i < payment.length; i++)
            {
                if(payment.length > 1 && payment[i].subtotal === 0) return `El subtotal del método de pago ${i+1} debe ser mayor que $0`;

                payment[i].subtotal = parseInt(payment[i].subtotal);
                if(isNaN(payment[i].subtotal) || payment[i].subtotal < 0) return `El subtotal del método de pago ${i+1} debe ser mayor que $0`;
                totalPayment += payment[i].subtotal;

                if(!payment[i].showBtnNTransfer || ['CASH', 'ONLINE'].indexOf(payment[i].method) > -1) delete payment[i]['n_transfer'];
                delete payment[i]['showBtnNTransfer'];

                if(this.pendingPaymentMethods[payment[i].method])
                {
                    if(pendingPayment === true) return 'No es posible seleccionar dos métodos de pago pendientes';   
                    pendingPayment = true;
                }

                if(payment[i].method == 'CASH')
                {
                    if(payment[i].cash)
                    {
                        if(isNaN(payment[i].cash) || (payment[i].cash - payment[i].subtotal < 0))
                            delete payment[i].cash;
                    }
                    else
                        delete payment[i].cash;
                }
                else
                {
                    if(payment[i].cash !== undefined) delete payment[i]['cash'];
                }

            }
            let total = this.total.totalMoney-this.discounts.totalDiscount;
            if(totalPayment != total) 
            {
                let diff = total - totalPayment;
                return (payment.length > 1 ? `La suma de los subtotales de los ${payment.length} métodos de pago` : 'El subtotal')+` debe ser igual a ${this.Util.Price(total)}` + (payment.length > 1 ? ` (${diff < 0 ? 'sobran' : 'faltan'} ${this.Util.Price(Math.abs(diff))})` : '');
            }
                

            //Verificar ley del redondeo
            if(payment.length == 1 && payment[0].method == 'CASH')
            {
                var dec = parseInt(total.toString()[total.toString().length-1]);
                var round = 0;
                if(dec >= 1 && dec <= 5)
                    round = -dec;
                else if(dec >= 6 && dec <= 9)
                    round = 10 - dec;
                
                if(round)
                {
                    orders.push({
                        id: null,
                        title: 'Ley Redondeo',
                        price: round,
                        modifiers: [],
                        quantity: 1,
                        total_money: round,
                        autocomplete: 1,
                        type: 'LEY_REDONDEO',
                    });
                }
            }


            return {
                orders: orders,
                discounts: discounts,
                payment: payment,
                categories: Object.values(categories),
                pending_payment: pendingPayment
            }
        },
        //valor de recargo o descuento por ley del redondeo
        roundTotal()
        {
            if(!this.transaction || typeof this.transaction == 'string' || !this.transaction.orders.length) return 0;
            var orders = this.transaction.orders;
            if(orders[orders.length-1].type === 'LEY_REDONDEO')
            {
                return orders[orders.length-1].price;
            }
            return 0;
        },
        tip() {
            if(!this.modalTips) return null;
            var tip = this.modalTips.amount.toString();
            var total = this.modalTips.subtotal.total;
            let methodPayment = this.modalTips.method;
            if(tip.indexOf('%') > -1 || tip.toUpperCase().indexOf('X') > -1)
            {
                tip = parseFloat(tip.toUpperCase().replace('%', '').replace('X', ''));
                //tip = (this.total.totalMoney - this.discounts.totalDiscount) * (tip / 100);
                tip = total * (tip / 100);
                console.log(methodPayment);
                if(methodPayment == 'CASH') return tip <= 0? null : Util.RoundPesos(tip);
                return tip > 0 ? tip : null;
            }
            else if(tip.indexOf('-') > -1)
            {
                tip = parseInt(tip.replace('-', ''));
                //tip = tip - (this.total.totalMoney - this.discounts.totalDiscount);
                tip = tip - total;
                console.log(methodPayment);
                if(methodPayment == 'CASH') return tip <= 0? null : Util.RoundPesos(tip);
                return tip > 0 ? tip : null;
            }
            else if(!isNaN(tip))
            {
                console.log(methodPayment);
                if(methodPayment == 'CASH') return tip <= 0? null : Util.RoundPesos(tip);
                return parseInt(tip);
            }
            return null;
        },
        totalMoney() {
            return this.total.totalMoney - this.discounts.totalDiscount + this.roundTotal;
        },
        totalTips() {
            var total = 0;
            this.tips.forEach(tip => {
                total += tip.total;
            });
            return total;
        },
        minDate(){
            if(!this.orderedItems) return;

            let maxAnticipationTime = 0;
            let tmpDateStart = null;
            let dateStart = null;
            let anticipation = false;
            let countHaveAnticipation = 0;

            if(Object.values(this.orderedItems).length){
                for (let key in this.orderedItems) {
                    if (this.orderedItems.hasOwnProperty(key)) {
                        const item = this.orderedItems[key];

                        if(item.anticipation){
                            anticipation = true;
                            countHaveAnticipation++;
                        }

                        if (item.anticipation_time > maxAnticipationTime) maxAnticipationTime = item.anticipation_time;
                    }
                }

                const currentTime = Date.now();
                const tmpDateStartTimestamp = currentTime + (maxAnticipationTime * 60 * 60 * 1000);
                tmpDateStart = new Date(tmpDateStartTimestamp);
                dateStart = Math.floor(new Date(tmpDateStart) / 1000);
            }

            return {
                'anticipation': anticipation,
                'dateStart' : dateStart,
                'maxAnticipationTime' : anticipation? maxAnticipationTime : null,
                'dateStartParse' : anticipation && dateStart? this.Util.Date(dateStart, 'DD/MM/YYYY HH:mm') : null
            };
        },
        canEditTips() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_tips);
        },
        canEditOld() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_previous_items);
        },
        canAddDiscount() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.apply_discount);
        },
        canAddCoupon() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.apply_coupon);
        },
        canEditItemPricePOS() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.can_edit_item_price_pos);
        },
        missingPaymentMethods() {
            let ret = {};
            if(!this.fromTransaction) return ret;
            this.fromTransaction.payment.forEach(method => {
                if(method.method !== 'NO_SELECT') ret[method.method] = true;
            });
            return ret;
        },
        totalModalChangePriceItem(){
            if(!this.modalChangePriceItem) return;
            return Object.values(this.modalChangePriceItem.items).reduce((acc, item) => acc + (item.quantity * item.price), 0);
        }
    },
    watch: {
        customerAccount: function(n, o) {
            this.$emit('OnCustomerChanged', n);
        },
        modalEditPhone: function(n,o){
            if(n){
                this.$set(this.customer, 'newPhone',"")
            }else{
                this.$delete(this.customer, 'newPhone')
            }
        },
        schedule: function(n, o) {
            if(n && !o && !this.fromTransaction) 
            {
                this.$nextTick(() => {
                    if(this.$refs.dateSchedule) this.$refs.dateSchedule.OpenCalendar();
                });
            }
        }
    },
    beforeDestroy() {
        if(this.observer)
        {
            this.observer.disconnect();
            this.observer = null;   
        }
        this.parentScrollEl.removeEventListener('scroll', this.IsScrolledToBottom);
    }
}
</script>

<style lang="scss">
.order-item {
    width: 100%;
    display: flex;
    align-items: flex-start; 
}
.order-item:last-child 
{

    border-bottom: none;
}

.order-item > div:first-child {
    width: 40px;
    font-weight: 600;
    flex-shrink: 0;
    /*margin-right: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEE;
    border-radius: 50%;
    */
}
.order-item > div:nth-child(2)
{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}
.order-item > div:nth-child(3) {
    margin-left: 5px;
    font-weight: 500;
}
.method-header {
    font-size: 12px;
    border-top: 1px solid #CCC;
    color: #CCC;
    margin: 5px 0px;
    padding: 5px 0px;
}
.method-header.first {
    margin-top: 0px;
    border-top: none;
    padding-top: 0px;
}
.bottom-actions {
    position: sticky;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    display: block !important;
}
.txt-right input {
    text-align: right;
    padding-right: 10px;
}
.customer-search-box {
    max-height: 300px; 
    background-color: var(--white); 
    position: absolute; 
    top: 56px; 
    left: 0px; 
    right: 0px; 
    border-radius: 5px; 
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1); 
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
.customer-search-item {
    padding: 5px 0px;
    border-bottom: 1px solid whitesmoke;
    cursor: pointer;
}
.customer-search-item:hover {
    background-color: rgba(0,0,0,0.01);
}
.customer-search-item:last-child {
    border-bottom: none;
}

.tag-delivered::before {
    content: "&";
    font-family: 'influye-admin-font';
    font-size: 14px;
    color: rgb(0, 151, 255);
}
.tag-prepared::before {
    content: "T";
    font-family: 'influye-admin-font';
    font-size: 14px;
    color: rgb(255, 187, 0);
}
.tag-order-form {
    right: 3px;
    top: 3px;
    height: 100%;
    border-radius: 50%;
}

.btn-pya {

    padding-left: 12px;
    padding-right: 12px;

    &:hover{
        background-color: var(--color-pya) !important;
        color: var(--white) !important;
    }
    
    i {
        transform: scale(1.4);
    }
}


</style>