<template>
<div class="pa-body">

<div class="opt r">
    <i class="options icon-dots-vertical" @click="Options([
        {text: 'Crear Cupón', function: () => {OpenNewCoupon()}},
        /*
        {text: 'Crear Promoción', function: () => {modalCoupon={
            type: 'PROMO',
            minimun: 0,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true,
            discount: 'ITEM_%',
            items: [],
            items_rule: 'ALL',
            monday_start: '00:00',
            monday_finish: '23:59',
            tuesday_start: '00:00',
            tuesday_finish: '23:59',
            wednesday_start: '00:00',
            wednesday_finish: '23:59',
            thursday_start: '00:00',
            thursday_finish: '23:59',
            friday_start: '00:00',
            friday_finish: '23:59',
            saturday_start: '00:00',
            saturday_finish: '23:59',
            sunday_start: '00:00',
            sunday_finish: '23:59',
        }}}*/
    ])"></i>
</div>


<div v-if="!coupons.length" class="secondary-text ta-center mt10">No hay cupones</div>

<div v-for="(coupon, index) in coupons" :key="index" class="section selectable mb10 flex align-center" @click="GetCouponStats(coupon)">

    <avatar class="mr10"
    :color="CouponColor(coupon)"
    :defaultImageIcon="CouponIcon[coupon.type]">
    </avatar>

    <div class="flex column flex-fill">
        <!--<div class="bold">{{CouponTitle(coupon)}}</div>-->
        <div class="bold">{{CouponTypes[coupon.type]}} <span class="tag ml5" v-if="coupon.franchise">Franquicia</span></div>
        <div class="secondary-text" v-if="!coupon.is_active"><span class="bold cred">Código Desactivado</span></div>
        <div class="secondary-text" v-if="coupon.code"><span class="bold">Código: </span>{{coupon.code}}</div>
        <div class="secondary-text" v-if="coupon.type !== 'PROMOTION_INFINITE'" :class="`${ Util.Number(coupon.totalAvailable) == 0? 'cyellow' : null}`"><span class="bold">Disponibles: </span>{{Util.Number(coupon.totalAvailable)}}/{{Util.Number(coupon.totalCodes)}}</div>
        <div class="secondary-text" v-if="coupon.minimun"><span class="bold">Compra mínima: </span>{{Util.Price(coupon.minimun)}}</div>
        <div class="secondary-text" v-if="coupon.method !== 'ANY'"><span class="bold">Método de pago: </span>{{Util.PayMethod(coupon.method)}}</div>
        <div class="secondary-text" v-if="coupon.sale !== 'ANY'"><span class="bold">Válido para compras: </span>para {{Util.SaleType[coupon.sale]}}</div>
        <div class="secondary-text" v-if="CouponDays(coupon)"><span class="bold">Válido los días: </span>{{CouponDays(coupon)}}</div>
        <div v-if="(coupon.items && coupon.items.length) || (coupon.sections && Object.values(coupon.sections).length)">
            <div class="secondary-text" v-if="coupon.discount.indexOf('ITEM') > -1"><span class="bold">Aplica a: </span> {{productsRulesDescription[coupon.items_rule]}}</div>
            <div class="secondary-text" v-else><span class="bold">Aplica solo si: </span>contiene {{coupon.items_rule == 'ALL' ? 'todos los productos seleccionados' : 'al menos uno de los productos seleccionados'}}</div>
        </div>
    </div>
    <div class="flex ta-right">
        <div class="flex column mr10">
            <div class="bold">{{CouponDiscount(coupon)}}</div>
            <div class="secondary-text" v-if="coupon.discount.indexOf('ITEM') > -1">Productos</div>
            <div class="secondary-text" v-else>Total</div>
        </div>
        <i class="options icon-dots-vertical" v-if="!coupon.franchise" slot="options" @click.stop="Options([
            {text: 'Eliminar Cupón', class: 'error-color', function: () => {DeleteCoupon(coupon)}, confirm: `Se eliminarán todos los códigos de este cupón`},
            {text: 'Eliminar Códigos Disponibles', class: 'error-color', function: () => {modalRemoveCodes= {id: coupon.id, quantity: null, index : index}}, if: coupon.type !== 'PROMOTION_INFINITE'},
            {text: 'Editar Cupón', function: () => {OpenEditCouponList(coupon)}},
            {text: 'Descargar Códigos', function: () => {DownloadCodes(coupon)}, if: coupon.type == 'COUPON'},
            {text: 'Deshabilitar', function: () => {EnableCoupon(coupon, false)}, if: !!coupon.is_active},
            {text: 'Habilitar', function: () => {EnableCoupon(coupon, true)}, if: !coupon.is_active},
            {text: 'Agregar Códigos', function: () => { modalAddCodes = {id: coupon.id, quantity: 1, index : index}}, if: coupon.type !== 'PROMOTION_INFINITE'},
        ])"></i>
        
    </div>
</div>

<modal v-model="modalCoupon" dir="right" :modalClass="['fill', 'md right h100']" :title="modalCoupon ? (modalCoupon.id ? (`Editar ${CouponTypes[modalCoupon.type]}`) : 'Nuevo Cupón/Promoción') : ''">
    <div v-if="modalCoupon" class="section">

        <!--<div class="bold">{{modalCoupon.type !== 'PROMO' ? 'Cupón' : 'Promoción'}}</div>-->
        <input-select v-if="!modalCoupon.id && modalCoupon.type !== 'PROMO'" class="mb15"
        label="Tipo" 
        :items="[{text: 'Cupón', value: 'COUPON'}, {text: 'Código Promocional Ilimitado', value: 'PROMOTION_INFINITE'}, {text: 'Código Promocional Limitado', value: 'PROMOTION'}]"
        v-model="modalCoupon.type">
        </input-select>
        
        <input-field  class="mb15"
        v-if="!modalCoupon.id && modalCoupon.type.indexOf('PROMOTION') > -1 && modalCoupon.type !== 'PROMO'"
        v-model="modalCoupon.code"
        label="Código">
        </input-field>

        <input-field v-show="modalCoupon.type !== 'PROMOTION_INFINITE' && modalCoupon.type !== 'PROMO'" class="mb15"
        v-model="modalCoupon.quantity"
        v-if="!modalCoupon.id"
        :label="modalCoupon.type == 'PROMOTION' ? `Capacidad` : 'Cantidad'"
        :description="modalCoupon.type == 'PROMOTION' ? 'Cantidad de veces que se puede utilizar el código (máximo 1.000)' : 'Cantidad de cupones que se van a generar (máximo 1.000)'"
        type="int+">
        </input-field>

        <div :class="`hdvm ${desktop ? 'align-end' : ''}`">
            <input-select 
            v-model="modalCoupon.discount"
            label="Tipo"
            :items="discountsTypes">
            </input-select>
            <input-field
            v-if="modalCoupon.discount && modalCoupon.discount !== 'DELIVERY'"
            v-model="modalCoupon.value"
            :suffix="modalCoupon.discount.indexOf('%') > -1 ? '%' : '$'"
            type="float+">
            </input-field>
        </div>
        <label class="mt10" v-if="modalCoupon.discount == '$' || modalCoupon.discount == '%'"><input type="checkbox" v-model="modalCoupon.include_delivery">Incluir el costo de reparto <div class="checkmark"></div></label>

        <div class="flex align-center mb20 mt20">
            <div class="f18 flex-fill">Productos</div>
            <i class="options icon-check-list" v-tooltip="'Secciones'" @click="OpenSections()"></i>
            <i class="options icon-plus ml5" @click="OpenItems()"></i>
        </div>
        <input-select v-if="modalCoupon.discount && modalCoupon.discount.indexOf('ITEM') > -1" 
        :items="productsRules" v-model="modalCoupon.items_rule" 
        label="Aplicar"></input-select>
        <input-select v-else :items="[
            {text: 'Si contiene todos los productos', value: 'ALL'},
            {text: 'Si contiene al menos 1 de los producto', value: 'SOME'}
        ]"
        v-model="modalCoupon.items_rule" label="Condiciones"></input-select>
        <label class="mt10" v-if="modalCoupon.discount == 'ITEM_$' || modalCoupon.discount == 'ITEM_%' || modalCoupon.discount == 'ITEM_MINUS'"><input type="checkbox" v-model="modalCoupon.not_apply_discount">No aplicar descuento con horario <div class="checkmark"></div></label>
        
        <div class="mt20" v-if="modalCoupon.items.length || Util.Arr(modalCoupon.sections).length">
            <div class="flex" v-if="Util.Arr(modalCoupon.sections).length">
                <div v-if="Local.type == 'LOCAL'" class="well mr5 mb10" v-for="(value, section) in modalCoupon.sections" :key="section.title">{{section}} <i @click="$delete(modalCoupon.sections, section)" class="icon icon-trash selectable ml5"></i></div>
                <div v-if="Local.type == 'FRANCHISE'" class="well mr5 mb10" v-for="(value, section) in SublocalsSections" :key="section.title">{{section}} <i @click="$delete(modalCoupon.sections, `{${value}}-${section}`)" class="icon icon-trash selectable ml5"></i></div>
            </div>
            
            <div class="well mb10 flex" v-for="(item, index) in modalCoupon.items" :key="index">
                <div class="flex column flex-fill">
                    <div class="bold">{{item.title}}</div>
                    <div class="secondary-text" v-for="(mod, modIndex) in item.modifiers" :key="`item-${index}-mod-${modIndex}`">{{mod.group}} - {{mod.title}}</div>
                    <label class="secondary-text mt5" v-show="item.modifiers.length">
                        <input type="checkbox" v-model="item.include_modifiers"> Incluir precio de modificadores
                        <div class="checkmark sm"></div>
                    </label>
                </div>
                
                <i class="icon icon-trash selectable" @click="modalCoupon.items.splice(index, 1)"></i>
            </div>
        </div>
        <div class="well mt10 secondary-text" v-else-if="modalCoupon.discount && modalCoupon.discount.indexOf('ITEM') == -1">No hay productos seleccionados por lo que siempre se aplicará el descuento sin importar el pedido</div>
        <div class="well mt10 secondary-text" v-else>No hay productos seleccionados</div>
        
        <div class="f18 mb20 mt20">Restricciones</div>
        
        <input-field class="mb15"
        v-model="modalCoupon.minimun"
        label="Compra mínima"
        prefix="$ "
        type="int+">
        </input-field>

        <input-select class="mb15"
        :items="methods"
        v-model="modalCoupon.method"
        label="Método de Pago">
        </input-select>

        <input-select class="mb15"
        v-if="modalCoupon.discount !== 'DELIVERY'"
        v-model="modalCoupon.sale"
        :items="[{text: 'Cualquier tipo de compra', value: 'ANY'}, {text: 'Compras Delivery', value: 'DELIVERY'}, {text: 'Compras Retiro en Local', value: 'TAKEAWAY'}, {text: 'Compras para Servir', value: 'SERVE'}]"
        label="Tipo de compra">
        </input-select>
        
        <div class="bold">Días</div>
        <div class="secondary-text mb20">Determina los días y horas que se puede utilizar el cupón</div>
        <div>
            <div v-for="i in 7" :key="i" :class="`entry flex ${desktop ? 'align-center' : 'column'}`">
                <label class="flex-fill"><input type="checkbox" v-model="modalCoupon[Util.DaysArr[i-1]]">{{Util.DaysArrEs[i-1]}} <div class="checkmark"></div></label>
                <div class="flex" v-show="modalCoupon[Util.DaysArr[i-1]]">
                    <input-date :inputClass="`secondary ${desktop ? 'sm' : 'mt5'}`" type="TIME" v-model="modalCoupon[Util.DaysArr[i-1]+'_start']" format="HH:mm"></input-date>
                    <input-date :inputClass="`secondary ${desktop ? 'sm' : 'mt5'}`" class="ml10" type="TIME" v-model="modalCoupon[Util.DaysArr[i-1]+'_finish']" format="HH:mm"></input-date>
                </div>
            </div>
        </div>

        <!--<div class="secondary-text ta-center mt10">{{modalCoupon.discount && modalCoupon.discount.indexOf('ITEM') > -1 ? 'Se aplicará a todos los productos' : 'Sin restricciones de productos'}}</div>-->
        
            
        
    </div>
    
    <button v-if="modalCoupon && modalCoupon.id" slot="actions" class="primary purple" @click="EditCoupon()">Editar</button>
    <button v-else slot="actions" class="primary purple" @click="SaveCoupon()">Guardar</button>
    
</modal>

<modal v-model="modalCouponItems" :dir="['right', 'right']" :modalClass="['fill', 'md right h100']" title="Menú" hideActions>
    <input-select
        v-if="Local.type == 'FRANCHISE'"
        slot="options"
        inputClass="icon primary"
        @OnValueChanged="selectLocalMenu"
        :items="franchiseLocals"
        v-model="local"
    > </input-select>
   
    <items-selection
    :menu="menu"
    :selectModifiers="true"
    callbackOnly
    :showSelected="false"
    :modifiersValidation="false"
    @OnItemAdded="ItemSelected"
    bodyClass="pl20 pr20 bgbg1"
    hideComment
    :multiSelection="false">
    </items-selection>
</modal>

<modal v-model="modalSections" :dir="['right', 'right']" :modalClass="['fill', 'sm right h100']" title="Secciones">
    <input-select
        v-if="Local.type == 'FRANCHISE'"
        slot="options"
        inputClass="icon primary"
        @OnValueChanged="selectLocalMenu"
        :items="franchiseLocals"
        v-model="local"
    > </input-select>
    <div v-if="modalSections" class="pa20">
        <div class="mb10" v-for="section in menu" :key="section.title">
            <label><input v-if="Local.type == 'FRANCHISE'" type="checkbox" v-model="modalSections[`{${local}}-${section.title}`]"><input v-else type="checkbox" v-model="modalSections[section.title]">{{section.title}} <div class="checkmark"></div></label>
        </div>
    </div>
    <button class="primary purple" slot="actions" @click="SaveSections()">Aceptar</button>
</modal>

<modal v-model="modalCodes" dir="right" :modalClass="['fill', 'md right h100']" :title="modalCodes ? CouponTypes[modalCodes.type] : ''" hideActions>
    <i class="options icon-dots-vertical" v-if="modalCodes && modalCodes.franchise ? false : true " slot="options" @click="Options([
            {text: 'Eliminar Cupón', class: 'error-color', function: () => {DeleteCoupon(modalCodes)}, confirm: `Se eliminarán todos los códigos de este cupón`},
            {text: 'Eliminar Códigos Disponibles', class: 'error-color', function: () => {modalRemoveCodes=modalCodes}, if: modalCodes.type !== 'PROMOTION_INFINITE'},
            {text: 'Editar Cupón', function: () => {OpenEditCoupon()}},
            {text: 'Descargar Códigos', function: () => {DownloadCodes()}, if: modalCodes.type == 'COUPON'},
            {text: 'Deshabilitar', function: () => {EnableCoupon(modalCodes, false)}, if: !!modalCodes.is_active},
            {text: 'Habilitar', function: () => {EnableCoupon(modalCodes, true)}, if: !modalCodes.is_active},
            {text: 'Agregar Códigos', function: () => {modalAddCodes = {id: modalCodes.id, quantity: 1}}, if: modalCodes.type !== 'PROMOTION_INFINITE'},
        ])"></i>

    <div class="pa20" v-if="modalCodes">

        <div class="bold mb15">{{CouponTitle(modalCodes)}}</div>
        <div v-if="modalCodes.type != 'COUPON'" class="mb15">
            <div class="bold">Código</div>
            <div>{{modalCodes.code}}</div>
        </div>
        <div v-if="modalCodes.type != 'PROMOTION_INFINITE'" class="mb15">
            <div class="bold">Disponibles</div>
            <div>{{Util.Number(modalCodes.totalAvailable)}}/{{Util.Number(modalCodes.totalCodes)}}</div>
        </div>
        <div v-if="modalCodes.minimun" class="mb15">
            <div class="bold">Compra mínima</div>
            <div>{{Util.Price(modalCodes.minimun)}}</div>
        </div>
        <div v-if="modalCodes.method !== 'ANY'" class="mb15">
            <div class="bold">Método de pago</div>
            <div>{{Util.PayMethod(modalCodes.method)}}</div>
        </div>
        <div v-if="modalCodes.sale !== 'ANY'" class="mb15">
            <div class="bold">Tipo de pedido</div>
            <div>para {{Util.SaleType[modalCodes.sale]}}</div>
        </div>
        <div v-if="CouponDays(modalCodes)" class="mb15">
            <div class="bold">Días válidos</div>
            <div>{{CouponDays(modalCodes)}}</div>
        </div>
        <div v-if="modalCodes.items && modalCodes.items.length">
            <div v-if="modalCodes.discount.indexOf('ITEM') > -1">
                <div class="bold">Aplica a</div> 
                <div>{{productsRulesDescription[modalCodes.items_rule]}} {{ modalCodes.not_apply_discount ? '(sujeto a restricción horaria)' : null }}</div>
            </div>
            <div v-else>
                <div class="bold">Aplica solo si</div>
                <div>contiene {{modalCodes.items_rule == 'ALL' ? 'todos los productos seleccionados' : 'al menos uno de los productos seleccionados'}}</div>
            </div>
        </div>
    
        <div v-if="(modalCodes.items && modalCodes.items.length) || modalCodes.sections">

            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Productos del Cupón</div>
            
            <div v-if="modalCodes.sections">
                <div v-if="Local.type == 'FRANCHISE' || modalCodes.franchise" class="well mb10" v-for="(val, section) in SublocalsSections" :key="section">
                    <div class="bold">Productos de la sección {{section}}</div>
                </div>
                <div v-if="Local.type == 'LOCAL' && !modalCodes.franchise" class="well mb10" v-for="(val, section) in modalCodes.sections" :key="section">
                    <div class="bold">Productos de la sección {{section}}</div>
                </div>
            </div>

            <div class="well mb10" v-for="(item, index) in modalCodes.items" :key="index">
                <div class="bold">{{item.title}}</div>
                <div v-for="(modifier, modIndex) in item.modifiers" :key="`item-${index}-mod-${modIndex}`" class="secondary-text">{{modifier.group}} - {{modifier.title}}</div>
                <div v-if="item.include_modifiers && item.modifiers.length" class="mt5 secondary-text"><i class="icon icon-check cpurple"></i> Se incluye el precio de los modificadores en el descuento</div>
            </div>
        </div>

        <div v-if="modalCodes.type == 'COUPON'">
            <div class="separator mb20 mt20"></div>
            <div class="f18 mb20 flex align-center">
                <div class="flex-fill flex">Códigos Disponibles <span class="counter">{{modalCodes.codes.length}}</span></div>
                <i @click="CopyAllCodes()" class="options icon icon-copy" v-tooltip="'Copiar todos los códigos'"></i>
            </div>
            <div class="well ofy" style="max-height: 200px">
                <div v-for="(code, index) in modalCodes.codes" :key="index" class="entry flex selectable"
                @click="Util.Clipboard(code)">
                    <div class="flex-fill bold">{{code}}</div> 
                    <i class="icon icon-copy c4"></i>
                </div>
            </div>
            
        </div>
        <div>
            <div class="separator mb20 mt20"></div>
            <div class="f18 mb20">Transacciones (códigos utilizados)</div>
            <table class="summary mt10">
                <tr>
                    <th>N°</th>
                    <th>Completada</th>
                    <th>Total</th>
                    <th>Descuento</th>
                    <th></th>
                </tr>
                <tr v-for="(transaction, index) in modalCodes.transactions" :key="index" :class="`selectable`"
                @click="$refs.transactionDetails.Open(transaction.id)">
                    <td>{{Util.Zeropad(transaction.id_ticket, 10)}}</td>
                    <td>{{Util.Date(transaction.completed_at, 'DD/MM/YYYY HH:mm')}}</td>
                    <td>{{Util.Price(transaction.total_money)}}</td>
                    <td>{{Util.Price(transaction.total_discount)}}</td>
                    <td style="text-align: right">
                        <i class="icon icon-arrow-right c4"></i>
                    </td>
                </tr>
            </table>
            <div class="secondary-text ta-center mt10" v-if="!modalCodes.transactions.length">No hay transacciones</div>
        </div>
    </div>
</modal>

<modal v-model="modalAddCodes" dir="center" modalClass="sm" title="Agregar Códigos">
    <div class="section" v-if="modalAddCodes">
        <input-field
        label="Cantidad"
        description="Máximo 1.000 códigos"
        v-model="modalAddCodes.quantity"
        type="int+">
        </input-field>
    </div>
    
    <button slot="actions" class="primary purple" @click="AddCodes()">Agregar</button>
    
</modal>

<modal v-model="modalRemoveCodes" dir="center" modalClass="sm" title="Eliminar Códigos Disponibles">
    <div class="section" v-if="modalRemoveCodes">
        <input-field
        label="Cantidad"
        v-model="modalRemoveCodes.quantity"
        type="int+">
        </input-field>
    </div>
    
    <button slot="actions" class="primary error-color" @click="RemoveCodes()">Eliminar</button>
    
</modal>

<transaction-details ref="transactionDetails"></transaction-details>

</div>
</template>
<script>
export default {
    data() {
        return {
            coupons: [],
            methods: [{text: 'Cualquier método de pago', value: 'ANY'}],
            modalCoupon: null,
            modalCodes: null,
            discountsTypes: [
                {text: 'Porcentaje de Descuento', value: '%'},
                {text: 'Descuento fijo', value: '$'},
                {text: 'Delivery Gratis', value: 'DELIVERY'},
                {text: 'Productos con Porcentaje de Descuento', value: 'ITEM_%'},
                {text: 'Productos con Descuento Fijo', value: 'ITEM_MINUS'},
                {text: 'Productos con Precio Fijo', value: 'ITEM_$'},
            ],
            productsRules: [
                {text: 'A cada producto', value: 'ALL'},
                {text: 'Al primero de cada producto', value: 'INDEX_1'},
                {text: 'Al segundo de cada producto', value: 'INDEX_2'},
                {text: 'Al tercero de cada producto', value: 'INDEX_3'},
                {text: 'Al cuarto de cada producto', value: 'INDEX_4'},
                {text: 'Cada 2 productos iguales', value: 'EVERY_2'},
                {text: 'Cada 3 productos iguales', value: 'EVERY_3'},
                {text: 'Al primer producto de los seleccionados', value: 'INDEX_1_SELECTION'},
                {text: 'Al segundo producto de los seleccionados', value: 'INDEX_2_SELECTION'},
                {text: 'Al tercer producto de los seleccionados', value: 'INDEX_3_SELECTION'},
                {text: 'Al cuarto producto de los seleccionados', value: 'INDEX_4_SELECTION'},
                {text: 'Cada 2 productos de los seleccionados', value: 'EVERY_2_SELECTION'},
                {text: 'Cada 3 productos de los seleccionados', value: 'EVERY_3_SELECTION'},
            ],
            productsRulesDescription: {
                ALL: 'cada producto',
                INDEX_1: 'primero de cada producto',
                INDEX_2: 'segundo de cada producto',
                INDEX_3: 'tercero de cada producto',
                INDEX_4: 'cuarto de cada producto',
                EVERY_2: 'cada 2 productos iguales',
                EVERY_3: 'cada 3 productos iguales',
                INDEX_1_SELECTION: 'primer producto de los seleccionados',
                INDEX_2_SELECTION: 'segundo producto de los seleccionados',
                INDEX_3_SELECTION: 'tercer producto de los seleccionados',
                INDEX_4_SELECTION: 'cuarto producto de los seleccionados',
                EVERY_2_SELECTION: 'cada 2 productos de los seleccionados',
                EVERY_3_SELECTION: 'cada 3 productos de los seleccionados'
            },
            modalAddCodes: null,
            modalRemoveCodes: null,
            menu: null,
            modalCouponItems: false,
            CouponTypes: {
                COUPON: 'Cupón',
                PROMOTION_INFINITE: 'Código Promocional Ilimitado', 
                PROMOTION: 'Código Promocional'
            },
            CouponIcon: {
                COUPON: 1274,
                PROMOTION_INFINITE: 585, 
                PROMOTION: 104
            },
            modalSections: null,
            locals : [],
            local : null,
            menuSublocals : null,
            indexCoupon : null
        }
    },
    mounted() {
        this.Loading();
        axios.get(`/coupons/${this.Local.id}`).then(res => {
            console.log(res.data);
            this.coupons = res.data.coupons;
            res.data.methods.forEach(method => {
                this.methods.push({text: this.Util.PayMethod(method), value: method});
            });
            this.Response(res);
        }).catch(err => {
            this.Response(err);
        });
    },
    methods: {
        CouponTitle(coupon) {
            /*
            var ret = coupon.type == 'COUPON' ? 'Cupón ' : (coupon.type == 'PROMO' ? 'Promoción ' : 'Código Promocional ');
            if(coupon.type == 'PROMOTION_INFINITE') ret += ' (ilimitado) ';
            if(coupon.discount.indexOf('%') > -1) ret += `${coupon.value}% DCTO`;
            else if(coupon.discount.indexOf('$') > -1 || coupon.discount.indexOf('MINUS') > -1) ret += `${Util.Price(coupon.value)} DCTO`;
            else if(coupon.discount == 'DELIVERY') ret += 'Delivery Gratis';
            if(coupon.discount.indexOf('ITEM') > -1) ret += ' (Aplicado a productos)';
            return ret;
            */
            var onTotal = coupon.discount.indexOf('ITEM') == -1;
            if(coupon.discount == '%') return `${coupon.value}% DCTO ${onTotal ? 'sobre el total' : 'a productos seleccionados'}`;
            else if(coupon.discount == '$') return `${Util.Price(coupon.value)} DCTO  ${onTotal ? 'sobre el total' : 'a productos seleccionados'}`;
            else if(coupon.discount == 'DELIVERY') return 'Delivery Gratis';
            else if(coupon.discount == 'ITEM_%') return `Productos seleccionados con ${coupon.value}% DCTO`;
            else if(coupon.discount == 'ITEM_MINUS') return `Productos seleccionados con ${Util.Price(coupon.value)} DCTO`;
            else if(coupon.discount == 'ITEM_$') return `Productos seleccionados a ${Util.Price(coupon.value)}`;
        },
        CouponDiscount(coupon) {
            if(coupon.discount == '%') return `${coupon.value}% DCTO`;
            else if(coupon.discount == '$') return `${Util.Price(coupon.value)} DCTO`;
            else if(coupon.discount == 'DELIVERY') return 'Delivery Gratis';
            else if(coupon.discount == 'ITEM_%') return `${coupon.value}% DCTO`;
            else if(coupon.discount == 'ITEM_MINUS') return `${Util.Price(coupon.value)} DCTO`;
            else if(coupon.discount == 'ITEM_$') return `Precio ${Util.Price(coupon.value)}`;
        },
        CouponDays(coupon) {
            var daysCount = 0;
            var validDays = '';
            var customTime = false;
            for(var i = 0; i < 7; i++)
            {
                var day = this.Util.DaysArr[i];
                if(coupon[day])
                {
                    daysCount++;
                    var time = coupon[`${day}_start`] !== '00:00' || coupon[`${day}_finish`] !== '23:59' ? ` (${coupon[`${day}_start`]}-${coupon[`${day}_finish`]})` : '';
                    if(time) customTime = true;
                    validDays += `, ${this.Util.DaysArrEs[i]}${time}`;
                }
            }
            if(daysCount < 7 || customTime)
                return validDays.substring(2);
            else
                return '';
        },
        GetCouponStats(coupon)
        {
            this.Loading();
            axios.get(`/coupon/${coupon.id}/stats${this.Local.type == 'LOCAL' ? '/'+this.Local.id : ''}`).then(res => {
                this.Response(res);
                coupon.codes = res.data.codes;
                coupon.transactions = res.data.transactions;
                this.modalCodes = coupon;
                if(this.Local.type == 'LOCAL'){
                    this.modalCodes.items = this.modalCodes.items.filter(element => element.id_local == this.Local.id)
                    const simplifiedData = {};
                    for (let key in this.modalCodes.sections) {
                        const [number, category] = key.split('-');
                       if(!category) break;
                        if(number == this.Local.id){
                            simplifiedData[category] = this.modalCodes.sections[key]
                        }
                    }
                    if(Object.keys(simplifiedData).length > 0) this.modalCodes.sections = simplifiedData
                } 
                
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveCoupon() {
            if(this.modalCoupon.type !== 'PROMO' && this.modalCoupon.type !== 'PROMOTION_INFINITE' && (!this.modalCoupon.quantity || this.modalCoupon.quantity < 0)) return this.Info('Cantidad inválida');
            if((this.modalCoupon.discount == '$' || this.modalCoupon.discount == '%' || this.modalCoupon.discount == 'ITEM_$' || this.modalCoupon.discount == 'ITEM_MINUS' || this.modalCoupon.discount == 'ITEM_%') && (this.modalCoupon.value < 0 || !this.modalCoupon.value)) return this.Info("Debe ingresar valor de descuento")
            if(this.modalCoupon.type !== 'PROMO' && this.modalCoupon.type.indexOf('PROMOTION') > -1 )
            {
                if(!this.modalCoupon.code) return this.Info('Código inválido');
                if(this.modalCoupon.code.length < 5) return this.Info('Código debe tener al menos 5 caracteres');
                this.modalCoupon.code = this.modalCoupon.code.toUpperCase();
                //if(!(/[A-Z0-9]+/g.test(this.modalCoupon.code))) return this.Info('Código solo puede contener letras y números');
            }
            if( (this.modalCoupon.discount !== 'DELIVERY' && this.modalCoupon.discount == 'ITEM_$') && (!this.modalCoupon.value || this.modalCoupon.value <= 0)) return this.Info('Valor descuento inválido')
            
            
            this.Loading();
            axios.put(`/coupon/${this.Local.id}`, this.modalCoupon).then(res => {
                this.Response(res);
                var coupons = this.coupons;
                coupons.push(res.data);
                this.coupons = [];
                this.$nextTick(() => {
                    this.coupons = coupons;
                });
                this.modalCoupon = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        EditCoupon() {
            if((this.modalCoupon.discount == '$' || this.modalCoupon.discount == '%' || this.modalCoupon.discount == 'ITEM_$' || this.modalCoupon.discount == 'ITEM_MINUS' || this.modalCoupon.discount == 'ITEM_%') && (this.modalCoupon.value < 0 || !this.modalCoupon.value)) return this.Info("Debe ingresar valor de descuento")
            if(this.modalCoupon.discount == '$' || this.modalCoupon.discount == '%' || this.modalCoupon.discount == 'DELIVERY') this.modalCoupon.not_apply_discount = false
            this.Loading();
            axios.post(`/coupon/${this.modalCoupon.id}`, this.modalCoupon).then(res => {
                this.Response(res);
                var coupons = this.coupons;
                for(var i = 0; i < coupons.length; i++)
                {
                    if(coupons[i].id == res.data.id) coupons[i] = res.data;
                }
                this.coupons = [];
                this.$nextTick(() => {
                    this.coupons = coupons;
                });
                this.modalCoupon = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteCode(code, index)
        {
            this.Loading();
            axios.delete(`/coupon/code/${code.id}`).then(res => {
                this.Response(res);
                this.modalCodes.codes.splice(index, 1);
                this.$forceUpdate();
            }).catch(err => {
                this.Response(err);
            });
        },
        AddCodes()
        {
            this.Loading();
            axios.post(`/coupon/codes/${this.modalAddCodes.id}`, this.modalAddCodes).then(res => {
                this.Info(`Se agregaron  ${res.data.added} códigos`);
                if(!this.modalCodes){
                    this.coupons[this.modalAddCodes.index].totalAvailable = res.data.available
                    this.coupons[this.modalAddCodes.index].totalCodes = res.data.total
                }else{
                    this.$set(this.modalCodes, 'totalAvailable', res.data.available);
                    this.$set(this.modalCodes, 'totalCodes', res.data.total);
                    this.$set(this.modalCodes, 'codes', this.modalCodes.codes.concat(res.data.codes))
                }
                this.modalAddCodes = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        RemoveCodes()
        {
            this.Loading();
            axios.delete(`/coupon/codes/${this.modalRemoveCodes.id}/${this.modalRemoveCodes.quantity}`).then(res => {
                this.Info(`Se eliminaron ${res.data.deleted} códigos disponibles`);
                //this.modalCodes = null;
                if(!this.modalCodes){
                    this.coupons[this.modalRemoveCodes.index].totalAvailable = res.data.available
                    this.coupons[this.modalRemoveCodes.index].totalCodes = res.data.total
                }else{
                    this.$set(this.modalCodes, 'totalAvailable', res.data.available);
                    this.$set(this.modalCodes, 'totalCodes', res.data.total);
                    this.$set(this.modalCodes, 'codes', res.data.codes)
                }
                this.modalRemoveCodes = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteCoupon(coupon)
        {
            this.Loading();
            axios.delete(`/coupon/${coupon.id}`).then(res => {
                this.Response(res);
                for(var i = 0; i < this.coupons.length; i++)
                {
                    if(this.coupons[i].id == coupon.id)
                    {
                        this.coupons.splice(i, 1);
                        break;
                    }
                }
                this.modalCodes = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        LocalMenu(){
            this.Loading();
            axios.get(`/menu/${this.local}/complete`).then(res => {
                this.Response(res);
                this.menu = res.data;
                this.modalCouponItems = true;
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenItems()
        {   
            if(!this.menu)
            {
                this.Loading();
                axios.get(`/menu/${this.Local.id}/complete`).then(res => {
                    this.Response(res);
                    this.menu = res.data;
                    this.modalCouponItems = true;
                }).catch(err => {
                    this.Response(err);
                });
            }
            else
            {
                this.modalCouponItems = true;
            }
        },
        OpenSections()
        {
            if(!this.menu)
            {
                this.Loading();
                axios.get(`/menu/${this.Local.id}/complete`).then(res => {
                    this.Response(res);
                    this.menu = res.data;
                    this.modalSections = this.Util.Copy(this.modalCoupon.sections);
                }).catch(err => {
                    this.Response(err);
                });
            }
            else
            {
                this.modalSections = this.Util.Copy(this.modalCoupon.sections);
            }
        },
        SaveSections() {
            var ret = {};
            for(var key in this.modalSections)
            {
                if(this.modalSections[key]) ret[key] = true;
            }
            this.$set(this.modalCoupon, 'sections', ret);
            this.modalSections = null;
        },
        ItemSelected(item)
        {

            this.modalCouponItems = false;
            var entry = {
                id: item.id,
                title: item.title,
                modifiers: item.modifiers,
                include_modifiers: true,
                id_local : item.id_local
            };
            if(this.modalCoupon.items)
                this.modalCoupon.items.push(entry);
            else
                this.$set(this.modalCoupon, 'items', [entry]);
        },
        CouponColor(coupon)
        {
            if(!coupon.is_active) return this.Util.baseColors.yellow;
            return this.Util.baseColors.green;
        },
        EnableCoupon(coupon, state)
        {
            this.Loading();
            axios.patch(`/coupon/enable/${coupon.id}`, {state: state}).then(res => {
                this.$set(coupon, 'is_active', res.data.is_active);
                this.Info(res.data.is_active ? 'Promoción activada' : 'Promoción desactivada');
            }).catch(err => {
                this.Response(err);
            })
        },
        DownloadCodes(coupon=null)
        {
            if(coupon) this.modalCodes = coupon
            this.Loading();
            axios.get(`/coupon/${this.modalCodes.id}/export`, {responseType: 'blob'}).then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `cupon_${Util.Today().date.replace(/\//g, '-')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        CopyAllCodes() {
            var str = '';
            this.modalCodes.codes.forEach(code => {
                str += (str ? '\n' : '') + code;
            });
            this.Util.Clipboard(str);
        },
        OpenEditCoupon() {
            console.log(this.modalCodes);
            var copy = this.Util.Copy(this.modalCodes);
            if(!copy.sections) copy.sections = {};
            this.modalCoupon = copy;
            this.modalCodes = null;
        },
        OpenNewCoupon(){
            this.modalCoupon={
                type: 'COUPON',
                minimun: 0,
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
                items: [],
                items_rule: 'ALL',
                monday_start: '00:00',
                monday_finish: '23:59',
                tuesday_start: '00:00',
                tuesday_finish: '23:59',
                wednesday_start: '00:00',
                wednesday_finish: '23:59',
                thursday_start: '00:00',
                thursday_finish: '23:59',
                friday_start: '00:00',
                friday_finish: '23:59',
                saturday_start: '00:00',
                saturday_finish: '23:59',
                sunday_start: '00:00',
                sunday_finish: '23:59',
                sections: {}
            }
            
            if(this.Local.type == 'FRANCHISE' && this.menuSublocals === null) this.FranchiseMenuSublocals()
        },
        OpenEditCouponList(coupon){
            this.Loading();
            axios.get(`/coupon/${coupon.id}/stats${this.Local.type == 'LOCAL' ? '/'+this.Local.id : ''}`).then(res => {
                this.Response(res);
                coupon.codes = res.data.codes;
                coupon.transactions = res.data.transactions;
                this.modalCodes = coupon;
                if(this.Local.type == 'LOCAL')this.modalCodes.items = this.modalCodes.items.filter(element => element.id_local == this.Local.id)
                this.OpenEditCoupon()
            }).catch(err => {
                this.Response(err);
            });
            this.$nextTick(() => {
                if(this.Local.type == 'FRANCHISE' && this.menuSublocals === null) this.FranchiseMenuSublocals()
            })
            
        },
        FranchiseMenuSublocals(){
            axios.get(`/menu/${this.Local.id}/sublocals`).then(res => {
                this.Response(res);
                this.menuSublocals = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        selectLocalMenu(){
            this.menu = this.menuSublocals.filter(filtered => filtered.id === this.local).map(filtered => filtered.menu)[0]
        }
    },
    computed: {
        totalCodes () {
            if(!this.modalCodes) return 0;
            return this.modalCodes.codes.length;
        },
        availableCodes () {
            if(!this.modalCodes) return 0;
            var total = this.modalCodes.codes.length;
            var used = 0;
            this.modalCodes.codes.forEach(code => {
                if(code.used_at) used++;
            });
            return total - used;
        },
        franchiseLocals() {
            return this.menuSublocals ? this.menuSublocals.map(sub => ({ text: sub.name, value: sub.id })) : [];
        },
        SublocalsSections(){
            let sect = (this.modalCodes && this.modalCodes.sections) || (this.modalCoupon && this.modalCoupon.sections) || {};
            const simplifiedData = {};
            for (let key in sect) {
                const [number, category] = key.split('-');
                number = number.replace(/[{}]/g, '');
                console.log(number, this.Local.id);
                
                if(this.Local.type == 'LOCAL' && this.Local.id == number) simplifiedData[category] = number
                if(this.Local.type == 'FRANCHISE') simplifiedData[category] = number
                
            }
            return simplifiedData
        }
    },
    watch: {
        coupons(n, o) {
            console.log(n);
            document.getElementById('header-title').innerHTML = `Cupones <span class="counter">${n.length}</span>`;
        }
    }

}
</script>