<template>
<div class="pa-body">
<div class="flex column" v-if="Local.type == 'FRANCHISE' || (location && location.latitude && location.longitude)">

    <div v-if="Local.type == 'FRANCHISE'">
        <div v-if="settings">
            <div class="f18 mb20">General</div>
            <div class="section mb20" v-if="Local.modules.module_store">
                <div class="secondary-text mb20">Determina si los pedidos a través del sitio ecommerce están habilitados. Un cliente puede hacer un pedido si se encuentra dentro de la zona de delivery, esta dentro de los horarios determinados.</div>
                <label class="">
                    <input type="checkbox" v-model="settings.is_active_ecommerce"> Activado <div class="checkmark"></div>
                </label>
                
                <div class="flex column mt15">
                    <!--<div class="secondary-text mb5">Pagina web del ecommerce</div>-->
                    <input-field
                    v-model="settings.ecommerce_url"
                    label="Dominio"
                    placeholder="dominio.cl (sin www, sin http://)">
                        <div slot="after" class="append" v-if="Auth.role=='admin' || Auth.role == 'sub_admin'">
                            <i class="options icon-dots-vertical" @click="Options([
                                {text: 'Instalar SSL', function: () => {CreateSSLEntry()}, confirm: 'Se instalará un certificado SSL del dominio configurado. El dominio debe estar previamente funcionando (DNS propagados).'},
                                {text: 'Crear Dominio', function: () => {UpdateEcommerceDomain()}, confirm: `Se crearán las entradas del dominio en el servidor. Si hay un certificado SSL configurado se sobreescribirá.`},
                            ])"></i>
                        </div>
                    </input-field>
                </div>
            </div>

            <div class="f18 mb20">Personalización</div>
            <div class="section mb20">
                <div class="hdvm mini">
                    <input-image class="mb20"
                    label="Banner"
                    v-model="settings.ecommerce_banner"
                    path="banners/">
                    </input-image>

                    <div>
                        <div class="bold mb10">Carusel</div>
                        <div class="flex">
                            <div class="flex column mr5 relative" v-for="i in 4" :key="`carousel-${i}`">
                                <input-image 
                                v-model="settings.carousel[i-1].image"
                                path="banners/">
                                </input-image>
                                <div class="flex absolute w100 carousel-controls">
                                    <i class="icon selectable icon-arrow-left pa10" @click.stop="MoveCarousel(i-1, -1)" v-show="i > 1"></i>
                                    <div class="flex-fill"></div>
                                    <i class="icon selectable icon-arrow-right pa10" @click.stop="MoveCarousel(i-1, 1)" v-show="i < 4"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <input-field
                label="Descripción"
                description="Descripción de la página web presente en buscadores y al compartir enlace"
                v-model="settings.description">
                </input-field>

                <!--
                <div class="flex mt20 mb15">
                    <div class="flex-fill">
                        <div class="bold">Instagram</div>
                        <div class="secondary-text" v-if="!instagram">Contecta una cuenta de Instagram y muestra las últimas publicaciones en la página web</div>
                        <div class="secondary-text" v-else>Conectada la cuenta @{{instagram.account}}</div>
                    </div>

                    <button v-if="!instagram" @click="ConnectInstagram" class="secondary ml5">Conectar</button>
                    <button v-else class="secondary error-color ml5" @click="Confirm({
                        text: 'Desconectar',
                        class: 'error-color',
                        title: '¿Desconectar cuenta de instagram?',
                        function: DisconnectInstagram
                    })">Desconectar</button>
                </div>
                -->

                <div v-if="Local.modules.module_store_plus" class="flex mt20 mb15">
                    <div class="flex-fill">
                        <div class="bold">Personalizar Apariencia</div>
                        <div class="secondary-text">Cambia los colores, tipografía y formas de la página web</div>
                    </div>
                    <button class="secondary" @click="OpenEcommerceCustomization(Local.type == 'FRANCHISE'? 'STYLE_FRANCHISE' : 'STYLE')">Editar</button>
                </div>
            </div>

            <div class="f18 mb20">Ajustes</div>
            <div class="section mb20">
                <!-- tiempo de expiracion -->
                <!-- <input-field
                    v-model="settings.expiration_time"
                    label="Tiempo de expiración"
                    description="Determina el tiempo en minutos en que el cliente puede confirmar un pedido o realizar el pago, de lo contrario el pedido será cancelado."
                    type="int+"
                    suffix=" minutos">
                </input-field> -->

                <input-select
                    v-model="settings.format_hour"
                    label="Formato de hora"
                    description="Determina como se mostrarán las horas en la página"
                    :items="[
                        {text: 'Formato 24 horas', value: '24_hours', description: 'Muestra la hora en un formato de 24 horas (ejemplo: 13:00).'}, 
                        {text: 'Formato AM/PM', value: 'am_pm', description: 'Muestra la hora en formato AM/PM (ejemplo: 1:00 PM).'},
                    ]">
                </input-select>

                <input-select class="mt20"
                    v-model="settings.format_range"
                    label="Configuración de horario"
                    description="Determina como se mostrarán los horarios en la página"
                    :items="[
                        {text: 'Horario continuo', value: 'continuous', description: 'Muestra el horario continuo (ejemplo: Lunes 18:00 - 02:00).'}, 
                        {text: 'Horario dividido', value: 'split', description: 'Muestra el horario separado (ejemplo: Lunes 18:00 - 23:59, Martes 00:00 - 02:00).'},
                    ]">
                </input-select>

            </div>
        </div>
    </div>

    <div v-else>
        <div class="f18 mb20">General</div>
        <div class="section mb20" v-if="Local.modules.module_store">
            <div class="secondary-text mb20">Determina si los pedidos a través del sitio ecommerce están habilitados. Un cliente puede hacer un pedido si se encuentra dentro de la zona de delivery, esta dentro de los horarios determinados.</div>
            <label class="">
                <input type="checkbox" v-model="settings.is_active_ecommerce"> Activado <div class="checkmark"></div>
            </label>
            
            <div class="flex column mt15">
                <input-field
                v-model="settings.ecommerce_url"
                label="Dominio"
                placeholder="dominio.cl (sin www, sin http://)">
                    <div slot="after" class="append" v-if="Auth.role=='admin' || Auth.role == 'sub_admin'">
                        <i class="options icon-dots-vertical" @click="Options([
                            {text: 'Instalar SSL', function: () => {CreateSSLEntry()}, confirm: 'Se instalará un certificado SSL del dominio configurado. El dominio debe estar previamente funcionando (DNS propagados).'},
                            {text: 'Crear Dominio', function: () => {UpdateEcommerceDomain()}, confirm: `Se crearán las entradas del dominio en el servidor. Si hay un certificado SSL configurado se sobreescribirá.`},
                        ])"></i>
                    </div>
                </input-field>
                <!--
                <div class="secondary-text mb5">Banner del ecommerce (aparecerá en la portada de la página web). Se recomienda una imagen JPG con dimesiones de 1280x720</div>
                <input-image 
                label="Banner"
                v-model="settings.ecommerce_banner"
                path="banners/">
                </input-image>

                <div class="bold mt10 mb5">Carusel</div>
                <input-image v-for="i in 4" :key="`carousel-${i}`" class="relative"
                :label="`Imagen ${i}`"
                v-model="settings.carousel[i-1].image"
                path="banners/">
                    <i class="options icon-arrow-up absolute" style="right: 0; top: 0" @click.stop="MoveCarousel(i-1, -1)"></i>
                    <i class="options icon-arrow-down absolute" style="right: 0; bottom: 0" @click.stop="MoveCarousel(i-1, 1)"></i>
                </input-image>

                <div class="bold mt10 mb5">Instagram</div>
                <div>
                    <button v-if="!instagram" @click="ConnectInstagram">Conectar Instagram</button>
                    <button v-else class="error-color" @click="Confirm({
                        text: 'Desconectar',
                        class: 'error-color',
                        title: '¿Desconectar cuenta de instagram?',
                        function: DisconnectInstagram
                    })">Desconectar @{{instagram.account}}</button>
                </div>
                -->
                <!--<div v-if="Auth.role == 'admin'">
                    <button v-if="!instagram" @click="modalInstagram={token: ''}">Conectar Instagram</button>
                    <button v-else class="error-color" @click="Confirm({
                        text: 'Desconectar',
                        class: 'error-color',
                        title: '¿Desconectar cuenta de instagram?',
                        function: DisconnectInstagram
                    })">Desconectar @{{instagram.account}}</button>
                </div>
                <div v-else>
                    <div v-if="!instagram">No hay ninguna cuenta conectada</div>
                    <div v-else>Cuenta conectada @{{instagram.account}}</div>
                </div>
                -->

            </div>
            
            
        </div>


        <div class="f18 mb20">Personalización</div>
        <div class="section mb20">

            <div class="hdvm mini">
                <input-image class="mb20"
                label="Banner"
                v-model="settings.ecommerce_banner"
                path="banners/">
                </input-image>

                <div>
                    <div class="bold mb10">Carusel</div>
                    <div class="flex">
                        <div class="flex column mr5 relative" v-for="i in 4" :key="`carousel-${i}`">
                            <input-image 
                            v-model="settings.carousel[i-1].image"
                            path="banners/">
                            </input-image>
                            <div class="flex absolute w100 carousel-controls">
                                <i class="icon selectable icon-arrow-left pa10" @click.stop="MoveCarousel(i-1, -1)" v-show="i > 1"></i>
                                <div class="flex-fill"></div>
                                <i class="icon selectable icon-arrow-right pa10" @click.stop="MoveCarousel(i-1, 1)" v-show="i < 4"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <input-field
            label="Descripción"
            description="Descripción de la página web presente en buscadores y al compartir enlace"
            v-model="settings.description">
            </input-field>

            <!--
            <div class="flex mt20 mb15">
                <div class="flex-fill">
                    <div class="bold">Instagram</div>
                    <div class="secondary-text" v-if="!instagram">Contecta una cuenta de Instagram y muestra las últimas publicaciones en la página web</div>
                    <div class="secondary-text" v-else>Conectada la cuenta @{{instagram.account}}</div>
                </div>

                <button v-if="!instagram" @click="ConnectInstagram" class="secondary ml5">Conectar</button>
                <button v-else class="secondary error-color ml5" @click="Confirm({
                    text: 'Desconectar',
                    class: 'error-color',
                    title: '¿Desconectar cuenta de instagram?',
                    function: DisconnectInstagram
                })">Desconectar</button>
            </div>
            -->

            <div v-if="Local.modules.module_store_plus" class="flex mt20">
                <div class="flex-fill">
                    <div class="bold">Personalizar Apariencia</div>
                    <div class="secondary-text">Cambia los colores, tipografía y formas de la página web</div>
                </div>
                <button class="secondary" @click="OpenEcommerceCustomization(Local.type == 'FRANCHISE'? 'STYLE_FRANCHISE' : 'STYLE')">Editar</button>
            </div>

            <div v-if="Local.modules.module_store_plus" class="flex mt15">
                <div class="flex-fill">
                    <div class="bold">Personalizar Estado del Pedido</div>
                    <div class="secondary-text">Cambia los mensajes e imágenes del estado del pedido que se muestran al cliente en la página web</div>
                </div>
                <button class="secondary" @click="OpenEcommerceCustomization('MESSAGES')">Editar</button>
            </div>

            <div class="flex mt15">
                <div class="flex-fill">
                    <div class="bold">Personalizar Notificaciones</div>
                    <div class="secondary-text">Cambia el contenido del email que envía a los clientes</div>
                </div>
                <button class="secondary" @click="OpenEcommerceCustomization('NOTIFICATIONS')">Editar</button>
            </div>

            <div v-if="Local.modules.module_store_plus" class="flex mt15">
                <div class="flex-fill">
                    <div class="bold">Encuesta de Satisfacción</div>
                    <div class="secondary-text">Las encuestas están {{settings.is_survey_active ? 'activadas' : 'desactivadas'}}</div>
                </div>
                <button class="secondary" @click="modalSurvey=Util.Copy(settings)">Editar</button>
            </div>
        </div>

        <!-- más vendidos -->
        <div class="section mb20">

            <input-select class="mb15"
            label="Visualización del menú"
            description="Determina como se mostrará el menú en la página"
            :items="[
                {text: 'Todos los productos', value: 'ALL', description: 'Se muestran todos los productos a la vez, para desplazarse entre secciones se hace scroll'},
                {text: 'Expandir secciones', value: 'EXPAND', description: 'Se muestran las secciones que se pueden expandir para mostrar los productos'},
                {text: 'Separar secciones', value: 'TABS', description: 'Sólo se muestran los productos de la sección seleccionada'}
            ]"
            v-model="settings.menu_display">
            </input-select>

            <input-select class="mb15"
            label="Precio del menú"
            description="Determina como se mostrará el precio de los productos en la página"
            :items="optionsItemPrice"
            v-model="settings.item_price_display">
            </input-select>


            <div class="flex column mb15">
                <div class="flex align-center">
                    <div class="flex-fill">
                        <div class="bold">Sección de Más Vendidos</div>
                    </div>
                    <button class="secondary" @click="OpenModalItemsSelection('Sección Más Vendidos', 'TP')" v-tooltip="'Elige los productos que se mostrarán en los Más Vendidos'">Seleccionar</button>
                </div>
                
                <div v-if="!settings.products_top_items.length" class="secondary-text mt5">Determina el número de productos más vendidos que se mostrarán. Dejar en 0 para no mostrar esta sección.</div>
                <input-field class="mt10"
                v-if="settings.products_top_items.length == 0"
                v-model="settings.top_items"
                description=""
                type="int+"
                suffix=" productos">
                </input-field>
                <div v-else class="secondary-text mt5">
                    Se mostrarán <span class="bold" v-for="(prod, index) in settings.products_top_items" :key="prod.id"> {{ prod.title }}<span v-if="index < settings.products_top_items.length - 1">,</span></span> como productos más vendidos.
                </div>
                
            </div>

            <div class="flex column mb15">
                <div class="flex align-center">
                    <label>
                        <input type="checkbox" v-model="settings.recommend_products_active">
                        <div class="checkmark"></div>
                    </label>
                    <div class="flex-fill">
                        <div class="bold">Recomendar Productos</div> 
                    </div>
                    <button v-if="settings.recommend_products_active" class="secondary" @click="OpenModalItemsSelection('Productos recomendados', 'RM')" v-tooltip="'Elige los productos que se mostrarán en los productos recomendados'">Seleccionar</button>
                </div>

                <div class="secondary-text mt5">Antes de enviar el pedido se mostrarán 
                    <span v-if="!settings.products_recommend.length || !settings.recommend_products_active">items relevantes o más vendidos para agregar</span>
                    <span v-else class="bold"><span v-for="(prod, index) in settings.products_recommend" :key="prod.id"> {{ prod.title }}<span v-if="index < settings.products_recommend.length - 1">,</span></span></span>
                </div>   
                
                <input-field
                v-if="settings.recommend_products_active && (settings.products_recommend.length == 0)"
                class="mt10"
                v-model="settings.recommend_items"
                type="int+"
                prefix="mostrar "
                suffix=" productos">
                </input-field>

            </div>

            <div class="flex column">
                <div class="flex align-center">
                    <label>
                        <input type="checkbox" v-model="settings.show_supersection">
                        <div class="checkmark"></div>
                    </label>
                    <div class="flex-fill">
                        <div class="bold">Mostrar super secciones </div> 
                        <div class="secondary-text">Al estar activado no se mostrará la sección "Más Vendidos"</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="f18 mb20">Ajustes</div>
        <div class="section mb20">
            <!-- Tipo de E-commerce -->
            <div class="bold">Tipo de Tienda</div>
            <div class="secondary-text mb10">Selecciona el tipo de pedidos que hace el local.</div>
            <label class="mb5">
                <input type="radio" name="ecommerce" value="RESTAURANT" v-model="settings.ecommerce_type"> Restaurante (se pueden hacer pedidos los días configurados) <div class="radio"></div>
            </label>
            <label class="">
                <input type="radio" name="ecommerce" value="SHIPMENT" v-model="settings.ecommerce_type"> Encargos (se pueden hacer pedidos a cualquier hora y son aceptados automáticamente) <div class="radio"></div>
            </label>

            <input-select class="mt15"
            v-model="settings.default_sale"
            label="Tipo de pedido por defecto"
            description="Determina el tipo de pedido por defecto"
            :items="[
                {text: 'Retiro', value: 'TAKEAWAY'},
                {text: `${settings.ecommerce_type == 'SHIPMENT'? 'Encargo' : 'Delivery'}`, value: 'DELIVERY'},
            ]">
            </input-select>

            <div class="well mt20">
                <label>
                    <input type="checkbox" v-model="settings.accepts_takeaway"> Acepta pedidos para retirar <div class="checkmark"></div>
                </label>

                <label class="mt20" v-if="settings.ecommerce_type == 'RESTAURANT'">
                    <input type="checkbox" v-model="settings.accepts_skip_line"> Permite opción "Saltarse la fila" <div class="checkmark"></div>
                </label>

                <label class="mt20 ml35" v-if="settings.ecommerce_type == 'RESTAURANT' && settings.accepts_skip_line">
                    <input type="checkbox" v-model="settings.skip_line_distance_restriction"> 
                    <div class="flex column">
                        <div class="bold">Restricción de distancia</div>
                        <div class="secondary-text">Opción para habilitar/deshabilitar la restricción, la que obliga al usuario a permanecer cerca del local para poder habilitar opción "Saltarse la fila".</div>
                    </div> 
                    <div class="checkmark"></div>
                </label>
            </div>

            <div class="well mt10">
                <label v-if="settings.ecommerce_type == 'RESTAURANT'">
                    <input type="checkbox" v-model="settings.accepts_delivery"> Acepta pedidos delivery <div class="checkmark"></div>
                </label>
                <div v-if="settings.accepts_delivery || settings.ecommerce_type == 'SHIPMENT'" :class="`flex column ${settings.ecommerce_type == 'RESTAURANT' ? 'mt20' : ''}`">
                    <input-field
                    v-model="settings.minimun"
                    label="Mínimo"
                    description="Compra mínima para aceptar compras delivery"
                    type="int+">
                    </input-field>

                    <label class="mt20" v-if="Local.modules.module_pya_courier">
                        <input type="checkbox" v-model="settings.delivery_pya"> <div class="flex column">
                            <div class="bold">Repartos por PedidosYa</div>
                            <div class="secondary-text">Los pedidos Delivery que hagan los clientes se enviarán por PedidosYa Courier. Los horarios para estos pedidos también serán restringindos a los horarios de trabajo de PedidosYa.</div>
                        </div> <div class="checkmark"></div>
                    </label>
                </div>
            </div>

            <div class="well mt10" v-if="settings.ecommerce_type == 'RESTAURANT'">
                <label>
                    <input type="checkbox" v-model="settings.accepts_scheduled"> Se aceptan pedidos programados para otro día<div class="checkmark"></div>
                </label>
                <input-field class="mt20"
                v-if="settings.accepts_scheduled"
                v-model="settings.min_scheduled_time_today"
                label="Tiempo mínimo"
                description="Determina el tiempo mínimo (en minutos) cuando se programan pedidos para el mismo día"
                type="int+"
                suffix=" minutos">
                </input-field>
            </div>
        </div>

        <div class="section mb20">
            <!-- tiempo de expiracion -->
            <input-field
            v-model="settings.expiration_time"
            label="Tiempo de expiración"
            description="Determina el tiempo en minutos en que el cliente puede confirmar un pedido o realizar el pago, de lo contrario el pedido será cancelado."
            type="int+"
            suffix=" minutos">
            </input-field>

            <input-select class="mt20"
            v-model="settings.format_hour"
            label="Formato de hora"
            description="Determina como se mostrarán las horas en la página"
            :items="[
                {text: 'Formato 24 horas', value: '24_hours', description: 'Muestra la hora en un formato de 24 horas (ejemplo: 13:00).'}, 
                {text: 'Formato AM/PM', value: 'am_pm', description: 'Muestra la hora en formato AM/PM (ejemplo: 1:00 PM).'},
            ]">
            </input-select>

            <input-select class="mt20"
            v-model="settings.format_range"
            label="Configuración de horario"
            description="Determina como se mostrarán los horarios en la página"
            :items="[
                {text: 'Horario continuo', value: 'continuous', description: 'Muestra el horario continuo (ejemplo: Lunes 18:00 - 02:00).'}, 
                {text: 'Horario dividido', value: 'split', description: 'Muestra el horario separado (ejemplo: Lunes 18:00 - 23:59, Martes 00:00 - 02:00).'},
            ]">
            </input-select>

        </div>

        <div class="section mb20">
            <!-- propinas -->
            <label class="">
                <input type="checkbox"  v-model="settings.tips_enabled"> <div class="flex column">
                    <div class="bold">Propinas</div>
                    <div class="secondary-text">Selecciona si se permite el ingreso de propinas desde el ecommerce</div>
                </div> <div class="checkmark"></div>
            </label>

            
            <input-field v-show="settings.tips_enabled" class="mt20"
            v-model="settings.tips_default"
            label="Propina por defecto"
            description="Ingresa una propina por defecto que se ingresará al hacer un nuevo pedido (ecommerce). El cliente puede cambiar o quitar la propina."
            type="float+"
            suffix="%">
            </input-field>
        </div>

        <div class="section mb20" v-if="Local.modules.module_store_plus">
            <input-field class="mb15"
            v-model="settings.google_analytics"
            label="Google Analytics ID"
            placeholder="G-XXXXXXXXXXX (Opcional)">
            </input-field>

            <input-field class="mb15"
            v-model="settings.facebook_pixel"
            label="Facebook Pixel ID"
            placeholder="123456789012345 (Opcional)">
            </input-field>

            <input-field
            v-model="settings.facebook_pixel_access_token"
            label="Facebook Pixel Token de Acceso"
            placeholder="Token de Acceso (Opcional)">
            </input-field>
        </div>

        <div>
            <div class="flex mb20">
                <div class="flex-fill">
                    <div class="f18">Horarios</div>
                    <div class="secondary-text">Días y horarios cuando un cliente puede hacer pedidos.</div>
                </div>

                <i class="options icon-plus" @click="modalSchedule={day: 'monday', start: '00:00', finish: '23:59', delivery: true, takeaway: true}"></i>

            </div>
            
            <div class="section mb20">
                <div v-if="!settings.delivery_pya && settings.ecommerce_type == 'SHIPMENT'">
                    <input-select class="mb15"
                    v-model="settings.shipment_type"
                    label="Fecha de despacho"
                    description="Determina si la fecha de despacho de un pedido realizado se calculará automáticamente o si el cliente deberá ingresarla al hacer el pedido"
                    :items="[
                        {text: 'Programar fecha automáticamente', value: 'AUTO', description: 'Se calculará la fecha de despacho automáticamente al hacer el pedido'}, 
                        {text: 'Programar fecha manualmente', value: 'MANUAL_DATE', description: 'Permite al cliente solo seleccionar la fecha'},
                        {text: 'Programar fecha y hora manualmente', value: 'MANUAL_DATETIME', description: 'Permite al cliente seleccionar la fecha y hora'}
                    ]">
                    </input-select>

                    <input-field v-if="settings.shipment_type == 'AUTO'"
                    v-model="settings.shipment_time"
                    label="Tiempo de despacho"
                    description="Determina el tiempo o rango de tiempo en que se despacharán los encargos una vez hecho el pedido"
                    placeholder="(Ej: 24 horas, 2 dias - 3 dias)"
                    info="Ejemplo: <ul><li>30 minutos</li><li>3 horas</li><li>5 horas 30 minutos</li><li>1 dia</li><li>3 dias 4 horas</li><li>10 horas - 20 horas</li><li>1 dia - 2 dias</li></ul>">
                    </input-field>
                    <input-field v-else
                    v-model="settings.shipment_time"
                    label="Tiempo de despacho"
                    description="Determina el tiempo de demora en que se despacharán los encargos una vez hecho el pedido"
                    placeholder="(Ej: 24 horas)"
                    info="Ejemplo: <ul><li>30 minutos</li><li>3 horas</li><li>5 horas 30 minutos</li><li>1 dia</li><li>3 dias 4 horas</li></ul>">
                    </input-field>
                </div>
                <div v-if="!settings.delivery_pya && settings.ecommerce_type == 'SHIPMENT'" class="separator mb20 mt20"></div>

                <div class="well mb10 selectable flex align-center" v-for="(schedule, index) in settings.schedule" :key="index"
                @click="modalSchedule = Util.Copy(schedule, {index: index})">
                    <div class="flex-fill">
                        <div class="bold">{{Util.DictDaysEnToEs[schedule.day]}}</div>
                        <div class="secondary-text">{{schedule.start}} - {{schedule.finish}}</div>
                    </div>
                    <i class="options icon-delivery" :style="{color: schedule.delivery ? 'var(--color-delivery-transit)' : 'var(--gray-2)'}" @click.stop="$set(schedule, 'delivery', !schedule.delivery)" v-tooltip="'Activar para pedidos Delivery'"></i>
                    <i class="options icon-bag" :style="{color: schedule.takeaway ? 'var(--color-takeaway-time)' : 'var(--gray-2)'}" @click.stop="$set(schedule, 'takeaway', !schedule.takeaway)" v-tooltip="'Activar para pedidos para LLevar'"></i>
                    <i class="options icon-trash ml5" @click.stop="Confirm({
                        text: 'Eliminar',
                        title: '¿Eliminar horario?',
                        class: 'error-color',
                        function: () => {settings.schedule.splice(index, 1)}
                    })"></i>
                </div>
            </div>
        </div>

        <div class="f18 mb20">Métodos de Pago</div>

        <div class="section mb20">
            <draggable
                :list="dragListPayment"
                :animation="200"
                handle=".handle"
                group="methods">
        
                <div v-for="method in dragListPayment" :key="method.method" class="well mb10 selectable">
                    <div class="flex align-center">
                        <i class="icon icon-drag-handle handle"></i>
                        <div class="flex-fill">
                            <div class="bold">{{Util.PayMethod(method.method)}}</div>
                        </div>
                        <i class="options icon-delivery" :style="{color: method.delivery ? 'var(--color-delivery-transit)' : 'var(--gray-2)'}" @click.stop="$set(method, 'delivery', !method.delivery)" v-tooltip="'Activar para pedidos Delivery'"></i>
                        <i class="options icon-bag" :style="{color: method.takeaway ? 'var(--color-takeaway-time)' : 'var(--gray-2)'}" @click.stop="$set(method, 'takeaway', !method.takeaway)" v-tooltip="'Activar para pedidos LLevar'"></i>
                    </div>
                    <div class="secondary-text" v-if="method.method == 'ONLINE'">Se debe enlazar el negocio con Transbank en la pestaña General</div>
                    <label class="mt5" v-if="method.method == 'BANK_DEPOSIT'">
                        <input type="checkbox" v-model="settings.auto_confirm_bank_deposit"> Confirmar Transferencia Bancaria automáticamente <div class="checkmark"></div>
                    </label>
                    <label class="mt5" v-if="method.method != 'CASH' && method.method != 'BANK_DEPOSIT' && method.method != 'ONLINE'">
                        <input type="checkbox" v-model="method.confirm_pay"> Confirmar pago automáticamente <div class="checkmark reverse"></div>
                    </label>
                    <label class="mt5" v-if="method.method == 'CASH'">
                        <input type="checkbox" v-model="settings.confirm_cash"> Efectivo obligatorio <div class="checkmark"></div>
                    </label>
                </div>
            </draggable>
        </div>
    </div>

    <modal v-model="modalSchedule" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalSchedule && 'index' in modalSchedule ? 'Editar Horario' : 'Nuevo Horario'">
        <div class="pa20" v-if="modalSchedule">
            <input-select class="mb15"
            label="Día"
            :items="[{text: 'Lunes', value: 'monday'}, {text: 'Martes', value: 'tuesday'}, {text: 'Miércoles', value: 'wednesday'} ,{text: 'Jueves', value: 'thursday'}, {text: 'Viernes', value: 'friday'}, {text: 'Sábado', value: 'saturday'}, {text: 'Domingo', value: 'sunday'}]"
            v-model="modalSchedule.day">
            </input-select>
            <div class="hs mb20">
                <input-date class="sm"
                label="Apertura"
                v-model="modalSchedule.start"
                type="TIME"
                format="HH:mm">
                </input-date>
                
                <input-date class="sm"
                label="Clausura"
                v-model="modalSchedule.finish"
                type="TIME"
                format="HH:mm">
                </input-date>
            </div>
            <label class="mb5">
                <input type="checkbox" v-model="modalSchedule.delivery"> Habilitado para pedidos Delivery <div class="checkmark"></div>
            </label>
            <label class="">
                <input type="checkbox" v-model="modalSchedule.takeaway"> Habilitado para pedidos para Retiro <div class="checkmark"></div>
            </label>
        </div>
        <button class="primary purple" slot="actions" @click="SaveSchedule">Guardar</button>
    </modal>
</div>

<div v-else class="ta-center secondary-text mt10">Ubicación del negocio faltante (latitud y longitud)</div>

    <modal v-model="modalInstagram" dir="right" desktopWidth="400px" title="Instagram">
        <div class="section" v-if="modalInstagram">
            <input-field
            v-model="modalInstagram.token"
            label="Token de acceso"
            type="password">
            </input-field>
        </div>
        <div class="modal-actions">
            <button class="selected-color" @click="ConnectInstagram">Conectar</button>
        </div>
    </modal>

    <customization-form ref="customizationForm" @OnSaved="SaveCustomization($event)"></customization-form>

    <modal v-model="modalSurvey" title="Encuesta de Satisfacción" dir="right" :modalClass="['fill', 'right md h100']">
        <div v-if="modalSurvey" class="pa20">
            
            <input-toggle class="mb15"
            v-model="modalSurvey.is_survey_active"
            label="Encuestas Activadas">
            </input-toggle>

            <input-toggle class="mb15"
            v-model="modalSurvey.survey_items"
            label="Evaluar Productos"
            description="Determina si en la encuesta se evaluará cada producto del pedido por separado">
            </input-toggle>

            <input-toggle class="mb15"
            v-model="modalSurvey.survey_comment"
            label="Comentario adicional"
            description="Permite al cliente escribir un comentario adicional en la encuesta">
            </input-toggle>

            <input-field
            v-model="modalSurvey.survey_tags"
            :lines="5"
            label="Calificar"
            description="Muestra en la encuesta opciones que el cliente puede seleccionar para evaluar su experiencia. Ingresar una opción por línea.">
            </input-field>

        </div>

        <button slot="actions" class="primary purple" @click="editSettingSurvey()">Guardar</button>

    </modal>
    <modal v-model="modalStore" dir="right" :modalClass="['fill', 'md right h100']" :title="modalStore ? modalStore.title : ''">
        <input-select class="ml5"
        v-if="modalStore"
        ref="CurrentItem"
        inputClass="icon primary"
        :triggerFirstValueChanged="false"
        @OnValueChanged="AddToItemsSelection($event)"
        :items="storeMenuItems"
        :canType="'search'"
        icon="plus"
        slot="options"
        callbackOnly>
        </input-select>
        <div v-if="modalStore" class="pa20">
            <div v-if="!modalStore.array.length" class="secondary-text ta-center">No hay items seleccionados</div>
            <draggable 
            :list="dragList"
            handle=".handle"
            :animation="200"
            group="items">
                <div class="flex well mb10" v-for="(item, index) in dragList" :key="index">
                    <div class="flex-fill">
                        <div class="flex align-center">
                            <i class="icon icon-drag-handle handle"></i>
                            <div class="align-center">{{ item.title }} </div>  
                            <div class="flex-fill"></div>
                            <i class="options icon-trash ml10 c4 align-center" @click.stop="modalStore.array.splice(index, 1)"></i>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
        <button slot="actions" class="primary purple" @click="SaveItemsSelection()">Aceptar</button>
    </modal>

</div>

</template>

<script>
import CustomizationForm from '../common/CustomizationForm.vue';

export default {
  components: { CustomizationForm},
    data() {
        return {
            location: null,
            methods: null,
            settings: null,
            selectedMethods: {},
            dragList: [],
            dragListPayment : [],
            instagram: null,
            modalInstagram: null,
            key: null,
            modalSchedule: null,
            modalSurvey: null,
            modalStore: null,
            storeMenuItems: [],
            optionsItemPrice: [
                {value: 'ITEM_PRICE', text: 'Producto', description: 'Precio del producto'},
                {value: 'MODIFIER_PRICE', text: 'Modificador', description: 'Precio del primer modificador, si no hay se mostrará el precio del producto'},
                {value: 'AUTO', text: 'Auto', description: 'Precio del producto, si no hay se mostrará el precio de un modificador, si no hay se oculta'},
                {value: 'HIDDEN_ZERO', text: 'Productos con precio', description: 'Precio del producto pero no se mostrará si el precio es cero'},
                {value: 'HIDDEN_ALL', text: 'Oculto', description: 'No mostrará el precio'},
            ],
            dictDays: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
        }
    },
    mounted() {        
        
    },
    methods: {    
        Init(force) {
            if(force || !this.settings)
            {
                this.Loading();
                axios.get(`/settings/delivery/${this.Local.id}/v2`).then(res => {
                    this.Response(res);

                    if(!res.data.delivery.carousel) res.data.delivery.carousel = [{image: null},{image: null},{image: null},{image: null}];

                    this.location = res.data.location;
                    this.methods = res.data.methods;
                    if(res.data.delivery.bank_settings.length == 0) res.data.delivery.bank_settings = {};
                    this.settings = res.data.delivery;
                    console.log(this.settings);
                    this.instagram = res.data.instagram;
                    this.key = res.data.key;

                    var settingsMethods = {
                        ONLINE: {method: 'ONLINE', delivery: false, takeaway: false},
                        BANK_DEPOSIT: {method: 'BANK_DEPOSIT', delivery: false, takeaway: false},
                        CASH: {method: 'CASH', delivery: false, takeaway: false},
                    }
                    this.methods.payment_methods.forEach(method => {
                        if(!method.pending_payment) settingsMethods[method.title] = {method: method.title, delivery: false, takeaway: false, confirm_pay : false};
                    });

                    this.settings.payment_methods.forEach(method => {
                        if(settingsMethods[method.method])
                        {
                            settingsMethods[method.method].delivery = method.delivery;
                            settingsMethods[method.method].takeaway = method.takeaway;
                            settingsMethods[method.method].confirm_pay = method.confirm_pay;
                        }
                    });
                    this.selectedMethods = settingsMethods;
                    

                    if(this.settings.payment_methods_order.length == 0){
                        this.dragListPayment = Object.values(this.selectedMethods)
                    }else{

                        let auxArrayMethods = []
                        auxArrayMethods = Object.values(this.selectedMethods)
                        let indexMap = {};
                        this.settings.payment_methods_order.forEach((obj, index) => {
                            indexMap[obj] = index;
                        });

                        let sortedMethods = auxArrayMethods.sort((x, y) => indexMap[x.method] - indexMap[y.method]);
                        this.dragListPayment = sortedMethods
                    }
                    
                    /*
                    setTimeout(() => {
                        if(this.settings.zones.length)
                            this.SelectPolygon(0);
                    }, 100);
                    */
                    
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        Save() {

            if(this.settings.recommend_products_active && this.settings.recommend_items <= 0 && this.settings.products_recommend.length == 0) return this.Info("Debe ingresar cantidad mayor a 0 en productos a recomendar.")

            if(!this.settings.accepts_takeaway && this.settings.accepts_skip_line) return this.Info('"Saltar fila" requiere aceptar "Pedidos para retirar"');
            if((!this.settings.accepts_takeaway || !this.settings.accepts_skip_line) && this.settings.skip_line_distance_restriction) return this.Info('Esta opción requiere tener activado "Pedidos para retirar" y "Saltar fila"');

            if(this.settings.minimun < 0) return this.Info('Mínimo inválido');
            if(this.settings.facebook_pixel_access_token && !this.settings.facebook_pixel) return this.Info('Pixel ID es requerido para Token de Acceso');
            
            if(this.settings.ecommerce_url)
            {
                this.settings.ecommerce_url = this.settings.ecommerce_url.trim().toLowerCase();
                var invalidDomains = ['influye.app', 'influye.online', 'influyeapp.cl'];
                if(this.settings.ecommerce_url.indexOf('://') > -1 || 
                    this.settings.ecommerce_url.indexOf('www.') > -1 ||
                    (this.settings.ecommerce_url.split('.').length > 3 || this.settings.ecommerce_url.split('.').length < 2) || 
                    invalidDomains.indexOf(this.settings.ecommerce_url) > -1
                    )
                        return this.Info('Dominio inválido');
            }

            var payment_methods = [];
            for(var key in this.selectedMethods)
            {
                var method = this.selectedMethods[key];
                if(method.delivery || method.takeaway)
                {
                    payment_methods.push(method);
                }
            }

            this.settings.payment_methods = payment_methods;
           
            let newOrder = []
            for (let index = 0; index < this.dragListPayment.length; index++) {
                const element = this.dragListPayment[index];
                newOrder.push(element.method)
            }
            this.settings.payment_methods_order = newOrder

            if(this.settings.ecommerce_type == 'SHIPMENT' && this.settings.delivery_pya)
            {
                this.settings.shipment_type = 'MANUAL_DATETIME';
                this.settings.shipment_time = 0;
            }

            this.Loading();
            axios.post(`/settings/delivery/${this.Local.id}/v2`, this.settings).then(res => {
                this.Info('Configuración guardada.');
            }).catch(err => {
                this.Response(err);
            });

        },
        /*
        @method ImportMaps Creación de poligonos de zona a partir de un texto (almacenado en la variable stringXML), agregandolos al arreglo de zonas
        */
        ImportMaps()
        {
            const reader = new FileReader();

            reader.onload = (e) => {
                console.log(e.target.result);
                var stringXML = e.target.result;
                var domParser = new DOMParser();
                var xmlDocument = domParser.parseFromString(stringXML, "text/xml");

                console.log(xmlDocument);

                var placemarks = xmlDocument.getElementsByTagName('Placemark');
                for(var i = 0; i < placemarks.length; i++)
                {
                    var placemark = placemarks[i];
                    var polygon = placemark.getElementsByTagName('Polygon');
                    if(polygon && polygon.length)
                    {
                        var names = placemark.getElementsByTagName('name');
                        var name = '';
                        if(names && names.length) name = names[0].textContent;
                        console.log(name);
                        
                        var coordinates = polygon[0].getElementsByTagName('coordinates');
                        if(coordinates && coordinates.length) coordinates = coordinates[0].textContent.trim();
                        else coordinates = null;
                        console.log(coordinates);

                        if(coordinates)
                        {
                            var poly = [];
                            var coords = coordinates.split(' ');
                            var pointsMap = {};
                            coords.forEach(point => {
                                if(point)
                                {
                                    var xyz = point.split(',');
                                    if(xyz.length >= 2)
                                    {
                                        var lat = parseFloat(xyz[1]);
                                        var lon = parseFloat(xyz[0]);
                                        if(!isNaN(lat) && !isNaN(lon))
                                        {
                                            if(!pointsMap[lat+','+lon])
                                            {
                                                poly.push({lat: lat, lng: lon});
                                                pointsMap[lat+','+lon] = true;
                                            }
                                        }
                                    }
                                }
                            });
                            if(poly.length)
                            {
                                this.settings.zones.push({
                                    cost: 0, 
                                    polygon: poly,
                                    label: name ? name : 'Zona importada'
                                });
                                
                            }
                        }

                    }

                    

                };

                /*
                var n = 2;
                for (let j = 1; j < xmlDocument.getElementsByTagName('coordinates').length; j++) { 

                    var z = 2*n;
                    n++;
                    var valores = xmlDocument.getElementsByTagName('name')[z].childNodes[0].nodeValue;
                    var costos = Number(valores.replace('$', '').replace('.',''));

                    var coordenandasXml = xmlDocument.getElementsByTagName('coordinates')[j].childNodes[0].nodeValue;
                    var arrayCoordenadas = coordenandasXml.split("\n");

                    var latitud = arrayCoordenadas[1].toString().trim().split(",", 2)[1];
                    var longitud = arrayCoordenadas[1].toString().trim().split(",", 2)[0];

                    var coordenadas = {lat: Number(latitud), lng: Number(longitud)};
                    var polygon = []; 
                    
                    polygon[0] = coordenadas;


                    for (let i = 1; i < 360; i++) {
                        //console.log(arrayCoordenadas[i].toString().trim().split(",", 2));
                        latitud = arrayCoordenadas[i].toString().trim().split(",", 2)[1];
                        longitud = arrayCoordenadas[i].toString().trim().split(",", 2)[0];
                        coordenadas = {lat: Number(latitud), lng: Number(longitud)};
                        polygon[i] = coordenadas;
                    }
                    this.settings.zones.push({cost: costos, polygon});
                    this.SelectPolygon(this.settings.zones.length-1);
                }
                */
                this.modalImportMaps = false;
                this.SelectPolygon(this.settings.zones.length-1);
            };
            reader.readAsText(this.modalImportMaps.file);
        },
        /*
        @method AddRadio Creacion de area circular para reparto basado en kilómetros, agregandolo al arreglo de zonas
        @params modalRadio
        @param radio <Float> el radio en kilómetros
        @param cost <Integer> el costo de la zona
        */
        AddRadio()
        {          
            var coordenadas = {lat: (Number(this.location.latitude) + (this.modalRadio.radio / 111.32)), lng: Number(this.location.longitude)};
           
            var polygon = [];              
            //var constante = 0.069;      
            //polygon[0] = coordenadas;

            var center = {lat: Number(this.location.latitude), lng: Number(this.location.longitude)};
            var radio = this.modalRadio.radio;

            var R = 6371;
            var pi = Math.PI;

            var lat  = (center.lat * pi) / 180;
            console.log('lat ' + lat);
            var lng  = (center.lng * pi) / 180;
            var d = radio / R;

            for(var angle = 0; angle < 360; angle+=15)
            {
                var brng = angle * pi / 180;
                console.log('brng ' + brng);

                var pLat = Math.asin(Math.sin(lat)*Math.cos(d) + Math.cos(lat)*Math.sin(d)*Math.cos(brng));
                console.log('pLat ' + pLat);
                var pLng = ((lng + Math.atan2(Math.sin(brng)*Math.sin(d)*Math.cos(lat), Math.cos(d)-Math.sin(lat)*Math.sin(pLat))) * 180) / pi;
                pLat = (pLat * 180) / pi;

                polygon.push({
                    lat: pLat,
                    lng: pLng
                });
            }
            this.settings.zones.push({cost: this.modalRadio.cost, polygon});
            this.SelectPolygon(this.settings.zones.length-1);
            this.modalRadio = false;
        },
        AddZone() {

            var polygon = [];    
            var radio = null;
            if(this.modalZone.radio && !isNaN(this.modalZone.radio))
            {
                var center = {lat: Number(this.location.latitude), lng: Number(this.location.longitude)};
                radio = parseFloat(this.modalZone.radio);

                var R = 6371;
                var pi = Math.PI;

                var lat  = (center.lat * pi) / 180;
                console.log('lat ' + lat);
                var lng  = (center.lng * pi) / 180;
                var d = radio / R;

                for(var angle = 0; angle < 360; angle+=15)
                {
                    var brng = angle * pi / 180;
                    console.log('brng ' + brng);

                    var pLat = Math.asin(Math.sin(lat)*Math.cos(d) + Math.cos(lat)*Math.sin(d)*Math.cos(brng));
                    console.log('pLat ' + pLat);
                    var pLng = ((lng + Math.atan2(Math.sin(brng)*Math.sin(d)*Math.cos(lat), Math.cos(d)-Math.sin(lat)*Math.sin(pLat))) * 180) / pi;
                    pLat = (pLat * 180) / pi;

                    polygon.push({
                        lat: pLat,
                        lng: pLng
                    });
                }
            }

            if(radio && !this.modalZone.label) this.modalZone.label = `${radio} Km`;
            if(this.modalZone.edit !== undefined)
            {
                if(radio) this.modalZone.polygon = polygon;
                this.$set(this.settings.zones, this.modalZone.edit, this.modalZone);
                this.SelectPolygon(this.modalZone.edit);
            }
            else
            {
                this.modalZone.polygon = polygon;
                this.settings.zones.push(this.modalZone);
                this.SelectPolygon(this.settings.zones.length-1);
            }
            this.modalZone = null;
        },
        SelectPolygon(index) {
            this.currentPolygon = index;
            var bg = [];
            for(var i = 0; i < this.settings.zones.length; i++)
                if(i !== index) bg.push(this.settings.zones[i].polygon);
            this.$refs.map.SetPolygon(this.settings.zones[index].polygon, bg);
        },
        RemovePolygon(index) {
            this.settings.zones.splice(index, 1);
            if(!this.settings.zones.length)
            {
                this.currentPolygon = null;
                this.$refs.map.SetPolygon([]);
            }
            else
            {
                this.SelectPolygon(0);
            }
        },
        UpdatePolygon(polygon)
        {
            if(this.currentPolygon !== null)
            {
                this.$set(this.settings.zones[this.currentPolygon], 'polygon', polygon);
            }
        },
        UpdateEcommerceDomain()
        {
            this.Loading();
            axios.get(`/settings/delivery/${this.Local.id}/domain/update`).then(res => {
                this.Info('Se han creado las configuraciones en el servidor para el dominio');
            }).catch(err => {
                this.Response(err);
            });
        },
        CreateSSLEntry()
        {
            this.Loading();
            axios.get(`/settings/delivery/${this.Local.id}/domain/ssl`).then(res => {
                this.Info('Se instalará un certificado SSL para el dominio');
            }).catch(err => {
                this.Response(err);
            });
        },
        /*
        DisableIAP(time)
        {
            this.Loading();
            axios.post(`/settings/delivery/${this.Local.id}/iap/disable`, {time: time}).then(res => {
                this.$set(this.settings, 'disabled_until', res.data.timestamp);
                this.Info(`Pedidos online deshabilitados hasta ${this.Util.Date(res.data.timestamp, 'HH:mm DD/MM/YYYY')}`);
            }).catch(err => {
                this.Response(err);
            });
        },
        EnableIAP()
        {
            this.Loading();
            axios.get(`/settings/delivery/${this.Local.id}/iap/enable`).then(res => {
                this.$set(this.settings, 'disabled_until', null);
                this.Info(`Pedidos online habilitados`);
            }).catch(err => {
                this.Response(err);
            });
        },
        */
        MoveCarousel(index, mov)
        {
            mov = index + mov;
            if(mov < 0) mov = 3;
            if(mov > 3) mov = 0;
            var tmp = this.settings.carousel[mov];
            this.$set(this.settings.carousel, mov, this.settings.carousel[index]);
            this.$set(this.settings.carousel, index, tmp);
        },
        OpenEcommerceCustomization(type)
        {
            this.Loading();
            axios.get(`/ecommerce/${this.Local.id}/customization`).then(res => {
                //this.modalCustomization = res.data;
                this.$refs.customizationForm.Open(res.data, type, null, this.settings.ecommerce_url);
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveCustomization(data)
        {
            this.Loading();
            axios.patch(`/ecommerce/${this.Local.id}/customization`, data).then(res => {
                this.Info('Datos guardados');
            }).catch(err => {
                this.Response(err);
            });
        },
        ConnectInstagram()
        {
            this.Util.Open(`https://api.instagram.com/oauth/authorize?client_id=861852671083759&redirect_uri=https://backend.influye.app/instagram/callback&scope=user_profile,user_media&response_type=code&state=${this.key}`);
            /*
            this.Loading();
            axios.post(`/instagram/${this.Local.id}/connect`, this.modalInstagram).then(res => {
                this.Info('Cuenta conectada correctamente');
                this.instagram = res.data;
                this.modalInstagram = null;
            }).catch(err => {
                this.Response(err);
            });
            */
        },
        DisconnectInstagram()
        {
            this.Loading();
            axios.get(`/instagram/${this.Local.id}/disconnect`).then(res => {
                this.Info('Cuenta desconectada correctamente');
                this.instagram = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveSchedule() 
        {
            //verificar horas de horario
            var start = this.Util.TimeToMinutes(this.modalSchedule.start);
            var finish = this.Util.TimeToMinutes(this.modalSchedule.finish);

            if(start >= finish){
                const currentIndex = this.dictDays.indexOf(this.modalSchedule.day);
                const nextDayIndex = (currentIndex + 1) % 7;
                const nextDay = this.dictDays[nextDayIndex];

                let schedule1 = this.Util.Copy(this.modalSchedule);
                let schedule2 = this.Util.Copy(this.modalSchedule);

                schedule1.finish = '23:59';

                schedule2.day = nextDay;
                schedule2.start = '00:00';
                delete schedule2.index;
                
                if('index' in schedule1){
                    var index = schedule1.index;
                    delete schedule1.index;
                    this.$set(this.settings.schedule, index, schedule1);
                }else{
                    this.settings.schedule.push(schedule1);
                }
                
                this.settings.schedule.push(schedule2);
                this.modalSchedule = null;
                return this.SortSchedules();
            }

            if('index' in this.modalSchedule)
            {
                var index = this.modalSchedule.index;
                delete this.modalSchedule.index;
                this.$set(this.settings.schedule, index, this.modalSchedule);
            }
            else
            {
                this.settings.schedule.push(this.modalSchedule);
            }
            this.modalSchedule = null;
            this.SortSchedules();
        },
        SortSchedules() 
        {
            var daysVal = {
                monday: 0,
                tuesday: 100000,
                wednesday: 200000,
                thursday: 300000,
                friday: 400000,
                saturday: 500000,
                sunday: 600000
            };

            this.settings.schedule.sort((a, b) => {
                var valA = daysVal[a.day] + this.Util.TimeToMinutes(a.start);
                var valB = daysVal[b.day] + this.Util.TimeToMinutes(b.start);
                return valA - valB;
            });
        },
        editSettingSurvey(){
            this.Loading();
            axios.put('/customers/editSettingSurvey/'+this.Local.id,{
                'is_survey_active': this.modalSurvey.is_survey_active,
                'survey_items': this.modalSurvey.survey_items,
                'survey_comment': this.modalSurvey.survey_comment,
                'survey_tags': this.modalSurvey.survey_tags,
            }).then(({data}) => {
                this.Info(data.message);
                if(!data.status) return;
                this.modalSurvey = null;
                this.settings = data.settings;
            })
            .catch(function (err) {
                //console.log(err);
            });
        },
        async OpenModalItemsSelection(title, modalType) {

            this.modalStore = { title: title, modal: modalType, array: []};
            if(this.modalStore.modal == 'TP'){
                this.modalStore.array = [...this.settings.products_top_items];
                this.dragList = this.modalStore.array
            }
            if(this.modalStore.modal == 'RM'){
                this.modalStore.array = [...this.settings.products_recommend];
                this.dragList = this.modalStore.array
            }

            if (this.storeMenuItems) {
                try {
                    const res = await axios.get(`/menu/${this.Local.id}/complete`);
                    let itemSelect = [];
                    let inv = res.data
                    let aux = []
                    inv.forEach(element => {
                        if(!element.invisible)
                            aux.push(...element['items'])
                    });

                    aux.forEach(item => {
                        if(!item.invisible){
                            itemSelect.push({
                                text: `${item.title}`,
                                value: { dataitem: item },
                            });
                        }
                    })
                    
                    this.storeMenuItems = itemSelect;
                    this.Response(res);
                } catch (err) {
                    this.Response(err);
                }
            }
        },
        AddToItemsSelection(data) {
            // Buscar si el elemento entrante ya existe en el array
            const itemExists = this.modalStore.array.find(item => item.id === data.dataitem.id);
            
            // Si el elemento existe, retornar mensaje
            if (itemExists) {
                return this.Info("El ítem ya se encuentra agregado en la lista.");
            }
            
            // Agregar el nuevo ítem al array
            this.modalStore.array.push({id : data.dataitem.id, title : data.dataitem.title});
        },
        SaveItemsSelection(){
            if(this.modalStore.modal == 'TP') this.settings.products_top_items = this.modalStore.array
            if(this.modalStore.modal == 'RM') this.settings.products_recommend = this.modalStore.array
            this.modalStore = null
        }
    },
    computed: {
        availableMethods() {
            if(!this.methods) return [];
            var ret = [];
            ret.push('ONLINE');
            ret.push('BANK_DEPOSIT');
            ret.push('CASH');
            //if(this.methods.accept_cash) ret.push('CASH');
            this.methods.payment_methods.forEach(method => {
                if(!method.pending_payment) ret.push(method.title);
            });
            return ret;
        }
    }
}
</script>

<style lang="scss">

</style>