<template>
<div class="fill">
    <tabs v-if="tabs" v-model="tab" :tabs="tabs" :keyByTab="true" @OnTabMapa="LoadMapLocals" class="fill">

        <div class="opt r">
            <input-field inputClass="secondary" :icon="mobile?'search':''" useHeader
            pre="search"
            cleareable
            v-show="tab=='Locales'"
            v-model="search"
            :timeout="500"
            @OnChange="LoadLocals(true)"
            placeholder="Buscar...">
            </input-field>

            <input-select class="ml5" inputClass="secondary" :icon="mobile?'sort':''"
            v-show="tab == 'Locales'"
            v-model="sort"
            :items="[
                {text: 'Plan ↓', value: 'plan_price,desc'},
                {text: 'Plan ↑', value: 'plan_price,asc'},
                {text: 'Creación ↓', value: 'created_at,desc'},
                {text: 'Creación ↑', value: 'created_at,asc'},
                {text: 'Nombre ↓', value: 'name,desc'},
                {text: 'Nombre ↑', value: 'name,asc'},
            ]">
            </input-select>
            
            <button @click="$set(filterAdvanced, 'open', true)" class="options ml5"><i class="icon icon-filter"></i></button>

            <i class="options icon-dots-vertical ml5" @click="Options([
                {text: 'Descargar', if: !!Auth.influye_admin, function: () => {DownloadLocalsList()}},
                {text: 'Nueva Franquicia', class: 'selected-color', function: () => {modalCreate={type:'FRANCHISE'}}},
                {text: 'Nuevo Local', class: 'selected-color', function: () => {modalCreate={type:'LOCAL'}}},
            ], $event)"></i>
        </div>

        <vertical-content slot="Locales" class="pa-body" @OnBottom="LoadLocals()" :list="locals">
            <div class="">

                <div v-if="filter == 'all' && search == '' && sort == 'plan_price,desc'">
                    <div class="section mb10 selectable"
                    v-for="(local, index) in pinnedLocals"
                    :key="`pinned-${index}`"
                    @click="GoToLocal(local)">
                        <div class="flex fill align-center">
                            <div class="flex align-center">
                                <avatar :image="'logos/'+local.logotype" class="mr15"
                                :secondaryIcon="LocalStatus(local).icon" :color="LocalStatus(local).color" :border="!!LocalStatus(local).icon"></avatar>
                                <div class="flex column">
                                    <div class="c2 bold"><i :class="`icon icon-${lastLocals.indexOf(local.id) > -1 ? 'clock cblue' : 'star cyellow'}`"></i> {{local.name}} <span class="tag ml5" v-if="local.type == 'FRANCHISE'">Franquicia</span> <span class="tag ml5" style="background: var(--blue-2)" v-if="local.server != 'DEFAULT'">{{local.server}}</span></div>
                                    <div class="secondary-text">
                                        <span class="bold">Plan: </span><span>{{Util.Plans[local.plan] ? Util.Plans[local.plan].title : local.plan}}</span>
                                        <span v-if="local.plan_status == 'DEMO'"> (Demo termina el {{Util.Date(local.plan_expiration, 'DD/MM/YYYY')}})</span>
                                        <span v-else-if="local.plan_expiration && local.plan_price"> ({{Util.Date(local.plan_expiration, 'DD/MM/YYYY')}})</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-fill"></div>
                            <div v-if="local.plan_price" class="bold unshrink">{{Util.Number(local.plan_price)}} UF</div>
                            <div class="options icon-dots-vertical" @click.stop="() => {OpenLocalOptions(local)}"></div>
                        </div>
                    </div>
                    <div class="separator mb20 mt20" v-if="pinnedLocals.length"></div>
                </div>

                <div class="section mb10 selectable"
                v-for="(local, index) in locals"
                :key="index"
                @click="GoToLocal(local)">
                    <div class="flex fill align-center">
                        <div class="flex align-center">
                            <!--
                            <div class="mr10" style="width: 60px; height: 60px; padding: 5px">
                                <div class="contain-img round" :style="{'background-image': img(`logos/${local.logotype}`, true)}"></div>
                            </div>
                            -->
                            <avatar :image="'logos/'+local.logotype" class="mr15"
                            :secondaryIcon="LocalStatus(local).icon" :color="LocalStatus(local).color" :border="!!LocalStatus(local).icon"></avatar>
                            <div class="flex column">
                                <div class="c2 bold">{{local.name}} <span class="tag ml5" v-if="local.type == 'FRANCHISE'">Franquicia</span> <span class="tag ml5" style="background: var(--blue-2)" v-if="local.server != 'DEFAULT'">{{local.server}}</span></div>
                                <div class="secondary-text"><span class="bold">Creado:</span> {{Util.Date(local.created_at)}}</div>
                                <div class="secondary-text">
                                    <span class="bold">Plan: </span><span>{{Util.Plans[local.plan] ? Util.Plans[local.plan].title : local.plan}}</span>
                                    <span v-if="local.plan_status == 'DEMO'"> (Demo termina el {{Util.Date(local.plan_expiration, 'DD/MM/YYYY')}})</span>
                                    <span v-else-if="local.plan_expiration && local.plan_price"> ({{local.plan_status == 'PAID' ? 'próxima facturación ' : 'expiró el '}}{{Util.Date(local.plan_expiration, 'DD/MM/YYYY')}})</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-fill"></div>
                        <div v-if="local.plan_price" class="bold unshrink">{{Util.Number(local.plan_price)}} UF</div>
                        <div class="options icon-dots-vertical" @click.stop="() => {OpenLocalOptions(local)}"></div>
                    </div>
                </div>
            </div>

        </vertical-content>

        <div slot="Mapa" class="fill flex">
            <div :class="`ofy pa-body ${desktop ? 'w50' : 'fill'}`" v-if="mapLocals" v-show="desktop || (mapLocals && mapLocals.showList)">
                <div v-if="mapLocals.all" class="secondary-text well mb10 selectable flex align-center" @click="SearchByIds(mapLocals.all)">
                    <div class="flex-fill">{{mapLocals.all.length}} resultados</div>
                    <i class="options icon-cross" v-if="mobile" @click.stop="$set(mapLocals, 'showList', false)"></i>
                </div>


                <div class="section mb10 selectable"
                v-for="(local, index) in mapLocals.selectedCluster"
                :key="index"
                @click="GoToLocal(local)">
                    <div class="flex fill align-center">
                        <div class="flex align-center">
                            <avatar :image="'logos/'+local.logotype" class="mr15"
                            :secondaryIcon="LocalStatus(local).icon" :color="LocalStatus(local).color" :border="!!LocalStatus(local).icon"></avatar>
                            <div class="flex column">
                                <div class="c2 bold">{{local.name}} <span class="tag ml5" v-if="local.type == 'FRANCHISE'">Franquicia</span> <span class="tag ml5" style="background: var(--blue-2)" v-if="local.server != 'DEFAULT'">{{local.server}}</span></div>
                                <div class="secondary-text"><span class="bold">Creado:</span> {{Util.Date(local.created_at)}}</div>
                                <div class="secondary-text">
                                    <span class="bold">Plan: </span><span>{{Util.Plans[local.plan] ? Util.Plans[local.plan].title : local.plan}}</span>
                                    <span v-if="local.plan_status == 'DEMO'"> (Demo termina el {{Util.Date(local.plan_expiration, 'DD/MM/YYYY')}})</span>
                                    <span v-else-if="local.plan_expiration && local.plan_price"> ({{local.plan_status == 'PAID' ? 'próxima facturación ' : 'expiró el '}}{{Util.Date(local.plan_expiration, 'DD/MM/YYYY')}})</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-fill"></div>
                        <div v-if="local.plan_price" class="bold unshrink">{{Util.Number(local.plan_price)}} UF</div>
                        <!--<div class="options icon-dots-vertical" @click.stop="() => {OpenLocalOptions(local)}"></div>-->
                    </div>
                </div>

            </div>
            <div :class="desktop ? 'w50' : 'fill'" v-show="desktop || (mapLocals && !mapLocals.showList)">
                <div class="fill" style="overflow: hidden">
                    <map-component v-if="mapLocals" ref="map" class="animated fadeIn"
                    @OnNavigationEnd="LoadMapLocals"
                    canSearch
                    :view="{lat:-33.45694, lon: -70.64827, zoom: 3}">
                    </map-component>
                </div>
            </div>
        </div>

    </tabs>

    <modal v-model="modalCreate"  :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalCreate?modalTitles[modalCreate.type]:''">
        <div class="pa20" v-if="modalCreate">
            
            <avatar v-if="modalCreate.local" :title="modalCreate.local.name" :image="'logos/'+modalCreate.local.logotype" class="mb15"></avatar>
            
            <input-field
            v-model="modalCreate.name"
            label="Nombre">
            </input-field>

            <input-field class="mt15" v-if="modalCreate.type == 'LOCAL' || modalCreate.type == 'FRANCHISE'"
            v-model="modalCreate.email"
            label="Email"
            description="Se enviarán las notificaciones de facturación a este correo"
            placeholder="(Opcional)">
            </input-field>
            
            
        </div>
        <div slot="actions" v-if="modalCreate">
            <button v-if="modalCreate.type == 'LOCAL' || modalCreate.type == 'FRANCHISE'" @click="Save()" class="primary purple">Crear</button>
            <button v-if="modalCreate.type == 'RENAME'" @click="Rename()" class="primary purple">Renombrar</button>
            <button v-if="modalCreate.type == 'CLONE'" @click="Confirm({
                title: '¿Clonar local? Se copiarán todas las configuraciones del local menos las cuentas de usuario.',
                text: 'Clonar',
                function: () => {Clone()}
            })" class="primary purple">Clonar</button>
        </div>
    </modal>

    <modal v-model="modalPlan" :dir="['right', 'right']" title="Editar plan" :modalClass="['fill', 'md right h100']">
        <div class="section" v-if="modalPlan">
            
            <avatar :title="modalPlan.name" :image="'logos/'+modalPlan.logotype" class="mb15"></avatar>

            <input-select class="mb15"
            label="Plan"
            v-model="modalPlan.plan"
            :items="plans">
            </input-select>

            <div class="bold mb10">Módulos</div>

            <label class="mb5" v-if="!isNewPlanSelected">
                <input type="checkbox" v-model="modalPlan.module_store"> Influye Store <div class="checkmark"></div>
            </label>
            <label class="mb5" v-if="modalPlan.module_store && !isNewPlanSelected">
                <input type="checkbox" v-model="modalPlan.module_store_plus"> Influye Store Plus <div class="checkmark"></div>
            </label>
            <label class="mb5">
                <input type="checkbox" v-model="modalPlan.module_sii_boleta"> Boleta Electrónica <div class="checkmark"></div>
            </label>
            <label class="mb5">
                <input type="checkbox" v-model="modalPlan.module_sii_factura"> Factura Electrónica <div class="checkmark"></div>
            </label>
            <label class="mb5" v-if="!isNewPlanSelected">
                <input type="checkbox" v-model="modalPlan.module_service"> Influye Service <div class="checkmark"></div>
            </label>
            <label class="mb5">
                <input type="checkbox" v-model="modalPlan.module_virtual_menu"> Menú Virtual <div class="checkmark"></div>
            </label>
            <label class="mb5" v-if="!isNewPlanSelected">
                <input type="checkbox" v-model="modalPlan.module_expenses"> Gastos <div class="checkmark"></div>
            </label>
            <label class="mb5">
                <input type="checkbox" v-model="modalPlan.module_inventory"> Inventario <div class="checkmark"></div>
            </label>
            <label class="mb5" v-if="!isNewPlanSelected">
                <input type="checkbox" v-model="modalPlan.module_reports"> Reportes y Alertas <div class="checkmark"></div>
            </label>
            <label class="mb5" v-if="!isNewPlanSelected">
                <input type="checkbox" v-model="modalPlan.module_transaction_stats"> Estadísticas Transacciones <div class="checkmark"></div>
            </label>
            <label class="mb5">
                <input type="checkbox" v-model="modalPlan.module_tables"> Mesas <div class="checkmark"></div>
            </label>
            <label class="mb5" v-if="!isNewPlanSelected">
                <input type="checkbox" v-model="modalPlan.module_barcode"> Lector Código de Barra <div class="checkmark"></div>
            </label>
            <label class="mb5">
                <input type="checkbox" v-model="modalPlan.module_pya"> Integración PedidosYa <div class="checkmark"></div>
            </label>
            <label>
                <input type="checkbox" v-model="modalPlan.module_pya_courier"> Integración PedidosYa Courier <div class="checkmark"></div>
            </label>

            <!--
            <div class="well mt20 secondary-text">
                Valor Plan {{Util.Number(planValue)}} UF
            </div>
            -->

            <div class="separator mb20 mt20"></div>

            <input-field class="mb15"
            label="Precio"
            :description="`Valor UF: $${Util.Number(modalPlan.uf)}`"
            type="float+"
            suffix=" UF"
            v-model="modalPlan.plan_price">
                <div v-if="modalPlan.plan_price" class="append" slot="after">{{Util.Price(modalPlan.uf * modalPlan.plan_price)}}</div>
            </input-field>
            
            <input-select class="mb15"
            v-model="modalPlan.plan_status"
            label="Estado"
            :items="planStatus">
            </input-select>

            <input-date
            label="Fecha Expiración"
            type="DATE"
            format="timestamp"
            v-model="modalPlan.plan_expiration">
            </input-date>


        </div>
        
        <button slot="actions" class="primary purple" @click="SavePlan()">Editar plan</button>
        
    </modal>

    <modal v-model="modalSale" title="Registrar Venta" :dir="['right', 'center']" :modalClass="['fill', 'sm']">
        <div class="pa20" v-if="modalSale">
            
            
            <avatar :title="modalSale.local.name" :image="'logos/'+modalSale.local.logotype" class="mb15"></avatar>

            <input-date v-model="modalSale.date" class="mb15"
            label="Fecha"
            format="timestamp">
            </input-date>

            <!--
            <div class="hdvm">
                <label class="input-style" v-show="!modalSale.is_subscription_only">
                    <input type="checkbox" v-model="modalSale.is_sellup"> Sell-up <div class="checkmark"></div>
                </label>

                <label class="input-style" v-show="!modalSale.is_sellup">
                    <input type="checkbox" v-model="modalSale.is_subscription_only"> Solo suscripción <div class="checkmark"></div>
                </label>
            </div>
            -->

            <input-field v-model="modalSale.plan" class="mb15"
            type="float+" :label="modalSale.source == 'SELLUP' ? 'Aumento del Plan (UF)' : 'Plan (UF)'"
            suffix=" UF">
            </input-field>

            <input-select v-model="modalSale.months" v-show="modalSale.source !== 'SELLUP'" class="mb20"
            :items="modalSale.source != 'SUBSCRIPTION' ? [{text: 'Mensual', value: 1}, {text: 'Trimestral', value: 3}, {text: 'Semestral', value: 6}, {text: 'Anual', value: 12}] : [{text: 'Trimestral', value: 3}, {text: 'Semestral', value: 6}, {text: 'Anual', value: 12}]"
            label="Tiempo suscripción">
            </input-select>

            <input-select v-model="modalSale.id_account" class="mb15"
            :items="accounts"
            label="Vendedor">
            </input-select>

            <input-select v-model="modalSale.source"
            :items="[{text: 'Venta propia', value: 'DIRECT'}, {text: 'Venta asistida', value: 'ASSISTED'}, {text: 'Sell-up', value: 'SELLUP'}, {text: 'Sólo Suscripción', value: 'SUBSCRIPTION'}]"
            label="Tipo venta">
            </input-select>

        </div>

        <div slot="actions">
            <button class="primary purple" @click="CreateSale()">Registrar Venta</button>
        </div>

    </modal>

    <modal v-model="modalBackup" title="Backup" :dir="['right', 'center']" :modalClass="['fill', 'sm']">
        <div v-if="modalBackup" class="pa20">

            <label class="mb5">
                <input type="checkbox" v-model="modalBackup.use_range"> Exportar transacciones dentro de un rango de fecha <div class="checkmark"></div>
            </label>

            <input-range v-show="modalBackup.use_range" v-model="modalBackup.range" label="Rango"></input-range>
        </div>
        <div slot="actions">
            <button v-if="modalBackup" class="primary purple" @click="Backup(modalBackup.local, modalBackup.use_range ? modalBackup.range : null)">Descargar</button>
        </div>
    </modal>

    <modal v-model="filterAdvanced.open" :dir="['up', 'right']" :modalClass="['w100 up', 'md right h100']" title="Filtro">
        <div class="pa20">

            <input-field class="mb10"
            v-if="tab == 'Mapa'"
            cleareable
            v-model="filterAdvanced.name"
            label="Nombre"
            placeholder="(Opcional)">
            </input-field>

            <input-select class="mb10"
            v-model="filterAdvanced.status"
            label="Estado"
            :items="[
                {text: 'Todos', value: 'all'},
                {text: 'Pagados', value: 'paid'},
                {text: 'No pagados', value: 'unpaid'},
                {text: 'Periodo de prueba', value: 'demo'},
            ]">
            </input-select>

            <input-select class="mb10"
            v-model="filterAdvanced.plan"
            label="Plan"
            :items="[
                {text: 'Todos', value: 'all'},
                {text: 'Plan POS Pro', value: 'pospro'},
                {text: 'Plan POS Pyme', value: 'pospyme'},
                {text: 'Plan Ecommerce', value: 'ecommerce'},
                {text: 'Plan Ecommerce Plus', value: 'ecommerceplus'},
                {text: 'Plan Pro', value: 'pro'},
                {text: 'Plan Pyme', value: 'pyme'},
                {text: 'Plan Gratis', value: 'free'},
            ]">
            </input-select>

            <input-select class="mb10"
            v-if="tab == 'Locales'"
            v-model="filterAdvanced.type"
            label="Tipo Local"
            :items="[
                {text: 'Todos', value: 'all'},
                {text: 'Locales', value: 'local'},
                {text: 'Franquicias', value: 'franchise'},
                //{text: 'Sub Local', value: 'sublocal'},
            ]">
            </input-select>

            <input-field class="mb10"
            v-if="tab == 'Locales'"
            cleareable
            v-model="filterAdvanced.location"
            label="Ciudad/Comuna/Región"
            placeholder="(Opcional)">
            </input-field>

            <div class="mb10 flex align-center" v-if="tab == 'Locales'">
                <label class="bold flex-fill mr20">
                    <input type="checkbox" v-model="filterAdvanced.map"> Ubicación
                    <div class="checkmark"></div>
                </label>
                <input-field v-show="filterAdvanced.map"
                v-model="filterAdvanced.radius"
                type="number+"
                suffix=" Km">
                </input-field>
            </div>
            <map-component style="height: 200px; border-radius: 10px" v-if="filterAdvanced.map" class="mb10"
            ref="map"
            v-model="filterAdvanced.coordinates"
            useMarker="location3"
            :canEditMarker="true"
            :canSearch="true"
            :initialZoom="7"
            @OnMarkerChanged="$set(filterAdvanced, 'coordinates', {latitude: $event.lat, longitude: $event.lng})">
            </map-component>

            <input-field class="mb10"
            cleareable
            v-model="filterAdvanced.segment"
            label="Segmento"
            placeholder="(Separados por coma. Opcional)">
            </input-field>

            <div class="mb10">
                <div class="bold mb5">Módulos</div>
                <label v-for="(value, key) in availableModules" class="mb5" :key="key">
                    <input type="checkbox" v-model="filterAdvanced.modules[key]"> {{value}}
                    <div class="checkmark"></div>
                </label>
            </div>                

            <input-field class="mb10"
            cleareable
            v-model="filterAdvanced.ids"
            label="IDs"
            placeholder="(Separados por coma. Opcional)">
            </input-field>
            
        </div>
        <button class="primary w100" @click="(tab == 'Mapa' ? LoadMapLocals() : LoadLocals(true)); $set(filterAdvanced, 'open', false)" slot="actions">Buscar</button>
    </modal>

</div>
</template>

<script>
export default {
    data() {
        return {
            tabs: null,
            tab: null,
            locals: [],
            franchises: [],
            modalCreate: null,
            modalNotification: null,
            search: '',
            modalPlan: null,
            PlanStatus: {
                PAID: 'Pagado',
                UNPAID: 'No Pagado'
            },
            sort: 'plan_price,desc',
            filter: 'all',
            modalSale: null,
            accounts: null,
            modalTitles: {
                LOCAL: 'Crear Local',
                FRANCHISE: 'Crear Franquicia',
                CLONE: 'Clonar Local',
                RENAME: 'Renombrar Local'
            },
            plans: [
                {text: 'Plan Demo', value: 'DEMO'},
                {text: 'Plan POS Pyme', value: 'POSPYME'},
                {text: 'Plan POS Pro', value: 'POSPRO'},
                {text: 'Plan Ecommerce', value: 'ECOMMERCE'},
                {text: 'Plan Ecommerce Plus', value: 'ECOMMERCEPLUS'},
                {text: 'Plan POS Pyme + Ecommerce', value: 'POSPYME+ECOMMERCE'},
                {text: 'Plan POS Pyme + Ecommerce Plus', value: 'POSPYME+ECOMMERCEPLUS'},
                {text: 'Plan POS Pro + Ecommerce', value: 'POSPRO+ECOMMERCE'},
                {text: 'Plan POS Pro + Ecommerce Plus', value: 'POSPRO+ECOMMERCEPLUS'},
                {text: '---------', separator: true},
                {text: 'Plan Gratis', value: 'FREE'},
                {text: 'Plan Pyme', value: 'PYME'},
                {text: 'Plan Pro', value: 'PRO'},
            ],
            planStatus: [
                {value: 'PAID', text: 'Pagado'},
                {value: 'UNPAID', text: 'No Pagado'},
                {text: 'Periodo Prueba', value: 'DEMO'}
            ],
            modalBackup: null,
            lastLocals: [],
            maxLastLocals: 3,
            pinnedLocalsIds: null,
            pinnedLocals: [],
            filterAdvanced: {
                name: '',
                location: '',
                segment: '',
                modules: {},
                map: false,
                coordinates: {latitude: -33.45694, longitude: -70.64827},
                radius: 10,
                ids: '',
                type: 'all',
                plan: 'all',
                status: 'all',
            },
            availableModules: {
                //module_pos: 'POS',
                module_virtual_menu: 'Menú Virtual',
                module_expenses: 'Gastos',
                module_inventory: 'Inventario',
                module_reports: 'Reportes y Alertas',
                module_transaction_stats: 'Estadísticas Transacciones',
                module_tables: 'Mesas',
                module_turns: 'Turnos',
                module_store: 'Influye Store',
                module_store_plus: 'Influye Store Plus',
                module_sii_boleta: 'SII Boleta',
                module_sii_factura: 'SII Factura',
                //module_service: 'Service',
                module_barcode: 'Codigo de barra',
                module_pya_courier: 'PYA Courier',
                module_pya: 'PYA Integración',

            },
            mapLocals: null,
        }
    },
    mounted() {
        this.Loading();

        this.lastLocals = localStorage.lastLocals ? JSON.parse(localStorage.lastLocals) : [];
        this.pinnedLocalsIds = localStorage.pinnedLocals ? JSON.parse(localStorage.pinnedLocals) : [];
        let pinned = JSON.parse(JSON.stringify(this.pinnedLocalsIds));
        if(this.lastLocals) pinned = this.lastLocals.concat(pinned);
        this.maxLastLocals = localStorage.maxLastLocals ? parseInt(localStorage.maxLastLocals) : 3;
        if(isNaN(this.maxLastLocals)) this.maxLastLocals = 3;
        localStorage.maxLastLocals = this.maxLastLocals;
        
        if(pinned.length)
        {
            axios.get(`/list/locals?ids=${JSON.stringify(pinned)}`).then(res => {

                res.data.sort((a, b) => {
                    let indexA = this.lastLocals.indexOf(a.id);
                    let indexB = this.lastLocals.indexOf(b.id);
                    return (indexA > -1 ? indexA : (a.id*10)) - (indexB > -1 ? indexB : (b.id*10));
                });

                this.pinnedLocals = res.data;
                console.log(res.data);
            }).catch(err => {
                console.log(err);
            });
        }

        this.tabs = this.Auth.influye_admin ? ['Locales', 'Mapa'] : ['Locales'];

    },
    methods: {
        LoadLocals(clear) {
            if(!this.sort) return;
            var parts = this.sort.split(',');
            //this.Loading();
            if(clear) this.locals = [];

            //advanced filters
            let filter2 = '';
            if(this.filterAdvanced.location.trim()) filter2 += `&location=${this.filterAdvanced.location.trim()}`;
            if(this.filterAdvanced.segment.trim()) filter2 += `&segment=${this.filterAdvanced.segment.trim()}`;
            let modules = '';
            for(let key in this.filterAdvanced.modules)
            {
                if(this.filterAdvanced.modules[key]) modules += `,${key}`;
            }
            modules = modules.substring(1);
            if(modules) filter2 += `&modules=${modules}`;

            if(this.filterAdvanced.ids.trim()) filter2 += `&ids=${this.filterAdvanced.ids.trim()}`;

            if(this.filterAdvanced.map)
            {
                filter2 += `&coordinates=${this.filterAdvanced.coordinates.latitude},${this.filterAdvanced.coordinates.longitude}&radius=${this.filterAdvanced.radius}`;
            }

            filter2 += `&type=${this.filterAdvanced.type}`;
            filter2 += `&status=${this.filterAdvanced.status}`;
            filter2 += `&plan=${this.filterAdvanced.plan}`;

            console.log(this.filterAdvanced);

            axios.get(`/list/locals?skip=${this.locals.length}&orderBy=${parts[0]}&orderDirection=${parts[1]}&search=${this.search}&filter=${this.filter}${filter2}`).then(res => {
                const totalLocals = this.locals.concat(res.data);

                this.locals = totalLocals.filter((local, index, self) =>
                    index == self.findIndex((tmpLocal) => (
                        tmpLocal.id == local.id
                    ))
                );

                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        GoToLocal(local) {
            this.Loading();
            axios.get(`/relogin/${local.id}`).then(res => {
                this.lastLocals = this.lastLocals.filter(el => el !== local.id);
                this.lastLocals.unshift(local.id);
                this.lastLocals = this.lastLocals.slice(0, this.maxLastLocals);
                localStorage.lastLocals = JSON.stringify(this.lastLocals);
                this.Response(res);
                this.$store.commit('setLocal', res.data);
                this.Util.SetServer(res.data.server, res.data.token);
                this.$router.push('/dashboard');
            }).catch(err => {
                this.Response(err);
            });
        },
        Save() {
            this.$root.$emit('Loading');
            axios.put('/local', this.modalCreate).then(res => {
                //Util.GoTo(res.data);
                this.modalCreate = null;
                this.locals.unshift(res.data);
                this.OpenModalPlan(res.data);
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        OpenLocalOptions(local) {
            this.Options([
                {text: 'Backup', class: 'csupport', icon: 'lifebuoy', function: () => {this.modalBackup = {local: local}}, if: this.Auth.role == 'admin'},
                {text: 'Pin', class: 'csupport', function: () => {this.PinLocal(local, true)}, if: !!(this.pinnedLocalsIds.indexOf(local.id) === -1)},
                {text: 'Unpin', class: 'csupport', function: () => {this.PinLocal(local, false)}, if: !!(this.pinnedLocalsIds.indexOf(local.id) > -1)},
                {text: 'Eliminar', class: 'error-color', function: () => {this.Delete(local.id)}, confirm: `¿Eliminar ${local.name} definitivamente?`, if: this.Auth.role == 'admin' && local.type === 'LOCAL'},
                {text: 'Reset', class: 'error-color', function: () => {this.Options([
                    {text: 'Eliminar Todo', function: () => {this.Reset('ALL', local)}, class: 'error-color'},
                    {text: 'Eliminar Transacciones', function: () => {this.Reset('TRANSACTIONS', local)}},
                    {text: 'Eliminar Transacciones de Gastos', function: () => {this.Reset('EXPENSES', local)}},
                    {text: 'Eliminar Transacciones de Inventario', function: () => {this.Reset('INVENTORY', local)}},
                    {text: 'Eliminar Transacciones de Pagos Pendientes', function: () => {this.Reset('PAYMENTS', local)}},
                    {text: 'Eliminar Turnos', function: () => {this.Reset('TURNS', local)}},
                    {text: 'Eliminar Clientes del Local', function: () => {this.Reset('CUSTOMERS', local)}},
                ], null, true)}, if: local.type === 'LOCAL'},
                //{text: 'Transferir', class: 'csupport', function: () => {Transfer(local)}, if: Auth.role == 'admin'},
                {text: 'Registrar Venta', function: () => {this.OpenModalSale(local)}, if: this.Auth.role == 'admin'},
                {text: 'Clonar', function: () => {this.modalCreate={local: local, type: 'CLONE'}}, if: local.type === 'LOCAL'},
                {text: 'Renombrar', function: () => {this.modalCreate={local: local, type: 'RENAME'}}},
                //{text: 'Enviar notificación', function: () => {modalNotification = {id_local: local.id}}},
                {text: 'Editar Plan', function: () => {this.OpenModalPlan(local)}},
            ]);
        },
        Delete(id) {
            this.$root.$emit('Loading');
            axios.delete(`/local/${id}`).then(res => {
                for(var i = 0; i < this.locals.length; i++)
                    if(this.locals[i].id == id) this.locals.splice(i, 1);

                for(var i = 0; i < this.pinnedLocals.length; i++)
                    if(this.pinnedLocals[i].id == id) this.pinnedLocals.splice(i, 1);

                for(var i = 0; i < this.pinnedLocalsIds.length; i++)
                    if(this.pinnedLocalsIds[i] == id) this.pinnedLocalsIds.splice(i, 1);
                localStorage.pinnedLocals = JSON.stringify(this.pinnedLocalsIds);
                if(this.lastLocals.indexOf(id) > -1) 
                {
                    this.lastLocals = this.lastLocals.filter(el => el !== id);
                    localStorage.lastLocals = JSON.stringify(this.lastLocals);
                }
                
                this.modalDelete = null;
                this.$root.$emit('Response', res);
            }).catch(err => {
                this.modalDelete = null;
                this.$root.$emit('Response', err);
            });
        },
        async Reset(content, local)
        {
            var msg = {
                'ALL': 'todos los datos',
                'TRANSACTIONS': 'todos los datos de transacciones',
                'EXPENSES': 'todos los datos de gastos',
                'INVENTORY': 'todos los datos de transacciones de inventario',
                'PAYMENTS': 'todos los datos de transacciones de pagos pendientes liquidados',
                'CUSTOMERS': 'todos los clientes del local',
                'TURNS': 'todos los turnos registrados del local',
            };

            this.Confirm({
                text: 'Eliminar',
                title: `¿Eliminar ${msg[content]} de ${local.name}?`,
                class: 'error-color',
                function: async () => {
                    this.Loading();

                    var axiosConn = axios;
                    if(local.server !== 'DEFAULT')
                    {
                        var token = (await axios.get(`/relogin/${local.server}/true`)).data;
                        var Axios = require('axios');
                        axiosConn = Axios.create({
                            baseURL: this.Util.ConnectionAddresses(local.server).backend+'admin/',
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                    }

                    axiosConn.get(`/local/${local.id}/reset/${content}`).then(res => {
                        this.Info('Datos eliminados correctamente');
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            })
        },
        Rename()
        {
            this.Loading();
            axios.patch(`/local/${this.modalCreate.local.id}/rename`, {name: this.modalCreate.name}).then(res => {
                this.modalCreate = null;
                for(var i = 0; i < this.locals.length; i++)
                {
                    if(this.locals[i].id == res.data.id)
                    {
                        this.$set(this.locals, i, res.data);
                        break;
                    }
                }
                for(var i = 0; i < this.pinnedLocals.length; i++)
                {
                    if(this.pinnedLocals[i].id == res.data.id)
                    {
                        this.$set(this.pinnedLocals, i, res.data);
                        break;
                    }
                }
                this.Info('Local renombrado');
            }).catch(err => {
                this.Response(err);
            });
        },
        Clone() 
        {
            this.Loading();
            axios.post(`/local/${this.modalCreate.local.id}/clone`, {name: this.modalCreate.name}).then(res => {
                this.modalCreate = null;
                this.locals.push(res.data);
                this.Info('Local clonado');
            }).catch(err => {
                this.Response(err);
            });
        },
        SavePlan()
        {
            this.Loading();
            axios.post(`/local/${this.modalPlan.id}/plan`, this.modalPlan).then(res => {
                var local = null;
                this.modalPlan = null;
                for(var i = 0; i < this.locals.length; i++)
                {
                    if(this.locals[i].id == res.data.id)
                    {
                        this.$set(this.locals, i, res.data);
                        local = this.locals[i];
                        break;
                    }
                }

                for(var i = 0; i < this.pinnedLocals.length; i++)
                {
                    if(this.pinnedLocals[i].id == res.data.id)
                    {
                        this.$set(this.pinnedLocals, i, res.data);
                        local = this.pinnedLocals[i];
                        break;
                    }
                }
                
                this.Response(res);
                this.Confirm({
                    text: 'Ir al local',
                    title: 'Plan actualizado',
                    function: () => {
                        this.GoToLocal(local);
                    }
                });
            }).catch(err => {
                this.Response(err);
            });
        },
        async OpenModalSale(local)
        {
            if(!this.accounts)
            {
                this.Loading();
                var accounts = (await axios.get(`/influye/accounts`)).data;
                var ret = [];
                accounts.forEach(account => {ret.push({text: account.display_name, value: account.id})});
                this.accounts = ret;
                this.Response({error: false});
            }
            this.modalSale = {
                id_local: local.id,
                local: local,
                plan: local.plan_price,
                months: 1,

            }
        },
        OpenModalPlan(local)
        {
            this.Loading();
            axios.get(`/local/${local.id}/plan`).then(res => {
                this.Response(res);
                res.data.logotype = local.logotype;
                res.data.name = local.name;
                this.modalPlan = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        CreateSale()
        {
            this.Loading();
            axios.post(`/influye/sale`, this.modalSale).then(res => {
                this.Info('Venta creada')
                this.modalSale = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        LocalStatus(local)
        {
            if(local.plan_status == 'DEMO') return {icon: 'clock', color: 'var(--yellow)'};
            if(local.plan_status == 'PAID' && local.plan_price) return {icon: 'check', color: 'var(--green)'};
            if(local.plan_status == 'UNPAID' && local.plan_price) return {icon: 'cross', color: 'var(--red)'};
            return {icon: null, color: null};
            //.icon.plan_price ? (local.plan_status == 'PAID' ? 'check' : 'cross') : ''" :color="local.plan_price ? (local.plan_status == 'PAID' ? 'var(--green)' : 'var(--red)') : ''" :border="!!local.plan_price"
        },
        DownloadLocalsList()
        {
            this.Loading();
            axios.get(`/influye/locals/export`, {responseType: 'blob'}).then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `locales_${Util.Today().date.replace(/\//g, '-')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        async Backup(local, range)
        {
            this.Loading();

            var axiosConn = axios;
            if(local.server !== 'DEFAULT')
            {
                var token = (await axios.get(`/relogin/${local.server}/true`)).data;
                var Axios = require('axios');
                axiosConn = Axios.create({
                    baseURL: this.Util.ConnectionAddresses(local.server).backend+'admin/',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }

            let query = '';
            if(range) query = `?start=${range.rangeStart}&finish=${range.rangeEnd}`;

            axiosConn.get(`/influye/backup/${local.id}${query}`, {responseType: 'blob'}).then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, this.Util.Filename(`backup_${local.name}`, 'sql', true));
            }).catch(err => {
                this.Response(err);
            });

        },
        Transfer(local){
            this.Loading();
            axios.get(`/influye/transfer/${local.id}`).then( ({data}) => {
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        PinLocal(local, add)
        {
            if(add && this.pinnedLocalsIds.indexOf(local.id) === -1)
            {
                this.pinnedLocalsIds.push(local.id);
                this.pinnedLocals.push(local);
            }

            if(!add)
            {
                let index = this.pinnedLocalsIds.indexOf(local.id);
                if(index > -1) this.pinnedLocalsIds.splice(index, 1);
                this.pinnedLocals = this.pinnedLocals.filter(el => el.id !== local.id);
            }

            localStorage.pinnedLocals = JSON.stringify(this.pinnedLocalsIds);
        },
        LoadMapLocals() {

            if(this.mapLocals == null)
            {
                setTimeout(() => {
                    this.mapLocals = {};
                    this.$nextTick(() => {
                        this.LoadMapLocals();
                    });
                }, 300);
                return;
            }

            let filter = '';
            if(this.filterAdvanced.name.trim()) filter += `&name=${this.filterAdvanced.name.trim()}`;
            if(this.filterAdvanced.segment.trim()) filter += `&segment=${this.filterAdvanced.segment.trim()}`;
            let modules = '';
            for(let key in this.filterAdvanced.modules)
            {
                if(this.filterAdvanced.modules[key]) modules += `,${key}`;
            }
            modules = modules.substring(1);
            if(modules) filter += `&modules=${modules}`;

            filter += `&status=${this.filterAdvanced.status}`;
            filter += `&plan=${this.filterAdvanced.plan}`;

            let position = this.$refs.map.CurrentPosition();

            filter += `&coordinates=${position.center.lat},${position.center.lng}&radius=${position.radius/1000}`;

            axios.get(`/map/locals?${filter}`).then(res => {
                console.log(res.data);
                
                let allLocals = [];
                res.data.forEach(cluster => {
                    let outline = 'background: var(--purple); ';
                    if(cluster.status == 'DEMO' || cluster.status == 'FREE') outline = 'background: var(--yellow); ';
                    else if(cluster.status == 'PAID') outline = 'background: var(--green); ';
                    else if(cluster.status == 'UNPAID') outline = 'background: var(--red); ';
                    cluster.icon = `<div style="width: 30px; height: 30px; margin-left: -15px; margin-top: -15px; border-radius: 50%; text-align: center; line-height: 30px; ${outline}">${cluster.ids.length > 1 ? cluster.ids.length : this.Util.Initials(cluster.tooltip)}</div>`
                    cluster.click = () => {
                        this.$set(this.mapLocals, 'showList', true);
                        this.SearchByIds(cluster.ids);
                    };
                    allLocals = allLocals.concat(cluster.ids);
                });
                this.mapLocals = {
                    list: res.data, 
                    all: allLocals, 
                    selectedCluster: this.mapLocals ? this.mapLocals.selectedCluster : []
                };
                this.$refs.map.UpdateMarkers(res.data, true);
                //this.$refs.map.UpdateMarkers(res.data.centers, false, false, true);
            }).catch(err => {
            
            });
        },
        SearchByIds(ids) 
        {
            this.Loading();
            axios.get(`/list/locals?ids=${ids.join(',')}`).then(res => {
                this.$set(this.mapLocals, 'selectedCluster', res.data);
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
    },
    computed: {
        planValue() {
            if(!this.modalPlan) return;
            var ret = 0;
            if(this.modalPlan.plan == 'PYME') ret+= 5;//ret += 0.7;
            if(this.modalPlan.plan == 'PRO') ret+=4;//ret += 1;
            if(this.modalPlan.module_virtual_menu) ret+= 1;
            if(this.modalPlan.module_expenses) ret+= 1;
            if(this.modalPlan.module_inventory) ret+= 1;
            if(this.modalPlan.module_reports) ret+= 1;
            if(this.modalPlan.module_transaction_stats) ret+= 1;
            if(this.modalPlan.module_tables) ret+= 1;
            if(this.modalPlan.module_store) ret+= 10;
            if(this.modalPlan.module_store_plus) ret+= 3;
            if(this.modalPlan.module_sii_boleta) ret+= 5;
            //pendiente module_sii_factura¿?
            if(this.modalPlan.module_service) ret+= this.modalPlan.plan == 'PRO' ? 0 : 5;
            return ret/10;
        },
        isNewPlanSelected() {
            if(!this.modalPlan) return false;
            return ['FREE', 'PYME', 'PRO'].indexOf(this.modalPlan.plan) == -1;
        },
    }, 
    watch: {
        sort: function(n, o) {
            this.LoadLocals(true);
        },
        filter: function(n, o) {
            this.LoadLocals(true);
        },
    }
}
</script>
