<template>
<div class="fill flex">
    
    <div class="fill flex" v-if="order" :style="{'font-size': fontSize+'px'}">
        <div class="h100 flex column br" style="width: 60%">

            <div class="flex-fill ofy pa20" ref="orderContent">
                <div>
                    <div class="entry"
                    v-for="(order, index) in items"
                    :key="index">
                        <div class="order-item">
                            <div v-if="!order.type">{{Util.Number(order.quantity)}}</div>
                            <div v-if="order.type == 'DELIVERY'"><i class="icon icon-delivery c3"></i></div>
                            <div v-if="order.type == 'LEY_REDONDEO'"><i class="icon icon-receipt c3"></i></div>
                            <div><span>{{order.title}} <span class="secondary-text" v-if="order.external">(Externo)</span></span> <!--<span class="secondary-text">({{Util.Price(order.price*order.quantity)}})</span>-->
                                <ul>
                                    <li :class="modifier.type" v-for="(modifier, index) in Util.ModifiersList(order)" :key="index">{{modifier.value}} <span class="secondary-text" v-if="modifier.price">{{Util.Price(modifier.price*order.quantity)}}</span></li>
                                    <!-- <li class="order-comment" v-if="order.comment">{{order.comment}}</li> -->
                                </ul>
                            </div>
                            <div>{{Util.Price(order.total_money)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            <div class="bt flex">
                <div class="br flex column flex-fill pa10">
                    <div class="flex">
                        <div class="flex-fill">Subtotal</div>
                        <div>$10.000</div>
                    </div>
                </div>
                <div class="flex-fill pa10">
                    <div>Total</div>
                    <div class="f18">$10.500</div>
                </div>
            </div>
            -->

        </div>

        <div class="h100 flex column flex-fill hover-listener">
            <div class="flex flex-fill relative align-center justify-center">
                <img v-if="!images.length && local" :src="local.logotype" style="width: 50%">
                <div v-for="(image, index) in images" :key="index">
                    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                        <div class="absolute fill trbl0 cover" 
                        v-show="index == imageDisplayIndex"
                        :style="{'background-image': `url('${image}')`}">
                        </div>
                    </transition>
                </div>
                
                <div class="absolute flex show-hover section pa10" style="top: 10px; right: 10px">
                    <i class="options icon-minus" @click="ChangeFontSize(-1)" v-tooltip="'Disminuir Tamaño de Letra'"></i>
                    <i class="options icon-plus ml5" @click="ChangeFontSize(1)" v-tooltip="'Aumentar Tamaño de Letra'"></i>
                </div>

            </div>
            <div class="pa20 flex column bt">
                <div class="flex">
                    <div class="flex-fill">Subtotal</div>
                    <div>{{Util.Price(order.subtotal)}}</div>
                </div>
                <div class="flex" v-if="order.delivery">
                    <div class="flex-fill">Costo de Reparto</div>
                    <div>{{Util.Price(order.delivery)}}</div>
                </div>
                <div class="flex" v-if="order.discount">
                    <div class="flex-fill">Descuento</div>
                    <div>- {{Util.Price(order.discount)}}</div>
                </div>
                <div class="flex" v-if="order.round">
                    <div class="flex-fill">Ley del Redondeo</div>
                    <div>{{Util.Price(order.round)}}</div>
                </div>
                <div class="flex" v-if="order.tips">
                    <div class="flex-fill">Propinas</div>
                    <div>{{Util.Price(order.tips)}}</div>
                </div>
                <div class="f18 flex">
                    <div class="flex-fill">Total</div>
                    <div>{{Util.Price(order.total)}}</div>
                </div>
                <div class="f18 flex" v-if="order.tips">
                    <div class="flex-fill">Total con Propinas</div>
                    <div>{{Util.Price(order.total_tips)}}</div>
                </div>
            </div>
        </div>

        <modal v-model="order.bankPaymentData" hideActions title="Datos Bancarios" :closeable="false">
            <div v-if="order.bankPaymentData" class="pa20 f18">
                <div><span class="bold">Nombre: </span>{{order.bankPaymentData.name}}</div>
                <div><span class="bold">RUT: </span>{{order.bankPaymentData.rut}}</div>
                <div><span class="bold">Banco: </span>{{order.bankPaymentData.bank}}</div>
                <div><span class="bold">Cuenta: </span>{{order.bankPaymentData.account}}</div>
                <div><span class="bold">Número Cuenta: </span>{{order.bankPaymentData.account_number}}</div>
                <div><span class="bold">Email: </span>{{order.bankPaymentData.email}}</div>
            </div>
        </modal>

        <modal v-model="order.onlinePaymentData" hideActions title="Link de Pago Online" :closeable="false">
            <div v-if="order.onlinePaymentData" class="pa20 ta-center">
    			<img :src="`${Util.backend}qr/${order.paymentUrl.replace(/\//g, '|')}`" style="max-height: 400px; height: 50vw">
            </div>
        </modal>

    </div>
    <div v-else class="fill flex column align-center justify-center">
        <div class="f18 bold">Bienvenid@ <span v-if="local">a {{local.name}}</span></div>
        <img v-if="local" :src="local.logotype" style="width: 200px" class="mt20">
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            local: null,
            order: null,
            timer: null,
            timerImages: null,
            timerAutoScroll: null,
            items: null,
            logo: null,
            images: [],
            imageDisplayIndex: 0,
            fontSize: 14,
            scrollData: {
                autoscroll: true,
                lastScrollValue: NaN,
                direction: true,
                step: 0.5,
                stayTime: 0,
                stay: false,
            },

        }
    },
    mounted() {

        if(localStorage.local) this.local = JSON.parse(localStorage.local);
        if(localStorage.customerscreenFont) this.fontSize = parseInt(localStorage.customerscreenFont);

        this.timer = setInterval(() => {
            this.order = localStorage.customerscreen ? JSON.parse(localStorage.customerscreen) : null; 
            console.log(this.order);
            if(this.order)
            {
                var images = [];
                if(this.order.local && this.order.local.logotype) this.logo = this.img('logos/'+this.order.local.logotype);

                this.items = Object.values(this.order.items).sort((a, b) => {
                    return a.timestamp - b.timestamp;
                });

                this.items.forEach(item => {
                    if(item.image && item.image !== 'NO_IMAGE.png') images.push(this.img('products/'+item.image));
                });

                this.images = images;
                if(this.imageDisplayIndex >= this.images.length) this.imageDisplayIndex = 0;

                if(this.order.onlinePaymentData) 
                {
                    this.$set(this.order, 'paymentUrl', process.env.NODE_ENV === 'production' ? `https://payment.influye.app/local/${this.transaction.code}` : `${this.Util.backend}webpay/pos/${this.transaction.code}`);
                }

            }

            
        }, 1000);
        this.timerImages = setInterval(() => {
            this.imageDisplayIndex = this.images.length ? (this.imageDisplayIndex + 1) % this.images.length : 0;
        }, 5000);
        this.timerAutoScroll = setInterval(() => {

            if(this.scrollData.stay)
            {
                this.scrollData.stayTime += 1;
                if(this.scrollData.stayTime > 500)
                {
                    this.scrollData.stay = false;
                    this.scrollData.direction = !this.scrollData.direction;
                    this.scrollData.stayTime = 0;
                }
            }

            var el = this.$refs.orderContent;
            if(el)
            {
                var currentHeight = el.scrollTop;

                el.scrollTo(0, this.scrollData.direction ? (currentHeight += this.scrollData.step) : (currentHeight -= this.scrollData.step))
                if (this.scrollData.lastScrollValue == currentHeight) 
                    this.scrollData.stay = true;
                    //this.scrollData.direction = !this.scrollData.direction;

                this.scrollData.lastScrollValue = currentHeight;

            }

        }, 1);
    },
    methods: {
        ChangeFontSize(val)
        {
            this.fontSize += val;
            if(this.fontSize < 8) this.fontSize = 8;
            else if(this.fontSize > 50) this.fontSize = 50;
            localStorage.customerscreenFont = this.fontSize;
        }
    },
    beforeDestroy() {
        if(this.timer) clearInterval(this.timer);
        if(this.timerImages) clearInterval(this.timerImages);
        if(this.timerAutoScroll) clearInterval(this.timerAutoScroll);
    }
}
</script>

<style lang="scss">
.cover {
    background-position: center;
    background-size: cover;
}
.hover-listener {
    .show-hover {
        opacity: 0;
    }
    &:hover {
        .show-hover {
            opacity: 1;
        }
    }
}
</style>