<template>
<div class="flex-fill fill flex column ofy">

    <div class="opt r" v-if="!Local.id_franchise">
        <input-field v-model="search" 
        v-show="tab == 'Menú'" 
        placeholder="Buscar..."
        pre="search"
        cleareable
        inputClass="secondary"
        :timeout="300"
        :icon="mobile ? 'search' : ''"
        useHeader>
        </input-field>

        <input-field v-model="searchOverwrite" 
        v-show="tab == 'Horarios'" 
        placeholder="Buscar item o modificador..."
        pre="search"
        cleareable
        inputClass="secondary"
        :timeout="300"
        :icon="mobile ? 'search' : ''"
        useHeader
        @OnChange="ToggleModifiersVisibility()">
        </input-field>

        <input-field v-model="searchOverwritePlatform" 
        v-show="tab == 'Plataformas'" 
        placeholder="Buscar item o modificador..."
        pre="search"
        cleareable
        inputClass="secondary"
        :timeout="300"
        :icon="mobile ? 'search' : ''"
        useHeader
        @OnChange="ToggleModifiersVisibility()">
        </input-field>

        <button slot="actions" class="ml10 primary purple" v-if="ItemsToUpdatePya && tab == 'Plataformas'" @click="OpenModalUpdateAvailabilityPya()">Guardar</button>
        <button slot="actions" class="ml10 secondary purple" v-if="ItemsToUpdatePya && tab == 'Plataformas'" @click="ItemsToUpdatePya = null">Cancelar</button>

        <!--
        <input-select class="ml5" 
        v-if="tab == 'Horarios' && schedulesItems.length"
        :items="schedulesItems" 
        v-model="selectedSchedule">
        </input-select>
        -->

        <input-field v-model="searchAvailability"  
        v-show="tab == 'Disponibilidad'" 
        placeholder="Buscar item o modificador..."
        pre="search"
        cleareable
        inputClass="secondary"
        :timeout="300"
        :icon="mobile ? 'search' : ''"
        :useHeader="mobile ? {right: '140px'} : false">
        </input-field>

        <input-field v-model="searchlanguage" 
        v-show="tab == 'Idiomas'" 
        placeholder="Buscar item o modificador..."
        pre="search"
        cleareable
        inputClass="secondary"
        :timeout="300"
        :icon="mobile ? 'search' : ''"
        useHeader>
        </input-field>

        <i title="Opciones" class="options icon-dots-vertical ml5" @click="Options([
            {text: 'Disponibilidad Items PedidosYa', class: 'selected-color', function: () => {ItemsToUpdatePya = {availability: {}, unavailable: {}, availabilityToppings: {}, unavailableToppings: {}}}, if: Local.modules.module_pya && tab == 'Plataformas' && selectedPlatform && selectedPlatform.type == 'pedidos_ya'},
            {text: 'Exportar Menú a PedidosYa', class: 'selected-color', function: () => {MenuPya()}, if: Local.modules.module_pya && tab == 'Menú' },
            {text: 'Limpiar Menú PedidosYa', class: 'error-color', function: () => {MenuPya(true)}, if: Local.modules.module_pya && tab == 'Menú' },
            { text: 'Respaldos', class: 'csupport', icon: 'lifebuoy', function: () => {OpenBackups()}, if: Auth.role == 'admin' && tab == 'Menú' },
            {text: 'Descartar Selección', class: 'error-color', function: () => {availabilityToggled = {}}, if: tab == 'Disponibilidad' && Object.values(availabilityToggled).length > 0},
            {text: 'Deseleccionar Modificadores', class: 'error-color', function: () => {SelectAll('MODIFIER', true)}, if: tab == 'Disponibilidad'},
            {text: 'Deseleccionar Items', class: 'error-color', function: () => {SelectAll('ITEM', true)}, if: tab == 'Disponibilidad'},
            {text: 'Deseleccionar Todo', class: 'error-color', function: () => {SelectAll('ALL', true)}, if: tab == 'Disponibilidad'},
            {text: 'Seleccionar Modificadores', class: 'selected-color', function: () => {SelectAll('MODIFIER', false)}, if: tab == 'Disponibilidad'},
            {text: 'Seleccionar Items', class: 'selected-color', function: () => {SelectAll('ITEM', false)}, if: tab == 'Disponibilidad'},
            {text: 'Seleccionar Todo', class: 'selected-color', function: () => {SelectAll('ALL', false)}, if: tab == 'Disponibilidad'},
            {text : 'Ordenar Menú', function : () => OpenModalOrderMenu(), if: tab == 'Menú'},
            {text: showImages ? 'Ocultar Imágenes' : 'Mostrar Imágenes', function: () => {showImages = Util.StoreLocal('menuShowImages', !showImages, true)}, if: tab == 'Menú'},
            {text: 'Exportar Menú', function: () => {ExportMenu()}, if: tab == 'Menú'},
            {text: 'Importar Menú', function: () => {modalImport = {}}, if: tab == 'Menú'},
            //{text: drag ? 'Terminar de Ordenar Secciones' : 'Ordenar Secciones', function: () => {drag = !drag}, if: tab == 'Menu'},
            {text: 'Carga Rápida de Menú', class: 'selected-color', function: () => {modalSimpleMenu = {items: []}}, if: tab == 'Menú'},
            {text: 'Nueva Sección', class: 'selected-color', function: () => {OpenNewSection()}, if: tab == 'Menú'},
            {text: 'Nuevo Horario', class: 'selected-color', function: () => {modalSchedule = {schedule: [], pos: true, ecommerce: true, virtual_menu: true, start: null, finish: null}}, if: tab == 'Horarios'},
            {text: 'Nueva plataforma', class: 'selected-color', function: () => {modalPlatform = {schedule: [], pos: true, ecommerce: true, virtual_menu: true}}, if: tab == 'Plataformas'},
            {text: 'Carga Rápida de Traducciones', class: 'selected-color', function: () => {OpenModalLoadFastMenu()}, if: tab == 'Idiomas' && languages.length>0},
            {text: 'Nuevo Idioma', class: 'selected-color', function: () => {OpenModalNewLanguage()}, if: tab == 'Idiomas'},
            ])"></i>

         <!--
        <button class="primary purple ml5" v-if="tab === 'Horarios' && selectedSchedule"
        @click="Confirm({
        text: 'Guardar',
        class: 'selected-color',
        title: `¿Guardar cambios del menú del horario ${selectedSchedule.label}?`,
        function: SaveMenuSchedule
        })">Guardar</button>
        -->

        <button class="primary purple ml5" v-if="tab === 'Disponibilidad' && Util.Arr(availabilityToggled).length"
        @click="Confirm({
            text: 'Aplicar Cambios',
            class: 'selected-color',
            title: `¿Aplicar ${Util.Arr(availabilityToggled).length} cambios?`,
            function: () => {ApplyAvailabilityChanges()}
        })">Aplicar <span v-if="desktop" style="margin-left: 3px"> cambios ({{Util.Arr(availabilityToggled).length}})</span></button>
        

    </div>
    
    <tabs v-model="tab" :tabs="tabs" v-if="menu" class="fill" :keyByTab="true" @OnTabHorarios="GetSchedules" @OnTabIdiomas="GetLanguages" @OnTabPlataformas="GetPlatforms"
    :tabCount="tabCount" tabCountOnSelectedOnly>

        <div slot="Menú" ref="container" class="pa-body pt10" v-if="tab == 'Menú'">
            <div class="ta-right sticky bgbg" v-if="Local.modules.module_pya">
                <button class="primary purple ml5" @click="Confirm({
                text: 'Confirmar',
                class: 'selected-color',
                title: `¿Desea sincronizar el menú Influye con PedidosYa?`,
                function: MenuPya
                })">Sincronizar con PedidosYa</button>
            </div>
            <div v-if="!visibleItems.length" class="secondary-text ta-center mt10">No hay items</div>
            <draggable
            :list="visibleItems"
            handle=".handle"
            :animation="200"
            group="sections"
            @start="inMotionSections = true"
            @end="inMotionSections = false"
            @change="OnMenuOrderChanged">
                
                <div ref="section" class="mb20"
                v-for="(section, index) in visibleItems"
                :key="section.title">
                    <div :class="`sticky bgbg flex align-center pt10 pb10 mb10`">
                        <i v-if="canEdit && !search" class="icon icon-drag-handle handle"></i>
                        <div class="flex-fill flex align-center">
                            <div class="flex column">
                                <div class="flex align-center">
                                    <span class="bold f18">{{section.title}} </span> 
                                    <span class="counter">{{section.items.length}}</span>
                                </div>
                                <div class="flex">
                                    <span class="menu-tag pos" v-if="!section.hide_pos"></span>
                                    <span class="menu-tag ec" v-if="!section.invisible"></span>
                                    <span class="menu-tag vm" v-if="!section.hide_virtual_menu"></span>
                                    <span class="menu-tag-round rs" v-if="!section.hide_takeaway"></span>
                                    <span class="menu-tag-round dl" v-if="!section.hide_delivery"></span>
                                </div>
                            </div>
                            
                        </div>
                        <div v-if="canEdit" class="options icon-dots-vertical" @click="Options([
                            {text: 'Eliminar Sección', class: 'error-color', function: () => {DeleteSection(section.title, section.sectionIndex)}, confirm: 'Eliminar una sección eliminara todos los items que contenga'},
                            {text: 'Editar Sección', function: () => {EditSection(section, section.sectionIndex)}},
                            {text: 'Nuevo Combo', class: 'selected-color', function: () => {NewItem(1, section.sectionIndex)}},
                            {text: 'Nuevo Producto', class: 'selected-color', function: () => {NewItem(0, section.sectionIndex)}},
                        ])"></div>
                    </div>

                    <draggable 
                    :list="section.items"
                    v-show="!inMotionSections"
                    handle=".handle"
                    :animation="200"
                    group="items"
                    @change="OnMenuOrderChanged($event, {title: section.title, index: index})">

                        <div
                        v-for="(item,index) in section.items"
                        :key="'menu-'+index+'-'+item.id">
                            <div :class="`section mb10 selectable ${highlightedItem == item.id ? 'glow' : ''}`"
                            :id="`menu-item-${item.id}`" style="scroll-margin-top: 70px">
                                <div class="flex fill align-center">

                                    <div v-if="showImages" class="relative flex justify-center align-center mr10 menu-entry-handle">
                                        <img v-if="item.image && item.image !== 'NO_IMAGE.png'" :src="img(`products/s${item.image}`)" class="fill">
						                <img v-else :src="img(`products/${dark ? 'sDARK_NO_IMAGE.png' : 'sNO_IMAGE.png'}`)" class="fill">
                                        <i v-if="canEdit && !search" class="absolute icon icon-drag-handle handle" style="padding: 0px"></i>
                                    </div>
                                    <div v-else class="relative flex justify-center align-center mr20">
                                        <i v-if="canEdit && !search" class="absolute icon icon-drag-handle handle" style="padding: 0px"></i>
                                    </div>
                                    
                                    <div class="flex-fill flex column"
                                    @click="ShowStats(item)">
                                        <div class="flex column">
                                            <div class="bold">{{item.title}}</div>
                                            <div class="flex">
                                                <span class="menu-tag pos" v-if="!section.hide_pos && !item.hide_pos"></span>
                                                <span class="menu-tag ec" v-if="!section.invisible && !item.invisible"></span>
                                                <span class="menu-tag vm" v-if="!section.hide_virtual_menu && !item.hide_virtual_menu"></span>
                                                <span class="menu-tag-round rs" v-if="item.type == 0 && !section.hide_takeaway && !item.hide_takeaway"></span>
                                                <span class="menu-tag-round dl" v-if="item.type == 0 && !section.hide_delivery && !item.hide_delivery"></span>
                                            </div>
                                        </div>
                                        <div class="secondary-text ellipsis">{{item.description}}</div>
                                    </div>
                                    
                                    <i v-if="item.type == 1 && IsComboIncomplete(item)" class="icon icon-circle-exclamation cyellow mr5" v-tooltip="'Este combo contiene items que no se encuentran en el menú actualmente y se ocultará al hacer pedidos'"></i>
                                    <i v-else-if="item.unavailable" class="icon icon-forbidden mr5 cred"></i>

                                    <div class="bold ta-center mr10">{{Util.Price(item.price)}}</div>
                                    <div v-if="canEdit" class="options icon-dots-vertical" @click.stop="() => {Options([
                                        {text: 'Eliminar', class: 'error-color', function: () => {DeleteItem(item)}, confirm: `¿Eliminar item definitivamente del menú? Los combos que contengan este item igual serán eliminados.`},
                                        {text: 'Duplicar', function: () => {DuplicateItem(item)}, confirm: '¿Duplicar item?'},
                                        {text: 'Editar', function: () => {EditItem(item)}}
                                    ])}"></div>

                                </div>
                            </div>
                        </div>

                    </draggable>

                </div>
                
            </draggable>
        </div>

        <div slot="Horarios" class="pa-body pt0" v-if="tab == 'Horarios'">
            <div v-if="schedules && schedules.length">
                <div class="flex sticky bgbg pb20 pt20" v-if="schedules && schedules.length">

                    <div class="flex flex-fill column-mobile">

                        <input-select class=""
                        :items="schedulesItems" 
                        v-model="selectedSchedule">
                        </input-select>

                        <div :class="`flex column ${desktop ? 'ml10' : 'mt5 ml5'}`" v-if="selectedSchedule">
                            <div class="secondary-text" v-show="desktop">{{selectedSchedule.label}}</div>
                            <div class="secondary-text" v-show="desktop && (selectedSchedule.start || selectedSchedule.finish)"> Vigencia: {{selectedSchedule.validity}}</div>
                            <div class="flex align-center">
                                <div v-if="selectedSchedule.pos" class="menu-tag pos ta-center pa5" style="height: 10px; width: 10px"></div>
                                <div v-if="selectedSchedule.ecommerce" class="menu-tag ec ta-center pa5" style="height: auto; width: 10px"></div>
                                <div v-if="selectedSchedule.virtual_menu" class="menu-tag vm ta-center pa5" style="height: auto; width: 10px"></div>
                            </div>
                        </div>

                    </div>

                    <i class="options icon-dots-vertical ml5" @click="Options([
                        {text: 'Eliminar Horario', class: 'error-color', function: DeleteSchedule},
                        {text: 'Añadir regla', function: () => {OpenOverwriteEntry(selectedSchedule)}},
                        {text: 'Editar Horario', function: () => {modalSchedule = Util.Copy(selectedSchedule)}},
                    ])" v-if="selectedSchedule"></i>

                    <button class="primary purple ml5" v-if="selectedSchedule"
                    @click="Confirm({
                    text: 'Guardar',
                    class: 'selected-color',
                    title: `¿Guardar cambios del menú del horario ${selectedSchedule.label}?`,
                    function: SaveMenuSchedule
                    })">Guardar</button>

                </div>
                
                <div v-if="selectedSchedule">
                    <div class="mb20"
                    v-for="(section, si) in visibleItemsAvailable" :key="`s${si}`">
                        <div class="flex align-center">
                            <div class="flex-fill f18 mb20">{{section.title}}</div>
                            <i class="icon icon-dots-vertical ml5 selectable" @click.stop="OpenOverwriteEntry(section, section.id)"></i>
                        </div>
                        <div class="section mb10"
                        v-for="(item, i) in section.items" :key="`s${si}i${i}`">
                            <div class="fill flex column">
                                <div class="flex align-center selectable" @click.stop="OpenOverwriteEntry(item, item.id)">
                                    <!--<div style="flex-shrink: 0" :class="`check mr5 ${selectedSchedule.overwrites[item.id + '-unavailable'] ? '' : 'selected'}`" @click="$set(selectedSchedule.overwrites, item.id + '-unavailable', !selectedSchedule.overwrites[item.id + '-unavailable'])"></div>-->
                                    <i v-if="item.modifiers.length" :class="`icon icon-arrow-${!showModifiers['schedules'][item.id]? 'right' : 'down'} mr15 selectable`" @click.stop="ToggleModifiersVisibility(item)"></i>
                                    <div class="flex-fill"><span class="bold">{{item.title}}</span></div>
                                    <!--
                                    <input-select v-if="selectedSchedule.overwrites[item.id + '-overwrite']" v-model="selectedSchedule.overwrites[item.id + '-overwrite']" :items="overwriteOptions"></input-select>
                                    <input-field v-if="['PRICE', 'PRICE_REDUCTION', 'DISCOUNT'].indexOf(selectedSchedule.overwrites[item.id + '-overwrite']) > -1" type="number" class="ml5" v-model="selectedSchedule.overwrites[item.id + '-value']" style="width: 100px"></input-field>
                                    -->
                                    <div v-html="OverwrittenPrice(item, item.id)"></div>
                                    <i class="icon icon-dots-vertical ml5 selectable"></i>
                                </div>
                                <div v-if="showModifiers['schedules'][item.id]">
                                    <div class="flex column mt20" 
                                    v-for="(group, gi) in item.modifiers" :key="`s${si}i${i}g${gi}`">
                                        <div class="flex-fill bold secondary-text mb10"><i class="icon icon-link cgray1" v-show="group.id"></i> {{group.title}}</div>
                                        <div class="flex column" style="flex-wrap: wrap;">
                                            <div class="entry flex align-center fill selectable" @click="OpenOverwriteEntry(modifier, `${item.id}-${group.title}-${modifier.title}`, modifier.id || group.id? {modifier, group, item, section, isReplacement: false} : null)"
                                            v-for="(modifier, mi) in group.modifiers" :key="`s${si}i${i}g${gi}m${mi}`">
                                                <div class="flex-fill"><i class="icon icon-link cgray1" v-show="modifier.id|| group.id"></i> {{modifier.title}}</div>
                                                <div v-html="OverwrittenPrice(modifier, `${item.id}-${group.title}-${modifier.title}`)"></div>
                                                <i class="icon icon-dots-vertical pl10 selectable"></i>
                                            </div>
                                        </div>

                                        <div class="flex column mt10" v-if="group.modifiers_replacement && group.modifiers_replacement.length">
                                            <div class="bold secondary-text mb10">(Reemplazos) {{group.title}}</div>
                                            <div>
                                                <div class="entry flex selectable" @click="OpenOverwriteEntry(modifier, `${item.id}-${group.title}-${modifier.title}-r`, modifier.id || group.id? {modifier, group, item, section, isReplacement: true} : null)"
                                                v-for="(modifier, mi) in (group.modifiers_replacement ? group.modifiers_replacement : [])" :key="`s${si}i${i}g${gi}m${mi}-r`">
                                                    <div class="flex-fill"><i class="icon icon-link cgray1" v-show="modifier.id || group.id"></i> (Reemplazo) {{modifier.title}}</div>
                                                    <div v-html="OverwrittenPrice(modifier, `${item.id}-${group.title}-${modifier.title}-r`)"></div>
                                                    <i class="icon icon-dots-vertical pl10 selectable"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text ta-center mt10">No hay horarios especiales</div>
            
        </div>

        <div slot="Plataformas" class="pa-body pt0" v-if="tab == 'Plataformas'">
            <div v-if="platforms && platforms.length">
                <div class="flex sticky bgbg pb20 pt20" v-if="platforms && platforms.length">
                    <div class="flex flex-fill column-mobile">
                        <input-select class=""
                        :items="platformsItems" 
                        v-model="selectedPlatform">
                        </input-select>
                    </div>

                    <i class="options icon-dots-vertical ml5" @click="Options([
                        {text: 'Eliminar Plataforma', class: 'error-color', function: DeleteSchedule},
                        {text: 'Añadir regla', function: () => {OpenOverwriteEntry(selectedPlatform)}},
                    ])" v-if="selectedPlatform"></i>

                    <button class="primary purple ml5" v-if="selectedPlatform"
                    @click="Confirm({
                    text: 'Guardar',
                    class: 'selected-color',
                    title: `¿Guardar cambios del menú de la plataforma ${selectedPlatform.label}?`,
                    function: SaveMenuSchedule
                    })">Guardar</button>
                </div>

                <div v-if="selectedPlatform">
                    <div class="mb20"
                    v-for="(section, si) in visibleItemsAvailable" :key="`s${si}`">
                        <div class="flex align-center">
                            <div class="flex-fill f18 mb20">{{section.title}}</div>
                            <i class="icon icon-dots-vertical ml5 selectable" @click.stop="OpenOverwriteEntry(section, section.id)"></i>
                        </div>
                        <div class="section mb10"
                        v-for="(item, i) in section.items" :key="`s${si}i${i}`">
                            <div class="fill flex column">
                                <div class="flex align-center selectable" @click.stop="OpenOverwriteEntry(item, item.id)">
                                    <i v-if="item.modifiers.length" :class="`icon icon-arrow-${!showModifiers['platforms'][item.id]? 'right' : 'down'} mr15 selectable`" @click.stop="ToggleModifiersVisibility(item)"></i>
                                    <div class="flex-fill"><span class="bold">{{item.title}}</span></div>
                                    <div v-html="OverwrittenPrice(item, item.id)"></div>
                                    <i class="icon icon-dots-vertical ml5 selectable"></i>

                                    <label class="mb10 mt10 ml10" v-if="ItemsToUpdatePya" @click.stop>
                                        <input 
                                            type="checkbox" 
                                            @change.stop="ToggleItemInPya('item', item, item.id)"
                                        >
                                        <div class="checkmark"></div>
                                    </label>
                                </div>
                                <div v-if="showModifiers['platforms'][item.id]">
                                    <div class="flex column mt20"
                                    v-for="(group, gi) in item.modifiers" :key="`s${si}i${i}g${gi}`">
                                        <div class="flex-fill bold secondary-text mb10"><i class="icon icon-link cgray1" v-show="group.id"></i> {{group.title}}</div>
                                        <div class="flex column" style="flex-wrap: wrap;">
                                            <div class="entry flex align-center fill selectable" @click="OpenOverwriteEntry(modifier, `${item.id}-${group.title}-${modifier.title}`, modifier.id || group.id? {modifier, group, item, section, isReplacement: false} : null)"
                                            v-for="(modifier, mi) in group.modifiers" :key="`s${si}i${i}g${gi}m${mi}`">
                                                <div class="flex-fill"><i class="icon icon-link cgray1" v-show="modifier.id || group.id"></i> {{modifier.title}}</div>
                                                <div v-html="OverwrittenPrice(modifier, `${item.id}-${group.title}-${modifier.title}`)"></div>
                                                <i class="icon icon-dots-vertical pl10 selectable"></i>
                                                <label class="mb10 mt10 ml10" v-if="ItemsToUpdatePya" @click.stop>
                                                    <input 
                                                        type="checkbox" 
                                                        @change="ToggleItemInPya('modifier', modifier, `${item.id}-${group.title}-${modifier.title}`)"
                                                        :checked="isModifierChecked(item.id, group.title, modifier.title)"
                                                    >
                                                    <div class="checkmark"></div>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="flex column mt10" v-if="group.modifiers_replacement && group.modifiers_replacement.length">
                                            <div class="bold secondary-text mb10">(Reemplazos) {{group.title}}</div>
                                            <div>
                                                <div class="entry flex selectable" @click="OpenOverwriteEntry(modifier, `${item.id}-${group.title}-${modifier.title}-r`, modifier.id || group.id? {modifier, group, item, section, isReplacement: true} : null)"
                                                v-for="(modifier, mi) in (group.modifiers_replacement ? group.modifiers_replacement : [])" :key="`s${si}i${i}g${gi}m${mi}-r`">
                                                    <div class="flex-fill"><i class="icon icon-link cgray1" v-show="modifier.id || group.id"></i> (Reemplazo) {{modifier.title}}</div>
                                                    <div v-html="OverwrittenPrice(modifier, `${item.id}-${group.title}-${modifier.title}-r`)"></div>
                                                    <i class="icon icon-dots-vertical pl10 selectable"></i>
                                                    <label class="mb10 mt10 ml10" v-if="ItemsToUpdatePya" @click.stop>
                                                        <input 
                                                            type="checkbox" 
                                                            @change="ToggleItemInPya('modifier', modifier, `${item.id}-${group.title}-${modifier.title}-r`)"
                                                        >
                                                        <div class="checkmark"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text ta-center mt10">No hay precios especiales para otras plataformas</div>
        </div>

        <div class="pa-body pt10" slot="Disponibilidad" v-if="tab == 'Disponibilidad'">
            <div class="secondary-text ta-center mt10" v-if="!visibleItemsAvailable.length">No hay items en esta búsqueda</div>
            <div class="mb20"
            v-for="(section, si) in visibleItemsAvailable" :key="`s${si}`">
                <div class="sticky mb10 pb10 pt10 bgbg bold"><div class="f18">{{section.title}}</div></div>
                <div class="section mb10"
                v-for="(item, i) in section.items" :key="`s${si}i${i}`">
                    <div class="fill flex column">
                        <div class="flex align-center selectable"
                        @click="ToggleAvailabilityItem(item, section)">
                            <div :class="`check mr5 ${(availabilityToggled[item.id] !== undefined ? !availabilityToggled[item.id].value : !item.unavailable) ? 'selected' : ''}`"></div>
                            <div class="bold">{{item.title}}</div>
                        </div>
                        <div class="flex column" style="padding-left: 30px"
                        v-for="(group, gi) in item.modifiers" :key="`s${si}i${i}g${gi}`">
                            <div class="secondary-text bold mt5 mb5"><i class="icon icon-link cgray1 mr5" v-show="group.id"></i>{{group.title}}</div>
                            <div class="flex column" style="flex-wrap: wrap;">
                                <div class="flex align-center f12 selectable fill mb5"
                                v-for="(modifier, mi) in group.modifiers" :key="`s${si}i${i}g${gi}m${mi}`"
                                @click="ToggleAvailabilityModifier(modifier, group, item, section)">
                                    <div style="flex-shrink: 0" :class="`check mr5 ${(availabilityToggled[item.id+group.title+modifier.title] !== undefined ? !availabilityToggled[item.id+group.title+modifier.title].value : !modifier.unavailable) ? 'selected' : ''}`"></div>
                                    <div><i class="icon icon-link cgray1 mr5" v-show="modifier.id || group.id"></i>{{modifier.title}}</div>
                                </div>
                                <div class="flex align-center secondary-text selectable fill mb5"
                                v-for="(modifier, mi) in group.modifiers_replacement" :key="`s${si}i${i}g${gi}m${mi}-r`"
                                @click="ToggleAvailabilityModifier(modifier, group, item, section, true)">
                                    <div style="flex-shrink: 0" :class="`check mr5 ${(availabilityToggled[item.id+group.title+modifier.title+'-r'] !== undefined ? !availabilityToggled[item.id+group.title+modifier.title+'-r'].value : !modifier.unavailable) ? 'selected' : ''}`"></div>
                                    <div><i class="icon icon-link cgray1 mr5" v-show="modifier.id"></i>(Reemplazo) {{modifier.title}}</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            

        </div>

        <div slot="Idiomas" class="pa-body pt0" v-if="tab == 'Idiomas'">

            <div v-if="languages && languages.length">
                <div class="flex sticky bgbg pb20 pt20" v-if="languages && languages.length">
                    <div class="flex flex-fill column-mobile">
                        <input-select
                        :items="languageItems" 
                        v-model="selectedLanguage">
                        </input-select>

                        <div v-if="selectedLanguage" class="flex align-center ml10">
                            <div v-if="selectedLanguage.pos" class="menu-tag pos ta-center pa5" style="height: 10px; width: 10px"></div>
                            <div v-if="selectedLanguage.ecommerce" class="menu-tag ec ta-center pa5" style="height: auto; width: 10px"></div>
                            <div v-if="selectedLanguage.virtual_menu" class="menu-tag vm ta-center pa5" style="height: auto; width: 10px"></div>
                        </div>

                    </div>

                    <i class="options icon-dots-vertical ml5" @click="Options([
                        {text: 'Eliminar Idioma', class: 'error-color', function: DeleteLanguage},
                        {text: 'Editar Idioma', class: '', function: () => { modalLanguage = { id : selectedLanguage.id ,language : '', pos : selectedLanguage.pos, ecommerce : selectedLanguage.ecommerce, virtual_menu : selectedLanguage.virtual_menu}}},
                    ])" v-if="selectedLanguage"></i>

                    <button class="primary purple ml5" v-if="selectedLanguage"
                    @click="Confirm({
                    text: 'Guardar',
                    class: 'selected-color',
                    title: `¿Guardar cambios de idioma '${selectedLanguage.label}'?`,
                    function: SaveMenuLanguage
                    })">Guardar</button>
                </div>
                <div v-if="listSuperSections.length > 0 && !searchlanguage">
                    <div class="flex-fill f18 mb20">Super secciones</div>
                    <div v-for="(ss, index) in listSuperSections" :key="`super-${index}`" class="section mb10" @click.stop="OpenModalLanguageEntry(ss, null, null, OverwrittenLanguages(ss, null, false,null, null, true))">
                        <div class="flex fill align-center selectable">
                            
                            <div class="flex fill">
                                <div class="flex column flex-fill">
                                    <span class="bold">{{ ss }}</span>
                                    <span class="cgray1">{{OverwrittenLanguages(ss, null, false,null, null, true)}}</span>
                                </div>

                            </div>
                            <i class="icon icon-dots-vertical ml5 selectable"></i>
                        </div>
                    </div>
                </div>

                <div v-if="selectedLanguage">
                    <div class="mb20"
                    v-for="(section, si) in visibleItemsAvailable" :key="`s${si}`">
                        <div class="sticky pb20 pt10 bgbg bold flex align-center">
                            <div class=" flex-fill f18 bold">
                                {{section.title}} 
                                <span class="cgray1">{{OverwrittenLanguages(section.title)}}</span>
                            </div>
                            <i class="icon icon-dots-vertical ml5 selectable" @click.stop="OpenModalLanguageEntry(section, null, null, OverwrittenLanguages(section.title))"></i>
                        </div>
                        <div class="section mb10"
                        v-for="(item, i) in section.items" :key="`s${si}i${i}`">
                            <div class="fill flex column">
                                <div class="flex align-center selectable" @click.stop="OpenModalLanguageEntry(item, null, null, OverwrittenLanguages(item, item.id), OverwrittenLanguages(item, item.id, true))">
                                    <div class="flex-fill">
                                        <div class="flex column ">
                                            <span class="bold mr5">{{item.title}}</span>
                                            <div>

                                                <span class="cgray1">{{ OverwrittenLanguages(item, item.id) }}</span>
                                                <span class="cgray1 mr5 ml5" v-if="!!OverwrittenLanguages(item, item.id) && !!OverwrittenLanguages(item, item.id, true)">></span>
                                                <span class="cgray1">{{ OverwrittenLanguages(item, item.id, true) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <i class="icon icon-dots-vertical ml5 selectable"></i>
                                </div>
                                <div>
                                    <div class="flex column mt20"
                                    v-for="(group, gi) in item.modifiers" :key="`s${si}i${i}g${gi}`">
                                        <div class="flex selectable" @click.stop="OpenModalLanguageEntry(group, item, null, OverwrittenLanguages(group, null, false, item))">
                                            <div class="flex-fill flex column bold secondary-text mb10 ">
                                                <span>
                                                    <i class="icon icon-link cgray1" v-show="group.id"></i>{{group.title}}
                                                </span>
                                                <span class="cgray1">{{ OverwrittenLanguages(group, null, false, item) }}</span>
                                            </div>
                                            <i class="icon icon-dots-vertical pl10 selectable"></i>
                                        </div>

                                        <div class="flex column" style="flex-wrap: wrap;">
                                            <div class="entry flex align-center fill selectable" @click.stop="OpenModalLanguageEntry(modifier, item, group,  OverwrittenLanguages(modifier, null, false, item, group),  OverwrittenLanguages(modifier, null, true, item, group))"
                                            v-for="(modifier, mi) in group.modifiers" :key="`s${si}i${i}g${gi}m${mi}`">
                                                <div class="flex-fill flex column">

                                                    <span>
                                                        <i class="icon icon-link cgray1" v-show="modifier.id || group.id"></i>
                                                        {{modifier.title}}
                                                    </span> 
                                                     <div class="flex">{{OverwrittenLanguages(modifier, null, false, item, group)}}
                                                        <div class="cgray1">
                                                            <span class="mr5 ml5" v-if="!!OverwrittenLanguages(modifier, null, false, item, group) && !! OverwrittenLanguages(modifier, null, true, item, group)">></span> 
                                                            {{ OverwrittenLanguages(modifier, null, true, item, group) }}
                                                        </div>
                                                    </div>

                                                </div>
                                                <i class="icon icon-dots-vertical pl10 selectable"></i>
                                            </div>
                                        </div>

                                        <div class="flex column mt10" v-if="group.modifiers_replacement && group.modifiers_replacement.length">
                                            <div class="bold secondary-text mb10">(Reemplazos) {{OverwrittenLanguages(group, null, false, item)}}</div>
                                            <div>
                                                <div class="entry flex selectable"
                                                @click.stop="OpenModalLanguageEntry(modifier, item, group, OverwrittenLanguages(modifier, null, false, item, group),  OverwrittenLanguages(modifier, null, true, item, group))"
                                                v-for="(modifier, mi) in (group.modifiers_replacement ? group.modifiers_replacement : [])" :key="`s${si}i${i}g${gi}m${mi}-r`">
                                                    <div class="flex-fill flex column">
                                                        <div class="flex-fill "><i class="icon icon-link cgray1" v-show="modifier.id || group.id"></i> (Reemplazo) {{modifier.title}}</div>
                                                    <div class="flex-fill">
                                                        <div class="flex">{{OverwrittenLanguages(modifier, null, false, item, group)}}
                                                            <div class="cgray1">
                                                                <span class="mr5 ml5" v-if="!!OverwrittenLanguages(modifier, null, false, item, group) && !!OverwrittenLanguages(modifier, null, true, item, group)">></span> 
                                                                {{ OverwrittenLanguages(modifier, null, true, item, group) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    </div>
                                                    <i class="icon icon-dots-vertical pl10 selectable"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text ta-center mt10">No hay otros idiomas disponibles.</div>
        </div>

    </tabs>

    


<modal v-model="modalSection" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalSection && modalSection.edit ? 'Editar Sección' : 'Nueva Sección'">
    
    <div class="section" v-if="modalSection">
        <input-field class="mb15"
        v-model="modalSection.title"
        label="Título">
        </input-field>

        <input-field class="mb15"
        v-model="modalSection.description"
        :lines="2"
        label="Descripción">
        </input-field>

        <input-select class="mb15" v-model="modalSection.super_section" canType :items="superSection" label="Super sección"></input-select>
        <!--
        <input-image class="mb15"
        label="Imagen"
        path="products/"
        v-model="modalSection.image">
        </input-image>
        -->
        
        <div class="bold mb10">Visibilidad</div>
        
        <label class="mb10">
            <input type="checkbox" v-model="modalSection.hide_pos"> POS <div class="checkmark reverse"></div>
        </label>
        <label class="mb10">
            <input type="checkbox" v-model="modalSection.invisible"> Ecommerce <div class="checkmark reverse"></div>
        </label>
        <label class="">
            <input type="checkbox" v-model="modalSection.hide_virtual_menu"> Virtual Menu <div class="checkmark reverse"></div>
        </label>
        
        <div class="bold mt20 mb10">Tipo de Pedido</div>
        
        <label class="mb10">
            <input type="checkbox" v-model="modalSection.hide_takeaway"> Retirar/Servir <div class="checkmark reverse"></div>
        </label>
        <label>
            <input type="checkbox" v-model="modalSection.hide_delivery"> Delivery <div class="checkmark reverse"></div>
        </label>

        <div class="bold mt20 mb10">Color en cocina</div>
        <input-color class="mb10"
            label=""
            v-model="modalSection.color"
            :canRemove="true"
            >
        </input-color>
        
    </div>

    
    <button slot="actions" @click="SaveSection()" class="primary purple">Guardar</button>
    
    
</modal>

<menu-item-form ref="itemForm"
:inventory="inventory"
:categories="categories"
:menu="menu"
@UpdateMenu="menu=$event">
</menu-item-form>

<modal v-model="modalItemStats" dir="right" :modalClass="['fill', 'md right h100']" :title="modalItemStats?modalItemStats.data:''" hideActions>
    <button class="secondary" slot="options" v-if="modalItemStats && !modalItemStats.show_modifiers" @click="$set(modalItemStats, 'show_modifiers', true)" v-show="modalItemStats.tab == 'Análisis de Ganancias'"><i v-show="mobile" class="icon icon-eye"></i><span v-show="desktop">Mostrar modificadores</span></button>
    <button class="secondary" slot="options" v-if="modalItemStats && modalItemStats.show_modifiers" @click="$set(modalItemStats, 'show_modifiers', false)" v-show="modalItemStats.tab == 'Análisis de Ganancias'"><i v-show="mobile" class="icon icon-invisible"></i><span v-show="desktop">Ocultar modificadores</span></button>
    <tabs :tabs="['Estadísticas de Ventas', 'Análisis de Ganancias']" :keyByTab="true" v-if="modalItemStats" v-model="modalItemStats.tab">
        <div slot="Estadísticas de Ventas" class="pa20">
            <div v-if="modalItemStats.total_transactions > 0">
                <div>
                    <div class="f18 mb20">Datos de los últimos 30 días</div>
                    <div class="flex justify-center ">
                        <div class="flex column ta-center mr10">
                            <div class="secondary-text">Aparece en</div>
                            <div class="bold" style="font-size: 35px">{{Math.round(100*modalItemStats.total_item_transactions/modalItemStats.total_transactions)}}%</div>
                            <div class="secondary-text">de las transacciones</div>
                        </div>
                        <div class="flex column ta-center ml10 mb20">
                            <div class="secondary-text">Corresponde a</div>
                            <div class="bold" style="font-size: 35px">{{Math.round(100*modalItemStats.total_money/modalItemStats.total_transactions_money)}}%</div>
                            <div class="secondary-text">de los ingresos</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f18 mt20">Horarios</div>
                    <chart
                    :data="[modalItemStats.hours]"
                    dataset="count"
                    type="pie"
                    :label="(data) => {return data.data+':00 hrs'}"
                    :singleDataset="false"
                    :showLegend="true"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Math.floor(100*value/statsTotal.hours)}%`}"
                    height="200px"
                    :customColor="[null, null]"
                    :keyColors="Util.Chart.hours">
                    </chart>
                </div>

                <div>
                    <div class="f18 mt20">Días</div>
                    <chart
                    :data="[modalItemStats.days]"
                    dataset="count"
                    type="pie"
                    :label="(data) => {return days[data.data]}"
                    :singleDataset="false"
                    :showLegend="true"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Math.floor(100*value/statsTotal.days)}%`}"
                    height="200px"
                    :customColor="[null, null]"
                    :keyColors="Util.Chart.days">
                    </chart>
                </div>

                <div v-if="modalItemStats.group_modifiers.length > 0">
                    <div class="f18 mt20">Grupo Modificadores</div>
                    <chart
                    :data="[modalItemStats.group_modifiers]"
                    dataset="count"
                    type="pie"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="true"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Math.floor(100*value/statsTotal.group_modifiers)}%`}"
                    height="200px">
                    </chart>
                </div>

                <div>
                    <div class="f18 mt20">Modificadores</div>
                    <chart
                    :data="[modalItemStats.modifiers]"
                    dataset="count"
                    type="pie"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="true"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Math.floor(100*value/statsTotal.modifiers)}%`}"
                    height="200px">
                    </chart>
                </div>

                <div>
                    <div class="f18 mt20">Se compra con</div>
                    <chart
                    :data="[modalItemStats.relations]"
                    dataset="count"
                    type="pie"
                    :label="(data) => {return data.data}"
                    :singleDataset="false"
                    :maxDatasets="7"
                    :showLegend="true"
                    :xLabelFormat="null"
                    :yLabelFormat="null"
                    :tooltip="(value, label, index) => {return `${label} ${Math.round(100*value/statsTotal.relations)}%`}"
                    height="200px">
                    </chart>
                </div>
            </div>
            <div class="secondary-text ta-center mt10" v-else>No hay datos</div>
            
        </div>
        <div slot="Análisis de Ganancias" class="pa20">
            
            <div v-if="modalItemStats.item.inventory_references.length">
                <div class="f18 mb20">Inventario</div>
                <div>
                    <div class="entry flex align-center" v-for="(reference, index) in modalItemStats.item.inventory_references" :key="index+'item_reference'">
                        
                        <div class="flex-fill flex column">
                            <div>{{reference.inventory_item}}</div>
                            <div class="secondary-text">
                                <span>{{Util.Number(reference.quantity)}}</span>
                                <span v-if="inventory.items[reference.inventory_item]">
                                    <span class="ml5" v-if="inventory.items[reference.inventory_item].unit">{{inventory.items[reference.inventory_item].unit}}</span>
                                    <span v-if="inventory.items[reference.inventory_item].unit_cost"> x ${{Util.Number(inventory.items[reference.inventory_item].unit_cost)}}</span>
                                    <span v-else> x $0</span>
                                </span>
                            </div>
                        </div>
                        
                        <div v-if="inventory.items[reference.inventory_item]" class="bold">${{inventory.items[reference.inventory_item].unit_cost ? Util.Number(reference.quantity * inventory.items[reference.inventory_item].unit_cost) : '0'}}</div>

                    </div>
                </div>

                <div class="separator mb20 mt20"></div>

            </div>

            <div v-if="modalItemStats.show_modifiers">
                <div class="f18 mb20">Modificadores</div>
                <div v-for="(group, index) in modalItemStats.item.modifiers" :key="index+'item group'" class="mb20">
                    <div class="bold mb15 flex align-center">{{group.title}} <span class="secondary-text thin ml5">{{Util.ModifierGroupLimits(group)}}</span></div>
                    <div>
                        <div class="entry flex relative" v-for="(modifier, modIndex) in group.modifiers" :key="index+'mod'+modIndex">
                            <div :class="`check selectable mr10 ${modifier.selected ? 'selected' : ''}`" @click="$set(modifier, 'selected', !modifier.selected)"></div>
                            <div class="fill flex column flex-fill">
                                <div>{{modifier.title}}</div>
                                <div class="secondary-text"><span class="bold">Precio: </span>{{Util.Price(modifier.price)}}</div>
                                <div class="secondary-text flex" v-for="(reference, refIndex) in modifier.inventory_references" :key="index+'ref'+modIndex+'mod'+refIndex">
                                    <span class="bold mr5">{{reference.inventory_item}}:</span> {{Util.Number(reference.quantity)}} 
                                    <span v-if="inventory.items[reference.inventory_item]">
                                        <span class="secondary-text ml5" v-if="inventory.items[reference.inventory_item].unit">{{inventory.items[reference.inventory_item].unit}}</span>
                                        <span v-if="inventory.items[reference.inventory_item].unit_cost"> 
                                            x ${{Util.Number(inventory.items[reference.inventory_item].unit_cost)}}
                                            
                                        </span>
                                        <span class="secondary-text absolute" style="right: 0">${{inventory.items[reference.inventory_item].unit_cost ? Util.Number(reference.quantity * inventory.items[reference.inventory_item].unit_cost) : '0'}}</span>
                                    </span>
                                </div>
                                
                            </div>
                            <div class="bold">${{Util.Number(InventoryCost(modifier.inventory_references, inventory))}}</div>
                        </div>
                    </div>
                </div>

                <div class="separator mb20 mt20"></div>

            </div>

            <div class="hs">

                <div class="well">
                    <div class="bold">Precio</div>
                    <div>{{Util.Price(itemInventory.price)}}</div>
                </div>
                
                <div class="well">
                    <div class="bold">Costo Total</div>
                    <div class="cred">${{Util.Number(itemInventory.cost)}}</div>
                </div>

                <div class="well">
                    <div class="bold">Margen</div>
                    <div :class="`flex align-center ${itemInventory.margin > 0 ? 'cgreen' : 'cred'}`">
                        <div>${{Util.Number(itemInventory.margin)}}</div>
                        <i class="icon icon-arrow-right c4 ml10 mr10 secondary-text"></i>
                        <div>{{itemInventory.margin_percentage ? Util.Number(itemInventory.margin_percentage) : '0'}}%</div>
                    </div>
                </div>
                            
            </div>

        </div>
    </tabs>
</modal>

<modal v-model="modalImport" dir="center" modalClass="sm" title="Importar Menú">
    <div class="pa20" v-if="modalImport">
        <div class="secondary-text mb10">Seleccionar un archivo Excel con el formato de menús</div>
    </div>
    
    <button-file slot="actions" v-if="modalImport" accept=".xlsx, .xls, .csv" 
    :type="null"
    @FileSelected="$set(modalImport, 'file', $event)">
        <button :class="`secondary ${modalImport.file ? '' : ''}`">{{modalImport.file ? modalImport.file.name : 'Seleccionar Archivo'}}</button>
    </button-file>
    <button slot="actions" class="primary purple" @click="Confirm({
        title: 'Se remplazará el menú antiguo por el nuevo menú. ¿Desea continuar?',
        text: 'Importar Nuevo Menu',
        class: 'selected-color',
        function: () => {ImportMenu()}
    })">Importar</button>
    
</modal>

<modal v-model="modalSchedule" dir="right" :modalClass="['fill', 'md right h100']" :title="modalSchedule && modalSchedule.id ? 'Editar Horario' : 'Nuevo Horario'">
    <div v-if="modalSchedule" class="pa20">
        
        <div class="bold">Menús</div>
        <div class="secondary-text mb10">Menús en que se aplicarán los cambios de precios</div>
        <label class="mb5">
            <input type="checkbox" v-model="modalSchedule.pos"> POS <div class="checkmark"></div>
        </label>
        <label class="mb5">
            <input type="checkbox" v-model="modalSchedule.ecommerce"> Ecommerce <div class="checkmark"></div>
        </label>
        <label class="mb5">
            <input type="checkbox" v-model="modalSchedule.virtual_menu"> Virtual Menu <div class="checkmark"></div>
        </label>

        <div class="flex">
            <div class="w100 mr5">
                <div class="bold mt10">Inicio</div>

                <input-date
                class="mt10"
                v-if="modalSchedule.start"
                ref="dateScheduleStart"
                v-model="modalSchedule.start"
                type="DATE"
                format="timestamp"
                minDate="today">
                    <div class="append" slot="after">
                        <i  class="icon icon-cross selectable c4" title="Quitar inicio" @click.stop="modalSchedule.start = null"></i>
                    </div>
                </input-date>

                <div class="flex align-center" v-if="!modalSchedule.start">
                    <div class="flex-fill secondary-text">Sin fecha de inicio</div>
                    <i class="options icon-plus" title="Añadir inicio" @click.stop="modalSchedule.start = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000); $nextTick(() => $refs.dateScheduleStart.OpenCalendar())"></i>
                </div>
            </div>
            
            <div class="w100 ml5">
                <div class="bold mt10">Fin</div>

                <input-date
                class="mt10"
                v-if="modalSchedule.finish"
                ref="dateScheduleFinish"
                v-model="modalSchedule.finish"
                type="DATE"
                format="timestamp"
                minDate="today">
                    <div class="append" slot="after">
                        <i  class="icon icon-cross selectable c4" title="Quitar fin" @click.stop="modalSchedule.finish = null"></i>
                    </div>
                </input-date>

                <div class="flex align-center" v-if="!modalSchedule.finish">
                    <div class="flex-fill secondary-text">Sin fecha de fín</div>
                    <i class="options icon-plus" title="Añadir fin" @click.stop="modalSchedule.finish = Math.floor(new Date().setHours(23, 59, 59, 999) / 1000); $nextTick(() => {$refs.dateScheduleFinish.OpenCalendar()})"></i>
                </div>

            </div>
        </div>

        <div class="bold mt20">Horarios</div>

        <div class="secondary-text mb10">Días y horas en que se aplicarán los cambios de precios</div>
        <div>
            <div v-for="(schedule, index) in modalSchedule.schedule" :key="index" class="entry flex">
                
                <div class="hdvm fill">
                    <input-select :items="daysItems" v-model="schedule.day"></input-select>
                    
                    <div class="flex">
                        <input-date
                        v-model="schedule.start"
                        type="TIME"
                        format="HH:mm"
                        inputClass="secondary">
                        </input-date>

                        <input-date class="ml5"
                        v-model="schedule.finish"
                        type="TIME"
                        format="HH:mm"
                        inputClass="secondary">
                        </input-date>

                        <i class="options icon-trash ml5" @click="modalSchedule.schedule.splice(index, 1)"></i>
                    </div>
                </div>
                
            </div>
        </div>

    </div>
    
    <button slot="actions" class="secondary" @click="AddDayToSchedule(true)">Agregar Semana</button>
    <button slot="actions" class="secondary" @click="AddDayToSchedule()">Agregar Día</button>
    <button slot="actions" v-if="modalSchedule" class="primary purple" :disabled="!modalSchedule.schedule.length" @click="SaveSchedule">Guardar</button>
    
</modal>

<modal v-model="modalPlatform" dir="center" :modalClass="['fill', 'sm center']" :title="modalPlatform && modalPlatform.id ? 'Editar Plataforma' : 'Nueva Plataforma'">
    <div v-if="modalPlatform" class="pa20">
        <div class="bold">Plataformas</div>
        <div class="secondary-text mb10">Plataformas disponibles para configurar.</div>
        <input-select class="mt15 restriction-option"
        v-model="modalPlatform.platform"
        placeholder="Seleccione plataforma"
        canType
        :items="[
            {value: 'pedidos_ya', text: 'PedidosYa'}, 
            {value: 'uber_eats', text: 'Uber Eats'}, 
            {value: 'rappi', text: 'Rappi'}, 
        ]">
        </input-select>
    </div>
    
    <button slot="actions" v-if="modalPlatform" class="primary purple" @click="SavePlatform()">Guardar</button>
</modal>

<modal v-model="modalOverwriteEntry" :dir="['center', 'center']" :modalClass="['sm', 'sm']" :title="modalOverwriteEntry?modalOverwriteEntry.entry.title:''">
    <div class="pa20" v-if="modalOverwriteEntry">
        <input-select
        label="Regla"
        description="Determina que se aplicará en el item/modificador en el horario seleccionado"
        v-model="modalOverwriteEntry.rule" 
        :items="modalOverwriteEntry.entry.items? overwriteOptionsSections : (modalOverwriteEntry.isFullMenu? overwriteOptionsMenu : (tab == 'Horarios'? overwriteOptions : overwriteOptionsPlatform))">
        </input-select>

        <!--
        <input-field v-if="['PRICE', 'PRICE_REDUCTION', 'DISCOUNT'].indexOf(modalOverwriteEntry.rule) > -1" 
        label="Nuevo Precio"
        type="number" 
        class="mt15"
        v-model="modalOverwriteEntry.value">
        </input-field>
        -->

        <input-field v-if="modalOverwriteEntry.rule == 'PRICE' || modalOverwriteEntry.rule == 'PRICE_REDUCTION'" 
        label="Nuevo Precio"
        :description="`Precio original: ${Util.Price(modalOverwriteEntry.entry.price)}`"
        type="int+" 
        class="mt15"
        prefix="$"
        v-model="modalOverwriteEntry.value">
        </input-field> 

        <input-field v-if="modalOverwriteEntry.rule == 'DISCOUNT'" 
        label="Porcentaje de Descuento"
        :description="!modalOverwriteEntry.id? '' : `Precio original: ${Util.Price(modalOverwriteEntry.entry.price)}`"
        type="float+" 
        class="mt15"
        suffix="%"
        v-model="modalOverwriteEntry.value">
            <div class="append unshrink" slot="after" v-if="modalOverwriteEntry.id"><span class="bold mr5">Nuevo Precio: </span>{{Util.Price(modalOverwriteEntry.entry.price * (100 - modalOverwriteEntry.value) / 100)}}</div>
        </input-field> 

        <div v-if="modalOverwriteEntry.entry.items || modalOverwriteEntry.isFullMenu">
            <label class="mb10 mt20">
                <input type="checkbox" v-model="modalOverwriteEntry.affectModifiers"> Afectar a los modificadores <div class="checkmark"></div>
            </label>
        </div>
    </div>

    <button slot="actions" class="primary purple" @click="ApplyOverwrite()">Aceptar</button>

</modal>

<modal v-model="modalSimpleMenu" dir="right" :modalClass="['fill', 'md right h100']" title="Carga Rápida de Menú">

    <div class="pa20" v-if="modalSimpleMenu">

        <div class="secondary-text mb20">Cada ítem debe estar separado por una linea vacía donde la primera linea corresponde al nombre del ítem, la segunda linea al SKU (Opcional), la última línea al precio y las lineas intermedias a la descripción (Opcional). Una sola linea de texto corresponde a una sección (si la sección no existe en el menú se creará) y todos los items que proceden serán agregados a esa sección. Si no se especifica la sección se agregarán a la sección "Otros".</div>

        <input-field
        v-model="modalSimpleMenu.content"
        label="Items"
        :placeholder="`Bowl\nSKU 123456789\nBase de arroz con lechuga, maíz y repollo\n4000\n\nBebidas\n\nCoca Cola\nBebida de 2 litros\nBotella desechable\n1500\n\nPepsi\n1500`"
        :lines="true">
        </input-field>

    </div>

    <button slot="actions" class="primary purple" @click="SaveSimpleMenu()">Guardar</button>

</modal>

<modal v-model="modalBackups" dir="right" :modalClass="['fill', 'sm right h100']" title="Menu Backups" hideActions>
    <div class="pa20" v-if="modalBackups">
        <div class="well selectable mb10" v-for="backup in modalBackups" :key="backup.key" @click="DownloadBackup(backup)">Creado el {{Util.Date(backup.created_at, 'DD/MM/YYYY HH:mm')}}</div>
    </div>
</modal>

<modal v-model="modalUpdateAvailabilityPya" dir="right" :modalClass="['fill', 'md right h100']" title="Modificación de Disponibilidad de Items">
    <div v-if="modalUpdateAvailabilityPya" class="pa20">

        <div v-if="Object.keys(modalUpdateAvailabilityPya.availability).length">
            <div class="bold">Items a habilitar</div>
            <div v-for="(item,index) in modalUpdateAvailabilityPya.availability" class="mt10"
            :key="'menu-'+index+'-'+item.id">
                <div :class="`section well mb10`"
                @click="() => {}">
                    <div class="flex fill align-center">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="Object.keys(modalUpdateAvailabilityPya.availabilityToppings).length">
            <br>
            <div class="bold">Modificadores a habilitar</div>
            <div v-for="(topping,index) in modalUpdateAvailabilityPya.availabilityToppings" class="mt10 mb20"
            :key="'menu-'+index+'-'+topping.id">
                <div :class="`section well mb10`"
                @click="() => {}">
                    <div class="flex fill align-center">
                        {{ topping.title }}
                    </div>
                </div>
            </div>
        </div>

        <div class="separator" v-if="Object.keys(modalUpdateAvailabilityPya.availability).length || Object.keys(modalUpdateAvailabilityPya.availabilityToppings).length"></div>

        <input-select class="restriction-option"
        v-model="modalUpdateAvailabilityPya.disablingMethod"
        label="Opciones para deshabilitar"
        :items="[
            {value: 'DisableCatalogItemRequest', text: 'Deshabilitar ítems del catálogo'}, 
            {value: 'DisableCatalogItemUntilNextBusinessDayRequests', text: 'Deshabilitar ítems hasta el próximo día hábil'}, 
            {value: 'DisableCtalogItemUntilTimeStampRequest', text: 'Deshabilitar ítems hasta marca de tiempo'}, 
        ]">
        </input-select>

        <input-date v-if="modalUpdateAvailabilityPya.disablingMethod == 'DisableCtalogItemUntilTimeStampRequest'" class="sm mt10"
        label="Disponibilidad de los ítems"
        v-model="modalUpdateAvailabilityPya.atTimeStamp"
        type="DATETIME"
        format="timestamp">
        </input-date>

        <br>
        <div v-if="Object.keys(modalUpdateAvailabilityPya.unavailable).length">
            <div class="bold">Items a deshabilitar</div>
            <div v-for="(item,index) in modalUpdateAvailabilityPya.unavailable" class="mt10"
            :key="'menu-'+index+'-'+item.id">
                <div :class="`section well mb10 selectable`"
                @click="() => {}">
                    <div class="flex fill align-center">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
        
        <br>
        <div v-if="Object.keys(modalUpdateAvailabilityPya.unavailableToppings).length">
            <div class="bold">Modificadores a deshabilitar</div>
            <div v-for="(topping,index) in modalUpdateAvailabilityPya.unavailableToppings" class="mt10"
            :key="'menu-'+index+'-'+topping.id">
                <div :class="`section well mb10 selectable`"
                @click="() => {}">
                    <div class="flex fill align-center">
                        {{ topping.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <button slot="actions" v-if="modalUpdateAvailabilityPya" class="secondary purple" @click="modalUpdateAvailabilityPya = null">Cancelar</button> -->
    <button slot="actions" v-if="modalUpdateAvailabilityPya" class="primary purple" @click="UpdateAvailabilityPya()">Guardar</button>
</modal>

<sku-reader @OnSKU="OnSKU"></sku-reader>

<modal v-model="modalAssignSKU" :modalClass="['fill', 'md']" :title="'SKU: '+(modalAssignSKU?modalAssignSKU.sku:'')" hideActions>
    <div class="pa20" v-if="modalAssignSKU">
        
        <div class="bold mb10">Asignar SKU a producto</div>
        <div class="flex">
            <input-select class="mr10 flex-fill"
            v-model="modalAssignSKU.item"
            :items="modalAssignSKU.items"
            :canType="'search'">
            </input-select>
            <button slot="actions" class="primary purple" @click="AssignSKU">Asignar</button>
        </div>

        <div class="bold mt20" v-if="modalAssignSKU.options.length">Crear nuevo producto</div>
        <div class="well mt10 flex align-center" v-for="(entry, index) in modalAssignSKU.options" :key="index">
            <img v-if="entry.image" :src="entry.image" alt="" width="75" style="border-radius: 5px" class="mr10">
            <input-image v-else class="mr10"
            path="products/"
            v-model="entry.image"
            :crop="true">
            </input-image>
            <div class="flex column flex-fill mr10">
                <input-field v-model="entry.title" class="flex-fill"></input-field>
                <div class="flex mt5">
                    <input-select class="flex-fill mr5"
                    v-model="entry.section"
                    :items="modalAssignSKU.sections"
                    :canType="'search'">
                    </input-select>
                    <input-field v-model="entry.price" class="flex-fill" prefix="$" type="int+" placeholder="Precio"></input-field>
                </div>
            </div>
            <button class="primary purple" @click="CreateSKUItem(entry)">Crear</button>
        </div>

    </div>
</modal>

<!-- MODAL ORDER MENU -->
<modal v-model="modalOrderMenu" dir="right" :modalClass="['fill', 'md right h100']" title="Ordenar Menú">
    <input-field 
    v-model="searchOrderMenu" 
    v-show="tab == 'Menú'" 
    placeholder="Buscar..."
    pre="search"
    cleareable
    inputClass="secondary"
    :timeout="300"
    :icon="mobile ? 'search' : ''"
    useHeader
    slot="options"
    >
    </input-field>
    <div class="pa20" v-if="modalOrderMenu" ref="orderContainer">
        <div v-for="(section, sectionIndex) in orderMenuItems" :key="sectionIndex">
            <div class="flex fill align-center selectable" @click="ClickHideShowSectionOrderMenu(section.title)" :id="`s-${section.title}`">
                <span class="flex-fill bold f18">{{ section.title }}</span>
                
                <div class="flex">
                    <i @click.stop="MoveDownOrderMenu(sectionIndex, null, `s-${section.title}`)" v-show="!(sectionIndex === modalOrderMenu.items.length - 1)" class="options icon-arrow-down ml5" title="Bajar sección"></i>
                    <i @click.stop="MoveUpOrderMenu(sectionIndex, null, `s-${section.title}`)" v-show="!(sectionIndex === 0)" class="options icon-arrow-up ml5" title="Subir sección"></i>
                </div>
            </div>

            <div class="" style="" v-for="(item, itemIndex) in section.items" :key="itemIndex" v-show="modalOrderMenu.hide[section.title]" :ref="'item-' + sectionIndex + '-' + itemIndex">
                <div class="flex fill align-center" :id="`i-${item.id}`">
                    <span class="flex-fill">{{ item.title }}</span> 
                    <div class="flex">
                        <i @click="MoveDownOrderMenu(sectionIndex, itemIndex, `i-${item.id}`)" class="options icon-arrow-down ml5" title="Bajar item" v-show="!(sectionIndex === modalOrderMenu.items.length - 1 && itemIndex === section.items.length - 1)"></i>
                        <i @click="MoveUpOrderMenu(sectionIndex, itemIndex, `i-${item.id}`)" class="options icon-arrow-up ml5" title="Subir item" v-show="!(sectionIndex === 0 && itemIndex === 0)"></i>
                        <i class="options icon-dots-vertical ml5" @click="Options(ChangeItemSectionOptions(itemIndex, sectionIndex))" title="Cambiar de sección"></i>
                    </div>
                </div>
            </div>


            <div class="separator" v-if="!(sectionIndex === modalOrderMenu.items.length - 1)"></div>
        </div>
    </div>
    <button slot="actions" class="secondary" @click="ClickToggleShowAllSectionsOrderMenu(!modalOrderMenu.hideItems)">{{ modalOrderMenu && modalOrderMenu.hideItems? 'Contraer' : 'Expandir' }} Secciones</button>
    <button slot="actions" class="primary purple" @click="SaveOrderMenu()">Guardar</button>

</modal>
<!-- MODAL ORDER MENU -->
<!-- MODAL IDIOMA -->
<modal v-model="modalLanguage" dir="center" :modalClass="['fill', 'sm center']" :title="modalLanguage && modalLanguage.id ? 'Editar Idioma '+selectedLanguage.label : 'Nuevo Idioma'">
    <div v-if="modalLanguage" class="pa20">
        <template v-if="!(modalLanguage && modalLanguage.id)">
            <div class="bold">Idiomas</div>
            <div class="secondary-text mb10">Idiomas disponibles para configurar.</div>
            <input-select class="mt15 mb10 restriction-option"
                v-model="modalLanguage.language"
                :placeholder="languagesAvailable.length ? 'Seleccione Idioma' : 'No hay idiomas para seleccionar'"
                :items="languagesAvailable"
            >
            </input-select>
        </template>

        <div class="bold">Visibilidad</div>
        <div class="secondary-text mb10">Define en que plataforma se muestra.</div>
        <label class="mb10">
            <input type="checkbox" v-model="modalLanguage.ecommerce">Ecommerce <div class="checkmark"></div>
        </label>
        <label class="">
            <input type="checkbox" v-model="modalLanguage.virtual_menu">Menú Virtual <div class="checkmark"></div>
        </label>
    </div>
    
    <button slot="actions" v-if="modalLanguage" class="primary purple" @click="modalLanguage && modalLanguage.id ? EditLanguage() : SaveLanguage()">Guardar</button>
</modal>
<!-- MODAL IDIOMA -->

<!-- MODAL TRADUCCIONES IDIOMA -->
<modal v-model="modalLanguageEntry" :dir="['center', 'center']" :modalClass="['sm', 'sm']" :title="modalLanguageEntry?modalLanguageEntry.title:''">
    <div class="pa20" v-if="modalLanguageEntry">

        <div><span class="bold">Idioma :</span> {{ selectedLanguage.label }}</div>
        <input-field
        :label="`Nombre`"
        class="mt15"
        v-model="modalLanguageEntry.translate_title">
        </input-field> 

        <input-field
        v-if="(typeof modalLanguageEntry.id === 'number' && !modalLanguageEntry.items) || modalLanguageEntry.is_modifier"
        :label="`Descripción`"
        :description="modalLanguageEntry.description ? `Descripción original: ${modalLanguageEntry.description}` : ''"
        class="mt15"
        v-model="modalLanguageEntry.translate_description">
        </input-field> 

    </div>

    <button slot="actions" class="primary purple" @click="ApplyLanguageOverwrite()">Aceptar</button>

</modal>
<!-- MODAL TRADUCCIONES IDIOMA -->

<modal v-model="modalLoadFastTraduction" dir="right" :modalClass="['fill', 'md right h100']" title="Carga Rápida de Traducciones">

    <div class="pa20" v-if="modalLoadFastTraduction">
        <span class="flex-col">
            Presiona el botón "Traducir menú ..." y se abrirá una nueva pestaña con una lista que contiene la traducción del menú realizada. En la pestaña, haz clic en el botón "Copiar" para copiar la lista traducida, regresa al sistema y pega la traducción copiada en el campo "Menú traducido".
            Presiona el botón "Guardar" para confirmar y aplicar la traducción al menú.
        </span>
        <button slot="actions" class="primary purple mt15 mb10" @click="ClickSelectLanguageToTranslate(modalLoadFastTraduction.language)">Traducir menú al {{ selectedLanguage.label }}</button>
        <input-field
        v-model="modalLoadFastTraduction.pos_translate"
        label="Menú traducido"
        placeholder=""
        :lines="true"
        >
        </input-field>

    </div>

    <button slot="actions" class="primary purple" @click="SaveMasiveLanguageTranslate()">Aplicar traducción</button>

</modal>
</div>
</template>

<script>
import SkuReader from '../common/SkuReader.vue';
export default {
    components: {SkuReader},
    data() {
   
        return {
            menu: null,
            drag: false,
            itemDrop: null,
            startDragSection: null,
            itemDragData: {},
            modalSection: null,
            sectionTitle: '',
            modalMenuItem: false,
            createItemSection: -1,
            currentItem: null,
            currentSection: null,
            inventory: null,
            categories: null,
            modalItemStats: null,
            days: {
                '0': 'Domingo',
                '1': 'Lunes',
                '2': 'Martes',
                '3': 'Miércoles',
                '4': 'Jueves',
                '5': 'Viernes',
                '6': 'Sábado'
            },
            search: '',
            searchAvailability: '',
            searchTmp: '',
            availability: false,
            availabilityToggled: {},
            tab: null,
            showAll: true, //Mostrar los items ocultos de los clientes en la dsponibilidad
            modalImport: null,
            modalSchedule: null,
            modalPlatform: null,
            daysItems: [
                {text: 'Lunes', value: 'monday'},
                {text: 'Martes', value: 'tuesday'},
                {text: 'Miércoles', value: 'wednesday'},
                {text: 'Jueves', value: 'thursday'},
                {text: 'Viernes', value: 'friday'},
                {text: 'Sábado', value: 'saturday'},
                {text: 'Domingo', value: 'sunday'},
            ],
            dayLetter: {
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                sunday: 'Dom'
            },
            schedules: null,
            platforms: null,
            selectedSchedule: null,
            selectedPlatform: null,
            searchOverwrite: '',
            searchOverwritePlatform: '',
            overwriteOptions: [
                {text: 'Sin Sobreescribir', value: 'NONE'},
                {text: 'No disponible', value: 'UNAVAILABLE'},
                {text: 'Cambio de Precio', value: 'PRICE'},
                {text: 'Precio Rebajado', value: 'PRICE_REDUCTION'},
                {text: 'Porcentaje de DCTO', value: 'DISCOUNT'},
                //{text: '2x1', value: '2x1'},
                //{text: '3x2', value: '3x2'},
            ],
            overwriteOptionsMenu: [
                {text: 'Sin Sobreescribir', value: 'NONE'},
                {text: 'Porcentaje de DCTO', value: 'DISCOUNT'},
            ],
            overwriteOptionsPlatform: [
                {text: 'Sin Sobreescribir', value: 'NONE'},
                {text: 'No disponible', value: 'UNAVAILABLE'},
                {text: 'Cambio de Precio', value: 'PRICE'},
            ],
            overwriteOptionsSections: [
                {text: 'Sin Sobreescribir', value: 'NONE'},
                {text: 'No disponible', value: 'UNAVAILABLE'},
                {text: 'Porcentaje de DCTO', value: 'DISCOUNT'},
            ],
            modalOverwriteEntry: null,
            modalSimpleMenu: null,
            dragEventData: null,
            showImages: false,
            dictPlatforms: {
                pedidos_ya: 'PedidosYa',
                uber_eats: 'Uber Eats',
            },
            showModifiers: {
                schedules: {},
                platforms: {},
                languages : {}
            },
            availabilityToggledSchedules : {},
            availabilityToggledPlatforms : {},
            modalBackups: null,
            ItemsToUpdatePya: null,
            modalUpdateAvailabilityPya: null,
            highlightedItem: null,
            modalAssignSKU: null,
            inMotionSections: false,
            modalOrderMenu : null,
            searchOrderMenu : '',
            modalLanguage : null,
            modalLanguageEntry : null,
            searchlanguage : '',
            languages : null,
            selectedLanguage : null,
            dictlanguages : {},
            listSuperSections : [],
            modalLoadFastTraduction : null,
            url: null,
            defaultLanguages : [
                {value: 'EN', text: 'English'}, 
                {value: 'PT', text: 'Português'}, 
            ],
            keyService : null
        }
    },
    mounted() {
        if(localStorage.menuShowImages) this.showImages = true;
        this.$nextTick(() => {
            this.$root.$emit('Loading');
            axios.get(`/menu/${this.Local.id}/complete`).then((res) => {
                this.$root.$emit('Response', res);
                this.menu = res.data;
            }).catch((err) => {
                this.$root.$emit('Response', err);
            });
            /*
            axios.get(`/stock/${this.Local.id}`).then(res => {
                this.$root.$emit('Response', res);
                this.stock = res.data;
            }).catch(err => {
                this.$root.$emit('Response', err);
                console.log(err);
            });
            */
        });
    },
    methods: {
        OpenNewSection(){
            this.modalSection = {
                title : '',
                invisible: false,
                hide_delivery:false,
                hide_takeaway: false,
                hide_pos: false,
                hide_virtual_menu: false,
                description: '',
                image: null,
                color: 'gray',
                super_section : 'Otros'
            };
        },
        SaveSection() 
        {
            if(!this.modalSection.title || this.modalSection.title.trim() == '')
                return this.Info('Título de sección inválido');
            
            var event = '';
            if(this.modalSection.edit)
            {
                var section = {
                    title: this.modalSection.title,
                    invisible: this.modalSection.invisible === undefined ? false : this.modalSection.invisible,
                    hide_delivery: this.modalSection.hide_delivery === undefined ? false : this.modalSection.hide_delivery,
                    hide_takeaway: this.modalSection.hide_takeaway === undefined ? false : this.modalSection.hide_takeaway,
                    hide_pos: this.modalSection.hide_pos === undefined ? false : this.modalSection.hide_pos,
                    hide_virtual_menu: this.modalSection.hide_virtual_menu === undefined ? false : this.modalSection.hide_virtual_menu,
                    description: this.modalSection.description,
                    image: this.modalSection.image,
                    color: this.modalSection.color,
                    super_section : this.modalSection.super_section ? this.modalSection.super_section : 'Otros'
                };
                this.Loading();
                axios.patch(`/menu/section/${this.Local.id}`, {index: this.modalSection.editIndex, oldSection: this.modalSection.oldSection, section: section}).then(res => {
                    this.Response(res);
                    this.menu = res.data;
                    this.modalSection = null;
                }).catch(err => {
                    this.Response(err);
                });
            }
            else
            {
                var section = {
                    title: this.modalSection.title,
                    invisible: this.modalSection.invisible === undefined ? false : this.modalSection.invisible,
                    delivery: this.modalSection.hide_delivery === undefined? false : this.modalSection.hide_delivery,
                    takeaway: this.modalSection.hide_takeaway === undefined? false : this.modalSection.hide_takeaway,
                    hide_pos: this.modalSection.hide_pos === undefined ? false : this.modalSection.hide_pos,
                    hide_virtual_menu: this.modalSection.hide_virtual_menu === undefined ? false : this.modalSection.hide_virtual_menu,
                    description: this.modalSection.description,
                    image: this.modalSection.image,
                    color : this.modalSection.color,
                    super_section : this.modalSection.super_section ? this.modalSection.super_section : 'Otros',
                    items: []
                };
                this.Loading();
                axios.put(`/menu/section/${this.Local.id}`, {section: section}).then(res => {
                    this.Response(res);
                    this.menu = res.data;
                    this.$nextTick(() => {
                        scrollTo(100000000 ,{
                            elementToScroll: this.$refs.container.parentNode,
                            maxDuration: 500,
                            minDuration: 500
                        }).then();
                    });
                    this.modalSection = null;
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        EditSection(section, index) 
        {
            var data = JSON.parse(JSON.stringify(section));
            data.edit = true;
            data.editIndex = index;
            data.oldSection = section.title;
            this.modalSection = data;
        },
        DeleteSection(sectionTitle, sectionIndex) 
        {
            this.Loading();
            axios.patch(`/menu/section/${this.Local.id}/delete`, {section: sectionTitle, index: sectionIndex}).then(res => {
                this.Response(res);
                this.menu = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        OnMenuOrderChanged(event, section)
        {
            if(!event) return;
            var eventData = null;
            /*
            var reorderedMenu = this.Util.Copy(this.visibleItems);
            if(event.moved || event.removed)
            {
                var menuOrder = [];
                reorderedMenu.forEach(section => {
                    var sectionOrderData = {title: section.title, items: []};
                    section.items.forEach(item => {
                        sectionOrderData.items.push(item.id);
                    });
                    menuOrder.push(sectionOrderData);
                });
                axios.patch(`/menu/reorder/${this.Local.id}`, {order: menuOrder}).then(res => {
                    this.Toast({text: 'Menu reordenado', override: true});
                    this.menu = res.data;
                }).catch(err => {
                    
                });
            }
            */

            if(event.moved && !section)
            {
                eventData = {event: 'SECTION_MOVED', from: event.moved.oldIndex, to: event.moved.newIndex, subject: event.moved.element.title};
            }
            else if(event.moved && section)
            {
                eventData = {event: 'ITEM_MOVED', from: event.moved.oldIndex, to: event.moved.newIndex, fromSection: section, toSection: section, subject: event.moved.element.id};
            }
            else if(event.added)
            {
                this.dragEventData = {event: event, section: section};
            }
            else if(event.removed && this.dragEventData)
            {
                if(this.dragEventData.event.added.element.id == event.removed.element.id)
                    eventData = {event: 'ITEM_MOVED', from: event.removed.oldIndex, to: this.dragEventData.event.added.newIndex, fromSection: section, toSection: this.dragEventData.section, subject: event.removed.element.id};
                this.dragEventData = null;
            }
            
            console.log(event);
            console.log(eventData);

            this.menu = this.Util.Copy(this.visibleItems);

            if(eventData)
            {
                axios.patch(`/menu/reorder/${this.Local.id}`, eventData).then(res => {
                    this.menu = res.data.menu;
                    if(res.data.error) this.Info(res.data.error);
                    else this.Toast({text: 'Menu reordenado', override: true});
                }).catch(err => {

                });
            }

        },
        async NewItem(type, sectionIndex)
        {
            this.$root.$emit('Loading');
            try {
                if(type === 0)
                {
                    if(!this.categories)
                        this.categories = (await axios.get(`/categories`)).data;
                    if(!this.inventory)
                    {
                        this.inventory = (await axios.get(`/inventory/${this.Local.id}`)).data;
                        this.inventory.items = this.Util.CalculateInventoryComposite(this.inventory.items);
                    } 
                }
                this.$refs.itemForm.New({
                    type: type,
                    sectionIndex: sectionIndex
                });
                this.$root.$emit('Response', {error: false});
            } catch (error) {
                this.$root.$emit('Response', error);
            }
        },
        async EditItem(item)
        {
            this.$root.$emit('Loading');
            try {
                if(!this.categories)
                    this.categories = (await axios.get(`/categories`)).data;
                if(!this.inventory)
                {
                    this.inventory = (await axios.get(`/inventory/${this.Local.id}`)).data;
                    this.inventory.items = this.Util.CalculateInventoryComposite(this.inventory.items);
                }  
                var itemData = (await axios.get(`/menu/item/${item.id}`)).data;
                this.$refs.itemForm.Open(itemData);
                this.$root.$emit('Response', {error: false});
            } catch (error) {
                this.$root.$emit('Response', error);
            }
        },
        DeleteItem(item)
        {
            this.Loading();
            axios.delete(`/menu/item/${item.id}`).then(res => {
                this.Response(res);
                this.menu = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        DuplicateItem(item)
        {
            this.$root.$emit('Loading');
            axios.patch(`/menu/item/${this.Local.id}/duplicate/${item.id}`).then(res => {
                this.$root.$emit('Response', res);
                this.menu = res.data;
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        async ShowStats(item) {
            this.Loading();
            if(!this.inventory)
            {
                this.inventory = (await axios.get(`/inventory/${this.Local.id}`)).data;
                this.inventory.items = this.Util.CalculateInventoryComposite(this.inventory.items);
            } 
            
            axios.get(`/menu/item/${item.id}/stats`).then(res => {
                this.Response(res);
                res.data.data = item.title;
                this.modalItemStats = res.data;
            }).catch(err => {
                this.Response({error: false});
                this.modalItemStats = {
                    total_transactions: 0,
                    data: item.title,
                    item: item
                };
            })
        },
        SelectAll(type, value)
        {
            for(var i = 0; i < this.visibleItemsAvailable.length; i++)
            {
                var section = this.visibleItemsAvailable[i];
                for(var j = 0; j < section.items.length; j++)
                {
                    var item = section.items[j];
                    if((type == 'ALL' || type == 'ITEM'))
                    {
                        if(this.availabilityToggled[item.id] && this.availabilityToggled[item.id].value != value)
                            this.$delete(this.availabilityToggled, item.id);
                        else if(!!item.unavailable != value)
                            this.$set(this.availabilityToggled, item.id, {type: 'ITEM', id: item.id, sectionIndex: section.sectionIndex, itemIndex: item.itemIndex, value: value});
                    }
                    if(item.modifiers)
                    {
                        for(var x = 0; x < item.modifiers.length; x++)
                        {
                            var group = item.modifiers[x];
                            for(var y = 0; y < group.modifiers.length; y++)
                            {
                                var modifier = group.modifiers[y];
                                if((type == 'ALL' || type == 'MODIFIER'))
                                {
                                    if(this.availabilityToggled[item.id+group.title+modifier.title] && this.availabilityToggled[item.id+group.title+modifier.title].value != value)
                                        this.$delete(this.availabilityToggled, item.id+group.title+modifier.title);
                                    else if(!!modifier.unavailable != value)
                                        this.$set(this.availabilityToggled, item.id+group.title+modifier.title, {type: 'MODIFIER', id: item.id, sectionIndex: section.sectionIndex, itemIndex: item.itemIndex, groupIndex: group.groupIndex, modifierIndex: modifier.modifierIndex, value: value});
                                }
                            }

                            if(group.modifiers_replacement)
                            {
                                for(var y = 0; y < group.modifiers_replacement.length; y++)
                                {
                                    var modifier = group.modifiers_replacement[y];
                                    if((type == 'ALL' || type == 'MODIFIER'))
                                    {
                                        if(this.availabilityToggled[item.id+group.title+modifier.title+'-r'] && this.availabilityToggled[item.id+group.title+modifier.title+'-r'].value != value)
                                            this.$delete(this.availabilityToggled, item.id+group.title+modifier.title+'-r');
                                        else if(!!modifier.unavailable != value)
                                            this.$set(this.availabilityToggled, item.id+group.title+modifier.title+'-r', {type: 'MODIFIER_REPLACEMENT', id: item.id, sectionIndex: section.sectionIndex, itemIndex: item.itemIndex, groupIndex: group.groupIndex, modifierIndex: modifier.modifierIndex, value: value});
                                    }
                                }
                            }

                        }
                    }
                }
            }
        },
        ToggleAvailabilityItem(item, section)
        {
            if(this.availabilityToggled[item.id] !== undefined)
                this.$delete(this.availabilityToggled, item.id)
            else 
                this.$set(this.availabilityToggled, item.id, {type: 'ITEM', id: item.id, sectionIndex: section.sectionIndex, itemIndex: item.itemIndex, value: !item.unavailable})
        },
        ToggleAvailabilityModifier(modifier, group, item, section, isReplacement)
        {
            let availability = this.tab == 'Disponibilidad'? this.availabilityToggled : (this.tab == 'Horarios'? this.availabilityToggledSchedules : this.availabilityToggledPlatforms);
            let hyphenated = this.tab == 'Disponibilidad'? false : true;
            var val = 'DELETE';
            var key = hyphenated? `${item.id}-${group.title}-${modifier.title}`+(isReplacement?'-r':'') : item.id+group.title+modifier.title+(isReplacement?'-r':'');
            if(availability[key] !== undefined)
                this.$delete(availability, key)
            else
            {
                val = !modifier.unavailable;
                this.$set(availability, key, {type: isReplacement ? 'MODIFIER_REPLACEMENT' : 'MODIFIER', id: item.id, sectionIndex: section.sectionIndex, itemIndex: item.itemIndex, groupIndex: group.groupIndex, modifierIndex: modifier.modifierIndex, value: !modifier.unavailable})
            }
            
            if(group.id)
            {
                for(var i = 0; i < this.indexedMenu.length; i++)
                {
                    var section = this.indexedMenu[i];
                    for(var j = 0; j < section.items.length; j++)
                    {
                        var item = section.items[j];
                        for(var k = 0; k < item.modifiers.length; k++)
                        {
                            var g = item.modifiers[k];
                            if(!isReplacement && group.id == g.id)
                            {
                                for(var l = 0; l < group.modifiers.length; l++)
                                {
                                    var mod = group.modifiers[l];
                                    if(modifier.title == mod.title)
                                    {
                                        if(val == 'DELETE')
                                        {
                                            this.$delete(availability, hyphenated? `${item.id}-${group.title}-${modifier.title}` : `${item.id}${group.title}${mod.title}`);
                                        }
                                        else
                                        {
                                            this.$set(availability, hyphenated? `${item.id}-${group.title}-${modifier.title}` : `${item.id}${group.title}${mod.title}`, {
                                                type: 'MODIFIER', 
                                                id: item.id, 
                                                sectionIndex: section.sectionIndex, 
                                                itemIndex: item.itemIndex, 
                                                groupIndex: g.groupIndex, 
                                                modifierIndex: mod.modifierIndex, 
                                                value: val
                                            });
                                        }
                                    }
                                }
                            }
                            
                            if(isReplacement && group.id == g.id && group.modifiers_replacement){
                                for(var l = 0; l < group.modifiers_replacement.length; l++)
                                {
                                    var mod = group.modifiers_replacement[l];
                                    if(modifier.title == mod.title)
                                    {
                                        if(val == 'DELETE')
                                        {
                                            this.$delete(availability, hyphenated? `${item.id}-${group.title}-${modifier.title}-r` : `${item.id}${group.title}${mod.title}-r`);
                                        }
                                        else
                                        {
                                            this.$set(availability, hyphenated? `${item.id}-${group.title}-${modifier.title}-r` : `${item.id}${group.title}${mod.title}-r`, {
                                                type: 'MODIFIER_REPLACEMENT', 
                                                id: item.id, 
                                                sectionIndex: section.sectionIndex, 
                                                itemIndex: item.itemIndex, 
                                                groupIndex: g.groupIndex, 
                                                modifierIndex: mod.modifierIndex, 
                                                value: val
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if(modifier.id)
            {
                for(var i = 0; i < this.indexedMenu.length; i++)
                {
                    var section = this.indexedMenu[i];
                    for(var j = 0; j < section.items.length; j++)
                    {
                        var item = section.items[j];
                        for(var k = 0; k < item.modifiers.length; k++)
                        {
                            var group = item.modifiers[k];
                            for(var l = 0; l < group.modifiers.length; l++)
                            {
                                var mod = group.modifiers[l];
                                if(modifier.id == mod.id)
                                {
                                    if(val == 'DELETE')
                                    {
                                        this.$delete(availability, hyphenated? `${item.id}-${group.title}-${modifier.title}` : `${item.id}${group.title}${mod.title}`);
                                    }
                                    else
                                    {
                                        this.$set(availability, hyphenated? `${item.id}-${group.title}-${modifier.title}` : `${item.id}${group.title}${mod.title}`, {
                                            type: 'MODIFIER', 
                                            id: item.id, 
                                            sectionIndex: section.sectionIndex, 
                                            itemIndex: item.itemIndex, 
                                            groupIndex: group.groupIndex, 
                                            modifierIndex: mod.modifierIndex, 
                                            value: val
                                        });
                                    }
                                }
                            }

                            if(group.modifiers_replacement)
                            {
                                for(var l = 0; l < group.modifiers_replacement.length; l++)
                                {
                                    var mod = group.modifiers_replacement[l];
                                    if(modifier.id == mod.id)
                                    {
                                        if(val == 'DELETE')
                                        {
                                            this.$delete(availability, hyphenated? `${item.id}-${group.title}-${modifier.title}-r` : `${item.id}${group.title}${mod.title}-r`);
                                        }
                                        else
                                        {
                                            this.$set(availability, hyphenated? `${item.id}-${group.title}-${modifier.title}-r` : `${item.id}${group.title}${mod.title}-r`, {
                                                type: 'MODIFIER_REPLACEMENT', 
                                                id: item.id, 
                                                sectionIndex: i, 
                                                itemIndex: j, 
                                                groupIndex: k, 
                                                modifierIndex: l, 
                                                value: val
                                            });
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }

        },
        ApplyAvailabilityChanges()
        {
            this.Loading();
            axios.post(`/menu/${this.Local.id}/availability`, {availability: this.availabilityToggled}).then(res => {
                this.Response(res);
                this.menu = res.data;
                this.availabilityToggled = {};
            }).catch(err => {
                this.Response(err);
            });
        },
        ImportMenu() {
            if(!this.modalImport.file) return this.Info('Se debe seleccionar un archivo');
            
            var formData = new FormData();
            formData.append("file", this.modalImport.file);
            this.Loading();
            axios.post(`/menu/${this.Local.id}/import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.Info('Menu importado correctamente');
                this.modalImport = null;
                this.menu = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        ExportMenu() {
            this.Loading();
            axios.get(`/menu/${this.Local.id}/export`, {responseType: 'blob'}).then(res => {
                this.Response(res);
                var moment = require('moment');
                var today = moment();
                this.Util.DownloadFile(res, `menu-${today.format('DD-MM-YYYY')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        InventoryCost(references, inventory)
        {
            var cost = 0;
            references.forEach(reference => {
                if(inventory.items[reference.inventory_item] && inventory.items[reference.inventory_item].unit_cost)
                {
                    cost += (inventory.items[reference.inventory_item].unit_cost * reference.quantity);
                }
            });
            return cost;
        },


        GetSchedules() 
        {
            if(this.schedules) return;
            this.Loading();
            axios.get(`/menu/${this.Local.id}/schedules`).then(res => {
                this.Response(res);
                this.schedules = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        GetPlatforms() 
        {
            if(this.platforms) return;
            this.Loading();
            axios.get(`/menu/${this.Local.id}/platforms`).then(({data}) => {
                this.Response(data);
                this.platforms = data;
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveSchedule()
        {
            if(this.modalSchedule.start && this.modalSchedule.finish && (this.modalSchedule.finish < this.modalSchedule.start)) return this.Info('La fecha de término debe ser superior a la de inicio');

            if(this.modalSchedule.id)
            {
                this.Loading();
                axios.patch(`/menu/${this.Local.id}/schedule/${this.modalSchedule.id}/modify`, this.modalSchedule).then(res => {
                    this.Info('Horario modificado');
                    this.modalSchedule = null;
                    if(res.data.overwrites.length === 0) res.data.overwrites = {};
                    for(var i = 0; i < this.schedules.length; i++)
                    {
                        if(this.schedules[i].id == res.data.id)
                        {
                            this.$set(this.schedules, i, res.data);
                            this.selectedSchedule = this.schedules[i];
                            break;
                        }
                    }
                }).catch(err => {
                    this.Response(err);
                });
            }
            else
            {
                this.Loading();
                axios.put(`/menu/${this.Local.id}/schedule`, this.modalSchedule).then(res => {
                    this.Response(res);
                    this.modalSchedule = null;
                    if(res.data.overwrites.length === 0) res.data.overwrites = {};
                    this.schedules.push(res.data);
                    this.selectedSchedule = res.data;
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        SavePlatform(){
            this.Loading();
            axios.put(`/menu/${this.Local.id}/platform`, this.modalPlatform).then(({data}) => {
                this.Response(data);
                this.modalPlatform = null;
                if(!data.overwrites.length) data.overwrites = {};
                this.platforms.push(data);
                this.selectedPlatform = data;
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveMenuSchedule()
        {
            let selected = this.tab == 'Horarios'? this.selectedSchedule : this.selectedPlatform;
            if(!selected) return;

            let category = this.tab == 'Horarios'? this.schedules : this.platforms;

            this.Loading();
            axios.patch(`/menu/${this.Local.id}/schedule/${selected.id}`, selected).then(({data}) => {
                this.Info('Menú guardado');
                if(data.overwrites.length === 0) data.overwrites = {};
                for(var i = 0; i < category.length; i++)
                {
                    if(category[i].id == data.id)
                    {
                        this.$set(category, i, data);       
                        break;
                    }
                }
                if(this.tab == 'Horarios') this.selectedSchedule = data;
                else this.selectedPlatform = data;
            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteSchedule()
        {
            let isSchedule = this.tab == 'Horarios'? true : false;
            let selected = isSchedule? this.selectedSchedule : this.selectedPlatform;
            if(!selected) return;
            let category = isSchedule? this.schedules : this.platforms;

            this.Confirm({
                text: 'Eliminar',
                title: `¿Eliminar ${isSchedule? 'horario' : 'configuración'} con los cambios del menú?`,
                class: 'error-color',
                function: () => {
                    this.Loading();
                    axios.delete(`/menu/${this.Local.id}/schedule/${selected.id}`).then(({data}) => {
                        this.Response(data);
                        for(var i = 0; i < category.length; i++)
                        {
                            if(category[i].id == data.id)
                            {
                                this.$delete(category, i);
                                break;
                            }
                        }
                        selected = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
            
        },
        ToggleScheduleModifier(modifier, group, item)
        {
            var val = !this.selectedSchedule.overwrites[`${item.id}-${group.title}-${modifier.title}-unavailable`];
            if(modifier.id)
            {
                for(var i = 0; i < this.menu.length; i++)
                {
                    var section = this.menu[i];
                    for(var j = 0; j < section.items.length; j++)
                    {
                        var item = section.items[j];
                        for(var k = 0; k < item.modifiers.length; k++)
                        {
                            var group = item.modifiers[k];
                            for(var l = 0; l < group.modifiers.length; l++)
                            {
                                var mod = group.modifiers[l];
                                if(modifier.id == mod.id)
                                {
                                    this.$set(this.selectedSchedule.overwrites, `${item.id}-${group.title}-${mod.title}-unavailable`, val);                    
                                }
                            }
                        }
                    }
                }
            }
            else
                this.$set(this.selectedSchedule.overwrites, `${item.id}-${group.title}-${modifier.title}-unavailable`, val);
        },
        EditScheduleModifier(val, key, modifier, group, item, isReplacement)
        {
            if(modifier.id)
            {
                for(var i = 0; i < this.menu.length; i++)
                {
                    var section = this.menu[i];
                    for(var j = 0; j < section.items.length; j++)
                    {
                        var item = section.items[j];
                        for(var k = 0; k < item.modifiers.length; k++)
                        {
                            var group = item.modifiers[k];
                            for(var l = 0; l < group.modifiers.length; l++)
                            {
                                var mod = group.modifiers[l];
                                if(modifier.id == mod.id)
                                {
                                    this.$set(this.selectedSchedule.overwrites, `${item.id}-${group.title}-${mod.title}-${key}`, val);                 
                                }
                            }
                            if(group.modifiers_replacement)
                            {
                                for(var l = 0; l < group.modifiers_replacement.length; l++)
                                {
                                    var mod = group.modifiers_replacement[l];
                                    if(modifier.id == mod.id)
                                    {
                                        this.$set(this.selectedSchedule.overwrites, `${item.id}-${group.title}-${mod.title}-r-${key}`, val);                 
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else
            {
                this.$set(this.selectedSchedule.overwrites, `${item.id}-${group.title}-${modifier.title}${isReplacement?'-r':''}-${key}`, val);
            }
        },
        OverwrittenPrice(entry, id)
        {
            const selected = this.tab == 'Horarios'? this.selectedSchedule : this.selectedPlatform;
            if(!selected) return null;
            /*
            {text: 'Sin Sobreescribir', value: 'NONE'},
            {text: 'No disponible', value: 'UNAVAILABLE'},
            {text: 'Cambio de Precio', value: 'PRICE'},
            {text: 'Precio Rebajado', value: 'PRICE_REDUCTION'},
            {text: 'Porcentaje de DCTO', value: 'DISCOUNT'},
            */

            var overwrite = selected.overwrites[id+'-overwrite'];
            var value = selected.overwrites[id+'-value'];
            if(!overwrite || overwrite == 'NONE') return `<span class="cgray1">${this.Util.Price(entry.price)}</span>`;
            if(overwrite == 'UNAVAILABLE') return `<span class="cred">No disponible</span>`;
            if(overwrite == 'PRICE') return `<span class="cgray1">${this.Util.Price(entry.price)}</span> <i class="icon icon-arrow-right"></i> <span>${this.Util.Price(value)}</span>`;
            if(overwrite == 'PRICE_REDUCTION') return `<span class="cgray1 line-through">${this.Util.Price(entry.price)}</span> <i class="icon icon-arrow-right"></i> <span class="cred">${this.Util.Price(value)}</span>`;
            if(overwrite == 'DISCOUNT') return `<span class="cred">${value}% DCTO</span> <i class="icon icon-arrow-right"></i> <span>${this.Util.Price(entry.price * (100 - value) / 100)}</span>`;

            return '';
        },
        OpenOverwriteEntry(entry, id, modifierTree)
        {
            let isFullMenu = !id && !entry.items? true : false; 

            const selected = this.tab == 'Horarios'? this.selectedSchedule : this.selectedPlatform;
            if(!selected) return null;
            this.modalOverwriteEntry = {
                rule: selected.overwrites[id + '-overwrite'],
                value: selected.overwrites[id + '-value'],
                entry: entry,
                id: id,
                platform: this.tab == 'Plataformas'? true : false,
                tree: modifierTree,
                affectModifiers: true,
                isFullMenu,
            }
        },
        ApplyOverwrite(dataOverwriteEntry = null)
        {
            let modalOverwriteEntry = dataOverwriteEntry? dataOverwriteEntry : this.modalOverwriteEntry;
            const selected = this.tab == 'Horarios'? this.selectedSchedule : this.selectedPlatform;
            if(!selected) return null;

            if(modalOverwriteEntry && modalOverwriteEntry.isFullMenu) {
                let tmpMenu = this.Util.Copy(this.visibleItemsAvailable);
                return tmpMenu.forEach(section => {
                    this.ApplyOverwrite({...modalOverwriteEntry, isFullMenu: false, entry: section});
                });
            }

            let tree = modalOverwriteEntry && modalOverwriteEntry.tree? modalOverwriteEntry.tree : null;
            // if(tree) this.ToggleAvailabilityModifier(tree.modifier, tree.group, tree.item, tree.section, tree.isReplacement);
            let similarities = null;
            if(tree) similarities = this.SearchReferences(tree);

            if(['PRICE', 'PRICE_REDUCTION', 'DISCOUNT'].indexOf(modalOverwriteEntry.rule) > -1 && isNaN(parseFloat(modalOverwriteEntry.value))) return this.Info('Datos inválidos');

            if(modalOverwriteEntry.entry.items){
                modalOverwriteEntry.entry.items.forEach((item) => {
                    this.$set(selected.overwrites, item.id+'-overwrite', modalOverwriteEntry.rule);
                    this.$set(selected.overwrites, item.id+'-value', modalOverwriteEntry.value);

                    if(!modalOverwriteEntry.affectModifiers) return;

                    item.modifiers.forEach(group => {
                        group.modifiers.forEach(modifier => {
                            this.$set(selected.overwrites, `${item.id}-${group.title}-${modifier.title}-overwrite`, modalOverwriteEntry.rule);
                            this.$set(selected.overwrites, `${item.id}-${group.title}-${modifier.title}-value`, modalOverwriteEntry.value);
                        });
                    });
                });
            }else{
                if(tree) {
                    similarities.forEach(key => {
                        this.$set(selected.overwrites, key+'-overwrite', modalOverwriteEntry.rule);
                        this.$set(selected.overwrites, key+'-value', modalOverwriteEntry.value);
                    });
                }
                this.$set(selected.overwrites, modalOverwriteEntry.id+'-overwrite', modalOverwriteEntry.rule);
                this.$set(selected.overwrites, modalOverwriteEntry.id+'-value', modalOverwriteEntry.value);
            }

            var entry = modalOverwriteEntry.entry;
            if(!('modifiers' in entry) && entry.id)
            {
                this.menu.forEach(section => {
                    section.items.forEach(item => {
                        if(item.modifiers)
                        {
                            item.modifiers.forEach(group => {
                                group.modifiers.forEach(modifier => {
                                    if(modifier.id === entry.id)
                                    {
                                        this.$set(selected.overwrites, `${item.id}-${group.title}-${modifier.title}-overwrite`, modalOverwriteEntry.rule);
                                        this.$set(selected.overwrites, `${item.id}-${group.title}-${modifier.title}-value`, modalOverwriteEntry.value);
                                    } 
                                });

                                if(group.modifiers_replacement)
                                {
                                    group.modifiers_replacement.forEach(modifier => {
                                        if(modifier.id === entry.id)
                                        {
                                            this.$set(selected.overwrites, `${item.id}-${group.title}-${modifier.title}-r-overwrite`, modalOverwriteEntry.rule);
                                            this.$set(selected.overwrites, `${item.id}-${group.title}-${modifier.title}-r-value`, modalOverwriteEntry.value);
                                        } 
                                    }); 
                                }

                            });
                        }
                        
                    });
                });
            }

            this.modalOverwriteEntry = null;
        },
        SearchReferences(tree) {
            let { group, modifier, section, item, isReplacement } = tree;
            let similarities = [];

            for(let i = 0; i < this.indexedMenu.length; i++){
                let section = this.indexedMenu[i];
                for(let j = 0; j < section.items.length; j++){
                    let item = section.items[j];
                    for(let k = 0; k < item.modifiers.length; k++){
                        let g = item.modifiers[k];
                        if(!isReplacement && group.id == g.id){
                            for(let l = 0; l < group.modifiers.length; l++){
                                let mod = group.modifiers[l];
                                if(modifier.title == mod.title) {
                                    similarities.push(`${item.id}-${g.title}-${mod.title}`);
                                }
                            }
                        }
                        
                        if(isReplacement && group.id == g.id && group.modifiers_replacement) {
                            for(var l = 0; l < group.modifiers_replacement.length; l++){
                                var mod = group.modifiers_replacement[l];
                                if(modifier.title == mod.title) {
                                    similarities.push(`${item.id}-${g.title}-${mod.title}-r`);
                                }
                            }
                        }
                    }
                }
            }
            return similarities;
        },
        SaveSimpleMenu()
        {
            var entries = this.modalSimpleMenu.content.split(/\n\n/g);
            var items = [];
            var currentSection = 'Otros';

            var summary = {};

            entries.forEach(entry => {

                var lines = entry.split(/\n/g);

                if(lines.length == 1)
                {
                    currentSection = lines[0];
                }
                else
                {
                    var item = {
                        title: '',
                        description: '',
                        price: 0,
                        section: currentSection
                    }
                    for(var i = 0; i < lines.length; i++)
                    {
                        if(i == 0)
                        {
                            item.title = lines[i];
                        }
                        else if(i == 1 && (/sku/i.test(lines[i]))) 
                        {
                            item.sku = lines[i].match(/sku\s*:?(\s*\d+)/i)[1].trim();
                        }
                        else if(i == lines.length-1)
                        {
                            item.price = parseInt(lines[i]);
                        }
                        else
                        {
                            item.description += `\n${lines[i]}`;
                        }
                    }
                    item.description = item.description.substr(1);
                    if(item.title && !isNaN(item.price)) 
                    {
                        items.push(item);
                        if(!summary[item.section]) summary[item.section] = 1;
                        else summary[item.section]++;
                    }
                }
            });
            console.log(items);

            var summaryStr = '';
            for(var key in summary)
            {
                summaryStr += `, ${key} (${summary[key]})`;
            }
            summaryStr = summaryStr.substr(2);
            if(!summaryStr) return this.Info('Menú inválido');

            this.Confirm({
                title: '¿Cargar menú?<br>'+summaryStr,
                text: 'Cargar',
                function: () => {
                    this.Loading();
                    axios.post(`/menu/${this.Local.id}/quick`, {items: items}).then(res => {
                        this.Response('Menú cargado');
                        this.menu = res.data;
                        this.modalSimpleMenu = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                } 
            });

        },
        IsComboIncomplete(combo)
        {
            if(!combo.products) return false;
            var subitems = this.Util.IsObject(combo.products) ? Object.values(combo.products) : combo.products;
            for(var i = 0; i < subitems.length; i++)
            {
                if(!this.dictItems[subitems[i].id]) return true;
            }
            return false;
        },
        ToggleModifiersVisibility(item){
            let typeTab = this.tab == 'Plataformas'? 'platforms' : this.tab == 'Idiomas'? 'languages' : 'schedules';
            if(item) return !this.showModifiers[typeTab][item.id]? this.$set(this.showModifiers[typeTab], item.id, true) : this.$delete(this.showModifiers[typeTab], item.id);

            let search = this.tab == 'Plataformas'? this.searchOverwritePlatform : this.tab == 'Idiomas'? this.searchlanguage : this.searchOverwrite;

            if(!search || search == '') return this.showModifiers.schedules = {};

            let items = Util.Copy(this.visibleItemsAvailable).flatMap(section => section.items);
            items.forEach(item => {
                this.$set(this.showModifiers[typeTab], item.id, true);
            });
        },
        OpenBackups() {
            this.Loading();
            axios.get(`/influye/menu/backups/${this.Local.id}`).then(res => {
                this.modalBackups = res.data;
                this.Response(res);
            }).catch(err => {
                this.Info('No se encontraron backups');
            });
        },
        DownloadBackup(backup) {
            this.Loading();
            axios.get(`/influye/menu/backups/${backup.key}/download`, {responseType: 'blob'}).then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `menu-${Util.Date(backup.created_at, 'DD-MM-YYYY')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        MenuPya(clear = false) {
            this.Loading();
            axios.post(`/pya/menu/${this.Local.id}${clear? '/clear' : ''}`)
            .then(({data}) => {
                this.Info(`Menú ${clear? 'limpiado' : 'importado'} correctamente`);
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenModalUpdateAvailabilityPya() {
            this.modalUpdateAvailabilityPya = this.ItemsToUpdatePya;
        },
        isModifierChecked(itemId, groupTitle, modifierTitle) {
            const modifierId = `${itemId}-${groupTitle}-${modifierTitle}`;
            return !!(this.ItemsToUpdatePya.availabilityToppings[modifierId] || this.ItemsToUpdatePya.unavailableToppings[modifierId]);
        },
        ToggleItemInPya(type, element, id) {
            const selected = this.selectedPlatform;
            if (!selected) return;

            const overwrite = selected.overwrites[id + '-overwrite'];
            let listType = '';

            listType = overwrite === 'UNAVAILABLE' ? 'availability' : 'unavailable';
            if(type == 'modifier') listType = listType+"Toppings";

            if (listType) {
                let dict = this.ItemsToUpdatePya[listType];

                if (!dict[id]) {
                    this.$set(dict, id, { ...element, fullId: id });
                } else {
                    this.$delete(dict, id);
                }

                if (type === 'item' && element.modifiers && element.modifiers.length > 0) {
                    element.modifiers.forEach(group => {
                        group.modifiers.forEach(modifier => {
                            const modifierId = `${id}-${group.title}-${modifier.title}`;
                            const modifierOverwrite = selected.overwrites[modifierId + '-overwrite'];
                            const modifierListType = modifierOverwrite === 'UNAVAILABLE' ? 'availabilityToppings' : 'unavailableToppings';
                            let modifierDict = this.ItemsToUpdatePya[modifierListType];

                            if (dict[id]) {
                                this.$set(modifierDict, modifierId, { ...modifier, fullId: modifierId });
                            }else {
                                this.$delete(modifierDict, modifierId);
                            }
                        });
                    });
                }
            }
        },
        UpdateAvailabilityPya() {
            let data = this.modalUpdateAvailabilityPya;
            if(!data.disablingMethod && data.unavailable.length) return this.Info('Se debe seleccionar un método para deshabilitar productos');
            let platform = this.selectedPlatform;

            data.id_platform = platform.id;

            this.Loading();
            axios.post(`/pya/menu/${this.Local.id}/update/items`, data)
            .then(({data}) => {
                if(data.status) this.selectedPlatform = data.overwrites;
                this.modalUpdateAvailabilityPya = null;
                this.ItemsToUpdatePya = null;
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        OnSKU(sku) {
            if(document.querySelectorAll('.modal').length) return;
            if(!this.menu || this.tab !== 'Menú') return;
            let foundItem = false;
            let items = [];
            let sections = [];
            for(let i = 0; i < this.menu.length; i++)
            {
                let section = this.menu[i];
                sections.push({text: 'Sección: '+section.title, value: i});
                for(let j = 0; j < section.items.length; j++)
                {
                    let item = section.items[j];
                    if(item.sku == sku)
                    {
                        this.highlightedItem = item.id;
                        this.Toast(`SKU registrado para el item: ${item.title}`);
                        let el = document.getElementById(`menu-item-${item.id}`)
                        if(el) el.scrollIntoView();
                        foundItem = true;
                    }

                    if(!item.sku && item.type == 0) items.push({text: item.title, value: item.id});

                }
            }
            if(foundItem) return;

            if(!items.length || !sections.length) return;

            setTimeout(() => {
                this.modalAssignSKU = {
                    sku: sku,
                    items: items,
                    item: null,
                    options: [
                        {title: '', price: '', section: '', image: ''}
                    ],
                    sections: sections
                };
            }, 100)

            axios.get(`/menu/sku/search/${sku}`).then(res => {
                console.log(res.data);
                if(this.modalAssignSKU) this.$set(this.modalAssignSKU, 'options', this.modalAssignSKU.options.concat(res.data));
            });

        },
        AssignSKU() 
        {
            this.Loading();
            axios.patch(`/menu/item/${this.modalAssignSKU.item}/sku/assign`, {sku: this.modalAssignSKU.sku}).then(res => {
                this.Response(res);
                this.menu = res.data;
                this.modalAssignSKU = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        CreateSKUItem(entry)
        {
            if(!entry.title) return this.Info('Nombre inválido');
            if(!entry.price) return this.Info('Precio inválido');

            let itemData = {
                type: 0,
                title: entry.title,
                price: entry.price,
                categories: [],
                modifiers: [],
                inventory_references: [],
                sku: this.modalAssignSKU.sku,
                image: entry.image ? entry.image : 'NO_IMAGE.png',
            };

            this.Loading();
            axios.put(`/menu/item/${this.Local.id}/${entry.section}`, itemData).then(res => {
                this.Response(res);
                this.menu = res.data;
                this.modalAssignSKU = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        AddDayToSchedule(fullWeek) {
            const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            
            if (fullWeek) {
                const existingDays = this.modalSchedule.schedule.map(entry => entry.day);
                
                const missingDays = daysOfWeek.filter(day => !existingDays.includes(day));

                if (missingDays.length > 0) {
                    missingDays.forEach(day => {
                        this.modalSchedule.schedule.push({
                            day: day,
                            start: '00:00',
                            finish: '23:59'
                        });
                    });
                }
            } else {
                const nextDayIndex = this.modalSchedule.schedule.length % daysOfWeek.length;
                const nextDay = daysOfWeek[nextDayIndex];
                
                this.modalSchedule.schedule.push({
                    day: nextDay,
                    start: '00:00',
                    finish: '23:59'
                });
            }
        },
        OpenModalOrderMenu(){
            if(!this.menu) return;
            this.searchOrderMenu = '';
            let copy = this.Util.Copy(this.menu);
            this.modalOrderMenu = {items : [], options : [], hideItems: false, hide : {} };
            this.$set(this.modalOrderMenu, 'items', copy);
            copy.forEach(section => {
                this.$set(this.modalOrderMenu.hide, section.title, false); 
            });
        },
        ChangeItemSectionOptions(indexItem, indexSection){
            if(!this.menu) return;
            this.$set(this.modalOrderMenu, 'options', []);
            // let copy = this.Util.Copy(this.menu);

            this.modalOrderMenu.items.forEach((section, sectIndex) => {
                //Opciones para cambiar el item a otra seccion
                this.modalOrderMenu.options.unshift({text: section.title, function : () => {
                    
                    //Saca el item de su sección actual
                    let itemToMove = this.modalOrderMenu.items[indexSection].items.splice(indexItem, 1)[0];
                    //Introduce el item extraido anteriormente, y lo coloca en la nueva sección
                    this.modalOrderMenu.items[sectIndex].items.push(itemToMove);
                }})
            });
            return this.modalOrderMenu.options;
        },
        MoveUpOrderMenu(sectionIndex, itemIndex = null, key = null) {
            if (!this.modalOrderMenu) return;

            let selected = this.$refs.orderContainer.querySelectorAll('.cpurple');
            selected.forEach(el => {
                el.classList.remove('cpurple');
            });
            setTimeout(() => {
                if(key)
                {
                    let el = document.getElementById(key);
                    el.classList.add('cpurple');
                }
            }, 10);

            if (sectionIndex !== null && sectionIndex !== undefined && itemIndex === null) {
                // Es sección
                if (sectionIndex > 0) { // Verifica que no es la primera sección
                    let toUp = this.modalOrderMenu.items[sectionIndex];       // Sección que sube
                    let toDown = this.modalOrderMenu.items[sectionIndex - 1]; // Sección que baja

                    this.$set(this.modalOrderMenu.items, sectionIndex, toDown);
                    this.$set(this.modalOrderMenu.items, sectionIndex - 1, toUp);
                }
            } else {
                // Es ítem
                let currentSection = this.modalOrderMenu.items[sectionIndex];
                let currentItems = currentSection.items;

                if (itemIndex > 0) {
                    // El ítem NO es el primero de la sección: intercambiar con el anterior
                    let toUp = currentItems[itemIndex]; 
                    let toDown = currentItems[itemIndex - 1]; 

                    this.$set(currentItems, itemIndex, toDown);
                    this.$set(currentItems, itemIndex - 1, toUp);

                } else if (sectionIndex > 0) {
                    // El ítem ES el primero de la sección y hay una sección anterior

                    // Se extrae el elemetno de la seccion
                    let itemToMove = currentItems.splice(itemIndex, 1)[0];

                    // Se inserta el elemento en la siguiente seccion
                    let prevSectionItems = this.modalOrderMenu.items[sectionIndex - 1].items;
                    prevSectionItems.push(itemToMove);

                    this.$set(this.modalOrderMenu.items, sectionIndex, currentSection);
                    this.$set(this.modalOrderMenu.items, sectionIndex - 1, this.modalOrderMenu.items[sectionIndex - 1]);
                    if(!this.modalOrderMenu.hide[this.modalOrderMenu.items[sectionIndex-1].title]) this.ClickHideShowSection(this.modalOrderMenu.items[sectionIndex-1].title);
                }
            }
        },
        MoveDownOrderMenu(sectionIndex, itemIndex = null, key = null) {
            if (!this.modalOrderMenu) return;

            let selected = this.$refs.orderContainer.querySelectorAll('.cpurple');
            selected.forEach(el => {
                el.classList.remove('cpurple');
            });
            setTimeout(() => {
                if(key)
                {
                    let el = document.getElementById(key);
                    el.classList.add('cpurple');
                }
            }, 10);

            if (sectionIndex !== null && sectionIndex !== undefined && itemIndex === null) {
                // Es sección
                if (sectionIndex < this.modalOrderMenu.items.length - 1) { // Verifica que no es la última sección
                    // Sección que bajará
                    let toDown = this.modalOrderMenu.items[sectionIndex];
                    // Sección que subirá
                    let toUp = this.modalOrderMenu.items[sectionIndex + 1];     

                    // Intercambiar las secciones
                    this.$set(this.modalOrderMenu.items, sectionIndex, toUp);
                    this.$set(this.modalOrderMenu.items, sectionIndex + 1, toDown);
            
                }
            } else {
                // Es ítem
                let currentSection = this.modalOrderMenu.items[sectionIndex];
                let currentItems = currentSection.items;

                if (itemIndex < currentItems.length - 1) {
                    // Item no es el ultimo elemento de la sección
                    let toUp = currentItems[itemIndex + 1]; 
                    let toDown = currentItems[itemIndex]; 

                    this.$set(currentItems, itemIndex, toUp);
                    this.$set(currentItems, itemIndex + 1, toDown);

                } else if (sectionIndex < this.modalOrderMenu.items.length - 1) {
                    // Ultimo item de la seccion y pasa a la siguiente sección.

                   // Se extrae el elemento 
                    let itemToMove = currentItems.splice(itemIndex, 1)[0];

                    // Se inserta el elemento en la siguiente seccion
                    let nextSectionItems = this.modalOrderMenu.items[sectionIndex + 1].items;
                    nextSectionItems.unshift(itemToMove);

                    this.$set(this.modalOrderMenu.items, sectionIndex, currentSection);
                    this.$set(this.modalOrderMenu.items, sectionIndex + 1, this.modalOrderMenu.items[sectionIndex + 1]);
                    if(!this.modalOrderMenu.hide[this.modalOrderMenu.items[sectionIndex+1].title]) this.ClickHideShowSection(this.modalOrderMenu.items[sectionIndex+1].title);
                }
            }
        },
        ClickHideShowSectionOrderMenu(section){
            if(!this.modalOrderMenu) return;
            this.$set(this.modalOrderMenu.hide, section, !this.modalOrderMenu.hide[section]);
        },
        ClickToggleShowAllSectionsOrderMenu(show){
            if(!this.menu || !this.modalOrderMenu) return;
            let copy = this.Util.Copy(this.menu);
            this.$set(this.modalOrderMenu, 'hideItems', show);
            copy.forEach(section => {
                this.$set(this.modalOrderMenu.hide, section.title, show);   // Aquí se asigna correctamente
            });
        },
        SaveOrderMenu(){
            if(!this.modalOrderMenu.items.length) return this.Info("No hay items para guardar.")
            this.Loading();
            axios.post(`/menu/order/${this.Local.id}`, {menu : this.modalOrderMenu.items}).then((res) => {
                this.$root.$emit('Response', res);
                this.menu = res.data;
                this.modalOrderMenu = null;
            }).catch((err) => {
                this.$root.$emit('Response', err);
            });
        },
        ReorderByKeyPress(e)
        {
            if(!this.$refs.orderContainer) return;
            let elClass = '';
            if(e.key == 'ArrowUp') elClass = '.icon-arrow-up';
            else if(e.key == 'ArrowDown') elClass = '.icon-arrow-down';
            if(!elClass) return;
            let item = this.$refs.orderContainer.querySelector('.cpurple');
            if(item)
            {
                let btn = item.querySelector(elClass);
                if(btn) btn.click();
            }
        },
        SaveLanguage(){
            if(!this.modalLanguage.language) return this.Info("El idioma es obligatorio.");
            //modalLanguage.language devuelve value "EN" "PT"
            // busca objeto segun value
            let find = this.defaultLanguages.find(item => item.value === this.modalLanguage.language);
            this.$set(this.modalLanguage, 'language', find.text);    
            this.Loading();
            axios.put(`/menu/${this.Local.id}/language`, this.modalLanguage)
            .then(({data}) => {
                this.Response(data);
                this.modalLanguage = null;
                // if(!data.overwrites.length) data.overwrites = {};
                this.languages.push(data);
                this.selectedLanguage = data;
            }).catch(err => {
                this.Response(err);
            });
        },
        EditLanguage(){
            this.Loading();
            this.modalLanguage.language = this.selectedLanguage.label;
            axios.patch(`/menu/${this.Local.id}/language/modify`, this.modalLanguage)
            .then(({data}) => {
                this.Response(data);
                this.modalLanguage = null;

                //Se busca el index del idioma editado en el array
                let findIndex = this.languages.findIndex(lan => lan.id === data.id);            
                // Se cambia los datos por los editados   
                this.$set(this.languages, findIndex, data);
                this.selectedLanguage = data;
            }).catch(err => {
                this.Response(err);
            });
        },
        GetLanguages() 
        {
            if(this.languages) return;
            this.Loading();
            axios.get(`/menu/${this.Local.id}/languages`).then(({data}) => {
                this.Response(data);
                this.languages = data.menu_overwrite;
                this.keyService = data.key_service;
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenModalLanguageEntry(item,parent = null, group = null, ttitle = null, tdescription = null)
        {
            let isString = typeof item === 'string';
            this.modalLanguageEntry = {
                id : item.id,
                items : !!item.items,
                title : isString ? item : item.title,
                translate_title : item.translate_title ? item.translate_title : (ttitle ? ttitle : ''),
                description : item.description ? item.description : '',
                translate_description : item.translate_description ? item.translate_description : (tdescription ? tdescription : ''),
                is_modifier: item.id == '' && !(item.items && item.items.length) && item.modifierIndex >= 0 ? true : false,
                is_group: (item.id == ''|| !item.id) && !!(item.modifiers && item.modifiers.length) && item.groupIndex >= 0 ? true : false,
                is_super_section : isString,
                group : group ? group : '',
                parent_id: parent? parent.id : '',
            }
        },
        ApplyLanguageOverwrite(){
            
            if(this.modalLanguageEntry.is_super_section) this.$set(this.selectedLanguage.overwrites, this.modalLanguageEntry.title+'-supersection-value', this.modalLanguageEntry.translate_title);

            if(!this.modalLanguageEntry.is_modifier){
                if(this.modalLanguageEntry.id && typeof this.modalLanguageEntry.id === 'number'){
                    // Item
                    //console.log("itemr");
                    this.$set(this.selectedLanguage.overwrites, this.modalLanguageEntry.id+'-value', this.modalLanguageEntry.translate_title);

                    this.$set(this.selectedLanguage.overwrites, this.modalLanguageEntry.id+'-description-value', this.modalLanguageEntry.translate_description);
                } else if((typeof this.modalLanguageEntry.id === 'string' && this.modalLanguageEntry.id) || this.modalLanguageEntry.is_group){
                    // Es Grupo
                    //console.log("grupo");
                    this.$set(this.selectedLanguage.overwrites, this.modalLanguageEntry.parent_id+'-'+this.modalLanguageEntry.title+'-value', this.modalLanguageEntry.translate_title);
                }else{
                    // Sección
                    //console.log("seccion");
                    this.$set(this.selectedLanguage.overwrites, this.modalLanguageEntry.title+'-value', this.modalLanguageEntry.translate_title);
                    this.$set(this.selectedLanguage.overwrites, this.modalLanguageEntry.title+'-description-value', this.modalLanguageEntry.translate_description);
                }
            }else if(this.modalLanguageEntry.is_modifier){
                //Es modificador
                //console.log("modificador");
                
                this.$set(this.selectedLanguage.overwrites, this.modalLanguageEntry.parent_id+'-'+this.modalLanguageEntry.group.title+'-'+this.modalLanguageEntry.title+'-value', this.modalLanguageEntry.translate_title);
                this.$set(this.selectedLanguage.overwrites, this.modalLanguageEntry.parent_id+'-'+this.modalLanguageEntry.group.title+'-'+this.modalLanguageEntry.title+'-description-value', this.modalLanguageEntry.translate_description);
            }

            this.modalLanguageEntry = null;
        },
        OverwrittenLanguages(entry, id = null, description = false, parent = null, group = null, super_section = false)
        {
            
            let selected = this.selectedLanguage;
            if(!selected) return;

            let getValue = function(key) {
                if(selected.overwrites[key]) return selected.overwrites[key];
                // return selected.overwrites[key] || '';
            };

            //Super sección
            if(super_section) return getValue(entry+'-supersection-value');
            
            if (entry.id === '' && !(entry.items && entry.items.length) && entry.modifierIndex >= 0) {
                 
                const key = description 
                    ? parent.id + '-' + group.title + '-' + entry.title + '-description-value' 
                    : parent.id + '-' + group.title + '-' + entry.title + '-value';                
                return getValue(key);
            }

            //Grupo
            if (entry.modifiers && entry.modifiers.length && entry.groupIndex >= 0 && parent) {
                return getValue(parent.id + '-' + entry.title + '-value');
            }

            // Sección o Ítem
            if (!id && !description) {
                return getValue(entry + '-value');
            }

            const key = description 
                ? id + '-description-value' 
                : id + '-value';
            return getValue(key);
        },
        DeleteLanguage()
        {
            let selected = this.selectedLanguage
            if(!selected) return;
            let language = this.languages;

            this.Confirm({
                text: 'Eliminar',
                title: `¿Eliminar idioma ${selected.label}?`,
                class: 'error-color',
                function: () => {
                    this.Loading();
                    axios.delete(`/menu/${this.Local.id}/language/${selected.id}`).then(({data}) => {
                        this.Response(data);
                        for(var i = 0; i < language.length; i++)
                        {
                            if(language[i].id == data.id)
                            {
                                this.$delete(language, i);
                                break;
                            }
                        }
                        selected = null;
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            });
            
        },
        SaveMenuLanguage()
        {
            let selected = this.selectedLanguage;
            if(!selected) return;
            
            let language = this.languages;

            this.Loading();
            axios.patch(`/menu/${this.Local.id}/language/${selected.id}`, selected).then(({data}) => {
                this.Info('Menú guardado');
                if(data.overwrites.length === 0) data.overwrites = {};
                for(var i = 0; i < language.length; i++)
                {
                    if(language[i].id == data.id)
                    {
                        this.$set(language, i, data);       
                        break;
                    }
                }
                // if(this.tab == 'Horarios') this.selectedSchedule = data;
                // else this.selectedPlatform = data;
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenModalLoadFastMenu(){
            let find = this.defaultLanguages.find(item => item.text === this.selectedLanguage.label);
            this.modalLoadFastTraduction = {pos_translate : null, language : find.value};
        },
        ClickSelectLanguageToTranslate(lang) {
            if(!lang) return;
            
            this.url = process.env.VUE_APP_BACKEND_URL+'language/translate/'+this.keyService

            const targetLanguage = lang; // Código de idioma destino

            const googleTranslateUrl = `https://translate.google.com/translate?hl=${targetLanguage}&sl=auto&u=${this.url}`;

            this.$set(this.modalLoadFastTraduction, 'google', googleTranslateUrl);
            // Se abre una pestaña nueva con las traducciones
            window.open(googleTranslateUrl, '_blank');
        },
        SaveMasiveLanguageTranslate() {
            if(!this.modalLoadFastTraduction.pos_translate) return this.Info("Debes ingresar las traducciones.")
            let selected = this.selectedLanguage;
            this.Loading();
            axios.post(`/menu/${this.Local.id}/language/translate/${selected.id}`, {translates : this.modalLoadFastTraduction.pos_translate})
            .then(({data}) => {
                this.Response(data);
                this.selectedLanguage = data; //Se asigna a la seleccion actual del lenguaje
                let findLang = this.languages.findIndex(lang => lang.id == data.id);
                if(findLang !== -1) this.$set(this.languages, findLang, data); // Se actualiza en el array de lenguajes
                this.modalLoadFastTraduction = null
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenModalNewLanguage(){
            let available = this.defaultLanguages.filter(lang => 
                !this.languages.some(l => l.label === lang.text || l.text === lang.text)
            );
            if(!available.length) return this.Info("No hay idiomas habilitados para seleccionar.");
            this.modalLanguage = {language : '', pos : false, ecommerce : true, virtual_menu : true};
        }
    },
    computed: {
        isLocal() {
            return this.Local.type == 'LOCAL';
        },
        canEdit() {
            return !this.Local.id_franchise;
        },
        statsTotal() {
            if(!this.modalItemStats) return null;
            var ret = {
                hours: 0,
                days: 0,
                modifiers: 0,
                group_modifiers: 0,
                relations: 0
            };
            for(var i = 0; i < this.modalItemStats.hours.length; i++)
                ret.hours += this.modalItemStats.hours[i].count;
            for(var i = 0; i < this.modalItemStats.days.length; i++)
                ret.days += this.modalItemStats.days[i].count;
            for(var i = 0; i < this.modalItemStats.modifiers.length; i++)
                ret.modifiers += this.modalItemStats.modifiers[i].count;
            for(var i = 0; i < this.modalItemStats.group_modifiers.length; i++)
                ret.group_modifiers += this.modalItemStats.group_modifiers[i].count;
            for(var i = 0; i < this.modalItemStats.relations.length; i++)
                ret.relations += this.modalItemStats.relations[i].count;
            
            return ret;
        },
        visibleItems() {
            if(!this.search) return this.indexedMenu;

            var ret = [];
            for(var i = 0; i < this.indexedMenu.length; i++)
            {
                var section = this.indexedMenu[i];

                var addSection = this.Util.InString(section.title, this.search);

                var items = [];
                for(var j = 0; j < section.items.length; j++)
                {
                    var item = section.items[j];
                    if(addSection || this.Util.InString(item.title, this.search))
                        items.push(item);
                }
                if(items.length > 0)
                    ret.push({
                        title: section.title,
                        invisible: section.invisible,
                        items: items,
                        hide_pos: section.hide_pos,
                        hide_virtual_menu: section.hide_virtual_menu,
                        sectionIndex: section.sectionIndex,
                        color : section.color
                    });
            }
            return ret;
              
        },
        visibleItemsAvailable() {
            var search = (
                this.tab == 'Disponibilidad' 
                    ? this.searchAvailability 
                    : this.tab == 'Plataformas' 
                        ? this.searchOverwritePlatform 
                        : this.tab == 'Idiomas' 
                            ? this.searchlanguage 
                            : this.searchOverwrite
            )
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase();

            var ret = [];
            for(var i = 0; i < this.indexedMenu.length; i++)
            {
                var section = this.indexedMenu[i];
                if(section.super_section && !this.listSuperSections.includes(section.super_section)) this.listSuperSections.push(section.super_section);
                var items = [];
                for(var j = 0; j < section.items.length; j++)
                {
                    
                    var item = section.items[j];
                    var addItem = false;
                    if(this.showAll || !item.invisible)
                    {

                        if(this.Util.InString(item.title, search))
                            addItem = true;

                        if(!item.modifiers) item.modifiers = [];

                        var groups = [];
                        for(var x = 0; x < item.modifiers.length; x++)
                        {
                            var group = item.modifiers[x];
                            if(this.showAll || !group.invisible)
                            {
                                var mods = [];
                                for(var y = 0; y < group.modifiers.length; y++)
                                {
                                    var modifier = group.modifiers[y];
                                    var addModifier = false;
                                    if(this.Util.InString(modifier.title, search))
                                        addModifier = true;
                                    if((this.showAll || !modifier.invisible) && (addItem || addModifier))
                                    {
                                        mods.push(modifier);
                                    }
                                }

                                var mods_replacement = [];
                                if(group.modifiers_replacement)
                                {
                                    for(var y = 0; y < group.modifiers_replacement.length; y++)
                                    {
                                        var modifier = group.modifiers_replacement[y];
                                        var addModifier = false;
                                        if(this.Util.InString(modifier.title, search))
                                            addModifier = true;
                                        if((this.showAll || !modifier.invisible) && (addItem || addModifier))
                                        {
                                            mods_replacement.push(modifier);
                                        }
                                    }
                                }

                                if(mods.length > 0)
                                    groups.push({
                                        title: group.title,
                                        groupIndex: group.groupIndex,
                                        modifiers: mods,
                                        modifiers_replacement: mods_replacement,
                                        id: group.id
                                    });
                                
                            }
                            
                        }
                        if(addItem || groups.length)
                        {
                            items.push({
                                title: item.title,
                                id: item.id,
                                unavailable: item.unavailable,
                                modifiers: groups,
                                price: item.price,
                                description : item.description
                            });
                        }
                        
                    }
                    
                }
                if(items.length > 0)
                    ret.push({
                        title: section.title,
                        invisible: section.invisible,
                        items: items
                    });
            }
            return ret;
        },
        indexedMenu() {
            var menu = JSON.parse(JSON.stringify(this.menu));
            for(var i = 0; i < menu.length; i++)
            {
                var section = menu[i];
                section.sectionIndex = i;
                for(var j = 0; j < section.items.length; j++)
                {
                    var item = section.items[j];
                    item.hide_modifiers = true;
                    item.itemIndex = j;
                    if(item.modifiers)
                    {
                        for(var x = 0; x < item.modifiers.length; x++)
                        {
                            var group = item.modifiers[x];
                            group.groupIndex = x;
                            for(var y = 0; y < group.modifiers.length; y++)
                            {
                                var modifier = group.modifiers[y];
                                modifier.modifierIndex = y;
                            }
                            if(group.modifiers_replacement)
                            {
                                for(var y = 0; y < group.modifiers_replacement.length; y++)
                                {
                                    var modifier = group.modifiers_replacement[y];
                                    modifier.modifierIndex = y;
                                }
                            }
                        }
                    }
                }
            }
            return menu;
        },
        dictItems() {
            var ret = {};
            if(!this.menu) return ret;
            this.menu.forEach(section => {
                section.items.forEach(item => {
                    ret[item.id] = true;
                });
            });
            return ret;
        },
        tabs() {
            if(this.Auth.role == 'cashier') return ['Disponibilidad'];
            return this.Local.type == 'FRANCHISE' ? ['Menú'] : ['Menú', 'Horarios', 'Plataformas', 'Disponibilidad', 'Idiomas'];
        },
        itemInventory() 
        {
            if(!this.modalItemStats) return null;

            var item = this.modalItemStats.item;
            var inventory = this.inventory;
            var price = item.price;
            var cost = 0;

            item.inventory_references.forEach(reference => {
                if(inventory.items[reference.inventory_item] && inventory.items[reference.inventory_item].unit_cost)
                {
                    cost += inventory.items[reference.inventory_item].unit_cost * reference.quantity;
                }
            });

            if(this.modalItemStats.show_modifiers)
            {
                item.modifiers.forEach(group => {
                    group.modifiers.forEach(modifier => {
                        if(modifier.selected)
                        {
                            price += modifier.price;
                            cost += this.InventoryCost(modifier.inventory_references, this.inventory);
                        }
                    });
                });
            }

            var margin = price - cost;

            return {
                price: price,
                cost: this.Util.RoundFloat(cost),
                margin: margin,
                margin_percentage: parseInt((margin / price * 100) * 10) / 10
            }


        },
        schedulesItems() {
            if(!this.schedules) return;
            var ret = [];
            for(var i = 0; i < this.schedules.length; i++)
            {
                var label = '';
                this.schedules[i].schedule.forEach(schedule => {
                    label += `, ${this.dayLetter[schedule.day]} [${schedule.start}-${schedule.finish}]`;
                });
                label = label.substr(2);
                if(this.schedules[i].overwrites.length === 0) this.schedules[i].overwrites = {};
                this.schedules[i].label = label;
                this.schedules[i].validity = 
                this.schedules[i].start && this.schedules[i].finish
                    ? `Desde el ${this.Util.Date(this.schedules[i].start, 'DD/MM/YYYY')} hasta el ${this.Util.Date(this.schedules[i].finish, 'DD/MM/YYYY')}`
                    : this.schedules[i].start
                    ? `Desde el ${this.Util.Date(this.schedules[i].start, 'DD/MM/YYYY')}`
                    : this.schedules[i].finish
                    ? `Hasta el ${this.Util.Date(this.schedules[i].finish, 'DD/MM/YYYY')}`
                    : '';
                this.schedules[i].title = `Horario ${i+1}`;
                ret.push({text: `Horario ${i+1}`,description: label, value: this.schedules[i]});
            }
            return ret;
        },
        platformsItems() {
            if(!this.platforms) return;
            let ret = [];
            for(let i = 0; i < this.platforms.length; i++)
            {
                const platform = this.platforms[i];
                let label = this.dictPlatforms[platform.type]? this.dictPlatforms[platform.type] : platform.type;
                if(this.platforms[i].overwrites.length === 0) this.platforms[i].overwrites = {};
                this.platforms[i].label = label;
                this.platforms[i].title = label;
                ret.push({text: label, value: this.platforms[i]});
            }
            return ret;
        },

        languageItems() {
            if(!this.languages) return;
            let ret = [];
            for(let i = 0; i < this.languages.length; i++)
            {
                const language = this.languages[i];
                let label = this.dictlanguages[language.name]? this.dictlanguages[language.name] : language.name;
                if(this.languages[i].overwrites.length === 0) this.languages[i].overwrites = {};
                this.languages[i].label = label;
                this.languages[i].name = label;
                ret.push({text: label, value: this.languages[i]});
            }
            return ret;
        },
        tabCount() {
            return {
                Horarios: this.schedulesItems ? this.schedulesItems.length : 0,
                Plataformas: this.platformsItems ? this.platformsItems.length : 0,
                Idiomas: this.languageItems ? this.languageItems.length : 0
            }
        },
        superSection(){
            if(!this.modalSection) return;
            let ret = {};
            for(let key in this.visibleItems)
            {
                let super_section = this.visibleItems[key].super_section;
                if(super_section) {
                    if(!ret[super_section]) ret[super_section] = {text: super_section, value: super_section, count: 0};
                    ret[super_section].count++;
                }
            }

            ret = Object.values(ret);
            ret.forEach(entry => {
                entry.description = `${entry.count} items`;
            });

            return ret;
        },
        languagesAvailable(){
            if(!this.modalLanguage) return;

            //Si se está editanto el idioma
            if(this.modalLanguage.id){
               return [this.defaultLanguages.find(lang => lang.text === this.selectedLanguage.name)]
            }

            // Retona los idiomas que estan habilitados para mostrarse
            let ret = this.defaultLanguages.filter(lang => 
                !this.languages.some(l => l.label === lang.text || l.text === lang.text)
            );
            return ret;
        },
        orderMenuItems(){
            if(!this.modalOrderMenu.items) return;
            if(this.searchOrderMenu.length > 0 || this.searchOrderMenu != ''){
                let firstMatch = null;
                this.modalOrderMenu.items.forEach((section, sectionIndex) => {
                    let shouldShowSection = false;

                    section.items.forEach((item, itemIndex) => {
                        if (this.Util.InString(item.title, this.searchOrderMenu)) {
                            shouldShowSection = true;
                            // Guardar la primera coincidencia para hacer scroll
                            if (!firstMatch) {
                                firstMatch = { sectionIndex, itemIndex };
                            }
                        }
                    });

                    // Si al menos un item coincide, mostramos la sección
                    this.$set(this.modalOrderMenu.hide, section.title, shouldShowSection);
                });
                if (firstMatch) {
                    this.$nextTick(() => {
                        const refName = `item-${firstMatch.sectionIndex}-${firstMatch.itemIndex}`;
                        const element = this.$refs[refName];

                        if (element && element[0]) { 
                            element[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                    });
                }
            }
            return this.modalOrderMenu.items;
        }
    },
    watch: {
        modalOrderMenu: function(n, o) {
            if(n)
                document.addEventListener('keydown', this.ReorderByKeyPress);   
            else
                document.removeEventListener('keydown', this.ReorderByKeyPress);
        }
    }
}
</script>

<style lang="scss">
.stat-box {
    background-color: var(--white);
    padding: 10px;
    border-radius: 10px;
    margin: 0px 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    min-width: 120px;
}
.item-unavailable {
    color: var(--gray-1);
    cursor: default !important;
}
.item-grid.item-unavailable:hover {
    transform: scale(1) !important;
    background-color: var(--white) !important;
    box-shadow: none !important;
    outline: none !important;
}
.item-list.item-unavailable:hover {
    background-color: var(--white) !important;
    outline: none !important;
}
.item-unavailable .item-image {
    opacity: 0.65;
}

.ghost {
    opacity: 1;
}

.menu-tag {
    font-size: 8px;
    background-color: #CCC;
    color: var(--white);
    border-radius: 10px;
    height: 3px;
    width: 15px;
    margin-right: 2px;
    &.pos {
        background-color: var(--blue-2);   
    }
    &.ec {
        background-color: var(--pink);
    }
    &.vm {
        background-color: var(--color-table);
    }
}

.menu-tag-round {
    font-size: 8px;
    background-color: #CCC;
    color: var(--white);
    border-radius: 50%;
    height: 3px;
    width: 3px;
    margin-left: 4px;
    transform: scale(2);
    &.dl {
        background-color: var(--color-delivery-transit);
    }
    &.rs {
        background-color: var(--color-takeaway-time);
    }
}

.dark {
    .item-unavailable {
        color: var(--dark-fc-3);
    }
    .item-grid.item-unavailable:hover {
        transform: scale(1) !important;
        background-color: var(--dark-bg-1) !important;
        box-shadow: none !important;
        outline: none !important;
    }
    .item-list.item-unavailable:hover {
        background-color: var(--dark-bg-1) !important;
        outline: none !important;
    }
}

.menu-entry-handle {
    width: 40px; 
    height: 40px; 
    border-radius: 50%; 
    overflow: hidden;
    flex-shrink: 0;

    .handle {
        opacity: 0;
        transition: opacity 0.1s linear;
    }

    img {
        transition: opacity 0.1s linear;
    }

}

.menu-entry:hover {
    .menu-entry-handle {
        img {
            opacity: 0;
        }
        .handle {
            opacity: 1;
        }
    }
}

//Mobile
@media (max-width: 820px) {
    .menu-entry-handle {
        width: 14px; 
        height: 14px;

        .handle {
            opacity: 1;
        }

    }
}

.glow {
    box-shadow: 0px 0px 10px 2px var(--pink);
}

</style>
